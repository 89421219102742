<template>
  <MCard class="organization-snapshot-header">
    <p class="heading">Statistics {{ renderOrgName() }}</p>
    <ag-row class="mt-10">
      <ag-column xs="12" sm="12" md="12" lg="5">
        <MDatePicker
          label="Start Date"
          :start-date="defaultStartDate"
          :max-date="new Date()"
          @onRangeDateChange="(value:any) => startDate = value"
          placeholder="Start Date"
          dateType="startDate"
          :multiCalendar="false"
          :hasRange="false"
          :autoPosition="true"
          :error="bookingDateError"
        />
      </ag-column>
      <ag-column xs="12" sm="12" md="12" lg="5">
        <MDatePicker
          label="End Date"
          :start-date="new Date()"
          :max-date="new Date()"
          :min-date="startDate"
          @onRangeDateChange="(value:any) => endDate = value"
          placeholder="End Date"
          dateType="endDate"
          :multiCalendar="false"
          :hasRange="false"
          :autoPosition="true"
          :error="bookingDateError"
        />
      </ag-column>
      <ag-column
        xs="12"
        sm="12"
        md="12"
        lg="2"
        class="d-flex align-items-center"
      >
        <MButton
          @click="handleFetchData"
          :disabled="isLoading"
          class="margin_bottom_20"
          >Fetch Statistics</MButton
        >
      </ag-column>
    </ag-row>
  </MCard>
  <template v-if="isOrganizationProductivityLoading">
    <ShimmerCard card-height="200px" />
    <ShimmerCard card-height="200px" />
    <ShimmerCard card-height="150px" />
    <ShimmerCard card-height="100px" />
  </template>

  <template v-else>
    <div v-if="showDetails" class="details">
      <!-- Overall Counts -->
      <SnapshotDetailAccordion title="Overall Counts" prepand-icon="m-number">
        <template #overview>
          <div class="grid-container">
            <SnapshotTile
              heading="Total Flight Bookings"
              :subHeading="
                organizationProductivity?.hotel_and_flight_details?.aggregates
                  ?.total_flight_bookings
              "
              icon="m-issue-ticket"
              subHeadingTitle="Count"
            >
            </SnapshotTile>
            <SnapshotTile
              heading="Total Issued Tickets"
              :subHeading="
                organizationProductivity?.total_tickets_issued
                  ?.total_issued_tickets
              "
              icon="m-issue-ticket"
              subHeadingTitle="Count"
            >
            </SnapshotTile>
            <SnapshotTile
              heading="Total Hotel Bookings"
              :subHeading="
                organizationProductivity?.hotel_and_flight_details?.aggregates
                  ?.total_hotels_bookings
              "
              icon="m-hotel"
              subHeadingTitle="Count"
            >
            </SnapshotTile>
            <SnapshotTile
              heading="Total Issued Hotels"
              :subHeading="
                organizationProductivity?.hotel_and_flight_details?.aggregates
                  ?.total_hotels_issued
              "
              icon="m-hotel"
              subHeadingTitle="Count"
            >
            </SnapshotTile>
            <SnapshotTile
              heading="Total Payment Created"
              :subHeading="
                organizationProductivity?.payment_details?.aggregates
                  .total_payments_created
              "
              icon="m-payment-management"
              subHeadingTitle="Count"
            >
            </SnapshotTile>
            <SnapshotTile
              heading="Total Credit Limit Requests"
              :subHeading="
                organizationProductivity?.total_credit_limit_requests
              "
              icon="m-credit-limit-management"
              subHeadingTitle="Count"
            >
            </SnapshotTile>
            <SnapshotTile
              heading="Total Sabre Segments"
              :subHeading="organizationProductivity?.total_sabre_segments"
              icon="m-sabre-segment"
              subHeadingTitle="Count"
            >
            </SnapshotTile>
          </div>
        </template>
      </SnapshotDetailAccordion>

      <!-- Booking Card -->
      <SnapshotDetailAccordion title="Booked" prepand-icon="m-my-bookings">
        <template #overview>
          <div class="grid-container">
            <div
              v-for="(item, index) in organizationProductivity
                ?.hotel_and_flight_details?.flight_bookings"
              :key="index"
            >
              <SnapshotTile
                :heading="replaceUnderscoreWithSpace(item.provider_name)"
                :subHeading="item.count"
                icon="m-flight"
                subHeadingTitle="Booked Count"
                :description="
                  formattedCurrency(
                    item.total_amount.currency,
                    item.total_amount.value
                  )
                "
                descriptionTitle="Amount"
              >
              </SnapshotTile>
            </div>
          </div>
          <p class="empty-items-message" v-show="showBookedEmptyMsg">
            Nothing Booked Yet
          </p>
        </template>
      </SnapshotDetailAccordion>

      <!-- Issued Card -->
      <SnapshotDetailAccordion title="Issued" prepand-icon="m-issue-ticket">
        <template #overview>
          <div class="grid-container">
            <div
              v-for="(item, index) in organizationProductivity
                ?.total_tickets_issued?.issued_tickets"
              :key="index"
            >
              <SnapshotTile
                :heading="replaceUnderscoreWithSpace(item.provider_name)"
                :subHeading="item.count"
                icon="m-flight"
                subHeadingTitle="Issued Count"
                :description="
                  formattedCurrency(
                    item.total_amount.currency,
                    item.total_amount.value
                  )
                "
                descriptionTitle="Amount"
              >
              </SnapshotTile>
            </div>
          </div>
          <p class="empty-items-message" v-show="showIssuedEmptyMsg">
            Nothing Issued Yet
          </p>
        </template>
      </SnapshotDetailAccordion>

      <!-- Payment Card -->
      <SnapshotDetailAccordion
        title="Payments"
        prepand-icon="m-payment-management"
      >
        <template #overview>
          <div class="grid-container">
            <div
              v-for="(item, index) in organizationProductivity?.payment_details
                ?.payments"
              :key="index"
            >
              <SnapshotTile
                :heading="replaceUnderscoreWithSpace(item.payment_method)"
                :subHeading="item.count"
                subHeadingTitle="Count"
                icon="m-payment-management"
              >
              </SnapshotTile>
            </div>
          </div>
          <p class="empty-items-message" v-show="showPaymentsEmptyMsg">
            No Payment Found
          </p>
        </template>
      </SnapshotDetailAccordion>
    </div>
    <ResultNotFound v-if="showResultNotFound" />
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { format } from "date-fns";
import _ from "lodash";

import { IOrganizationProductivity } from "@/ag-portal-common/interfaces/organizationProductivity.interface";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { IOrganizationStats } from "@/ag-portal-common/interfaces/organizationStatistics.interface";
import { MCard, MButton } from "@aeroglobe/ag-core-ui";
import ShimmerCard from "@/components/ShimmerCard.vue";
import SnapshotDetailAccordion from "@/components/SnapshotDetailAccordion.vue";
import SnapshotTile from "@/components/SnapshotTile.vue";
import ResultNotFound from "@/components/ResultNotFound.vue";
import { getCurrencyFormatter } from "@/ag-flight-components/utils";

export default defineComponent({
  name: "OrganizationSnapshotProductionTab",
  components: {
    MCard,
    MButton,
    ShimmerCard,
    ResultNotFound,
    SnapshotDetailAccordion,
    SnapshotTile,
  },
  data(): {
    startDate: Date;
    endDate: Date;
    selectedOrg: string;
    isInitial: boolean;
    bookingDateError: string;
  } {
    return {
      startDate: new Date(),
      endDate: new Date(),
      selectedOrg: "",
      isInitial: true,
      bookingDateError: "",
    };
  },
  methods: {
    formattedCurrency(currency: string, amount: number): string {
      return getCurrencyFormatter(currency).format(amount);
    },
    handleFetchData() {
      if (this.startDate > this.endDate) {
        this.bookingDateError = "Start date Can't be less than end date";
      } else {
        this.bookingDateError = "";
        const { org_id } = this.$route.query;
        this.$store.dispatch("getOrganizationProductivity", {
          organizationId: org_id,
          payload: {
            start_date: format(this.startDate, FORMAT_YYY_MM_DD),
            end_date: format(this.endDate, FORMAT_YYY_MM_DD),
          },
        });
      }
    },
    renderOrgName() {
      const org_name = this.organizationStats?.organization?.org_name;
      return org_name ? `- ${org_name}` : "";
    },
    replaceUnderscoreWithSpace(title: string) {
      return title.replaceAll("_", " ");
    },
  },
  created() {
    this.startDate = this.defaultStartDate;
  },
  computed: {
    defaultStartDate(): Date {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      return yesterday;
    },
    organizationProductivity(): IOrganizationProductivity {
      return this.$store.getters.organizationProductivity;
    },
    isLoading(): boolean {
      return this.$store.getters.isFetchingOrganizations;
    },
    isOrganizationProductivityLoading(): boolean {
      return this.$store.getters.isOrganizationProductivityLoading;
    },
    showDetails(): boolean {
      const isNotEmpty = !_.isEmpty(this.organizationProductivity);
      return isNotEmpty;
    },
    showIssuedEmptyMsg(): boolean {
      return (
        !this.isOrganizationProductivityLoading &&
        this.organizationProductivity?.total_tickets_issued?.issued_tickets
          .length < 1
      );
    },
    showBookedEmptyMsg(): boolean {
      return (
        !this.isOrganizationProductivityLoading &&
        this.organizationProductivity?.hotel_and_flight_details?.flight_bookings
          .length < 1
      );
    },
    showPaymentsEmptyMsg(): boolean {
      return (
        !this.isOrganizationProductivityLoading &&
        this.organizationProductivity?.payment_details?.payments.length < 1
      );
    },
    organizationStats(): IOrganizationStats {
      return this.$store.getters.organizationStats;
    },
    showResultNotFound(): boolean {
      return (
        !this.isInitial &&
        !this.isOrganizationProductivityLoading &&
        !this.showDetails
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.organization-snapshot-header {
  .name-container {
    display: flex;
    align-items: center;
    img {
      width: 70px;
    }
    .agency-name-container {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .agency-name {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
        color: #000;
      }
      p {
        margin-bottom: 0;
        color: #6b7280;
      }
    }
  }
}

.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-left: solid 8px var(--green-color);
}

.heading {
  font-size: 24px;
  font-weight: 600;
}

.color-chip {
  width: 120px;
}

.website-slug {
  font-weight: bold;
  color: #6b7280;
  &:hover {
    color: #0c47bc;
  }
}
.empty-items-message {
  width: 100%;
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}
.white-icon {
  color: #fff;
}
</style>

<style lang="css" scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

@media (max-width: 1350px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 963px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
.details {
  padding: 24px;
}
</style>
