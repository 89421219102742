<template>
  <MDialog title="Delete Agent" v-model="isMdialogOpen" hide-header>
    <template #content>
      <div class="delete-agent-alert">
        <MIcon
          class="delete-agent-alert-icon"
          name="m-info"
          width="48"
          height="48"
        />
        <div class="delete-agent-alert-title">Delete Agent</div>
        <div class="delete-agent-alert-subtitle">
          Are you sure you want to delete
          {{ agent?.user.email }}
        </div>
      </div>
    </template>
    <template #actions>
      <MButton @click="onCloseModal()" type="outlined"> No </MButton>
      <MButton
        @click="onDelete()"
        :disabled="$store.getters.isDeletingAgentFromOrganization"
      >
        Yes
      </MButton>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IAgentV2 } from "@/ag-portal-common/interfaces/agent.interface";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { MDialog } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "AgentDeleteConfirmationModal",
  props: {
    agent: { type: (Object as PropType<IAgentV2>) || null, required: false },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isMdialogOpen: this.isOpen,
    };
  },
  watch: {
    isOpen(newVal) {
      this.isMdialogOpen = newVal;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("onCloseModal");
      }
    },
  },
  methods: {
    genTestId,
    onCloseModal() {
      this.$emit("onCloseModal");
    },
    onDelete() {
      this.$emit("onDeleteAgent");
    },
  },
});
</script>

<style scoped lang="css">
.delete-agent-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 16px;

  padding: 16px;
}

.delete-agent-alert .delete-agent-alert-icon {
  width: 48px;
  height: 48px;

  color: var(--m-error-color);
}
.delete-agent-alert .delete-agent-alert-title {
  font-size: 24px;
  font-weight: 500;
}
.delete-agent-alert .delete-agent-alert-subtitle {
  color: var(--m-label-color);
}
</style>
