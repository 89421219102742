export interface UpdateOrganizationPreferenceRequest {
  displayName: string;
  defaultColor: string;
  logoFile: File;
  template?: string;
}

export const updateOrganizationPreferenceRequestAsMultipartRequestData = (
  dto: UpdateOrganizationPreferenceRequest
): FormData => {
  const formData = new FormData();
  if (dto.displayName) formData.append("display_name", dto.displayName);
  if (dto.defaultColor) formData.append("default_color", dto.defaultColor);
  if (dto.logoFile) formData.append("logo", dto.logoFile);
  if (dto.template) formData.append("flight_booking_template", dto.template);
  return formData;
};
