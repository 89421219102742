<template>
  <MProgress
    v-if="isAirlineAndProvidersFetching"
    indeterminate
    class="mprogress-alignment"
  />
  <MCard v-else>
    <MTypography type="headline">Search for Sabre Details</MTypography>
    <div class="fare-management-header">
      <MTextfield
        v-model:inputValue.trim="pnrNumber"
        label="PNR Number"
        :hasError="hasPnrError"
        :errorMessage="pnrNumberError"
        :disabled="false"
      />
      <MCombobox
        v-model:inputValue="airlineCode"
        :options="sabreAirlinesComboboxOptions"
        itemValue="value"
        itemLabel="label"
        label="Airline/Supplier"
        :hasError="hasAirlineCodeError"
        :errorMessage="airlineCodeError"
        :disabled="isAirlineAndProvidersFetching"
      />
      <MButton
        class="get-fare-action-button"
        @click="getSabreFares()"
        :disabled="isSabreFaresLoading"
        >Get Fare</MButton
      >
    </div>
    <MTypography type="label" v-if="airlineCode">
      {{ sabreAirlineInfoMessage }}
    </MTypography>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SABRE_AIRLINE } from "@/ag-flight-components/types";
import { MCard, MProgress, MTypography } from "@aeroglobe/ag-core-ui";
import { ComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import { FinancialProfile } from "@/interfaces/agentState.interface";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";

export default defineComponent({
  name: "FareManagementSearchCard",
  data() {
    return {
      pnrNumber: "",
      pnrNumberError: "",
      airlineCode: "",
      airlineCodeError: "",
      isAirlineAndProvidersFetching: true,
    };
  },
  async created() {
    this.isAirlineAndProvidersFetching = true;
    let defaultFinancialProfile = this.financialProfiles?.find(
      (item) => item.is_default
    );

    var id = "";
    if (defaultFinancialProfile) {
      id = defaultFinancialProfile.public_id;
    }
    this.fetchAirlinesAndProviders(id);
  },
  methods: {
    async fetchAirlinesAndProviders(id: string) {
      const payload = {
        id,
        isFinancialProfile: true,
      };

      await this.$store.dispatch("fetchAirlinesAndProviders", payload);
      this.isAirlineAndProvidersFetching = false;
    },
    getSabreFares() {
      if (!this.validateData()) {
        const payload = {
          pnr: this.pnrNumber,
          airlineCode: this.airlineCode,
        };
        return this.$store.dispatch("fetchSabreFares", payload);
      }
    },
    hasPnrError(): boolean {
      return this.pnrNumberError.length > 0;
    },
    hasAirlineCodeError(): boolean {
      return this.airlineCodeError.length > 0;
    },
    validateData(): boolean {
      this.airlineCodeError = "";
      this.pnrNumberError = "";
      let hasError = false;
      if (this.airlineCode.length === 0) {
        this.airlineCodeError = "Airline Code is required";
        hasError = true;
      }
      if (this.pnrNumber.length <= 5) {
        this.pnrNumberError = "PNR Number is required";
        hasError = true;
      }
      return hasError;
    },
  },
  computed: {
    sabreAirlineInfoMessage(): string {
      const airlineData = this.sabreAirlines;
      const matchingAirline = airlineData.find(
        (item) => item.airline_code === this.airlineCode
      );

      if (matchingAirline && matchingAirline.pcc) {
        return `Please queue on PCC "${matchingAirline.pcc}" for airline code "${this.airlineCode}".`;
      } else {
        return `This airline cannot be issued through portal, please issue through whatsapp or email.`;
      }
    },
    isSabreFaresLoading(): boolean {
      return this.$store.getters.isSabreFaresLoading;
    },
    financialProfiles(): FinancialProfile[] | null {
      return this.$store.getters[AUTH_GETTERS.FINANCIAL_PROFILES];
    },
    sabreAirlines(): SABRE_AIRLINE[] {
      return this.$store.getters.sabreAirlines as SABRE_AIRLINE[];
    },
    sabreAirlinesComboboxOptions(): ComboboxOptions[] {
      return [...this.sabreAirlines]
        .sort((a: SABRE_AIRLINE, b: SABRE_AIRLINE) =>
          a.airline_code.localeCompare(b.airline_code)
        )
        .map((airline: SABRE_AIRLINE) => ({
          label: airline.airline_code,
          value: airline.airline_code,
        }));
    },
  },
});
</script>

<style lang="css" scoped>
.fare-management-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 10px;
}

@media (max-width: 900px) {
  .fare-management-header {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  .fare-management-header {
    grid-template-columns: repeat(1, 1fr);
  }
}
.get-fare-action-button {
  margin-top: 10px;
}
</style>
