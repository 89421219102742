import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70b85ffa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "forgot-pass-page-view" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MTextfield = _resolveComponent("MTextfield")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MCard = _resolveComponent("MCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MCard, { class: "forgot-pass-card" }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmitForgotPasswordFormHandler && _ctx.onSubmitForgotPasswordFormHandler(...args)), ["prevent"])),
          class: "forgot-pass-form"
        }, [
          _createElementVNode("img", {
            src: _ctx.getOrgLogoHandler(),
            alt: "organisation-logo",
            class: "org-logo"
          }, null, 8, _hoisted_2),
          _createVNode(_component_MTypography, { type: "title" }, {
            default: _withCtx(() => [
              _createTextVNode(" Forgot Your Password? ")
            ]),
            _: 1
          }),
          _createVNode(_component_MTypography, null, {
            default: _withCtx(() => [
              _createTextVNode(" Enter your registered email address below and we will send you a link to reset your password. ")
            ]),
            _: 1
          }),
          _createVNode(_component_MTextfield, {
            "input-value": _ctx.forgotPassInformation.email.value,
            "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.forgotPassInformation.email.value) = $event)),
            label: "Email",
            placeholder: "Enter your email",
            autocomplete: "email",
            "has-error": _ctx.forgotPassInformation.email.hasError,
            "error-message": _ctx.forgotPassInformation.email.errorMessage,
            disabled: _ctx.isForgettingPassword
          }, null, 8, ["input-value", "has-error", "error-message", "disabled"]),
          _createVNode(_component_MButton, {
            type: "text",
            class: "btn-link",
            disabled: _ctx.isForgettingPassword,
            onClick: _ctx.onNavigateToLoginViewHandler
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Back To Login ")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createVNode(_component_MButton, {
            behaviour: "submit",
            class: "btn-submit",
            disabled: _ctx.infoHasErrors() || _ctx.isForgettingPassword
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Submit ")
            ]),
            _: 1
          }, 8, ["disabled"])
        ], 32)
      ]),
      _: 1
    })
  ]))
}