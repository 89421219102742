<template>
  <MDialog
    title="Add Whatsapp Member"
    v-model="isMdialogOpen"
    @close="$emit('closeModal')"
  >
    <template #content>
      <div class="add-whatsapp-member-container">
        <AgPhoneField
          test-id=""
          default-country-code="PK"
          :error="errors?.contact_number"
          @update-value="handleMobileFieldChange"
          class="phone-field"
        />
        <MTextfield
          label="Member Name"
          v-model:inputValue="name"
          variant="outlined"
          :hasError="errors?.name"
          :errorMessage="errors?.name"
        />
      </div>
    </template>

    <template #actions>
      <MButton
        :disabled="$store.getters.isWhatsappActionLoading"
        @click="onSubmit()"
        >Add</MButton
      >
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { addWhatsappMemberValidation } from "@/modules/Organization/validations/addWhatsappMember.validation";
import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { ValidationError } from "yup";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import UTILS from "@/ag-portal-common/utils";
import { Organization } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";

import { MDialog, MTextfield } from "@aeroglobe/ag-core-ui";
export default defineComponent({
  name: "AddWhatsappMemberDialog",
  components: { MTextfield, MDialog },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data(): {
    name: string;
    contact_number: any;
    errors: any;
    isMdialogOpen: boolean;
  } {
    return {
      name: "",
      contact_number: {},
      errors: {},
      isMdialogOpen: false,
    };
  },
  computed: {
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
  },
  watch: {
    isOpen(newVal) {
      this.isMdialogOpen = newVal;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("closeModal");
      }
    },
  },
  methods: {
    clearState() {
      this.name = "";
      this.contact_number = "";
    },
    closeModal() {
      this.$emit("closeModal");
      this.clearState();
    },
    handleMobileFieldChange(value: any) {
      this.contact_number = value;
    },
    successHandler() {
      this.closeModal();
      this.fetchWhatsappMembers();
    },
    fetchWhatsappMembers() {
      const organization_id = this.getOrganizationId();
      this.$store.dispatch("getWhatsappMembers", organization_id);
    },
    getOrganizationId() {
      if (UTILS.isUser(USER_ROLES.AG_SUPER_USER)) {
        const { org_id } = this.$route.query;
        return org_id;
      } else {
        const organizationId = this.organization
          ? this.organization.organization_id
          : "";

        return organizationId;
      }
    },
    async onSubmit() {
      this.errors = {};
      const contact_number = this.contact_number;
      const payload_data = {
        name: this.name,
        contact_number: contact_number?.e164?.replace(/^\+/, ""),
        isValid: contact_number?.isValid,
      };
      try {
        const payload = await addWhatsappMemberValidation.validate(
          payload_data,
          { abortEarly: false }
        );
        const orgId = this.getOrganizationId();
        this.$store.dispatch("addWhatsappMember", {
          payload,
          organizationId: orgId,
          onSuccessHandler: this.successHandler,
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const _errors = yupValidationErrorAsSchema(ex);
          this.errors = _errors;
        }
      }
    },
  },
});
</script>

<style lang="css" scoped>
.add-whatsapp-member-container {
  margin-top: 10px;
}
.phone-field {
  margin-bottom: 10px;
}
</style>
