<template>
  <div class="ds-segments">
    <div
      class="ds-segment-wrapper"
      v-for="(segment, index) in segments"
      :key="index"
    >
      <div class="ds-segment">
        <div class="ds-segment-title">
          <svg class="ds-segment-icon start" viewBox="0 0 20 20">
            <mask id="cutout2">
              <rect width="100%" height="100%" fill="white"></rect>
              <circle cx="10" cy="10" r="2" fill="black"></circle>
            </mask>
            <circle
              class="outer circle"
              cx="10"
              cy="10"
              r="10"
              mask="url(#cutout2)"
            ></circle>
            <circle class="center circle" cx="10" cy="10" r="8"></circle>
            <circle class="inner circle" cx="10" cy="10" r="5"></circle>
          </svg>

          <div>
            <div>
              {{ getFormattedTime(`${segment.departure_datetime}`) }} -
              {{ segment.origin.city }}
              <span class="ds-segment-light">
                ({{ getDepartureTime(`${segment.departure_datetime}`) }})
              </span>
            </div>
            <div class="ds-segment-caption">
              ({{ segment.origin.iata_code }}) {{ segment.origin.airport }}
            </div>
          </div>
        </div>

        <div class="ds-segment-subtitle">
          <div>({{ getDuration(segment.duration_minutes) }})</div>

          <div class="ds-airline">
            <img
              class="ds-airline-image"
              :src="segment.operating_airline.logo"
              alt="flight-image"
              width="24"
              height="24"
            />

            <div>
              {{ segment.flight_number[0] }}
            </div>
          </div>
        </div>

        <div class="ds-segment-title">
          <svg class="ds-segment-icon end" viewBox="0 0 20 20">
            <mask id="cutout2">
              <rect width="100%" height="100%" fill="white"></rect>
              <circle cx="10" cy="10" r="2" fill="black"></circle>
            </mask>
            <circle
              class="outer circle"
              cx="10"
              cy="10"
              r="10"
              mask="url(#cutout2)"
            ></circle>
            <circle class="center circle" cx="10" cy="10" r="8"></circle>
            <circle class="inner circle" cx="10" cy="10" r="5"></circle>
          </svg>

          <div>
            <div>
              {{ getFormattedTime(`${segment.arrival_datetime}`) }} -
              {{ segment.destination.city }}
              <span class="ds-segment-light">
                ({{ getDepartureTime(`${segment.arrival_datetime}`) }})
              </span>
            </div>
            <div class="ds-segment-caption">
              ({{ segment.destination.iata_code }})
              {{ segment.destination.airport }}
            </div>
          </div>
        </div>
      </div>

      <div class="ds-layover" v-if="haveLayOff(index)">
        <MDivider class="ds-divider" />

        <div class="ds-layover-details">
          <MIcon class="dsl-icon" name="m-timer" width="16" height="16" />

          <div>{{ renderLayoffTime(index, segment) }}</div>
        </div>

        <MDivider class="ds-divider" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { MDivider, MIcon } from "@aeroglobe/ag-core-ui";
import { Segment } from "@/ag-flight-components/types";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";

export default defineComponent({
  name: "FlightSegments",
  components: { MDivider, MIcon },
  props: {
    segments: {
      type: Array as PropType<Segment[]>,
      required: true,
    },
  },
  methods: {
    haveLayOff(index: number): boolean {
      return index + 1 < this.segments.length;
    },
    getDepartureTime(departureTime: string): string {
      return TimeUtility.parsedFlightTimeorDate(
        departureTime,
        "EE, MMM dd, yyyy"
      );
    },
    getFormattedTime(time: string): string {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    getDuration(duration: number): string {
      return TimeUtility.getDurationInTextByMinutes(duration);
    },
    getNextDepartureTime(index: number): Date {
      return this.haveLayOff(index)
        ? this.segments[index + 1].departure_datetime
        : new Date();
    },
    renderLayoffTime(index: number, segment: Segment): string {
      const nextDepartureTime = this.getNextDepartureTime(index);
      const duration = TimeUtility.getTotalDurationInText(
        segment.arrival_datetime,
        nextDepartureTime
      );

      return `Stop at (${segment.destination.iata_code}) • ${segment.destination.airport} • ${duration}`;
    },
  },
});
</script>

<style scoped lang="css">
.ds-segments {
  color: var(--m-secondary-color);
}

.ds-segments .ds-segment {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;

  margin: 32px 0;
}
.ds-airline {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: normal;
}
.ds-airline .ds-airline-image {
  width: 24px;
  height: 24px;
}

.ds-segments .ds-segment::before {
  content: " ";

  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);

  width: 2px;
  height: calc(100% - (48px));

  z-index: -1;
  background-color: var(--m-primary-color);
}

.ds-segments .ds-segment .ds-segment-title {
  display: flex;
  align-items: center;
  gap: 12px;
}
.ds-segments .ds-segment .ds-segment-title .ds-segment-light {
  font-size: 14px;
  color: var(--m-label-color);
}
.ds-segments .ds-segment .ds-segment-title .ds-segment-caption {
  font-size: 12px;
}
.ds-segments .ds-segment .ds-segment-subtitle {
  font-size: 14px;
  font-weight: 500;
  padding-left: 26px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ds-segment-icon {
  width: 14px;
  height: 14px;
}
.ds-segment-icon.start .inner {
  fill: var(--m-primary-color);
}
.ds-segment-icon.start .center {
  fill: transparent;
}
.ds-segment-icon.start .outer {
  fill: transparent;
}
.ds-segment-icon.end .inner {
  fill: var(--m-primary-color);
}
.ds-segment-icon.end .center {
  fill: var(--m-light-color);
}
.ds-segment-icon.end .outer {
  fill: var(--m-primary-color);
}

.ds-layover {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.ds-layover .ds-divider {
  position: relative;
  width: 100%;

  border-style: dashed;
}
.ds-layover .ds-divider::before {
  content: "";

  position: absolute;
  left: -4px;
  top: -4px;

  width: 8px;
  height: 8px;
  border-radius: 4px;

  background-color: var(--m-divider-color);
}
.ds-layover .ds-divider::after {
  content: "";

  position: absolute;
  right: -4px;
  top: -4px;

  width: 8px;
  height: 8px;
  border-radius: 4px;

  background-color: var(--m-divider-color);
}
.ds-layover .ds-layover-details {
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 0 20px;
  font-size: 14px;
}
.ds-layover .dsl-icon {
  width: 16px;
  height: 16px;

  color: var(--m-primary-color);
}
</style>
