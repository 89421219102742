import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7492273c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_ShimmerCard = _resolveComponent("ShimmerCard")!
  const _component_OrganizationForm = _resolveComponent("OrganizationForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MCard, null, {
      default: _withCtx(() => [
        _createVNode(_component_AgIconInfoBar, {
          "test-id": "",
          title: "Create Organization",
          class: "section-title",
          mPrepandIcon: "m-organisations"
        })
      ]),
      _: 1
    }),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ShimmerCard, {
          key: 0,
          cardHeight: "400px"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_OrganizationForm, {
            onOnSubmitOrganizationForm: _ctx.createOrganization,
            onLoading: _ctx.$store.getters.isCreatingOrganization,
            isCreateOrganization: true
          }, null, 8, ["onOnSubmitOrganizationForm", "onLoading"])
        ]))
  ], 64))
}