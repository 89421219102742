import { Module } from "vuex";
import { StoreState } from "@/store/type";

import mutations from "./mutations";
import actions from "./actions";
import state, { IDealsState } from "./state";
import getters from "./getters";

const IDealsAndRefundModule: Module<IDealsState, StoreState> = {
  state,
  mutations,
  actions,
  getters,
};

export default IDealsAndRefundModule;
