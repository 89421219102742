import { IconName } from "@aeroglobe/ag-core-ui/dist/src/components/material/icons/icons.type";
import { Filter, FilterType } from "../types";

export class FlightFilters {
  price: Filter;
  stops: Filter[];
  times: Filter[];
  airlines: Filter[];

  constructor() {
    this.price = {
      label: "Price",
      value: false,
      count: 0,
      type: FilterType.PRICE,
    };
    this.stops = [];
    this.times = [];
    this.airlines = [];
  }

  get activeFilters(): Filter[] {
    let activeFilters: Filter[] = [];

    if (this.price.count > 0) {
      activeFilters.push(this.price);
    }

    activeFilters = activeFilters.concat(
      this.stops.filter((stop) => stop.value),
      this.times.filter((time) => time.value),
      this.airlines.filter((airline) => airline.value)
    );

    return activeFilters;
  }

  addFilter(
    type: FilterType,
    label: string,
    value = false,
    count = 0,
    icon?: IconName
  ): void {
    const filter: Filter = {
      label,
      value,
      count,
      type,
      icon,
    };

    switch (type) {
      case FilterType.PRICE:
        this.price = filter;
        break;
      case FilterType.STOPS:
        this.stops.push(filter);
        break;
      case FilterType.TIMES:
        this.times.push(filter);
        break;
      case FilterType.AIRLINES:
        this.airlines.push(filter);
        break;
    }
  }

  clearPrice(): void {
    this.price.count = 0;
  }

  clearAirlines(): void {
    this.airlines = [];
  }

  updatePrice(price: number): void {
    this.price.count = price;
  }

  toggleStops(state: boolean): void {
    this.stops = this.stops.map((stop) => ({
      ...stop,
      value: state,
    }));
  }

  toggleTimes(state: boolean): void {
    this.times = this.times.map((time) => ({
      ...time,
      value: state,
    }));
  }

  toggleAirlines(state: boolean): void {
    this.airlines = this.airlines.map((airline) => ({
      ...airline,
      value: state,
    }));
  }

  resetAll(): void {
    this.clearPrice();
    this.toggleStops(false);
    this.toggleTimes(false);
    this.toggleAirlines(false);
  }

  removeFilterByIndex(index: number): void {
    const activeFilter = this.activeFilters[index];

    switch (activeFilter.type) {
      case FilterType.PRICE:
        this.price.count = 0;
        break;
      case FilterType.STOPS:
        this.stops = this.stops.map((stop) =>
          stop.label === activeFilter.label ? { ...stop, value: false } : stop
        );
        break;
      case FilterType.TIMES:
        this.times = this.times.map((time) =>
          time.label === activeFilter.label ? { ...time, value: false } : time
        );
        break;
      case FilterType.AIRLINES:
        this.airlines = this.airlines.map((airline) =>
          airline.label === activeFilter.label
            ? { ...airline, value: false }
            : airline
        );
        break;
    }
  }
}
