<template>
  <MDialog v-model="isMdialogOpen">
    <template #content>
      <div class="delete-agent-alert">
        <MIcon
          class="delete-agent-alert-icon"
          name="m-info"
          width="48"
          height="48"
        />
        <div class="delete-agent-alert-title">Price Updated</div>
        <div class="delete-agent-alert-subtitle">
          New price for this booking is <b>{{ updatedFare }}</b>
        </div>
        <div class="delete-agent-alert-subtitle">
          Please verify before issuance.
        </div>
      </div>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";
import { ExtendedPrice, FlightDetailsType } from "@/ag-flight-components/types";
import { MDialog } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "PriceUpdatedDialog",
  data() {
    return {
      isMdialogOpen: this.isOpen,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    handleAccpet: {
      type: Function,
      required: true,
    },
  },
  computed: {
    flightDetails(): FlightDetailsType {
      return this.$store.getters.flightDetails;
    },
    fare(): ExtendedPrice | null {
      const flightDetails = this.flightDetails;
      return flightDetails && flightDetails.ag_total_amount
        ? flightDetails.ag_total_amount
        : null;
    },
    updatedFare(): string {
      return getCurrencyFormatter(this.fare?.currency).format(
        this.fare?.value || 0
      );
    },
  },

  watch: {
    isOpen(newVal) {
      this.isMdialogOpen = newVal;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("closeModal");
      }
    },
  },
});
</script>

<style scoped lang="css">
.delete-agent-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 16px;

  padding: 8px;
}

.delete-agent-alert .delete-agent-alert-icon {
  width: 48px;
  height: 48px;

  color: var(--m-error-color);
}
.delete-agent-alert .delete-agent-alert-title {
  font-size: 24px;
  font-weight: 500;
}
.delete-agent-alert .delete-agent-alert-subtitle {
  color: var(--m-label-color);
}
</style>
