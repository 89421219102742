import { StatusCodes } from "http-status-codes";
import {
  FIRST_POLLING_TIMEOUT,
  MAX_POLLING_LIMIT,
  MAX_POLLING_TIMEOUT,
} from "../constants";
import { PollingCallback, PollingData, PollingEmitCallback } from "../types";
import { IAGErrorResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";

export class PollingService<T extends PollingData, Q> {
  public isLoading = false;
  public keepPolling = true;
  public cancelPolling = false;

  private attempts = 0;
  private result: T | null = null;

  constructor(
    private query: Q,
    private pollingCallback: PollingCallback<T, Q>,
    private maxPollingLimit: number = MAX_POLLING_LIMIT,
    private pollingInterval: number = MAX_POLLING_TIMEOUT,
    private firstPollInterval: number = FIRST_POLLING_TIMEOUT
  ) {}

  get canPoll(): boolean {
    return (
      this.keepPolling &&
      this.attempts < this.maxPollingLimit &&
      !this.cancelPolling
    );
  }

  public updateQuery(query: Q, pollingCallback: PollingCallback<T, Q>): void {
    this.query = query;
    this.pollingCallback = pollingCallback;
  }

  public async startPolling(emit: PollingEmitCallback<T>): Promise<void> {
    this.keepPolling = true;
    this.cancelPolling = false;

    while (this.canPoll) {
      await this.poll();

      this.attempts++;

      emit(this.result);

      if (this.canPoll) {
        const pollingInterval =
          this.attempts === 1 ? this.firstPollInterval : this.pollingInterval;

        await this.delay(pollingInterval);
      }
    }

    this.resetPolling();
  }

  public async cancelCurrentPolling(
    emit: PollingEmitCallback<T>
  ): Promise<void> {
    this.cancelPolling = true;

    if (this.isLoading) {
      await new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (!this.isLoading) {
            clearInterval(interval);
            resolve();
          }
        }, 50);
      });
    }

    this.resetPolling();
    emit(null);
  }

  private async poll(): Promise<void> {
    this.isLoading = true;
    try {
      const response = await this.pollingCallback(this.query);

      if (response.success && response.status === StatusCodes.OK) {
        this.result = response.data.data;
        this.keepPolling = this.result.keep_polling;
      } else {
        throw response;
      }
    } catch (error: unknown) {
      this.handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  private resetPolling(): void {
    this.isLoading = false;
    this.keepPolling = false;
    this.attempts = 0;
    this.result = null;
    this.cancelPolling = false;
  }

  private handleError(error: unknown): void {
    const exception = error as IAGErrorResponse;

    notificationService.type = NOTIFICATION_TYPES.ERROR;
    notificationService.description =
      exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    notificationService.triggerNotification();

    this.resetPolling();
  }

  private delay(ms: number): Promise<unknown> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
