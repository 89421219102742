<template>
  <my-bookings-filters-view
    v-show="showFilters"
    @fetchBookings="onUpdateFilter"
  />

  <div>
    <MDataTable
      :is-api-paginated="true"
      :total-item-count="totalPageCount"
      :headers="headers"
      :item-per-page="30"
      :data="bookings"
      :is-loading="$store.getters.isFetchingFlightBookings"
      @onPageChange="onPageChange"
    >
      <template #bookingId="{ item }">
        <router-link
          :data-test-id="
            genTestId(ELEMENT_TYPES.LINK, `flight-booking-${item.id}`)
          "
          :to="
            formatStringToRoutePath(PATH.FLIGHTS_BOOKING_DETAILS, {
              id: item.id,
            })
          "
        >
          <MTypography class="description td-full-width booking-id" type="body">
            {{ item.bookingId }}
          </MTypography>
        </router-link>
      </template>

      <template #travelers="{ item }">
        <div class="text-center" v-if="isInitiated(item?.status)">-</div>

        <div class="td-full-width" v-else>
          <AGButtonDropDown
            class="table_dropdown_wrap"
            text="Test"
            :items="item?.travelers"
          >
            <template #label>
              <div v-if="!hasTravelers(item.travelers)">-</div>
              <i v-else>
                <MTypography class="description td-full-width" type="label">
                  {{ item.travelers[0] }}
                </MTypography></i
              >
            </template>
            <div v-if="hasTravelers(item.travelers)" class="td-full-width">
              <AGDropDownButtonItem
                class="dropdown-width"
                v-for="(dditem, index) in item?.travelers"
                :key="index"
                :name="dditem"
              />
            </div>
          </AGButtonDropDown>
        </div>
      </template>

      <template #sector="{ item }">
        <MTypography class="description td-full-width" type="label">
          {{ item.sector }}
        </MTypography>
      </template>

      <template #supplier="{ item }">
        <MTypography class="description td-full-width" type="label">
          {{ airlineSupplier(item.supplier) }}
        </MTypography>
      </template>
      <template #bookingLogs="{ item }" v-if="isOperationsOrSuperUser">
        <div class="btn-container">
          <MButton
            variant="primary"
            type="filled"
            @click="onViewBookingLogs(item.bookingId)"
          >
            View More
          </MButton>
        </div>
      </template>

      <template #issuanceLogs="{ item }" v-if="isOperationsOrSuperUser">
        <div class="btn-container">
          <MButton
            class="issuance-log-btn"
            variant="primary"
            type="filled"
            @click="onNavigateToIssuanceLogPage(item.pnr)"
          >
            View Issuance Logs
          </MButton>
        </div>
      </template>

      <template #bookingTime="{ item }">
        <MTypography class="description td-full-width" type="body">
          {{
            getFormattedDateTime(item.bookingTime, FORMAT_DD_MMM_YYYY_WITH_DASH)
          }}
        </MTypography>
        <MTypography class="description" type="label">
          {{ getFormattedDateTime(item.bookingTime, FORMAT_HH_SS_24_HOURS) }}
        </MTypography>
      </template>

      <template #pnr="{ item }">
        <div class="text-center" v-if="isInitiated(item?.status)">-</div>
        <MTypography
          class="description td-full-width"
          type="label"
          v-else-if="item.pnr"
        >
          {{ item.pnr }}
        </MTypography>
        <MTypography type="body" v-else>-</MTypography>
      </template>

      <template #travelDate="{ item }">
        <MTypography class="description td-full-width" type="body">
          {{
            getFormattedDateTime(item.travelDate, FORMAT_DD_MMM_YYYY_WITH_DASH)
          }}
        </MTypography>
        <MTypography class="description" type="label">
          {{ getFormattedDateTime(item.travelDate, FORMAT_HH_SS_24_HOURS) }}
        </MTypography>
      </template>

      <template #status="{ item }">
        <div class="status-container">
          <MChip :variant="bookingStatus(item.status)">{{ item.status }}</MChip>
        </div>
      </template>

      <template #status_remarks="{ item }">
        <div class="status-container">
          <MTypography
            class="description"
            type="label"
            v-if="isPNRExpiryNotAvailable(item)"
          >
            PNR Expiry will be available soon
          </MTypography>
          <MTypography
            class="description expired"
            type="label"
            v-else-if="isPNRExpired(item)"
          >
            Expired
          </MTypography>
          <div v-else-if="!checkStatus(item)" class="status_msg expires_in_msg">
            Expires in <br />
            <AgDuration
              :to="
                  getConfirmOrInitiateDate(item.status, item.initiatedBookingExpiresAt, item.confirmedBookingExpiresAt) as Date
                "
            />
          </div>
          <div v-else class="text-align-center">-</div>
        </div>
      </template>
      <template #brandTicket="{ item }">
        <MFabButton
          type="outlined"
          icon="m-download"
          @click="downloadBrandTicket(item.bookingId, item.pnr)"
          :disabled="disableBrandTicket(item.booking_actions)"
        >
        </MFabButton>
      </template>

      <template #airlineTicket="{ item }">
        <MFabButton
          type="outlined"
          icon="m-send"
          :disabled="disableAirlineTicket(item.booking_actions)"
          @click="onClickAirlineTicket(item)"
        >
        </MFabButton>
      </template>
    </MDataTable>
    <div test-id="" class="text-center" v-if="isDashboardView">
      <MButton variant="primary" type="filled" @click="handleViewMore">
        View More
      </MButton>
    </div>
    <airline-ticket-modal
      :show-modal="showAirlineTicketModal"
      @onSendAirlineTicket="onSendAirlineTicket"
      @closeModal="showAirlineTicketModal = false"
    />
  </div>

  <ApiResponseJsonViewerModal
    :is-open="isOpen"
    :json-data="jsonAPIResponse"
    @handleClose="closeModal"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  formatStringToRoutePath,
  genTestId,
  getFormattedDateTime,
  isDateExpired,
} from "@/ag-portal-common/utils/helpers";
import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_HH_SS_24_HOURS,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { ALLOWED_SUPPLIERS } from "@/ag-portal-common/constants/allowedSuppliers";
import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { FetchBookingsParamsRequestModel } from "@/modules/MyBookings/models/fetchBookingsParams.request";
import MyBookingsFiltersView from "@/modules/MyBookings/components/MyBookingsFilters.vue";
import AirlineTicketModal from "@/modules/MyBookings/components/AirlineTicketModal.vue";
import { IFlightBookings } from "@/interfaces/flightBookings.interface";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import AgDuration from "@/ag-portal-common/components/AgDuration.vue";
import { SendAirlineTicketBodyRequest } from "@/modules/MyBookings/models/sendAirlineTicketBody.request";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { PATH } from "@/ag-portal-common/constants/path";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import MyBookingsService from "@/modules/MyBookings/services/myBookings.service";

import analyticsService from "@/analytic.service";
import { MY_BOOKING_ANALYTICS_EVENTS } from "@/modules/MyBookings/constants/anaylticsEvents";
import {
  MButton,
  MDataTable,
  MChip,
  MFabButton,
  MTypography,
} from "@aeroglobe/ag-core-ui";
import { ChipVariant } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import { BOOKING_STATUSES_MAPPING } from "../constants";
import { MDataTableHeader } from "@/ag-portal-common/types";
import ApiResponseJsonViewerModal from "./ApiResponseJsonViewerModal.vue";
import UTILS from "@/ag-portal-common/utils";
import { bookingActions } from "@/ag-flight-components/types";

export default defineComponent({
  name: "FlightBookings",
  components: {
    AgDuration,
    AirlineTicketModal,
    MyBookingsFiltersView,
    MDataTable,
    MChip,
    MFabButton,
    MButton,
    ApiResponseJsonViewerModal,
  },
  props: {
    isDashboardView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isOperationsOrSuperUser(): boolean {
      return UTILS.isUser(USER_ROLES.OPERATIONS, USER_ROLES.AG_SUPER_USER);
    },

    isIssued(): string {
      return BOOKING_STATUS.ISSUED.toUpperCase();
    },
    isCanceled(): string {
      return BOOKING_STATUS.CANCELED.toUpperCase();
    },
    showFilters(): boolean {
      return this.isDashboardView === false;
    },
    showTable(): boolean {
      return (
        !this.$store.getters.isFetchingFlightBookings &&
        this.$store.getters.flightBookings.bookings.length
      );
    },
    hasPagination(): boolean {
      return this.isDashboardView ? false : true;
    },
    PATH(): typeof PATH {
      return PATH;
    },
    jsonAPIResponse(): string {
      return this.apiResponse ? this.apiResponse : {};
    },
    totalPageCount(): number {
      return this.$store.getters.flightBookings.count;
    },
    bookings(): {
      status_remarks: string;
      id: string;
      bookingId: string;
      travelers: string[];
      initiatedBookingExpiresAt: Date;
      confirmedBookingExpiresAt: Date | null;
      sector: string;
      bookingTime: string;
      pnr: string;
      supplier: string;
      travelDate: string;
      status: string;
    }[] {
      const bookings = this.$store.getters.flightBookings
        .bookings as IFlightBookings[];

      return bookings.map((booking: IFlightBookings) => {
        return {
          ...booking,
          status_remarks: booking.status,
        };
      });
    },
  },
  created() {
    if (this.isOperationsOrSuperUser) {
      this.headers.push(
        {
          title: "Booking Logs",
          value: "bookingLogs",
          key: "bookingLogs",
          sortable: false,
        },
        {
          title: "Issuance Logs",
          value: "issuanceLogs",
          key: "issuanceLogs",
          sortable: false,
        }
      );
    }
  },
  beforeMount() {
    const isNewFetchData = false;
    this.fetchBookings(isNewFetchData, this.setItemsPerPage);
  },
  data(): {
    apiResponse: any;
    ELEMENT_TYPES: typeof ELEMENT_TYPES;
    activeBooking: IFlightBookings | null;
    showAirlineTicketModal: boolean;
    itemsPerPage: number;
    selectedId: string;
    headers: MDataTableHeader[];
    fetchBookingParams: FetchBookingsParamsRequestModel;
    NOTIFICATION_MESSAGES: IObject;
    activeBookingIdForBrandTicket: string;
    isBookingLogLoading: boolean;
    isOpen: boolean;
    FORMAT_DD_MMM_YYYY_WITH_DASH: string;
    FORMAT_HH_SS_24_HOURS: string;
  } {
    return {
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
      FORMAT_HH_SS_24_HOURS: FORMAT_HH_SS_24_HOURS,
      NOTIFICATION_MESSAGES,
      apiResponse: null,
      ELEMENT_TYPES,
      isOpen: false,
      itemsPerPage: 0,
      selectedId: "",
      isBookingLogLoading: false,
      fetchBookingParams: {
        page: 1,
        start: null,
        end: null,
        search: "",
        booking_type: BOOKING_TYPES.FLIGHT,
      },
      activeBooking: null,
      activeBookingIdForBrandTicket: "",
      showAirlineTicketModal: false,
      headers: [
        {
          title: "Booking ID",
          value: "bookingId",
          key: "bookingId",
          sortable: false,
        },
        {
          title: "PNR",
          value: "pnr",
          key: "pnr",
          sortable: false,
        },
        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: false,
        },
        {
          title: "Status Remarks",
          value: "status_remarks",
          key: "status_remarks",
          sortable: false,
        },
        {
          title: "Travelers",
          value: "travelers",
          key: "travelers",
          sortable: false,
        },
        {
          title: "Sector",
          value: "sector",
          key: "sector",
          sortable: false,
        },
        {
          title: "Booking Time",
          value: "bookingTime",
          key: "bookingTime",
          sortable: false,
        },

        {
          title: "Supplier",
          value: "supplier",
          key: "supplier",
          sortable: false,
        },
        {
          title: "Travel Date",
          value: "travelDate",
          key: "travelDate",
          sortable: false,
        },

        {
          title: "Agent",
          value: "agent",
          key: "agent",
          sortable: false,
        },

        {
          title: "Brand Ticket",
          value: "brandTicket",
          key: "brandTicket",
          sortable: false,
        },
        {
          title: "Airline Ticket",
          value: "airlineTicket",
          key: "airlineTicket",
          sortable: false,
        },
      ] as MDataTableHeader[],
    };
  },
  methods: {
    formatStringToRoutePath,
    genTestId,
    isDateExpired,
    getFormattedDateTime,
    airlineSupplier(supplierName: string) {
      return supplierName.replace("_", " ");
    },
    hasTravelers(travelers: string[]) {
      if (travelers?.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    onNavigateToIssuanceLogPage(pnr: string | null) {
      const PNR = pnr ?? "";
      const path = this.formatStringToRoutePath(PATH.ISSUANCE_LOGS, {
        pnr: PNR,
      });

      const { href } = this.$router.resolve(path);

      window.open(href, "_blank");
    },

    closeModal() {
      this.isOpen = false;
    },
    async onViewBookingLogs(id: string) {
      this.selectedId = id;
      this.isOpen = false;
      this.apiResponse = null;
      this.isBookingLogLoading = true;
      const myBookingsService = new MyBookingsService();
      const response = await myBookingsService.fetchBookingLogs(id);
      this.apiResponse = response;
      if (response) {
        this.isBookingLogLoading = false;
        this.isOpen = true;
        this.selectedId = "";
      }
    },

    onPageChange(num: number) {
      let nextPage = this.$store.getters.flightBookings.next;
      let prevPage = this.$store.getters.flightBookings.previous;
      let currentPage: number = this.fetchBookingParams.page || 1;
      if ((num > currentPage && nextPage) || (currentPage > num && prevPage)) {
        this.fetchBookingParams.page = num;
        const isNewFetchData = true;
        this.fetchBookings(isNewFetchData);
      }
    },
    setItemsPerPage() {
      let bookingsLength = this.$store.getters.flightBookings.bookings.length;
      this.itemsPerPage = bookingsLength;
    },
    onUpdateFilter(filters: FetchBookingsParamsRequestModel) {
      this.fetchBookingParams = {
        ...filters,
        booking_type: this.fetchBookingParams.booking_type,
        page: 1,
      };
      const isNewFetchData = true;
      this.fetchBookings(isNewFetchData);
    },
    getConfirmOrInitiateDate(
      status: BOOKING_STATUS,
      initiatedBookingExpiresAt: Date,
      confirmedBookingExpiresAt: null | Date
    ) {
      if (status === BOOKING_STATUS.CONFIRMED.toUpperCase())
        return confirmedBookingExpiresAt || "Invalid Date";
      return initiatedBookingExpiresAt;
    },
    onSendAirlineTicket(emails: string[]) {
      this.$store.dispatch("sendAirlineTicket", {
        payload: {
          pnr: this.activeBooking?.pnr,
          airline_provider: this.activeBooking?.supplier,
          emails,
        } as SendAirlineTicketBodyRequest,
        callback: () => (this.showAirlineTicketModal = false),
      });
    },
    downloadBrandTicket(bookingId: string, pnr: string) {
      this.activeBookingIdForBrandTicket = bookingId;
      this.$store.dispatch("downloadBrandTicket", { bookingId, pnr });
    },
    onClickAirlineTicket(booking: IFlightBookings) {
      this.activeBooking = booking;
      this.showAirlineTicketModal = true;
    },
    fetchBookings(isFetchNewData = false, callBack?: () => void) {
      const payload = this.fetchBookingParams;

      let eventName = MY_BOOKING_ANALYTICS_EVENTS.FLIGHTS_SEARCH;

      analyticsService.logActionEvent(eventName, payload);

      this.$store.dispatch("fetchFlightBookings", {
        payload,
        isFetchNewData,
        callBack,
      });
    },
    formattedDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY_WITH_DASH);
    },
    formattedTime(date: Date) {
      return getFormattedDateTime(date, FORMAT_HH_SS_24_HOURS);
    },
    showDownloadAirlineTicketButton(status: string, supplier: string) {
      const isAllowedSupplier = ALLOWED_SUPPLIERS.includes(supplier);
      return (
        status === BOOKING_STATUS.ISSUED.toUpperCase() && isAllowedSupplier
      );
    },
    disableBrandTicket(bookingAction: bookingActions) {
      return !bookingAction.is_download_ticket;
    },
    disableAirlineTicket(bookingAction: bookingActions) {
      return !bookingAction.is_airline_ticket_enabled;
    },
    handleViewMore() {
      this.$router.push(PATH.MY_BOOKINGS);
    },
    isInitiated(status: BOOKING_STATUS) {
      return status === BOOKING_STATUS.INITIATED.toUpperCase();
    },
    isPNRExpiryNotAvailable(item: IFlightBookings) {
      const isNotIssued = item.status !== this.isIssued;
      const isNotInitiated = !this.isInitiated(item.status as BOOKING_STATUS);
      const isNotCanceled = item.status !== this.isCanceled;
      return (
        item.confirmedBookingExpiresAt === null &&
        isNotIssued &&
        isNotInitiated &&
        isNotCanceled
      );
    },
    isPNRExpired(item: IFlightBookings) {
      if (BOOKING_STATUS.INITIATED.toUpperCase() === item.status) {
        return false;
      } else if (BOOKING_STATUS.CANCELED.toUpperCase() === item.status) {
        return true;
      }
      return (
        this.isDateExpired(
          this.getConfirmOrInitiateDate(
            item.status as BOOKING_STATUS,
            item.initiatedBookingExpiresAt,
            item.confirmedBookingExpiresAt
          ) as Date
        ) && item.status !== this.isIssued
      );
    },
    bookingStatus(status: BOOKING_STATUS): ChipVariant {
      return BOOKING_STATUSES_MAPPING[status] as ChipVariant;
    },
    checkStatus(item: IFlightBookings): boolean {
      return [
        BOOKING_STATUS.CANCELED.toUpperCase(),
        BOOKING_STATUS.ISSUED.toUpperCase(),
        BOOKING_STATUS.INITIATED.toUpperCase(),
      ].includes(item.status);
    },
  },
});
</script>

<style lang="scss" scoped>
.booking-id {
  color: var(--green-color);
}
.sector-chip-container {
  width: 175px;
  display: flex;
  justify-content: center;
}

.status-container {
  width: 160px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.status_msg {
  font-size: 12px;
  line-height: 1.4;
  display: inline;
  width: 110px;
  text-align: center;
  font-weight: 500;
}
.expired {
  font-weight: 500;
  color: red;
}
.expires_in_msg {
  color: #ff8600;
  width: 150px;
}
.supplier-container {
  text-align: center;
}
.view-more-btn {
  width: 150px;
}
.issuance-log-btn {
  width: 200px;
}
.text-align-center {
  text-align: center;
}
.width-150 {
  width: 150px;
}
.booking-time {
  margin-left: 5px;
}
.btn-container {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
