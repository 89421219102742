import { Module } from "vuex";
import { IHotelSearch } from "../interfaces/hotels.interface";
import { StoreState } from "@/store/type";

import mutations from "./mutations";
import actions from "./actions";
import state from "./state";
import getters from "./getters";

const hotelModule: Module<IHotelSearch, StoreState> = {
  state,
  mutations,
  actions,
  getters,
};

export default hotelModule;
