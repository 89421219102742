import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4156598f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "penalty-details-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_MAccordion = _resolveComponent("MAccordion")!

  return (_openBlock(), _createBlock(_component_MAccordion, { "initial-open": true }, {
    title: _withCtx(() => [
      _createVNode(_component_AgIconInfoBar, {
        "test-id": "",
        mPrepandIcon: "m-penalty",
        title: "Penalties",
        class: "section-title"
      })
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.penalties, (penalty, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `penalty-${index}`
          }, [
            _createVNode(_component_MChip, {
              variant: "success",
              class: "refund-penalties"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(penalty.departure_city_code) + " - " + _toDisplayString(penalty.arrival_city_code), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_MDataTable, {
              headers: _ctx.penaltyTableHeaders,
              "is-loading": false,
              data: penalty.penalty_details,
              "items-per-page": 5,
              "has-search": false,
              simpleDataTable: true,
              "has-pagination": false
            }, {
              amount: _withCtx(({ item }) => [
                _createVNode(_component_MTypography, {
                  class: "description",
                  type: "label"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formattedCurrency(item.currency, item.amount)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["headers", "data"])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}