<template>
  <MDialog
    title="Delete Organization"
    v-model="isMdialogOpen"
    @click="$emit('handleClose')"
  >
    <template #content>
      <MTypography
        >Are you sure to delete
        {{ organization_name }} organization?</MTypography
      >
    </template>
    <template #actions>
      <MButton type="outlined" @click="$emit('handleClose')"> No </MButton>
      <MButton :disabled="isLoading" @click="$emit('handleAccept')">
        Yes
      </MButton>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MTypography, MButton } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "DeleteOrganizationConfirmationDialog",
  components: { MTypography, MButton },
  data() {
    return {
      isMdialogOpen: this.isOpen,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    organization_name: {
      type: String,
      default: "",
      required: true,
    },
  },
  computed: {
    isLoading(): boolean {
      return this.$store.getters.isUpdatingOrganization;
    },
  },
  watch: {
    isOpen(newVal) {
      this.isMdialogOpen = newVal;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("closeModal");
      }
    },
  },
});
</script>

<style lang="css" scoped>
.delete-organization-btn-container {
  display: flex;
  float: right;
}
.delete-organization-reject_btn {
  margin-right: 10px;
  background-color: var(--m-error-color);
}
.delete-organization-footer {
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
}
</style>
