<template>
  <AgCard class="flightFilterWrap">
    <AgDiv class="head"> Filter </AgDiv>
    <!-- **** Price Range **** -->
    <AgDiv class="d-flex">
      <AgFilterDropdown test-id="" label="Price Range (PKR)">
        <template #Items>
          <AgPriceRange
            @update:rangeSliderValue="handleUpdateRange"
            :min="localFilters.priceRange[0]"
            :max="localFilters.priceRange[1]"
            :thumb-size="20"
            thumb-label="always"
            test-id=""
          />
        </template>
      </AgFilterDropdown>

      <!-- **** Airline **** -->
      <AgFilterDropdown test-id="" label="Airlines">
        <template #Items>
          <AgCheckbox
            v-for="(item, index) in filters.airlines"
            @click.stop=""
            v-model="localFilters.airlines"
            :key="index"
            :value="item.value"
            :label="getAirlineLabelHandler(item)"
            test-id=""
          />
        </template>
      </AgFilterDropdown>

      <!-- **** Stops **** -->
      <AgFilterDropdown test-id="" label="Stops">
        <template #Items>
          <AgCheckbox
            v-for="(item, index) in stopsFilters"
            @click.stop=""
            v-model="stopsFilter"
            :key="index"
            :value="item.value"
            :label="item.label"
            test-id=""
          ></AgCheckbox>
        </template>
      </AgFilterDropdown>

      <!-- **** Departure Time **** -->
      <AgFilterDropdown test-id="" label="Departure Time">
        <template #Items>
          <AgCheckbox
            v-for="(item, index) in filters.departureTime"
            v-model="localFilters.departureTime"
            @click.stop=""
            :key="index"
            :value="item"
            :label="item"
            test-id=""
          ></AgCheckbox>
        </template>
      </AgFilterDropdown>
    </AgDiv>

    <!-- **** Change Search **** -->
    <AGButton
      test-id=""
      @click="handleChangeSearchDialogOpen"
      variant="link"
      :disabled="isLoading"
      class="change_search_btn_modal"
      >Change Search</AGButton
    >
  </AgCard>
</template>

<script lang="ts">
import {
  FlightAirlneBaseFilter,
  FlightBaseFilter,
  FlightFilters,
  ReturnFlightPairStop,
  SelectedFlightSearchQueryFilters,
} from "@/ag-flight-components/types/Flights";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "FlightFilters",
  props: {
    selectedFilters: {
      type: Object as PropType<SelectedFlightSearchQueryFilters>,
      required: true,
    },
    handleUpdateRange: {
      type: Function,
      required: true,
    },
    handleChangeSearchDialogOpen: {
      type: Function,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isPairView: {
      type: Boolean,
      default: false,
    },
    returnFlightPairsStops: {
      type: Array as PropType<ReturnFlightPairStop[]>,
    },
  },
  computed: {
    filters(): FlightFilters {
      return this.$store.getters.filters;
    },
    stopsFilters(): ReturnFlightPairStop[] | FlightBaseFilter[] | undefined {
      if (this.isPairView) {
        return this.returnFlightPairsStops;
      } else {
        return this.filters.stops;
      }
    },
    stopsFilter: {
      get(): string[] | number[][] {
        return this.isPairView
          ? this.localFilters.rtStops
          : this.localFilters.stops;
      },
      set(value: string[] | number[][]) {
        if (this.isPairView) {
          this.localFilters.rtStops = value as number[][];
        } else {
          this.localFilters.stops = value as string[];
        }
      },
    },
    localFilters(): SelectedFlightSearchQueryFilters {
      return this.selectedFilters;
    },
  },
  watch: {
    localFilters: {
      handler(newFilters: SelectedFlightSearchQueryFilters) {
        this.$emit("updateSelectedFilters", newFilters);
      },
      deep: true,
    },
  },
  methods: {
    getAirlineLabelHandler(item: FlightAirlneBaseFilter) {
      return this.isPairView ? item.labelRt ?? item.label : item.label;
    },
  },
});
</script>
