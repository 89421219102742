import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MProgress = _resolveComponent("MProgress")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MTextfield = _resolveComponent("MTextfield")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_MDataTable = _resolveComponent("MDataTable")!

  return (_ctx.$store.getters.isCurrenciesLoading)
    ? (_openBlock(), _createBlock(_component_MProgress, {
        key: 0,
        class: "mprogress-alignment",
        indeterminate: ""
      }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_MCard, { class: "m-card-currency-conversion" }, {
          default: _withCtx(() => [
            _createVNode(_component_AgIconInfoBar, {
              "test-id": "",
              title: "Currency Conversion",
              class: "section-title",
              mPrepandIcon: "m-currency-conversion"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_MCard, { class: "m-card-currency-conversion" }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ag_column, {
                  xs: "12",
                  sm: "12",
                  md: "6",
                  lg: "5"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ag_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_MTypography, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Convert PKR amount into other currencies")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ag_column, {
                          xs: "12",
                          sm: "12",
                          md: "6",
                          lg: "8"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createVNode(_component_MTextfield, {
                                type: "number",
                                inputValue: _ctx.pkrAmount,
                                "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pkrAmount) = $event)),
                                label: "PKR Amount"
                              }, null, 8, ["inputValue"]),
                              _withDirectives(_createElementVNode("p", { class: "converted-amount" }, _toDisplayString(_ctx.convertPkrToCurrency()), 513), [
                                [_vShow, _ctx.convertPkrToCurrency()]
                              ])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ag_column, {
                          xs: "12",
                          sm: "12",
                          md: "6",
                          lg: "4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_MCombobox, {
                              inputValue: _ctx.selectedCurrency1,
                              "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCurrency1) = $event)),
                              label: "Currency",
                              itemValue: "value",
                              itemLabel: "label",
                              options: _ctx.currencyItemsPKRToCurrencies,
                              errorMessage: "Required",
                              name: "Currency"
                            }, null, 8, ["inputValue", "options"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ag_column, {
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "2"
                }),
                _createVNode(_component_ag_column, {
                  xs: "12",
                  sm: "12",
                  md: "6",
                  lg: "5"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ag_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_MTypography, null, {
                          default: _withCtx(() => [
                            _createTextVNode(" Convert other currencies amount into PKR")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ag_column, {
                          xs: "12",
                          sm: "12",
                          md: "6",
                          lg: "8"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createVNode(_component_MTextfield, {
                                type: "number",
                                inputValue: _ctx.currencyAmount,
                                "onUpdate:inputValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currencyAmount) = $event)),
                                label: "Currency Amount"
                              }, null, 8, ["inputValue"]),
                              _withDirectives(_createElementVNode("p", { class: "converted-amount" }, _toDisplayString(_ctx.convertCurrencyToPkr()), 513), [
                                [_vShow, _ctx.convertCurrencyToPkr()]
                              ])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ag_column, {
                          xs: "12",
                          sm: "12",
                          md: "6",
                          lg: "4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_MCombobox, {
                              inputValue: _ctx.selectedCurrency2,
                              "onUpdate:inputValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedCurrency2) = $event)),
                              label: "Currency",
                              itemValue: "value",
                              itemLabel: "label",
                              options: _ctx.currencyItems,
                              errorMessage: "Required",
                              name: "Currency"
                            }, null, 8, ["inputValue", "options"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_MCard, { class: "m-card-currency-conversion" }, {
          default: _withCtx(() => [
            _createVNode(_component_AgIconInfoBar, {
              "test-id": "",
              title: "Currency Rates",
              class: "section-title",
              mPrepandIcon: "m-ag-pricing"
            }),
            _createVNode(_component_MDataTable, {
              headers: _ctx.headers,
              data: _ctx.currencies,
              "total-item-count": _ctx.currencies.length,
              "has-search": false
            }, null, 8, ["headers", "data", "total-item-count"])
          ]),
          _: 1
        })
      ], 64))
}