import { Organization } from "@/modules/Auth/types";

class ColorTheme {
  mainColor: string;
  opacity: number;

  constructor(mainColor: string, opacity: number) {
    this.mainColor = mainColor;
    this.opacity = opacity;
  }

  hexToRGB(hex: string): number[] {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  }

  rgbToHex(r: number, g: number, b: number): string {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  }

  calculateHoverColor(brightness: number): string {
    const rgb = this.hexToRGB(this.mainColor);
    const hoverRGB = rgb.map((channel) =>
      Math.min(255, channel + brightness)
    ) as [number, number, number];
    return this.rgbToHex(...hoverRGB);
  }

  calculateLighterColor(opacity: number): string {
    const rgb = this.hexToRGB(this.mainColor);
    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
  }
}

export const getThemeColor = (organization?: Organization | null): string => {
  const color = organization?.org_default_color ?? "#10B981";
  const theme = new ColorTheme(color, 0.13);

  return theme.mainColor;
};

export const setThemeColor = (organization?: Organization) => {
  const color = organization?.org_default_color ?? "#10B981";

  const theme = new ColorTheme(color, 0.13);

  const root = document.documentElement;
  root.style.setProperty("--green-color", theme.mainColor);
  root.style.setProperty("--primary-color-hover", theme.calculateHoverColor(1));
  root.style.setProperty(
    "--login-screen-color",
    theme.calculateLighterColor(0.13)
  );
};
