import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7278cd14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "limit-date" }
const _hoisted_2 = {
  key: 0,
  class: "margin_bottom_0"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "limit-date"
}
const _hoisted_5 = {
  key: 1,
  class: "margin_bottom_0"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "mchip-alignment" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_AgTabs = _resolveComponent("AgTabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MCard, { class: "credit-limit-heading" }, {
      default: _withCtx(() => [
        _createVNode(_component_AgIconInfoBar, {
          "test-id": "",
          title: "Credit Limit Management",
          class: "section-title",
          mPrepandIcon: "m-credit-limit-management"
        })
      ]),
      _: 1
    }),
    _createVNode(_component_MCard, null, {
      default: _withCtx(() => [
        _createVNode(_component_AgTabs, {
          "test-id": "test",
          "panel-value": [1],
          class: "no_tab_icon"
        }, {
          TabHeading: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.CREDIT_LIMIT_REQUEST_STATUSES), (status, index) => {
              return (_openBlock(), _createBlock(_component_v_tab, {
                key: index,
                value: index,
                onClick: ($event: any) => (_ctx.onClickTab(status as CreditLimitRequestStatusKey))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.formatWordIntoCapitalize(status.replaceAll("_", " "))), 1)
                ]),
                _: 2
              }, 1032, ["value", "onClick"]))
            }), 128))
          ]),
          TabDetail: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_MDataTable, {
                "is-loading": _ctx.isFetchingCreditLimitRequests,
                headers: _ctx.creditLimitHeaders,
                data: _ctx.creditLimitRequests.data,
                "item-per-page": 30,
                "is-api-paginated": true,
                onOnPageChange: _ctx.onPageChange,
                "total-item-count": _ctx.creditLimitRequests.count
              }, {
                requestInitiatedAt: _withCtx(({ item }) => [
                  _createElementVNode("div", _hoisted_1, [
                    (item.requestInitiatedAt)
                      ? (_openBlock(), _createBlock(_component_MTypography, {
                          key: 0,
                          class: "date-class",
                          type: "body"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(
                      item.requestInitiatedAt,
                      _ctx.FORMAT_DD_MMM_YYYY_WITH_DASH
                    )), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    (item.requestInitiatedAt)
                      ? (_openBlock(), _createBlock(_component_MTypography, {
                          key: 1,
                          class: "description",
                          type: "label"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(
                      item.requestInitiatedAt,
                      _ctx.FORMAT_HH_SS_24_HOURS
                    )), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ]),
                  (!item.requestInitiatedAt)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_2, "N/A"))
                    : _createCommentVNode("", true)
                ]),
                organizationName: _withCtx(({ item }) => [
                  (!_ctx.isSuperUser)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: 
                  _ctx.formatStringToRoutePath(_ctx.PATH.CREDIT_LIMIT_REQUEST_DETAIL, {
                    id: item.id,
                  })
                
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MTypography, {
                            class: "description td-full-width",
                            type: "body"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.organizationName), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_MTypography, {
                          class: "description td-full-width",
                          type: "label"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.organizationName), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                ]),
                agentSectorName: _withCtx(({ item }) => [
                  _createVNode(_component_MTypography, {
                    class: "td-full-width description",
                    type: "label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.agentSectorName), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                limitExpiresAt: _withCtx(({ item }) => [
                  (item.limitExpiresAt)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_MTypography, {
                          class: "description",
                          type: "body"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(
                      item.limitExpiresAt,
                      _ctx.FORMAT_DD_MMM_YYYY_WITH_DASH
                    )), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_MTypography, {
                          class: "description",
                          type: "label"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(
                      item.limitExpiresAt,
                      _ctx.FORMAT_HH_SS_24_HOURS
                    )), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_5, "-"))
                ]),
                requestNotes: _withCtx(({ item }) => [
                  (!item.requestNotes)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_6, "-"))
                    : (_openBlock(), _createBlock(_component_MTypography, {
                        key: 1,
                        class: "td-full-width description",
                        type: "label"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.requestNotes), 1)
                        ]),
                        _: 2
                      }, 1024))
                ]),
                priority: _withCtx(({ item }) => [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_MChip, {
                      variant: _ctx.creditLimitPriorityclass(item.priority),
                      class: "m-chip-credit-limit"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.priority), 1)
                      ]),
                      _: 2
                    }, 1032, ["variant"])
                  ])
                ]),
                financialProfileName: _withCtx(({ item }) => [
                  _createVNode(_component_MTypography, {
                    class: "description financial_profile_name",
                    type: "body"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.financialProfileName), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                requestedAmount: _withCtx(({ item }) => [
                  _createVNode(_component_MTypography, {
                    class: "td-full-width description",
                    type: "label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.formatNumber(item.requestedAmount)), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["is-loading", "headers", "data", "onOnPageChange", "total-item-count"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}