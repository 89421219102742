<template>
  <ag-modal
    :is-open="isDialogOpen"
    persistent
    class="change_search_modal"
    modal-width="80%"
  >
    <template #header>
      <v-card-title>Change Search</v-card-title>
      <a-g-button
        type="button"
        @click="handleClose"
        class="modal_close_icon"
        variant="link"
        >Close</a-g-button
      >
    </template>
    <template #body>
      <FlightSearch @close_flight_search_dialog="handleCloseDialog" />
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FlightSearch from "../FlightSearch/index.vue";

export default defineComponent({
  name: "FlightChangeSearch",
  components: {
    FlightSearch,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    handleClose: Function,
  },
  data() {
    return {
      isDialogOpen: this.isOpen,
    };
  },
  methods: {
    handleCloseDialog() {
      if (this.handleClose) {
        this.handleClose();
      }
    },
  },
  watch: {
    isOpen: {
      handler: function (value) {
        this.isDialogOpen = value;
      },
      immediate: true,
    },
  },
});
</script>
