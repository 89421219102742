<template>
  <MCard>
    <AgIconInfoBar
      test-id=""
      title="Update Organization"
      class="section-title"
      mPrepandIcon="m-organisations"
    />
  </MCard>
  <ShimmerCard v-if="isLoading" cardHeight="400px" />
  <div v-else>
    <OrganizationForm
      v-if="$store.getters.organization"
      :organizationData="$store.getters.organization"
      @onSubmitOrganizationForm="updateOrganization"
      :onLoading="$store.getters.isUpdatingOrganization"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OrganizationForm from "@/modules/Organization/components/OrganizationForm.vue";
import { UpdateOrganizationDto } from "@/modules/Organization/dtos/organization.dto";
import { PATH } from "@/ag-portal-common/constants/path";
import ShimmerCard from "@/components/ShimmerCard.vue";

export default defineComponent({
  name: "UpdateOrganization",
  components: { OrganizationForm, ShimmerCard },
  beforeMount() {
    this.$store.dispatch("fetchOrganizationCreateFormDetails");
    if (this.$route.params.id) {
      this.$store.dispatch("fetchOrganization", this.$route.params.id);
    }
  },
  computed: {
    isLoading() {
      return (
        this.$store.getters.isFetchingOrganizations ||
        this.$store.getters.isFetchingOrganization
      );
    },
  },
  methods: {
    updateOrganization(payload: UpdateOrganizationDto) {
      this.$store.dispatch("updateOrganization", {
        body: payload,
        callback: this.routeToOrganizationDetail,
      });
    },
    routeToOrganizationDetail() {
      this.$router.push(`${PATH.ORGANIZATION}/${this.$route.params.id}`);
    },
  },
});
</script>

<style lang="css" scoped>
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
</style>
