<template>
  <div
    ref="targetEl"
    :style="`min-height:${fixedMinHeight ? fixedMinHeight : minHeight}px`"
  >
    <MTransition>
      <slot v-if="shouldRender"></slot>
    </MTransition>
  </div>
</template>

<script lang="ts">
import { MTransition } from "@aeroglobe/ag-core-ui";
import { defineComponent, nextTick } from "vue";

export default defineComponent({
  props: {
    renderOnIdle: {
      type: Boolean,
      default: false,
    },
    unrender: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: Number,
      default: 0,
    },
    unrenderDelay: {
      type: Number,
      default: 10000,
    },
  },
  components: { MTransition },
  data() {
    return {
      shouldRender: false,
      targetEl: null as HTMLElement | null,
      fixedMinHeight: 0,
      unrenderTimer: 0 as ReturnType<typeof setTimeout>,
      renderTimer: 0 as ReturnType<typeof setTimeout>,
      observer: null as IntersectionObserver | null,
    };
  },
  methods: {
    observerCallback(entries: IntersectionObserverEntry[]): void {
      const entry = entries[0];

      if (entry.isIntersecting) {
        clearTimeout(this.unrenderTimer);

        if (this.unrender) {
          this.renderTimer = setTimeout(() => {
            this.shouldRender = true;
          }, 200);
        } else {
          this.shouldRender = true;
        }

        if (!this.unrender) {
          this.observer?.disconnect();
        }
      } else if (this.unrender) {
        clearTimeout(this.renderTimer);
        this.unrenderTimer = setTimeout(() => {
          if (this.targetEl) {
            this.fixedMinHeight = this.targetEl.clientHeight;
          }
          this.shouldRender = false;
        }, this.unrenderDelay);
      }
    },
    onIdle(cb: () => void): void {
      if ("requestIdleCallback" in window) {
        window.requestIdleCallback(cb);
      } else {
        setTimeout(() => {
          nextTick(cb);
        }, 300);
      }
    },
  },
  mounted(): void {
    this.targetEl = this.$refs.targetEl as HTMLDivElement | null;

    this.observer = new IntersectionObserver(this.observerCallback, {
      rootMargin: "600px",
    });

    if (this.targetEl) {
      this.observer.observe(this.targetEl);
    }

    if (this.renderOnIdle) {
      this.onIdle(() => {
        this.shouldRender = true;
        if (!this.unrender) {
          this.observer?.disconnect();
        }
      });
    }
  },
  beforeUnmount(): void {
    if (this.observer && this.targetEl) {
      this.observer.unobserve(this.targetEl);
      this.observer.disconnect();
    }
    clearTimeout(this.unrenderTimer);
    clearTimeout(this.renderTimer);
  },
});
</script>
