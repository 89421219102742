import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { SABRE_SEGMENT_API_PATH } from "@/modules/SabreSegment/constants";

class sabreSegmentService {
  private restClient: RestClientService;
  constructor() {
    this.restClient = new RestClientService();
  }
  public getByDefaultSegmentStats(): Promise<IAGResponse> {
    return this.restClient.post(
      `v1/${SABRE_SEGMENT_API_PATH.POST_DEFAULT_STATS}`,
      ""
    );
  }
  public getByDefaultSegmentList(): Promise<IAGResponse> {
    return this.restClient.post(
      `v1/${SABRE_SEGMENT_API_PATH.POST_DEFAULT_LIST}`,
      ""
    );
  }
  public getCustomSegmenStats(
    start_date: any,
    end_date: any,
    organizationId?: string
  ): Promise<IAGResponse> {
    const apiVersion = organizationId ? "v2" : "v1";
    const organization = organizationId ? `${organizationId}/` : "";

    return this.restClient.post(
      `${apiVersion}/${SABRE_SEGMENT_API_PATH.POST_DEFAULT_STATS}${organization}?start_date=${start_date}&end_date=${end_date}`,
      ""
    );
  }
  public getCustomSegmenLists(
    start_date: any,
    end_date: any,
    organizationId?: string
  ): Promise<IAGResponse> {
    const apiVersion = organizationId ? "v2" : "v1";
    const organization = organizationId ? `${organizationId}/` : "";

    return this.restClient.post(
      `${apiVersion}/${SABRE_SEGMENT_API_PATH.POST_DEFAULT_LIST}${organization}?start_date=${start_date}&end_date=${end_date}`,
      ""
    );
  }
}
export default sabreSegmentService;
