<template>
  <div class="normal_recent_search_container">
    <div class="label">
      <span class="route">{{ origin }}</span>
      <span class="multicity_route_type_icon" v-if="isMulticity">...</span>
      <MIcon class="route_type_icon_flight_search" v-else :name="routeIcon" />
      <span class="route">{{ destination }}</span>
    </div>

    <span class="iternary-details-dates">{{ itineraryDetails }}</span>
    <div class="flight-trip-details-container">
      <span class="iternary-details">{{ tripType }}</span
      ><span class="black-dot"></span
      ><span class="iternary-details">{{ travelers }} Traveler</span
      ><span class="black-dot"></span
      ><span class="iternary-details">{{ cabinClass }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MIcon } from "@aeroglobe/ag-core-ui";
import { IconName } from "@aeroglobe/ag-core-ui/dist/src/components/material/icons/icons.type";
import {
  ROUTE_TYPE_ICONS_MAPPING,
  ROUTE_TYPE_NAMES_FOR_RECENT_SEARCH,
} from "@/ag-flight-components/enums/route_type";

export default defineComponent({
  name: "RecentSearchBox",
  components: {
    MIcon,
  },
  props: {
    itineraryDetails: {
      type: String,
      default: "",
    },
    tripType: {
      type: String,
      default: "",
    },
    cabinClass: {
      type: String,
      default: "",
    },
    travelers: {
      type: Number,
      default: 0,
    },
    origin: {
      type: String,
      default: "",
    },
    destination: {
      type: String,
      default: "",
    },
  },
  computed: {
    routeIcon(): IconName {
      return ROUTE_TYPE_ICONS_MAPPING[
        this.tripType as ROUTE_TYPE_NAMES_FOR_RECENT_SEARCH
      ] as IconName;
    },
    isMulticity(): boolean {
      return this.tripType === ROUTE_TYPE_NAMES_FOR_RECENT_SEARCH.MULTI_CITY;
    },
  },
});
</script>

<style lang="scss" scoped>
.normal_recent_search_container {
  width: 240px;
  border: solid 1px rgba(16, 185, 129, 0.4);
  padding: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 1px 7px 10px 0px;
  transition: all 0.4s;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(100, 100, 111, 0.5) 1px 7px 10px 0px;
  }
  .label {
    display: flex;
    align-items: center;
    position: relative;
    gap: 5px;
    .route {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .iternary-details {
    font-size: 12px;
    color: #49454f;
    letter-spacing: 0.3px;
  }
  .iternary-details-dates {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    color: #49454f;
    letter-spacing: 0.3px;
  }
  .black-dot {
    width: 5px;
    height: 5px;
    background: #000000;
    display: block;
    border-radius: 50%;
  }
  .flight-trip-details-container {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
</style>

<style lang="scss">
.route_type_icon_flight_search {
  width: 10px;
  margin-top: 8px;
  margin-right: 10px;
  svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
  }
  path {
    fill: var(--green-color);
  }
}
.multicity_route_type_icon {
  color: var(--green-color);
  font-weight: 600;
}
</style>
