<template>
  <MulticityFlightItem
    v-for="(leg, index) in multiCityLegs"
    :errors="errors"
    :multicity-leg="leg"
    :index="index"
    :key="index"
    @validateFields="validateSearchFeilds"
  />
  <div v-if="showAddFlightBtn" test-id="" class="add_multiple_flight">
    <div @click="addFlight" test-id="" class="add_another_flight_link">
      + Add Another Flight
    </div>
  </div>
  <ag-row>
    <ag-column xs="12" sm="12" md="7" cols="12">
      <AgRow>
        <ag-column xs="12" sm="12" md="6" cols="12">
          <MTravelerCountBox
            label="Travelers"
            prepend-icon="m-traveler-count-icon"
            :adult-count="adultCount"
            :child-count="childCount"
            :infant-count="infantCount"
          >
            <template #items>
              <MTravelerCountBoxItem
                name="adultCount"
                label="Adult"
                icon="m-adult-traveler-icon"
                hint="(12 years and above)"
                :count="adultCount"
                @update:adultCount="updateAdultCount"
              />
              <MTravelerCountBoxItem
                name="childCount"
                label="Children"
                icon="m-child-traveler-icon"
                hint="(2 to 11 years)"
                :count="childCount"
                @update:childCount="updateChildCount"
              />
              <MTravelerCountBoxItem
                name="infantCount"
                label="Infants"
                icon="m-infant-traveler-icon"
                hint="(0 to less than 2)"
                :count="infantCount"
                @update:infantCount="updateInfantCount"
              />
            </template>
          </MTravelerCountBox>
        </ag-column>
        <ag-column xs="12" sm="12" md="6" cols="12">
          <slot name="financial-profile-combobox" />
        </ag-column>
      </AgRow>
    </ag-column>
    <ag-column xs="12" sm="12" md="5" cols="12">
      <ag-row>
        <ag-column xs="12" sm="12" md="6" cols="12">
          <MCombobox
            v-model:inputValue="selectedCabinClass"
            label="Cabin Class"
            itemValue="value"
            itemLabel="label"
            :options="CabinClassOptions"
            class="no-margin"
            focusedBorderEnabled
          />
        </ag-column>
        <ag-column
          xs="12"
          sm="12"
          md="6"
          cols="12"
          class="search-btn-container"
        >
          <MButton type="square" @click="onFlightSearch()">
            Search Flights
          </MButton>
        </ag-column>
      </ag-row>
    </ag-column>
  </ag-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { addDays } from "date-fns";
import {
  MCombobox,
  MButton,
  MTravelerCountBox,
  MTravelerCountBoxItem,
} from "@aeroglobe/ag-core-ui";

import MulticityFlightItem from "./MulticityFlightItem.vue";
import { MultiCityLegStateType } from "../../types/Flights";
import { CabinClassOptions } from "@/ag-flight-components/constants";
import { CABIN_CLASS } from "@/ag-flight-components/enums/cabin_class";

export default defineComponent({
  name: "MulticityFlightSearch",
  events: ["validateSearchFeilds"],
  components: {
    MulticityFlightItem,
    MCombobox,
    MButton,
    MTravelerCountBox,
    MTravelerCountBoxItem,
  },
  data() {
    return {
      CabinClassOptions,
      selectedCabinClass: "",
    };
  },
  props: {
    onFlightSearch: {
      type: Function,
      required: true,
    },
    errors: {
      type: Object,
    },
  },
  computed: {
    multiCityLegs(): MultiCityLegStateType[] {
      return this.$store.state.flightModule.multiCityLegs;
    },
    showAddFlightBtn(): boolean {
      return this.$store.state.flightModule.multiCityLegs.length < 5;
    },
    adultCount(): number {
      return Number(this.$store.state.flightModule.adult_count);
    },
    childCount(): number {
      return Number(this.$store.state.flightModule.child_count);
    },
    infantCount(): number {
      return Number(this.$store.state.flightModule.infant_count);
    },
    cabinClass(): CABIN_CLASS {
      return this.$store.state.flightModule.cabin_class;
    },
  },
  methods: {
    validateSearchFeilds() {
      this.$emit("validateSearchFeilds");
    },
    addFlight() {
      const multiCityLegs = this.$store.state.flightModule.multiCityLegs;
      const lastLeg = multiCityLegs[multiCityLegs.length - 1];
      const payload = {
        origin: lastLeg.destination,
        destination: null,
        departure_date: new Date(addDays(lastLeg.departure_date, 1)),
      };
      this.$store.commit("addMulticityFlight", payload);
      this.$store.commit("addNewMulticityAirportsRow");
    },
    deleteFlight(index: number) {
      this.$store.commit("deleteMulticityFlight", index);
    },
    updateAdultCount(value: number): void {
      this.$store.commit("saveAdultCount", value);
    },
    updateChildCount(value: number): void {
      this.$store.commit("saveChildCount", value);
    },
    updateInfantCount(value: number): void {
      this.$store.commit("saveInfantCount", value);
    },
    changeCabinClass(cabinClass: CABIN_CLASS) {
      this.$store.commit("saveCabinClass", cabinClass);
    },
  },
  watch: {
    selectedCabinClass: {
      handler: function (cabinClass: CABIN_CLASS) {
        this.changeCabinClass(cabinClass);
      },
    },
    cabinClass: {
      handler: function (cabinClass: CABIN_CLASS) {
        this.selectedCabinClass = cabinClass;
      },
      immediate: true,
    },
  },
});
</script>

<style scoped>
.add_another_flight_link {
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  color: var(--green-color);
  margin: 25px 0px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .search-btn-container {
    display: flex;
    justify-content: center;
  }
}
</style>
