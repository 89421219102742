import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bc28616"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["view-type"]
const _hoisted_2 = { class: "fr-column" }
const _hoisted_3 = { class: "fr-time" }
const _hoisted_4 = { class: "fr-code" }
const _hoisted_5 = { class: "fr-column fr-route-details" }
const _hoisted_6 = { class: "fr-duration" }
const _hoisted_7 = { class: "fr-graphics" }
const _hoisted_8 = { class: "fr-code" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "fr-column" }
const _hoisted_11 = { class: "fr-time" }
const _hoisted_12 = { class: "fr-code" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDivider = _resolveComponent("MDivider")!
  const _component_MIcon = _resolveComponent("MIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flight-route",
    "view-type": _ctx.viewType
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.details.originCode), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.details.originTime), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.details.duration), 1),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_MDivider, { class: "fr-border" }),
        _createVNode(_component_MIcon, {
          class: "fr-icon",
          name: "m-flight"
        }),
        _createVNode(_component_MDivider, { class: "fr-border" })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", null, [
          _createElementVNode("b", null, _toDisplayString(_ctx.stopsLabel), 1)
        ]),
        _createTextVNode(" " + _toDisplayString(" ") + " "),
        (_ctx.stopsWithLayoffs.length > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_9, " (" + _toDisplayString(_ctx.stopsWithLayoffs.join(", ")) + ") ", 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.details.destinationCode), 1),
      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.details.destinationTime), 1)
    ])
  ], 8, _hoisted_1))
}