<template>
  <MCard class="sector-snapshot-header">
    <ag-row class="fields-container">
      <ag-column sm="12" lg="6">
        <MCombobox
          v-model:inputValue="selectedSector"
          label="Select Sector"
          itemValue="value"
          itemLabel="label"
          :options="sectors"
          :disabled="$store.getters.isSalesSectorsFetching"
        />
      </ag-column>
      <ag-column sm="12" lg="6" v-show="selectedSector">
        <MCombobox
          v-model:inputValue="selectedSalesUser"
          label="Sales People"
          itemValue="value"
          itemLabel="label"
          :options="salesUsers"
        />
      </ag-column>
    </ag-row>
    <template v-if="$store.getters.isSalesLoading">
      <ShimmerCard card-height="400px" />
      <ShimmerCard card-height="300px" />
      <ShimmerCard card-height="200px" />
    </template>
    <template v-else>
      <template v-if="showDetails">
        <!-- General Stats Card -->
        <SnapshotDetailAccordion
          title="General Stats"
          prepand-icon="m-analytics"
        >
          <template #overview>
            <div class="grid-container">
              <SnapshotTile
                heading="Enabled Websites"
                :subHeading="salesSnapshot?.aggregates?.enabled_website_count"
                subHeadingTitle="Count"
                icon="m-website-management"
              >
              </SnapshotTile>
              <SnapshotTile
                heading="Disabled Websites"
                :subHeading="salesSnapshot?.aggregates?.disabled_website_count"
                subHeadingTitle="Count"
                icon="m-website-management"
              >
              </SnapshotTile>
              <SnapshotTile
                heading="Logo Added"
                :subHeading="salesSnapshot?.aggregates?.logo_added_count"
                subHeadingTitle="Count"
                icon="m-logo-icon"
              >
              </SnapshotTile>
              <SnapshotTile
                heading="Color Added"
                :subHeading="salesSnapshot?.aggregates?.color_added_count"
                subHeadingTitle="Count"
                icon="m-color-icon"
              >
              </SnapshotTile>
              <SnapshotTile
                heading="Default Color"
                :subHeading="salesSnapshot?.aggregates?.default_color_count"
                icon="m-default-color-fill"
                subHeadingTitle="Count"
              >
              </SnapshotTile>
              <SnapshotTile
                heading="Default Logo"
                :subHeading="salesSnapshot?.aggregates?.default_logo_count"
                icon="m-default-logo-icon"
                subHeadingTitle="Count"
              >
              </SnapshotTile>
            </div>
          </template>
        </SnapshotDetailAccordion>

        <!-- Users Card -->
        <SnapshotDetailAccordion
          title="Users"
          prepand-icon="m-agent-management"
        >
          <template #overview>
            <div class="grid-container">
              <SnapshotTile
                heading="Owners"
                :subHeading="salesSnapshot?.users?.owners"
                subHeadingTitle="Count"
                icon="m-my-team"
              >
              </SnapshotTile>
              <SnapshotTile
                heading="Employees"
                :subHeading="salesSnapshot?.users?.employees"
                subHeadingTitle="Count"
                icon="m-my-team"
              >
              </SnapshotTile>
              <SnapshotTile
                heading="Sub Agents"
                :subHeading="salesSnapshot?.users?.sub_agents"
                subHeadingTitle="Count"
                icon="m-my-team"
              >
              </SnapshotTile>
            </div>
          </template>
        </SnapshotDetailAccordion>

        <!-- Organization Card -->
        <SnapshotDetailAccordion
          title="Organizations"
          prepand-icon="m-organisations"
        >
          <template #accordion-content>
            <MDataTable
              :headers="headers"
              :data="salesSnapshotOrganizations"
              :item-per-page="10"
              :has-search="true"
              v-if="showOrganizationTable"
            >
              <template #logo="{ item }">
                <div class="width-150 text-align-center">
                  <img width="60" :src="item.logo" />
                </div>
              </template>
              <template #org_color="{ item }">
                <div>
                  <ag-color-chip
                    :color-name="item.org_color"
                    :color-code="item.org_color"
                  />
                </div>
              </template>
              <template #name="{ item }">
                <div @click="handleRowClick($event, item)">
                  <MTypography
                    class="description organization-link td-full-width"
                    type="body"
                  >
                    {{ item.name }}
                  </MTypography>
                </div>
              </template>
              <template #domain_name="{ item }">
                <MTypography
                  class="description td-full-width"
                  type="label"
                  v-if="item.domain_name"
                >
                  {{ item.domain_name }}
                </MTypography>
                <MTypography
                  class="description td-full-width"
                  type="label"
                  v-else
                >
                  N/A
                </MTypography>
              </template>
              <template #display_name="{ item }">
                <MTypography
                  class="description td-full-width"
                  type="label"
                  v-if="item.display_name"
                >
                  {{ item.display_name }}
                </MTypography>
                <MTypography
                  class="description td-full-width"
                  type="label"
                  v-else
                >
                  N/A
                </MTypography>
              </template>
            </MDataTable>
            <ResultNotFound
              title="No Organization Found"
              description=""
              v-else
            />
          </template>
        </SnapshotDetailAccordion>
      </template>
      <ResultNotFound title="No Details Found" v-if="showResultNotFound" />
    </template>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";

import { formatQueryPath } from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  ISalesSector,
  SalesUser,
  ISalesSnapshot,
} from "@/ag-portal-common/interfaces/salesProductivity.interface";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import ShimmerCard from "@/components/ShimmerCard.vue";
import ResultNotFound from "@/components/ResultNotFound.vue";
import { MDataTable, MCard, MCombobox } from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";
import SnapshotDetailAccordion from "@/components/SnapshotDetailAccordion.vue";
import SnapshotTile from "@/components/SnapshotTile.vue";

export default defineComponent({
  name: "SalesSnapshotAnalyticsTab",
  components: {
    ShimmerCard,
    ResultNotFound,
    MDataTable,
    MCard,
    MCombobox,
    SnapshotDetailAccordion,
    SnapshotTile,
  },
  data(): {
    headers: MDataTableHeader[];
    salesUsers: any[];
    selectedSalesUser: string;
    selectedSector: string;
    lastSectorId: string;
    lastSalesmanId: string;
    isInitial: boolean;
  } {
    return {
      isInitial: true,
      selectedSector: "",
      headers: [
        {
          title: "Organization Name",
          value: "name",
          key: "name",
          sortable: true,
        },
        {
          title: "Owners",
          value: "owner_count",
          key: "owner_count",
          sortable: true,
        },
        {
          title: "Sub Agents",
          value: "sub_agent_count",
          key: "sub_agent_count",
          sortable: true,
        },
        {
          title: "Employees",
          value: "employee_count",
          key: "employee_count",
          sortable: true,
        },
        {
          title: "Logo",
          value: "logo",
          key: "logo",
          sortable: false,
        },
        {
          title: "Display Name",
          value: "display_name",
          key: "display_name",
          sortable: true,
        },
        {
          title: "Website Slugs",
          value: "website_slug",
          key: "website_slug",
          sortable: true,
        },
        {
          title: "Website Status",
          value: "website_status",
          key: "website_status",
          sortable: true,
        },
        {
          title: "Domain Name",
          value: "domain_name",
          key: "domain_name",
          sortable: true,
        },
        {
          title: "Organization Color",
          value: "org_color",
          key: "org_color",
          sortable: false,
        },
      ],
      salesUsers: [],
      selectedSalesUser: "",
      lastSalesmanId: "",
      lastSectorId: "",
    };
  },
  methods: {
    handleSalesPersonChange() {
      if (this.selectedSalesUser) {
        const path = `${PATH.SALES_SNAPSHOT}${formatQueryPath({
          sectorId: this.selectedSector,
          salesmanId: this.selectedSalesUser,
        })}`;
        this.$router.push(path);
      }
    },
    fetchSalesStats() {
      const { salesmanId } = this.$route.query;
      this.isInitial = false;
      this.$store.dispatch("getSalesStats", salesmanId);
    },
    updateSalesUsers(selectedSector: string) {
      const salesSectors: ISalesSector[] =
        this.$store.getters.salesSectors || [];
      const selectedObj = salesSectors.find(
        (item) => item.sector_id === Number(selectedSector)
      );
      if (selectedObj) {
        this.salesUsers = selectedObj.sales_users.map((user: SalesUser) => {
          return {
            label: user.name + " - (" + user.email + ")",
            value: user.id.toString(),
          };
        });
      } else {
        this.salesUsers = [];
      }
    },
    handleRowClick(e: any, row: any) {
      const org_id = row?.public_id;
      const url = this.$router.resolve({
        path: PATH.ORGANIZATION_SNAPSHOT,
        query: { org_id: org_id },
      });

      // Open the route in a new tab
      window.open(url.href, "_blank");
    },
  },
  computed: {
    sectors(): IObject[] {
      const sectors = this.$store.getters.salesSectors;
      return (
        sectors?.map((item: ISalesSector) => {
          return {
            label: item.sector_name,
            value: item.sector_id.toString(),
          };
        }) || []
      );
    },
    salesSnapshot(): ISalesSnapshot {
      return this.$store.getters.salesSnapshot;
    },
    salesSnapshotOrganizations(): any[] {
      return this.$store.getters.salesSnapshot.organizations || [];
    },
    showDetails(): boolean {
      const isNotEmpty = !_.isEmpty(this.salesSnapshot);
      return isNotEmpty;
    },
    showOrganizationTable(): boolean {
      const isLoading = this.$store.getters.isSalesLoading;
      const organizations =
        this.$store.getters.salesSnapshot.organizations || [];
      return !isLoading && organizations.length > 0;
    },
    showResultNotFound(): boolean {
      return (
        !this.isInitial &&
        !this.$store.getters.isSalesLoading &&
        !this.showDetails
      );
    },
  },
  watch: {
    "$route.query": {
      handler: function (value) {
        const isNotEmpty = !_.isEmpty(value);
        if (isNotEmpty) {
          const salesmanIdisNotSameAsLastSalesmanId =
            value?.salesmanId !== this.lastSalesmanId;

          if (salesmanIdisNotSameAsLastSalesmanId) {
            this.selectedSector = value?.sectorId;
            this.selectedSalesUser = value?.salesmanId;
            this.lastSectorId = value?.sectorId;
            this.lastSalesmanId = value?.salesmanId;
            this.fetchSalesStats();
          }
        }
      },
      immediate: true,
    },
    selectedSector: {
      handler: function (selectedSector) {
        this.selectedSalesUser = "";
        if (selectedSector) {
          this.updateSalesUsers(selectedSector);
        }
      },
    },
    selectedSalesUser: {
      handler: function () {
        this.handleSalesPersonChange();
      },
    },
  },
  mounted() {
    this.$store.dispatch("fetchSectors");
    const { salesmanId, sectorId } = this.$route.query;
    if (salesmanId) {
      setTimeout(() => {
        this.selectedSector = sectorId?.toString() || "";
      }, 1000);
    }
  },
});
</script>

<style lang="scss" scoped>
.sector-snapshot-header {
  .name-container {
    display: flex;
    align-items: center;
    img {
      width: 70px;
    }
    .agency-name-container {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .agency-name {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
        color: #000;
      }
      p {
        margin-bottom: 0;
        color: #6b7280;
      }
    }
  }
}

.autocomplete-container {
  max-width: 400px;
  .label {
    font-weight: bold;
  }
}

.heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}

.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-left: solid 8px var(--green-color);
}
.empty-items-message {
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}
.color-chip {
  width: 120px;
}

.website-slug {
  font-weight: bold;
  color: #6b7280;
  &:hover {
    color: #0c47bc;
  }
}

.name-column {
  min-width: 210px;
}

.organization-link {
  color: var(--green-color);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.width-150 {
  min-width: 150px;
}
.width-200 {
  min-width: 200px;
}
.text-align-center {
  text-align: center;
}
.fields-container {
  margin-bottom: 30px;
}
.white-icon {
  color: #fff;
}
</style>

<style lang="css" scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

@media (max-width: 1350px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 963px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
</style>
