import { ISOAState } from "@/ag-portal-common/interfaces/soaState.interface";

const soaState: ISOAState = {
  isDownloadingSOA: false,
  isSendingSOA: false,
  pendingInvoicesAndReceipts: {
    flightInvoices: [],
    flightReceipts: [],
    hotelInvoices: [],
    groupFlightInvoices: [],
  },
};

export default soaState;
