import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_MAccordion = _resolveComponent("MAccordion")!

  return (_openBlock(), _createBlock(_component_MAccordion, {
    "initial-open": true,
    class: "m-accordion-fare-management"
  }, {
    title: _withCtx(() => [
      _createVNode(_component_AgIconInfoBar, {
        mPrepandIcon: "m-passenger-management",
        title: "Passenger Details",
        class: "maccordion-infobar"
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_MDataTable, {
        headers: _ctx.tableHeaders,
        data: _ctx.getPassengersInfo,
        "item-per-page": 30,
        "is-api-paginated": false,
        "has-search": false,
        "has-pagination": false
      }, null, 8, ["headers", "data"])
    ]),
    _: 1
  }))
}