export const FARE_MANAGEMENT_API_PATH = {
  GET_FARE: "flights/fare-management",
};

export interface Tax {
  code: string;
  amount: number;
  currency_code: string;
  tax_name?: string | null;
  ticketing_tax_code?: string | null;
}

export interface Price {
  base_fare: number;
  tax_list: Tax[];
  total_tax: number;
  fees: number;
  surcharges: number;
  gross_fare: number;
  currency_code: string;
}

export type PricePerPassenger = Price;

export interface Baggage {
  allowance: string;
  type: string;
}

export interface Segment {
  segment_status: string;
  segment_number: string;
  marketing_airline: string;
  flight_number: string;
  rbd: string;
  departure_city: string;
  departure_date_time: string;
  arrival_city: string;
  arrival_date_time: string;
  baggage: Baggage;
}

export interface OldPriceQuote {
  pricing_indicator?: string | null;
  passenger_type: string;
  price: Price;
  price_per_passenger: PricePerPassenger;
  passenger_count: number;
  validating_carrier: string;
  pq_number: string;
  pricing_status: string;
  pricing_type: string;
  price_quote_policies: any[];
  segments: Segment[];
  baggage_info?: Baggage[];
}

export type NewPriceQuote = OldPriceQuote;

export interface Passenger {
  title: string;
  first_name: string;
  last_name: string;
  passenger_type: string;
}

export interface fareManagementResponse {
  old_price_quotes: OldPriceQuote[];
  new_price_quotes: NewPriceQuote[];
  passengers: Passenger[];
  pnr: string;
  reference: string;
  total_new_fare: number;
}
