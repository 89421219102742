export const SOA_API_PATH = {
  GET: "financial-profile/",
  INVOICE: "financial-profile/{0}/pending-receipt-and-invoice/",
  GET_EMAIL: "traacs/agent-soa-items-list",
  SEND_SOA_EMAIL: "traacs/agent-soa-items-list-v2",
};

export const SOA_DATE_FORMAT = "yyyy-MM-dd";

export const SOA_ANALYTICS_COMMON_EVENTS = {
  SOA_DOWNLOAD: "soa-download-pdf-clicked",
  SOA_DOWNLOAD_OLD: "soa-download-old-pdf-clicked",
  SOA_SEND_EMAIL: "soa-send-email-clicked",
};
