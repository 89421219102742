<template>
  <SnapshotDetailAccordion
    title="Flight Issuance"
    prepand-icon="m-issue-ticket"
  >
    <template #overview>
      <div class="grid-container">
        <div v-for="(item, index) in flightIssuanceAggregates" :key="index">
          <SnapshotTile
            :heading="renderItemTitle(item)"
            :subHeading="renderItemValue(item)"
            :description="getAmount(item)"
            descriptionTitle="Amount"
            subHeadingTitle="Issued Count"
            icon="m-flight"
          >
          </SnapshotTile>
        </div>
      </div>
    </template>
    <template #accordion-content>
      <MDataTable
        :headers="issuanceHeader"
        :data="flightIssuanceOrganizations"
        :item-per-page="10"
        :has-search="true"
        v-if="showDetails"
      >
        <template #organization_name="{ item }">
          <AgDiv @click="handleOrganizationRowClick($event, item)">
            <MTypography class="description organization-link" type="label">
              {{ item.organization_name }}
            </MTypography>
          </AgDiv>
        </template>
      </MDataTable>
      <ResultNotFound title="No Organization Found" description="" v-else />
    </template>
  </SnapshotDetailAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";

import {
  ISectorProductivity,
  FlightIssuanceAggregates,
} from "@/ag-portal-common/interfaces/sectorProductivity.interface";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { MDataTable } from "@aeroglobe/ag-core-ui";
import SnapshotDetailAccordion from "@/components/SnapshotDetailAccordion.vue";
import SnapshotTile from "@/components/SnapshotTile.vue";

import ResultNotFound from "@/components/ResultNotFound.vue";
import { getCurrencyFormatter } from "@/ag-flight-components/utils";
export default defineComponent({
  name: "FlightIssuanceTable",
  components: {
    MDataTable,
    SnapshotDetailAccordion,
    SnapshotTile,
    ResultNotFound,
  },
  props: {
    handleOrganizationRowClick: {
      type: Function,
      default: () => ({}),
    },
  },
  data(): {
    startDate: Date;
    endDate: Date;
    selectedSector: string;
    issuanceHeader: MDataTableHeader[];
  } {
    return {
      startDate: new Date(),
      endDate: new Date(),
      selectedSector: "",
      issuanceHeader: [
        {
          title: "Organization Name",
          value: "organization_name",
          key: "organization_name",
          sortable: true,
        },
        {
          title: "Total PNR Issued",
          value: "total_pnr_issued",
          key: "total_pnr_issued",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    renderItemTitle(value: string) {
      return _.capitalize(value?.replaceAll("_", " "));
    },
    renderItemValue(value: keyof FlightIssuanceAggregates) {
      return this.salesProductivity.flight_issuance_data?.aggregates[value]
        .count_pnrs;
    },
    formattedCurrency(currency: string, amount: number): string {
      return getCurrencyFormatter(currency).format(amount);
    },
    getAmount(value: keyof FlightIssuanceAggregates): string {
      const fare =
        this.salesProductivity.flight_issuance_data?.aggregates[value]
          .total_amount;
      const formattedCurrency = this.formattedCurrency(
        fare.currency,
        fare.value
      );
      return formattedCurrency;
    },
    updateIssuanceHeader() {
      const salesProductivity = this.$store.getters
        .salesProductivity as ISectorProductivity;
      const organizations =
        salesProductivity?.flight_issuance_data?.organizations ?? [];

      // Check if organizations array has at least one entry and pnrs_issued_by_provider exists
      const providersName =
        organizations.length > 0
          ? organizations[0]?.pnrs_issued_by_provider?.map(
              ({ provider_name }) => provider_name
            ) ?? []
          : [];

      const updatedTableHeaders = providersName?.map((item) => {
        return {
          title: item.replaceAll("_", " "),
          value: item,
          key: item,
          sortable: true,
        };
      }) as MDataTableHeader[];

      // Ensure issuanceHeader is initialized and safely spread new headers
      this.issuanceHeader = [
        ...(this.issuanceHeader ?? []),
        ...updatedTableHeaders,
      ];
    },
  },
  computed: {
    salesProductivity(): ISectorProductivity {
      return this.$store.getters.salesProductivity;
    },
    showDetails(): boolean {
      const isLoading = this.$store.getters.isSalesLoading;
      const organizations =
        this.$store.getters.salesProductivity?.flight_issuance_data
          ?.organizations || [];
      return !isLoading && organizations.length > 0;
    },
    flightIssuanceOrganizations() {
      const salesProductivity = this.$store.getters
        .salesProductivity as ISectorProductivity;
      const organizations =
        salesProductivity?.flight_issuance_data?.organizations ?? [];

      const formattedOrganizations = organizations.map((item) => {
        const flights = Object.fromEntries(
          item.pnrs_issued_by_provider.map(
            ({ provider_name, issuance_count }) => [
              provider_name,
              issuance_count,
            ]
          )
        );

        return {
          public_id: item.public_id,
          organization_name: item.organization_name,
          total_pnr_issued: item.total_pnr_issued,
          ...flights,
        };
      });

      return formattedOrganizations;
    },
    flightIssuanceAggregates(): Array<keyof FlightIssuanceAggregates> {
      const salesProductivity = this.$store.getters.salesProductivity as
        | ISectorProductivity
        | undefined;
      const aggregates = salesProductivity?.flight_issuance_data?.aggregates;

      if (aggregates) {
        return Object.keys(aggregates) as Array<keyof FlightIssuanceAggregates>;
      }

      return [];
    },
  },
  mounted() {
    this.updateIssuanceHeader();
  },
});
</script>

<style lang="scss" scoped>
.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-top: solid 8px var(--green-color);
}

.heading {
  font-size: 24px;
  font-weight: 600;
}

.overview-heading {
  font-size: 19px;
  margin: 10px;
  font-weight: bold;
}

.items-container {
  border-top: solid 4px var(--green-color);
}

.empty-items-message {
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}

.organization-link {
  color: var(--green-color);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.accordion-title {
  font-size: 20px;
  margin-right: 20px;
  font-weight: 600;
  text-align: start;
}
.overview-container {
  margin-top: 25px;
}
.white-icon {
  color: #fff;
}
</style>

<style lang="css" scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}
</style>
