<template>
  <MProgress class="mprogress-alignment" v-if="isLoading" indeterminate />
  <template v-else>
    <div class="flight-booking-details">
      <ag-row>
        <ag-column xs="12" sm="12" md="8" lg="9">
          <AgCard test-id="">
            <AgRow test-id="" class="v-center">
              <AgColumn test-id="" md="9">
                <AgSelectedFlightInfo>
                  <template #pnrInfo>
                    <AgDiv class="booking_id_wrap"
                      >Booking ID: <span>{{ renderBookingId }}</span></AgDiv
                    >
                  </template>
                </AgSelectedFlightInfo>
              </AgColumn>
              <AgColumn test-id="" md="3" class="text-right">
                <AGButton
                  test-id=""
                  type="button"
                  :variant="renderStatusSeverity"
                >
                  {{ flightBookingStatus }}
                </AGButton>
              </AgColumn>
            </AgRow>
          </AgCard>
          <AgCard test-id="">
            <ag-heading
              variant="h2"
              title="Flight Details"
              class="margin_bottom_20"
            />
            <FlightDetailAccordion
              v-for="(flightLeg, index) in flightDetails?.flight_legs"
              :key="index"
              :flight-leg="flightLeg"
              :index="index"
            />
          </AgCard>
          <AgCard test-id="" v-if="hasPassengers">
            <ag-heading
              variant="h2"
              title="Passenger Details"
              class="margin_bottom_20"
            />
            <PassengerDetailCard
              v-for="(passenger, index) in flightDetails?.passengers"
              :key="index"
              :passenger="passenger"
              :is-international="flightDetails?.is_international"
            />
          </AgCard>
          <AGDiv v-if="additionalFlightDetails.length > 0">
            <AgCard
              v-for="(details, index) in additionalFlightDetails"
              :key="index"
            >
              <ag-heading
                variant="h2"
                :title="'Additional Services on Flight ' + (index + 1)"
              />
              <AdditionalDetailsCard
                :has-meal="details.hasMeal"
                :luggage-info="details.baggageInfo"
              />
            </AgCard>
          </AGDiv>
        </ag-column>
        <ag-column xs="12" sm="12" md="4" lg="3">
          <AgCard>
            <PNRExpiryTimer
              :status="flightBookingStatus"
              :pnrExpiry="pnrExpiresAt"
              :pnr="supplierPnr ?? ''"
            />
          </AgCard>
          <AgCard v-if="rawSabreExpiry">
            <div class="bd-expiry-card">
              <div class="bde-title">Sabre Expiry:</div>
              <div class="bde-subtitle">
                {{ rawSabreExpiry[0] }}
              </div>
            </div>
          </AgCard>
          <AgCard :class="{ 'has-flag': discountData && agDealDiscount }">
            <template v-if="discountData && agDealDiscount">
              <DiscountFlag
                :code="discountData.discount_code"
                :title="parseDiscountLabel(discountData)"
              />
            </template>
            <AgSidebarThumb test-id="" thumb="sidebarFlightThumb1">
              <PriceSummary>
                <template #flightDetailsActionButtons>
                  <template v-if="isBookingCancelled">
                    <h3
                      style="
                        font-weight: bold;
                        color: #ed6c02;
                        text-align: center;
                      "
                    >
                      Booking {{ flightBookingStatus }}
                    </h3>
                  </template>

                  <template v-else>
                    <AGButton
                      :disabled="isFlightWaitListed"
                      v-show="isIssuanceEnabled"
                      @click="onProceedToIssuance"
                      class="full-width margin_bottom_10"
                      test-id=""
                      >Proceed To Issuance
                    </AGButton>
                    <AGButton
                      v-show="isCancelEnabled"
                      :is-loading="isCancelBookingLoading"
                      @click="isCancelationDialogOpen = true"
                      class="full-width margin_bottom_10"
                      test-id=""
                      variant="danger"
                      >Cancel Booking
                    </AGButton>
                    <AGButton
                      v-show="isVoidEnabled"
                      :is-loading="isVoidFlightTicketLoading"
                      @click="onOpenVoidModalHandler"
                      class="full-width margin_bottom_10"
                      test-id=""
                      >Void ticket
                    </AGButton>
                    <AGButton
                      v-show="isDownloadEnabled"
                      :is-loading="isDownloadTicketLoading"
                      @click="onOpenEditFareModalHandler"
                      class="full-width margin_bottom_10"
                      test-id=""
                      >Download ticket
                    </AGButton>
                    <AGButton
                      v-show="isRefundEnabled"
                      @click="onProceedToRefund()"
                      class="full-width margin_bottom_10"
                      test-id=""
                      variant="danger"
                      >Refund ticket
                    </AGButton>
                    <AGButton
                      v-show="isAirlineTicketEnabled"
                      :is-loading="isDownloadTicketLoading"
                      @click="onClickAirlineTicket()"
                      class="full-width margin_bottom_10"
                      test-id="emailTicket"
                      >Email ticket
                    </AGButton>
                  </template>
                  <AirlineTicketModal
                    :show-modal="showAirlineTicketModal"
                    @onSendAirlineTicket="onSendAirlineTicket"
                    @closeModal="showAirlineTicketModal = false"
                  />
                </template>
              </PriceSummary>
            </AgSidebarThumb>
          </AgCard>
        </ag-column>
      </ag-row>
      <MDialog
        persistent
        title="Edit & Download Ticket"
        v-model="isEditModalOpen"
        max-width="1200"
      >
        <template #content>
          <div>
            <MTypography class="modal-content">
              Do you want to show fare on ticket?
            </MTypography>
            <MSegmentedButton
              :segments="showFareSegments"
              class="modal-content"
            />
          </div>
          <div v-if="onShowFareHandler()">
            <MTypography v-if="canRenderFare" class="modal-content">
              Do you want to edit fare on ticket?
            </MTypography>
            <MSegmentedButton
              :segments="editFareSegments"
              class="modal-content"
            />
          </div>
          <div v-if="onEditFareHandler()">
            <MDataTable
              :headers="headers"
              :data="items"
              :item-per-page="tableRows"
              :has-search="false"
              :has-pagination="false"
            >
              <template #editedAmount="{ item }">
                <MTextfield
                  v-model:inputValue="item.updatedAmount"
                  :value="item.updatedAmount"
                  label="Edited Amount"
                  type="number"
                  class="edit-fare-text-field"
                ></MTextfield>
              </template>
              <template #type="{ item }">
                <MTypography>{{ item.paxType }}</MTypography>
              </template>
              <template #amount="{ item }">
                <MTypography>{{ item.paxAmount }}</MTypography>
              </template>
            </MDataTable>
          </div>
        </template>
        <template #actions>
          <MButton variant="filled" @click="saveAndDownload()"
            >Save & Download</MButton
          >
        </template>
      </MDialog>
    </div>
  </template>

  <VoidBookingDialog
    :is-open="isVoidDialogOpen"
    :handle-accept="onVoidPIATicket"
    :handle-reject="handleCloseVoidDialog"
  />

  <CancelBookingConfirmationDialog
    :is-open="isCancelationDialogOpen"
    :handle-accept="onCancelBooking"
    :handle-reject="handleCloseCancelationDialog"
  />

  <PriceUpdatedDialog
    :is-open="isPriceUpdatedDialog"
    :handle-accpet="handlePriceUpdateContinue"
  />
  <WaitListDialog :is-open="isWaitListDialog" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AirlineTicketModal from "../views/AirlineTicketModal.vue";
import { ALLOWED_SUPPLIERS } from "@/ag-portal-common/constants/allowedSuppliers";
import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";
import _ from "lodash";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import FlightDetailAccordion from "@/ag-flight-components/components/FlightBooking/FlightDetailAccordion.vue";
import PassengerDetailCard from "@/ag-flight-components/components/FlightBooking/PassengerDetailCard.vue";
import CancelBookingConfirmationDialog from "@/ag-flight-components/components/FlightBooking/CancelBookingConfirmationDialog.vue";
import VoidBookingDialog from "@/ag-flight-components/components/FlightBooking/VoidBookingDialog.vue";
import AdditionalDetailsCard from "@/ag-flight-components/components/FlightBooking/AdditionalDetailsCard.vue";
import DiscountFlag from "@/ag-flight-components/components/DiscountFlag.vue";
import PriceUpdatedDialog from "@/ag-flight-components/components/FlightBooking/PriceUpdatedDialog.vue";
import WaitListDialog from "@/ag-flight-components/components/FlightBooking/WaitlistDialog.vue";
import PriceSummary from "@/ag-flight-components/components/FlightBooking/PriceSummary.vue";
import { BOOKING_STATUSES } from "@/ag-flight-components/enums/booking_statuses";
import { renderBaggageInfo } from "@/ag-flight-components/utils";
import {
  FlightLeg,
  FlightDetailsType,
  DealDiscount,
  AdditionalFlightDetails,
  ExtendedPrice,
  BaggageInfo,
  bookingActions,
} from "@/ag-flight-components/types";
import { PATH } from "@/ag-portal-common/constants/path";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import analyticsService from "@/analytic.service";
import { FLIGHT_ANALYTICS_EVENTS } from "@/constants/analyticsEvents";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";
import {
  MButton,
  MDataTable,
  MDialog,
  MTextfield,
  MTypography,
} from "@aeroglobe/ag-core-ui";
import PNRExpiryTimer from "@/components/PNRExpiryTimer.vue";
import UTILS from "@/ag-portal-common/utils";
import { USER_ROLES } from "@/modules/Auth/types";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { MSegment } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";

export default defineComponent({
  name: "FlightBookingForm",
  components: {
    FlightDetailAccordion,
    PassengerDetailCard,
    PriceSummary,
    CancelBookingConfirmationDialog,
    AdditionalDetailsCard,
    PriceUpdatedDialog,
    AirlineTicketModal,
    WaitListDialog,
    PNRExpiryTimer,
    MButton,
    DiscountFlag,
    VoidBookingDialog,
  },
  data() {
    return {
      legAdditionalDetails: [] as AdditionalFlightDetails[],
      canEditFare: false,
      canRenderFare: false,
      tableRows: 0,
      isVoidDialogOpen: false,
      isCancelationDialogOpen: false,
      isPriceUpdatedDialog: false,
      isWaitListDialog: false,
      showAirlineTicketModal: false,
      showFareOnDownload: false,
      showEditOnDownload: false,
      editFareOnDownload: false,
      perAdultEditedFare: 0,
      perChildEditedFare: 0,
      perInfantEditedFare: 0,
      isEditModalOpen: false,
      isInputEditModalOpen: false,
      inputAmount: 0,
      mainIndex: 0,
      isTableShow: false,
      isOpen: false,
      showFareSegments: [
        {
          label: "Yes",
          checked: false,
        },
        {
          label: "No",
          checked: true,
        },
      ] as MSegment[],
      editFareSegments: [
        {
          label: "Yes",
          checked: false,
        },
        {
          label: "No",
          checked: true,
        },
      ] as MSegment[],
      items: [
        {
          paxType: "Adult",
          paxCount: "0",
          paxAmount: "0",
          updatedAmount: 0,
          index: 0,
        },
        {
          paxType: "Child",
          paxCount: "0",
          paxAmount: "0",
          updatedAmount: "0",
          index: 1,
        },
        {
          paxType: "Infant",
          paxCount: "0",
          paxAmount: "0",
          updatedAmount: "0",
          index: 2,
        },
      ],
      headers: [
        {
          title: "Passenger Type",
          value: "type",
          key: "type",
          sortable: true,
        },
        {
          title: "Count",
          value: "count",
          key: "count",
          sortable: true,
        },
        {
          title: "Amount",
          value: "amount",
          key: "amount",
          sortable: false,
        },
        {
          title: "Edited Amount",
          value: "editedAmount",
          key: "editedAmount",
          sortable: false,
        },
      ] as MDataTableHeader[],
    };
  },
  methods: {
    getBaggageInfo(baggageInfo?: BaggageInfo | null): string {
      return UTILS.getBaggageInfo(baggageInfo);
    },
    parseDiscountLabel(data: DealDiscount | null): string {
      return UTILS.parseDiscountLabel(data);
    },
    openEditFareTable() {
      if (this.canRenderFare && this.canEditFare) {
        const index = this.headers.findIndex(
          (header) => header.key === "count"
        );
        if (index !== -1) {
          this.headers.splice(index, 1);
        }
        return true;
      } else {
        return false;
      }
    },
    closeDownloadTicketModal() {
      this.isEditModalOpen = false;
      this.canRenderFare = false;
      this.canEditFare = false;
    },
    onSave() {
      if (this.inputAmount > 0 && this.inputAmount) {
        this.items[this.mainIndex].updatedAmount = this.inputAmount;
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          "Fare cant be empty or negative or in words";
        notificationService.triggerNotification();
      }
    },
    populateTable() {
      this.tableRows = this.showAdultBreakDown ? 1 : 0;
      this.tableRows = this.showChildBreakDown
        ? this.tableRows + 1
        : this.tableRows;
      this.tableRows = this.showInfantBreakDown
        ? this.tableRows + 1
        : this.tableRows;
      if (
        this.showAdultBreakDown ||
        this.showChildBreakDown ||
        this.showInfantBreakDown
      ) {
        if (this.showAdultBreakDown) {
          this.items[0].paxCount = this.renderPriceSummaryTitle("adult").label;
          this.items[0].paxAmount = this.renderPriceSummaryTitle("adult").price;
          this.items[0].updatedAmount =
            this.renderPriceSummaryTitle("adult").amount;
        }
        if (this.showChildBreakDown) {
          this.items[1].paxCount = this.renderPriceSummaryTitle("child").label;
          this.items[1].paxAmount = this.renderPriceSummaryTitle("child").price;
          this.items[1].updatedAmount =
            this.renderPriceSummaryTitle("child").amount;
        }
        if (this.showInfantBreakDown) {
          this.items[2].paxCount = this.renderPriceSummaryTitle("infant").label;
          this.items[2].paxAmount =
            this.renderPriceSummaryTitle("infant").price;
          this.items[2].updatedAmount =
            this.renderPriceSummaryTitle("infant").amount;
        }
        if (!this.showInfantBreakDown) {
          this.items.splice(2, 1);
        }
        if (!this.showChildBreakDown) {
          this.items.splice(1, 1);
        }
        if (!this.showAdultBreakDown) {
          this.items.splice(0, 1);
        }
        return this.tableRows;
      } else {
        return false;
      }
    },
    formatCurrency(amount: number, currency_format: string) {
      return getCurrencyFormatter(currency_format).format(amount);
    },
    onShowFareHandler() {
      const isShowFareSelected = this.showFareSegments[0].checked
        ? true
        : false;
      this.canRenderFare = isShowFareSelected;
      this.showEditOnDownload = isShowFareSelected;
      return isShowFareSelected;
    },
    onEditFareHandler() {
      const iseditFareSelected = this.editFareSegments[0].checked
        ? true
        : false;
      this.canEditFare = iseditFareSelected;
      this.showEditOnDownload = iseditFareSelected;
      this.openEditFareTable();
      return iseditFareSelected;
    },
    showEditTicket() {
      return (
        this.showAdultBreakDown ||
        this.showChildBreakDown ||
        this.showInfantBreakDown
      );
    },
    saveAndDownload() {
      const adultItem = this.items.find((item) => item.paxType === "Adult");
      this.perAdultEditedFare = adultItem
        ? Number(adultItem?.updatedAmount)
        : 0;
      const childItem = this.items.find((item) => item.paxType === "Child");
      this.perChildEditedFare = childItem
        ? Number(childItem?.updatedAmount)
        : 0;
      const infantItem = this.items.find((item) => item.paxType === "Infant");
      this.perInfantEditedFare = infantItem
        ? Number(infantItem?.updatedAmount)
        : 0;
      const payload = {
        adultItem,
        childItem,
        infantItem,
      };
      analyticsService.logActionEvent(
        FLIGHT_ANALYTICS_EVENTS.EDIT_FARE,
        payload
      );
      this.onDownloadTicket(
        this.canRenderFare,
        this.canEditFare,
        this.perAdultEditedFare,
        this.perChildEditedFare,
        this.perInfantEditedFare
      );
      this.isEditModalOpen = false;
      this.canRenderFare = false;
      this.canEditFare = false;
      this.showFareSegments[1].checked = true;
      this.showFareSegments[0].checked = false;
      this.editFareSegments[1].checked = true;
      this.editFareSegments[0].checked = false;
    },
    onSendAirlineTicket(emails: string[]) {
      this.$store.dispatch("sendAirlineTicket", {
        payload: {
          pnr: this.supplierPnr,
          airline_provider: this.flightDetails?.flight_sub_provider,
          emails,
        } as SendAirlineTicketBodyRequest,
        callback: () => (this.showAirlineTicketModal = false),
      });
    },
    showDownloadAirlineTicketButton() {
      const status = this.flightBookingStatus;
      const supplier = this.flightDetails?.flight_sub_provider;
      const isAllowedSupplier = ALLOWED_SUPPLIERS.includes(supplier);
      return (
        status === BOOKING_STATUS.ISSUED.toUpperCase() && isAllowedSupplier
      );
    },
    onClickAirlineTicket() {
      // this.activeBooking = booking;
      this.showAirlineTicketModal = true;
    },
    onProceedToRefund() {
      const supplier = this.flightDetails?.flight_sub_provider || "";

      this.$router.push({
        path: PATH.FLIGHT_REFUNDS,
        query: {
          pnr: this.supplierPnr,
          supplier,
        },
      });
    },
    onProceedToIssuance() {
      const flight_sub_provider: string =
        this.flightDetails?.flight_sub_provider || "";
      const sub_providers_enum: IObject = {
        FLYJINNAH: "fly_jinnah",
        AIRARABIA: "air_arabia",
      };
      const supplier = Object.keys(sub_providers_enum).includes(
        this.flightDetails?.flight_sub_provider
      )
        ? sub_providers_enum[flight_sub_provider]
        : flight_sub_provider;

      const airline =
        this.flightDetails?.flight_legs[0].segment[0].marketing_airline.code;

      this.$router.push({
        path: PATH.FLIGHT_ISSUANCE,
        query: {
          pnr: this.supplierPnr,
          supplier,
          ...(supplier === "SABRE" && { sabre_airline: airline }),
        },
      });
    },
    onSuccessHandler() {
      const booking_id = this.$route.params.id;
      this.$store.dispatch("getFlightBooking", { booking_id });
    },
    onCancelBooking() {
      const booking_id = this.$route.params.id;
      this.isCancelationDialogOpen = false;
      const payload = {
        booking_id,
      };
      this.$store.dispatch("cancelFlightBooking", {
        payload,
        successCallback: this.onSuccessHandler,
      });
    },
    handleCloseCancelationDialog() {
      this.isCancelationDialogOpen = false;
    },
    handleCloseVoidDialog() {
      this.isVoidDialogOpen = false;
    },
    onDownloadTicket(
      render_fare: boolean,
      is_edited: boolean,
      per_adult_edited_fare: number,
      per_child_edited_fare: number,
      per_infant_edited_fare: number
    ) {
      const booking_id = this.$route.params.id;
      const pnr = this.supplierPnr;
      const payload = {
        booking_id,
        render_fare,
        is_edited,
        per_adult_edited_fare,
        per_child_edited_fare,
        per_infant_edited_fare,
        pnr,
      };
      analyticsService.logActionEvent(
        FLIGHT_ANALYTICS_EVENTS.AG_FLIGHT_TICKET_DOWNLOAD,
        payload
      );
      this.$store.dispatch("downloadFlightBooking", payload);
    },
    handlePriceUpdateContinue() {
      this.isPriceUpdatedDialog = false;
    },
    onOpenVoidModalHandler() {
      this.isVoidDialogOpen = true;
    },
    onVoidPIATicket() {
      const booking_id = this.$route.params.id;
      const payload = {
        booking_id,
      };
      this.$store.dispatch("voidPIAFlightBooking", {
        payload,
        successCallback: this.onSuccessHandler,
      });
    },
    onOpenEditFareModalHandler() {
      this.isEditModalOpen = true;
      this.showFareOnDownload = true;
      this.showFareSegments[1].checked = true;
      this.showFareSegments[0].checked = false;
      this.editFareSegments[1].checked = true;
      this.editFareSegments[0].checked = false;
      this.populateTable();
      return this.showFareOnDownload;
    },
    onHideFareHandler() {
      this.canRenderFare = false;
      this.canEditFare = false;
      this.showEditOnDownload = false;
    },
    onShowEditedFareHandler() {
      this.canEditFare = true;
    },
    onHideEditedFareHandler() {
      this.canEditFare = false;
    },
    getMealInfo(mealInfo: boolean): string {
      return mealInfo === true ? "Meal Included" : "No Meal Included";
    },
    renderPriceSummaryTitle(paxType: "adult" | "child" | "infant"): {
      label: string;
      price: string;
      amount: number;
    } {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};

      const paxTypeFareBreakdown = flightDetails.price.pax_type_price_breakdown;
      let count = 0;
      let fare = null;

      switch (paxType) {
        case "child":
          count = flightDetails["child_count"] ?? 0;
          fare = paxTypeFareBreakdown.child;
          break;

        case "infant":
          count = flightDetails["infant_count"] ?? 0;
          fare = paxTypeFareBreakdown.infant;
          break;

        default:
          count = flightDetails["adult_count"] ?? 0;
          fare = paxTypeFareBreakdown.adult;
          break;
      }

      const agTotalAmount = fare.ag_total_amount
        ? fare.ag_total_amount.value
        : 0;
      const agTotalCurrency = fare.ag_total_amount
        ? fare.ag_total_amount.currency
        : "PKR";

      const price = this.formatCurrency(Number(agTotalAmount), agTotalCurrency);

      const amount = agTotalAmount;

      return {
        label: count.toString(),
        price,
        amount,
      };
    },
  },
  computed: {
    bookingActions(): bookingActions {
      return this.$store.getters.bookingActions;
    },
    isIssuanceEnabled(): boolean {
      const actions = this.$store.getters.bookingActions;
      return actions.is_ticket_issuance_enabled;
    },
    isRefundEnabled(): boolean {
      const actions = this.$store.getters.bookingActions;
      return actions.is_refund_enabled;
    },
    isVoidEnabled(): boolean {
      const actions = this.$store.getters.bookingActions;
      return actions.is_ticket_void_enabled;
    },
    isCancelEnabled(): boolean {
      const actions = this.$store.getters.bookingActions;
      return actions.is_ticket_cancel_enabled;
    },
    isDownloadEnabled(): boolean {
      const actions = this.$store.getters.bookingActions;
      return actions.is_download_ticket;
    },
    isAirlineTicketEnabled(): boolean {
      const actions = this.$store.getters.bookingActions;
      return actions.is_airline_ticket_enabled;
    },
    canBeRefunded(): boolean {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      const refundAirlines = ["AIRBLUE", "PIA", "AIRSIAL"];
      const flight_sub_provider: string =
        this.flightDetails?.flight_sub_provider || "";
      const isSuperOrOperationUser = UTILS.isUser(
        USER_ROLES.AG_SUPER_USER,
        USER_ROLES.OPERATIONS
      );
      if (
        _.capitalize(bookingStatus) === BOOKING_STATUSES.ISSUED &&
        refundAirlines.includes(flight_sub_provider) &&
        isSuperOrOperationUser
      ) {
        return true;
      } else {
        return false;
      }
    },
    rawSabreExpiry(): string[] | null {
      const flightDetails = this.flightDetails;
      return flightDetails && flightDetails.raw_pnr_expiry?.length
        ? flightDetails.raw_pnr_expiry
        : null;
    },
    discountData(): DealDiscount | undefined {
      return this.flightDetails ? this.flightDetails.discount_data : undefined;
    },
    agDealDiscount(): ExtendedPrice | null | undefined {
      return this.flightDetails?.ag_deal_discount;
    },
    flightLegs(): FlightLeg[] {
      return this.$store.getters.flightDetails.flight_legs;
    },
    additionalFlightDetails(): AdditionalFlightDetails[] {
      const details = this.flightLegs.map((leg) => {
        const baggageInfo = this.getBaggageInfo(leg.baggage_info);
        const hasMeal: boolean = leg.has_meal;

        let details: AdditionalFlightDetails = {
          hasMeal,
          baggageInfo,
        };

        return details;
      });

      return details;
    },
    showAdultBreakDown() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      return Number(flightDetails?.adult_count) > 0;
    },
    showChildBreakDown() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      return Number(flightDetails?.child_count) > 0;
    },
    showInfantBreakDown() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      return Number(flightDetails?.infant_count) > 0;
    },
    Currency() {
      return UTILS.getCurrency() || "PKR";
    },
    isFlightWaitListed() {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      if (_.capitalize(bookingStatus) === BOOKING_STATUSES.WAITLIST) {
        return true;
      } else {
        return false;
      }
    },
    isInitiateBooking(): boolean {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      return _.capitalize(bookingStatus) === BOOKING_STATUS.INITIATED;
    },
    renderBookingId() {
      return this.$route.params.id;
    },
    flightBookingStatus(): BOOKING_STATUS {
      return this.$store.getters.flightBookingStatus;
    },
    flightDetails(): FlightDetailsType {
      return this.$store.getters.flightDetails;
    },
    supplierPnr() {
      return this.$store.state?.flightBookingModule?.supplierPnr;
    },
    pnrExpiresAt(): string {
      const pnrExpiryTime =
        this.$store.state?.flightBookingModule?.pnrExpiryDate;
      return pnrExpiryTime ? pnrExpiryTime : "";
    },
    isLoading() {
      return this.$store.getters.isFlightBookingLoading;
    },
    isCancelBookingLoading() {
      return this.$store.getters.isFlightCancelBookingLoading;
    },
    isDownloadTicketLoading() {
      return this.$store.getters.isDownloadTicketLoading;
    },
    isBookingCancelled() {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      return (
        _.capitalize(bookingStatus) === BOOKING_STATUSES.CANCELED ||
        _.capitalize(bookingStatus) === BOOKING_STATUSES.VOIDED ||
        _.capitalize(bookingStatus) === BOOKING_STATUSES.FAILED
      );
    },
    luggageInfo(): string {
      const flight_legs = this.flightDetails?.flight_legs || [];
      const leg_info = flight_legs.find((x: FlightLeg) => x.baggage_info);

      if (!_.isEmpty(leg_info?.baggage_info) && leg_info?.baggage_info) {
        return renderBaggageInfo(leg_info?.baggage_info);
      }

      return "";
    },
    hasReturnluggageInfo(): string {
      const flight_legs =
        this.flightDetails?.flight_legs[1]?.baggage_info || "";

      if (flight_legs) {
        return renderBaggageInfo(flight_legs);
      }

      return "";
    },
    hasMeal(): boolean {
      const flight_legs = this.flightDetails?.flight_legs || [];
      const leg_info = flight_legs.find((x: FlightLeg) => x.baggage_info);

      return leg_info ? leg_info.has_meal : false;
    },
    isReturnMeal(): boolean {
      return this.flightDetails?.flight_legs[1]?.has_meal || false;
    },
    renderStatusSeverity() {
      switch (_.capitalize(this.flightBookingStatus)) {
        case BOOKING_STATUSES.CANCELED:
          return "danger";
        case BOOKING_STATUSES.CONFIRMED:
          return "primary";
        case BOOKING_STATUSES.ISSUED:
          return "primary";
        case BOOKING_STATUSES.WAITLIST:
          return "danger";
        default:
          return "primary";
      }
    },
    showAdditionalDetails(): boolean {
      return this.hasMeal || !!this.luggageInfo;
    },
    showAdditionalDetailsOnReturn(): boolean {
      return this.isReturnMeal || !!this.hasReturnluggageInfo;
    },
    isVoidFlightTicketLoading() {
      return this.$store.getters.isVoidFlightTicketLoading;
    },
    isIssuedAndisPIAAndisNotInternational() {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      const isInternational =
        this.$store.getters.flightDetails.is_international;
      const isPIA =
        this.$store.getters.flightDetails?.flight_legs[0].segment[0]
          .operating_airline.name;
      if (
        _.capitalize(bookingStatus) === BOOKING_STATUSES.ISSUED &&
        isInternational === false &&
        isPIA === "Pakistan International Airlines"
      ) {
        return true;
      } else {
        return false;
      }
    },
    isFlightIssued() {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      if (
        _.capitalize(bookingStatus) === BOOKING_STATUSES.ISSUED ||
        _.capitalize(bookingStatus) === BOOKING_STATUSES.VOIDED
      ) {
        return true;
      } else {
        return false;
      }
    },
    isFlightCancelled() {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      if (
        _.capitalize(bookingStatus) === BOOKING_STATUSES.CANCELED ||
        _.capitalize(bookingStatus) === BOOKING_STATUSES.VOIDED
      ) {
        return true;
      } else {
        return false;
      }
    },
    hasPassengers() {
      const flightDetails = this.$store.getters.flightDetails;
      return Boolean(flightDetails?.passengers?.length);
    },
  },
  beforeMount() {
    const booking_id = this.$route.params.id;
    this.$store.dispatch("getFlightBooking", { booking_id });
  },
  watch: {
    "$store.getters.flightDetails": {
      handler(flightDetails) {
        const bookingStatus = this.$store.getters.flightBookingStatus as string;
        const isBookingWaitlisted =
          bookingStatus === BOOKING_STATUSES.WAITLIST.toLocaleUpperCase();

        if (isBookingWaitlisted) {
          this.isWaitListDialog = true;
        }
        const isBookingConfirmed =
          bookingStatus === BOOKING_STATUSES.CONFIRMED.toLocaleUpperCase();

        if (isBookingConfirmed && flightDetails?.price?.fare_updated) {
          this.isPriceUpdatedDialog = true;
        }
      },
    },
  },
});
export interface SendAirlineTicketBodyRequest {
  pnr: string;
  airline_provider: string;
  emails: string[];
}
</script>

<style lang="css" scoped>
.selected {
  border: 2px solid var(--green-color) !important;
}

.bd-expiry-card {
  --theme-color: var(--green-color);
}

.bd-expiry-card .bde-title {
  font-weight: 600;
  color: var(--theme-color);
  margin-bottom: 10px;
}

.flight-booking-details .has-flag {
  position: relative;
  padding: 50px 24px 10px;
}
.edit-fare-text-field {
  max-width: 400px;
  margin: auto;
}
.modal-content {
  margin-bottom: 10px;
}
</style>
