<template>
  <MAccordion :initial-open="true">
    <template v-slot:title>
      <div class="org-details-title">
        <AgIconInfoBar
          test-id=""
          :title="
            getFPTitle(
              financialProfile.financial_profile_name,
              financialProfile.platform_id
            )
          "
          class="section-title"
          mPrepandIcon="m-payment-management"
        />
        <MChip class="status-chip" :variant="isActive ? 'success' : 'error'">
          {{ financialProfile?.status }}
        </MChip>
        <MChip
          class="status-chip"
          v-if="financialProfile.is_default"
          variant="success"
        >
          DEFAULT
        </MChip>
      </div>
    </template>
    <template v-slot:content>
      <div class="content-container">
        <div class="container">
          <span class="plan_name">
            <MIcon
              :type="getFPType(financialProfile.plan_name)"
              class="details-type-icon icon"
              :name="getFPIconName(financialProfile.plan_name)"
              width="24"
              height="24"
            />
            {{ financialProfile.plan_name.toUpperCase() }}
          </span>
          <div v-if="enableActions" class="update-btn-container">
            <MButton @click="onUpdateFinancialBtnClick">Update</MButton>
            <MButton @click="onUnLinknBtnClick">Unlink</MButton>
          </div>
        </div>
        <!-- Tiles container -->
        <div class="grid-container">
          <OrganizationDetailTile
            subHeading="Net Available Limit"
            :heading="
              formatCurrency(
                Number(financialProfile.financial_data?.total_balance_current),
                financialProfile.financial_data?.currency_code
              )
            "
            icon="m-request-quote"
          >
          </OrganizationDetailTile>

          <OrganizationDetailTile
            subHeading="Available Limit"
            :heading="
              formatCurrency(
                Number(financialProfile.financial_data?.available_credit),
                financialProfile.financial_data?.currency_code
              )
            "
            icon="m-wallet"
          >
          </OrganizationDetailTile>

          <OrganizationDetailTile
            subHeading="Total Not Invoiced Sales"
            :heading="
              formatCurrency(
                Number(
                  financialProfile.financial_data?.total_of_not_invoiced_tickets
                ),
                financialProfile.financial_data?.currency_code
              )
            "
            icon="m-receipt-long"
          >
            <template #button>
              <MFabButton
                @click="handleOpenNotInvoicedDialog"
                type="outlined"
                icon="m-receipt-long"
              />
            </template>
          </OrganizationDetailTile>

          <OrganizationDetailTile
            subHeading="Total Payable Amount"
            :heading="
              formatCurrency(
                Number(financialProfile.financial_data?.current_balance),
                financialProfile.financial_data?.currency_code
              )
            "
            icon="m-credit-score"
          >
          </OrganizationDetailTile>

          <OrganizationDetailTile
            subHeading="Temporary Credit Limit"
            :heading="
              formatCurrency(
                Number(financialProfile.financial_data?.temporary_credit_limit),
                financialProfile.financial_data?.currency_code
              )
            "
            icon="m-credit-limit-management"
          >
          </OrganizationDetailTile>

          <OrganizationDetailTile
            subHeading="Permanent Credit Limit"
            :heading="
              formatCurrency(
                Number(financialProfile.financial_data?.credit_limit),
                financialProfile.financial_data?.currency_code
              )
            "
            icon="m-request-quote"
          >
          </OrganizationDetailTile>
        </div>
      </div>
    </template>
  </MAccordion>
  <UnlinkFinancialProfileConfirmationDialog
    @handleClose="handleReject"
    @handleAccept="handleApprove"
    :financial_profile_name="financialProfile?.financial_profile_name"
    :is-open="isUnlinkConfirmationDialogOpened"
  />
  <ViewNotInvoicedReceiptsDialog
    :financial-profile="financialProfile"
    :is-open="isViewNotInvoicedReceiptsDialogOpened"
    @handleClose="handleCloseNotInvoicedDialog"
  />
  <UpdateFinancialProfileProvidersDialog
    :financial-profile-id="financialProfile?.public_id"
    :is-open="isUpdateFinancialProfileProvidersDialogOpened"
    :pia-provider="financialProfile?.pia_provider"
    :airblue-provider="financialProfile?.airblue_provider"
    @handleClose="handleCloseUpdateProvidersDialog"
    @onSuccess="onUpdateProvidersSuccess"
  />
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import {
  MIcon,
  MAccordion,
  MChip,
  MButton,
  MFabButton,
} from "@aeroglobe/ag-core-ui";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { IconName } from "@aeroglobe/ag-core-ui/dist/src/components/material/icons/icons.type";
import OrganizationDetailTile from "@/components/OrganizationDetailTile.vue";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import UnlinkFinancialProfileConfirmationDialog from "./UnlinkFinancialProfileConfirmationDialog.vue";
import ViewNotInvoicedReceiptsDialog from "./ViewNotInvoicedReceiptsDialog.vue";
import UpdateFinancialProfileProvidersDialog from "@/modules/Organization/components/OrganizationModals/UpdateFinancialProfileProviders.vue";

export default defineComponent({
  name: "OrganizationFinancialProfile",
  components: {
    OrganizationDetailTile,
    MIcon,
    MAccordion,
    MButton,
    MChip,
    UnlinkFinancialProfileConfirmationDialog,
    ViewNotInvoicedReceiptsDialog,
    MFabButton,
    UpdateFinancialProfileProvidersDialog,
  },
  data() {
    return {
      isUnlinkConfirmationDialogOpened: false,
      isUpdateFinancialProfileProvidersDialogOpened: false,
      isViewNotInvoicedReceiptsDialogOpened: false,
    };
  },
  props: {
    financialProfile: {
      type: Object as PropType<IFinancialProfile>,
      required: true,
    },
    enableActions: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getFPTitle(name: string, id: string) {
      return name + " - " + id;
    },
    formatCurrency(amount: number, currency: string | undefined) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      const formatedAmount = formatter.format(amount);
      return `${currency?.toUpperCase() ?? ""} ${formatedAmount ?? ""}`;
    },
    getFPIconName(plan_name: string): IconName {
      const plan = plan_name.split(" ")[1]?.toLowerCase();
      switch (plan) {
        case "elite":
          return "m-ag-elite";
        case "pro":
          return "m-ag-pro";
        case "lite":
          return "m-ag-elite";
        case "smart":
          return "m-ag-smart";
        default:
          return "m-ag-elite";
      }
    },
    getFPType(plan_name: string): string {
      const plan = plan_name.split(" ")[1]?.toLowerCase();
      return plan;
    },
    onUnLinknBtnClick() {
      this.isUnlinkConfirmationDialogOpened = true;
    },
    onUpdateFinancialBtnClick() {
      this.isUpdateFinancialProfileProvidersDialogOpened = true;
    },
    onSuccessHandler() {
      this.isUnlinkConfirmationDialogOpened = false;
    },
    handleApprove() {
      this.$store.dispatch("unlinkFinancialProfile", {
        id: this.financialProfile?.public_id,
        successCallback: this.onSuccessHandler,
      });
    },
    handleReject() {
      this.isUnlinkConfirmationDialogOpened = false;
    },
    handleOpenNotInvoicedDialog() {
      this.isViewNotInvoicedReceiptsDialogOpened = true;
    },
    handleCloseNotInvoicedDialog() {
      this.isViewNotInvoicedReceiptsDialogOpened = false;
    },
    handleCloseUpdateProvidersDialog() {
      this.isUpdateFinancialProfileProvidersDialogOpened = false;
    },
    onUpdateProvidersSuccess() {
      this.isUpdateFinancialProfileProvidersDialogOpened = false;
      this.$store.dispatch("fetchOrganization", this.$route.params.id);
    },
  },
  computed: {
    isActive(): boolean {
      return this.financialProfile.status === ORGANIZATION_STATUSES.ACTIVE;
    },
  },
});
</script>

<style scoped lang="scss">
.accordion-title-container {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
  }
}
.accordion-chip-container {
  display: flex;
  gap: 10px;
}
.accordion-title {
  font-size: 20px;
  margin-right: 20px;
  font-weight: 600;
  text-align: start;
  @media (max-width: 767px) {
    text-align: center;
  }
}
.content-container {
  padding: 0px 0 0 0;
  .container {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
  }
}

.plan_name {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  margin: 10px;
}
.plan_name > .icon {
  margin-top: 1px;
  margin-right: 5px;
}
.tile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;
  width: 100%;
}
.white-icon {
  color: #fff;
}

.details-type-icon[type="pro"] {
  color: var(--m-info-color);
}
.details-type-icon[type="elite"] {
  color: var(--m-success-color);
}
.details-type-icon[type="smart"] {
  color: var(--m-warning-color);
}
.details-type-icon[type="lite"] {
  color: var(--m-success-color);
}
.status-chip {
  font-weight: 600;
  max-width: fit-content;
}
.update-btn-container {
  display: flex;
  gap: 10px;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

@media (max-width: 1350px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 963px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
.org-details-title {
  display: flex;
  gap: 10px;
  @media (max-width: 867px) {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
