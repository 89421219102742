import { GetterTree } from "vuex";
import { StoreState } from "@/store/type";
import { IFareManagementState } from "@/interfaces/fareManagementState.interface";
import { fareManagementResponse } from "../constants";

const getters: GetterTree<IFareManagementState, StoreState> = {
  isSabreFaresLoading: (state: IFareManagementState): boolean =>
    state.isSabreFaresLoading,
  sabreFares: (state: IFareManagementState): fareManagementResponse | null =>
    state.sabreFares,
};

export default getters;
