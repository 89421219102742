import { Module } from "vuex";
import { StoreState } from "@/store/type";

import mutations from "./mutations";
import actions from "./actions";
import state, { IFITState } from "./state";
import getters from "./getters";

const IFITModule: Module<IFITState, StoreState> = {
  state,
  mutations,
  actions,
  getters,
};

export default IFITModule;
