import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87d7015e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "traveler-form-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewTravelerCard = _resolveComponent("NewTravelerCard")!
  const _component_NewTravelerCardPreview = _resolveComponent("NewTravelerCardPreview")!
  const _component_NewTravelerCardAccordion = _resolveComponent("NewTravelerCardAccordion")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localTravelers, (traveler, index) => {
    return (_openBlock(), _createBlock(_component_NewTravelerCardAccordion, {
      key: index,
      headline: _ctx.renderAccordionHeadline(traveler.isInternational),
      title: _ctx.renderAccordionTitle(index),
      errorsLength: _ctx.errorsLength(index),
      initialOpen: index === 0,
      ref_for: true,
      ref: `accordion-${index}`
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_NewTravelerCard, {
            traveler: traveler,
            isInternational: traveler.isInternational,
            errors: _ctx.localErrors[index],
            "onUpdate:traveler": ($event: any) => (_ctx.updateTraveler(index, $event)),
            onValidate: (field, isValid) => _ctx.validateTraveler(index, field, isValid),
            ref_for: true,
            ref: `travelerCard-${index}`
          }, null, 8, ["traveler", "isInternational", "errors", "onUpdate:traveler", "onValidate"]),
          _createVNode(_component_NewTravelerCardPreview, {
            title: "Traveler Details",
            traveler: traveler,
            previewFields: _ctx.getPreviewFields(traveler.isInternational),
            formatPreviewValue: _ctx.formatPreviewValue,
            errors: _ctx.localErrors[index]
          }, null, 8, ["traveler", "previewFields", "formatPreviewValue", "errors"])
        ])
      ]),
      _: 2
    }, 1032, ["headline", "title", "errorsLength", "initialOpen"]))
  }), 128))
}