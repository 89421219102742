import {
  IAGErrorResponse,
  IAGResponse,
} from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import OrganizationService from "@/modules/Organization/services/organization.service";
import { ActionContext, ActionTree } from "vuex";
import { IOrganizationState } from "@/ag-portal-common/interfaces/organizationState.interface";
import { UpdateOrganizationPreferenceRequest } from "../models/UpdateOrganizationPreferenceRequest";
import {
  AddWhatsappMemberDto,
  ContentUpload,
  OrganizationContentUpload,
  CreateOrganizationDto,
  UpdateOrganizationDto,
  AuthorizeWhatsppMemberOTPDto,
  StartDateEndDateDTO,
} from "@/modules/Organization/dtos/organization.dto";
import { AUTH_EVENTS, authBus } from "@/ag-portal-common/eventBusses/auth";
import { AddAgentDto } from "@/modules/Organization/dtos/agent.dto";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { formatString, stringEncrypt } from "@/ag-portal-common/utils/helpers";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import analyticsService from "@/analytic.service";
import {
  ORGANIZATION_ANALYTICS_EVENTS,
  CURRENCY_CONVERSION_ANALYTICS_EVENTS,
} from "@/modules/Organization/constants/analyticsEvents";
import AgentService from "@/modules/Agent/services/agent.service";
import { UpdateWebsiteConfigDto } from "../dtos/website.dto";
import { IWebsiteContent } from "@/ag-portal-common/interfaces/website.interface";
import { IPassenger } from "@/ag-portal-common/interfaces/passenger.interface";
import { OrganizationProductivityParamsRequestModel } from "../models/FetchOrganizationProductivityParams.request";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";
import storageService from "@/ag-portal-common/services/storage.service";
import { StoreState } from "@/store/type";
import { ORGANIZATION_STATUS, Organization } from "@/modules/Auth/types";
import { UpdateFinancialProfileProviders } from "../models/UpdateFinancialProfileProviders.request";

const actions: ActionTree<IOrganizationState, StoreState> = {
  async createOrganization(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      body,
      callback,
    }: { body: CreateOrganizationDto; callback: (id: string) => void }
  ) {
    let response: IAGResponse = {
      success: false,
    };
    const methodName = "actions.createOrganization";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableCreateOrganizationLoading");

      const organizationService = new OrganizationService();
      response = await organizationService.create(body);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        callback(response.data.public_id);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          NOTIFICATION_MESSAGES.ORGANIZATION_CREATE_SUCCESS;
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      loggerService.logError(`${methodName}:`, exception);
    }
    notificationService.triggerNotification();
    context.commit("disableCreateOrganizationLoading");
  },
  async updateOrganization(
    context: ActionContext<IOrganizationState, StoreState>,
    { body, callback }: { body: UpdateOrganizationDto; callback: () => void }
  ) {
    let response: IAGResponse = {
      success: false,
    };

    const methodName = "actions.updateOrganization";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableUpdateOrganizationLoading");

      const organizationService = new OrganizationService();
      response = await organizationService.update(body);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        callback();
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          NOTIFICATION_MESSAGES.ORGANIZATION_UPDATE_SUCCESS;
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    }
    context.commit("disableUpdateOrganizationLoading");
    notificationService.triggerNotification();
  },

  async deleteOrganization(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      organizationId,
      callback,
    }: { organizationId: string; callback: () => void }
  ) {
    let response: IAGResponse<any> = {
      success: false,
    };

    const methodName = "actions.deleteOrganization";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableUpdateOrganizationLoading");

      const organizationService = new OrganizationService();
      response = await organizationService.delete(organizationId);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        callback();
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = response?.message || "";
      } else {
        throw response;
      }
    } catch (exception: any) {
      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    }
    context.commit("disableUpdateOrganizationLoading");
    notificationService.triggerNotification();
  },

  async updateStatus(
    context: ActionContext<IOrganizationState, StoreState>,
    { body, callback }: { body: UpdateOrganizationDto; callback: () => void }
  ) {
    const methodName = "actions.updateStatus";
    let response: IAGResponse = { success: false };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableUpdateOrganizationLoading");

      const organizationService = new OrganizationService();
      response = await organizationService.update(body);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        callback();
        context.commit("updateStatus", response.data);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = formatString(
          NOTIFICATION_MESSAGES.ORGANIZATION_STATUS_UPDATE_SUCCESS,
          body.status === ORGANIZATION_STATUSES.ACTIVE
            ? "activated"
            : "deactivated"
        );
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      context.commit("disableUpdateOrganizationLoading");
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      loggerService.logError(`${methodName}:`, exception);
    }
    notificationService.triggerNotification();
  },
  async fetchOrganizations(
    context: ActionContext<IOrganizationState, StoreState>
  ) {
    const methodName = "actions.fetchOrganizations";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableFetchOrganizationsLoading");
      const organizationService = new OrganizationService();
      const response: IAGResponse = await organizationService.getAll();
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("saveOrganizations", response.data);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      context.commit("saveOrganizations", []);
      loggerService.logError(`${methodName}:`, exception);
    }
  },
  async fetchOrganizationsByCreationDate(
    context: ActionContext<IOrganizationState, StoreState>,
    payload: StartDateEndDateDTO
  ) {
    const methodName = "actions.fetchOrganizationsByCreationDate";
    context.commit("saveOrganizations", []);
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableFetchOrganizationsLoading");
      const organizationService = new OrganizationService();
      const response: IAGResponse =
        await organizationService.getAllByCreationDate(payload);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("saveOrganizations", response.data);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      context.commit("saveOrganizations", []);
      loggerService.logError(`${methodName}:`, exception);
    }
  },
  async fetchOrganization(
    context: ActionContext<IOrganizationState, StoreState>,
    id: string
  ) {
    const methodName = "actions.fetchOrganization";
    let response: IAGResponse = {
      success: false,
    };
    try {
      context.commit("setErrorOrganizationNotFound", false);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableFetchOrganizationLoading");
      const organizationService = new OrganizationService();
      response = await organizationService.getById(id, {
        financial_data: "show",
      });
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("saveOrganization", response.data);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      context.commit("saveOrganization", null);
      context.commit("setErrorOrganizationNotFound", true);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      loggerService.logError(`${methodName}:`, exception);
      notificationService.triggerNotification();
    } finally {
      context.commit("disableFetchOrganizationLoading");
    }
  },
  async updateOrganizationPreference(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      payload,
      organizationId,
    }: { payload: UpdateOrganizationPreferenceRequest; organizationId: string }
  ) {
    const methodName = "actions.updateOrganizationPreference";
    let response: IAGResponse = {
      success: false,
    };
    try {
      if (!organizationId) {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          NOTIFICATION_MESSAGES.ORGANIZATION_NOT_FOUND;
      }
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, payload);
      context.commit("enableUpdateOrganizationLoading");
      const organizationService = new OrganizationService();
      response = await organizationService.updatePreference(
        payload,
        organizationId
      );
      context.commit("disableUpdateOrganizationLoading");
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        authBus.emit(AUTH_EVENTS.UPDATE_PREFERENCES, response.data);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          NOTIFICATION_MESSAGES.ORGANIZATION_UPDATE_PREFERENCE_SUCCESS;
        analyticsService.logActionEvent(
          ORGANIZATION_ANALYTICS_EVENTS.UPDATE_ORGANIZATION_PREFERENCE,
          {
            displayName: payload.displayName,
            color: payload.defaultColor,
            isUpdateLogo: payload.logoFile ? true : false,
          }
        );
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      context.commit("disableUpdateOrganizationLoading");
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    }
    notificationService.triggerNotification();
  },

  async addAgentInOrganization(
    context: ActionContext<IOrganizationState, StoreState>,
    payload: { body: AddAgentDto; orgId: string; callback: () => void }
  ) {
    let response: IAGResponse = {
      success: false,
    };
    const methodName = "actions.addAgentInOrganization";
    try {
      context.commit("enableAddAgentLoading");
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const organizationService = new OrganizationService();
      response = await organizationService.createAgent(
        payload.body,
        payload.orgId
      );
      if (response.success && response.status === StatusCodes.OK) {
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = formatString(
          NOTIFICATION_MESSAGES.ADD_AGENT_SUCCESS,
          payload.body.role.charAt(0) + payload.body.role.slice(1).toLowerCase()
        );
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("updateAgents", response.data.agents);
        payload.callback();
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      context.commit("disableAddAgentLoading");
      loggerService.logError(`${methodName}:`, exception);
    }

    notificationService.triggerNotification();
  },

  resetOrganization(context: ActionContext<IOrganizationState, StoreState>) {
    context.commit("resetOrganizationState");
  },

  async deleteAgentFromOrganization(
    context: ActionContext<IOrganizationState, StoreState>,
    payload: { agentId: string; callback: () => void }
  ) {
    const methodName = "actions.deleteUser";
    let response: IAGResponse = { success: false };
    try {
      context.commit("enableIsDeletingAgentFromOrganization");
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const agentService = new AgentService();
      response = await agentService.deleteAgent(payload.agentId);
      context.commit("deleteAgentFromOrganization", payload.agentId);
      notificationService.type = NOTIFICATION_TYPES.SUCCESS;
      notificationService.description =
        NOTIFICATION_MESSAGES.AGENT_DELETE_SUCCESS;
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
    }
    context.commit("disableIsDeletingAgentFromOrganization");
    notificationService.triggerNotification();
    payload.callback();
  },
  async fetchCreateFormDetails(
    context: ActionContext<IOrganizationState, StoreState>
  ) {
    const methodName = "actions.fetchCreateFormDetails";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableFetchOrganizationsLoading");
      const organizationService = new OrganizationService();
      const response: IAGResponse = await organizationService.getFormDetails();
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("saveOrganizations", response.data);
        return response.data;
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      context.commit("saveOrganizations", []);
      loggerService.logError(`${methodName}:`, exception);
    }
  },
  // *********** Get Organization Form Details ***********
  async fetchOrganizationCreateFormDetails(
    context: ActionContext<IOrganizationState, StoreState>
  ) {
    const methodName = "actions.fetchOrganizationCreateFormDetails";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setFetchingOrganization", true);
      context.commit("setOrganizationSectors", null);
      context.commit("setOrganizationCurrency", null);
      context.commit("setPiaProviders", null);
      const organizationService = new OrganizationService();
      const response: IAGResponse = await organizationService.getFormDetails();
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setOrganizationSectors", response.data?.sector);
        context.commit("setOrganizationCurrency", response.data?.currency);
        context.commit("setPiaProviders", response.data?.pia_provider);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
    } finally {
      context.commit("setFetchingOrganization", false);
    }
  },

  async updateWebsiteConfiguration(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      payload,
      organizationId,
    }: {
      payload: UpdateWebsiteConfigDto;
      organizationId: string;
    }
  ) {
    const methodName = "actions.updateWebsiteConfiguration";
    let response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, payload);
      context.commit("setUpdateWebsiteConfigurationLoading", true);
      const organizationService = new OrganizationService();
      response = await organizationService.updateWebsiteConfiguration(
        payload,
        organizationId
      );
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = response.data?.message;
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setUpdateWebsiteConfigurationLoading", false);
    }
    notificationService.triggerNotification();
  },

  async getWebsiteConfiguration(
    context: ActionContext<IOrganizationState, StoreState>,
    { organizationId }: { organizationId: string }
  ) {
    const methodName = "actions.getWebsiteConfiguration";
    const response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setWebsiteConfigLoading", true);
      const organizationService = new OrganizationService();
      const config_response = await organizationService.getWebsiteConfiguration(
        organizationId
      );
      const content_response = await organizationService.getWebsiteContent(
        config_response?.data?.data?.web_slug
      );
      if (
        content_response.success &&
        content_response.status === StatusCodes.OK
      ) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setWebsiteConfig", config_response.data?.data);
        context.commit("setWebsiteContent", content_response.data?.data);
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          content_response.error
        );
        throw content_response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
    } finally {
      context.commit("setWebsiteConfigLoading", false);
    }
  },

  async updateWebsiteContent(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      payload,
      organizationId,
    }: { payload: IWebsiteContent; organizationId: string }
  ) {
    const methodName = "actions.updateWebsiteConfiguration";
    let response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, payload);
      context.commit("setUpdateWebsiteContentLoading", true);
      const organizationService = new OrganizationService();
      response = await organizationService.updateWebsiteContent(
        payload,
        organizationId
      );
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = response.data?.message;
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setUpdateWebsiteContentLoading", false);
    }
    notificationService.triggerNotification();
  },

  // **** Get Organization Passengers ****
  async getOrganizationPassengers(
    context: ActionContext<IOrganizationState, StoreState>,
    organizationId: string
  ) {
    const methodName = "actions.getOrganizationPassengers";
    try {
      context.commit("fetchingPassengers", true);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const organizationService = new OrganizationService();
      const response: IAGResponse =
        await organizationService.getOrganizationPassengers(organizationId);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("fetchingPassengers", false);
        const passengers: IPassenger[] = response.data;
        const updatedPassengers = passengers.map((x) => {
          return {
            ...x,
            fullName: x?.first_name + " " + x?.last_name,
          };
        });
        context.commit("setPassengers", updatedPassengers);
      } else {
        context.commit("fetchingPassengers", false);
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      context.commit("fetchingPassengers", false);
      loggerService.logError(`${methodName}:`, exception);
    }
  },

  // **** Create Organization Passenger ****
  async createOrganizationPassenger(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      body,
      organizationId,
      onSuccessHandler,
    }: {
      body: IPassenger[];
      organizationId: string;
      onSuccessHandler: () => void;
    }
  ) {
    const methodName = "actions.createOrganizationPassenger";
    let response: IAGResponse = {
      success: false,
    };
    try {
      context.commit("setPassengerActionLoading", true);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const organizationService = new OrganizationService();
      response = await organizationService.createPassenger(
        body,
        organizationId
      );
      if (response.success && response.status === StatusCodes.CREATED) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("addPassenger", {
          passenger: response.data?.passengers[0],
        });
        context.commit("setPassengerActionLoading", false);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = response.message as string;
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        response?.message || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setPassengerActionLoading", false);
    }
    notificationService.triggerNotification();
  },

  // **** Update Organization Passenger ****
  async updateOrganizationPassengerById(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      body,
      organizationId,
      passengerId,
    }: {
      body: IPassenger;
      organizationId: string;
      passengerId: string;
      onSuccessHandler: () => void;
    }
  ) {
    const methodName = "actions.updateOrganizationPassengerById";
    let response: IAGResponse = {
      success: false,
    };
    try {
      context.commit("setPassengerActionLoading", true);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const organizationService = new OrganizationService();
      response = await organizationService.updateOrganizationPassengerById(
        body,
        organizationId,
        passengerId
      );
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("updatePassenger", { passengerId, passenger: body });
        context.commit("setPassengerActionLoading", false);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = response.message as string;
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        response?.message || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setPassengerActionLoading", false);
    }
    notificationService.triggerNotification();
  },

  // **** Delete Organization Passenger ****
  async deleteOrganizationPassengerById(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      organizationId,
      passengerId,
    }: { organizationId: string; passengerId: string }
  ) {
    const methodName = "actions.deleteOrganizationPassengerById";
    let response: IAGResponse = {
      success: false,
    };
    try {
      context.commit("setPassengerActionLoading", true);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const organizationService = new OrganizationService();
      response = await organizationService.deleteOrganizationPassengerById(
        organizationId,
        passengerId
      );
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        const passengers = context.state.passengers;
        const updatedPassengers = passengers?.filter(
          (item) => item.public_id !== passengerId
        );
        context.commit("setPassengers", updatedPassengers);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = response?.message as string;
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setPassengerActionLoading", false);
    }
    notificationService.triggerNotification();
  },

  async websiteUploadContent(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      payload,
      organizationId,
    }: { payload: ContentUpload; organizationId: string }
  ) {
    const methodName = "actions.websiteUploadContent";
    let public_id: string | null = null;
    let content_url: string | null = null;
    let response: IAGResponse = {
      success: false,
    };

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, payload);

      const organizationService = new OrganizationService();

      response = await organizationService.organizationContentUpload(
        payload,
        organizationId
      );

      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);

        public_id = response.data?.data.public_id;
        content_url = response.data?.data.content_url;

        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = response.data?.message;

        analyticsService.logActionEvent(
          ORGANIZATION_ANALYTICS_EVENTS.WEBSITE_CONTENT_UPLOAD,
          payload
        );
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );

        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);

      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    }

    notificationService.triggerNotification();

    return [public_id, content_url];
  },

  // *********** Get Whatsapp Members ***********
  async getWhatsappMembers(
    context: ActionContext<IOrganizationState, StoreState>,
    organizationId
  ) {
    const methodName = "actions.getWhatsappMembers";
    let response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setWhatsappMembersLoading", true);
      const organizationService = new OrganizationService();
      response = await organizationService.getWhatsappMembers(organizationId);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setWhatsMembers", response.data?.data?.results);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          "Whatsapp Members Successfully Fetched";
        analyticsService.logActionEvent(
          ORGANIZATION_ANALYTICS_EVENTS.GET_WHATSAPP_MEMBERS
        );
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setWhatsappMembersLoading", false);
    }
    notificationService.triggerNotification();
  },

  // *********** Add Whatsapp Member ***********
  async addWhatsappMember(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      payload,
      organizationId,
      onSuccessHandler,
    }: {
      payload: AddWhatsappMemberDto;
      organizationId: string;
      onSuccessHandler: () => void;
    }
  ) {
    const methodName = "actions.addWhatsappMember";
    let response: IAGResponse = {
      success: false,
    };
    try {
      const updatedPayload = {
        ...payload,
        contact_number: payload.contact_number?.replace(
          /^0(\d{3})\s?(\d{7})$/,
          "92$1$2"
        ),
      };

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setWhatsappActionLoading", true);
      const organizationService = new OrganizationService();
      response = await organizationService.addWhatsappMember(
        updatedPayload,
        organizationId
      );
      if (response.success && response.status === StatusCodes.CREATED) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        onSuccessHandler();
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = "Whatsapp member added successfully";
        analyticsService.logActionEvent(
          ORGANIZATION_ANALYTICS_EVENTS.ADD_WHATSAPP_MEMBERS
        );
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setWhatsappActionLoading", false);
    }
    notificationService.triggerNotification();
  },

  // *********** Delete Whatsapp Member ***********
  async deleteWhatsappMemberById(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      whatsappMemberId,
      organizationId,
      successHandler,
    }: {
      whatsappMemberId: string;
      organizationId: string;
      successHandler: () => void;
    }
  ) {
    const methodName = "actions.deleteWhatsappMemberById";
    let response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setWhatsappActionLoading", true);
      const organizationService = new OrganizationService();
      response = await organizationService.deleteWhatsappMembers(
        organizationId,
        whatsappMemberId
      );
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        successHandler();
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          "Whatsapp member is successfully deleted";
        analyticsService.logActionEvent(
          ORGANIZATION_ANALYTICS_EVENTS.DELETE_WHATSAPP_MEMBERS
        );
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setWhatsappActionLoading", false);
    }
    notificationService.triggerNotification();
  },

  // *********** Generate OTP For Whatsapp Member ***********
  async generateOTPForWhatsappMember(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      whatsappMemberId,
      organizationId,
      successHandler,
    }: {
      whatsappMemberId: string;
      organizationId: string;
      successHandler: (data: any) => void;
    }
  ) {
    const methodName = "actions.generateOTPForWhatsappMember";
    let response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setGenerateOTPLoading", true);
      const organizationService = new OrganizationService();
      response = await organizationService.generateOTPForWhatsappMember(
        organizationId,
        whatsappMemberId
      );
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        successHandler(response.data?.data);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = "OTP generated successfully";
        analyticsService.logActionEvent(
          ORGANIZATION_ANALYTICS_EVENTS.GENERATE_OTP_WHATAPP_MEMBER
        );
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setGenerateOTPLoading", false);
    }
    notificationService.triggerNotification();
  },

  // *********** Authorize OTP For Whatsapp Member ***********
  async authorizeOTPForWhatsappMember(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      payload,
      whatsappMemberId,
      organizationId,
      successHandler,
    }: {
      payload: AuthorizeWhatsppMemberOTPDto;
      whatsappMemberId: string;
      organizationId: string;
      successHandler: () => void;
    }
  ) {
    const methodName = "actions.authorizeOTPForWhatsappMember";
    let response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setWhatsappActionLoading", true);
      const organizationService = new OrganizationService();
      response = await organizationService.authorizeOTPForWhatsappMember(
        payload,
        organizationId,
        whatsappMemberId
      );
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        successHandler();
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          "Whatsapp member successfully authorized";
        analyticsService.logActionEvent(
          ORGANIZATION_ANALYTICS_EVENTS.AUTHORIZE_OTP_WHATAPP_MEMBER
        );
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setWhatsappActionLoading", false);
    }
    notificationService.triggerNotification();
  },

  // *********** Get Organization Stats ***********
  async getOrganizationStats(
    context: ActionContext<IOrganizationState, StoreState>,
    organizationId
  ) {
    const methodName = "actions.getOrganizationStats";
    let response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setOrganizationStatsLoading", true);
      context.commit("setOrganizationStats", {});
      context.commit("setOrganizationProductivity", {});
      const organizationService = new OrganizationService();
      response = await organizationService.getOrganizationStats(organizationId);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setOrganizationStats", response.data?.data);
        analyticsService.logActionEvent(
          ORGANIZATION_ANALYTICS_EVENTS.FETCH_ORGANIZATION_SNAPSHOT,
          {
            organizationId,
          }
        );
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          "Organization statistics fetched successfully";
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setOrganizationStatsLoading", false);
    }
    notificationService.triggerNotification();
  },

  // *********** Get Organization Productivity ***********
  async getOrganizationProductivity(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      payload,
      organizationId,
    }: {
      payload: OrganizationProductivityParamsRequestModel;
      organizationId: string;
    }
  ) {
    const methodName = "actions.getOrganizationProductivity";
    let response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setOrganizationProductivityLoading", true);
      context.commit("setOrganizationProductivity", {});
      const organizationService = new OrganizationService();
      response = await organizationService.getOrganizationProductivity(
        organizationId,
        payload
      );
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setOrganizationProductivity", response.data?.data);
        analyticsService.logActionEvent(
          ORGANIZATION_ANALYTICS_EVENTS.FETCH_ORGANIZATION_PRODUCTIVITY,
          {
            organizationId,
            ...payload,
          }
        );
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          "Organization Productivity fetched successfully";
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setOrganizationProductivityLoading", false);
    }
    notificationService.triggerNotification();
  },

  // *********** Get Currencies ***********
  async getCurrencies(context: ActionContext<IOrganizationState, StoreState>) {
    const methodName = "actions.getCurrencies";
    let response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setCurrencyLoading", true);
      const organizationService = new OrganizationService();
      response = await organizationService.getCurrencies();
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setCurrencies", response.data?.results?.data);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = "Currencies Successfully Fetched";
        analyticsService.logActionEvent(
          CURRENCY_CONVERSION_ANALYTICS_EVENTS.CURRENCY_FETCHED
        );
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setCurrencyLoading", false);
    }
    notificationService.triggerNotification();
  },

  // *********** Organization Documents Upload **************
  async organizationDocumentsUpload(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      payload,
      organizationId,
      successHandler,
    }: {
      payload: OrganizationContentUpload;
      organizationId: string;
      successHandler: () => void;
    }
  ) {
    const methodName = "actions.organizationDocumentsUpload";
    const responses = [];
    // const response: IAGResponse = {
    //   success: false,
    // };

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, payload);
      context.commit("setDocumentUploadLoading", true);

      const organizationService = new OrganizationService();

      const uploadFiles = Array.prototype.slice.call(payload.content, 0, 2);

      for (const file of uploadFiles) {
        const response = await organizationService.organizationContentUpload(
          {
            ...payload,
            content: file,
          },
          organizationId
        );

        if (response.success && response.status === StatusCodes.OK) {
          loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
          responses.push(response.data?.data);
        } else {
          loggerService.logInfo(
            `${methodName}: ${LOG_LABELS.ERROR}`,
            response.error
          );
          // Throw an error to stop the loop if any upload fails
          throw response;
        }
      }

      await organizationService.organizationConfigUpdate(
        { documents: { cnic: responses } },
        organizationId
      );

      const organization = storageService.getItem<Organization>(
        STORAGE_KEYS.ORGANIZATION
      );

      if (organization) {
        organization.organization_status =
          ORGANIZATION_STATUS.FINANCIAL_PROFILE_PENDING;
        storageService.setItem(
          STORAGE_KEYS.ORGANIZATION,
          stringEncrypt(JSON.stringify(organization))
        );
      }

      successHandler();
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);

      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("setDocumentUploadLoading", false);
    }

    return responses;
  },
  async fetchSaasOrganizations(
    context: ActionContext<IOrganizationState, StoreState>
  ) {
    const methodName = "actions.fetchSaasOrganizations";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableFetchOrganizationsLoading");
      const organizationService = new OrganizationService();
      const response: IAGResponse =
        await organizationService.getAllSaasEliteOrganizations();
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("saveSaasOrganizations", response.data?.data);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      context.commit("saveSaasOrganizations", []);
      loggerService.logError(`${methodName}:`, exception);
    }
  },

  async updateEliteOrganizationStatus(
    context: ActionContext<IOrganizationState, StoreState>,
    { status, organizationId, successHandler }
  ) {
    const methodName = "actions.updateEliteOrganizationStatus";
    let response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setEliteOrganizationStatusUpdateLoading", true);
      const organizationService = new OrganizationService();
      response = await organizationService.updateEliteOrganizationStatus(
        organizationId,
        status
      );
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        successHandler();
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = "Status updated successfully";
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setEliteOrganizationStatusUpdateLoading", false);
      notificationService.triggerNotification();
    }
  },
  async getInternalProviders(
    context: ActionContext<IOrganizationState, StoreState>
  ) {
    const methodName = "actions.getInternalProviders";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setFetchInternalProvidersLoading", true);
      const organizationService = new OrganizationService();
      const response: IAGResponse =
        await organizationService.getInternalProviders();
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit(
          "setAirblueProviders",
          response.data?.data?.airblue_providers
        );
        context.commit("setPIAProviders", response.data?.data?.pia_providers);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;
      loggerService.logError(`${methodName}:`, exception);
    } finally {
      context.commit("setFetchInternalProvidersLoading", false);
    }
  },
  async updateFinancialProfileProviders(
    context: ActionContext<IOrganizationState, StoreState>,
    {
      payload,
      successHandler,
    }: {
      payload: UpdateFinancialProfileProviders;
      successHandler: () => void;
    }
  ) {
    const methodName = "actions.updateFinancialProfileProviders";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setUpdateFinancialProfileProvidersLoading", true);
      const organizationService = new OrganizationService();
      const response: IAGResponse =
        await organizationService.updateFinancialProfileProviders(payload);
      if (response.success && response.status === StatusCodes.OK) {
        successHandler();
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = "Providers updated successfully";
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;
      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setUpdateFinancialProfileProvidersLoading", false);
      notificationService.triggerNotification();
    }
  },
};

export default actions;
