import { IMyTeam } from "@/ag-portal-common/interfaces/salesMyTeam.interface";
import {
  ISalesSector,
  ISalesSnapshot,
} from "@/ag-portal-common/interfaces/salesProductivity.interface";
import { ISalesState } from "@/ag-portal-common/interfaces/salesState.interface";
import { ISectorProductivity } from "@/ag-portal-common/interfaces/sectorProductivity.interface";
import { MutationTree } from "vuex";

const mutations: MutationTree<ISalesState> = {
  setSalesLoading(state, payload: boolean) {
    state.isLoading = payload;
  },
  setFetchSectorsLoading(state, payload: boolean) {
    state.isSalesSectorsFetching = payload;
  },
  setSalesSectors(state, payload: ISalesSector[]) {
    state.sectors = payload;
  },
  setSalesSnapshot(state, payload: ISalesSnapshot) {
    state.salesSnapshot = payload;
  },
  setSalesProductivity(state, payload: ISectorProductivity) {
    state.salesProductivity = payload;
  },
  setSalesMyTeam(state, payload: IMyTeam[]) {
    state.salesMyTeam = payload;
  },
  setSalesMyTeamLoading(state, payload: boolean) {
    state.isSalesMyTeamLoading = payload;
  },
};

export default mutations;
