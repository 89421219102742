import { ActionContext, ActionTree } from "vuex";
import { StatusCodes } from "http-status-codes";

import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import FITService from "@/modules/FIT/services/fit.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import notificationService from "@/ag-portal-common/services/notification.service";
import { IFITState } from "@/modules/FIT/vuex/state";
import { StoreState } from "@/store/type";
import { FITBookingPayload, FITSearchPayload } from "../constants";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";

const actions: ActionTree<IFITState, StoreState> = {
  // ****** Fetch FIT Hotels ******
  async getFITHotelDetails(
    context: ActionContext<IFITState, StoreState>,
    params: FITSearchPayload
  ) {
    const methodName = "actions.getFITHotelDetails";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, params);
      context.commit("fetchedHotelData", null);
      context.commit("isFetchingFitHotels", true);
      const fITService = new FITService();
      const response = await fITService.fetchFitHotel(params);
      if (
        response.success &&
        response.status === StatusCodes.OK &&
        response.data
      ) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("fetchedHotelData", response.data.data);
      } else {
        throw response;
      }
    } catch (error: any) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, error);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        error?.message || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("isFetchingFitHotels", false);
    }
  },
  async confirmFITHotels(
    context: ActionContext<IFITState, StoreState>,
    params: FITBookingPayload
  ): Promise<boolean> {
    const methodName = "actions.confirmFITHotels";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, params);
      context.commit("isSendingFITEmail", true);

      const fITService = new FITService();
      const response = await fITService.fitSendEmailRequest(params);

      if (response.success) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);

        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = response?.message || "";
        notificationService.triggerNotification();

        return true;
      } else {
        throw response;
      }
    } catch (error: any) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, error);

      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        error?.message || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();

      return false;
    } finally {
      context.commit("isSendingFITEmail", false);
    }
  },
};

export default actions;
