<template>
  <ag-heading :title="fdgDetail?.title" class="bg_heading_wrap" variant="h1" />

  <MProgress
    class="mprogress-alignment"
    v-if="isFetchingFDGDetail"
    indeterminate
  />
  <ag-div v-else-if="!isFetchingFDGDetail && fdgDetail" class="margin_top_20">
    <ag-row>
      <ag-column md="9" sm="12">
        <ag-card>
          <ag-heading title="Itinerary Details" variant="h2" />
        </ag-card>

        <slot :fdgDetail="fdgDetail" name="inclusions"></slot>

        <ag-card v-if="fdgDetail?.rules?.length">
          <ag-heading title="Rules" variant="h2" />
        </ag-card>
      </ag-column>

      <ag-column md="3" sm="12">
        <ag-card>
          <ag-heading title="Select Fare" variant="h3" />
          <ag-heading
            title="Please select any of the given Fare(s)"
            variant="p"
          />
          <ag-radio v-model="selectedBookingId">
            <ag-heading
              v-for="bookingOption in fdgDetail.bookingOptions"
              v-bind:key="bookingOption.id"
              :class="`select_fare_wrap ${
                selectedBookingId === bookingOption.id ? 'active' : ''
              }`"
              variant="label"
            >
              <ag-div class="flex-wrap d-flex align-items-center">
                <ag-radio-item
                  :value="bookingOption.id"
                  name="selectedBookingId"
                />

                <ag-heading
                  :title="`${bookingOption.currency} ${formatNumber(
                    bookingOption.price
                  )} (${bookingOption.option_name})`"
                  class="margin_bottom_0"
                  variant="b"
                />
              </ag-div>

              <ag-chip color="success"
                >{{ bookingOption.remainingQuantity }} Available
              </ag-chip>
            </ag-heading>
          </ag-radio>

          <ag-heading
            title="Note: Rates of Child / Infant may vary (possibly
            lesser). The final price can be checked with the FDG team after
            booking. (No amount will be deducted when booking)"
            variant="p"
          />

          <ag-div>
            <ag-heading :title="paxSelectionHeading" variant="h3" />
            <ag-heading :title="paxSelectionDescription" variant="p" />

            <ag-div>
              <AgTravelerCountItem
                :count="adultCount"
                icon="adultIcon"
                label="Adult"
                name="AdultCount"
                test-id=""
                @update:AdultCount="updateAdultCount"
              ></AgTravelerCountItem>
              <AgTravelerCountItem
                :count="childCount"
                hint="(2 to less than 12)"
                icon="childrenIcon"
                label="Children"
                name="ChildCount"
                test-id=""
                @update:ChildCount="updateChildCount"
              ></AgTravelerCountItem>
              <AgTravelerCountItem
                :count="infantCount"
                hint="(0 to less then 2)"
                icon="infantIcon"
                label="Infants"
                name="InfantCount"
                test-id=""
                @update:InfantCount="updateInfantCount"
              ></AgTravelerCountItem>
            </ag-div>
            <ag-div class="margin_top_20 text-center">
              <a-g-button
                :is-loading="isInitiatingFDGBooking"
                @click="onBookGroup"
              >
                Book Now
              </a-g-button>
            </ag-div>
          </ag-div>
        </ag-card>
      </ag-column>
    </ag-row>
  </ag-div>

  <ag-not-found v-else :description="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import {
  formatNumber,
  formatStringToRoutePath,
} from "@/ag-portal-common/utils/helpers";
import { FDGBookingDetailRequestModel } from "@/modules/FDG/models/fdgBookingDetail.request";
import { IFDGDetail } from "@/interfaces/fdgDetail.interface";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_STYLES } from "@/ag-portal-common/enums/NOTIFICATION_STYLES";
import { PATH } from "@/ag-portal-common/constants/path";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";

export default defineComponent({
  name: "TourDetail",
  props: {
    paxSelectionHeading: {
      type: String,
      default: "",
    },
    paxSelectionDescription: {
      type: String,
      default: "",
    },
  },
  methods: {
    formatNumber,
    updateAdultCount(count: number) {
      this.adultCount = count;
    },
    updateChildCount(count: number) {
      this.childCount = count;
    },
    updateInfantCount(count: number) {
      this.infantCount = count;
    },
    routeToBookingConfirmation(id: string) {
      let url = PATH.FDG_FLIGHT_BOOKING_CONFIRMATION;
      url += `?adult=${this.adultCount}`;
      url += `&child=${this.childCount}`;
      url += `&infant=${this.infantCount}`;

      this.$router.push(formatStringToRoutePath(url, { id }));
    },
    onBookGroup() {
      const { adultCount, childCount, infantCount } = this;

      if (!this.selectedBookingId) {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
        notificationService.description =
          NOTIFICATION_MESSAGES.SELECT_ANY_PACKAGE;
        notificationService.triggerNotification();
        return;
      }

      let payload: FDGBookingDetailRequestModel = {
        no_of_passengers: infantCount + adultCount + childCount,
        tour_option_id: this.selectedBookingId,
      };

      this.$store.dispatch("initiateGroupFlightBooking", {
        payload,
        callback: this.routeToBookingConfirmation,
      });
    },
  },
  beforeMount() {
    this.$store.dispatch("fetchGroupFlightDetail", this.$route.params.id);
  },
  data() {
    return {
      selectedBookingId: "",
      adultCount: 1,
      childCount: 0,
      infantCount: 0,
    };
  },
  computed: {
    BOOKING_TYPES() {
      return BOOKING_TYPES;
    },
    NOTIFICATION_MESSAGES() {
      return NOTIFICATION_MESSAGES;
    },
    fdgDetail(): IFDGDetail {
      return this.$store.getters.fdgDetail;
    },
    isFetchingFDGDetail(): boolean {
      return this.$store.getters.isFetchingFDGDetail;
    },
    isInitiatingFDGBooking(): boolean {
      return this.$store.getters.isInitiatingFDGBooking;
    },
  },
});
</script>
