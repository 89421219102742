import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7feeb0ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-bar" }
const _hoisted_2 = {
  key: 0,
  class: "combobox-grid"
}
const _hoisted_3 = { class: "search-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HotelDestinationCombobox = _resolveComponent("HotelDestinationCombobox")!
  const _component_MDatePicker = _resolveComponent("MDatePicker")!
  const _component_MTravelerCountBoxItem = _resolveComponent("MTravelerCountBoxItem")!
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_MTravelerCountBox = _resolveComponent("MTravelerCountBox")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MCard = _resolveComponent("MCard")!

  return (_openBlock(), _createBlock(_component_MCard, { class: "hotel-search-card" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_HotelDestinationCombobox, {
          "input-value": _ctx.destination,
          "onUpdate:inputValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.destination) = $event)),
            _ctx.onSelectLocation
          ],
          "input-text": _ctx.destination,
          "onUpdate:inputText": [
            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.destination) = $event)),
            _ctx.searchLocation
          ],
          label: "Where",
          placeholder: "Destination",
          "is-loading": _ctx.$store.getters.isLocationsFetching,
          options: _ctx.locations,
          "prepend-icon": "m-hotel",
          "has-error": !!_ctx.errors?.destination,
          "error-message": _ctx.errors?.destination,
          class: "destination"
        }, null, 8, ["input-value", "input-text", "is-loading", "options", "onUpdate:inputText", "onUpdate:inputValue", "has-error", "error-message"]),
        _createVNode(_component_MDatePicker, {
          label: "Check In",
          startDate: _ctx.checkInMinDate,
          "min-date": new Date(),
          onOnRangeDateChange: _ctx.changeCheckInDate,
          placeholder: "Check In Date",
          dateType: "startDate",
          multiCalendar: false,
          hasRange: false,
          name: "passportExpiry",
          error: _ctx.errors?.checkIn,
          class: "check-in"
        }, null, 8, ["startDate", "min-date", "onOnRangeDateChange", "error"]),
        _createVNode(_component_MDatePicker, {
          label: "Check Out",
          startDate: _ctx.checkoutMinDate,
          "min-date": _ctx.checkoutMinDate,
          onOnRangeDateChange: _ctx.changeCheckOutDate,
          placeholder: "Check Out Date",
          dateType: "endDate",
          multiCalendar: false,
          hasRange: false,
          name: "passportExpiry",
          error: _ctx.errors?.checkOut,
          class: "check-out"
        }, null, 8, ["startDate", "min-date", "onOnRangeDateChange", "error"]),
        _createVNode(_component_MTravelerCountBox, {
          class: "destination",
          label: "Guests",
          "prepend-icon": "m-traveler-count-icon",
          "adult-count": _ctx.$store.state.hotelModule.hotel_adult_count,
          "child-count": _ctx.$store.state.hotelModule.hotel_child_count,
          "infant-count": 0
        }, {
          items: _withCtx(() => [
            _createVNode(_component_MTravelerCountBoxItem, {
              name: "adultCount",
              label: "Adult",
              icon: "m-adult-traveler-icon",
              hint: "(12 years and above)",
              count: _ctx.adultCount,
              "onUpdate:adultCount": _ctx.updateAdultCount
            }, null, 8, ["count", "onUpdate:adultCount"]),
            _createVNode(_component_MTravelerCountBoxItem, {
              name: "childCount",
              label: "Children",
              icon: "m-child-traveler-icon",
              hint: "(0 to 17 years)",
              count: _ctx.childCount,
              "onUpdate:childCount": _ctx.updateChildCount
            }, null, 8, ["count", "onUpdate:childCount"]),
            (_ctx.$store.state.hotelModule.hotel_child_count > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.childCount, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      _createVNode(_component_MCombobox, {
                        inputValue: _ctx.childrenAges[index],
                        "onUpdate:inputValue": [($event: any) => ((_ctx.childrenAges[index]) = $event), (value) => _ctx.handleAgeSelectChange(value, index)],
                        options: _ctx.childAges,
                        label: _ctx.renderAgeSelecterLabel(index),
                        itemValue: "value",
                        itemLabel: "label",
                        variant: "outlined",
                        class: "child_age_select",
                        focusedBorderEnabled: ""
                      }, null, 8, ["inputValue", "onUpdate:inputValue", "options", "label"])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["adult-count", "child-count"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MButton, {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSearchClick())),
          disabled: _ctx.isPropertiesFetching
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Search Hotels ")
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ]),
    _: 1
  }))
}