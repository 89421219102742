import { RouteRecordRaw } from "vue-router";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import HotelSubRoutes from "@/modules/Hotel/route";

const HotelRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "hotels",
    children: HotelSubRoutes,
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.PERMISSIONS,
    },
  },
];

export default HotelRoutes;
