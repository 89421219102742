<template>
  <div class="hotel_selected_info">
    <div class="additional_info_wrap">
      <div class="label_wrap">
        <MTypography type="body"> Hotel Name </MTypography>
        <MTypography type="label" v-show="hotelName">
          {{ hotelName }}
        </MTypography>
      </div>

      <div class="label_wrap">
        <MTypography type="body"> Room Type </MTypography>
        <MTypography type="label" v-show="roomType">
          {{ roomType }}
        </MTypography>
      </div>

      <div class="label_wrap">
        <MTypography type="body"> Check In </MTypography>
        <MTypography type="label" v-show="checkinDate">
          {{ checkinDate }}
        </MTypography>
      </div>

      <div class="label_wrap">
        <MTypography type="body"> Check out </MTypography>
        <MTypography type="label" v-show="checkoutDate">
          {{ checkoutDate }}
        </MTypography>
      </div>

      <div class="label_wrap">
        <MTypography type="body"> No Of Nights </MTypography>
        <MTypography type="label" v-show="checkinDate">
          {{ noOfNight }}
        </MTypography>
      </div>

      <div class="label_wrap" v-show="travelers">
        <MTypography type="body"> Travelers </MTypography>
        <MTypography type="label" v-show="totalCost">
          {{ travelers }}
        </MTypography>
      </div>
      <div class="label_wrap" v-show="totalCost">
        <MTypography type="body"> Total Cost </MTypography>
        <MTypography type="label" v-show="totalCost">
          {{ totalCost }}
        </MTypography>
      </div>
      <slot name="deleteFIT"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "FITHotelSelectedInfo",
  props: {
    checkoutDate: {
      type: String,
    },
    checkinDate: {
      type: String,
    },
    noOfNight: {
      type: Number,
    },
    totalCost: {
      type: String,
    },
    roomType: {
      type: String,
    },
    hotelName: {
      type: String,
    },
    travelers: {
      type: Number,
    },
  },
});
</script>

<style scoped lang="css">
.hotel_selected_info {
  margin-bottom: 25px;
}
.additional_info_wrap {
  border-bottom: 1px solid grey;
  padding-bottom: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border: 1px solid var(--green-color) !important;
  padding: 16px;
  border-radius: 8px;
  flex-wrap: wrap;
}
</style>
