import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import {
  FIT_API_PATH,
  FITSearchPayload,
  HotelsData,
  FITBookingPayload,
} from "@/modules/FIT/constants";
class FITService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public async fetchFitHotel(
    params: FITSearchPayload
  ): Promise<IAGResponse<IAGResponse<HotelsData>>> {
    return this.restClient.get(
      `${FIT_API_PATH.GET_HOTELS}city_id=${params.city}&check_in=${params.checkin}&check_out=${params.checkout}&travelers=${params.travelers}`
    );
  }
  public async fitSendEmailRequest(
    payload: FITBookingPayload
  ): Promise<IAGResponse<IAGResponse<IAGResponse>>> {
    return this.restClient.post(FIT_API_PATH.SEND_EMAIL, payload);
  }
}

export default FITService;
