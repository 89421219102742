import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78cb3e54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SnapshotTile = _resolveComponent("SnapshotTile")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!
  const _component_SnapshotDetailAccordion = _resolveComponent("SnapshotDetailAccordion")!

  return (_openBlock(), _createBlock(_component_SnapshotDetailAccordion, {
    title: "Flight Issuance",
    "prepand-icon": "m-issue-ticket"
  }, {
    overview: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flightIssuanceAggregates, (item, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createVNode(_component_SnapshotTile, {
              heading: _ctx.renderItemTitle(item),
              subHeading: _ctx.renderItemValue(item),
              description: _ctx.getAmount(item),
              descriptionTitle: "Amount",
              subHeadingTitle: "Issued Count",
              icon: "m-flight"
            }, null, 8, ["heading", "subHeading", "description"])
          ]))
        }), 128))
      ])
    ]),
    "accordion-content": _withCtx(() => [
      (_ctx.showDetails)
        ? (_openBlock(), _createBlock(_component_MDataTable, {
            key: 0,
            headers: _ctx.issuanceHeader,
            data: _ctx.flightIssuanceOrganizations,
            "item-per-page": 10,
            "has-search": true
          }, {
            organization_name: _withCtx(({ item }) => [
              _createVNode(_component_AgDiv, {
                onClick: ($event: any) => (_ctx.handleOrganizationRowClick($event, item))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MTypography, {
                    class: "description organization-link",
                    type: "label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.organization_name), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          }, 8, ["headers", "data"]))
        : (_openBlock(), _createBlock(_component_ResultNotFound, {
            key: 1,
            title: "No Organization Found",
            description: ""
          }))
    ]),
    _: 1
  }))
}