<template>
  <AgentDashboardView v-if="isUserAgent()" />
  <SuperUserDashboardView v-if="isSuperUser()" />
  <OperationUserDashboardView v-if="isOperationUser()" />
  <FinanceUserDashboardView v-if="isFinanceUser()" />
  <SalesUserDashboardView v-if="isSalesUser()" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import AgentDashboardView from "../components/Dashboards/AgentDashboard.vue";
import SuperUserDashboardView from "../components/Dashboards/SuperUserDashboard.vue";
import OperationUserDashboardView from "../components/Dashboards/OperationUserDashboard.vue";
import FinanceUserDashboardView from "../components/Dashboards/FinanceUserDashboard.vue";
import SalesUserDashboardView from "../components/Dashboards/SalesUserDashboard.vue";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";

import "swiper/css";
import "swiper/css/navigation";
import UTILS from "@/ag-portal-common/utils";

export default defineComponent({
  name: "DashboardView",
  components: {
    AgentDashboardView,
    SuperUserDashboardView,
    OperationUserDashboardView,
    FinanceUserDashboardView,
    SalesUserDashboardView,
  },
  methods: {
    isUserAgent() {
      return UTILS.isUser(USER_ROLES.AGENT);
    },
    isSuperUser() {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER);
    },
    isFinanceUser() {
      return UTILS.isUser(USER_ROLES.FINANCE);
    },
    isOperationUser() {
      return UTILS.isUser(USER_ROLES.OPERATIONS);
    },
    isSalesUser() {
      return UTILS.isUser(USER_ROLES.SALES);
    },
  },
});
</script>
