<template>
  <div>
    <div class="row">
      <div class="col-sm-4 px-4 pt-0 pb-5">
        <div class="promos">
          <div class="promos-body bg-orange-gradient">
            <div class="promos-text">
              <MTypography type="body" class="text-white promo-name">{{
                promo_name
              }}</MTypography>
              <div class="promo">
                <button @click.prevent="copyToClipboard(promo_code)">
                  <MTypography type="body" class="text-white promo-code"
                    >{{ promo_code }}
                  </MTypography>
                </button>
              </div>
            </div>
            <div class="promos-border-left"></div>
            <div class="promos-border-right"></div>
          </div>
          <div class="flip-container" @click="flipCard">
            <div class="flipper" :class="{ flipped: animationState }">
              <div class="front" v-show="!animationState">
                <div class="promos-footer">
                  <div class="applicable-on">
                    <div class="promos-details">
                      <div class="details-text">
                        <div class="text-title">Applicable On</div>
                        <div class="text-description text-primary">
                          {{ applicable_on }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="details">
                    <div class="promos-details">
                      <div class="details-text">
                        <div class="text-title">Valid From</div>
                        <div class="text-description text-primary">
                          {{
                            getFormattedDateTime(
                              start,
                              FORMAT_DD_MMM_YYYY_WITH_DASH
                            )
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="promos-details">
                      <div class="details-text">
                        <div class="text-title">Valid Till</div>
                        <div class="text-description text-primary">
                          {{
                            getFormattedDateTime(
                              end,
                              FORMAT_DD_MMM_YYYY_WITH_DASH
                            )
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="promos-details">
                      <div class="details-text">
                        <div class="text-title">Discount</div>
                        <div class="text-description text-primary">
                          {{ amount }}
                        </div>
                      </div>
                    </div>
                    <div class="promos-details">
                      <div class="details-text">
                        <div class="text-title">Status</div>
                        <div class="text-description text-primary">
                          {{ isActive }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="back" v-show="animationState">
                <div class="promos-footer">
                  <div class="details applicable-on">
                    <div class="promos-details">
                      <div class="details-text">
                        <div class="text-title">Max Usage</div>
                        <div class="text-description text-primary">
                          {{ max_usage }}
                        </div>
                      </div>
                    </div>
                    <div class="promos-details">
                      <div class="details-text">
                        <div class="text-title">Discount Mode</div>
                        <div class="text-description text-primary">
                          {{ renderDetailsToLowerCase(discount_mode) }}
                        </div>
                      </div>
                    </div>
                    <div class="promos-details">
                      <div class="details-text">
                        <div class="text-title">Booking Via</div>
                        <div class="text-description text-primary">
                          {{ for_portal_only ? "Portal Only" : "All Bookings" }}
                        </div>
                      </div>
                    </div>
                    <div class="promos-details">
                      <div class="details-text">
                        <div class="text-title">Apply On</div>
                        <div class="text-description text-primary">
                          {{ grossApply ? "Gross Fare" : "Base Fare" }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="details">
                    <div class="promos-details">
                      <div class="details-text">
                        <div class="text-title">Organization Created</div>
                        <div class="text-description text-primary">
                          {{
                            organization_created_in_days
                              ? organization_created_in_days + " Days Ago"
                              : "-"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="promos-details">
                      <div class="details-text">
                        <div class="text-title">Last Issuance</div>
                        <div class="text-description text-primary">
                          {{
                            last_issuance_before_days
                              ? last_issuance_before_days + " Days Ago"
                              : "-"
                          }}
                        </div>
                      </div>
                    </div>

                    <div class="promos-details">
                      <div class="details-text">
                        <div class="text-title">Route</div>
                        <div
                          class="text-description text-primary tooltip-container"
                        >
                          {{ renderRoutes }}
                          <span class="tooltip-text">{{ renderToolTip }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { MTypography } from "@aeroglobe/ag-core-ui";
import { defineComponent } from "vue";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { FORMAT_DD_MMM_YYYY_WITH_DASH } from "@/ag-portal-common/constants/dateTimeFormats";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";

export default defineComponent({
  name: "PromoComponent",
  components: {
    MTypography,
  },
  data() {
    return {
      animationState: false,
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
    };
  },
  props: {
    start: {
      type: Date,
      required: true,
    },
    end: {
      type: Date,
      required: true,
    },
    promo_name: {
      type: String,
      required: true,
    },
    promo_code: {
      type: String,
      required: true,
    },
    amount: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    applicable_on: {
      type: String,
      required: true,
    },
    grossApply: {
      type: Boolean,
      required: true,
    },
    discount_mode: {
      type: String,
      required: true,
    },
    organization_created_in_days: {
      type: Number || null,
      required: true,
    },
    last_issuance_before_days: {
      type: Number || null,
      required: true,
    },
    max_usage: {
      type: Number || null,
      required: true,
    },
    for_portal_only: {
      type: Boolean,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
    flightSector: {
      type: String,
    },
    originAirports: {
      type: String,
    },
    destinationAirports: {
      type: String,
    },
  },
  computed: {
    themeColor() {
      const root = document.documentElement;
      const mainColor = getComputedStyle(root)
        .getPropertyValue("--green-color")
        .trim();
      return mainColor;
    },
    isActive(): string {
      return this.active ? "Active" : "In Active";
    },
    renderRoutes(): string {
      switch (this.route) {
        case "ALL":
          return "All Routes";
        case "SECTOR":
          return "Selected Routes";
        case "ORIGIN":
          return "Selected Origin Airports";
        case "DESTINATION":
          return "Selected Destination Airports";
        default:
          return "";
      }
    },
    renderToolTip(): string {
      switch (this.route) {
        case "ALL":
          return "All Routes";
        case "SECTOR":
          return this.flightSector ?? "";
        case "ORIGIN":
          return this.originAirports ?? "";
        case "DESTINATION":
          return this.destinationAirports ?? "";
        default:
          return "";
      }
    },
  },
  methods: {
    getFormattedDateTime,
    renderDetailsToLowerCase(value: string) {
      return value
        .toLowerCase()
        .replace(/[_-]/g, " ")
        .replace(/(^\w|\s\w)/g, (match: string) => match.toUpperCase());
    },
    flipCard() {
      this.animationState = !this.animationState;
    },
    copyToClipboard(promoCode: string) {
      const tempInput = document.createElement("input");
      tempInput.value = promoCode;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      notificationService.type = NOTIFICATION_TYPES.SUCCESS;
      notificationService.description = "Promo Copied To Clipboard";
      notificationService.triggerNotification();
    },
  },
});
</script>

<style lang="css" scoped>
.promos {
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  padding: 0;
  margin-bottom: 10px;
}
.promo-name {
  text-transform: capitalize;
}
.promos-body,
.promos-footer {
  position: relative;
  flex-direction: row;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  transition: box-shadow 0.3s ease;
}
.promos-body {
  overflow: hidden;
  flex-direction: row;
  box-shadow: inset 0px 0px 0px 1px #e5e9ec;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.promos-footer {
  flex-direction: row;
  justify-content: space-between;
  align-items: normal;
  padding: 0.5rem;
  box-shadow: inset 0px -1px 0px 1px #e5e9ec;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.promos:hover .promos-footer {
  box-shadow: inset 0px -1px 0px 1px #e5e9ec,
    0 0.125rem 0.25rem rgba(33, 37, 41, 0.075) !important;
}

.promos-text {
  flex: 1 1 auto;
  padding: 1.25rem 2rem;
  max-height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.promos-border-left,
.promos-border-right {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.promos-border-right {
  right: 0;
}
.promos-border-left:before,
.promos-border-right:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  border: 1px solid #e5e9ec;
  border-radius: 50%;
  background: #f8f9fa;
}
.promos-border-left:before {
  left: -15px;
}
.promos-border-right:before {
  right: -15px;
}

.promos-details .details-text {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}

.promos-details .text-title {
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.38);
}
.promos-details .text-description {
  display: flex;
  justify-content: flex-start;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.text-primary {
  color: var(--green-color) !important;
}
.bg-orange-gradient {
  background: var(--green-color) !important;
}
.promo-code {
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  padding-top: 2px;
}
.promo {
  border: 1px dashed white;
  border-radius: 12px;
}
.details {
  display: flex;
  justify-content: space-between;
}
.applicable-on {
  margin-bottom: 10px;
}

.flipper {
  transition: transform 0.6s;
  transform-style: preserve-3d;
  height: 100%;
}

.flipper.flipped {
  transform: rotateX(180deg);
}

.front,
.back {
  width: 100%;
  backface-visibility: hidden;
}

.back {
  transform: rotateX(180deg);
}
.tooltip-container {
  position: relative;
  cursor: pointer;
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
</style>
