<template>
  <SnapshotDetailAccordion title="Flight Bookings" prepand-icon="m-flight">
    <template #overview>
      <div class="grid-container">
        <div v-for="(item, index) in flightBookingsAggregates" :key="index">
          <SnapshotTile
            :heading="renderItemTitle(item)"
            :subHeading="renderItemValue(item)"
            subHeadingTitle="Booking Count"
            icon="m-flight"
            :description="getAmount(item)"
            descriptionTitle="Amount"
          >
          </SnapshotTile>
        </div>
      </div>
    </template>
    <template #accordion-content>
      <MDataTable
        v-if="showDetails"
        :headers="bookingHeader"
        :data="flightBookingsOrganizations"
        :item-per-page="10"
        :hasSearch="true"
      >
        <template #organization_name="{ item }">
          <div
            @click="handleOrganizationRowClick($event, item)"
            class="name-column organization-link"
          >
            <MTypography class="description organization-link" type="label">
              {{ item.organization_name }}
            </MTypography>
          </div>
        </template>
      </MDataTable>
      <ResultNotFound title="No Organization Found" description="" v-else />
    </template>
  </SnapshotDetailAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";

import ResultNotFound from "@/components/ResultNotFound.vue";
import {
  ISectorProductivity,
  FlightBookingAggregates,
} from "@/ag-portal-common/interfaces/sectorProductivity.interface";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { MDataTable } from "@aeroglobe/ag-core-ui";
import SnapshotDetailAccordion from "@/components/SnapshotDetailAccordion.vue";
import SnapshotTile from "@/components/SnapshotTile.vue";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";

export default defineComponent({
  name: "FlightBookingsTable",
  components: {
    MDataTable,
    SnapshotDetailAccordion,
    SnapshotTile,
    ResultNotFound,
  },
  props: {
    handleOrganizationRowClick: {
      type: Function,
      default: () => ({}),
    },
  },
  data(): {
    startDate: Date;
    endDate: Date;
    selectedSector: string;
    bookingHeader: MDataTableHeader[];
  } {
    return {
      startDate: new Date(),
      endDate: new Date(),
      selectedSector: "",
      bookingHeader: [
        {
          title: "Organization Name",
          value: "organization_name",
          key: "organization_name",
          sortable: true,
        },
        {
          title: "Total Bookings",
          value: "total_flight_bookings",
          key: "total_flight_bookings",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    getCurrencyFormatter,
    formattedCurrency(currency: string, amount: number): string {
      return getCurrencyFormatter(currency).format(amount);
    },
    renderItemTitle(value: string) {
      return _.capitalize(value?.replaceAll("_", " "));
    },
    renderItemValue(value: keyof FlightBookingAggregates) {
      return this.salesProductivity.flight_bookings_data?.aggregates[value]
        .count_pnrs;
    },
    getAmount(value: keyof FlightBookingAggregates): string {
      const fare =
        this.salesProductivity.flight_bookings_data?.aggregates[value]
          .total_amount;
      const formattedCurrency = this.formattedCurrency(
        fare.currency,
        fare.value
      );
      return formattedCurrency;
    },
    updateBookingHeader() {
      const salesProductivity = this.$store.getters
        .salesProductivity as ISectorProductivity;
      const organizations =
        salesProductivity?.flight_bookings_data?.organizations ?? [];

      // Check if organizations array has at least one entry and flight_booking_by_provider exists
      const providersName =
        organizations.length > 0
          ? organizations[0]?.flight_booking_by_provider?.map(
              ({ provider_name }) => provider_name
            ) ?? []
          : [];

      const updatedTableHeaders = providersName?.map((item) => {
        return {
          title: item?.replaceAll("_", " "),
          value: item,
          key: item,
          sortable: true,
        };
      }) as MDataTableHeader[];

      // Ensure bookingHeader is initialized and safely spread new headers
      this.bookingHeader = [
        ...(this.bookingHeader ?? []),
        ...updatedTableHeaders,
      ];
    },
  },
  computed: {
    salesProductivity(): ISectorProductivity {
      return this.$store.getters.salesProductivity;
    },
    showDetails(): boolean {
      const isLoading = this.$store.getters.isSalesLoading;
      const organizations =
        this.$store.getters.salesProductivity?.flight_bookings_data
          ?.organizations || [];
      return !isLoading && organizations.length > 0;
    },
    flightBookingsOrganizations() {
      const salesProductivity = this.$store.getters
        .salesProductivity as ISectorProductivity;
      const organizations =
        salesProductivity?.flight_bookings_data?.organizations ?? [];

      const formattedOrganizations = organizations.map((item) => {
        const flights = Object.fromEntries(
          item.flight_booking_by_provider.map(
            ({ provider_name, issuance_count }) => [
              provider_name,
              issuance_count,
            ]
          )
        );

        return {
          public_id: item.public_id,
          organization_name: item.organization_name,
          total_flight_bookings: item.total_flight_bookings,
          ...flights,
        };
      });

      return formattedOrganizations;
    },
    flightBookingsAggregates(): Array<keyof FlightBookingAggregates> {
      const salesProductivity = this.$store.getters.salesProductivity as
        | ISectorProductivity
        | undefined;
      const aggregates = salesProductivity?.flight_bookings_data?.aggregates;

      if (aggregates) {
        return Object.keys(aggregates) as Array<keyof FlightBookingAggregates>;
      }

      return [];
    },
  },
  mounted() {
    this.updateBookingHeader();
  },
});
</script>

<style lang="scss" scoped>
.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-top: solid 8px var(--green-color);
}

.heading {
  font-size: 30px;
  font-weight: bold;
}

.overview-heading {
  font-size: 19px;
  margin: 10px;
  font-weight: bold;
}

.items-container {
  border-left: solid 4px var(--green-color);
}

.empty-items-message {
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}

.organization-link {
  color: var(--green-color);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.white-icon {
  color: #fff;
}
</style>
<style lang="css" scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}
</style>
