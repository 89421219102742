import { Module } from "vuex";
import { IFlightBooking } from "@/ag-flight-components/interfaces/IFlightBooking.interface";
import { StoreState } from "@/store/type";

import mutations from "./mutations";
import actions from "./actions";
import state from "./state";
import getters from "./getters";

const flightBookingModule: Module<IFlightBooking, StoreState> = {
  state,
  mutations,
  actions,
  getters,
};

export default flightBookingModule;
