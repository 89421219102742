<template>
  <div class="password-change">
    <MCard class="pass-container">
      <div class="profile-header">
        <MTypography type="body">Password Management</MTypography>
        <MTypography type="label">Change your password</MTypography>
        <hr />
      </div>

      <form class="user-profile" autocomplete="off" @submit.prevent>
        <div class="pass-input">
          <MTextfield
            v-model:input-value.trim="password.current"
            type="password"
            label="Current Password"
            placeholder="Enter your current password"
            autocomplete="password"
            :has-error="changePasswordErrors['current']"
            :error-message="changePasswordErrors['current']"
            :disabled="isChangePasswordLoading"
            class="pass-text-field"
          />
        </div>

        <div class="pass-input">
          <MTextfield
            v-model:input-value.trim="password.newPass"
            type="password"
            label="New Password"
            placeholder="Enter your new password"
            autocomplete="password"
            :has-error="changePasswordErrors['newPass']"
            :error-message="changePasswordErrors['newPass']"
            :disabled="isChangePasswordLoading"
            class="pass-text-field"
          />
        </div>

        <div class="pass-input">
          <MTextfield
            v-model:input-value.trim="password.confirm"
            type="password"
            label="Confirm New Password"
            placeholder="Re Enter your new password"
            autocomplete="password"
            :has-error="changePasswordErrors['confirm']"
            :error-message="changePasswordErrors['confirm']"
            :disabled="isChangePasswordLoading"
            class="pass-text-field"
          />
        </div>

        <MButton
          @click="onChangePasswordHandler"
          :disabled="isChangePasswordLoading"
        >
          Change Password
        </MButton>
      </form>
    </MCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { passwordChangeValidation } from "@/modules/Profile/validations/passwordChangeValidation";

import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { ValidationError } from "yup";
import { AUTH_ACTIONS } from "@/modules/Auth/vuex/actions";

export default defineComponent({
  name: "userProfile",
  data() {
    return {
      password: {
        current: "",
        newPass: "",
        confirm: "",
      },

      passwordShow: {
        current: false,
        newPass: false,
        confirm: false,
      },

      isChangePasswordLoading: false,
      changePasswordErrors: {} as { [index: string]: string },
    };
  },
  methods: {
    async onChangePasswordHandler() {
      this.isChangePasswordLoading = true;
      this.changePasswordErrors = {};

      try {
        const { current, newPass, confirm } = this.password;

        const payload = await passwordChangeValidation.validate(
          {
            current,
            newPass,
            confirm,
          },
          { abortEarly: false }
        );

        const body = {
          new_password: payload.newPass,
          old_password: payload.current,
        };

        await this.$store.dispatch(AUTH_ACTIONS.CHANGE_PASSWORD, body);

        this.onResetPasswordFormHandler();
      } catch (exception) {
        if (exception instanceof ValidationError) {
          this.changePasswordErrors = yupValidationErrorAsSchema(exception);
        }
      } finally {
        this.isChangePasswordLoading = false;
      }
    },

    onResetPasswordFormHandler() {
      this.password = {
        current: "",
        confirm: "",
        newPass: "",
      };
    },
  },
  unmounted() {
    this.onResetPasswordFormHandler();
  },
});
</script>

<style lang="scss">
.pass-text-field {
  position: relative;

  .v-input {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .v-input__control {
      flex: 1;
    }

    .v-input__append {
      right: 2px;
      background-color: white;
      padding: 10px;
      margin: 0;
    }

    .error-text {
      width: calc(100% - 10px);
      position: absolute;
      inset: 58px 10px;
    }
  }
}
</style>

<style lang="scss" scoped>
[disabled="true"] {
  opacity: 0.5 !important;
  pointer-events: none;
  user-select: none;
  cursor: not-allowed;
}

.pass-container {
  margin: 0 auto 16px;
}

.password-change {
  width: 100%;
  padding: 4px 0 0;

  .pass-text-field {
    max-width: 360px;
    .v-input {
      margin: 0;
    }
  }
  .pass-input {
    .label {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 12px;
  }
}

@media screen and (max-width: 470px) {
  .pass-action {
    width: 100% !important;
  }
}
</style>
