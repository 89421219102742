import AgentService from "@/modules/Agent/services/agent.service";
import {
  IAGErrorResponse,
  IAGResponse,
} from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { ActionContext, ActionTree } from "vuex";
import {
  AgentPricingResponse,
  AgentPricingUpdatePayload,
  IAgentState,
} from "@/interfaces/agentState.interface";
import OrganizationService from "@/modules/Organization/services/organization.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { AgentPermissionsChangeVerificationRequestModel } from "@/modules/Agent/models/agentPermissionsChangeVerification.request";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import analyticsService from "@/analytic.service";
import { AGENT_ANALYTICS_EVENTS } from "@/modules/Agent/constants/analyticsEvents";
import { StoreState } from "@/store/type";

const actions: ActionTree<IAgentState, StoreState> = {
  async getOrganizationAgents(
    context: ActionContext<IAgentState, StoreState>,
    organizationId: string
  ) {
    const methodName = "actions.getOrganizationAgents";
    try {
      context.commit("fetchingOrganizationAgents", true);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const organizationService = new OrganizationService();
      const response: IAGResponse<any> =
        await organizationService.getOrganizationById(organizationId);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("fetchingOrganizationAgents", false);
        context.commit("saveOrganizationAgents", response.data.agents);
      } else {
        context.commit("fetchingOrganizationAgents", false);
        throw response;
      }
    } catch (err) {
      context.commit("fetchingOrganizationAgents", false);
      loggerService.logError(`${methodName}:`, err);
    }
  },

  async fetchAgentById(
    context: ActionContext<IAgentState, StoreState>,
    payload: { agentId: string; organizationId: string }
  ) {
    const methodName = "actions.fetchAgentById";
    try {
      context.commit("fetchingAgent", true);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const organizationService = new OrganizationService();
      const response: IAGResponse<any> =
        await organizationService.getOrganizationAgentById(
          payload.organizationId,
          payload.agentId
        );
      context.commit("fetchAgentSuccess", response.data);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
    } catch (err) {
      context.commit("fetchingAgent", false);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
  },

  async updateAgentPermissions(
    context: ActionContext<IAgentState, StoreState>,
    payload: {
      agentId: string;
      callback: (referenceId: string) => void;
      permissions: { [key: string]: boolean };
    }
  ) {
    let response: IAGResponse<any> = {
      success: false,
    };

    const methodName = "actions.updateAgentPermissions";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("updatingAgentPermissions", true);
      const agentService = new AgentService();
      response = await agentService.updateAgentPermissions(
        context.rootGetters.organizationId,
        payload.agentId,
        payload.permissions
      );
      if (response.success) {
        context.commit("updateAgentPermissionsSuccess", response.data);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("updatingAgentPermissions", false);

        const analyticsPayload = {
          "agent-id": payload.agentId,
          "agent-user-permissions": payload.permissions,
        };

        analyticsService.logActionEvent(
          AGENT_ANALYTICS_EVENTS.UPDATE_PERMISSIONS,
          analyticsPayload
        );

        payload.callback(response.data?.reference || "");
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      context.commit("updatingAgentPermissions", false);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
    }
  },

  async verificationOnPermissionsChange(
    context: ActionContext<IAgentState, StoreState>,
    payload: {
      body: AgentPermissionsChangeVerificationRequestModel;
      onSuccessCallback: () => void;
      callback: () => boolean;
    }
  ) {
    let response: IAGResponse<any> = {
      success: false,
    };

    const methodName = "actions.verificationOnPermissionsChange";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const agentService = new AgentService();
      response = await agentService.agentPermissionsChangeVerification(
        payload.body
      );
      if (response.success) {
        context.commit("updateAgentPermissionsSuccess", response.data);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          NOTIFICATION_MESSAGES.UPDATE_PERMISSIONS_SUCCESS;
        payload.onSuccessCallback();

        const analyticsPayload = {
          reference: payload.body.reference,
          otp: payload.body.otp,
        };

        analyticsService.logActionEvent(
          AGENT_ANALYTICS_EVENTS.OTP_VERIFICATION,
          analyticsPayload
        );

        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
    }
    payload.callback();
    notificationService.triggerNotification();
  },

  async getAgentPricing(
    context: ActionContext<IAgentState, StoreState>,
    payload: {
      agentId: string;
    }
  ) {
    const methodName = "actions.getAgentPricing";
    let response: IAGResponse<IAGResponse<AgentPricingResponse>> = {
      success: false,
    };

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

      const agentService = new AgentService();

      response = await agentService.getAgentPricing(payload.agentId);

      if (response.success && response.data) {
        const agentPricingResponse = response.data.data;

        context.commit("saveAgentPricingResponse", agentPricingResponse);

        const analyticsPayload = {
          "agent-id": payload.agentId,
        };

        analyticsService.logActionEvent(
          AGENT_ANALYTICS_EVENTS.GET_AGENT_PRICING,
          analyticsPayload
        );

        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
    }
  },

  async updateAgentPricing(
    context: ActionContext<IAgentState, StoreState>,
    payload: {
      agentId: string;
      data: AgentPricingUpdatePayload;
    }
  ) {
    const methodName = "actions.updateAgentPricing";
    let response: IAGResponse<IAGResponse<AgentPricingResponse>> = {
      success: false,
    };

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

      const agentService = new AgentService();

      response = await agentService.updateAgentPricing(
        payload.agentId,
        payload.data
      );

      if (response.success && response.data) {
        const agentPricingResponse = response.data.data;

        context.commit("updateAgentPricingResponse", agentPricingResponse);

        const analyticsPayload = {
          "agent-id": payload.agentId,
        };

        analyticsService.logActionEvent(
          AGENT_ANALYTICS_EVENTS.GET_AGENT_PRICING,
          analyticsPayload
        );

        notificationService.type = NOTIFICATION_TYPES.INFO;
        notificationService.description = "Pricing updated successfully";
        notificationService.triggerNotification();

        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
    }
  },
};

export default actions;
