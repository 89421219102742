import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-403594fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "passenger-list-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgContactInfo = _resolveComponent("AgContactInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AgContactInfo, {
      class: "flex-child",
      "icon-name": "pakFlag",
      title: _ctx.renderFullName(_ctx.passenger)
    }, null, 8, ["title"]),
    _createVNode(_component_AgContactInfo, {
      class: "flex-child",
      "icon-name": "nicIcon",
      title: _ctx.renderPassportNumberandExpiry(_ctx.passenger).number,
      "sub-title": _ctx.renderPassportNumberandExpiry(_ctx.passenger).expiry
    }, null, 8, ["title", "sub-title"]),
    _createVNode(_component_AgContactInfo, {
      class: "flex-child",
      "icon-name": _ctx.renderPassengerType(_ctx.passenger).icon,
      title: _ctx.renderPassengerType(_ctx.passenger).type
    }, null, 8, ["icon-name", "title"])
  ]))
}