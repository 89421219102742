import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgPriceSummary = _resolveComponent("AgPriceSummary")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgFareInfo = _resolveComponent("AgFareInfo")!
  const _component_ag_accordion_panel = _resolveComponent("ag-accordion-panel")!
  const _component_ag_accordion = _resolveComponent("ag-accordion")!

  return (_ctx.flightDetails)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createVNode(_component_ag_heading, {
          variant: "h2",
          title: "Price Summary",
          class: "margin_bottom_10"
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paxTypes, (type, index) => {
          return _withDirectives((_openBlock(), _createBlock(_component_AgPriceSummary, {
            key: `fare-info-${index}`,
            "test-id": "",
            info: _ctx.getPriceSummaryTitleHandler(type).label,
            price: _ctx.getPriceSummaryTitleHandler(type).price
          }, null, 8, ["info", "price"])), [
            [_vShow, _ctx.canShowBreakDown(type)]
          ])
        }), 128)),
        _createVNode(_component_AgPriceSummary, {
          "test-id": "",
          info: "Price You Pay",
          price: _ctx.renderTotalGrossPrice(),
          "total-price": "true"
        }, null, 8, ["price"]),
        (_ctx.canDisplayAgentPricing && _ctx.isUserPermitted)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_AgPriceSummary, {
                "test-id": "",
                info: "Your Price",
                price: _ctx.renderTotalAgentPrice(),
                "total-price": "true"
              }, null, 8, ["price"]),
              _createVNode(_component_AgPriceSummary, {
                "test-id": "",
                info: "Difference",
                price: _ctx.agentPricingDifference,
                "total-price": "true"
              }, null, 8, ["price"])
            ], 64))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_ag_accordion, {
          class: "flight_accordion_wrap",
          "panel-value": [1, 2]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_accordion_panel, null, {
              flightInfo: _withCtx(() => [
                _createVNode(_component_ag_heading, {
                  variant: "h3",
                  title: "Fare Break Down",
                  class: "margin_bottom_10",
                  style: {"margin-top":"10px"}
                })
              ]),
              tabSection: _withCtx(() => [
                _createVNode(_component_AgFareInfo, {
                  "test-id": "",
                  style: {"max-width":"inherit"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paxTypes, (type, index) => {
                      return _withDirectives((_openBlock(), _createBlock(_component_AgDiv, {
                        key: `fare-info-${index}`
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_heading, {
                            variant: "h3",
                            title: _ctx.getHeadingHandler(type),
                            class: "margin_bottom_10",
                            style: {"margin-top":"10px"}
                          }, null, 8, ["title"]),
                          _createVNode(_component_AgPriceSummary, {
                            "test-id": "",
                            info: "Base Fare",
                            price: _ctx.getFareHandler('base_fare')[type]
                          }, null, 8, ["price"]),
                          _createVNode(_component_AgPriceSummary, {
                            "test-id": "",
                            info: "Tax",
                            price: _ctx.getFareHandler('tax')[type]
                          }, null, 8, ["price"]),
                          _createVNode(_component_AgPriceSummary, {
                            "test-id": "",
                            info: "Gross Fare",
                            price: _ctx.getFareHandler('gross_fare')[type]
                          }, null, 8, ["price"]),
                          (_ctx.getPriceDifferenceHandler(type) !== 0)
                            ? (_openBlock(), _createBlock(_component_AgPriceSummary, {
                                key: 0,
                                "test-id": "",
                                info: 
                    _ctx.getSummaryTitleHandler(_ctx.getPriceDifferenceHandler(type) || 0)
                  ,
                                price: _ctx.getFormattedPriceDifferenceHandler(type)
                              }, null, 8, ["info", "price"]))
                            : _createCommentVNode("", true),
                          (_ctx.getDealDiscount()[type])
                            ? (_openBlock(), _createBlock(_component_AgPriceSummary, {
                                key: 1,
                                "test-id": "",
                                info: "Deal Discount",
                                price: _ctx.getDealDiscount()[type]
                              }, null, 8, ["price"]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_AgPriceSummary, {
                            "test-id": "",
                            info: "Total",
                            price: _ctx.getFareHandler('ag_total_amount')[type],
                            "total-price": "true"
                          }, null, 8, ["price"])
                        ]),
                        _: 2
                      }, 1536)), [
                        [_vShow, _ctx.canShowBreakDown(type)]
                      ])
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.canShowAllBreakdowns]
        ]),
        _renderSlot(_ctx.$slots, "flightDetailsActionButtons")
      ], 64))
    : _createCommentVNode("", true)
}