import { ActionContext, ActionTree } from "vuex";
import { IVisaVerificationState } from "@/ag-portal-common/interfaces/visaVerificationState.interface";
import { StoreState } from "@/store/type";
import {
  IAGErrorResponse,
  IAGResponse,
} from "@/ag-portal-common/interfaces/agResponse.interface";
import loggerService from "@/ag-portal-common/services/logger.service";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { StatusCodes } from "http-status-codes";
import VisaVerificationService from "../service/visaVerification.service";
import { AddVisaRequestDTO } from "../dto/addVisaRequest.dto";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { ContentUpload } from "@/modules/Organization/dtos/organization.dto";
import OrganizationService from "@/modules/Organization/services/organization.service";
import analyticServices from "@/analytic.service";
import { VISA_VERIFICATION_ANALYTICS_EVENTS } from "../constants/analyticsEvent";

import download from "downloadjs";

const actions: ActionTree<IVisaVerificationState, StoreState> = {
  async visaPDFUpload(
    context: ActionContext<IVisaVerificationState, StoreState>,
    {
      payload,
      organizationId,
    }: { payload: ContentUpload; organizationId: string }
  ) {
    const methodName = "actions.visaPDFUpload";
    let public_id: string | null = null;
    let content_url: string | null = null;
    let response: IAGResponse = {
      success: false,
    };

    try {
      context.commit("setAddingRequestLoading", true);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, payload);

      const organizationService = new OrganizationService();

      response = await organizationService.organizationContentUpload(
        payload,
        organizationId
      );

      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);

        public_id = response.data?.data.public_id;
        content_url = response.data?.data.content_url;

        analyticServices.logActionEvent(
          VISA_VERIFICATION_ANALYTICS_EVENTS.UPLOAD_VISA_PDF,
          payload
        );
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );

        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);

      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("setAddingRequestLoading", false);
    }
    return [public_id, content_url];
  },

  async addVisaRequests(
    context: ActionContext<IVisaVerificationState, StoreState>,
    {
      payload,
      organizationId,
      successHandler,
    }: {
      payload: AddVisaRequestDTO;
      organizationId: string;
      successHandler: () => void;
    }
  ) {
    const methodName = "actions.addVisaRequests";
    let response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setAddingRequestLoading", true);
      const visaVerificationService = new VisaVerificationService();
      response = await visaVerificationService.addVisaRequest(
        payload,
        organizationId
      );
      if (response.success && response.status === StatusCodes.CREATED) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        successHandler();
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          "Visa Ok To Board request successfully submitted";
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setAddingRequestLoading", false);
      notificationService.triggerNotification();
    }
  },

  async getVisaRequests(
    context: ActionContext<IVisaVerificationState, StoreState>,
    organizationId: string
  ) {
    const methodName = "actions.getVisaRequests";
    let response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setFetchingRequestLoading", true);
      const visaVerificationService = new VisaVerificationService();
      response = await visaVerificationService.getVisaRequests(organizationId);
      if (response.success && response.status === StatusCodes.OK) {
        context.commit("setVisaVerificationList", response.data?.data);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          "Visa ok to board requests successfully fetched";
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setFetchingRequestLoading", false);
      notificationService.triggerNotification();
    }
  },

  async downloadVisaPDF(
    context: ActionContext<IVisaVerificationState, StoreState>,
    {
      id,
      watermarkPosition,
      successHandler,
    }: { id: string; watermarkPosition: string; successHandler: () => void }
  ) {
    const methodName = "actions.downloadVisaPDF";
    const response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setDownloadVisaLoading", true);
      const visaVerificationService = new VisaVerificationService();
      await visaVerificationService.downloadVisaPDF(id, watermarkPosition);
      successHandler();
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      notificationService.type = NOTIFICATION_TYPES.SUCCESS;
      notificationService.description = "Visa downloaded successfully";
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setDownloadVisaLoading", false);
      notificationService.triggerNotification();
    }
  },
  async openDownloadVisaPDF(
    context: ActionContext<IVisaVerificationState, StoreState>,
    { name, id }: { name: string; id: string }
  ) {
    const methodName = "actions.openDownloadVisaPDF";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setDownloadVisaLoading", true);
      const visaVerificationService = new VisaVerificationService();

      const response: IAGResponse =
        await visaVerificationService.openUrlDownloadVisaPDF(name, id);

      if (response.success && response.status === StatusCodes.OK) {
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = "Visa PDF Downloaded Successfully";
        notificationService.triggerNotification();
        download(response.data, name + "-visa.pdf");
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("setDownloadVisaLoading", false);
    }
  },
};

export default actions;
