<template>
  <MProgress indeterminate v-if="isLoading" class="mprogress-alignment" />
  <div v-else>
    <MCard class="heading">
      <AgIconInfoBar
        test-id=""
        title="Self Onboard Organizations"
        class="section-title"
        mPrepandIcon="m-organisations"
        v-show="!isDashboardView"
      />
    </MCard>
    <MDataTable
      :headers="headers"
      :is-loading="isFetchingOrganizations"
      :data="organizationList || []"
      :item-per-page="30"
      :has-search="true"
    >
      <template #name="{ item }">
        <div @click="handleRowClick($event, item)">
          <MTypography
            class="description td-full-width organization-link"
            type="body"
          >
            {{ item.name }}
          </MTypography>
        </div>
      </template>
      <template #created_at="{ item }">
        <MTypography class="description td-full-width" type="body">
          {{
            getFormattedDateTime(item.created_at, FORMAT_DD_MMM_YYYY_WITH_DASH)
          }}
        </MTypography>
        <MTypography class="description td-full-width" type="label">
          {{ getFormattedDateTime(item.created_at, FORMAT_HH_SS_24_HOURS) }}
        </MTypography>
      </template>
      <template #city="{ item }">
        <MTypography
          class="description td-full-width"
          type="label"
          v-if="item.city"
        >
          {{ item.city }}
        </MTypography>
        <MTypography class="description" type="label" v-else> - </MTypography>
      </template>
      <template #status="{ item }">
        <div class="status-container width-150">
          <MChip
            class="log-type-item"
            :borderLess="true"
            :variant="saasOrganizationStatusType(item?.status)"
          >
            {{ item.status?.replaceAll("_", " ") }}
          </MChip>
        </div>
      </template>
      <template #documents="{ item }">
        <div class="width-165">
          <MButton
            :disabled="isViewDocumentsBtnDisabled(item.status)"
            @click="handleClickViewDocuments(item.documents, item.name)"
            type="filled"
          >
            View Documents
          </MButton>
        </div>
      </template>
      <template #action="{ item }">
        <div class="action_btn_container">
          <MButton
            :disabled="isVerifyBtnDisabled(item.status)"
            @click="handleOpenApproveDialog(item)"
          >
            Approve
          </MButton>
          <MButton
            variant="danger"
            :disabled="isRejectBtnDisabled(item.status)"
            @click="handleOpenRejectDialog(item)"
          >
            Reject
          </MButton>
        </div>
      </template>
    </MDataTable>
  </div>

  <DocumentsModal
    :is-open="isDocumentModalOpened"
    :selected-documents="selectedDocuments"
    :organization-name="selectedOrganizationName"
    @closeModal="handleCloseDocumentModal"
  />
  <ApproveOrganizationDialog
    @handleClose="handleCloseDialog"
    @handleAccept="handleApprove"
    :organization_name="selectedOrganizationName"
    :is-open="isAprroveDialogOpened"
  />
  <RejectOrganizationDialog
    @handleClose="handleCloseDialog"
    @handleReject="handleReject"
    :organization_name="selectedOrganizationName"
    :is-open="isRejectDialogOpened"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PATH } from "@/ag-portal-common/constants/path";
import { AGENT_SUB_ROLES } from "@/ag-portal-common/enums/AGENT_SUB_ROLES";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { ISaasEliteOrganization } from "@/ag-portal-common/interfaces/organization.interface";
import {
  FORMAT_HH_SS_24_HOURS,
  FORMAT_DD_MMM_YYYY_WITH_DASH,
} from "@/ag-portal-common/constants/dateTimeFormats";
import {
  SAAS_ORGANIZATION_STATUSES_MAPPING,
  SAAS_ORGANIZATION_DOCUMENTS_STATUSES,
} from "../constants/index";
import DocumentsModal from "@/modules/Organization/components/OrganizationModals/DocumentsDialog.vue";
import ApproveOrganizationDialog from "@/modules/Organization/components/OrganizationModals/ApproveOrganizationDialog.vue";
import RejectOrganizationDialog from "@/modules/Organization/components/OrganizationModals/RejectOrganizationDialog.vue";
import { MButton, MDataTable, MChip } from "@aeroglobe/ag-core-ui";
import { ChipVariant } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import { SAAS_ELITE_ORGANIZATION_STATUS } from "@/ag-portal-common/enums/SAAS_ELITE_ORGANIZATION_STATUS";

export default defineComponent({
  name: "SaasEliteOrganizationList",
  components: {
    DocumentsModal,
    ApproveOrganizationDialog,
    RejectOrganizationDialog,
    MButton,
    MDataTable,
    MChip,
  },
  props: {
    isDashboardView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ELEMENT_TYPES,
      AGENT_SUB_ROLES,
      isDocumentModalOpened: false,
      isAprroveDialogOpened: false,
      isRejectDialogOpened: false,
      selectedDocuments: {},
      selectedOrganizationName: "",
      selectedOrganizationPublicId: "",
      FORMAT_HH_SS_24_HOURS: FORMAT_HH_SS_24_HOURS,
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
      headers: [
        {
          title: "Organization Name",
          value: "name",
          key: "name",
          sortable: true,
        },
        {
          title: "Created At",
          value: "created_at",
          key: "created_at",
          sortable: true,
        },
        {
          title: "Country",
          value: "country",
          key: "country",
          sortable: true,
        },
        {
          title: "City",
          value: "city",
          key: "city",
          sortable: true,
        },

        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: true,
        },
        {
          title: "Email",
          value: "contact_person_email",
          key: "contact_person_email",
          sortable: true,
        },
        {
          title: "Phone Number",
          value: "contact_person_phone",
          key: "contact_person_phone",
          sortable: true,
        },

        {
          title: "Documents",
          value: "documents",
          key: "documents",
          sortable: false,
        },
        {
          title: "Action",
          value: "action",
          key: "action",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    getFormattedDateTime,
    isParentPath(): any {
      return this.$router;
    },
    saasOrganizationStatusType(
      type: SAAS_ELITE_ORGANIZATION_STATUS
    ): ChipVariant {
      return SAAS_ORGANIZATION_STATUSES_MAPPING[type] as ChipVariant;
    },
    handleCloseDocumentModal() {
      this.isDocumentModalOpened = false;
      this.selectedOrganizationName = "";
      this.selectedDocuments = {};
    },
    handleClickViewDocuments(documents: object, organizationName: string) {
      this.isDocumentModalOpened = true;
      this.selectedDocuments = documents;
      this.selectedOrganizationName = organizationName;
    },
    isViewDocumentsBtnDisabled(status: string) {
      return status === SAAS_ELITE_ORGANIZATION_STATUS.DOCUMENTS_PENDING;
    },
    handleRowClick(e: any, row: any) {
      const org_id = row?.organization_id;
      const path = PATH.ORGANIZATION + "/" + org_id;
      this.$router.push(path);
    },
    handleCloseDialog() {
      this.isAprroveDialogOpened = false;
      this.isRejectDialogOpened = false;
      this.selectedOrganizationName = "";
    },
    approveSuccessHandler() {
      this.isAprroveDialogOpened = false;
      this.selectedOrganizationName = "";
      this.$store.dispatch("fetchSaasOrganizations");
    },
    rejectSuccessHandler() {
      this.isRejectDialogOpened = false;
      this.selectedOrganizationName = "";
      this.$store.dispatch("fetchSaasOrganizations");
    },
    handleOpenApproveDialog(row: any) {
      this.selectedOrganizationName = row?.name;
      this.selectedOrganizationPublicId = row?.public_id;
      this.isAprroveDialogOpened = true;
    },
    handleOpenRejectDialog(row: any) {
      this.selectedOrganizationName = row?.name;
      this.selectedOrganizationPublicId = row?.public_id;
      this.isRejectDialogOpened = true;
    },
    handleApprove() {
      const payload = {
        status: SAAS_ORGANIZATION_DOCUMENTS_STATUSES.APPROVE,
        organizationId: this.selectedOrganizationPublicId,
        successHandler: this.approveSuccessHandler,
      };
      this.$store.dispatch("updateEliteOrganizationStatus", payload);
    },
    handleReject() {
      const payload = {
        status: SAAS_ORGANIZATION_DOCUMENTS_STATUSES.REJECT,
        organizationId: this.selectedOrganizationPublicId,
        successHandler: this.rejectSuccessHandler,
      };
      this.$store.dispatch("updateEliteOrganizationStatus", payload);
    },
    isVerifyBtnDisabled(status: string) {
      return (
        status === SAAS_ELITE_ORGANIZATION_STATUS.VERIFIED ||
        status === SAAS_ELITE_ORGANIZATION_STATUS.DOCUMENTS_PENDING
      );
    },
    isRejectBtnDisabled(status: string) {
      return (
        status === SAAS_ELITE_ORGANIZATION_STATUS.DOCUMENTS_PENDING ||
        status === SAAS_ELITE_ORGANIZATION_STATUS.VERIFIED
      );
    },
    formattedDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY_WITH_DASH);
    },
    formattedTime(date: Date) {
      return getFormattedDateTime(date, FORMAT_HH_SS_24_HOURS);
    },
  },
  computed: {
    isLoading(): boolean {
      return this.isFetchingOrganizations && !this.isDashboardView;
    },
    PATH(): typeof PATH {
      return PATH;
    },
    organizationList(): Array<
      ISaasEliteOrganization & { action_delete: string; action_update: string }
    > {
      const organizationList = this.$store.getters
        .saasEliteOrganization as ISaasEliteOrganization[];

      return organizationList.map((item: ISaasEliteOrganization) => ({
        ...item,
        action_delete: "Delete",
        action_update: "Update",
      }));
    },
    isFetchingOrganizations(): boolean {
      return this.$store.getters.isFetchingOrganizations;
    },
  },
  mounted() {
    this.$store.dispatch("fetchSaasOrganizations");
  },
});
</script>

<style lang="scss" scoped>
.passenger-action-btn-container {
  display: flex;
  justify-content: space-between;
  width: 150px;
}

.header-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.passenger-delete-icon {
  svg {
    stroke: #d21414;
  }
}

.action_btn_container {
  min-width: 200px;
}

.organization-link {
  min-width: 200px;
  color: var(--green-color);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.action_btn_container {
  display: flex;
  justify-content: space-around;
}
.width-165 {
  min-width: 165px;
}
.width-150 {
  min-width: 150px;
}
.status-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-align-center {
  text-align: center;
}
.created-at-container {
  display: flex;
  gap: 5px;
}
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
.heading {
  margin-bottom: 10px;
}
</style>
