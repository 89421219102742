<template>
  <MAccordion>
    <template #title>
      <div
        class="accordion-title-container"
        :class="{ 'has-errors': hasError }"
      >
        <div class="headline-container">
          <MIcon
            class="hotel-form-accordion-title-traveler-icon"
            name="m-traveler-count-icon"
          />
          <MTypography class="headline" type="headline"
            >Guest Details
            <span v-if="hasError" class="required-error-text">
              {{ errorText }}</span
            >
          </MTypography>
        </div>
        <hr />
        <div class="title-container">
          <MTypography type="title">{{ renderAccordionTitle }} </MTypography>
        </div>
      </div>
    </template>
    <template #content>
      <AgRow class="accordion-content">
        <AgColumn test-id="" md="7" lg="7" cols="12">
          <AgRow test-id="">
            <AgColumn test-id="" md="12" lg="9">
              <MCombobox
                :options="renderTitleData"
                itemValue="value"
                itemLabel="label"
                v-model:inputValue="titleValue"
                :value="getValueByNameandIndex('title')"
                label="Title*"
                test-id=""
                :hasError="renderError('title')"
                :errorMessage="renderError('title')"
              />

              <MTextfield
                v-model:inputValue="firstNameValue"
                label="First Name and Middle Name (if any)*"
                :hasError="renderError('first_name')"
                :errorMessage="renderError('first_name')"
              />

              <MTextfield
                v-model:inputValue="lastNameValue"
                label="Last Name*"
                :hasError="renderError('last_name')"
                :errorMessage="renderError('last_name')"
              />

              <MDatePicker
                label="Date of Birth*"
                :startDate="disabledDates.from"
                :min-date="disabledDates.to"
                :max-date="disabledDates.from"
                @onRangeDateChange="changeBirthDate"
                placeholder="Enter Date of Birth*"
                dateType="birthDate"
                :multiCalendar="false"
                :hasRange="false"
                :error="renderError('date_of_birth')"
                name="date_of_birth"
                :autoPosition="true"
                position="top"
                class="m-date-picker"
              />
            </AgColumn>
          </AgRow>
        </AgColumn>

        <AgColumn test-id="" md="5" lg="5" cols="12">
          <AgTravelDetail
            test-id=""
            :items="{
              [TravelerCardDetails.TITLE]: getValueByNameandIndex('title'),
              [TravelerCardDetails.FIRST_NAME]:
                getValueByNameandIndex('first_name'),
              [TravelerCardDetails.LAST_NAME]:
                getValueByNameandIndex('last_name'),
              [TravelerCardDetails.DOB]: dateOfBirth,
            }"
          >
            <template #headingArea>
              <AgHeading
                variant="h2"
                title="Guest Details"
                class="margin_bottom_5"
              />
            </template>
          </AgTravelDetail>
        </AgColumn>
      </AgRow>
    </template>
  </MAccordion>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { addYears } from "date-fns";

import { titles } from "../constants/titles";
import { HotelTraveler } from "@/ag-portal-common/types/hotel";
import { getTravelerTypeName, TravelerTypes } from "../constants/travelerTypes";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { FORMAT_DD_MMM_YYYY } from "@/ag-portal-common/constants/dateTimeFormats";
import { TravelerCardDetails } from "@/ag-portal-common/enums/TRAVELER_CARD_DETAILS";
import { MCombobox, MTypography } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "TavelerCard",
  props: {
    index: {
      type: Number,
      default: 0,
    },
    traveler: {
      type: Object as PropType<HotelTraveler>,
    },
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      titles,
      titleValue: "",
      firstNameValue: "",
      lastNameValue: "",
      dobValue: "",
    };
  },
  watch: {
    titleValue(newValue) {
      this.handleFieldChange(newValue, "title");
    },
    firstNameValue(newValue) {
      this.handleFieldChange(newValue, "first_name");
    },
    lastNameValue(newValue) {
      this.handleFieldChange(newValue, "last_name");
    },
    dobValue(newValue) {
      this.handleFieldChange(newValue, "date_of_birth");
    },
  },
  computed: {
    hasError(): boolean {
      const errorCount = this.renderErrorCount();
      return errorCount > 1 ? true : false;
    },
    errorText(): string {
      const errorCount = this.renderErrorCount();
      const fieldText = errorCount > 1 ? "Fields" : "Field";
      return `( * ${errorCount} ${fieldText} Required )`;
    },
    TravelerCardDetails(): typeof TravelerCardDetails {
      return TravelerCardDetails;
    },
    dateOfBirth(): string {
      const dob = this.getValueByNameandIndex("date_of_birth");
      const date = new Date(dob);

      return dob ? getFormattedDateTime(date, FORMAT_DD_MMM_YYYY) : "";
    },
    renderAccordionTitle(): string {
      const passenger_type = getTravelerTypeName(this.traveler?.type as string);

      return `Guest ${(this.index || 0) + 1}: ${passenger_type}`;
    },
    disabledDates() {
      const currentDate = new Date();
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // One day in milliseconds
      const nextDate = new Date(currentDate.getTime() + oneDayInMilliseconds);

      let minDate = null;
      let maxDate = new Date();
      let yearsToShow: Array<string> = [];

      const passengerType = getTravelerTypeName(this.traveler?.type as string);

      switch (passengerType) {
        case "Adult":
          minDate = addYears(currentDate, -100);
          maxDate = addYears(currentDate, -18);
          yearsToShow = [
            minDate.getFullYear().toString(),
            maxDate.getFullYear().toString(),
          ];
          break;
        case "Child":
          minDate = addYears(nextDate, -18);
          yearsToShow = [
            minDate.getFullYear().toString(),
            maxDate.getFullYear().toString(),
          ];
      }

      return {
        to: minDate,
        from: maxDate,
        yearsToShow: yearsToShow,
      };
    },
    renderTitleData(): {
      label: string;
      value: string;
      type: TravelerTypes;
    }[] {
      return titles.filter((x) => x.type === (this.traveler?.type as string));
    },
  },

  methods: {
    changeBirthDate(newDate: Date) {
      this.handleDateSelect(newDate, "date_of_birth");
    },
    handleFieldChange(value: any, key: string) {
      const payload = {
        index: this.index,
        key,
        value,
      };
      this.$store.commit("updateTaveler", payload);
    },
    renderError(key: string) {
      return this.errors?.[`guests[${this.index}].${key}`];
    },

    renderErrorCount() {
      let errorsCount = 0;

      if (this.errors?.[`guests[${this.index}].title`]) {
        errorsCount += 1;
      }
      if (this.errors?.[`guests[${this.index}].first_name`]) {
        errorsCount += 1;
      }
      if (this.errors?.[`guests[${this.index}].last_name`]) {
        errorsCount += 1;
      }
      return errorsCount;
    },
    handleDateSelect(value: Date, key: string) {
      const payload = {
        index: this.index,
        key,
        value,
      };
      this.$store.commit("updateTaveler", payload);
    },
    getValueByNameandIndex(key: keyof HotelTraveler) {
      const item = this.$store.state.hotelModule.travelers[this.index][key];

      return item ? item : "";
    },
    handleTextFieldChange(event: InputEvent, key: string) {
      const value = (event.target as HTMLInputElement).value;
      const payload = {
        index: this.index,
        key,
        value,
      };
      this.$store.commit("updateTaveler", payload);
    },
    generateIds(key: string) {
      return this.errors?.[`guests[${this.index}].${key}`];
    },
  },
  mounted() {
    this.handleDateSelect(this.disabledDates.from, "date_of_birth");
  },
});
</script>

<style lang="css" scoped>
.accordion-title-container {
  width: 100%;
  .headline-container {
    display: flex;
    align-items: center;
    .headline {
      margin-left: 10px;
      .required-error-text {
        font-size: 16px;
        font-weight: 800;
        color: var(--m-error-color);
      }
    }
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-size: 18px;
  }

  .error-icon {
    color: var(--error-color);
  }
}

hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid var(--neutral-300);
}
</style>

<style>
.hotel-form-accordion-title-traveler-icon {
  svg {
    width: 35px;
    height: 30px;
    fill: var(--m-primary-color) !important;
  }
}
.accordion-content {
  margin-top: 10px;
}
.m-date-picker {
  z-index: 2;
}
</style>
