import { RouteRecordRaw } from "vue-router";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import { PATH } from "@/ag-portal-common/constants/path";
import GroupFlightsRoutes from "@/modules/FDG/route";
import FITRoutes from "@/modules/FIT/route";

const TourRoutes: Array<RouteRecordRaw> = [
  {
    path: PATH.FIX_DEPARTURE_GROUP,
    name: "tours",
    children: GroupFlightsRoutes,
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.PERMISSIONS,
    },
  },
  {
    path: PATH.FIT_HOTELS,
    name: "fit",
    children: FITRoutes,
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FIT.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FIT.PERMISSIONS,
    },
  },
];

export default TourRoutes;
