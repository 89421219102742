<template>
  <NewRegisteredUserBanner />
  <NewsSlider />
  <div>
    <BookingExpiry />
    <RevenueChart />
    <MCard>
      <MyBookingsTable :isDashboardView="true" />
    </MCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MyBookingsTable from "@/modules/MyBookings/components/MyBookingsTable.vue";
import BookingExpiry from "./../BookingExpiry.vue";
import RevenueChart from "../RevenueChart.vue";
import NewsSlider from "../NewsSlider.vue";
import NewRegisteredUserBanner from "./NewRegisteredUserBanner.vue";
import { MCard } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "AgentDashboardView",
  components: {
    RevenueChart,
    MyBookingsTable,
    NewsSlider,
    BookingExpiry,
    NewRegisteredUserBanner,
    MCard,
  },
});
</script>
