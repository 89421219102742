<template>
  <MChip class="booking-status-chip" border-less :variant="variant">
    <slot></slot>
  </MChip>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { MChip } from "@aeroglobe/ag-core-ui";
import { ChipVariant } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";

export default defineComponent({
  name: "BookingStatus",
  props: {
    variant: {
      type: String as PropType<ChipVariant>,
      default: "default",
    },
    status: {
      type: String,
      default: "",
    },
  },
  components: {
    MChip,
  },
});
</script>

<style lang="css">
.booking-status-chip {
  min-height: 48px !important;
  padding: 0 24px !important;
  background-color: color-mix(
    in srgb,
    var(--m-info-color) 15%,
    transparent 100%
  ) !important;

  .m-clabel {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
