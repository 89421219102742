import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0427a92d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "org-details-title" }
const _hoisted_2 = { class: "content-container" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "plan_name" }
const _hoisted_5 = {
  key: 0,
  class: "update-btn-container"
}
const _hoisted_6 = { class: "grid-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_OrganizationDetailTile = _resolveComponent("OrganizationDetailTile")!
  const _component_MFabButton = _resolveComponent("MFabButton")!
  const _component_MAccordion = _resolveComponent("MAccordion")!
  const _component_UnlinkFinancialProfileConfirmationDialog = _resolveComponent("UnlinkFinancialProfileConfirmationDialog")!
  const _component_ViewNotInvoicedReceiptsDialog = _resolveComponent("ViewNotInvoicedReceiptsDialog")!
  const _component_UpdateFinancialProfileProvidersDialog = _resolveComponent("UpdateFinancialProfileProvidersDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MAccordion, { "initial-open": true }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_AgIconInfoBar, {
            "test-id": "",
            title: 
            _ctx.getFPTitle(
              _ctx.financialProfile.financial_profile_name,
              _ctx.financialProfile.platform_id
            )
          ,
            class: "section-title",
            mPrepandIcon: "m-payment-management"
          }, null, 8, ["title"]),
          _createVNode(_component_MChip, {
            class: "status-chip",
            variant: _ctx.isActive ? 'success' : 'error'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.financialProfile?.status), 1)
            ]),
            _: 1
          }, 8, ["variant"]),
          (_ctx.financialProfile.is_default)
            ? (_openBlock(), _createBlock(_component_MChip, {
                key: 0,
                class: "status-chip",
                variant: "success"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" DEFAULT ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_MIcon, {
                type: _ctx.getFPType(_ctx.financialProfile.plan_name),
                class: "details-type-icon icon",
                name: _ctx.getFPIconName(_ctx.financialProfile.plan_name),
                width: "24",
                height: "24"
              }, null, 8, ["type", "name"]),
              _createTextVNode(" " + _toDisplayString(_ctx.financialProfile.plan_name.toUpperCase()), 1)
            ]),
            (_ctx.enableActions)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_MButton, { onClick: _ctx.onUpdateFinancialBtnClick }, {
                    default: _withCtx(() => [
                      _createTextVNode("Update")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_MButton, { onClick: _ctx.onUnLinknBtnClick }, {
                    default: _withCtx(() => [
                      _createTextVNode("Unlink")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_OrganizationDetailTile, {
              subHeading: "Net Available Limit",
              heading: 
              _ctx.formatCurrency(
                Number(_ctx.financialProfile.financial_data?.total_balance_current),
                _ctx.financialProfile.financial_data?.currency_code
              )
            ,
              icon: "m-request-quote"
            }, null, 8, ["heading"]),
            _createVNode(_component_OrganizationDetailTile, {
              subHeading: "Available Limit",
              heading: 
              _ctx.formatCurrency(
                Number(_ctx.financialProfile.financial_data?.available_credit),
                _ctx.financialProfile.financial_data?.currency_code
              )
            ,
              icon: "m-wallet"
            }, null, 8, ["heading"]),
            _createVNode(_component_OrganizationDetailTile, {
              subHeading: "Total Not Invoiced Sales",
              heading: 
              _ctx.formatCurrency(
                Number(
                  _ctx.financialProfile.financial_data?.total_of_not_invoiced_tickets
                ),
                _ctx.financialProfile.financial_data?.currency_code
              )
            ,
              icon: "m-receipt-long"
            }, {
              button: _withCtx(() => [
                _createVNode(_component_MFabButton, {
                  onClick: _ctx.handleOpenNotInvoicedDialog,
                  type: "outlined",
                  icon: "m-receipt-long"
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["heading"]),
            _createVNode(_component_OrganizationDetailTile, {
              subHeading: "Total Payable Amount",
              heading: 
              _ctx.formatCurrency(
                Number(_ctx.financialProfile.financial_data?.current_balance),
                _ctx.financialProfile.financial_data?.currency_code
              )
            ,
              icon: "m-credit-score"
            }, null, 8, ["heading"]),
            _createVNode(_component_OrganizationDetailTile, {
              subHeading: "Temporary Credit Limit",
              heading: 
              _ctx.formatCurrency(
                Number(_ctx.financialProfile.financial_data?.temporary_credit_limit),
                _ctx.financialProfile.financial_data?.currency_code
              )
            ,
              icon: "m-credit-limit-management"
            }, null, 8, ["heading"]),
            _createVNode(_component_OrganizationDetailTile, {
              subHeading: "Permanent Credit Limit",
              heading: 
              _ctx.formatCurrency(
                Number(_ctx.financialProfile.financial_data?.credit_limit),
                _ctx.financialProfile.financial_data?.currency_code
              )
            ,
              icon: "m-request-quote"
            }, null, 8, ["heading"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_UnlinkFinancialProfileConfirmationDialog, {
      onHandleClose: _ctx.handleReject,
      onHandleAccept: _ctx.handleApprove,
      financial_profile_name: _ctx.financialProfile?.financial_profile_name,
      "is-open": _ctx.isUnlinkConfirmationDialogOpened
    }, null, 8, ["onHandleClose", "onHandleAccept", "financial_profile_name", "is-open"]),
    _createVNode(_component_ViewNotInvoicedReceiptsDialog, {
      "financial-profile": _ctx.financialProfile,
      "is-open": _ctx.isViewNotInvoicedReceiptsDialogOpened,
      onHandleClose: _ctx.handleCloseNotInvoicedDialog
    }, null, 8, ["financial-profile", "is-open", "onHandleClose"]),
    _createVNode(_component_UpdateFinancialProfileProvidersDialog, {
      "financial-profile-id": _ctx.financialProfile?.public_id,
      "is-open": _ctx.isUpdateFinancialProfileProvidersDialogOpened,
      "pia-provider": _ctx.financialProfile?.pia_provider,
      "airblue-provider": _ctx.financialProfile?.airblue_provider,
      onHandleClose: _ctx.handleCloseUpdateProvidersDialog,
      onOnSuccess: _ctx.onUpdateProvidersSuccess
    }, null, 8, ["financial-profile-id", "is-open", "pia-provider", "airblue-provider", "onHandleClose", "onOnSuccess"])
  ], 64))
}