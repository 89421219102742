import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_MProgress = _resolveComponent("MProgress")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_radio_item = _resolveComponent("ag-radio-item")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_ag_chip = _resolveComponent("ag-chip")!
  const _component_ag_radio = _resolveComponent("ag-radio")!
  const _component_AgTravelerCountItem = _resolveComponent("AgTravelerCountItem")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_not_found = _resolveComponent("ag-not-found")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_heading, {
      title: _ctx.fdgDetail?.title,
      class: "bg_heading_wrap",
      variant: "h1"
    }, null, 8, ["title"]),
    (_ctx.isFetchingFDGDetail)
      ? (_openBlock(), _createBlock(_component_MProgress, {
          key: 0,
          class: "mprogress-alignment",
          indeterminate: ""
        }))
      : (!_ctx.isFetchingFDGDetail && _ctx.fdgDetail)
        ? (_openBlock(), _createBlock(_component_ag_div, {
            key: 1,
            class: "margin_top_20"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_column, {
                    md: "9",
                    sm: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_heading, {
                            title: "Itinerary Details",
                            variant: "h2"
                          })
                        ]),
                        _: 1
                      }),
                      _renderSlot(_ctx.$slots, "inclusions", { fdgDetail: _ctx.fdgDetail }),
                      (_ctx.fdgDetail?.rules?.length)
                        ? (_openBlock(), _createBlock(_component_ag_card, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ag_heading, {
                                title: "Rules",
                                variant: "h2"
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 3
                  }),
                  _createVNode(_component_ag_column, {
                    md: "3",
                    sm: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_heading, {
                            title: "Select Fare",
                            variant: "h3"
                          }),
                          _createVNode(_component_ag_heading, {
                            title: "Please select any of the given Fare(s)",
                            variant: "p"
                          }),
                          _createVNode(_component_ag_radio, {
                            modelValue: _ctx.selectedBookingId,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedBookingId) = $event))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fdgDetail.bookingOptions, (bookingOption) => {
                                return (_openBlock(), _createBlock(_component_ag_heading, {
                                  key: bookingOption.id,
                                  class: _normalizeClass(`select_fare_wrap ${
                _ctx.selectedBookingId === bookingOption.id ? 'active' : ''
              }`),
                                  variant: "label"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ag_div, { class: "flex-wrap d-flex align-items-center" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ag_radio_item, {
                                          value: bookingOption.id,
                                          name: "selectedBookingId"
                                        }, null, 8, ["value"]),
                                        _createVNode(_component_ag_heading, {
                                          title: `${bookingOption.currency} ${_ctx.formatNumber(
                    bookingOption.price
                  )} (${bookingOption.option_name})`,
                                          class: "margin_bottom_0",
                                          variant: "b"
                                        }, null, 8, ["title"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_ag_chip, { color: "success" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(bookingOption.remainingQuantity) + " Available ", 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1032, ["class"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"]),
                          _createVNode(_component_ag_heading, {
                            title: "Note: Rates of Child / Infant may vary (possibly\n            lesser). The final price can be checked with the FDG team after\n            booking. (No amount will be deducted when booking)",
                            variant: "p"
                          }),
                          _createVNode(_component_ag_div, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ag_heading, {
                                title: _ctx.paxSelectionHeading,
                                variant: "h3"
                              }, null, 8, ["title"]),
                              _createVNode(_component_ag_heading, {
                                title: _ctx.paxSelectionDescription,
                                variant: "p"
                              }, null, 8, ["title"]),
                              _createVNode(_component_ag_div, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_AgTravelerCountItem, {
                                    count: _ctx.adultCount,
                                    icon: "adultIcon",
                                    label: "Adult",
                                    name: "AdultCount",
                                    "test-id": "",
                                    "onUpdate:AdultCount": _ctx.updateAdultCount
                                  }, null, 8, ["count", "onUpdate:AdultCount"]),
                                  _createVNode(_component_AgTravelerCountItem, {
                                    count: _ctx.childCount,
                                    hint: "(2 to less than 12)",
                                    icon: "childrenIcon",
                                    label: "Children",
                                    name: "ChildCount",
                                    "test-id": "",
                                    "onUpdate:ChildCount": _ctx.updateChildCount
                                  }, null, 8, ["count", "onUpdate:ChildCount"]),
                                  _createVNode(_component_AgTravelerCountItem, {
                                    count: _ctx.infantCount,
                                    hint: "(0 to less then 2)",
                                    icon: "infantIcon",
                                    label: "Infants",
                                    name: "InfantCount",
                                    "test-id": "",
                                    "onUpdate:InfantCount": _ctx.updateInfantCount
                                  }, null, 8, ["count", "onUpdate:InfantCount"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ag_div, { class: "margin_top_20 text-center" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_g_button, {
                                    "is-loading": _ctx.isInitiatingFDGBooking,
                                    onClick: _ctx.onBookGroup
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Book Now ")
                                    ]),
                                    _: 1
                                  }, 8, ["is-loading", "onClick"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 3
              })
            ]),
            _: 3
          }))
        : (_openBlock(), _createBlock(_component_ag_not_found, {
            key: 2,
            description: _ctx.NOTIFICATION_MESSAGES.NO_RESULTS_FOUND
          }, null, 8, ["description"]))
  ], 64))
}