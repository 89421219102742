<template>
  <MDialog
    title="Credit Limit Request Confirmation"
    v-model="isMdialogOpen"
    @click="onCloseModal()"
  >
    <template #content>
      <p>
        You have requested credit
        <span class="amount">{{ formattedAmountRequested }}</span>
      </p>
      <p>
        <span class="amount">{{ formattedAmountFee }}</span> processing fee will
        be applied.
      </p>
    </template>

    <template #actions>
      <MButton type="outlined" @click="onCloseModal()"> Cancel </MButton>
      <MButton
        @click="onConfirm()"
        :disabled="$store.getters.isCreatingCreditLimitRequest"
      >
        Confirm
      </MButton>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";

export default defineComponent({
  name: "CreditLimitConfirmationModal",
  events: ["onCloseModal", "onConfirm"],
  props: {
    isOpen: { type: Boolean, required: true },
    amountRequested: { type: Number, required: true },
    amountFee: { type: Number, required: true },
  },
  data() {
    return {
      isMdialogOpen: this.isOpen,
    };
  },
  watch: {
    isOpen(newVal) {
      this.isMdialogOpen = newVal;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("closeModal");
      }
    },
  },
  methods: {
    formattedAmount(amount: number) {
      if (amount) {
        const currency = "PKR";
        const formattedAmount = getCurrencyFormatter(currency).format(amount);
        return formattedAmount;
      }
      return "0";
    },
    onCloseModal() {
      this.$emit("onCloseModal");
    },
    onConfirm() {
      this.$emit("onConfirm");
    },
  },
  computed: {
    formattedAmountRequested(): string {
      return this.formattedAmount(this.amountRequested);
    },
    formattedAmountFee(): string {
      return this.formattedAmount(this.amountFee);
    },
  },
});
</script>

<style lang="scss" scoped>
.amount {
  font-weight: bold;
}

.action-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
