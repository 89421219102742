import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e6f28c7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDatePicker = _resolveComponent("MDatePicker")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_ShimmerCard = _resolveComponent("ShimmerCard")!
  const _component_FlightIssuance = _resolveComponent("FlightIssuance")!
  const _component_FlightBookings = _resolveComponent("FlightBookings")!
  const _component_HotelBookings = _resolveComponent("HotelBookings")!
  const _component_SabreSegments = _resolveComponent("SabreSegments")!
  const _component_Payments = _resolveComponent("Payments")!
  const _component_CreditLimit = _resolveComponent("CreditLimit")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MCard, { class: "sector-snapshot-header" }, {
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_1, "Statistics " + _toDisplayString(_ctx.selectedSalesman), 1),
        _createVNode(_component_ag_row, { class: "mt-10" }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_column, {
              xs: "12",
              sm: "12",
              md: "12",
              lg: "5"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MDatePicker, {
                  label: "Start Date",
                  "start-date": _ctx.defaultStartDate,
                  "max-date": new Date(),
                  onOnRangeDateChange: _cache[0] || (_cache[0] = (value) => _ctx.startDate = value),
                  placeholder: "Start Date",
                  dateType: "startDate",
                  multiCalendar: false,
                  hasRange: false,
                  autoPosition: true,
                  error: _ctx.bookingDateError
                }, null, 8, ["start-date", "max-date", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              xs: "12",
              sm: "12",
              md: "12",
              lg: "5"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MDatePicker, {
                  label: "End Date",
                  "start-date": new Date(),
                  "max-date": new Date(),
                  "min-date": _ctx.startDate,
                  onOnRangeDateChange: _cache[1] || (_cache[1] = (value) => _ctx.endDate = value),
                  placeholder: "End Date",
                  dateType: "endDate",
                  multiCalendar: false,
                  hasRange: false,
                  autoPosition: true,
                  error: _ctx.bookingDateError
                }, null, 8, ["start-date", "max-date", "min-date", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              xs: "12",
              sm: "12",
              md: "12",
              lg: "2",
              class: "d-flex align-items-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MButton, {
                  onClick: _ctx.handleFetchData,
                  disabled: _ctx.isSalesLoading,
                  class: "margin_bottom_20"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Fetch Statistics")
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.isSalesLoading)
      ? (_openBlock(), _createBlock(_component_MCard, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_ShimmerCard, { "card-height": "200px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "200px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "150px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "100px" })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showDetails)
      ? (_openBlock(), _createBlock(_component_MCard, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_FlightIssuance, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8, ["handleOrganizationRowClick"]),
            _createVNode(_component_FlightBookings, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8, ["handleOrganizationRowClick"]),
            _createVNode(_component_HotelBookings, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8, ["handleOrganizationRowClick"]),
            _createVNode(_component_SabreSegments, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8, ["handleOrganizationRowClick"]),
            _createVNode(_component_Payments, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8, ["handleOrganizationRowClick"]),
            _createVNode(_component_CreditLimit, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8, ["handleOrganizationRowClick"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showResultNotFound)
      ? (_openBlock(), _createBlock(_component_ResultNotFound, { key: 2 }))
      : _createCommentVNode("", true)
  ], 64))
}