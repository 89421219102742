import { GetterTree } from "vuex";
import { IPaymentState } from "@/interfaces/paymentState.interface";
import {
  IPayment,
  IPayments,
  IPaymentMethodsByAeroglobeSector,
} from "@/interfaces/payments.interface";
import { StoreState } from "@/store/type";

const getters: GetterTree<IPaymentState, StoreState> = {
  isFetchingPayments: (state): boolean => state.isFetchingPayments,
  isFetchingPayment: (state): boolean => state.isFetchingPayment,
  isCreatingPayment: (state): boolean => state.isCreatingPayment,
  payments: (state): IPayments => state.payments,
  payment: (state): IPayment | null => state.payment,
  paymentsBySectors: (state): IPaymentMethodsByAeroglobeSector | null =>
    state.paymentsByMethods,
};

export default getters;
