export const AGENT_API_PATH = {
  UPDATE_AGENT_PERMISSIONS: "v2/organization/{0}/agent/{1}/",
  PERMISSIONS_CHANGE_VERIFICATION:
    "v2/organization/permission-change/verification/",
  DELETE_AGENT: "v2/agent-user",

  GET_AGENT_PRICING: (agentId: string): string =>
    `agentuser/${agentId}/agent-pricing/`,
  UPDATE_AGENT_PRICING: (agentId: string): string =>
    `agentuser/${agentId}/agent-pricing/update/`,
};
