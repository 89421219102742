<template>
  <MDialog
    title="Logs"
    v-model="isMdialogOpen"
    @close="handleClose"
    max-width="1000"
  >
    <template #content>
      <vue-json-pretty
        :data="jsonData"
        :show-icon="true"
        :show-line-number="true"
      />
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { JSONDataType } from "vue-json-pretty/types/utils";

export default defineComponent({
  name: "ApiResponseJsonViewerModal",
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      isMdialogOpen: this.isOpen,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    jsonData: {
      type: Object as PropType<JSONDataType>,
      default: () => ({}),
    },
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
  },
  watch: {
    isOpen(newVal) {
      this.isMdialogOpen = newVal;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("handleClose");
      }
    },
  },
});
</script>
