export const CREDIT_LIMIT_MANAGEMENT_API_PATH = {
  GET: "v2/limit-request",
  POST: "v2/limit-request/create",
  POST_PROCESS: "v2/limit-request/process",
  GET_BY_ID: "v2/limit-request/{0}",
  GET_CREDIT_LIMIT_SLABS: "v2/credit-limit-slabs",
};

export const CREDIT_LIMIT_REQUEST_PRIORITIES = [
  {
    label: "Low",
    value: "LOW",
  },
  {
    label: "Normal",
    value: "NORMAL",
  },
  {
    label: "Urgent",
    value: "URGENT",
  },
];

export enum CREDIT_STATUSES {
  NEW = "NEW",
  IN_PROGRESS = "IN_PROGRESS",
  PARTIALLY_APPROVED = "PARTIALLY_APPROVED",
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
}

export const CREDIT_STATUSES_MAPPING = {
  [CREDIT_STATUSES.NEW]: "blue",
  [CREDIT_STATUSES.IN_PROGRESS]: "yellow",
  [CREDIT_STATUSES.PARTIALLY_APPROVED]: "gray",
  [CREDIT_STATUSES.APPROVED]: "green",
  [CREDIT_STATUSES.CANCELLED]: "red",
};

export enum CREDIT_PRIORITIES {
  LOW = "LOW",
  NORMAL = "NORMAL",
  URGENT = "URGENT",
}

export const CREDIT_PRIORITIES_MAPPING = {
  [CREDIT_PRIORITIES.URGENT]: "error",
  [CREDIT_PRIORITIES.NORMAL]: "warning",
  [CREDIT_PRIORITIES.LOW]: "success",
};
