import { Module } from "vuex";
import { IMyBookingsState } from "@/interfaces/myBookingsState.interface";
import { StoreState } from "@/store/type";

import mutations from "./mutations";
import actions from "./actions";
import state from "./state";
import getters from "./getters";

const myBookingsModule: Module<IMyBookingsState, StoreState> = {
  state,
  mutations,
  actions,
  getters,
};

export default myBookingsModule;
