import { GetterTree } from "vuex";
import {
  IVisaVerificationState,
  VisaVerificationData,
} from "@/ag-portal-common/interfaces/visaVerificationState.interface";
import { StoreState } from "@/store/type";

const getters: GetterTree<IVisaVerificationState, StoreState> = {
  isDownloadVisaLoading: (state): boolean => state.isDownloadVisaLoading,
  isAddingRequestLoading: (state): boolean => state.isAddingRequestLoading,
  isFetchingRequestLoading: (state): boolean => state.isFetchingRequestLoading,
  visaVerificationRequestsList: (state): VisaVerificationData[] | null =>
    state.visaRequestsList,
};

export default getters;
