<template>
  <MProgress
    class="mprogress-alignment"
    indeterminate
    v-if="$store.getters.isSalesMyTeamLoading"
  />
  <template v-else>
    <MCard>
      <AgIconInfoBar
        title="My Team"
        class="section-title"
        mPrepandIcon="m-my-team"
      />
    </MCard>
    <div v-if="myTeam.length > 0">
      <div v-for="(item, index) in myTeam" :key="index" class="my-teams">
        <MAccordion class="flight_accordion_wrap">
          <template #title>
            <AgIconInfoBar
              :title="renderSectoName(item.sector_name)"
              class="section-title"
              mPrepandIcon="m-my-team"
            />
          </template>
          <template #content>
            <MDataTable
              :headers="headers"
              :data="item.my_team_members"
              :item-per-page="30"
              :has-search="false"
              :simpleDataTable="true"
            >
            </MDataTable>
          </template>
        </MAccordion>
      </div>
    </div>
    <MCard v-else class="m-card">
      <ResultNotFound title="No Sales Team Found" />
    </MCard>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IMyTeam } from "@/ag-portal-common/interfaces/salesMyTeam.interface";
import { MAccordion, MCard, MDataTable } from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";

import ResultNotFound from "@/components/ResultNotFound.vue";

export default defineComponent({
  name: "SalesMyTeam",
  components: {
    ResultNotFound,
    MCard,
    MDataTable,
    MAccordion,
  },
  data(): { headers: MDataTableHeader[] } {
    return {
      headers: [
        {
          title: "Email",
          value: "email",
          key: "email",
          sortable: true,
        },
        {
          title: "Role",
          value: "role",
          key: "role",
          sortable: true,
        },
      ] as MDataTableHeader[],
    };
  },
  methods: {
    renderSectoName(city: string): string {
      return `${city} Sector`;
    },
  },
  computed: {
    myTeam(): IMyTeam[] {
      return this.$store.getters.salesMyTeam;
    },
  },
  mounted() {
    this.$store.dispatch("getSalesMyTeam");
  },
});
</script>
<style lang="css" scoped>
.my-teams {
  margin-top: 10px;
}
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
</style>
