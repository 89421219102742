<template>
  <div class="form-preview">
    <MTypography class="headline" type="headline">{{ title }}</MTypography>
    <div
      v-for="field in previewFields"
      :key="field.key"
      class="preview-field"
      :class="{ 'has-error': hasError(field.key) }"
    >
      <MTypography type="body" class="preview-label"
        >{{ field.label }}:</MTypography
      >
      <MTypography type="body" class="preview-value">
        {{ formatPreviewValue(field.key, traveler[field.key]) }}
      </MTypography>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { MTypography } from "@aeroglobe/ag-core-ui";
import {
  PreviewField,
  Traveler,
} from "@/ag-flight-components/interfaces/IPassengerBookingForm";

export default defineComponent({
  name: "NewTravelerCardPreview",
  components: {
    MTypography,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    traveler: {
      type: Object as PropType<Traveler>,
      required: true,
    },
    previewFields: {
      type: Array as PropType<PreviewField[]>,
      required: true,
    },
    formatPreviewValue: {
      type: Function as PropType<(key: string, value: any) => string>,
      required: true,
    },
    errors: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
  },
  methods: {
    hasError(field: string): boolean {
      return !!this.errors[field];
    },
  },
});
</script>

<style lang="scss" scoped>
.form-preview {
  background-color: var(--login-screen-color);
  padding: 32px;
  border-radius: 16px;
  margin-top: 20px;
  width: 370px;

  .headline {
    margin-bottom: 20px;
  }

  .preview-field {
    border-bottom: 1px solid #e6e8f0;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;

    &.has-error {
      color: red;
    }
  }

  .preview-label {
    color: var(--neutral-600);
  }

  .preview-value {
    text-align: right;
    color: var(--neutral-900);
  }
}

@media (max-width: 767px) {
  .form-preview {
    width: 100%;
    padding: 20px;
  }
}
</style>
