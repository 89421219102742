import { MutationTree } from "vuex";

import { IDealsState } from "./state";

import { PROMOS_AND_DEALS } from "@/modules/Deals/constants";

const mutations: MutationTree<IDealsState> = {
  isPromoAndDealFetching(state, isFetching: boolean) {
    state.isFetchingPromosAndDeals = isFetching;
  },
  fetchedOrganizationPromosAndDeals(state, payload: PROMOS_AND_DEALS) {
    state.isFetchingPromosAndDeals = false;
    state.deals = payload.applicable_deals;
    state.promos = payload.applicable_promos;
  },
};

export default mutations;
