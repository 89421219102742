<template>
  <DashboardGreetingsCard user="Finance User" />
  <DigitalPaymentsChart />
  <SaasEliteOrganizationList :is-dashboard-view="true" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DashboardGreetingsCard from "../DashboardGreetingsCard.vue";
import DigitalPaymentsChart from "@/modules/Snapshots/views/DigitalPayments.vue";
import SaasEliteOrganizationList from "@/modules/Organization/views/SaasEliteOrganizationList.vue";

export default defineComponent({
  name: "FinanceUserDashboardView",
  components: {
    DashboardGreetingsCard,
    DigitalPaymentsChart,
    SaasEliteOrganizationList,
  },
});
</script>
