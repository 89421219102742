export interface IVisaVerificationState {
  isAddingRequestLoading: boolean;
  isFetchingRequestLoading: boolean;
  isDownloadVisaLoading: boolean;
  visaRequestsList: VisaVerificationData[] | null;
}

export enum VISA_VERIFICATION_STATUS_ENUM {
  PENDING = "PENDING",
  VERIFIED = "VERIFIED",
  FAILED = "FAILED",
}

export type VisaVerificationData = {
  public_id: string;
  organization_id: string;
  uploaded_visa_id: string | null;
  downloaded_visa_id: string | null;
  first_name: string;
  last_name: string;
  pnr: string;
  visa_number: string;
  passport_number: string;
  country: string;
  status: VISA_VERIFICATION_STATUS_ENUM;
  reason: string;
  captcha_try_count: number;
  created_at: string;
};
