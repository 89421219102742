import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fbbd01d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pdf-upload" }
const _hoisted_2 = {
  key: 0,
  class: "pdf-preview"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTextfield = _resolveComponent("MTextfield")!
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MFabButton = _resolveComponent("MFabButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: "passenger-visa-card",
    onSubmit: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createVNode(_component_MTextfield, {
      inputValue: _ctx.firstNameComputed,
      "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstNameComputed) = $event)),
      type: "text",
      label: "First Name",
      placeholder: "Enter First Name",
      hasError: !!_ctx.getError('firstName'),
      errorMessage: _ctx.getError('firstName'),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateField('firstName'))),
      name: "firstName",
      disabled: ""
    }, null, 8, ["inputValue", "hasError", "errorMessage"]),
    _createVNode(_component_MTextfield, {
      inputValue: _ctx.lastNameComputed,
      "onUpdate:inputValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.lastNameComputed) = $event)),
      type: "text",
      label: "Last Name",
      placeholder: "Enter Last Name",
      hasError: !!_ctx.getError('lastName'),
      errorMessage: _ctx.getError('lastName'),
      onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateField('lastName'))),
      name: "lastName",
      disabled: ""
    }, null, 8, ["inputValue", "hasError", "errorMessage"]),
    _createVNode(_component_MCombobox, {
      inputValue: _ctx.countryComputed,
      "onUpdate:inputValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.countryComputed) = $event)),
      label: "Visa Country",
      itemValue: "value",
      itemLabel: "label",
      options: _ctx.countryOptions,
      hasError: !!_ctx.getError('country'),
      errorMessage: _ctx.getError('country'),
      onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.validateField('country'))),
      name: "country"
    }, null, 8, ["inputValue", "options", "hasError", "errorMessage"]),
    _createVNode(_component_MTextfield, {
      inputValue: _ctx.passportNumberComputed,
      "onUpdate:inputValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.passportNumberComputed) = $event)),
      type: "text",
      label: "Passport Number",
      placeholder: "Enter Passport Number",
      hasError: !!_ctx.getError('passportNumber'),
      errorMessage: _ctx.getError('passportNumber'),
      onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.validateField('passportNumber'))),
      name: "passportNumber"
    }, null, 8, ["inputValue", "hasError", "errorMessage"]),
    _createVNode(_component_MTextfield, {
      inputValue: _ctx.visaNumberComputed,
      "onUpdate:inputValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.visaNumberComputed) = $event)),
      type: "text",
      label: "Visa Number",
      placeholder: "Enter Visa Number",
      hasError: !!_ctx.getError('visaNumber'),
      errorMessage: _ctx.getError('visaNumber'),
      onBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.validateField('visaNumber'))),
      name: "visaNumber"
    }, null, 8, ["inputValue", "hasError", "errorMessage"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_MButton, {
        onClick: _ctx.triggerFileInput,
        class: "upload-button"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.passenger.visaDocument ? "Change Visa PDF" : "Upload Visa PDF"), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createElementVNode("input", {
        type: "file",
        ref: "fileInput",
        onChange: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.handleFileUpload && _ctx.handleFileUpload(...args))),
        accept: "application/pdf",
        style: {"display":"none"}
      }, null, 544),
      (_ctx.passenger.visaDocument)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", null, _toDisplayString(_ctx.passenger.visaDocument.name), 1),
            _createVNode(_component_MFabButton, {
              class: "delete-icon",
              icon: "m-delete",
              onClick: _ctx.deleteFile
            }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 32))
}