import { MutationTree } from "vuex";
import { IFareManagementState } from "@/interfaces/fareManagementState.interface";
import { fareManagementResponse } from "../constants";

const mutations: MutationTree<IFareManagementState> = {
  saveSabreFares(state: IFareManagementState, payload: fareManagementResponse) {
    state.sabreFares = payload;
  },
  resetSabreFares(state: IFareManagementState) {
    state.sabreFares = null;
  },
  setSabreFaresLoading(state: IFareManagementState, payload: boolean) {
    state.isSabreFaresLoading = payload;
  },
};

export default mutations;
