<template>
  <div class="authentication-wrapper">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AUTH_EVENTS, authBus } from "@/ag-portal-common/eventBusses/auth";
import storageService from "@/ag-portal-common/services/storage.service";
import { clearCache } from "@/ag-portal-common/utils/helpers";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";
import { PATH } from "@/ag-portal-common/constants/path";
import { LoginResponse, Organization, USER_ROLES, User } from "../types";
import UTILS from "@/ag-portal-common/utils";
import { PERMISSIONS } from "@/ag-portal-common/enums/PERMISSIONS";
import { AUTH_MUTATIONS } from "../vuex/mutations";
import analyticServices from "@/analytic.service";
import { AUTH_ANALYTICS_EVENTS } from "../constants/analyticsEvents";

export default defineComponent({
  name: "AuthenticationWrapper",
  created() {
    const accessToken = storageService.getItem<string>(
      STORAGE_KEYS.ACCESS_TOKEN,
      false
    );
    const refreshToken = storageService.getItem<string>(
      STORAGE_KEYS.REFRESH_TOKEN,
      false
    );
    const permissions = storageService.getItem<PERMISSIONS[]>(
      STORAGE_KEYS.PERMISSIONS
    );
    const user = storageService.getItem<User>(STORAGE_KEYS.USER);
    const organization = storageService.getItem<Organization>(
      STORAGE_KEYS.ORGANIZATION
    );

    if (accessToken || refreshToken) {
      if (!(user && permissions)) {
        this.logout();
      }
    }

    if (user) {
      UTILS.updateUser(user);
    }

    if (permissions) {
      UTILS.updatePermissions(permissions);
    }

    if (organization) {
      UTILS.updateOrganization(organization);
    }
  },

  methods: {
    login(response: LoginResponse) {
      storageService.setItem(
        STORAGE_KEYS.ACCESS_TOKEN,
        response.token.access,
        false
      );
      storageService.setItem(
        STORAGE_KEYS.REFRESH_TOKEN,
        response.token.refresh,
        false
      );
      storageService.setItem(
        STORAGE_KEYS.PERMISSIONS,
        JSON.stringify(response.permissions)
      );

      const user = response.user;
      const isAgentUser = user.role_unique_id === USER_ROLES.AGENT;

      analyticServices.setUser(user);
      analyticServices.logActionEvent(AUTH_ANALYTICS_EVENTS.LOGIN, {
        email: user.email,
        role: user.role_name,
      });

      if (isAgentUser) {
        const agentUser = user.agent_user_data;

        if (agentUser && agentUser.id) {
          this.$store.dispatch("fetchPreferences", {
            agentId: agentUser.id,
          });
        }
      }

      UTILS.updateUser(response.user);

      this.$router.push(PATH.DASHBOARD);
    },
    clearStorage() {
      this.$store.commit(AUTH_MUTATIONS.RESET_LOGIN_RESPONSE);

      storageService.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
      storageService.removeItem(STORAGE_KEYS.REFRESH_TOKEN);

      storageService.removeItem(STORAGE_KEYS.USER);
      storageService.removeItem(STORAGE_KEYS.PERMISSIONS);

      storageService.removeItem(STORAGE_KEYS.FINANCIAL_PROFILES);
      storageService.removeItem(STORAGE_KEYS.SECTORS);

      storageService.removeItem(STORAGE_KEYS.LAST_FETCHED_FEATURED_NEWS_TIME);
    },
    logout() {
      clearCache();

      this.clearStorage();

      this.$router.replace(PATH.ROOT);

      if (typeof window.FreshworksWidget === "function") {
        if (window.isFreshworksWidgetMounted) {
          window.FreshworksWidget("destroy");
        }
      }
    },
  },
  beforeMount() {
    authBus.on(AUTH_EVENTS.LOGIN, (data) => {
      this.login(data as LoginResponse);
    });
    authBus.on(AUTH_EVENTS.LOGOUT, () => {
      this.logout();
    });
    authBus.on(AUTH_EVENTS.ROUTE_ON_ERROR_403, async (path) => {
      await this.$router.push({
        path: PATH.Error_403,
        query: { routeId: path as string },
      });
      window.history.pushState({}, document.title, `${location.origin}${path}`);
    });
  },
});
</script>
