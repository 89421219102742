import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0062ba9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "update-providers-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createBlock(_component_MDialog, {
    modelValue: _ctx.isMdialogOpen,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isMdialogOpen) = $event)),
    title: "Update Providers",
    onClose: _ctx.closeDialog,
    class: "update-providers m-scrollbar"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MCombobox, {
          inputValue: _ctx.localPiaProvider,
          "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localPiaProvider) = $event)),
          label: "PIA Provider",
          itemValue: "value",
          itemLabel: "label",
          options: _ctx.piaProvidersOptions,
          hasError: !!_ctx.piaProviderError,
          errorMessage: "Required",
          name: "piaProvider"
        }, null, 8, ["inputValue", "options", "hasError"]),
        _createVNode(_component_MCombobox, {
          inputValue: _ctx.localAirblueProvider,
          "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localAirblueProvider) = $event)),
          label: "Airblue Provider",
          itemValue: "value",
          itemLabel: "label",
          options: _ctx.airblueProvidersOptions,
          hasError: !!_ctx.airblueProviderError,
          errorMessage: "Required",
          name: "airblueProvider"
        }, null, 8, ["inputValue", "options", "hasError"])
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_MButton, {
        disabled: _ctx.isLoading,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onUpdate()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Update")
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 8, ["modelValue", "onClose"]))
}