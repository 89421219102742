<template>
  <div
    id="freshdesk-widget"
    class="floating-widget-button"
    @click="onClickWidgetHandler"
  >
    <MIcon name="m-send-message" width="14" height="14" />

    <div>FreshDesk</div>
  </div>
</template>

<script>
import { MIcon } from "@aeroglobe/ag-core-ui";

import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";
import storageService from "../services/storage.service";

export default {
  name: "FreshdeskWidget",
  components: {
    MIcon,
  },
  data() {
    return {
      user: {},
    };
  },
  methods: {
    setWidget(email) {
      if (email) {
        setTimeout(async () => {
          window.FreshworksWidget("prefill", "ticketForm", {
            email: email,
          });
          const response = await fetch(
            "https://widget.freshworks.com/widgets/70000003972.json"
          );
          const data = await response.json();
          const ticketFormIds =
            data?.settings?.contact_form?.ticket_forms?.ticket_form_ids || [];

          ticketFormIds.forEach((formId) => {
            window.FreshworksWidget("disable", "ticketForm", ["email"], {
              formId: formId,
            });
          });

          window.isFreshworksWidgetMounted = true;
        }, 1200);
      }
    },
    onClickWidgetHandler() {
      const iframe = document.querySelector("#freshworks-container iframe");

      if (iframe) {
        const widgetButton =
          iframe.contentWindow.document.querySelector(".launcher-button");
        console.error(widgetButton);

        if (widgetButton) {
          widgetButton.click();
        }
      }
    },
  },
  mounted() {
    const user = storageService.getItem(STORAGE_KEYS.USER);
    this.user = user;
    // Freshdesk widget initialization
    window.fwSettings = {
      widget_id: 70000003972,
    };
    !(function () {
      if ("function" != typeof window.FreshworksWidget) {
        var n = function () {
          n.q.push(arguments);
        };
        (n.q = []), (window.FreshworksWidget = n);
      }
    })();
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://widget.freshworks.com/widgets/70000003972.js";
    script.async = true;
    script.defer = true;
    document.getElementById("freshdesk-widget").appendChild(script);
  },

  watch: {
    user(value) {
      this.setWidget(value?.email);
    },
  },
};
</script>

<style>
#freshworks-container iframe {
  z-index: -100 !important;
}
</style>

<style scoped>
.floating-widget-button {
  background-color: #05a886;
}
</style>
