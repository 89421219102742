<template>
  <MSideSheet v-model="isOpen" title="Transaction Details">
    <MTransition>
      <div v-if="isFetchingPayment" class="payment-loader">
        <MProgress indeterminate />
      </div>
      <div v-else-if="!isFetchingPayment && payment">
        <div class="payment-summary">
          <img :src="paymentAvatarLink" class="payment-avatar" />
          <MTypography type="title">{{ paymentType }}</MTypography>
          <MTypography type="headline">{{ formattedCurrency }}</MTypography>
        </div>

        <div
          v-if="payment?.creditCardlDetail"
          class="payment-actions"
          @click="onProceedPayment()"
        >
          <div>Proceed To Pay</div>
          <MIcon class="open-link-icon primary" name="m-open-url-in-new-tab" />
        </div>

        <div
          v-if="payment?.oneBillDetail"
          class="payment-actions"
          @click="onPaymentGuide()"
        >
          <div>How To Pay</div>
          <MIcon class="open-link-icon primary" name="m-open-url-in-new-tab" />
        </div>
        <hr />

        <div class="payment-date-and-time">
          <MTypography class="date-label" type="label">Date</MTypography>
          <MTypography class="time-label" type="label">Time</MTypography>
        </div>

        <div class="payment-date-and-time">
          <MTypography class="formatted-date">{{ formattedDate }}</MTypography>
          <MTypography class="formatted-time">{{ formattedTime }}</MTypography>
        </div>
        <hr />

        <div class="payment-date-and-time">
          <MTypography class="date-label" type="label"
            >Payment Status</MTypography
          >
          <MTypography class="time-label" type="label"
            >Receipt Status</MTypography
          >
        </div>

        <div class="payment-date-and-time statuses">
          <MChip
            :border-less="true"
            class="formatted-date"
            :variant="paymentStatusClass(paymentStatus)"
          >
            {{ PAYMENT_STATUSES[paymentStatus] }}
          </MChip>
          <MChip
            :border-less="true"
            class="formatted-time"
            :variant="receiptStatusClass(receiptStatus)"
          >
            {{ RECEIPT_STATUSES[receiptStatus] }}
          </MChip>
        </div>

        <div class="payment-date-and-time" v-if="isOneBill">
          <MTypography class="date-label one-bill-id" type="label"
            >One Bill ID</MTypography
          >
        </div>

        <div class="payment-date-and-time" v-if="isOneBill">
          <MTypography class="formatted-date">{{ oneBillID }}</MTypography>
        </div>
        <hr />
        <div class="payment-table">
          <table>
            <thead>
              <tr>
                <th><MTypography>Transaction Id</MTypography></th>
                <th><MTypography>Type</MTypography></th>
                <th class="amount-details">
                  <MTypography>Amount</MTypography>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <MTypography type="body">{{ payment?.id }}</MTypography>
                </td>
                <td><MTypography type="label">Amount</MTypography></td>
                <td class="amount-details">
                  <MTypography type="label">{{
                    formattedCurrency
                  }}</MTypography>
                </td>
              </tr>
              <tr>
                <td></td>
                <td><MTypography type="label">Charges</MTypography></td>
                <td class="amount-details">
                  <MTypography type="label">{{ formattedCharges }}</MTypography>
                </td>
              </tr>
              <tr>
                <td></td>
                <td><MTypography type="label">Total Credited</MTypography></td>
                <td class="amount-details">
                  <MTypography>{{ amountCreditedInWallet }}</MTypography>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />

        <div class="agent-details">
          <MTypography type="label">Agent Name</MTypography
          ><MTypography>{{ agentName }}</MTypography>
        </div>

        <div class="agent-details">
          <MTypography type="label">Agent Email</MTypography
          ><MTypography>{{ payment?.agentEmail }}</MTypography>
        </div>

        <div class="agent-details">
          <MTypography type="label">Financial Profile</MTypography
          ><MTypography>{{ payment?.financialProfile }}</MTypography>
        </div>

        <div class="agent-details">
          <MTypography type="label">Description</MTypography
          ><MTypography>{{ paymentDescripition }}</MTypography>
        </div>
      </div>
      <ResultNotFound v-else title="No Payment Details Found" />
    </MTransition>
  </MSideSheet>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  formatNumber,
  genTestId,
  getFormattedDateTime,
  getCurrencyFormatter,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IPayment } from "@/interfaces/payments.interface";
import {
  payGuideUrl,
  PAYMENT_STATUS_MAPPING,
  RECEIPT_STATUS_MAPPING,
} from "@/modules/Payments/constants";
import { TRANSACTION_TYPES } from "@/ag-portal-common/constants/transactionTypes";
import { PAYMENT_STATUSES } from "../../../ag-portal-common/constants/paymentStatuses";
import { RECEIPT_STATUSES } from "../../../ag-portal-common/constants/receiptStatuses";
import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_HH_SS_24_HOURS,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { PAYMENT_STATUS } from "@/ag-portal-common/enums/PAYMENT_STATUS";
import { RECEIPT_STATUS } from "@/ag-portal-common/enums/RECEIPT_STATUS";
import {
  AGENT_FINANCES_EVENTS,
  agentFinanceBus,
} from "@/ag-portal-common/eventBusses/agentFinances";
import analyticsService from "@/analytic.service";
import { PAYMENTS_ANALYTICS_EVENTS } from "@/modules/Payments/constants/anaylticsEvents";
import UTILS from "@/ag-portal-common/utils";
import {
  MSideSheet,
  MTypography,
  MTransition,
  MProgress,
} from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "PaymentDetailModal",
  components: {
    MSideSheet,
    MTypography,
    MTransition,
    MProgress,
  },
  computed: {
    isOneBill(): boolean {
      let oneBillDetail = this.payment?.oneBillDetail;
      return oneBillDetail !== null;
    },
    RECEIPT_STATUSES() {
      return RECEIPT_STATUSES;
    },
    PAYMENT_STATUSES() {
      return PAYMENT_STATUSES;
    },
    TRANSACTION_TYPES() {
      return TRANSACTION_TYPES;
    },
    ELEMENT_TYPES() {
      return ELEMENT_TYPES;
    },
    payment(): IPayment | null {
      return this.$store.getters.payment;
    },
    paymentStatus(): string {
      const paymentStatus = this.payment?.paymentStatus || "";
      return paymentStatus;
    },
    receiptStatus(): string {
      const receiptStatus = this.payment?.receiptStatus || "";
      return receiptStatus;
    },

    paymentDescripition(): string {
      const paymentDescripition = this.payment?.description || "";
      if (paymentDescripition?.length === 0) {
        return "-";
      } else {
        return paymentDescripition;
      }
    },
    agentName(): string {
      const name = this.payment?.agentName || "";
      if (name?.length === 0) {
        return "-";
      } else {
        return name;
      }
    },
    isFetchingPayment(): boolean {
      return this.$store.getters.isFetchingPayment;
    },
    formattedDate(): string {
      return getFormattedDateTime(
        this.payment?.dateTime || new Date(),
        FORMAT_DD_MMM_YYYY_WITH_DASH
      );
    },
    formattedTime(): string {
      return getFormattedDateTime(
        this.payment?.dateTime || new Date(),
        FORMAT_HH_SS_24_HOURS
      );
    },
    formattedCharges(): string {
      const amount = this.payment?.charges || 0;
      const currency = UTILS.getCurrency() || "";
      const formattedAmount = getCurrencyFormatter(currency).format(amount);
      return formattedAmount;
    },
    paymentAvatarLink(): string {
      let oneBillDetail = this.payment?.oneBillDetail;
      let creditCardDetail = this.payment?.creditCardlDetail;
      let ibftDetail = this.payment?.ibftDetail;

      const oneBillUrl =
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/OneBill.png?alt=media&token=95432002-099c-4155-bdda-3feef658331d";

      const creditCardUrl =
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/CreditCard.png?alt=media&token=1cb3a56a-095d-4015-8cb3-1a2461260a4a";

      const ibftUrl =
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/IBFT.png?alt=media&token=3c0faf83-7048-4ef0-aa60-dbdd7612569b";

      switch (true) {
        case oneBillDetail !== null:
          return oneBillUrl;
        case creditCardDetail !== null:
          return creditCardUrl;
        case ibftDetail !== null:
          return ibftUrl;
        default:
          return "";
      }
    },
    paymentType(): string {
      let oneBillDetail = this.payment?.oneBillDetail;
      let creditCardDetail = this.payment?.creditCardlDetail;
      let ibftDetail = this.payment?.ibftDetail;

      switch (true) {
        case oneBillDetail !== null:
          return "One Bill";
        case creditCardDetail !== null:
          return "Credit Card";
        case ibftDetail !== null:
          return "IBFT";
        default:
          return "";
      }
    },
    oneBillID(): string {
      return this.payment?.oneBillDetail?.billCode || "";
    },
    formattedCurrency(): string {
      const amount = this.payment?.amount || 0;
      const currency = UTILS.getCurrency() || "";
      const formattedAmount = getCurrencyFormatter(currency).format(amount);
      return formattedAmount;
    },
    amountCreditedInWallet(): string {
      const amount = this.payment?.amount || 0;
      const charges = this.payment?.charges || 0;
      const total = amount - charges;
      const currency = UTILS.getCurrency() || "";
      const formattedAmount = getCurrencyFormatter(currency).format(total);
      return formattedAmount;
    },
  },
  methods: {
    formatNumber,
    genTestId,
    receiptStatusClass(status: RECEIPT_STATUS) {
      return `${RECEIPT_STATUS_MAPPING[status]}`;
    },
    paymentStatusClass(status: PAYMENT_STATUS) {
      return `${PAYMENT_STATUS_MAPPING[status]}`;
    },
    onProceedPayment() {
      const paymentUrl = this.payment?.creditCardlDetail?.paymentUrl;

      analyticsService.logActionEvent(
        PAYMENTS_ANALYTICS_EVENTS.PAYMENT_PROCEED,
        { "payment-url": paymentUrl }
      );

      window.open(paymentUrl);
    },
    closeModal() {
      this.$emit("onCloseModal");
    },
    onPaymentGuide() {
      analyticsService.logActionEvent(PAYMENTS_ANALYTICS_EVENTS.PAYMENT_GUIDE, {
        "pay-guide-url": payGuideUrl,
      });

      window.open(payGuideUrl);
    },
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.showModal,
    };
  },

  beforeMount() {
    this.$store.dispatch("fetchPayment", this.$route.params.id);
  },
  watch: {
    showModal(value) {
      this.isOpen = value;
    },
    isFetchingPayment() {
      if (
        !this.isFetchingPayment &&
        this.payment?.paymentStatus === PAYMENT_STATUS.SUCCESS
      ) {
        agentFinanceBus.emit(AGENT_FINANCES_EVENTS.REFRESH, true);
      }
    },
  },
});
</script>

<style lang="css" scoped>
.payment-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
.payment-date-and-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
}

.date-label,
.formatted-date,
.status-label {
  text-align: left;
}

.amount-details,
.status-value {
  text-align: right;
}
.time-label,
.formatted-time {
  text-align: right;
}
.payment-avatar {
  height: 64px;
  border-radius: 30%;
  background-color: #000000;
  border: 1px solid #f5f5f5;
}
.payment-table {
  border-radius: 4px;
  overflow: hidden;
}
table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: none;
}
tr:hover {
  background-color: #f1f1f1;
}
.agent-details {
  margin-bottom: 10px;
}

.payment-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
}

.payment-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 500;
  color: var(--m-primary-color);

  cursor: pointer;

  transition: opacity 0.2s ease-in-out;
  margin-bottom: 10px;
}

.payment-actions:hover {
  opacity: 0.75;
}
.open-link-icon {
  width: 18px;
  height: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.open-link-icon.primary {
  color: var(--m-primary-color);
}
.payment-loader {
  min-height: calc(100vh - (400px + 64px));
  display: flex;
  align-items: center;
  justify-content: center;
}
.statuses {
  margin-top: 10px;
}
.one-bill-id {
  margin-top: 10px;
}
</style>
