<template>
  <AgCard>
    <ag-heading variant="h2" title="Upload Documents"></ag-heading>
  </AgCard>
  <AgCard class="form_container">
    <form @submit="onSubmit" class="form">
      <AgDiv>
        <AgFile
          test-id=""
          class="ag-file-theme"
          label="Upload CNIC (Front, Back)"
          :multiple="true"
          @on:change="onFileChangeHandler"
        />
        <p class="error_msg">{{ fieldError }}</p>
      </AgDiv>

      <AGButton
        test-id=""
        type="submit"
        class="submit-btn"
        :is-loading="isLoading"
        :disabled="isDisabled"
      >
        Upload
      </AGButton>
    </form>
  </AgCard>
  <UploadDocumentSuccessDialog
    :is-open="isDialogOpen"
    @handleClose="handleCloseDialog"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  OrganizationContentUpload,
  UPLOAD_CONTENT_CATEGORY,
  UPLOAD_CONTENT_SUB_CATEGORY,
} from "@/modules/Organization/dtos/organization.dto";
import UploadDocumentSuccessDialog from "@/modules/Organization/components/OrganizationModals/UploadDocumentSuccessDialog.vue";
import {
  SIDE_MENU_EVENTS,
  sideMenuBus,
} from "@/ag-portal-common/eventBusses/sideMenu";
import { Organization } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";

export default defineComponent({
  name: "UploadDocuments",
  components: {
    UploadDocumentSuccessDialog,
  },
  data(): {
    images?: Array<string>;
    selectedFiles: FileList | null;
    fieldError: string | null;
    isDialogOpen: boolean;
  } {
    return {
      images: [],
      selectedFiles: null,
      fieldError: null,
      isDialogOpen: false,
    };
  },

  methods: {
    async onSubmit(e: Event) {
      e.preventDefault();
      await this.onContentUploadHandler();
    },
    onFileChangeHandler(files: FileList) {
      this.selectedFiles = files;
    },
    handleCloseDialog() {
      this.isDialogOpen = false;
    },
    handleSuccess() {
      this.isDialogOpen = true;
      sideMenuBus.emit(SIDE_MENU_EVENTS.REFRESH, new Date());
    },
    async onContentUploadHandler() {
      if (this.selectedFiles?.length) {
        if (this.selectedFiles.length === 2) {
          if (!this.organization) {
            return;
          }

          const organizationId = this.organization.organization_id;

          this.fieldError = null;
          const payload: OrganizationContentUpload = {
            content: this.selectedFiles,
            content_category: UPLOAD_CONTENT_CATEGORY.DOCUMENTS,
            content_sub_category: UPLOAD_CONTENT_SUB_CATEGORY.CNIC,
          };

          const response = await this.$store.dispatch(
            "organizationDocumentsUpload",
            {
              payload,
              organizationId,
              successHandler: this.handleSuccess,
            }
          );

          this.images = response;
        } else {
          this.fieldError = "Please upload both sides of CNIC";
        }
      } else {
        this.fieldError = "Please upload your CNIC";
      }
    },
  },
  computed: {
    isDisabled(): boolean {
      return this.$store.getters.isDocumentUploadLoading;
    },
    isLoading(): boolean {
      return this.$store.getters.isDocumentUploadLoading;
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
  },
});
</script>

<style scoped lang="scss">
#packageImageUploader {
  transition: scale 0.2s ease-in-out;

  &:active {
    transform: scale(0.98);
  }
}
.submit-btn {
  float: right;
}

.form_container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
}
.form {
  min-width: 50%;
}

.error_msg {
  color: red;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .form {
    min-width: 90%;
  }
}
</style>
