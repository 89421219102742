import { MutationTree } from "vuex";
import { IFlightIssuanceState } from "@/ag-flight-components/interfaces/IFlightIssuance.interface";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { SalesUser } from "@/ag-flight-components/types";
import { IApplyFlightPromoCodeResponse } from "@/ag-portal-common/interfaces/flightIssuance.interface";

const mutations: MutationTree<IFlightIssuanceState> = {
  fetchingPNR(state, isFetching) {
    state.fetchingPNR = isFetching;
  },
  calculatingFareV3(state, payload) {
    state.calculatingFareV3 = payload;
  },
  isIssueTicketLoading(state, payload) {
    state.issueTicketLoading = payload;
  },
  fetchedPNRDetail(state, payload) {
    state.fetchingPNR = false;
    state.pnrDetail = payload;
  },
  fetchedPNRDetailV3(state, payload) {
    state.fetchingPNR = false;
    state.pnrDetailV3 = payload;
  },
  setCalculatedFareV3(state, payload) {
    state.calculatingFareV3 = false;
    state.calculatedFareV3 = payload;
  },
  resetCalculatedFare(state) {
    state.calculatedFareV3 = null;
    state.calculatingFareV3 = false;
  },
  resetPNRDetails(state) {
    state.pnrDetail = null;
    state.calculatedFareV3 = null;
    state.pnrDetailV3 = null;
    state.isTicketIssued = false;
  },
  setPnr(state, payload) {
    state.pnr = payload;
  },
  setFinancialProfilePublicId(state, payload) {
    state.financialProfilePublicId = payload;
  },
  isTicketIssued(state, payload) {
    state.isTicketIssued = payload;
  },
  setFinancialProfileLoading(state, payload: boolean) {
    state.isFinancialProfilesFetching = payload;
  },
  saveIssuanceFinancialProfiles(state, payload: IFinancialProfile[]) {
    state.issuanceFinancialProfiles = payload;
  },
  saveSalesUsersListForApproval(state, payload: SalesUser[]) {
    state.salesUsersList = payload;
  },
  setApplyFlightPromoLoading(state, payload: boolean) {
    state.isApplyPromoCodeLoading = payload;
  },
  setApplyFlightPromoResponse(state, payload: IApplyFlightPromoCodeResponse) {
    state.promoCodeResponse = payload;
  },
  removeFlightPromoResponse(state) {
    state.promoCodeResponse = null;
  },
};

export default mutations;
