<template>
  <MCard class="dashboard-card">
    <div class="icon-container">
      <MIcon class="icon" :name="icon" />
    </div>
    <div class="content">
      <MTypography type="label">{{ heading }}</MTypography>
      <MTypography type="headline">{{ number }}</MTypography>
    </div>
  </MCard>
</template>

<script>
import { MCard, MIcon, MTypography } from "@aeroglobe/ag-core-ui";
import { defineComponent } from "vue";
export default defineComponent({
  name: "DashboardCard",
  props: {
    heading: {
      type: String,
      default: "Main Metric",
    },
    number: {
      type: [Number, String],
      default: "0",
    },
    icon: {
      type: String,
      default: "m-organisations",
    },
  },
});
</script>

<style scoped lang="css">
.dashboard-card {
  display: flex;
  align-items: center;
}

.icon-container {
  margin-right: 16px;
}

.content {
  display: flex;
  flex-direction: column;
}
</style>
