import { PASSENGER_TYPE } from "@/ag-portal-common/enums/PASSENGER_TYPES";
import { differenceInMonths, differenceInYears } from "date-fns";
import * as yup from "yup";

export const addPassengerValidation = yup.object().shape({
  title: yup.string().nullable().required("Title is required"),
  first_name: yup
    .string()
    .nullable()
    .min(2, "Please enter a valid First Name")
    .required("First Name is required"),
  last_name: yup
    .string()
    .nullable()
    .min(2, "Please enter a valid Last Name")
    .required("Last Name is required"),
  dob: yup
    .date()
    .nullable()
    .test(
      "required",
      "Adult age must be greater than or equal to 12yrs",
      (value, ctx) => {
        if (ctx.parent.passenger_type === PASSENGER_TYPE.ADULT) {
          return differenceInYears(new Date(), value as Date) >= 12;
        }
        return true;
      }
    )
    .test(
      "required",
      "Child age must be greater than 2 and less than or equal to 11yrs",
      (value, ctx) => {
        if (ctx.parent.passenger_type === PASSENGER_TYPE.CHILD) {
          const ageInYears = differenceInYears(new Date(), value as Date);
          return ageInYears >= 2 && ageInYears <= 11;
        }
        return true;
      }
    )
    .test(
      "required",
      "Infant age must be less than or equal to 23 months",
      (value, ctx) => {
        if (ctx.parent.passenger_type === PASSENGER_TYPE.INFANT) {
          const ageInMonths = differenceInMonths(new Date(), value as Date);
          return ageInMonths <= 23 && ageInMonths >= 0;
        }
        return true;
      }
    ),
  gender: yup.string().nullable().required("Gender is required"),
  nationality: yup.string().nullable().required("Nationality is required"),
  cnic: yup
    .string()
    .nullable()
    .notRequired()
    .test("len", "CNIC must be numeric and 13 characters long.", (val) => {
      if (val?.length) {
        const unmaskedValue = val.replace(/-/g, "");
        return unmaskedValue.length === 13 && /^[0-9]+$/.test(unmaskedValue);
      }
      return false;
    }),
  passport_number: yup
    .string()
    .nullable()
    .notRequired()
    .test(
      "length",
      "Passport Number must be between 7 to 15 characters long",
      (val) => {
        if (!val) return true;
        const length = val.trim().length;
        return length >= 7 && length <= 15;
      }
    )
    .test(
      "format",
      "Passport Number can't contain special characters",
      (val) => {
        if (!val) return true;
        return /^[a-zA-Z0-9]+$/.test(val);
      }
    ),
  passport_expiry: yup.date().nullable().notRequired(),
});
