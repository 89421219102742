<template>
  <TourDetailShimmer v-if="isFetchingFDGBookingDetail" />
  <div
    v-else-if="!isFetchingFDGBookingDetail && fdgBookingDetail"
    class="flight-booking-form"
  >
    <MCard class="margin-bottom-20">
      <ag-row>
        <ag-column xs="12" sm="12" md="8" lg="9">
          <MCard class="group-flight-form-title-card">
            <MTypography type="headline" class="group-flight-title">{{
              formatGroupFlightTitle(flightItems)
            }}</MTypography>
            <div class="header-pax-info-container">
              <MIcon name="m-adult-icon" />
              <MTypography type="body">{{
                renderHeaderPassengerCount
              }}</MTypography>
            </div>
          </MCard>
        </ag-column>
        <ag-column xs="12" sm="12" md="3">
          <div class="booking-id-wrap">
            <MTypography class="booking-id-title" type="title">
              Booking Confirmation
            </MTypography>
            <MTypography type="headline">{{ bookingId }}</MTypography>
          </div>
        </ag-column>
      </ag-row>
    </MCard>
    <!-- Contact Details -->
    <AgRow>
      <AgColumn md="8" lg="9" cols="12">
        <!-- Lead Contact Box -->
        <ContactDetailsAccordion
          headline="Contact Details"
          title="Lead Traveler"
          :initialOpen="true"
          :externalOpen="contactAccordionOpen"
          ref="accordionContactInfo"
          icon-name="m-contact"
          :errorsLength="contactErrorsLength"
        >
          <div class="traveler-form-container">
            <div>
              <AgPhoneField
                test-id=""
                default-country-code="PK"
                info-message="Please input the traveler's number here to receive flight updates"
                :error="contactErrors.contact_number"
                @update-value="onParsePhoneNumberHandler"
              />
              <MTextfield
                v-model:inputValue="email"
                type="email"
                label="Email"
                placeholder="Enter your email"
                name="email"
                :hasError="!!contactErrors.email"
                :errorMessage="contactErrors.email"
                @blur="validateEmail"
                @input="onEmailInput"
              />
            </div>

            <ContactDetailsPreview
              title="Contact Details"
              :traveler="contactDetails"
              :previewFields="getPreviewFields()"
              :formatPreviewValue="formatPreviewValue"
              :errors="contactErrors"
            />
          </div>
        </ContactDetailsAccordion>

        <MCard
          v-if="!showTravelersForms"
          class="contact-details-button-container"
        >
          <MButton
            :disabled="isOTPLoading"
            @click="handleContinueWithoutDetails"
            >Continue without filling details</MButton
          >
          <MButton @click="showTravelersForms = true"
            >Fill Passenger's Details</MButton
          >
        </MCard>

        <template v-if="showTravelersForms">
          <TravelersComponent
            :travelers="travelers"
            @update:travelers="updateTravelers"
            @submit="handleTravelersSubmit"
            ref="travelersComponent"
          />
          <MCard class="confirm-bottom-btn-container">
            <MButton
              :disabled="isOTPLoading"
              @click="handleContinueWithoutDetails"
              >Continue without filling details</MButton
            >
            <MButton :disabled="isOTPLoading" @click="handleSubmit"
              >Confirm Booking</MButton
            >
          </MCard>
        </template>
      </AgColumn>
      <AgColumn md="4" lg="3" cols="12">
        <MCard class="margin-bottom-20">
          <MTypography type="headline" class="trip-summary-title"
            >Trip Summary</MTypography
          >
          <TripSummary :legs="flightlegs" />
        </MCard>
      </AgColumn>
    </AgRow>
  </div>
  <ResultNotFound
    v-else
    class="result-not-found result-not-found-margin"
    :title="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND"
  />
  <OTPInputModal
    :isOpen="showOtpModal"
    @close="showOtpModal = false"
    @submit="handleSubmitVerifyOtp"
    :isSubmitLoading="
      withoutPaxDetailsConfirmLoading || withPaxDetailsConfirmLoading
    "
    title="OTP Confirmation"
    instruction="Enter the 6-digit code sent to your email."
  >
    <template #component>
      <MFinancialProfileCombobox
        class="fp-combo"
        v-model:inputValue="financialProfileInput"
        label="Select Financial Profile"
        itemValue="value"
        itemLabel="label"
        :disabled="isFinancialProfileFetching"
        :options="financialProfileOptions"
        :hasError="!!fpErrorMessage"
        :errorMessage="fpErrorMessage"
      />
    </template>
  </OTPInputModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TravelerTypes } from "@/ag-flight-components/constants/TravelerTypes";
import {
  MCard,
  MTypography,
  MTextfield,
  MButton,
  MIcon,
  MFinancialProfileCombobox,
} from "@aeroglobe/ag-core-ui";
import TravelersComponent from "@/ag-flight-components/components/FlightBooking/NewTravelerFormComponent.vue";
import ContactDetailsAccordion from "@/ag-flight-components/components/FlightBooking/NewTravelerAccordion.vue";
import ContactDetailsPreview from "@/ag-flight-components/components/FlightBooking/NewTravelerPreview.vue";
import ResultNotFound from "@/components/ResultNotFound.vue";
import TourDetailShimmer from "../components/TourDetailShimmer.vue";

import { IFDGBookingDetail } from "@/interfaces/fdgBookingDetail.interface";
import { FlightLeg } from "@/ag-flight-components/types";
import { formatGroupFlightTitle } from "../utils";
import { IFDGItem } from "@/interfaces/fdg.interface";
import { PreviewField } from "@/ag-flight-components/interfaces/IPassengerBookingForm";
import { PhoneFieldValue } from "@/ag-portal-common/interfaces/profile.interface";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import TripSummary from "@/ag-flight-components/components/FlightBooking/GroupFlightTripSummary.vue";
import { FDGBookingConfirmWithoutTravelerDetailsRequestModel } from "../models/fdgBookingConfirmWithoutTravelerDetails.request";
import { PATH } from "@/ag-portal-common/constants/path";
import OTPInputModal from "@/ag-flight-components/components/OTPModal.vue";
import { FDGBookingConfirmRequestModel } from "../models/fdgBookingConfirm.request";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { FinancialProfile } from "@/interfaces/agentState.interface";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { ITraveler } from "@/ag-flight-components/interfaces/IGroupFlight.interface";

export default defineComponent({
  name: "BookingForm",
  components: {
    TravelersComponent,
    ContactDetailsAccordion,
    ContactDetailsPreview,
    MCard,
    MTypography,
    MTextfield,
    MButton,
    ResultNotFound,
    TourDetailShimmer,
    TripSummary,
    OTPInputModal,
    MFinancialProfileCombobox,
    MIcon,
  },
  data() {
    return {
      NOTIFICATION_MESSAGES,
      contact_number_obj: {} as PhoneFieldValue,
      contact_number: "",
      email: "",
      travelers: [] as ITraveler[],
      contactErrors: {
        contact_number: "",
        email: "",
      },
      contactAccordionOpen: true,
      isSubmitted: false,
      showTravelersForms: false,
      showOtpModal: false,
      isVerifying: false,
      financialProfileInput: "",
      fpErrorMessage: "",
    };
  },
  computed: {
    flightlegs(): FlightLeg[] {
      return this.$store.getters.flightLegItems;
    },
    flightItems(): IFDGItem[] {
      const fdgBookingDetail: IFDGBookingDetail =
        this.$store.getters.fdgBookingDetail;
      return fdgBookingDetail?.items || [];
    },
    isFetchingFDGBookingDetail(): boolean {
      return this.$store.getters.isFetchingFDGBookingDetail;
    },
    fdgBookingDetail(): IFDGBookingDetail {
      return this.$store.getters.fdgBookingDetail;
    },
    totalNumberOfPassengers(): number {
      return this.$store.getters.totalNumberofPassengers || 0;
    },
    contactDetails(): { email: string; contact_number: string } {
      return {
        email: this.email,
        contact_number: this.contact_number,
      };
    },
    bookingId(): string {
      return this.$route.params.id as string;
    },
    contactErrorsLength(): number {
      return Object.values(this.contactErrors).filter((error) => error).length;
    },
    isOTPLoading(): boolean {
      return this.$store.getters.isOTPLoading;
    },
    renderHeaderPassengerCount(): string {
      const paxText =
        this.totalNumberOfPassengers > 1 ? "Passenger's" : "Passenger";
      return `${this.totalNumberOfPassengers} ${paxText}`;
    },
    withoutPaxDetailsConfirmLoading(): boolean {
      return this.$store.getters.isConfirmFDGBookingWithoutTravelerDetails;
    },
    withPaxDetailsConfirmLoading(): boolean {
      return this.$store.getters.isConfirmFDGBooking;
    },
    otpReferenceId(): string {
      return this.$store.getters.groupFlightsOTPReference;
    },
    isFinancialProfileFetching(): boolean {
      return this.$store.getters.isFetchingFinancialProfiles;
    },
    financialProfiles(): FinancialProfile[] | null {
      return this.$store.getters[AUTH_GETTERS.FINANCIAL_PROFILES];
    },
    financialProfileOptions(): FPComboboxOptions[] {
      const financialProfiles =
        this.$store.getters[AUTH_GETTERS.FINANCIAL_PROFILES];
      return financialProfiles?.map((fp: IFinancialProfile) => {
        const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase();
        const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
        return {
          id: fp?.platform_id,
          label: fp?.financial_profile_name,
          value: fp?.public_id,
          isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
          status: fp?.status,
          sector: sector,
          type: planType,
        };
      });
    },
  },
  watch: {
    totalNumberOfPassengers: {
      handler(newVal: number) {
        if (newVal > 0) {
          this.initializeTravelers(newVal);
        }
      },
      immediate: true,
    },
    financialProfileInput: {
      handler(value: string) {
        if (value) {
          this.fpErrorMessage = "";
        }
      },
      immediate: true,
    },
  },
  methods: {
    async fetchBookingDetail() {
      await this.$store.dispatch(
        "fetchGroupFlightBookingDetail",
        this.$route.params.id
      );
    },
    handleSubmit() {
      this.isSubmitted = true;
      this.validateContactDetails();
      if (this.showTravelersForms) {
        const travelerComponent = this.$refs.travelersComponent as any;
        travelerComponent.handleSubmit();
        if (travelerComponent.isFormValid) {
          this.generateOTPForGroupFlightBooking();
        }
      }

      const isFormValid =
        this.contactErrorsLength === 0 &&
        (!this.showTravelersForms ||
          (this.$refs.travelersComponent as any).isFormValid);

      if (!isFormValid) {
        this.scrollToFirstError();
        return;
      }
    },
    handleContinueWithoutDetails() {
      this.isSubmitted = true;
      this.showTravelersForms = false;
      this.validateContactDetails();

      if (this.contactErrorsLength === 0) {
        this.generateOTPForGroupFlightBooking();
      } else {
        this.scrollToFirstError();
      }
    },
    handleSubmitVerifyOtp(otp: string) {
      this.fpErrorMessage = "";
      if (this.showTravelersForms) {
        if (this.financialProfileInput) {
          this.submitForm(otp);
        } else {
          this.fpErrorMessage = "Please select financial profile";
        }
      } else {
        this.submitFormWithoutTravelerDetails(otp);
      }
    },
    submitFormWithoutTravelerDetails(otp: string) {
      const payload: FDGBookingConfirmWithoutTravelerDetailsRequestModel = {
        booking_id: this.$route.params.id as string,
        contact_information: {
          contact_number: this.contact_number.replaceAll(" ", ""),
          contact_email: this.email,
        },
        no_of_passengers: this.totalNumberOfPassengers,
        otp: otp,
        otp_reference_id: this.otpReferenceId,
        financial_profile_public_id: this.financialProfileInput,
      };
      this.$store.dispatch("confirmGroupFlightBookingWithoutTravelerDetails", {
        payload: payload,
        callback: () => this.$router.push(PATH.FDG_FLIGHTS),
      });
    },
    async submitForm(otp: string) {
      let _formData = new FormData();

      const passengerDetails: any = this.travelers.map(
        (passenger: ITraveler) => ({
          salutation: passenger.title,
          first_name: passenger.firstName,
          last_name: passenger.lastName,
          passenger_type: TravelerTypes.ADULT,
          nationality: passenger.nationality,
          passport_no: passenger.passportNumber,
          gender: passenger.gender,
          passport_expiry:
            this.formattedDate(passenger.passportExpiry as Date) || null,
          dob: this.formattedDate(passenger.dateOfBirth as Date) || null,
          passenger_relation: "LEAD",
          documents: [],
        })
      );

      let payload: FDGBookingConfirmRequestModel = {
        booking_id: this.bookingId,
        contact_information: {
          contact_number: this.contact_number.replaceAll(" ", ""),
          contact_email: this.email,
        },
        passenger_details: passengerDetails,
        otp: otp,
        otp_reference_id: this.otpReferenceId,
        financial_profile_public_id: this.financialProfileInput,
      };

      _formData.append("json_data", JSON.stringify(payload));
      this.$store.dispatch("confirmGroupFlightBooking", {
        payload: _formData,
        callback: () => this.$router.push(PATH.FDG_FLIGHTS),
      });
    },
    async generateOTPForGroupFlightBooking() {
      const booking_id = this.$route.params.id;
      await this.$store.dispatch("generateGroupFlightBookingOTP", {
        booking_id,
        callback: () => (this.showOtpModal = true),
      });
    },
    validateContactDetails() {
      this.validateEmail();
      this.validatePhoneNumber();
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.email) {
        this.contactErrors.email = "Email is required";
      } else if (!emailRegex.test(this.email)) {
        this.contactErrors.email = "Invalid email format";
      } else {
        this.contactErrors.email = "";
      }
    },
    onEmailInput() {
      if (this.contactErrors.email) {
        this.validateEmail();
      }
    },
    validatePhoneNumber() {
      if (!this.contact_number_obj.e164) {
        this.contactErrors.contact_number = "Contact number is required";
      } else if (!this.contact_number_obj.isValid) {
        this.contactErrors.contact_number = "Invalid contact number";
      } else {
        this.contactErrors.contact_number = "";
      }
    },
    scrollToFirstError() {
      if (this.contactErrorsLength > 0) {
        this.contactAccordionOpen = true;
        this.$nextTick(() => {
          const accordionElement = this.$refs.accordionContactInfo as any;
          if (accordionElement && accordionElement.$el) {
            const firstErrorElement =
              accordionElement.$el.querySelector(".has-error");
            if (firstErrorElement) {
              firstErrorElement.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          }
        });
      } else if (this.showTravelersForms) {
        (this.$refs.travelersComponent as any).navigateToFirstError();
      }
    },
    updateTravelers(updatedTravelers: ITraveler[]) {
      this.travelers = updatedTravelers;
    },
    handleTravelersSubmit(submittedTravelers: ITraveler[]) {
      this.travelers = submittedTravelers;
    },
    initializeTravelers(passengerCount: number) {
      const defaultTraveler: ITraveler = {
        title: "",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        gender: "",
        passportNumber: "",
        passportExpiry: "",
        isInternational: true,
        passengerType: TravelerTypes.ADULT,
        nationality: "PK",
        idNumber: "",
      };

      this.travelers = Array(passengerCount)
        .fill(null)
        .map(() => ({ ...defaultTraveler }));
    },
    getPreviewFields(): PreviewField[] {
      return [
        { key: "contact_number", label: "Contact Number" },
        { key: "email", label: "Email" },
      ];
    },
    formatPreviewValue(key: string, value: any): string {
      return value;
    },
    renderMobileNumberError(): string {
      return this.contactErrors.contact_number;
    },
    onParsePhoneNumberHandler(value: PhoneFieldValue) {
      this.contact_number_obj = value;
      this.contact_number = value.formatInternational || "";
      if (this.isSubmitted) {
        this.validatePhoneNumber();
      }
    },
    formattedDate(date: Date | string | null) {
      if (date) {
        return getFormattedDateTime(new Date(date), FORMAT_YYY_MM_DD);
      }
      return "";
    },
    formatGroupFlightTitle,
  },
  async created() {
    await this.fetchBookingDetail();
  },
  async mounted() {
    let defaultFinancialProfile = this.financialProfiles?.find(
      (item) => item.is_default
    );

    if (defaultFinancialProfile) {
      this.financialProfileInput = defaultFinancialProfile.public_id;
    }
  },
});
</script>

<style scoped lang="scss">
.margin-bottom-20 {
  margin-bottom: 20px;
}
.traveler-form-container {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  align-items: flex-start;
}
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.contact-details-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}
.booking-id-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  .booking-id-title {
    color: var(--green-color);
  }
}

.trip-summary-title {
  text-align: center;
  margin-bottom: 20px;
}

.confirm-bottom-btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.header-pax-info-container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .traveler-form-container,
  .button-container {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .confirm-bottom-btn-container {
    display: flex;
    justify-content: center;
  }
  .contact-details-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 10px;
  }
}
.group-flight-form-title-card {
  padding: 0 !important;
  .group-flight-title {
    font-weight: 900;
  }
}
</style>
