<template>
  <MCard>
    <AgIconInfoBar
      test-id=""
      title="Create Organization"
      class="section-title"
      mPrepandIcon="m-organisations"
    />
  </MCard>
  <ShimmerCard v-if="isLoading" cardHeight="400px" />
  <div v-else>
    <OrganizationForm
      @onSubmitOrganizationForm="createOrganization"
      :onLoading="$store.getters.isCreatingOrganization"
      :isCreateOrganization="true"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OrganizationForm from "@/modules/Organization/components/OrganizationForm.vue";
import { CreateOrganizationDto } from "@/modules/Organization/dtos/organization.dto";
import { PATH } from "@/ag-portal-common/constants/path";
import ShimmerCard from "@/components/ShimmerCard.vue";

export default defineComponent({
  name: "CreateOrganization",
  components: { OrganizationForm, ShimmerCard },
  beforeMount() {
    this.$store.dispatch("fetchOrganizationCreateFormDetails");
  },
  methods: {
    createOrganization(payload: CreateOrganizationDto) {
      this.$store.dispatch("createOrganization", {
        body: payload,
        callback: this.routeToOrganizationDetail,
      });
    },
    routeToOrganizationDetail(id: string) {
      this.$router.push(`${PATH.ORGANIZATION}/${id}`);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.isFetchingOrganization;
    },
  },
});
</script>
<style lang="css" scoped>
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
</style>
