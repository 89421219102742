import { MutationTree } from "vuex";
import { ISOAState } from "@/ag-portal-common/interfaces/soaState.interface";

const mutations: MutationTree<ISOAState> = {
  enableDownloadingSOA(state) {
    state.isDownloadingSOA = true;
  },
  disableDownloadingSOA(state) {
    state.isDownloadingSOA = false;
  },
  enableSendingSOA(state) {
    state.isSendingSOA = true;
  },
  disableSendingSOA(state) {
    state.isSendingSOA = false;
  },
  setPendingData(
    state,
    { invoices, receipts, hotelInvoices, groupFlightInvoices }
  ) {
    state.pendingInvoicesAndReceipts.flightInvoices = invoices;
    state.pendingInvoicesAndReceipts.flightReceipts = receipts;
    state.pendingInvoicesAndReceipts.hotelInvoices = hotelInvoices;
    state.pendingInvoicesAndReceipts.groupFlightInvoices = groupFlightInvoices;
  },
};

export default mutations;
