<template>
  <AgCard class="flightFilterWrap">
    <AgDiv class="head"> Filter </AgDiv>
    <AgDiv class="d-flex">
      <AgFilterDropdown label="Currency">
        <template #Items>
          <AgCheckbox
            @click="(e:MouseEvent) => e.stopPropagation()"
            v-for="currency in uniqueCurrencies"
            :key="currency"
            :value="currency"
            :label="currency"
            v-model="selectedCurrencies"
          ></AgCheckbox>
        </template>
      </AgFilterDropdown>
      <AgFilterDropdown label="Airlines">
        <template #Items>
          <AgCheckbox
            v-for="airlines in uniqueAirlines"
            :key="airlines"
            :value="airlines"
            :label="airlines"
            v-model="selectedAirlines"
          ></AgCheckbox>
        </template>
      </AgFilterDropdown>
      <AgFilterDropdown label="Departure City">
        <template #Items>
          <AgCheckbox
            v-for="city in uniqueCities"
            :key="city"
            :value="city"
            :label="city"
            v-model="selectedCities"
          ></AgCheckbox>
        </template>
      </AgFilterDropdown>
    </AgDiv>
  </AgCard>

  <TourListShimmer v-if="isFetchingFDGList" />

  <MCard
    v-else-if="!isFetchingFDGList && filteredFDGlist().length"
    class="group-flight-item"
    v-for="fdg in filteredFDGlist()"
    :key="fdg.id"
  >
    <div class="date-title">{{ formatGroupFlightTitle(fdg.items) }}</div>
    <div class="group-flight-item-detail-container">
      <div class="airline-itinerary-container">
        <div
          :class="[
            'airline-itinerary',
            { dashedBorder: idx + 1 < fdg.items.length },
          ]"
          v-for="(item, idx) in fdg.items"
          :key="idx"
        >
          <MIcon
            class="group-flight-item-departure-icon"
            name="m-flight-takeoff"
          />
          <AgFlightDetailCard
            test-id=""
            :airline-logo="item.flightItemDetails?.segment[0]?.airline?.logo"
            :airline-code="item.flightItemDetails?.segment[0]?.flightNumber"
            :duration="legDuration(item.flightItemDetails!.flightDuration)"
            :from-destination="item.flightItemDetails!.originIATACode"
            :from-time="formatTime(item.flightItemDetails!.departureDatetime as Date)"
            :to-time="formatTime(item.flightItemDetails?.arrivalDatetime as Date)"
            :to-destination="item.flightItemDetails!.destinationIATACode"
            :airline-name="item.flightItemDetails?.segment[0]?.airline?.name"
            fare-info="true"
          />
        </div>
      </div>
      <div class="action-btn-container">
        <div>
          <div class="seat-available-label">
            {{ remainingSeats(fdg.bookingInfo.remainingQuantity) }}
          </div>
          <div class="seat-fare">
            {{
              formatCurrency(fdg.bookingInfo.price, fdg.bookingInfo.currency)
            }}
            <span class="per-pax-text">Per Passenger</span>
          </div>
        </div>
        <router-link :to="navigateToDetailPage(fdg.id)">
          <MButton>Reserve Now</MButton>
        </router-link>
      </div>
    </div>
  </MCard>

  <ResultNotFound
    v-else
    class="result-not-found result-not-found-margin"
    :title="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IFDG, IFDGItem } from "@/interfaces/fdg.interface";
import { IFDGBookingInfo } from "@/interfaces/fdg.interface";
import {
  formatNumber,
  formatStringToRoutePath,
} from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";
import { MCard, MIcon, MButton } from "@aeroglobe/ag-core-ui";
import { format } from "date-fns";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";
import { formatCurrency } from "@/ag-flight-components/utils";
import TourListShimmer from "../components/TourListShimmer.vue";
import ResultNotFound from "@/components/ResultNotFound.vue";
import { formatGroupFlightTitle } from "../utils";

export default defineComponent({
  name: "ToursList",
  components: {
    MCard,
    MIcon,
    MButton,
    TourListShimmer,
    ResultNotFound,
  },
  props: {
    tourType: {
      type: String as PropType<TOUR_TYPES>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedCurrencies: [] as string[],
      selectedAirlines: [] as string[],
      selectedCities: [] as string[],
      selectedMonths: [] as string[],
      newLoad: true,
    };
  },
  methods: {
    navigateToDetailPage(id: string) {
      let routePath = PATH.FDG_FLIGHT_DETAIL;
      return formatStringToRoutePath(routePath, { id });
    },
    filteredFDGlist(): IFDG[] {
      let filteredList = this.fdgList || [];
      if (this.newLoad === true) {
        this.selectedCurrencies = this.uniqueCurrencies;

        this.selectedAirlines = this.uniqueAirlines;

        this.selectedCities = this.uniqueCities;

        this.selectedMonths = this.uniqueMonths;
      }

      this.newLoad = false;

      filteredList = filteredList.filter((fdg) =>
        this.selectedCurrencies.includes(fdg.bookingInfo.currency)
      );

      filteredList = filteredList.filter((fdg) =>
        this.selectedAirlines.includes(
          fdg?.items[0]?.flightItemDetails?.segment[0].airline.name || ""
        )
      );

      filteredList = filteredList.filter((fdg) =>
        this.selectedCities.includes(
          fdg?.items[0]?.flightItemDetails?.originCity || ""
        )
      );

      filteredList = filteredList.filter((fdg) =>
        this.selectedMonths.includes(
          fdg.items[0]?.flightItemDetails?.segment[0].departureDatetime?.toLocaleString(
            "default",
            {
              month: "long",
              year: "numeric",
            }
          ) || ""
        )
      );

      return filteredList;
    },
    formatNumber,
    formatCurrency,
    formatGroupFlightTitle,
    legDuration(durationInNumber: number): string {
      const duration = TimeUtility.getDurationInTextByMinutes(durationInNumber);
      return duration;
    },
    formatTime: (time: Date) => {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    remainingSeats(counts: number) {
      if (counts > 0) {
        return `${counts} Seats Available`;
      }
      return "Sold out";
    },
  },

  beforeMount() {
    this.$store.dispatch("fetchGroupFlights", this.TOUR_TYPES.FLIGHTS_ONLY);
  },
  computed: {
    TOUR_TYPES() {
      return TOUR_TYPES;
    },
    NOTIFICATION_MESSAGES() {
      return NOTIFICATION_MESSAGES;
    },
    fdgList(): IFDG[] {
      return this.$store.getters.fdgList;
    },
    fdgBookingDetail(): IFDGBookingInfo {
      return this.$store.getters.fdgBookingDetail;
    },
    isFetchingFDGList(): boolean {
      return this.$store.getters.isFetchingFDGList;
    },

    uniqueCurrencies(): string[] {
      const currencies = this.fdgList.map((fdg) => fdg.bookingInfo.currency);
      return [...new Set(currencies)];
    },
    uniqueAirlines(): string[] {
      const airlines = this.fdgList.map(
        (fdg: IFDG) =>
          fdg.items[0].flightItemDetails?.segment[0].airline.name || ""
      );
      return [...new Set(airlines)];
    },
    uniqueCities(): string[] {
      const cities = this.fdgList.map(
        (fdg: IFDG) => fdg.items[0].flightItemDetails?.originCity || ""
      );
      return [...new Set(cities)];
    },
    uniqueMonths(): string[] {
      const today = new Date();
      const sixMonthsLater = new Date();
      sixMonthsLater.setMonth(today.getMonth() + 6);
      const cities = this.fdgList.map(
        (fdg: IFDG) => fdg.items[0].flightItemDetails?.departureDatetime || ""
      );

      const uniqueMonths: Set<string> = new Set();

      while (today <= sixMonthsLater) {
        const monthYearString = `${today.toLocaleString("default", {
          month: "long",
          year: "numeric",
        })}`;
        uniqueMonths.add(monthYearString);
        today.setMonth(today.getMonth() + 1);
      }

      return Array.from(uniqueMonths);
    },
  },
});
</script>

<style lang="scss" scoped>
.date-title {
  margin-bottom: 10px;
  margin-left: 3px;
  position: absolute;
  top: 0px;
  left: -3px;
  padding: 6px 14px;
  background: var(--green-color);
  color: #fff;
  font-weight: 900;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 20px;
  font-size: 18px;
}

.group-flight-item {
  position: relative;
  margin-top: 20px;
}

.group-flight-item-detail-container {
  display: flex;
  align-items: center;
  .airline-itinerary-container {
    flex-basis: 75%;
    margin-top: 50px;
    .airline-itinerary {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .dashedBorder {
      border-bottom: dashed 1px lightgray;
      margin-bottom: 15px;
    }
  }
  .action-btn-container {
    flex-basis: 25%;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 35px;
    .seat-available-label {
      background: var(--m-secondary-color);
      border-radius: 14px;
      padding: 4px 10px;
      width: max-content;
      color: #fff;
      font-weight: bold;
    }
    .seat-fare {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      margin-top: 10px;
      .per-pax-text {
        display: block;
        font-size: 12px;
        text-align: center;
        color: gray;
        line-height: 12px;
      }
    }
  }
}
.result-not-found-margin {
  margin-top: 70px;
}
</style>
<style lang="scss">
.group-flight-item-departure-icon {
  margin-right: 20px;
  svg {
    width: 35px;
    height: 35px;
    path {
      fill: var(--green-color);
    }
  }
}
</style>
