import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CancellationDialogBody = _resolveComponent("CancellationDialogBody")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MDialog = _resolveComponent("MDialog")!
  const _component_OTPInputModal = _resolveComponent("OTPInputModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MDialog, {
      persistent: "",
      modelValue: _ctx.showCancellation,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showCancellation) = $event)),
      onClose: _ctx.handleCloseIssuanceDialog,
      title: "Hotel Issuance"
    }, {
      content: _withCtx(() => [
        (_ctx.showCancellation)
          ? (_openBlock(), _createBlock(_component_CancellationDialogBody, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_MButton, {
          onClick: _ctx.handleCloseIssuanceDialog,
          type: "outlined"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Cancel ")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_MButton, { onClick: _ctx.handleContinue }, {
          default: _withCtx(() => [
            _createTextVNode(" Continue ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["modelValue", "onClose"]),
    _createVNode(_component_OTPInputModal, {
      isOpen: _ctx.showOtp,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showOtp = false)),
      onSubmit: _ctx.onHotelIssuance,
      isSubmitLoading: _ctx.isLoadingOTPVerificationSubmit,
      title: "OTP Confirmation",
      instruction: "Enter the 6-digit code sent to your email."
    }, null, 8, ["isOpen", "onSubmit", "isSubmitLoading"])
  ], 64))
}