<template>
  <MCard class="credit-limit-heading">
    <AgIconInfoBar
      test-id=""
      title="Credit Limit Management"
      class="section-title"
      mPrepandIcon="m-credit-limit-management"
    />
  </MCard>
  <MCard>
    <AgTabs test-id="test" :panel-value="[1]" class="no_tab_icon">
      <template #TabHeading>
        <v-tab
          v-for="(status, index) in Object.keys(CREDIT_LIMIT_REQUEST_STATUSES)"
          v-bind:key="index"
          :value="index"
          @click="onClickTab(status as CreditLimitRequestStatusKey)"
        >
          <span>
            {{ formatWordIntoCapitalize(status.replaceAll("_", " ")) }}
          </span>
        </v-tab>
      </template>
      <template #TabDetail>
        <div>
          <MDataTable
            :is-loading="isFetchingCreditLimitRequests"
            :headers="creditLimitHeaders"
            :data="creditLimitRequests.data"
            :item-per-page="30"
            :is-api-paginated="true"
            @onPageChange="onPageChange"
            :total-item-count="creditLimitRequests.count"
          >
            <template #requestInitiatedAt="{ item }">
              <div class="limit-date">
                <MTypography
                  class="date-class"
                  type="body"
                  v-if="item.requestInitiatedAt"
                >
                  {{
                    getFormattedDateTime(
                      item.requestInitiatedAt,
                      FORMAT_DD_MMM_YYYY_WITH_DASH
                    )
                  }}
                </MTypography>
                <MTypography
                  class="description"
                  type="label"
                  v-if="item.requestInitiatedAt"
                >
                  {{
                    getFormattedDateTime(
                      item.requestInitiatedAt,
                      FORMAT_HH_SS_24_HOURS
                    )
                  }}
                </MTypography>
              </div>
              <p class="margin_bottom_0" v-if="!item.requestInitiatedAt">N/A</p>
            </template>
            <template #organizationName="{ item }">
              <router-link
                v-if="!isSuperUser"
                :to="
                  formatStringToRoutePath(PATH.CREDIT_LIMIT_REQUEST_DETAIL, {
                    id: item.id,
                  })
                "
              >
                <MTypography class="description td-full-width" type="body">
                  {{ item.organizationName }}
                </MTypography>
              </router-link>
              <div v-else>
                <MTypography class="description td-full-width" type="label">
                  {{ item.organizationName }}
                </MTypography>
              </div>
            </template>
            <template #agentSectorName="{ item }">
              <MTypography class="td-full-width description" type="label">
                {{ item.agentSectorName }}
              </MTypography>
            </template>
            <template #limitExpiresAt="{ item }">
              <div class="limit-date" v-if="item.limitExpiresAt">
                <MTypography class="description" type="body">
                  {{
                    getFormattedDateTime(
                      item.limitExpiresAt,
                      FORMAT_DD_MMM_YYYY_WITH_DASH
                    )
                  }}
                </MTypography>
                <MTypography class="description" type="label">
                  {{
                    getFormattedDateTime(
                      item.limitExpiresAt,
                      FORMAT_HH_SS_24_HOURS
                    )
                  }}
                </MTypography>
              </div>
              <p class="margin_bottom_0" v-else>-</p>
            </template>
            <template #requestNotes="{ item }">
              <p v-if="!item.requestNotes">-</p>
              <MTypography
                v-else
                class="td-full-width description"
                type="label"
              >
                {{ item.requestNotes }}
              </MTypography>
            </template>
            <template #priority="{ item }">
              <div class="mchip-alignment">
                <MChip
                  :variant="creditLimitPriorityclass(item.priority)"
                  class="m-chip-credit-limit"
                >
                  {{ item.priority }}
                </MChip>
              </div>
            </template>
            <template #financialProfileName="{ item }">
              <MTypography
                class="description financial_profile_name"
                type="body"
              >
                {{ item.financialProfileName }}
              </MTypography>
            </template>
            <template #requestedAmount="{ item }">
              <MTypography class="td-full-width description" type="label">
                {{ formatNumber(item.requestedAmount) }}
              </MTypography>
            </template>
          </MDataTable>
        </div>
      </template>
    </AgTabs>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  CREDIT_LIMIT_REQUEST_STATUSES,
  CreditLimitRequestStatusKey,
} from "@/ag-portal-common/enums/CREDIT_LIMIT_REQUEST_STATUSES";
import {
  formatNumber,
  formatStringToRoutePath,
  formatWordIntoCapitalize,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { IPaginatedCreditLimitRequests } from "@/interfaces/creditLimitRequest.interface";
import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_HH_SS_24_HOURS,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { PATH } from "@/ag-portal-common/constants/path";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import UTILS from "@/ag-portal-common/utils";
import { CREDIT_PRIORITIES } from "../constants";
import { MCard, MDataTable, MTypography } from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";

export default defineComponent({
  name: "ListViewForSalesUser",
  components: {
    MCard,
    MDataTable,
    MTypography,
  },
  data() {
    return {
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
      FORMAT_HH_SS_24_HOURS: FORMAT_HH_SS_24_HOURS,
      itemsPerPage: 30,
      page: 1,
      activeTab: CREDIT_LIMIT_REQUEST_STATUSES.PENDING,
      creditLimitHeaders: [
        {
          key: "requestInitiatedAt",
          title: "Request Initiated",
          value: "requestInitiatedAt",
        },
        {
          key: "organizationName",
          title: "Organization",
          value: "organizationName",
        },
        {
          key: "requestedAmount",
          title: "Requested Amount",
          value: "requestedAmount",
        },
        {
          key: "priority",
          title: "Priority",
          value: "priority",
        },
        {
          key: "limitExpiresAt",
          title: "Limit Expiry",
          value: "limitExpiresAt",
        },
        {
          key: "agentEmail",
          title: "Requested By",
          value: "agentEmail",
        },
        {
          key: "financialProfileName",
          title: "Financial Profile Name",
          value: "financialProfileName",
        },
        {
          key: "agentSectorName",
          title: "Sector",
          value: "agentSectorName",
        },
        {
          key: "requestNotes",
          title: "Request Notes",
          value: "requestNotes",
        },
        {
          key: "requestedDays",
          title: "Requested Days",
          value: "requestedDays",
        },
      ] as MDataTableHeader[],
    };
  },
  methods: {
    formatWordIntoCapitalize,
    formatNumber,
    getFormattedDateTime,
    formatStringToRoutePath,
    onItemClick(id: string) {
      if (this.isSuperUser) {
        this.$router.push(
          formatStringToRoutePath(PATH.CREDIT_LIMIT_REQUEST_DETAIL, {
            id: id,
          })
        );
      }
    },
    setItemsPerPage() {
      let requestsLength = this.$store.getters.creditLimitRequests.data.length;
      this.itemsPerPage = requestsLength;
    },
    fetchCreditLimitRequests(callback?: () => void) {
      let params = {
        status: this.activeTab,
        page: this.page,
      };
      let payload = {
        params,
        callback,
      };

      this.$store.dispatch("fetchCreditLimitRequests", payload);
    },
    onPageChange(num: number) {
      let nextPage = this.$store.getters.creditLimitRequests.next;
      let prevPage = this.$store.getters.creditLimitRequests.previous;
      let currentPage: number = this.page || 1;
      if ((num > currentPage && nextPage) || (currentPage > num && prevPage)) {
        this.page = num;
        this.fetchCreditLimitRequests();
      }
    },
    onClickTab(status: CreditLimitRequestStatusKey) {
      this.activeTab = CREDIT_LIMIT_REQUEST_STATUSES[status];
    },
    creditLimitPriorityclass(status: CREDIT_PRIORITIES) {
      switch (status) {
        case "LOW":
          return "warning";
        case "NORMAL":
          return "info";
        case "URGENT":
          return "error";
        default:
          return "success";
      }
    },
  },
  computed: {
    PATH(): typeof PATH {
      return PATH;
    },
    NOTIFICATION_MESSAGES(): typeof NOTIFICATION_MESSAGES {
      return NOTIFICATION_MESSAGES;
    },
    CREDIT_LIMIT_REQUEST_STATUSES(): typeof CREDIT_LIMIT_REQUEST_STATUSES {
      return CREDIT_LIMIT_REQUEST_STATUSES;
    },
    creditLimitRequests(): IPaginatedCreditLimitRequests {
      return this.$store.getters.creditLimitRequests;
    },
    isFetchingCreditLimitRequests(): boolean {
      return this.$store.getters.isFetchingCreditLimitRequests;
    },
    isSuperUser(): boolean {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER);
    },
  },
  watch: {
    activeTab() {
      this.page = 1;
      this.fetchCreditLimitRequests(this.setItemsPerPage);
    },
  },
  beforeMount() {
    this.fetchCreditLimitRequests(this.setItemsPerPage);
  },
});
</script>

<style lang="css" scoped>
.date-class {
  min-width: max-content;
}
.notes {
  max-width: fit-content;
}

.m-chip-credit-limit {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  max-width: max-content;
}
.credit-limit-heading {
  margin-bottom: 10px;
}
.organization-name {
  color: var(--green-color);
}
.sector-name {
  min-width: 200px;
}
.limit-date {
  min-width: max-content;
}

.financial_profile_name {
  min-width: 200px;
}
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
</style>
