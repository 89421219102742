import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30b3d8b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "sabre-segment-data-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MProgress = _resolveComponent("MProgress")!
  const _component_MFinancialProfileCombobox = _resolveComponent("MFinancialProfileCombobox")!
  const _component_MDatePicker = _resolveComponent("MDatePicker")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_MAccordion = _resolveComponent("MAccordion")!
  const _component_MChip = _resolveComponent("MChip")!

  return (_ctx.isFetchingOrganizations)
    ? (_openBlock(), _createBlock(_component_MProgress, {
        key: 0,
        indeterminate: "",
        class: "mprogress-alignment"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MCard, { class: "m-card" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              (_ctx.isAllowedUser())
                ? (_openBlock(), _createBlock(_component_MFinancialProfileCombobox, {
                    key: 0,
                    inputValue: _ctx.organizationId,
                    "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.organizationId) = $event)),
                    label: "Organization",
                    placeholder: "Select Organization",
                    itemValue: "value",
                    itemLabel: "label",
                    hasError: null,
                    errorMessage: null,
                    disabled: _ctx.isFetchingOrganizations,
                    options: _ctx.organizations
                  }, null, 8, ["inputValue", "disabled", "options"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_MDatePicker, {
                  label: "Start Date",
                  "min-date": _ctx.minStartDate,
                  maxDate: _ctx.maxDate,
                  onOnRangeDateChange: _ctx.changeStartDate,
                  onRangeStartDate: _ctx.minStartDate,
                  placeholder: _ctx.startDate,
                  dateType: "startDate",
                  multiCalendar: false,
                  hasRange: false,
                  disabled: _ctx.isFetchingOrganizations,
                  class: "sabre-segment-date-picker"
                }, null, 8, ["min-date", "maxDate", "onOnRangeDateChange", "onRangeStartDate", "placeholder", "disabled"]),
                _createVNode(_component_MDatePicker, {
                  label: "End Date",
                  "min-date": _ctx.minStartDate,
                  maxDate: _ctx.maxDate,
                  onOnRangeDateChange: _ctx.changeEndDate,
                  onRangeStartDate: _ctx.minStartDate,
                  placeholder: _ctx.endDate,
                  dateType: "startDate",
                  multiCalendar: false,
                  hasRange: false,
                  disabled: _ctx.isFetchingOrganizations,
                  class: "sabre-segment-date-picker"
                }, null, 8, ["min-date", "maxDate", "onOnRangeDateChange", "onRangeStartDate", "placeholder", "disabled"]),
                _createVNode(_component_MButton, {
                  onClick: _ctx.fetchBookings,
                  disabled: _ctx.isFetchingOrganizations || !_ctx.canFetch() || _ctx.isLoading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isFetchingOrganizations && _ctx.isAllowedUser() ? "Loading" : "Search"), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_MAccordion, { "initial-open": true }, {
          title: _withCtx(() => [
            _createVNode(_component_AgIconInfoBar, {
              "test-id": "",
              mPrepandIcon: "m-sabre-segment-stats",
              title: "Sabre Segment Stats",
              class: "accordion-title"
            })
          ]),
          content: _withCtx(() => [
            (_ctx.statsDetails)
              ? (_openBlock(), _createBlock(_component_MDataTable, {
                  key: 0,
                  headers: _ctx.statsTableHeader,
                  data: [_ctx.statsDetails],
                  "is-api-paginated": false,
                  "item-per-page": 1,
                  "has-search": false,
                  class: "item"
                }, {
                  start_date: _withCtx(({ item }) => [
                    _createVNode(_component_MTypography, {
                      class: "description td-full-width",
                      type: "label"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formattedDate(item.start_date)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  end_date: _withCtx(({ item }) => [
                    _createVNode(_component_MTypography, {
                      class: "description td-full-width",
                      type: "label"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formattedDate(item.end_date)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["headers", "data"]))
              : (_openBlock(), _createBlock(_component_AgNotFound, {
                  key: 1,
                  "test-id": "",
                  heading: "No Sabre Segment Stats found in the following date range",
                  description: ""
                }))
          ]),
          _: 1
        }),
        _createVNode(_component_MAccordion, { "initial-open": true }, {
          title: _withCtx(() => [
            _createVNode(_component_AgIconInfoBar, {
              "test-id": "",
              mPrepandIcon: "m-sabre-segment-list",
              title: "Sabre Segment List",
              class: "accordion-title"
            })
          ]),
          content: _withCtx(() => [
            (_ctx.listDetails.length)
              ? (_openBlock(), _createBlock(_component_MDataTable, {
                  key: 0,
                  headers: _ctx.listTableHeader,
                  data: _ctx.listDetails,
                  "is-api-paginated": false,
                  "item-per-page": 20,
                  "has-search": false,
                  class: "item"
                }, {
                  created_date: _withCtx(({ item }) => [
                    _createVNode(_component_MTypography, {
                      class: "description td-full-width",
                      type: "label"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formattedDate(item.created_date)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  departure_datetime: _withCtx(({ item }) => [
                    _createVNode(_component_MTypography, {
                      class: "description td-full-width",
                      type: "label"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formattedDate(item.departure_datetime)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  status: _withCtx(({ item }) => [
                    _createVNode(_component_MChip, {
                      class: "item",
                      variant: "success"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.status), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["headers", "data"]))
              : (_openBlock(), _createBlock(_component_AgNotFound, {
                  key: 1,
                  "test-id": "",
                  heading: "No Sabre Segment List found in the following date range",
                  description: ""
                }))
          ]),
          _: 1
        })
      ]))
}