import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { IPayment, IPayments } from "@/interfaces/payments.interface";
import { PaymentsResponseModel } from "@/modules/Payments/models/payments.response";
import { PaymentsMapper } from "@/modules/Payments/responseMappers/payments.mapper";
import { payGuideUrl, PAYMENTS_API_PATH } from "@/modules/Payments/constants";
import { FetchPaymentsParamsRequestModel } from "@/modules/Payments/models/fetchPaymentsParams.request";
import { CreatePaymentBodyRequest } from "@/modules/Payments/models/createPaymentBody.request";
import { PaymentDetailResponseModel } from "@/modules/Payments/models/paymentDetail.response";
import { formatString } from "@/ag-portal-common/utils/helpers";
import { NOTIFICATION_STYLES } from "@/ag-portal-common/enums/NOTIFICATION_STYLES";
import { PAYMENT_METHODS_TYPES } from "@/ag-portal-common/enums/PAYMENT_METHODS_TYPES";
import { StartDateEndDateDTO } from "@/modules/Organization/dtos/organization.dto";

class PaymentsService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public async fetchPayments(
    params: FetchPaymentsParamsRequestModel
  ): Promise<IPayments> {
    const methodName = "PaymentsService.fetchPayments";
    let mappedPayments: IPayments = {
      totalRecords: 0,
      hasNext: false,
      pageNumber: 0,
      pageSize: 0,
      totalPages: 0,
      payments: [],
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<PaymentsResponseModel> =
        await this.restClient.get(PAYMENTS_API_PATH.GET, params);

      if (response.success && response.data) {
        const paymentsMapper = new PaymentsMapper();
        mappedPayments = paymentsMapper.mapPaymentsResponse(response.data);
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    return mappedPayments;
  }

  public async fetchPayment(id: string): Promise<IPayment | null> {
    const methodName = "PaymentsService.fetchPayment";
    let mappedPayment: IPayment | null = null;
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<PaymentDetailResponseModel> =
        await this.restClient.get(
          formatString(PAYMENTS_API_PATH.GET_BY_ID, id)
        );

      if (response.success && response.data) {
        const paymentsMapper = new PaymentsMapper();
        mappedPayment = paymentsMapper.mapPaymentResponse(response.data);
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    return mappedPayment;
  }

  public async createPayment(
    body: CreatePaymentBodyRequest,
    callback: (id: string) => void
  ): Promise<IPayment | null> {
    const methodName = "PaymentsService.createPayment";
    let mappedPayment: IPayment | null = null;

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<PaymentDetailResponseModel> =
        await this.restClient.post(PAYMENTS_API_PATH.POST, body);

      if (response.success && response.data) {
        const paymentsMapper = new PaymentsMapper();
        mappedPayment = paymentsMapper.mapPaymentResponse(response.data);

        notificationService.notificationStyle = NOTIFICATION_STYLES.POPUP;
        notificationService.actionButtonText =
          mappedPayment?.transactionType === PAYMENT_METHODS_TYPES.ONE_BILL
            ? "How to pay"
            : "Proceed to pay";
        notificationService.action = () => {
          const url =
            mappedPayment?.transactionType === PAYMENT_METHODS_TYPES.ONE_BILL
              ? payGuideUrl
              : mappedPayment?.creditCardlDetail?.paymentUrl;
          window.open(url);
        };
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.title = "Payments";
        notificationService.description =
          NOTIFICATION_MESSAGES.PAYMENT_CREATE_SUCCESS;
        callback(mappedPayment.id);
      } else {
        notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    notificationService.triggerNotification();

    return mappedPayment;
  }

  public getAllPaymentsByDate(
    payload: StartDateEndDateDTO
  ): Promise<IAGResponse> {
    return this.restClient.get(
      PAYMENTS_API_PATH.GET_PAYMENTS_BY_DATE_RANGE,
      payload
    );
  }

  public getAllPaymentMethods(organization_id: string): Promise<IAGResponse> {
    return this.restClient.get(
      PAYMENTS_API_PATH.GET_PAYMENT_LIST + organization_id
    );
  }
}

export default PaymentsService;
