import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2695feb3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deal-card" }
const _hoisted_2 = { class: "deal-header" }
const _hoisted_3 = { class: "deal-body" }
const _hoisted_4 = { class: "item" }
const _hoisted_5 = { class: "item" }
const _hoisted_6 = { class: "item" }
const _hoisted_7 = { class: "item" }
const _hoisted_8 = { class: "item" }
const _hoisted_9 = { class: "item" }
const _hoisted_10 = { class: "item" }
const _hoisted_11 = { class: "deal-tags" }
const _hoisted_12 = { class: "deal-tag" }
const _hoisted_13 = { class: "deal-tag" }
const _hoisted_14 = { class: "tooltip-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_m_flight_timeline_icon = _resolveComponent("m-flight-timeline-icon")!
  const _component_MChip = _resolveComponent("MChip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MTypography, {
        type: "body",
        class: "text-white deal-name"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.renderDetailsToUpperCase(_ctx.deal_name)), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_MIcon, {
          name: "m-deals",
          class: "deals-icon"
        }),
        _createVNode(_component_MTypography, {
          type: "label",
          class: "item-details"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Discount Code:")
          ]),
          _: 1
        }),
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.discount_code), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_MIcon, {
          name: "m-deals-amount",
          class: "deals-icon"
        }),
        _createVNode(_component_MTypography, {
          type: "label",
          class: "item-details"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Discount:")
          ]),
          _: 1
        }),
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.amount), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_MIcon, {
          name: "m-deals-active",
          class: "deals-icon"
        }),
        _createVNode(_component_MTypography, {
          type: "label",
          class: "item-details"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Active:")
          ]),
          _: 1
        }),
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.renderBoolean(_ctx.isActive)), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_MIcon, {
          name: "m-deals-portal-only",
          class: "deals-icon"
        }),
        _createVNode(_component_MTypography, {
          type: "label",
          class: "item-details"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Booking Via:")
          ]),
          _: 1
        }),
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.renderBookingType(_ctx.portalOnly)), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_MIcon, {
          name: "m-deals-validity",
          class: "deals-icon"
        }),
        _createVNode(_component_MTypography, {
          type: "label",
          class: "item-details"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Validity:")
          ]),
          _: 1
        }),
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(_ctx.start, _ctx.FORMAT_DD_MMM_YYYY_WITH_DASH)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_m_flight_timeline_icon),
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(_ctx.end, _ctx.FORMAT_DD_MMM_YYYY_WITH_DASH)), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_MIcon, {
          name: "m-deals-confirmation",
          class: "deals-icon"
        }),
        _createVNode(_component_MTypography, {
          type: "label",
          class: "item-details"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Discount Mode:")
          ]),
          _: 1
        }),
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.renderDetailsToLowerCase(_ctx.discount_mode)), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_MIcon, {
          name: "m-deals-flight",
          class: "deals-icon"
        }),
        _createVNode(_component_MTypography, {
          type: "label",
          class: "item-details"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Applicable On:")
          ]),
          _: 1
        }),
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.applicable_on), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          (_ctx.grossApply)
            ? (_openBlock(), _createBlock(_component_MChip, {
                key: 0,
                variant: "warning",
                prependIcon: _ctx.amountType,
                "border-less": true
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Gross Fare ")
                ]),
                _: 1
              }, 8, ["prependIcon"]))
            : (_openBlock(), _createBlock(_component_MChip, {
                key: 1,
                variant: "error",
                prependIcon: _ctx.amountType,
                "border-less": true
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Base Fare ")
                ]),
                _: 1
              }, 8, ["prependIcon"]))
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_MChip, {
            variant: "info",
            prependIcon: "m-flight-takeoff",
            appendIcon: "m-deals-info",
            "border-less": true,
            class: "tooltip-container"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.renderRoutes) + " ", 1),
              _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.renderToolTip), 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}