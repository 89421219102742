import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0935704a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "overview-container"
}
const _hoisted_2 = { class: "items-container" }
const _hoisted_3 = {
  key: 1,
  class: "spacer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MAccordion = _resolveComponent("MAccordion")!

  return (_openBlock(), _createBlock(_component_MAccordion, {
    "initial-open": _ctx.isInitialOpen,
    "external-open": _ctx.isOpen
  }, {
    title: _withCtx(() => [
      _createVNode(_component_AgIconInfoBar, {
        "test-id": "",
        title: _ctx.title,
        class: "accordion-title",
        mPrepandIcon: _ctx.prepandIcon
      }, null, 8, ["title", "mPrepandIcon"])
    ]),
    content: _withCtx(() => [
      (_ctx.$slots.overview)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "overview", {}, undefined, true)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3)),
      _renderSlot(_ctx.$slots, "accordion-content", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["initial-open", "external-open"]))
}