import { MutationTree } from "vuex";
import {
  IVisaVerificationState,
  VisaVerificationData,
} from "@/ag-portal-common/interfaces/visaVerificationState.interface";

const mutations: MutationTree<IVisaVerificationState> = {
  setAddingRequestLoading(state, payload: boolean) {
    state.isAddingRequestLoading = payload;
  },
  setFetchingRequestLoading(state, payload: boolean) {
    state.isFetchingRequestLoading = payload;
  },
  setDownloadVisaLoading(state, payload: boolean) {
    state.isDownloadVisaLoading = payload;
  },
  setVisaVerificationList(state, payload: VisaVerificationData[]) {
    state.visaRequestsList = payload;
  },
};

export default mutations;
