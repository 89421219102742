<template>
  <PassengerVisaVerificationPage />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PassengerVisaVerificationPage from "../components/PassengerVerificationPage.vue";

export default defineComponent({
  components: {
    PassengerVisaVerificationPage,
  },
});
</script>
