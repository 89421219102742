<template>
  <div>
    <MCard class="m-card">
      <div>
        <MTypography type="body">Profile Management</MTypography>
        <MTypography type="label">Update your profile</MTypography>
        <hr />
      </div>

      <div>
        <div class="profile-details">
          <MTextfield
            label="User Name"
            placeholder="User Name"
            :disabled="true"
            v-model:inputValue="preferences.username"
            class="user-name"
          />
          <MTextfield
            label="Email"
            placeholder="Email"
            :disabled="true"
            v-model:inputValue="preferences.email"
          />
        </div>

        <div class="contact-details">
          <div class="phone-number">
            <MTypography type="body"> Phone Number </MTypography>
            <AgPhoneField
              test-id=""
              :default-value="preferences.phoneNumber"
              :error="preferencesErrors['contact_number']"
              @update-value="onParsePhoneNumberHandler"
            />
          </div>

          <div class="phone-number">
            <MTypography type="body">WhatsApp Number</MTypography>
            <AgPhoneField
              test-id=""
              :default-value="preferences.whatsappNumber"
              :error="preferencesErrors['whatsapp_number']"
              @update-value="onParseWhatsAppNumberHandler"
            />
          </div>
        </div>

        <div class="return-view">
          <MTypography type="body">Return flights default view</MTypography>
          <MSegmentedButton :segments="returnFlightViews" />
        </div>
        <MButton
          @click="onUpdatePreferencesHandler"
          :disabled="isPreferencesLoading"
        >
          Update
        </MButton>
      </div>
    </MCard>

    <MCard class="m-card">
      <div>
        <MTypography type="body">Password Management</MTypography>
        <MTypography type="label">Change your password</MTypography>
        <hr />
      </div>

      <div>
        <MButton @click="onChangePasswordHandler"> Change Password </MButton>
      </div>
    </MCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  IProfilePreferences,
  PhoneFieldValue,
} from "@/ag-portal-common/interfaces/profile.interface";

import { preferencesUpdateValidation } from "@/modules/Profile/validations/preferencesUpdateValidation";

import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { ValidationError } from "yup";
import { User } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { MCard, MTypography } from "@aeroglobe/ag-core-ui";
import { PATH } from "@/ag-portal-common/constants/path";
import { MSegment } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";

export default defineComponent({
  name: "userProfile",
  components: {
    MCard,
    MTypography,
  },
  data() {
    return {
      preferences: {
        username: "",
        email: "",
        phoneNumber: "",
        whatsappNumber: "",
        flightPairViewToggle: false,
      },

      isPreferencesLoading: false,
      preferencesErrors: {} as { [index: string]: string },

      isPrefPhoneNumberValid: false,
      isPrefWhatsappNumberValid: false,
      returnFlightViews: [
        {
          label: "Single View",
          checked: false,
          icon: "m-single-view",
        },
        {
          label: "Pair View",
          checked: false,
          icon: "m-pair-view",
        },
      ] as MSegment[],
    };
  },
  computed: {
    user(): User | null {
      return this.$store.getters[AUTH_GETTERS.USER];
    },

    savedPreferences(): IProfilePreferences {
      const savedPreferences = localStorage.getItem("user-preferences");

      if (savedPreferences) {
        return JSON.parse(savedPreferences);
      }

      return this.$store.state.profileModule.preferences;
    },
  },
  watch: {
    savedPreferences() {
      this.onSyncPreferencesHandler();
    },
  },
  created() {
    if (this.user) {
      this.preferences.email = this.user.email;
      this.preferences.username = `${this.user.first_name} ${this.user.last_name}`;
      this.returnFlightViews[1].checked = this.savedPreferences
        .flight_search_pairs_view
        ? true
        : false;
      this.returnFlightViews[0].checked = this.savedPreferences
        .flight_search_pairs_view
        ? false
        : true;
    }

    this.onSyncPreferencesHandler();
  },
  methods: {
    async onUpdatePreferencesHandler() {
      this.isPreferencesLoading = true;
      this.preferencesErrors = {};

      this.onValidatePhoneNumbers();

      try {
        if (!this.isPrefPhoneNumberValid || !this.isPrefWhatsappNumberValid) {
          throw new Error("Invalid Data");
        }

        const { phoneNumber, whatsappNumber } = this.preferences;

        const payload = await preferencesUpdateValidation.validate(
          {
            contact_number: phoneNumber,
            whatsapp_number: whatsappNumber,
            flight_search_pairs_view: this.returnFlightViews[1].checked,
          },
          { abortEarly: false }
        );
        const agentId = this.user?.agent_user_data.id;
        await this.$store.dispatch("updatePreferences", {
          body: payload,
          agentId,
        });
      } catch (exception) {
        if (exception instanceof ValidationError) {
          this.preferencesErrors = yupValidationErrorAsSchema(exception);
        }
      } finally {
        this.isPreferencesLoading = false;
      }
    },
    onChangePasswordHandler() {
      this.$router.push(PATH.CHANGE_PASSWORD);
    },

    onSyncPreferencesHandler() {
      this.preferences.phoneNumber = this.savedPreferences.contact_number;
      this.preferences.whatsappNumber = this.savedPreferences.whatsapp_number;
      this.preferences.flightPairViewToggle =
        this.savedPreferences.flight_search_pairs_view;
    },
    onValidatePhoneNumbers() {
      this.preferencesErrors = {
        contact_number: !this.isPrefPhoneNumberValid
          ? "Invalid phone number"
          : "",
        whatsapp_number: !this.isPrefWhatsappNumberValid
          ? "Invalid whatsapp number"
          : "",
      };
    },
    onParsePhoneNumberHandler(value: PhoneFieldValue) {
      if (value.e164) {
        this.preferences.phoneNumber = value.e164;

        this.isPrefPhoneNumberValid = value.isValid;

        this.onValidatePhoneNumbers();
      }
    },
    onParseWhatsAppNumberHandler(value: PhoneFieldValue) {
      if (value.e164) {
        this.preferences.whatsappNumber = value.e164;

        this.isPrefWhatsappNumberValid = value.isValid;

        this.onValidatePhoneNumbers();
      }
    },
  },
});
</script>

<style lang="scss">
.up-toggle {
  margin-bottom: 10px;

  .v-selection-control__wrapper {
    color: #dadada;
  }

  .v-selection-control {
    min-height: 0 !important;
  }
}
</style>

<style lang="css" scoped>
.m-card {
  margin: 0 auto 16px;
}
.contact-details {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.phone-number {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.profile-details {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.user-name {
  margin-right: 10px;
}
@media screen and (max-width: 1100px) {
  .phone-number {
    margin-right: 0px;
  }
  .profile-details {
    display: flex;
    flex-direction: column;
    min-width: 300px;
  }
  .contact-details {
    display: flex;
    flex-direction: column;
    min-width: 300px;
  }
}
.return-view {
  margin-bottom: 10px;
}
</style>
