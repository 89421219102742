import { GetterTree } from "vuex";
import { IFinancialProfileState } from "@/ag-portal-common/interfaces/financialProfileState.interface";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { StoreState } from "@/store/type";

const getters: GetterTree<IFinancialProfileState, StoreState> = {
  financialProfiles: (state: IFinancialProfileState): IFinancialProfile[] =>
    state.financialProfiles,
  financialProfile: (state: IFinancialProfileState): IFinancialProfile | null =>
    state.financialProfile,
  isFetchingFinancialProfiles: (state: IFinancialProfileState): boolean =>
    state.isFetchingFinancialProfiles,
  isFetchingFinancialProfile: (state: IFinancialProfileState): boolean =>
    state.isFetchingFinancialProfile,
  isLinkingFinancialProfile: (state: IFinancialProfileState): boolean =>
    state.isLinkingFinancialProfile,
  isUnlinkingFinancialProfile: (state: IFinancialProfileState): boolean =>
    state.isUnlinkingFinancialProfile,
};

export default getters;
