export const DASHBOARD_API_PATH = {
  GET_INSIGHTS: "v1/insights/booking/aggregate",
  GET_EXPIRY: "v1/insights/booking/expiry/soon",
  GET_FLIGHT_ISSUANCE_DATA: "flight-details",
  GET_HOTEL_ISSUANCE_DATA: "hotel-details",
};

export const DASHBOARD_ANALYTICS_COMMON_EVENTS = {
  NEWS_CLICKED: "news-action-clicked",
};
