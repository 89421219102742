import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3607c9ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "postings" }
const _hoisted_2 = { class: "accordion-title" }
const _hoisted_3 = { class: "mchip-alignment td-full-width" }
const _hoisted_4 = { class: "mchip-alignment td-full-width" }
const _hoisted_5 = { class: "accordion-title" }
const _hoisted_6 = { class: "mchip-alignment td-full-width" }
const _hoisted_7 = { class: "mchip-alignment td-full-width" }
const _hoisted_8 = { class: "accordion-title" }
const _hoisted_9 = { class: "mchip-alignment td-full-width" }
const _hoisted_10 = { class: "accordion-title" }
const _hoisted_11 = { class: "mchip-alignment td-full-width" }
const _hoisted_12 = { class: "mchip-alignment td-full-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_MAccordion = _resolveComponent("MAccordion")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createBlock(_component_MDialog, {
    modelValue: _ctx.isModalOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isModalOpen) = $event)),
    onClose: _ctx.onCloseModal,
    "max-width": "1200",
    title: _ctx.renderDialogTitle
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.pendingPostingFlightInvoices.length > 0)
          ? (_openBlock(), _createBlock(_component_MAccordion, {
              key: 0,
              "initial-open": false
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", _hoisted_2, [
                  _createVNode(_component_AgIconInfoBar, {
                    "test-id": "",
                    mPrepandIcon: "m-receipt-long",
                    title: "Flight Invoices",
                    class: "accordion-title accordions"
                  })
                ])
              ]),
              content: _withCtx(() => [
                _createVNode(_component_MDataTable, {
                  headers: _ctx.flightInvoicesHeaders,
                  data: _ctx.pendingPostingFlightInvoices,
                  "item-per-page": 10,
                  "total-item-count": _ctx.pendingPostingFlightInvoices.length,
                  "has-search": false,
                  "is-api-paginated": false
                }, {
                  ticket_no: _withCtx(({ item }) => [
                    _createVNode(_component_MTypography, {
                      class: "description",
                      type: "body"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.ticket_no), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  pnr: _withCtx(({ item }) => [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_MChip, {
                        class: "chip-width",
                        "border-less": true,
                        variant: "warning"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.pnr), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  posting_status: _withCtx(({ item }) => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_MChip, {
                        class: "chip-width",
                        "border-less": true,
                        variant: "error"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.posting_status), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 1
                }, 8, ["headers", "data", "total-item-count"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.pendingPostingFlightReceipts.length > 0)
          ? (_openBlock(), _createBlock(_component_MAccordion, {
              key: 1,
              "initial-open": false
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", _hoisted_5, [
                  _createVNode(_component_AgIconInfoBar, {
                    "test-id": "",
                    mPrepandIcon: "m-receipt-long",
                    title: "Flight Receipts",
                    class: "accordion-title accordions"
                  })
                ])
              ]),
              content: _withCtx(() => [
                _createVNode(_component_MDataTable, {
                  headers: _ctx.flightReceiptsHeaders,
                  data: _ctx.pendingPostingFlightReceipts,
                  "item-per-page": 10,
                  "has-search": false,
                  "is-api-paginated": false
                }, {
                  payment_id: _withCtx(({ item }) => [
                    _createVNode(_component_MTypography, {
                      class: "description",
                      type: "body"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.payment_id), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  amount: _withCtx(({ item }) => [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_MChip, {
                        class: "chip-width",
                        "border-less": true,
                        variant: "warning"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.amount), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  posting_status: _withCtx(({ item }) => [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_MChip, {
                        class: "chip-width",
                        "border-less": true,
                        variant: "error"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.posting_status), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 1
                }, 8, ["headers", "data"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.pendingPostingHotelInvoices.length > 0)
          ? (_openBlock(), _createBlock(_component_MAccordion, {
              key: 2,
              "initial-open": false
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", _hoisted_8, [
                  _createVNode(_component_AgIconInfoBar, {
                    "test-id": "",
                    mPrepandIcon: "m-receipt-long",
                    title: "Hotel Invoices",
                    class: "accordion-title accordions"
                  })
                ])
              ]),
              content: _withCtx(() => [
                _createVNode(_component_MDataTable, {
                  headers: _ctx.hotelInvoicesHeaders,
                  data: _ctx.pendingPostingHotelInvoices,
                  "item-per-page": 10,
                  "has-search": false,
                  "is-api-paginated": false
                }, {
                  booking_id: _withCtx(({ item }) => [
                    _createVNode(_component_MTypography, {
                      class: "description",
                      type: "body"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.booking_id), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  status: _withCtx(({ item }) => [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_MChip, {
                        class: "chip-width",
                        "border-less": true,
                        variant: "error"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.status), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 1
                }, 8, ["headers", "data"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.pendingPostingGroupFlightInvoices.length > 0)
          ? (_openBlock(), _createBlock(_component_MAccordion, {
              key: 3,
              "initial-open": false
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", _hoisted_10, [
                  _createVNode(_component_AgIconInfoBar, {
                    "test-id": "",
                    mPrepandIcon: "m-receipt-long",
                    title: "Group Flight Invoices",
                    class: "accordion-title accordions"
                  })
                ])
              ]),
              content: _withCtx(() => [
                _createVNode(_component_MDataTable, {
                  headers: _ctx.groupFlightInvoicesHeaders,
                  data: _ctx.pendingPostingGroupFlightInvoices,
                  "item-per-page": 10,
                  "has-search": false,
                  "is-api-paginated": false
                }, {
                  booking_id: _withCtx(({ item }) => [
                    _createVNode(_component_MTypography, {
                      class: "description",
                      type: "body"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.booking_id), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  posting_status: _withCtx(({ item }) => [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_MChip, {
                        class: "chip-width",
                        "border-less": true,
                        variant: "error"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.posting_status), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  price: _withCtx(({ item }) => [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_MChip, {
                        class: "chip-width",
                        "border-less": true,
                        variant: "warning"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.price), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 1
                }, 8, ["headers", "data"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      (
          _ctx.pendingPostingFlightInvoices.length === 0 &&
          _ctx.pendingPostingFlightReceipts.length === 0 &&
          _ctx.pendingPostingHotelInvoices.length === 0 &&
          _ctx.pendingPostingGroupFlightInvoices.length === 0
        )
        ? (_openBlock(), _createBlock(_component_AgNotFound, {
            key: 0,
            "test-id": "",
            heading: "No Pending Receipts & Invoices Found",
            description: ""
          }))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_AGButton, {
        onClick: _ctx.onCloseModal,
        variant: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["modelValue", "onClose", "title"]))
}