import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FITHotelSelectedInfo = _resolveComponent("FITHotelSelectedInfo")!
  const _component_MTextarea = _resolveComponent("MTextarea")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createBlock(_component_MDialog, {
    title: "Selected Hotel for FIT",
    modelValue: _ctx.isMdialogOpen,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isMdialogOpen) = $event)),
    maxWidth: 900
  }, {
    content: _withCtx(() => [
      (_ctx.hasEmailItem)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emailItems, (booking, bookingKey) => {
              return (_openBlock(), _createElementBlock("div", { key: bookingKey }, [
                _createVNode(_component_FITHotelSelectedInfo, {
                  checkinDate: booking.check_in,
                  checkoutDate: booking.check_out,
                  noOfNight: booking.nights,
                  totalCost: booking.total_selling_price,
                  hotelName: booking.hotel_name,
                  roomType: booking.room_name,
                  travelers: booking.total_passengers
                }, null, 8, ["checkinDate", "checkoutDate", "noOfNight", "totalCost", "hotelName", "roomType", "travelers"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.hasPreviewItem)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_FITHotelSelectedInfo, {
              checkinDate: _ctx.previewItems.check_in,
              checkoutDate: _ctx.previewItems.check_out,
              noOfNight: _ctx.previewItems.nights,
              totalCost: _ctx.previewItems.total_selling_price,
              hotelName: _ctx.previewItems.hotel_name,
              roomType: _ctx.previewItems.room_name,
              travelers: _ctx.previewItems.total_passengers
            }, null, 8, ["checkinDate", "checkoutDate", "noOfNight", "totalCost", "hotelName", "roomType", "travelers"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_MTextarea, {
        inputValue: _ctx.query,
        "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
        inputValueModifiers: { trim: true },
        label: "Comments",
        hasError: _ctx.queryError.length,
        errorMessage: _ctx.queryError
      }, null, 8, ["inputValue", "hasError", "errorMessage"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_MButton, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addNextItinerary())),
        variant: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Add Next Itinerary ")
        ]),
        _: 1
      }),
      _createVNode(_component_MButton, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sendFITEmailOnPreview())),
        type: "outlined"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Continue by sending email ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}