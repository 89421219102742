import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb7f668e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-preview" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTypography = _resolveComponent("MTypography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MTypography, {
      class: "headline",
      type: "headline"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.previewFields, (field) => {
      return (_openBlock(), _createElementBlock("div", {
        key: field.key,
        class: _normalizeClass(["preview-field", { 'has-error': _ctx.hasError(field.key) }])
      }, [
        _createVNode(_component_MTypography, {
          type: "body",
          class: "preview-label"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(field.label) + ":", 1)
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_MTypography, {
          type: "body",
          class: "preview-value"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.formatPreviewValue(field.key, _ctx.traveler[field.key])), 1)
          ]),
          _: 2
        }, 1024)
      ], 2))
    }), 128))
  ]))
}