import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bcefafe4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hotel_selected_info" }
const _hoisted_2 = { class: "additional_info_wrap" }
const _hoisted_3 = { class: "label_wrap" }
const _hoisted_4 = { class: "label_wrap" }
const _hoisted_5 = { class: "label_wrap" }
const _hoisted_6 = { class: "label_wrap" }
const _hoisted_7 = { class: "label_wrap" }
const _hoisted_8 = { class: "label_wrap" }
const _hoisted_9 = { class: "label_wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTypography = _resolveComponent("MTypography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(" Hotel Name ")
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(_component_MTypography, { type: "label" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.hotelName), 1)
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.hotelName]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(" Room Type ")
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(_component_MTypography, { type: "label" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.roomType), 1)
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.roomType]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(" Check In ")
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(_component_MTypography, { type: "label" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.checkinDate), 1)
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.checkinDate]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(" Check out ")
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(_component_MTypography, { type: "label" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.checkoutDate), 1)
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.checkoutDate]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(" No Of Nights ")
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(_component_MTypography, { type: "label" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.noOfNight), 1)
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.checkinDate]
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_8, [
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(" Travelers ")
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(_component_MTypography, { type: "label" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.travelers), 1)
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.totalCost]
        ])
      ], 512), [
        [_vShow, _ctx.travelers]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_9, [
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(" Total Cost ")
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(_component_MTypography, { type: "label" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.totalCost), 1)
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.totalCost]
        ])
      ], 512), [
        [_vShow, _ctx.totalCost]
      ]),
      _renderSlot(_ctx.$slots, "deleteFIT", {}, undefined, true)
    ])
  ]))
}