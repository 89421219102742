import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3402426e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "heading" }
const _hoisted_2 = {
  key: 0,
  class: "details"
}
const _hoisted_3 = { class: "grid-container" }
const _hoisted_4 = { class: "grid-container" }
const _hoisted_5 = { class: "empty-items-message" }
const _hoisted_6 = { class: "grid-container" }
const _hoisted_7 = { class: "empty-items-message" }
const _hoisted_8 = { class: "grid-container" }
const _hoisted_9 = { class: "empty-items-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDatePicker = _resolveComponent("MDatePicker")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_ShimmerCard = _resolveComponent("ShimmerCard")!
  const _component_SnapshotTile = _resolveComponent("SnapshotTile")!
  const _component_SnapshotDetailAccordion = _resolveComponent("SnapshotDetailAccordion")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MCard, { class: "organization-snapshot-header" }, {
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_1, "Statistics " + _toDisplayString(_ctx.renderOrgName()), 1),
        _createVNode(_component_ag_row, { class: "mt-10" }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_column, {
              xs: "12",
              sm: "12",
              md: "12",
              lg: "5"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MDatePicker, {
                  label: "Start Date",
                  "start-date": _ctx.defaultStartDate,
                  "max-date": new Date(),
                  onOnRangeDateChange: _cache[0] || (_cache[0] = (value) => _ctx.startDate = value),
                  placeholder: "Start Date",
                  dateType: "startDate",
                  multiCalendar: false,
                  hasRange: false,
                  autoPosition: true,
                  error: _ctx.bookingDateError
                }, null, 8, ["start-date", "max-date", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              xs: "12",
              sm: "12",
              md: "12",
              lg: "5"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MDatePicker, {
                  label: "End Date",
                  "start-date": new Date(),
                  "max-date": new Date(),
                  "min-date": _ctx.startDate,
                  onOnRangeDateChange: _cache[1] || (_cache[1] = (value) => _ctx.endDate = value),
                  placeholder: "End Date",
                  dateType: "endDate",
                  multiCalendar: false,
                  hasRange: false,
                  autoPosition: true,
                  error: _ctx.bookingDateError
                }, null, 8, ["start-date", "max-date", "min-date", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              xs: "12",
              sm: "12",
              md: "12",
              lg: "2",
              class: "d-flex align-items-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MButton, {
                  onClick: _ctx.handleFetchData,
                  disabled: _ctx.isLoading,
                  class: "margin_bottom_20"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Fetch Statistics")
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.isOrganizationProductivityLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_ShimmerCard, { "card-height": "200px" }),
          _createVNode(_component_ShimmerCard, { "card-height": "200px" }),
          _createVNode(_component_ShimmerCard, { "card-height": "150px" }),
          _createVNode(_component_ShimmerCard, { "card-height": "100px" })
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.showDetails)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_SnapshotDetailAccordion, {
                  title: "Overall Counts",
                  "prepand-icon": "m-number"
                }, {
                  overview: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_SnapshotTile, {
                        heading: "Total Flight Bookings",
                        subHeading: 
                _ctx.organizationProductivity?.hotel_and_flight_details?.aggregates
                  ?.total_flight_bookings
              ,
                        icon: "m-issue-ticket",
                        subHeadingTitle: "Count"
                      }, null, 8, ["subHeading"]),
                      _createVNode(_component_SnapshotTile, {
                        heading: "Total Issued Tickets",
                        subHeading: 
                _ctx.organizationProductivity?.total_tickets_issued
                  ?.total_issued_tickets
              ,
                        icon: "m-issue-ticket",
                        subHeadingTitle: "Count"
                      }, null, 8, ["subHeading"]),
                      _createVNode(_component_SnapshotTile, {
                        heading: "Total Hotel Bookings",
                        subHeading: 
                _ctx.organizationProductivity?.hotel_and_flight_details?.aggregates
                  ?.total_hotels_bookings
              ,
                        icon: "m-hotel",
                        subHeadingTitle: "Count"
                      }, null, 8, ["subHeading"]),
                      _createVNode(_component_SnapshotTile, {
                        heading: "Total Issued Hotels",
                        subHeading: 
                _ctx.organizationProductivity?.hotel_and_flight_details?.aggregates
                  ?.total_hotels_issued
              ,
                        icon: "m-hotel",
                        subHeadingTitle: "Count"
                      }, null, 8, ["subHeading"]),
                      _createVNode(_component_SnapshotTile, {
                        heading: "Total Payment Created",
                        subHeading: 
                _ctx.organizationProductivity?.payment_details?.aggregates
                  .total_payments_created
              ,
                        icon: "m-payment-management",
                        subHeadingTitle: "Count"
                      }, null, 8, ["subHeading"]),
                      _createVNode(_component_SnapshotTile, {
                        heading: "Total Credit Limit Requests",
                        subHeading: 
                _ctx.organizationProductivity?.total_credit_limit_requests
              ,
                        icon: "m-credit-limit-management",
                        subHeadingTitle: "Count"
                      }, null, 8, ["subHeading"]),
                      _createVNode(_component_SnapshotTile, {
                        heading: "Total Sabre Segments",
                        subHeading: _ctx.organizationProductivity?.total_sabre_segments,
                        icon: "m-sabre-segment",
                        subHeadingTitle: "Count"
                      }, null, 8, ["subHeading"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SnapshotDetailAccordion, {
                  title: "Booked",
                  "prepand-icon": "m-my-bookings"
                }, {
                  overview: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationProductivity
                ?.hotel_and_flight_details?.flight_bookings, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          _createVNode(_component_SnapshotTile, {
                            heading: _ctx.replaceUnderscoreWithSpace(item.provider_name),
                            subHeading: item.count,
                            icon: "m-flight",
                            subHeadingTitle: "Booked Count",
                            description: 
                  _ctx.formattedCurrency(
                    item.total_amount.currency,
                    item.total_amount.value
                  )
                ,
                            descriptionTitle: "Amount"
                          }, null, 8, ["heading", "subHeading", "description"])
                        ]))
                      }), 128))
                    ]),
                    _withDirectives(_createElementVNode("p", _hoisted_5, " Nothing Booked Yet ", 512), [
                      [_vShow, _ctx.showBookedEmptyMsg]
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SnapshotDetailAccordion, {
                  title: "Issued",
                  "prepand-icon": "m-issue-ticket"
                }, {
                  overview: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationProductivity
                ?.total_tickets_issued?.issued_tickets, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          _createVNode(_component_SnapshotTile, {
                            heading: _ctx.replaceUnderscoreWithSpace(item.provider_name),
                            subHeading: item.count,
                            icon: "m-flight",
                            subHeadingTitle: "Issued Count",
                            description: 
                  _ctx.formattedCurrency(
                    item.total_amount.currency,
                    item.total_amount.value
                  )
                ,
                            descriptionTitle: "Amount"
                          }, null, 8, ["heading", "subHeading", "description"])
                        ]))
                      }), 128))
                    ]),
                    _withDirectives(_createElementVNode("p", _hoisted_7, " Nothing Issued Yet ", 512), [
                      [_vShow, _ctx.showIssuedEmptyMsg]
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SnapshotDetailAccordion, {
                  title: "Payments",
                  "prepand-icon": "m-payment-management"
                }, {
                  overview: _withCtx(() => [
                    _createElementVNode("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationProductivity?.payment_details
                ?.payments, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          _createVNode(_component_SnapshotTile, {
                            heading: _ctx.replaceUnderscoreWithSpace(item.payment_method),
                            subHeading: item.count,
                            subHeadingTitle: "Count",
                            icon: "m-payment-management"
                          }, null, 8, ["heading", "subHeading"])
                        ]))
                      }), 128))
                    ]),
                    _withDirectives(_createElementVNode("p", _hoisted_9, " No Payment Found ", 512), [
                      [_vShow, _ctx.showPaymentsEmptyMsg]
                    ])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.showResultNotFound)
            ? (_openBlock(), _createBlock(_component_ResultNotFound, { key: 1 }))
            : _createCommentVNode("", true)
        ], 64))
  ], 64))
}