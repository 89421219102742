import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7481990e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "add-whatsapp-member-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgPhoneField = _resolveComponent("AgPhoneField")!
  const _component_MTextfield = _resolveComponent("MTextfield")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createBlock(_component_MDialog, {
    title: "Add Whatsapp Member",
    modelValue: _ctx.isMdialogOpen,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isMdialogOpen) = $event)),
    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('closeModal')))
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_AgPhoneField, {
          "test-id": "",
          "default-country-code": "PK",
          error: _ctx.errors?.contact_number,
          onUpdateValue: _ctx.handleMobileFieldChange,
          class: "phone-field"
        }, null, 8, ["error", "onUpdateValue"]),
        _createVNode(_component_MTextfield, {
          label: "Member Name",
          inputValue: _ctx.name,
          "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
          variant: "outlined",
          hasError: _ctx.errors?.name,
          errorMessage: _ctx.errors?.name
        }, null, 8, ["inputValue", "hasError", "errorMessage"])
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_MButton, {
        disabled: _ctx.$store.getters.isWhatsappActionLoading,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSubmit()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Add")
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}