import { Module } from "vuex";
import { IAgentState } from "@/interfaces/agentState.interface";
import { StoreState } from "@/store/type";

import mutations from "./mutations";
import actions from "./actions";
import state from "@/modules/Agent/vuex/state";
import getters from "@/modules/Agent/vuex/getters";

const organizationModule: Module<IAgentState, StoreState> = {
  state,
  mutations,
  actions,
  getters,
};

export default organizationModule;
