import { VISA_VERIFICATION_STATUS_ENUM } from "@/ag-portal-common/interfaces/visaVerificationState.interface";

export const VISA_VERIFICATION_API_PATH = {
  ADD_VISA_REQUEST: "visa/ok-to-board/{0}",
  GET_VISA_REQUESTS: "visa/ok-to-board/{0}",
  DOWNLOAD_VERIFIED_VISA: "visa/ok-to-board/download/{0}",
  OPEN_DOWNLOAD_VERIFIED_VISA: "visa/visa-verification/{0}-{1}",
};

export const VISA_VERIFICATION_STATUSES_MAPPING = {
  [VISA_VERIFICATION_STATUS_ENUM.VERIFIED]: "success",
  [VISA_VERIFICATION_STATUS_ENUM.PENDING]: "info",
  [VISA_VERIFICATION_STATUS_ENUM.FAILED]: "error",
};

export const PNR_STATUSES = {
  TICKETED: "TICKETED",
  ONHOLD: "ONHOLD",
};

export const VISA_VERIFICATION_STAMP_POSITIONS = [
  "top-left",
  "top-center",
  "top-right",
  "center-left",
  "center",
  "center-right",
  "bottom-left",
  "bottom-center",
  "bottom-right",
] as const;

export const SAUDI_WHITELISTED_AIRPORTS_FOR_OK_TO_BOARD = [
  "JED",
  "RUH",
  "DMM",
  "MED",
];
