<template>
  <MProgress indeterminate v-if="isLoading" class="mprogress-alignment" />
  <div v-else>
    <MCard class="ok-to-board-heading">
      <div class="d-flex justify-content-between align-items-center">
        <AgIconInfoBar
          test-id=""
          title="Ok To Board"
          class="section-title"
          mPrepandIcon="m-issue-ticket"
        />
        <div>
          <MButton variant="filled" @click="handleAddRequest"
            >Add Ok To Board Request</MButton
          >
        </div>
      </div>
    </MCard>
    <MCard>
      <AgNotFound
        v-if="!visaVerificationList.length"
        test-id=""
        heading="No Ok To Board Request Found"
      />
      <MDataTable
        v-else
        :headers="headers"
        :is-loading="isLoading"
        :data="visaVerificationList"
        :item-per-page="10"
        :has-search="true"
      >
        <template #status="{ item }">
          <div class="status-container">
            <MChip :variant="visaVerificationStatus(item.status)">{{
              item.status
            }}</MChip>
          </div>
        </template>
        <template #action="{ item }">
          <div>
            <MButton
              @click="handleClickDownloadVisa(item.public_id)"
              variant="primary"
              type="filled"
              :disabled="isDownloadBtnDisable(item.status)"
            >
              Download Visa
            </MButton>
          </div>
        </template>
      </MDataTable>
    </MCard>
  </div>
  <MDialog
    v-model="showDownloadPDFDialog"
    max-width="600"
    @close="closeDialog"
    title="Download Visa PDF"
    class="download-visa-modal m-scrollbar"
  >
    <template #content>
      <div class="download-visa-modal-content">
        <MCombobox
          v-model:inputValue="selectedStampPosition"
          label="Select Ok To Board Stamp Position"
          itemValue="value"
          itemLabel="label"
          :options="stampPositions"
          class="stamp-alignment"
        />
      </div>
    </template>
    <template #actions>
      <MButton :disabled="isDownloadVisaLoading" @click="handleDownloadPDF"
        >{{ downloadVisaText }} Visa</MButton
      >
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  MButton,
  MDataTable,
  MTypography,
  MCard,
  MCombobox,
} from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { Organization } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import {
  VISA_VERIFICATION_STATUS_ENUM,
  VisaVerificationData,
} from "@/ag-portal-common/interfaces/visaVerificationState.interface";
import { ChipVariant } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import {
  VISA_VERIFICATION_STATUSES_MAPPING,
  VISA_VERIFICATION_STAMP_POSITIONS,
} from "../constants";

export default defineComponent({
  name: "VisaVerificationList",
  components: {
    MDataTable,
    MButton,
    MCard,
    MCombobox,
  },
  mounted() {
    const organizationId = this.organization?.organization_id || "";
    this.$store.dispatch("getVisaRequests", organizationId);
  },
  data() {
    return {
      selectedStampPosition: "center",
      showDownloadPDFDialog: false,
      selectedRequestId: "",
      headers: [
        {
          title: "PNR",
          value: "pnr",
          key: "pnr",
          sortable: true,
        },
        {
          title: "First Name",
          value: "first_name",
          key: "first_name",
          sortable: true,
        },
        {
          title: "Last Name",
          value: "last_name",
          key: "last_name",
          sortable: true,
        },
        {
          title: "Passport Number",
          value: "passport_number",
          key: "passport_number",
          sortable: false,
        },
        {
          title: "Visa Number",
          value: "visa_number",
          key: "visa_number",
          sortable: false,
        },
        {
          title: "Application Status",
          value: "status",
          key: "status",
          sortable: false,
        },
        {
          title: "Download Visa",
          value: "action",
          key: "action",
          sortable: false,
        },
      ] as MDataTableHeader[],
    };
  },
  computed: {
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    isLoading(): boolean {
      return this.$store.getters.isFetchingRequestLoading;
    },
    visaVerificationList() {
      const list = this.$store.getters
        .visaVerificationRequestsList as VisaVerificationData[];
      if (list) {
        return list.map((item) => {
          return {
            pnr: item.pnr,
            public_id: item.public_id,
            first_name: item.first_name,
            last_name: item.last_name,
            passport_number: item.passport_number,
            visa_number: item.visa_number,
            status: item.status,
            action: "Download",
          };
        });
      }
      return [];
    },
    stampPositions() {
      return Object.keys(VISA_VERIFICATION_STAMP_POSITIONS).map((key) => {
        const item = VISA_VERIFICATION_STAMP_POSITIONS[key as any];
        return {
          label: item.replace("-", " ").toUpperCase(),
          value: item,
        };
      });
    },
    isDownloadVisaLoading(): boolean {
      return this.$store.getters.isDownloadVisaLoading;
    },
    downloadVisaText(): string {
      return this.isDownloadVisaLoading ? "Downloading" : "Download";
    },
  },
  methods: {
    handleAddRequest() {
      this.$router.push(PATH.ADD_VISA_VERIFICATION_REQ);
    },
    visaVerificationStatus(status: VISA_VERIFICATION_STATUS_ENUM): ChipVariant {
      return VISA_VERIFICATION_STATUSES_MAPPING[status] as ChipVariant;
    },
    isDownloadBtnDisable(status: VISA_VERIFICATION_STATUS_ENUM): boolean {
      return status !== VISA_VERIFICATION_STATUS_ENUM.VERIFIED;
    },
    handleClickDownloadVisa(id: string) {
      this.showDownloadPDFDialog = true;
      this.selectedRequestId = id;
    },
    closeDialog() {
      this.showDownloadPDFDialog = false;
    },
    handleDownloadPDF() {
      this.$store.dispatch("downloadVisaPDF", {
        id: this.selectedRequestId,
        watermarkPosition: this.selectedStampPosition,
        successHandler: () => {
          this.selectedRequestId = "";
          this.selectedStampPosition = "";
          this.showDownloadPDFDialog = false;
        },
      });
    },
  },
});
</script>

<style scoped lang="css">
.btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.status-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.stamp-alignment {
  margin-top: 10px;
}
.ok-to-board-heading {
  margin-bottom: 10px;
}
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
</style>

<style lang="css">
@media screen and (min-height: 960px) and (min-width: 960px) {
  .download-visa-modal,
  .download-visa-modal .m-dialog-content {
    overflow: visible;
  }
}
.download-visa-modal-content {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
