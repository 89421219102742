<template>
  <div>
    <div class="hotel-art-work-img"></div>
    <HotelSearchBar />
    <MCard class="m-card-hotel-search">
      <MTypography type="headline">Quick Search</MTypography>
      <MTypography type="label">
        Showing results based on portal's top destination
      </MTypography>
      <div class="m-card-quick-search">
        <AgHotelQuickSearch
          v-for="(item, index) in quickSearchLocations"
          :key="index"
          :country-name="item?.display_name"
          :country-thumb="item?.image"
          :description="item?.description"
          @click="handleQuickSearchClick(item)"
          class="quick-search-item"
        />
      </div>
    </MCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HotelSearchBar from "@/modules/Hotel/components/HotelSearchBar.vue";
import { HotelSearchSuggestion } from "@/ag-portal-common/types/hotel";
import { getPreHotelsSuggestions } from "@/ag-portal-common/constants/preHotelSuggestions";
import { addDays, format } from "date-fns";
import { PATH } from "@/ag-portal-common/constants/path";
import { formatQueryPath } from "@/ag-portal-common/utils/helpers";
import analyticsService from "@/analytic.service";
import { HOTEL_ANALYTICS_EVENTS } from "../constants/analyticsEvents";
import { MCard, MTypography } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "HotelSearch",
  components: { HotelSearchBar, MCard, MTypography },
  data(): {
    quickSearchLocations: Array<HotelSearchSuggestion>;
  } {
    return {
      quickSearchLocations: [],
    };
  },
  methods: {
    handleQuickSearchClick(selectedLocation: HotelSearchSuggestion) {
      const adult_count = 1;
      const checkin_date = addDays(new Date(), 10);
      const checkout_date = addDays(new Date(), 12);
      const query = this.formatQuery(
        adult_count,
        [],
        selectedLocation,
        checkin_date,
        checkout_date
      );
      const path = `${PATH.HOTELS_SEARCH_RESULT}${formatQueryPath(query)}`;

      const payload = {
        to: `${selectedLocation.display_name}, ${selectedLocation.sub_display_name}`,
        type: selectedLocation.type,
        "check-in": checkin_date.toLocaleString(),
        "check-out": checkout_date.toLocaleString(),
        "adult-travler-count": 1,
        "child-travler-count": 1,
      };

      analyticsService.logActionEvent(
        HOTEL_ANALYTICS_EVENTS.HOTEL_QUICK_SEARCH,
        payload
      );

      this.$router.push(path);
    },
    formatQuery(
      adult_count: number,
      child_age_count: Array<number>,
      selectedLocation: HotelSearchSuggestion,
      checkin_date: Date,
      checkout_date: Date
    ) {
      const query = {
        name: selectedLocation.display_name,
        sub_name: selectedLocation.sub_display_name,
        destination: selectedLocation.search_id,
        type: selectedLocation.type,
        checkin: format(checkin_date, "yyyy-MM-dd"),
        checkout: format(checkout_date, "yyyy-MM-dd"),
        adult: adult_count,
        ...(child_age_count?.length && { child: child_age_count.toString() }),
      };
      return query;
    },
  },
  mounted() {
    const locations: Array<HotelSearchSuggestion> = getPreHotelsSuggestions();
    const formattedLocations = locations?.map((item: HotelSearchSuggestion) => {
      return {
        ...item,
        label: `${item.display_name}, ${item.sub_display_name}`,
      };
    });
    this.$store.commit("saveLocations", formattedLocations);
    this.quickSearchLocations = locations;
  },
  unmounted() {
    this.$store.commit("toggleIsPropertiesFetching", false);
  },
});
</script>

<style lang="css" scoped>
.m-card-hotel-search {
  margin-top: 20px;
}
.m-card-quick-search {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
}

.quick-search-item {
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 1500px) {
  .m-card-quick-search {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1214px) {
  .m-card-quick-search {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 930px) {
  .m-card-quick-search {
    grid-template-columns: 1fr;
  }
}

.hotel-art-work-img {
  background-image: url("@/assets/HotelsArtWork.svg");
  width: 100%;
  height: 220px;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}
</style>
