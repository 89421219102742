import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { formatString } from "@/ag-portal-common/utils/helpers";
import { AddVisaRequestDTO } from "../dto/addVisaRequest.dto";
import { VISA_VERIFICATION_API_PATH } from "../constants";
import loggerService from "@/ag-portal-common/services/logger.service";
import download from "downloadjs";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";

class VisaVerificationService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public addVisaRequest(
    payload: AddVisaRequestDTO,
    organizationId: string
  ): Promise<IAGResponse<any>> {
    return this.restClient.post(
      formatString(VISA_VERIFICATION_API_PATH.ADD_VISA_REQUEST, organizationId),
      payload
    );
  }
  public getVisaRequests(organizationId: string): Promise<IAGResponse<any>> {
    return this.restClient.get(
      formatString(VISA_VERIFICATION_API_PATH.GET_VISA_REQUESTS, organizationId)
    );
  }
  public async downloadVisaPDF(id: string, watermarkPosition: string) {
    const methodName = "visaVerification.downloadVisaPDF";

    try {
      const url = formatString(
        VISA_VERIFICATION_API_PATH.DOWNLOAD_VERIFIED_VISA,
        id
      );
      const params = {
        ...(watermarkPosition && { watermark_position: watermarkPosition }),
      };
      const response: IAGResponse = await this.restClient.get(url, params, {
        responseType: "blob",
      });
      download(response.data, "Visa Ok To Board.pdf");
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
  }

  public async openUrlDownloadVisaPDF(name: string, id: string) {
    const url = formatString(
      VISA_VERIFICATION_API_PATH.OPEN_DOWNLOAD_VERIFIED_VISA,
      name,
      id
    );
    return this.restClient.get(
      url,
      {},
      {
        responseType: "blob",
      }
    );
  }
}

export default VisaVerificationService;
