import { HotelsData, ItineraryInfo } from "../constants";

const state: IFITState = {
  isFetchingFitHotels: false,
  hotelData: null,
  isSendingFITEmail: false,
  previewItems: null,
  emailItems: null,
};

export interface IFITState {
  isFetchingFitHotels: boolean;
  hotelData: HotelsData | null;
  isSendingFITEmail: boolean;
  previewItems: ItineraryInfo | null;
  emailItems: ItineraryInfo[] | null;
}

export default state;
