<template>
  <div class="reset-pass-page-view">
    <MCard class="reset-pass-card">
      <form
        @submit.prevent="onSubmitResetPasswordFormHandler"
        class="reset-pass-form"
      >
        <img
          :src="getOrgLogoHandler()"
          alt="organisation-logo"
          class="org-logo"
        />

        <MTypography type="title"> Reset Your Password? </MTypography>

        <MTypography> Enter your password below </MTypography>

        <MTextfield
          v-model:input-value="resetPassInformation.newPassword.value"
          type="password"
          label="New Password"
          placeholder="Enter your current password"
          autocomplete="current-password"
          :has-error="resetPassInformation.newPassword.hasError"
          :error-message="resetPassInformation.newPassword.errorMessage"
          :disabled="isResettingPassword"
        />

        <MTextfield
          v-model:input-value="resetPassInformation.confirmPassword.value"
          type="password"
          label="Confirm Password"
          placeholder="Confirm your new password"
          autocomplete="new-password"
          :has-error="resetPassInformation.confirmPassword.hasError"
          :error-message="resetPassInformation.confirmPassword.errorMessage"
          :disabled="isResettingPassword"
        />

        <MButton
          type="text"
          class="btn-link"
          :disabled="isResettingPassword"
          @click="onNavigateToLoginViewHandler"
        >
          Back To Login
        </MButton>

        <MButton
          behaviour="submit"
          class="btn-submit"
          :disabled="infoHasErrors() || isResettingPassword"
        >
          Submit
        </MButton>
      </form>
    </MCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MCard, MTypography, MTextfield, MButton } from "@aeroglobe/ag-core-ui";
import { AUTH_ACTIONS } from "../vuex/actions";
import { AUTH_GETTERS } from "../vuex/getters";
import { Organization, User } from "../types";
import { AUTH_ANALYTICS_EVENTS } from "../constants/analyticsEvents";
import analyticServices from "@/analytic.service";

export default defineComponent({
  name: "ResetPasswordView",
  components: { MCard, MTypography, MTextfield, MButton },
  created() {
    this.onPageTitleUpdateHandler();
  },
  mounted() {
    const htmlElement = document.querySelector("html");

    if (htmlElement) {
      htmlElement.style.overflow = "auto";
    }
  },
  unmounted() {
    const htmlElement = document.querySelector("html");

    if (htmlElement) {
      htmlElement.style.overflow = "hidden";
    }

    this.handleDataReset();
  },
  data() {
    return {
      resetPassInformation: {
        newPassword: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        confirmPassword: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      },

      validateResetInfoInfoErrors: false,
    };
  },
  computed: {
    user(): User | null {
      return this.$store.getters[AUTH_GETTERS.USER];
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    isResettingPassword(): boolean {
      return this.$store.getters[AUTH_GETTERS.IS_RESETTING_PASSWORD];
    },
  },
  methods: {
    infoHasErrors() {
      const transformedArray = Object.keys(this.resetPassInformation).map(
        (key) => (this.resetPassInformation as any)[key]
      );

      this.handleResetPassInfoErrors();

      return transformedArray.some((item) => item.hasError);
    },
    handleResetPassInfoErrors() {
      if (this.validateResetInfoInfoErrors) {
        const { newPassword, confirmPassword } = this.resetPassInformation;
        const passwordRegex = new RegExp(
          "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"
        );

        if (newPassword.value === "") {
          newPassword.hasError = true;
          newPassword.errorMessage = "Password is required";
        } else if (!passwordRegex.test(newPassword.value)) {
          newPassword.hasError = true;
          newPassword.errorMessage =
            "Must Contain 8 Characters, One Special, One Uppercase, One Lowercase, and One Number";
        } else {
          newPassword.hasError = false;
          newPassword.errorMessage = "";
        }

        if (confirmPassword.value === "") {
          confirmPassword.hasError = true;
          confirmPassword.errorMessage = "Confirm Password is required";
        } else if (newPassword.value !== confirmPassword.value) {
          confirmPassword.hasError = true;
          confirmPassword.errorMessage = "Both passwords must match";
        } else {
          confirmPassword.hasError = false;
          confirmPassword.errorMessage = "";
        }
      }
    },
    handleDataReset() {
      this.resetPassInformation = {
        newPassword: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        confirmPassword: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      };

      this.validateResetInfoInfoErrors = false;
    },
    async onSubmitResetPasswordFormHandler() {
      this.validateResetInfoInfoErrors = true;

      this.handleResetPassInfoErrors();

      if (this.infoHasErrors()) {
        return;
      }

      const queryToken = this.$route.query.token;
      const token = queryToken ?? "";

      const payload = {
        new_password: this.resetPassInformation.confirmPassword.value,
        token,
      };

      await this.$store.dispatch(AUTH_ACTIONS.RESET_PASSWORD, payload);

      analyticServices.logActionEvent(
        AUTH_ANALYTICS_EVENTS.RESET_PASSWORD,
        payload
      );

      this.handleResetPassInfoErrors();
    },

    onPageTitleUpdateHandler() {
      document.title = "Reset Password";
    },
    getOrgLogoHandler(): string {
      const DEFAULT_LOGO = "../../assets/logo.svg";
      const organization = this.organization;
      const org_logo = organization ? organization.org_logo : null;

      return org_logo ?? DEFAULT_LOGO;
    },
    onNavigateToLoginViewHandler() {
      this.$router.push("/");
    },
  },
});
</script>

<style lang="css">
.reset-pass-page-view {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #000 !important;
  }
}
</style>
<style lang="css" scoped>
.reset-pass-page-view {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  padding: 20px;

  background-color: color-mix(in srgb, var(--m-primary-color), transparent 90%);

  display: flex;
  align-items: center;
  justify-content: center;

  .reset-pass-card {
    width: 100%;
    max-width: 400px;

    padding: 30px;
    border-radius: calc(30px);

    box-shadow: 0 0 30px 0 var(--m-divider-color);
  }

  .reset-pass-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .org-logo {
      max-width: 200px;
      margin: 20px auto;
      user-select: none;
      pointer-events: none;
    }

    .m-textfield {
      margin-bottom: 0;
    }

    .btn-link {
      padding-left: 0;
      padding-right: 0;
      align-self: flex-end;
    }

    .btn-submit {
      width: 100%;
    }
  }
}
</style>
