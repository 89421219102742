import { StatusCodes } from "http-status-codes";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import AuthService from "@/modules/Auth/services/auth.service";
import { AUTH_EVENTS, authBus } from "@/ag-portal-common/eventBusses/auth";
import {
  ChangePasswordDto,
  ForgotPasswordDto,
  LoginDto,
  SignupDto,
  ResetPasswordDto,
} from "@/modules/Auth/dtos/auth.dto";
import { ActionTree } from "vuex";
import { IAuthState } from "@/ag-portal-common/interfaces/authState.interface";
import { IAGErrorResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import analyticsService from "@/analytic.service";
import { StoreState } from "@/store/type";
import UTILS from "@/ag-portal-common/utils";
import { LoginResponse } from "../types";
import { AUTH_MUTATIONS } from "./mutations";
import { AUTH_ANALYTICS_EVENTS } from "../constants/analyticsEvents";

export enum AUTH_ACTIONS {
  /**
   * This action handles user login.
   * @param context - The action context.
   * @param {LoginDto} payload - The login data transfer object.
   * @example
   * // Dispatch the action
   * this.$store.dispatch(AUTH_ACTIONS.LOGIN, loginDto);
   */
  LOGIN = "LOGIN",

  /**
   * This action handles user signup.
   * @param context - The action context.
   * @param {Object} payload - The signup payload and callback.
   * @param {SignupDto} payload.payload - The signup data transfer object.
   * @param {Function} payload.callback - The callback function to execute after signup.
   * @example
   * // Dispatch the action
   * this.$store.dispatch(AUTH_ACTIONS.SIGNUP, { payload: signupDto, callback });
   */
  SIGNUP = "SIGNUP",

  /**
   * This action handles forgot password process.
   * @param context - The action context.
   * @param {ForgotPasswordDto} payload - The forgot password data transfer object.
   * @example
   * // Dispatch the action
   * this.$store.dispatch(AUTH_ACTIONS.FORGOT_PASSWORD, forgotPasswordDto);
   */
  FORGOT_PASSWORD = "FORGOT_PASSWORD",

  /**
   * This action handles reset password process.
   * @param context - The action context.
   * @param {ResetPasswordDto} payload - The reset password data transfer object.
   * @example
   * // Dispatch the action
   * this.$store.dispatch(AUTH_ACTIONS.RESET_PASSWORD, resetPasswordDto);
   */
  RESET_PASSWORD = "RESET_PASSWORD",

  /**
   * This action handles change password process.
   * @param context - The action context.
   * @param {ChangePasswordDto} payload - The change password data transfer object.
   * @example
   * // Dispatch the action
   * this.$store.dispatch(AUTH_ACTIONS.CHANGE_PASSWORD, changePasswordDto);
   */
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
}

const actions: ActionTree<IAuthState, StoreState> = {
  async [AUTH_ACTIONS.LOGIN](context, payload: LoginDto): Promise<void> {
    loggerService.logInfo(`Login: ${LOG_LABELS.INITIATED}`);

    try {
      context.commit(AUTH_MUTATIONS.TOGGLE_IS_AUTHENTICATING, true);

      const authService = new AuthService();
      const response = await authService.login(payload);

      if (
        response.success &&
        response.status === StatusCodes.OK &&
        response.data
      ) {
        const data: LoginResponse = response.data;

        context.commit(AUTH_MUTATIONS.SAVE_LOGIN_RESPONSE, data);

        authBus.emit(AUTH_EVENTS.LOGIN, data);

        UTILS.notify("Successfully Logged In", NOTIFICATION_TYPES.SUCCESS);

        loggerService.logInfo(`Login: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      UTILS.notify(
        exception.error || exception.message,
        NOTIFICATION_TYPES.ERROR
      );

      loggerService.logError(`Login: ${LOG_LABELS.ERROR}`, exception);
    } finally {
      context.commit(AUTH_MUTATIONS.TOGGLE_IS_AUTHENTICATING, false);
    }
  },

  async [AUTH_ACTIONS.SIGNUP](
    context,
    { payload, callback }: { payload: SignupDto; callback: () => void }
  ) {
    context.commit(AUTH_MUTATIONS.TOGGLE_IS_AUTHENTICATING, true);

    const methodName = "actions.signup";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

      const authService = new AuthService();

      const response = await authService.signup(payload);

      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        analyticsService.logActionEvent(AUTH_ANALYTICS_EVENTS.SIGNUP, payload);
        callback();
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      UTILS.notify(
        exception.error || exception.message,
        NOTIFICATION_TYPES.ERROR
      );

      loggerService.logError(`${methodName}:`, exception);
    } finally {
      context.commit(AUTH_MUTATIONS.TOGGLE_IS_AUTHENTICATING, false);
    }
  },

  async [AUTH_ACTIONS.FORGOT_PASSWORD](
    context,
    payload: ForgotPasswordDto
  ): Promise<void> {
    loggerService.logInfo(`Forgot Password: ${LOG_LABELS.INITIATED}`);

    try {
      context.commit(AUTH_MUTATIONS.TOGGLE_IS_AUTHENTICATING, true);

      const authService = new AuthService();
      const response = await authService.forgotPassword(payload);

      if (
        response.success &&
        response.status === StatusCodes.OK &&
        response.data
      ) {
        const message = `Change Password link sent successfully to ${payload.email}`;

        UTILS.notify(message, NOTIFICATION_TYPES.SUCCESS);

        loggerService.logInfo(`Forgot Password: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      UTILS.notify(
        exception.error || exception.message,
        NOTIFICATION_TYPES.ERROR
      );

      loggerService.logError(`Reset Password: ${LOG_LABELS.ERROR}`, exception);
    } finally {
      context.commit(AUTH_MUTATIONS.TOGGLE_IS_AUTHENTICATING, false);
    }
  },

  async [AUTH_ACTIONS.RESET_PASSWORD](
    context,
    payload: ResetPasswordDto
  ): Promise<void> {
    loggerService.logInfo(`Reset Password: ${LOG_LABELS.INITIATED}`);

    try {
      context.commit(AUTH_MUTATIONS.TOGGLE_IS_RESETTING_PASSWORD, true);

      const authService = new AuthService();
      const response = await authService.resetPassword(payload);

      if (
        response.success &&
        response.status === StatusCodes.OK &&
        response.data
      ) {
        const message = "Password Reseted Successfully";

        UTILS.notify(message, NOTIFICATION_TYPES.SUCCESS);

        loggerService.logInfo(`Reset Password: ${LOG_LABELS.ENDED}`, response);

        window.location.replace("/");
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      UTILS.notify(
        exception.error || exception.message,
        NOTIFICATION_TYPES.ERROR
      );

      loggerService.logError(`Reset Password: ${LOG_LABELS.ERROR}`, exception);
    } finally {
      context.commit(AUTH_MUTATIONS.TOGGLE_IS_RESETTING_PASSWORD, false);
    }
  },

  async [AUTH_ACTIONS.CHANGE_PASSWORD](_, payload: ChangePasswordDto) {
    try {
      const authService = new AuthService();
      const response = await authService.changePassword(payload);

      if (response.success) {
        UTILS.notify(
          NOTIFICATION_MESSAGES.RESET_PASSWORD_SUCCESS,
          NOTIFICATION_TYPES.SUCCESS
        );
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      UTILS.notify(
        exception.message || exception.error,
        NOTIFICATION_TYPES.ERROR
      );
    }
  },
};

export default actions;
