<template>
  <div class="trip-type-radio-btn-container">
    <MRadioButton
      v-model="selectedRouteType"
      name="trip-type"
      :value="ROUTE_TYPE.ONEWAY"
    >
      One-Way
    </MRadioButton>
    <MRadioButton
      v-model="selectedRouteType"
      name="trip-type"
      :value="ROUTE_TYPE.RETURN"
    >
      Round-Trip
    </MRadioButton>
    <MRadioButton
      v-model="selectedRouteType"
      name="trip-type"
      :value="ROUTE_TYPE.MULTI_CITY"
    >
      Multi-City
    </MRadioButton>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { OptionValue } from "../../types/OptionValue";
import { ROUTE_TYPE } from "@/ag-flight-components/enums/route_type";
import { MRadioButton } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "FlightSearchOptions",
  components: {
    MRadioButton,
  },
  props: {
    routeType: {
      type: String as PropType<ROUTE_TYPE>,
      default: ROUTE_TYPE.ONEWAY,
    },
  },
  data() {
    return {
      ROUTE_TYPE,
      selectedRouteType: ROUTE_TYPE.ONEWAY,
    };
  },
  watch: {
    selectedRouteType: {
      handler(value: OptionValue) {
        this.$store.commit("saveRouteType", value);
      },
    },
    routeType: {
      handler(value: ROUTE_TYPE) {
        this.selectedRouteType = value;
      },
      immediate: true,
    },
  },
});
</script>

<style scoped>
.trip-type-radio-btn-container {
  display: flex;
  gap: 20px;
  align-items: center;
}

@media (max-width: 767px) {
  .trip-type-radio-btn-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>
