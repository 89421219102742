<template>
  <ag-row>
    <ag-column xs="12" sm="12" md="1" cols="12">
      <div class="flight_count">Flight {{ index + 1 }}</div>
    </ag-column>
    <ag-column xs="12" sm="12" md="7" cols="12">
      <div
        id="flight_search_box"
        class="combine_flight_search combobox-container"
      >
        <ag-row test-id="">
          <ag-column xs="12" sm="12" md="6" cols="12">
            <MAirportComboBox
              :inputValue="selectedOriginIata"
              label="From"
              itemValue="value"
              itemLabel="label"
              label-icon="m-cancel"
              :options="renderOriginPlaces"
              prepend-icon="m-flight-departure-icon"
              placeholder="Leave from"
              @update:inputValue="onSelectOrigin(index, $event)"
              :error-message="originError"
              :has-error="!!originError"
              @update:inputText="handleOriginMAirportInputText"
              :is-loading="isOriginInputLoading"
            />
          </ag-column>
          <div
            @click="swapFields"
            class="m-swap-flights-icon-container m-index-level-one"
            :class="{ flipped: swapIconFlipped }"
            variant="link"
          >
            <MIcon name="m-swap-flight-icon" />
          </div>
          <ag-column xs="12" sm="12" md="6" cols="12">
            <MAirportComboBox
              :inputValue="selectedDestinationIata"
              label="To"
              itemValue="value"
              itemLabel="label"
              label-icon="m-cancel"
              :options="renderDestinationPlaces"
              prepend-icon="m-flight-arrival-icon"
              placeholder="Going to"
              @update:inputValue="onSelectDestination(index, $event)"
              @update:input-text="handleDestinationMAirportInputText"
              :error-message="destinationError"
              :has-error="!!destinationError"
              :is-loading="isDestinationInputLoading"
            />
          </ag-column>
        </ag-row>
      </div>
    </ag-column>
    <ag-column xs="12" sm="12" md="3" cols="12">
      <div>
        <ag-row>
          <ag-column xs="12" sm="12" md="12" cols="12">
            <MDatePicker
              label="Departure Date"
              :startDate="multicityLeg!.departure_date"
              :min-date="minDate"
              @onRangeDateChange="changeDepartureDate(index, $event)"
              placeholder="Departuring"
              dateType="startDate"
              :multiCalendar="isMultiCalendar"
              @handleDatePickerFocused="handleDepartureDatePickerFocused"
              :isFocused="isDepartureDatePickerFocused"
              :autoPosition="false"
              position="right"
            />
          </ag-column>
        </ag-row>
      </div>
    </ag-column>
    <ag-column xs="12" sm="12" md="1" cols="12" class="multicity-delete-btn">
      <MIcon
        v-show="index > 1"
        @click="deleteFlight(index)"
        class="multicity-delete-row-icon"
        name="m-outlined-cancel"
      />
    </ag-column>
  </ag-row>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { debounce } from "lodash";

import { MAirportComboBox, MDatePicker, MIcon } from "@aeroglobe/ag-core-ui";
import { MultiCityLegStateType } from "../../types/Flights";
import { Place } from "../../types/Place";
import { IMultiCityAirports } from "@/ag-flight-components/interfaces/IFlightSearch.interface";
import { useIsMobile } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "MulticityFlightItem",
  components: {
    MAirportComboBox,
    MDatePicker,
    MIcon,
  },
  data() {
    return {
      departure_date: new Date(),
      originAirportQuery: "",
      destinationAirportQuery: "",
      selectedOriginIata: "",
      selectedDestinationIata: "",
      swapIconFlipped: false,
      fieldErrors: {},
      isMobile: false,
      isDepartureDatePickerFocused: false,
    };
  },
  props: {
    multicityLeg: { type: Object as PropType<MultiCityLegStateType> },
    index: { type: Number, default: 0 },
    errors: {
      type: Object,
    },
  },
  computed: {
    originError(): string | undefined {
      return this.errors?.[`[${this.index}].origin`];
    },
    destinationError(): string | undefined {
      return this.errors?.[`[${this.index}].destination`];
    },
    renderOriginPlaces(): Place[] {
      const multiCityAirports = this.$store.getters
        .multiCityAirports as Array<IMultiCityAirports>;
      const airports = multiCityAirports[this.index]?.origin || [];
      const selectedDestination = this.$store.state.flightModule.multiCityLegs[
        this.index
      ]?.destination as Place;
      if (selectedDestination) {
        return airports.filter(
          (x) => x?.iata_code !== selectedDestination?.iata_code
        );
      }
      return airports;
    },
    renderDestinationPlaces(): Place[] {
      const multiCityAirports = this.$store.getters
        .multiCityAirports as Array<IMultiCityAirports>;
      const airports = multiCityAirports[this.index]?.destination || [];
      const selectedOrigin = this.$store.state.flightModule.multiCityLegs[
        this.index
      ]?.origin as Place;
      if (selectedOrigin) {
        return airports.filter(
          (x) => x?.iata_code !== selectedOrigin?.iata_code
        );
      }
      return airports;
    },
    minDate(): Date {
      const allLegs = this.$store.state.flightModule.multiCityLegs;

      return this.index > 0
        ? allLegs[this.index - 1].departure_date
        : new Date();
    },
    isOriginInputLoading(): boolean {
      const type = this.$store.getters.multicityInputLoadingType;
      const index = this.$store.getters.multicityInputLoadingIndex;
      return type === "origin" && this.index === (index as number);
    },
    isDestinationInputLoading(): boolean {
      const type = this.$store.getters.multicityInputLoadingType;
      const index = this.$store.getters.multicityInputLoadingIndex;
      return type === "destination" && this.index === (index as number);
    },
    selectedOrigin(): Place | null {
      return this.$store.state.flightModule.multiCityLegs[this.index]?.origin;
    },
    selectedDeparture(): Place | null {
      return this.$store.state.flightModule.multiCityLegs[this.index]
        ?.destination;
    },
    isMultiCalendar(): boolean {
      return this.isMobile ? false : true;
    },
  },
  methods: {
    swapFields() {
      this.swapIconFlipped = !this.swapIconFlipped;
      const multiCityAirports = this.$store.getters
        .multiCityAirports as Array<IMultiCityAirports>;

      const tempOrigin =
        this.$store.state.flightModule.multiCityLegs[this.index]?.origin;
      const tempDestination =
        this.$store.state.flightModule.multiCityLegs[this.index]?.destination;
      const originAirports = multiCityAirports[this.index].origin || [];
      const destinationAirports =
        multiCityAirports[this.index].destination || [];

      this.$store.commit("setMulticityAirports", {
        airports: destinationAirports,
        type: "origin",
        index: this.index,
      });
      this.$store.commit("setMulticityAirports", {
        airports: originAirports,
        type: "destination",
        index: this.index,
      });

      const tempOriginIata = this.selectedOriginIata;
      const tempDestinationIata = this.selectedDestinationIata;
      this.selectedOriginIata = tempDestinationIata;
      this.selectedDestinationIata = tempOriginIata;

      if (tempDestination) {
        this.onSelectOrigin(this.index, tempDestination);
      }

      if (tempOrigin) {
        this.onSelectDestination(this.index, tempOrigin);
      }
    },
    changeDepartureDate(index: number, date: Date) {
      this.$store.commit("updateMulticityDepartureDate", {
        index,
        date,
      });
    },
    onSelectOrigin(index: number, selectedAirport: Place) {
      this.selectedOriginIata = selectedAirport?.iata_code;
      this.$store.commit("updateMulticityOrigin", {
        index,
        origin: selectedAirport,
      });
      this.$emit("validateFields");
    },
    onSelectDestination(index: number, selectedAirport: Place) {
      this.selectedDestinationIata = selectedAirport?.iata_code;
      this.$store.commit("updateMulticityDestination", {
        index,
        destination: selectedAirport,
      });
      this.$emit("validateFields");
    },
    searchOriginAirportQuery(value: string) {
      this.originAirportQuery = value;
    },
    searchDestinationAirportQuery(value: string) {
      this.destinationAirportQuery = value;
    },
    searchOriginAirports(value: string) {
      if (value && value.length > 2) {
        this.$store.dispatch("fetchMultiCityAirports", {
          location: value,
          type: "origin",
          index: this.index,
        });
      }
    },
    searchDestinationAirports(value: string) {
      if (value && value.length > 2) {
        this.$store.dispatch("fetchMultiCityAirports", {
          location: value,
          type: "destination",
          index: this.index,
        });
      }
    },
    deleteFlight(index: number) {
      this.$store.commit("deleteMulticityFlight", index);
    },
    handleOriginMAirportInputText(value: string) {
      if (value) {
        this.originAirportQuery = value;
      }
    },
    handleDestinationMAirportInputText(value: string) {
      if (value) {
        this.destinationAirportQuery = value;
      }
    },
    handleDepartureDatePickerFocused(value: boolean) {
      this.isDepartureDatePickerFocused = value;
    },
  },
  watch: {
    originAirportQuery: {
      handler: debounce(function (value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.searchOriginAirports(value);
      }, 500),
      immediate: true,
    },
    destinationAirportQuery: {
      handler: debounce(function (value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.searchDestinationAirports(value);
      }, 500),
      immediate: true,
    },
    selectedOrigin: {
      handler: function (value: Place) {
        this.selectedOriginIata = value?.iata_code || "";
      },
      immediate: true,
    },
    selectedDeparture: {
      handler: function (value: Place) {
        this.selectedDestinationIata = value?.iata_code || "";
      },
      immediate: true,
    },
    errors(errors: any) {
      this.fieldErrors = errors;
    },
  },
  created() {
    const { isMobile } = useIsMobile();
    this.isMobile = isMobile.value;
  },
});
</script>

<style scoped lang="scss">
.combine_flight_search {
  position: relative;
  .flipped {
    transform: rotate(180deg);
  }
  .m-swap-flights-icon-container {
    background-color: #fff !important;
    border: 0.75px solid #ababab;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    max-height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border-radius: 50%;
    padding-top: 9px;
    padding-left: 9px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: rgb(228, 228, 228);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    svg > path {
      fill: #ababab;
    }

    .btn-loading {
      svg path {
        fill: #ababab !important;
      }
    }
  }
}
.flight_count {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 19px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.multicity-delete-row-icon {
  padding: 16px;
  display: block;
  cursor: pointer;
  color: #898787;
  &:hover {
    color: var(--m-secondary-color);
  }
}

@media (max-width: 767px) {
  .multicity-delete-btn {
    display: flex;
    justify-content: center;
  }
}
</style>
