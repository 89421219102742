import { IVisaVerificationState } from "@/ag-portal-common/interfaces/visaVerificationState.interface";

const visaVerificationModule: IVisaVerificationState = {
  isAddingRequestLoading: false,
  isFetchingRequestLoading: false,
  isDownloadVisaLoading: false,
  visaRequestsList: null,
};

export default visaVerificationModule;
