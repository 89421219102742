<template>
  <UpdatePassengerShimmer v-if="isLoading" />

  <TravelersComponent
    v-else
    :travelers="travelers"
    ref="travelersComponent"
    @update:travelers="updateTravelers"
    @submit="handleTravelersSubmit"
  />
  <MCard class="confirm-bottom-btn-container">
    <MButton :disabled="isLoading" @click="closeUpdatePassengers"
      >Close</MButton
    >
    <MButton :disabled="isLoading" @click="handleSubmit"
      >Update Passengers</MButton
    >
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MCard, MButton } from "@aeroglobe/ag-core-ui";
import UpdatePassengerShimmer from "./UpdatePassengersShimmer.vue";
import { IFDGBookingPassenger } from "@/interfaces/fdgBookingDetail.interface";
import TravelersComponent from "@/ag-flight-components/components/FlightBooking/NewTravelerFormComponent.vue";
import { TravelerTypes } from "@/ag-flight-components/constants/TravelerTypes";
import { ITraveler } from "@/ag-flight-components/interfaces/IGroupFlight.interface";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { FDGUpdatePassengerRequest } from "../models/fdgUpdatePassenger.request";

export default defineComponent({
  name: "UpdateGroupFlightPassengers",
  components: {
    UpdatePassengerShimmer,
    TravelersComponent,
    MCard,
    MButton,
  },
  props: {
    isOpen: {
      type: Boolean,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      error: "",
      travelers: [] as ITraveler[],
    };
  },
  computed: {
    isLoading(): boolean {
      return this.$store.getters.isGroupBookingPassengersFetching;
    },
    passengers(): IFDGBookingPassenger[] {
      return this.$store.getters.groupBookingPassengers;
    },
    bookingId(): string {
      return this.$route.params.id as string;
    },
  },
  methods: {
    closeUpdatePassengers() {
      this.$emit("close");
    },
    formattedDate(date: Date | string | null): string {
      if (date) {
        return getFormattedDateTime(new Date(date), FORMAT_YYY_MM_DD);
      }
      return "";
    },
    formatTravelers(passengers: IFDGBookingPassenger[]): ITraveler[] {
      const travelers = passengers.map((item: IFDGBookingPassenger) => {
        const title = item.salutation === "UNDEFINED" ? "MR" : item.salutation;
        return {
          passengerType: TravelerTypes.ADULT,
          title: title,
          firstName: item.first_name,
          lastName: item.last_name,
          dateOfBirth: item.date_of_birth,
          gender: item?.gender || "M",
          nationality: item.nationality,
          passportNumber: item.passport_no,
          passportExpiry: item.passport_expiry,
          idNumber: "",
          isInternational: true,
        };
      });
      return travelers;
    },
    successCallback() {
      this.closeUpdatePassengers();
      this.$store.dispatch("fetchGroupFlightBookingDetail", this.bookingId);
    },
    handleSubmit() {
      const travelerComponent = this.$refs.travelersComponent as any;
      travelerComponent.handleSubmit();
      if (travelerComponent.isFormValid) {
        const passengers_list = this.travelers.map((item, index) => {
          const publicID = this.passengers[index]?.public_id || null;
          return {
            salutation: item.title,
            passenger_relation: "LEAD",
            first_name: item.firstName,
            last_name: item.lastName,
            passport_no: item.passportNumber,
            nationality: item.nationality,
            public_id: publicID,
            passport_expiry: item.passportExpiry
              ? this.formattedDate(item.passportExpiry)
              : null,
            date_of_birth: item.dateOfBirth
              ? this.formattedDate(item.dateOfBirth)
              : null,
            gender: item.gender,
          } as IFDGBookingPassenger;
        });
        const payload: FDGUpdatePassengerRequest = {
          passengers_list,
        };
        this.$store.dispatch("updateGroupBookingPassengers", {
          booking_id: this.bookingId,
          payload,
          callback: this.successCallback,
        });
      } else {
        this.scrollToFirstError();
      }
    },
    scrollToFirstError() {
      (this.$refs.travelersComponent as any).navigateToFirstError();
    },
    updateTravelers(updatedTravelers: ITraveler[]) {
      this.travelers = updatedTravelers;
    },
    handleTravelersSubmit(submittedTravelers: ITraveler[]) {
      this.travelers = submittedTravelers;
    },
  },
  mounted() {
    this.$store.dispatch("getGroupBookingPassengers", this.bookingId);
  },
  watch: {
    passengers: {
      handler: function (passengers: IFDGBookingPassenger[]) {
        if (passengers.length > 0) {
          const formattedTravelers = this.formatTravelers(passengers);
          this.travelers = formattedTravelers;
        }
      },
      immediate: true,
    },
  },
});
</script>
<style lang="scss" scoped>
.confirm-bottom-btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>
