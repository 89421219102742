import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a69c2e30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_3 = { class: "status-container" }
const _hoisted_4 = { class: "download-visa-modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MProgress = _resolveComponent("MProgress")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_MProgress, {
          key: 0,
          indeterminate: "",
          class: "mprogress-alignment"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_MCard, { class: "ok-to-board-heading" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_AgIconInfoBar, {
                  "test-id": "",
                  title: "Ok To Board",
                  class: "section-title",
                  mPrepandIcon: "m-issue-ticket"
                }),
                _createElementVNode("div", null, [
                  _createVNode(_component_MButton, {
                    variant: "filled",
                    onClick: _ctx.handleAddRequest
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Add Ok To Board Request")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_MCard, null, {
            default: _withCtx(() => [
              (!_ctx.visaVerificationList.length)
                ? (_openBlock(), _createBlock(_component_AgNotFound, {
                    key: 0,
                    "test-id": "",
                    heading: "No Ok To Board Request Found"
                  }))
                : (_openBlock(), _createBlock(_component_MDataTable, {
                    key: 1,
                    headers: _ctx.headers,
                    "is-loading": _ctx.isLoading,
                    data: _ctx.visaVerificationList,
                    "item-per-page": 10,
                    "has-search": true
                  }, {
                    status: _withCtx(({ item }) => [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_MChip, {
                          variant: _ctx.visaVerificationStatus(item.status)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.status), 1)
                          ]),
                          _: 2
                        }, 1032, ["variant"])
                      ])
                    ]),
                    action: _withCtx(({ item }) => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_MButton, {
                          onClick: ($event: any) => (_ctx.handleClickDownloadVisa(item.public_id)),
                          variant: "primary",
                          type: "filled",
                          disabled: _ctx.isDownloadBtnDisable(item.status)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Download Visa ")
                          ]),
                          _: 2
                        }, 1032, ["onClick", "disabled"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["headers", "is-loading", "data"]))
            ]),
            _: 1
          })
        ])),
    _createVNode(_component_MDialog, {
      modelValue: _ctx.showDownloadPDFDialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDownloadPDFDialog) = $event)),
      "max-width": "600",
      onClose: _ctx.closeDialog,
      title: "Download Visa PDF",
      class: "download-visa-modal m-scrollbar"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_MCombobox, {
            inputValue: _ctx.selectedStampPosition,
            "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStampPosition) = $event)),
            label: "Select Ok To Board Stamp Position",
            itemValue: "value",
            itemLabel: "label",
            options: _ctx.stampPositions,
            class: "stamp-alignment"
          }, null, 8, ["inputValue", "options"])
        ])
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_MButton, {
          disabled: _ctx.isDownloadVisaLoading,
          onClick: _ctx.handleDownloadPDF
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.downloadVisaText) + " Visa", 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      _: 1
    }, 8, ["modelValue", "onClose"])
  ], 64))
}