import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d23f9cae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex margin_bottom_10 margin_top_20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_a_g_text_field = _resolveComponent("a-g-text-field")!
  const _component_ag_chip = _resolveComponent("ag-chip")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createBlock(_component_MDialog, {
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isOpen) = $event)),
    onClose: _ctx.closeModal,
    title: "Airline Tickets",
    "max-width": "750"
  }, {
    content: _withCtx(() => [
      _createVNode(_component_MTypography, { class: "airline-tickets-content" }, {
        default: _withCtx(() => [
          _createTextVNode("Please type email in the box and press enter. Airline tickets will be sent on emails below. ")
        ]),
        _: 1
      }),
      _createVNode(_component_a_g_text_field, {
        label: "Email",
        testId: 
          _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'airline-ticket-modal-email')
        ,
        value: _ctx.emailField,
        onFocusout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emailFieldErrorMessage = '')),
        error: _ctx.emailFieldErrorMessage,
        modelValue: _ctx.emailField,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.emailField) = $event)),
        onKeydown: _withKeys(_ctx.updateEmails, ["enter"])
      }, null, 8, ["testId", "value", "error", "modelValue", "onKeydown"]),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emails, (email, index) => {
          return (_openBlock(), _createBlock(_component_ag_chip, {
            class: "margin_right_10",
            color: "success",
            key: index,
            testId: 
            _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'airline-ticket-modal-email-chip')
          ,
            closable: "",
            "onClick:close": ($event: any) => (_ctx.onRemoveEmail(index))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(email), 1)
            ]),
            _: 2
          }, 1032, ["testId", "onClick:close"]))
        }), 128))
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_MButton, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onSendAirlineTicket', _ctx.emails))),
        disabled: _ctx.$store.getters.isSendingAirlineTicket
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Send Airline Tickets ")
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 8, ["modelValue", "onClose"]))
}