import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60107551"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "old-price-quote-details" }
const _hoisted_2 = { class: "price-quote-detail-typographies" }
const _hoisted_3 = { class: "price-quote-detail-typographies" }
const _hoisted_4 = { class: "price-quote-detail-typographies" }
const _hoisted_5 = { class: "price-quote-detail-typographies" }
const _hoisted_6 = { class: "price-quote-detail-typographies" }
const _hoisted_7 = { class: "price-quote-detail-typographies" }
const _hoisted_8 = { class: "old-price-quote-details" }
const _hoisted_9 = { class: "price-quote-detail-typographies" }
const _hoisted_10 = { class: "price-quote-detail-typographies" }
const _hoisted_11 = { class: "price-quote-detail-typographies" }
const _hoisted_12 = { class: "price-quote-detail-typographies" }
const _hoisted_13 = { class: "price-quote-detail-typographies" }
const _hoisted_14 = { class: "price-quote-detail-typographies" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_RoundCheckCrossIcon = _resolveComponent("RoundCheckCrossIcon")!
  const _component_MAccordion = _resolveComponent("MAccordion")!

  return (_openBlock(), _createBlock(_component_MAccordion, {
    "initial-open": true,
    class: "m-accordion-fare-management"
  }, {
    title: _withCtx(() => [
      _createVNode(_component_AgIconInfoBar, {
        mPrepandIcon: "m-fare-management",
        title: "Old Price Quote",
        class: "maccordion-infobar"
      })
    ]),
    content: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOldPriceQuoteInfo, (passenger, index) => {
        return (_openBlock(), _createBlock(_component_MAccordion, {
          key: index,
          "initial-open": false,
          class: "m-accordion-fare-management"
        }, {
          title: _withCtx(() => [
            _createVNode(_component_AgIconInfoBar, {
              mPrepandIcon: _ctx.renderPassengerType(passenger.passenger_type).icon,
              title: _ctx.renderPassengerType(passenger.passenger_type).type,
              class: "maccordion-infobar"
            }, null, 8, ["mPrepandIcon", "title"])
          ]),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_MTypography, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Passenger Type")
                  ]),
                  _: 1
                }),
                _createVNode(_component_MTypography, { type: "label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(passenger.passenger_type), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_MTypography, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Base Fare")
                  ]),
                  _: 1
                }),
                _createVNode(_component_MTypography, { type: "label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getPassengerTypeCharges(passenger.price).base_fare), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_MTypography, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Fees")
                  ]),
                  _: 1
                }),
                _createVNode(_component_MTypography, { type: "label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getPassengerTypeCharges(passenger.price).fees), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_MTypography, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Total Taxes")
                  ]),
                  _: 1
                }),
                _createVNode(_component_MTypography, { type: "label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getPassengerTypeCharges(passenger.price).total_tax), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_MTypography, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Surcharges")
                  ]),
                  _: 1
                }),
                _createVNode(_component_MTypography, { type: "label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getPassengerTypeCharges(passenger.price).surcharges), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_MTypography, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Gross Fare")
                  ]),
                  _: 1
                }),
                _createVNode(_component_MTypography, { type: "label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getPassengerTypeCharges(passenger.price).gross_fare), 1)
                  ]),
                  _: 2
                }, 1024)
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_MDataTable, {
                headers: _ctx.taxTableHeaders,
                data: passenger.price.tax_list,
                "is-api-paginated": false,
                "has-search": false,
                "has-pagination": false
              }, {
                amount: _withCtx(({ item }) => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_MTypography, { type: "label" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formattedCurrency(item.currency, item.amount)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                tax_name: _withCtx(({ item }) => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_MTypography, { type: "label" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.tax_name ?? "-"), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                ticketing_tax_code: _withCtx(({ item }) => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_MTypography, { type: "label" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.ticketing_tax_code ?? "-"), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                _: 2
              }, 1032, ["headers", "data"])
            ]),
            _createVNode(_component_MTypography, { type: "headline" }, {
              default: _withCtx(() => [
                _createTextVNode("Price Per Passenger (Passenger Count: " + _toDisplayString(passenger.passenger_count) + ")", 1)
              ]),
              _: 2
            }, 1024),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_MTypography, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Passenger Type")
                  ]),
                  _: 1
                }),
                _createVNode(_component_MTypography, { type: "label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(passenger.passenger_type), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_MTypography, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Base Fare")
                  ]),
                  _: 1
                }),
                _createVNode(_component_MTypography, { type: "label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getPassengerTypeCharges(passenger.price_per_passenger).base_fare), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_MTypography, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Fees")
                  ]),
                  _: 1
                }),
                _createVNode(_component_MTypography, { type: "label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getPassengerTypeCharges(passenger.price_per_passenger).fees), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_MTypography, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Total Taxes")
                  ]),
                  _: 1
                }),
                _createVNode(_component_MTypography, { type: "label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getPassengerTypeCharges(passenger.price_per_passenger).total_tax), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_MTypography, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Surcharges")
                  ]),
                  _: 1
                }),
                _createVNode(_component_MTypography, { type: "label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getPassengerTypeCharges(passenger.price_per_passenger)
                  .surcharges), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_MTypography, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Gross Fare")
                  ]),
                  _: 1
                }),
                _createVNode(_component_MTypography, { type: "label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getPassengerTypeCharges(passenger.price_per_passenger)
                  .gross_fare), 1)
                  ]),
                  _: 2
                }, 1024)
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_MDataTable, {
                headers: _ctx.taxTableHeaders,
                data: passenger.price_per_passenger.tax_list,
                "is-api-paginated": false,
                "has-search": false,
                "has-pagination": false
              }, {
                amount: _withCtx(({ item }) => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_MTypography, { type: "label" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formattedCurrency(item.currency, item.amount)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                tax_name: _withCtx(({ item }) => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_MTypography, { type: "label" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.tax_name ?? "-"), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                ticketing_tax_code: _withCtx(({ item }) => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_MTypography, { type: "label" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.ticketing_tax_code ?? "-"), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                _: 2
              }, 1032, ["headers", "data"])
            ]),
            (passenger.segments.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createVNode(_component_MTypography, { type: "headline" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Segment Details")
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", null, [
                    _createVNode(_component_MDataTable, {
                      headers: _ctx.segmnetTableHeaders,
                      data: passenger.segments,
                      "is-api-paginated": false,
                      "has-search": false,
                      "has-pagination": false
                    }, {
                      baggage: _withCtx(({ item }) => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_MTypography, { type: "label" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.renderSegmentBaggageInfo(item.baggage)), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      sector: _withCtx(({ item }) => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_MTypography, { type: "label" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.renderSegmentSector(
                          item.departure_city,
                          item.arrival_city
                        )), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      departure_date_time: _withCtx(({ item }) => [
                        _createVNode(_component_MTypography, {
                          class: "description td-full-width",
                          type: "body"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(
                        item.departure_date_time,
                        _ctx.FORMAT_DD_MMM_YYYY_WITH_DASH
                      )), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_MTypography, {
                          class: "description",
                          type: "label"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(
                        item.departure_date_time,
                        _ctx.FORMAT_HH_SS_24_HOURS
                      )), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      arrival_date_time: _withCtx(({ item }) => [
                        _createVNode(_component_MTypography, {
                          class: "description td-full-width",
                          type: "body"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(
                        item.arrival_date_time,
                        _ctx.FORMAT_DD_MMM_YYYY_WITH_DASH
                      )), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_MTypography, {
                          class: "description",
                          type: "label"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(
                        item.arrival_date_time,
                        _ctx.FORMAT_HH_SS_24_HOURS
                      )), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["headers", "data"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (passenger.baggage_info.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createVNode(_component_MTypography, { type: "headline" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Baggage Details")
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", null, [
                    _createVNode(_component_MTypography, { type: "label" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(passenger.baggage_info
                    .map(
                      (info) =>
                        info.charAt(0).toUpperCase() +
                        info.slice(1).toLowerCase()
                    )
                    .join(", ")), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]))
              : _createCommentVNode("", true),
            (passenger.price_quote_policies.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createVNode(_component_MTypography, { type: "headline" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Price Quote Policies")
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", null, [
                    _createVNode(_component_MDataTable, {
                      headers: _ctx.policiesTableHeaders,
                      data: passenger.price_quote_policies,
                      "is-api-paginated": false,
                      "has-search": false,
                      "has-pagination": false
                    }, {
                      amount: _withCtx(({ item }) => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_MTypography, { type: "label" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.formattedCurrency(item.currency, item.amount)), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      cat_16: _withCtx(({ item }) => [
                        _createVNode(_component_RoundCheckCrossIcon, {
                          "is-check": item.cat_16
                        }, null, 8, ["is-check"])
                      ]),
                      not_applicable: _withCtx(({ item }) => [
                        _createVNode(_component_RoundCheckCrossIcon, {
                          "is-check": item.not_applicable
                        }, null, 8, ["is-check"])
                      ]),
                      _: 2
                    }, 1032, ["headers", "data"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}