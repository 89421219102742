import { RouteRecordRaw } from "vue-router";
import DashboardRoutes from "@/modules/Dashboard/route";
import AgentRoutes from "@/modules/Agent/route";
import OrganizationRoutes from "@/modules/Organization/route";
import SOARoutes from "@/modules/SOA/route";
import SabreSegmentRoutes from "@/modules/SabreSegment/route";
import MyBookingsRoutes from "@/modules/MyBookings/route";
import PaymentRoutes from "@/modules/Payments/route";
import CreditLimitManagementRoutes from "@/modules/CreditLimitManagement/route";
import NewsRoutes from "@/modules/News/route";
import fareManagementRoutes from "@/modules/FareManagement/route";
import agPricingRoutes from "@/modules/AgPricing/route";
import quotationManagement from "@/modules/ManageQuotations/route";
import { PATH } from "@/ag-portal-common/constants/path";
import UserProfileRoutes from "@/modules/Profile/route";
import SalesRoutes from "@/modules/Sales/route";
import Error403Route from "@/modules/403/route";
import Error404Route from "@/modules/404/route";
import DealsAndPromos from "@/modules/Deals/route";
import VisaVerification from "@/modules/VisaVerification/route";
import SnapShots from "@/modules/Snapshots/route";

const HomeRoutes: Array<RouteRecordRaw> = [
  {
    path: PATH.DASHBOARD,
    name: "dashboard",
    children: DashboardRoutes,
  },
  {
    path: PATH.AGENT_MANAGEMENT,
    name: "agents",
    children: AgentRoutes,
  },
  {
    path: PATH.ORGANIZATION,
    name: "organization",
    children: OrganizationRoutes,
  },
  {
    path: PATH.SOA,
    name: "soa",
    children: SOARoutes,
  },
  {
    path: PATH.MY_BOOKINGS,
    name: "myBookings",
    children: MyBookingsRoutes,
  },
  {
    path: PATH.PAYMENTS,
    name: "payments",
    children: PaymentRoutes,
  },
  {
    path: PATH.USER_PROFILE,
    name: "userProfile",
    children: UserProfileRoutes,
  },
  {
    path: PATH.CREDIT_LIMIT_MANAGEMENT,
    name: "creditLimitManagement",
    children: CreditLimitManagementRoutes,
  },
  {
    path: PATH.SABRE_SEGMENT,
    name: "sabreSegment",
    children: SabreSegmentRoutes,
  },
  {
    path: PATH.NEWS,
    name: "news",
    children: NewsRoutes,
  },
  {
    path: PATH.FARE_MANAGEMENT,
    name: "faremanagement",
    children: fareManagementRoutes,
  },
  {
    path: PATH.AG_PRICING,
    name: "agPricing",
    children: agPricingRoutes,
  },
  {
    path: PATH.MANAGE_QUOTATIONS,
    name: "manageQuotations",
    children: quotationManagement,
  },
  {
    path: PATH.SALES,
    name: "sales",
    children: SalesRoutes,
  },
  {
    path: PATH.Error_403,
    name: "403",
    children: Error403Route,
  },
  {
    path: PATH.Error_404,
    name: "404",
    children: Error404Route,
  },
  {
    path: PATH.DEALS_AND_PROMOS,
    name: "dealsAndPromos",
    children: DealsAndPromos,
  },
  {
    path: PATH.VISA_VERIFICATION,
    name: "VisaVerification",
    children: VisaVerification,
  },
  {
    path: PATH.SNAPSHOTS,
    name: "SnapShots",
    children: SnapShots,
  },
];

export default HomeRoutes;
