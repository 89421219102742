<template>
  <MDialog
    v-model="isOpen"
    @close="closeModal"
    title="Airline Tickets"
    max-width="750"
  >
    <template #content>
      <MTypography class="airline-tickets-content"
        >Please type email in the box and press enter. Airline tickets will be
        sent on emails below.
      </MTypography>

      <a-g-text-field
        label="Email"
        :testId="
          genTestId(ELEMENT_TYPES.TEXT_FIELD, 'airline-ticket-modal-email')
        "
        :value="emailField"
        @focusout="emailFieldErrorMessage = ''"
        :error="emailFieldErrorMessage"
        v-model="emailField"
        @keydown.enter="updateEmails"
      />

      <div class="d-flex margin_bottom_10 margin_top_20">
        <ag-chip
          class="margin_right_10"
          color="success"
          v-bind:key="index"
          :testId="
            genTestId(ELEMENT_TYPES.BUTTON, 'airline-ticket-modal-email-chip')
          "
          closable
          @click:close="onRemoveEmail(index)"
          v-for="(email, index) in emails"
        >
          {{ email }}
        </ag-chip>
      </div>
    </template>
    <template #actions>
      <MButton
        @click="$emit('onSendAirlineTicket', emails)"
        :disabled="$store.getters.isSendingAirlineTicket"
      >
        Send Airline Tickets
      </MButton>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { genTestId, validateEmail } from "@/ag-portal-common/utils/helpers";
import { User } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { MDialog, MTypography } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "AirlineTicketModal",
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data(): {
    ELEMENT_TYPES: typeof ELEMENT_TYPES;
    emailField: string;
    isOpen: boolean;
    emails: string[];
    emailFieldErrorMessage: string;
  } {
    return {
      emailFieldErrorMessage: "",
      ELEMENT_TYPES,
      emailField: "",
      isOpen: this.showModal,
      emails: [],
    };
  },
  beforeMount() {
    if (this.user) {
      this.emails.push(this.user.email);
    }
  },
  computed: {
    user(): User | null {
      return this.$store.getters[AUTH_GETTERS.USER];
    },
  },
  methods: {
    genTestId,
    updateEmails() {
      this.emailFieldErrorMessage = "";
      if (validateEmail(this.emailField)) {
        if (this.emails.length >= 5) {
          this.emailFieldErrorMessage = "Only 5 emails at a time are allowed";
          return;
        }
        this.emails.push(this.emailField);
        this.emailField = "";
      } else {
        this.emailFieldErrorMessage = "Invalid email";
      }
    },
    onRemoveEmail(index: number) {
      this.emails.splice(index, 0);
    },
    closeModal() {
      this.isOpen = false;
      this.$emit("closeModal");
    },
  },
  watch: {
    showModal(value: boolean) {
      this.isOpen = value;
    },
  },
});
</script>

<style lang="css" scoped>
.airline-tickets-content {
  margin-bottom: 16px;
}
</style>
