<template>
  <div style="width: 100%; padding: 10px; border-radius: 5px">
    <div>
      <img
        style="
          float: left;
          padding-right: 30px;
          margin-right: 30px;
          max-height: 54px;
        "
        :src="logoUrl"
        :alt="displayName"
      />
      <h2 style="text-align: center; margin-bottom: 20px">
        E-Ticket Receipt & Itinerary
      </h2>
    </div>
    <hr style="border: 1px solid lightgrey" />
    <div
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <div style="display: flex">
        <img
          src="https://logos-world.net/wp-content/uploads/2020/03/Qatar-Airways-Symbol.png"
          style="width: 100%; height: 70px"
          alt=""
        />
        <h3
          style="color: rgb(83, 75, 75); text-align: center; margin-top: 20px"
        >
          Qatar
        </h3>
      </div>
      <div style="flex-grow: 1; display: block; width: 100%">
        <h5 style="text-align: right; color: grey">Booking Reference (PNR):</h5>
        <h2 :style="{ 'text-align': 'right', color: primaryColor }">5REIDJ</h2>
      </div>
    </div>

    <hr style="border: 1px solid lightgrey" />

    <div style="width: 100%; margin-top: 1em">
      <table border="0" cellpadding="0" cellspacing="6" style="width: 100%">
        <tr>
          <td>
            <div style="width: 100%">
              <table
                border="0"
                cellpadding="0"
                cellspacing="0"
                style="
                  width: 100%;
                  background-color: #ecf9f5;
                  padding: 1em;
                  box-sizing: border-box;
                  border-radius: 8px;
                "
                :style="{ 'background-color': primaryColorAlpha }"
              >
                <tr>
                  <td>
                    <div
                      style="
                        text-transform: uppercase;
                        color: #6b7280;
                        font-style: normal;
                        font-size: 12px;
                        margin-bottom: 1em;
                      "
                    >
                      Agency
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style="text-transform: uppercase; color: #111827">
                      {{ displayName }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </td>
          <td>
            <div style="width: 100%">
              <table
                border="0"
                cellpadding="0"
                cellspacing="0"
                style="
                  width: 100%;
                  background-color: #ecf9f5;
                  padding: 1em;
                  box-sizing: border-box;
                  border-radius: 8px;
                "
                :style="{ 'background-color': primaryColorAlpha }"
              >
                <tr>
                  <td>
                    <div
                      style="
                        text-transform: uppercase;
                        color: #6b7280;
                        font-style: normal;
                        font-size: 12px;
                        margin-bottom: 1em;
                      "
                    >
                      Booking Status
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style="text-transform: uppercase; color: #111827">
                      Confirmed
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </td>
          <td>
            <div style="width: 100%">
              <table
                border="0"
                cellpadding="0"
                cellspacing="0"
                style="
                  width: 100%;
                  background-color: #ecf9f5;
                  padding: 1em;
                  box-sizing: border-box;
                  border-radius: 8px;
                "
                :style="{ 'background-color': primaryColorAlpha }"
              >
                <tr>
                  <td>
                    <div
                      style="
                        text-transform: uppercase;
                        color: #6b7280;
                        font-style: normal;
                        font-size: 12px;
                        margin-bottom: 1em;
                      "
                    >
                      Issued By
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style="text-transform: uppercase; color: #111827">
                      support@aeroglobe.com.pk
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div
      style="
        width: 100%;
        margin-top: 2em;
        border-radius: 8px;
        background-color: #ffffff;
        box-sizing: border-box;
        border: 1px solid rgba(100, 116, 139, 0.12);
        margin-bottom: 1em;
      "
    >
      <div
        style="
          width: 100%;
          padding: 1em;
          box-sizing: border-box;
          background-color: #10b981;
          border-top-left-radius: inherit;
          border-top-right-radius: inherit;
        "
        :style="{ 'background-color': primaryColor }"
      >
        <div
          style="
            color: #ffffff;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: 14px;
          "
        >
          Flight Details
        </div>
      </div>
      <div
        style="
          border-bottom-left-radius: inherit;
          border-bottom-right-radius: inherit;
          padding-bottom: 10px;
          padding-left: 10px;
        "
      >
        <div style="display: flex; margin-top: 10px">
          <div style="display: flex">
            <h2>Karachi [KHI]</h2>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-vue.appspot.com/o/arrow-left-broken.png?alt=media&token=951524fb-dd8e-4075-8c39-35424f400d4b"
              style="width: auto; height: 35px; max-height: 100%"
              alt=""
            />
            <h2>Istanbul [IST]</h2>
          </div>
        </div>
        <p style="font-size: 18px; color: grey">
          <b>Fri, 02 Feb 2024</b>
        </p>
        <hr style="border: 1px solid lightgrey" />

        <div class="container-fluid">
          <div
            class="row"
            style="display: flex; justify-content: space-between"
          >
            <div class="col-md-2">
              <div style="display: flex; align-items: center">
                <img
                  src="https://logos-world.net/wp-content/uploads/2020/03/Qatar-Airways-Symbol.png"
                  style="width: 100%; height: 50px"
                  class="img-fluid"
                  alt=""
                />
                <h3 style="color: rgb(99, 99, 99)">Qatar</h3>
              </div>
            </div>
            <div class="col-md-2" style="margin: auto">
              <div>
                <h3 style="text-align: center; color: rgb(99, 99, 99)">
                  10:30 PM
                </h3>
              </div>
            </div>
            <div
              class="col-md-2"
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: auto;
              "
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-vue.appspot.com/o/plane.png?alt=media&token=2a44455f-bb14-4a7f-9ad1-04a23913c1df"
                  alt=""
                  style="width: 100%; height: 20px"
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-vue.appspot.com/o/stop_sign.png?alt=media&token=672073e1-b1c6-4383-b2b3-dd2e96190637"
                  style="width: auto; max-height: 7px"
                  alt=""
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-vue.appspot.com/o/location.png?alt=media&token=1f36496f-ba04-4214-9294-4c05a9ea3f74"
                  style="width: 100%; height: 20px"
                  alt=""
                />
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                "
              >
                <h6 style="margin: 0; color: rgb(99, 99, 99)">KHI</h6>
                <h6 style="margin: 0; color: rgb(99, 99, 99)">IST</h6>
              </div>
            </div>

            <div class="col-md-2" style="margin: auto">
              <div>
                <h3 style="text-align: center; color: rgb(99, 99, 99)">
                  00:53 AM
                </h3>
              </div>
            </div>
            <div class="col-md-2" style="margin: auto">
              <div>
                <p style="text-align: center; color: rgb(99, 99, 99)">
                  2h 23mins
                </p>
              </div>
            </div>
            <div class="col-md-2" style="margin: auto">
              <div>
                <p style="text-align: center; color: rgb(99, 99, 99)">QR-203</p>
              </div>
            </div>
          </div>
        </div>
        <div
          :style="{
            height: '50px',
            'background-color': primaryColorAlpha,
            'margin-bottom': '10px',
            position: 'relative',
            display: 'flex',
            'align-items': 'center',
            'margin-top': '10px',
          }"
        >
          <div
            :style="{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              'border-left': '5px solid' + primaryColor,
              display: ' flex',
              'align-items': 'center',
              'padding-left': '10px',
            }"
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-vue.appspot.com/o/suitcase.png?alt=media&token=97bfef9e-2e38-4ef5-8b8d-bf97f41c077d"
              style="width: 30px; height: 30px"
              alt=""
            />
            <p
              style="margin-left: 5px; color: rgb(99, 99, 99); margin-top: auto"
            >
              Baggage Allowance: 2 peice of 30KG
            </p>

            <div
              style="
                height: 100%;
                border-left: 1px solid lightgrey;
                margin-left: 20px;
              "
            ></div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-vue.appspot.com/o/dinner.png?alt=media&token=b08edd7d-730d-4576-b6a7-d2478d7a199d"
              style="width: 30px; height: 30px; margin-left: 20px"
              alt=""
            />
            <p
              style="
                margin-left: 30px;
                color: rgb(99, 99, 99);
                margin-top: auto;
              "
            >
              Meal Included
            </p>

            <div
              style="
                height: 100%;
                border-left: 1px solid lightgrey;
                margin-left: 20px;
                margin-top: auto;
              "
            ></div>
            <p
              style="
                margin-left: 10px;
                color: rgb(99, 99, 99);
                margin-top: auto;
              "
            >
              Economy
            </p>
          </div>
        </div>

        <hr
          style="
            border: 1px dashed lightgrey;
            margin-top: 20px;
            margin-bottom: 5px;
          "
        />
        <div style="display: flex; align-items: center">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-vue.appspot.com/o/bed.png?alt=media&token=f88d81af-d312-41c8-ba1c-752835510d94"
            style="width: 30px; height: 30px; margin-left: 20px"
            alt=""
          />
          <p
            style="
              margin-left: 30px;
              color: rgb(99, 99, 99);
              margin-top: revert;
            "
          >
            Stop in Abu Dhabi - 1 h 25 mins
          </p>
        </div>
        <hr
          style="
            border: 1px dashed lightgrey;
            margin-top: 5px;
            margin-bottom: 15px;
          "
        />

        <!-- 2nd line  -->

        <div style="display: flex; margin-top: 10px">
          <div style="display: flex">
            <h2>Istanbul [IST]</h2>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-vue.appspot.com/o/arrow-left-broken.png?alt=media&token=951524fb-dd8e-4075-8c39-35424f400d4b"
              style="width: auto; height: 35px; max-height: 100%"
              alt=""
            />
            <h2>Karachi [KHI]</h2>
          </div>
        </div>
        <p style="font-size: 18px; color: grey">
          <b>Sun, 04 Feb 2024</b>
        </p>
        <hr style="border: 1px solid lightgrey" />

        <div class="container-fluid">
          <div
            class="row"
            style="display: flex; justify-content: space-between"
          >
            <div class="col-md-2">
              <div style="display: flex; align-items: center">
                <img
                  src="https://logos-world.net/wp-content/uploads/2020/03/Qatar-Airways-Symbol.png"
                  style="width: 100%; height: 50px"
                  class="img-fluid"
                  alt=""
                />
                <h3 style="color: rgb(99, 99, 99)">Qatar</h3>
              </div>
            </div>
            <div class="col-md-2" style="margin: auto">
              <div>
                <h3 style="text-align: center; color: rgb(99, 99, 99)">
                  6:35 PM
                </h3>
              </div>
            </div>
            <div
              class="col-md-2"
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: auto;
              "
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-vue.appspot.com/o/plane.png?alt=media&token=2a44455f-bb14-4a7f-9ad1-04a23913c1df"
                  alt=""
                  style="width: 100%; height: 20px"
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-vue.appspot.com/o/stop_sign.png?alt=media&token=672073e1-b1c6-4383-b2b3-dd2e96190637"
                  style="width: auto; max-height: 7px"
                  alt=""
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-vue.appspot.com/o/location.png?alt=media&token=1f36496f-ba04-4214-9294-4c05a9ea3f74"
                  style="width: 100%; height: 20px"
                  alt=""
                />
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                "
              >
                <h6 style="margin: 0; color: rgb(99, 99, 99)">IST</h6>
                <h6 style="margin: 0; color: rgb(99, 99, 99)">KHI</h6>
              </div>
            </div>
            <div class="col-md-2" style="margin: auto">
              <div>
                <h3 style="text-align: center; color: rgb(99, 99, 99)">
                  08:58 PM
                </h3>
              </div>
            </div>
            <div class="col-md-2" style="margin: auto">
              <div>
                <p style="text-align: center; color: rgb(99, 99, 99)">
                  2h 23mins
                </p>
              </div>
            </div>
            <div class="col-md-2" style="margin: auto">
              <div>
                <p style="text-align: center; color: rgb(99, 99, 99)">QR-603</p>
              </div>
            </div>
          </div>
        </div>
        <div
          :style="{
            height: '50px',
            'background-color': primaryColorAlpha,
            'margin-bottom': '10px',
            position: 'relative',
            display: 'flex',
            'align-items': 'center',
            'margin-top': '10px',
          }"
        >
          <div
            :style="{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              'border-left': '5px solid' + primaryColor,
              display: ' flex',
              'align-items': 'center',
              'padding-left': '10px',
            }"
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-vue.appspot.com/o/suitcase.png?alt=media&token=97bfef9e-2e38-4ef5-8b8d-bf97f41c077d"
              style="width: 30px; height: 30px"
              alt=""
            />
            <p
              style="margin-left: 5px; color: rgb(99, 99, 99); margin-top: auto"
            >
              Baggage Allowance: 1 peice of 25KG
            </p>

            <div
              style="
                height: 100%;
                border-left: 1px solid lightgrey;
                margin-left: 20px;
              "
            ></div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-vue.appspot.com/o/dinner.png?alt=media&token=b08edd7d-730d-4576-b6a7-d2478d7a199d"
              style="width: 30px; height: 30px; margin-left: 20px"
              alt=""
            />
            <p
              style="
                margin-left: 30px;
                color: rgb(99, 99, 99);
                margin-top: auto;
              "
            >
              Meal Included
            </p>

            <div
              style="
                height: 100%;
                border-left: 1px solid lightgrey;
                margin-left: 20px;
              "
            ></div>
            <p
              style="
                margin-left: 10px;
                color: rgb(99, 99, 99);
                margin-top: auto;
              "
            >
              Business
            </p>
          </div>
        </div>

        <hr
          style="
            border: 1px dashed lightgrey;
            margin-top: 20px;
            margin-bottom: 5px;
          "
        />
        <div style="display: flex; align-items: center">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-vue.appspot.com/o/bed.png?alt=media&token=f88d81af-d312-41c8-ba1c-752835510d94"
            style="width: 30px; height: 30px; margin-left: 20px"
            alt=""
          />
          <p
            style="
              margin-left: 30px;
              color: rgb(99, 99, 99);
              margin-top: revert;
            "
          >
            Stop in Doha - 1 h 21 mins
          </p>
        </div>
        <hr
          style="
            border: 1px dashed lightgrey;
            margin-top: 5px;
            margin-bottom: 15px;
          "
        />
      </div>
    </div>

    <div
      style="
        width: 100%;
        margin-top: 2em;
        border-radius: 8px;
        background-color: #ffffff;
        box-sizing: border-box;
        border: 1px solid rgba(100, 116, 139, 0.12);
        margin-bottom: 1em;
      "
    >
      <div
        style="
          width: 100%;
          padding: 1em;
          box-sizing: border-box;
          background-color: #10b981;
          border-top-left-radius: inherit;
          border-top-right-radius: inherit;
        "
        :style="{ 'background-color': primaryColor }"
      >
        <div
          style="
            color: #ffffff;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: 14px;
          "
        >
          Passenger Details
        </div>
      </div>
      <div
        style="
          border-bottom-left-radius: inherit;
          border-bottom-right-radius: inherit;
        "
      >
        <table
          class="passenger-info"
          style="width: 100%; border-collapse: collapse"
        >
          <tr style="max-width: 50%; margin-bottom: 20px">
            <th
              :style="{
                'background-color': primaryColor,
                padding: '10px',
                'text-align': 'left',
                'border-bottom': '1px solid #ddd',
                color: 'white',
              }"
            >
              Title
            </th>
            <th
              :style="{
                'background-color': primaryColor,
                padding: '10px',
                'text-align': 'left',
                'border-bottom': '1px solid #ddd',
                color: 'white',
              }"
            >
              First Name
            </th>
            <th
              :style="{
                'background-color': primaryColor,
                padding: '10px',
                'text-align': 'left',
                'border-bottom': '1px solid #ddd',
                color: 'white',
              }"
            >
              Last Name
            </th>
            <th
              :style="{
                'background-color': primaryColor,
                padding: '10px',
                'text-align': 'left',
                'border-bottom': '1px solid #ddd',
                color: 'white',
              }"
            >
              Passenger Type
            </th>
            <th
              :style="{
                'background-color': primaryColor,
                padding: '10px',
                'text-align': 'left',
                'border-bottom': '1px solid #ddd',
                color: 'white',
              }"
            >
              Birth Date
            </th>
            <th
              :style="{
                'background-color': primaryColor,
                padding: '10px',
                'text-align': 'left',
                'border-bottom': '1px solid #ddd',
                color: 'white',
              }"
            >
              Passport Number
            </th>
            <th
              :style="{
                'background-color': primaryColor,
                padding: '10px',
                'text-align': 'left',
                'border-bottom': '1px solid #ddd',
                color: 'white',
              }"
            >
              Passport Expiry
            </th>
          </tr>
          <tr
            onmouseover="this.style.backgroundColor='#f5f5f5';"
            onmouseout="this.style.backgroundColor='white';"
          >
            <td
              style="
                padding: 10px;
                text-align: left;
                border-bottom: 1px solid #ddd;
              "
            >
              Mr
            </td>
            <td
              style="
                padding: 10px;
                text-align: left;
                border-bottom: 1px solid #ddd;
              "
            >
              Ali
            </td>
            <td
              style="
                padding: 10px;
                text-align: left;
                border-bottom: 1px solid #ddd;
              "
            >
              Hasnain
            </td>
            <td
              style="
                padding: 10px;
                text-align: left;
                border-bottom: 1px solid #ddd;
              "
            >
              Adult
            </td>
            <td
              style="
                padding: 10px;
                text-align: left;
                border-bottom: 1px solid #ddd;
              "
            >
              Jan 19, 2002
            </td>
            <td
              style="
                padding: 10px;
                text-align: left;
                border-bottom: 1px solid #ddd;
              "
            >
              AB1234556
            </td>
            <td
              style="
                padding: 10px;
                text-align: left;
                border-bottom: 1px solid #ddd;
              "
            >
              31 Dec 2027
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookingTemplateTwo",
  props: {
    logo: {
      type: String,
      required: true,
    },
    organizationName: {
      type: String,
      required: true,
    },
    defaultColor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      logoUrl: this.logo,
      displayName: this.organizationName,
      primaryColor: this.defaultColor,
      primaryColorAlpha: this.defaultColor + "19",
    };
  },
  watch: {
    organizationName: function () {
      this.displayName = this.organizationName;
    },
    defaultColor: function () {
      this.primaryColor = this.defaultColor;
      this.primaryColorAlpha = this.defaultColor + "19";
    },
    logo: function () {
      this.logoUrl = this.logo;
    },
  },
};
</script>
