export enum ROUTE_TYPE {
  ONEWAY = "ONEWAY",
  RETURN = "RETURN",
  MULTI_CITY = "MULTICITY",
}

export enum ROUTE_TYPE_NAMES_FOR_RECENT_SEARCH {
  ONEWAY = "One-Way",
  RETURN = "Round-Trip",
  MULTI_CITY = "Multi-City",
}

export const ROUTE_TYPE_MAPPING = {
  [ROUTE_TYPE.ONEWAY]: ROUTE_TYPE_NAMES_FOR_RECENT_SEARCH.ONEWAY,
  [ROUTE_TYPE.RETURN]: ROUTE_TYPE_NAMES_FOR_RECENT_SEARCH.RETURN,
  [ROUTE_TYPE.MULTI_CITY]: ROUTE_TYPE_NAMES_FOR_RECENT_SEARCH.MULTI_CITY,
};

export const ROUTE_TYPE_ICONS_MAPPING = {
  [ROUTE_TYPE_NAMES_FOR_RECENT_SEARCH.ONEWAY]: "m-one-way-icon",
  [ROUTE_TYPE_NAMES_FOR_RECENT_SEARCH.RETURN]: "m-round-trip-icon",
  [ROUTE_TYPE_NAMES_FOR_RECENT_SEARCH.MULTI_CITY]: "m-one-way-icon",
};
