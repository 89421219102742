import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDataTable = _resolveComponent("MDataTable")!

  return (_openBlock(), _createBlock(_component_MDataTable, {
    headers: _ctx.tableHeaders,
    data: _ctx.localTravelers,
    "item-per-page": 30,
    "is-api-paginated": false,
    "has-search": false,
    "has-pagination": false
  }, null, 8, ["headers", "data"]))
}