import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-496735d7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "refunds refunds-view-page"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "actions" }
const _hoisted_4 = { class: "flight-refunds-content" }
const _hoisted_5 = { class: "refund-passenger" }
const _hoisted_6 = { class: "widget_border refunds-checkbox" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "refund-passenger" }
const _hoisted_9 = ["onChange", "onUpdate:modelValue"]
const _hoisted_10 = { class: "m-multiline-chip-wrapper" }
const _hoisted_11 = { class: "leg-refund-details" }
const _hoisted_12 = { class: "refund-charges-typographies" }
const _hoisted_13 = { class: "refund-charges-typographies" }
const _hoisted_14 = { class: "refund-charges-typographies" }
const _hoisted_15 = { class: "refund-charges-typographies" }
const _hoisted_16 = { class: "refund-charges-typographies" }
const _hoisted_17 = { class: "refund-charges-typographies" }
const _hoisted_18 = { class: "refund-charges-typographies" }
const _hoisted_19 = {
  key: 0,
  class: "leg-refund-details"
}
const _hoisted_20 = { class: "refund-charges-typographies" }
const _hoisted_21 = {
  key: 0,
  class: "text-right"
}
const _hoisted_22 = { class: "m-refunds" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MProgress = _resolveComponent("MProgress")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_m_card = _resolveComponent("m-card")!
  const _component_MFinancialProfileCombobox = _resolveComponent("MFinancialProfileCombobox")!
  const _component_m_combobox = _resolveComponent("m-combobox")!
  const _component_m_textfield = _resolveComponent("m-textfield")!
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_m_button = _resolveComponent("m-button")!
  const _component_m_typography = _resolveComponent("m-typography")!
  const _component_PenaltyDetails = _resolveComponent("PenaltyDetails")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_AgContactInfo = _resolveComponent("AgContactInfo")!
  const _component_RefundStatusFlag = _resolveComponent("RefundStatusFlag")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_MAccordion = _resolveComponent("MAccordion")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_CalculateSummary = _resolveComponent("CalculateSummary")!

  return (_ctx.isFinancialProfileFetching)
    ? (_openBlock(), _createBlock(_component_MProgress, {
        key: 0,
        indeterminate: "",
        class: "mprogress-alignment"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("section", null, [
          _createVNode(_component_m_card, { class: "refunds-note" }, {
            default: _withCtx(() => [
              _createVNode(_component_MTypography, { type: _ctx.renderNoteType }, {
                default: _withCtx(() => [
                  _createTextVNode(" Note: ")
                ]),
                _: 1
              }, 8, ["type"]),
              _createVNode(_component_MTypography, {
                type: _ctx.renderNoteType,
                class: "refunds-note-heading"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" For involuntary refund requests due to flight cancellations or delays, please contact our support team for assistance. ")
                ]),
                _: 1
              }, 8, ["type"])
            ]),
            _: 1
          }),
          _createVNode(_component_m_card, { class: "refunds-inputs" }, {
            default: _withCtx(() => [
              _createVNode(_component_MFinancialProfileCombobox, {
                inputValue: _ctx.flightInformation.financialProfile.value,
                "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.flightInformation.financialProfile.value) = $event)),
                label: "Select Financial Profile",
                itemValue: "value",
                itemLabel: "label",
                disabled: _ctx.isFinancialProfilesLoading,
                options: _ctx.financialProfileItems,
                hasError: _ctx.flightInformation.financialProfile.hasError,
                errorMessage: _ctx.flightInformation.financialProfile.errorMessage
              }, null, 8, ["inputValue", "disabled", "options", "hasError", "errorMessage"]),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_m_combobox, {
                    inputValue: _ctx.flightInformation.supplier.value,
                    "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.flightInformation.supplier.value) = $event)),
                    options: _ctx.flightProviders,
                    itemValue: "value",
                    itemLabel: "label",
                    label: "Airline/Supplier",
                    hasError: _ctx.flightInformation.supplier.hasError,
                    errorMessage: _ctx.flightInformation.supplier.errorMessage,
                    disabled: _ctx.isRefundAirlineAndProviderFetching || _ctx.isFetchingPNR
                  }, null, 8, ["inputValue", "options", "hasError", "errorMessage", "disabled"]),
                  _createVNode(_component_m_textfield, {
                    inputValue: _ctx.flightInformation.pnrNumber.value,
                    "onUpdate:inputValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.flightInformation.pnrNumber.value) = $event)),
                    inputValueModifiers: { trim: true },
                    label: "PNR Number",
                    hasError: _ctx.flightInformation.pnrNumber.hasError,
                    errorMessage: _ctx.flightInformation.pnrNumber.errorMessage,
                    disabled: _ctx.isFetchingPNR
                  }, null, 8, ["inputValue", "hasError", "errorMessage", "disabled"]),
                  (_ctx.isSabreSelected)
                    ? (_openBlock(), _createBlock(_component_MCombobox, {
                        key: 0,
                        inputValue: _ctx.flightInformation.sabreAirline.value,
                        "onUpdate:inputValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.flightInformation.sabreAirline.value) = $event)),
                        options: _ctx.sabreAirlinesComboboxOptions,
                        itemValue: "value",
                        itemLabel: "label",
                        label: "Sabre Airline",
                        hasError: _ctx.flightInformation.sabreAirline.hasError,
                        errorMessage: _ctx.flightInformation.sabreAirline.errorMessage
                      }, null, 8, ["inputValue", "options", "hasError", "errorMessage"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_m_button, {
                  class: "refunds-action",
                  disabled: !_ctx.canFetchPnrDetails,
                  type: "filled",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.canFetchPnrDetails ? _ctx.handleFetchPnrDetails() : ''))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isFetchingPNR ? "Fetching Refund Details" : "Get Refund Details"), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_m_button, {
                  class: "issuance-action",
                  disabled: _ctx.infoHasErrors(),
                  type: "outlined",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleDataReset()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Reset ")
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              (_ctx.isSabreSelected && _ctx.flightInformation.sabreAirline.value)
                ? (_openBlock(), _createBlock(_component_MTypography, {
                    key: 0,
                    type: "label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.sabreAirlineInfoMessage), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        (_ctx.hasPnrDetails)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_m_typography, {
                class: "m-header",
                type: "title"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Note: ")
                ]),
                _: 1
              }),
              _createVNode(_component_m_typography, {
                class: "m-header",
                type: "body"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.refundNote(_ctx.flightInformation.supplier.value)), 1)
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.hasPnrDetails && !_ctx.calculatedRefundDetails)
          ? (_openBlock(), _createBlock(_component_PenaltyDetails, { key: 1 }))
          : _createCommentVNode("", true),
        (_ctx.hasPnrDetails && !_ctx.calculatedRefundDetails)
          ? (_openBlock(), _createBlock(_component_MAccordion, {
              key: 2,
              "initial-open": true,
              externalOpen: _ctx.isPassengerDetailsOpen
            }, {
              title: _withCtx(() => [
                _createVNode(_component_AgIconInfoBar, {
                  "test-id": "",
                  mPrepandIcon: "m-passenger-management",
                  title: "Passenger Details",
                  class: "section-title"
                })
              ]),
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.passengers, (passenger, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                        _createVNode(_component_MAccordion, { class: "passenger-accordion-header" }, {
                          title: _withCtx(() => [
                            _createElementVNode("div", _hoisted_5, [
                              _createElementVNode("div", _hoisted_6, [
                                _withDirectives(_createVNode(_component_v_checkbox, {
                                  modelValue: passenger.selected,
                                  "onUpdate:modelValue": ($event: any) => ((passenger.selected) = $event),
                                  onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"])),
                                  onChange: _withModifiers(($event: any) => (_ctx.handlePassengerSelect(passenger.selected)), ["stop"])
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]), [
                                  [_vShow, 
                          _ctx.showPassenger && !_ctx.isPassengerFullyRefunded(passenger)
                        ]
                                ]),
                                (
                          !(
                            _ctx.showPassenger &&
                            !_ctx.isPassengerFullyRefunded(passenger)
                          )
                        )
                                  ? (_openBlock(), _createBlock(_component_MChip, {
                                      key: 0,
                                      variant: "error"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.isPassengerFullyRefunded(passenger)
                            ? "Fully Refunded"
                            : "Fully Travelled"), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_AgContactInfo, {
                                  "icon-name": "pakFlag",
                                  title: _ctx.renderPassengerTitle(passenger),
                                  class: "contact-info"
                                }, null, 8, ["title"]),
                                _createVNode(_component_AgContactInfo, {
                                  mPrepandIcon: 
                          _ctx.renderPassengerType(passenger.passenger_type).icon
                        ,
                                  title: 
                          _ctx.renderPassengerType(passenger.passenger_type).type
                        ,
                                  class: "contact-info"
                                }, null, 8, ["mPrepandIcon", "title"]),
                                _createVNode(_component_AgContactInfo, {
                                  "icon-name": "notInvoice",
                                  title: passenger.ticket_number,
                                  class: "contact-info"
                                }, null, 8, ["title"])
                              ])
                            ])
                          ]),
                          content: _withCtx(() => [
                            (_ctx.hasLegDetails(passenger.leg_details))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(passenger.leg_details, (legs, legIndex) => {
                                    return (_openBlock(), _createBlock(_component_MCard, {
                                      key: legIndex,
                                      class: "no-padding-card"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_8, [
                                          _createElementVNode("div", {
                                            class: _normalizeClass(
                            _ctx.isUnselected(legs.fare_detail.status)
                              ? 'un-selected-passenger'
                              : 'selected-passenger'
                          )
                                          }, [
                                            (_ctx.isUnselected(legs.fare_detail.status))
                                              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                                  key: 0,
                                                  type: "checkbox",
                                                  onChange: ($event: any) => (
                              _ctx.onSelectLegHandler(legs.selected, legIndex)
                            ),
                                                  "onUpdate:modelValue": ($event: any) => ((legs.selected) = $event)
                                                }, null, 40, _hoisted_9)), [
                                                  [_vShow, _ctx.showLegs],
                                                  [_vModelCheckbox, legs.selected]
                                                ])
                                              : (_openBlock(), _createBlock(_component_RefundStatusFlag, {
                                                  key: 1,
                                                  code: legs.fare_detail.status
                                                }, null, 8, ["code"])),
                                            _createVNode(_component_MChip, {
                                              variant: "success",
                                              class: "mchip-checkbox"
                                            }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("div", _hoisted_10, [
                                                  _createElementVNode("div", null, _toDisplayString(_ctx.renderSegmentNames(legs)), 1),
                                                  _createElementVNode("div", null, _toDisplayString(_ctx.renderSegmentDate(legs)) + " - " + _toDisplayString(_ctx.renderSegmentTime(legs)), 1)
                                                ])
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ], 2),
                                          _createElementVNode("div", _hoisted_11, [
                                            _createElementVNode("div", _hoisted_12, [
                                              _createVNode(_component_MTypography, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode("Base Refund Charges")
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_MTypography, { type: "label" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.getLegsInfo(legs).base_charges), 1)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _createElementVNode("div", _hoisted_13, [
                                              _createVNode(_component_MTypography, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode("Other Refund Charges")
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_MTypography, { type: "label" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.getLegsInfo(legs).other_charges), 1)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _createElementVNode("div", _hoisted_14, [
                                              _createVNode(_component_MTypography, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode("Base Fare")
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_MTypography, { type: "label" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.getLegsInfo(legs).base_fare), 1)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _createElementVNode("div", _hoisted_15, [
                                              _createVNode(_component_MTypography, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode("Fees")
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_MTypography, { type: "label" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.getLegsInfo(legs).fees), 1)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _createElementVNode("div", _hoisted_16, [
                                              _createVNode(_component_MTypography, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode("Taxes")
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_MTypography, { type: "label" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.getLegsInfo(legs).taxes), 1)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _createElementVNode("div", _hoisted_17, [
                                              _createVNode(_component_MTypography, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode("Others")
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_MTypography, { type: "label" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.getLegsInfo(legs).others), 1)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _createElementVNode("div", _hoisted_18, [
                                              _createVNode(_component_MTypography, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode("Total Fare")
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_MTypography, { type: "label" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.getLegsInfo(legs).total_fare), 1)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ])
                                          ])
                                        ]),
                                        (_ctx.isSabreSelected)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSabreTaxsInfo(legs), (value, key) => {
                                                return (_openBlock(), _createElementBlock("div", { key: key }, [
                                                  _createElementVNode("div", _hoisted_20, [
                                                    _createVNode(_component_MTypography, null, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(value.tax_code), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_component_MTypography, { type: "label" }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(value.amount) + " - " + _toDisplayString(value.type), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ])
                                                ]))
                                              }), 128))
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                    }), 128))
                  ]),
                  (_ctx.hasSelectedLegs)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _createVNode(_component_MButton, {
                          class: "calculate-btn",
                          variant: "filled",
                          disabled: _ctx.isCalculatingRefund,
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleCalculateRefundDetails()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.isCalculatingRefund ? "Calculating Refund" : "Calculate Refund"), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["externalOpen"]))
          : _createCommentVNode("", true),
        (_ctx.hasCalculatedRefundDetails())
          ? (_openBlock(), _createBlock(_component_CalculateSummary, { key: 3 }))
          : _createCommentVNode("", true),
        (_ctx.calculatedRefundDetails)
          ? (_openBlock(), _createBlock(_component_MCard, { key: 4 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_22, [
                  _createVNode(_component_m_textfield, {
                    class: "m-otp",
                    inputValue: _ctx.OTP.value,
                    "onUpdate:inputValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.OTP.value) = $event)),
                    inputValueModifiers: { trim: true },
                    label: "OTP",
                    hasError: _ctx.OTP.hasError,
                    errorMessage: _ctx.OTP.errorMessage,
                    onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handleFlightRefundsErrors()))
                  }, null, 8, ["inputValue", "hasError", "errorMessage"]),
                  _createVNode(_component_m_button, {
                    type: "filled",
                    onClick: _ctx.handleCommitRefundDetails,
                    disabled: _ctx.isCommitingRefund
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Commit Refund ")
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]))
}