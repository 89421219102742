import { MutationTree } from "vuex";
import {
  AgentPricingResponse,
  IAgentState,
} from "@/interfaces/agentState.interface";
import {
  IAgentDetail,
  IAgentDetails,
} from "@/ag-portal-common/interfaces/agent.interface";

const mutations: MutationTree<IAgentState> = {
  fetchingOrganizationAgents(state, fetching: boolean) {
    state.isFetchingOrganizationAgents = fetching;
  },
  saveOrganizationAgents(state, agents: Array<IAgentDetail>) {
    state.isFetchingAgents = false;
    state.organization_agents = agents;
  },
  fetchingAgent(state, fetching: boolean) {
    state.isFetchingAgent = fetching;
  },
  fetchAgentSuccess(state, agent: IAgentDetails) {
    state.isFetchingAgent = false;
    state.agent = agent;
  },
  updatingAgentPermissions(state, updating: boolean) {
    state.isUpdatingAgentPermissions = updating;
  },
  updateAgentPermissionsSuccess(state, agent: IAgentDetails) {
    state.isUpdatingAgentPermissions = false;
    state.agent = agent;
  },

  saveAgentPricingResponse(state, payload: AgentPricingResponse | null) {
    state.agentPricingResponse = payload;
  },

  updateAgentPricingResponse(state, payload: AgentPricingResponse) {
    if (state.agentPricingResponse) {
      state.agentPricingResponse.agent_pricing_public_id =
        payload.agent_pricing_public_id;
      state.agentPricingResponse.agent_pricing_type =
        payload.agent_pricing_type;
      state.agentPricingResponse.agent_pricing_financial_profile =
        payload.agent_pricing_financial_profile;
      state.agentPricingResponse.global_pricing_data =
        payload.global_pricing_data;
      state.agentPricingResponse.default_pricing_data =
        payload.default_pricing_data;
      state.agentPricingResponse.agent_pricing_line_data =
        payload.agent_pricing_line_data;
    }
  },
};

export default mutations;
