import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a1af0d12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "add-agent-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_MTextfield = _resolveComponent("MTextfield")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createBlock(_component_MDialog, {
    title: "Add New Agent",
    modelValue: _ctx.isMdialogOpen,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isMdialogOpen) = $event)),
    onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('handleClose')))
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MCombobox, {
          inputValue: _ctx.role,
          "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.role) = $event)),
          label: "Roles",
          itemValue: "value",
          itemLabel: "label",
          options: _ctx.roles
        }, null, 8, ["inputValue", "options"]),
        _createVNode(_component_MTextfield, {
          inputValue: _ctx.fullName,
          "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fullName) = $event)),
          label: "Name",
          hasError: !!_ctx.fullNameError,
          errorMessage: _ctx.fullNameError
        }, null, 8, ["inputValue", "hasError", "errorMessage"]),
        _createVNode(_component_MTextfield, {
          inputValue: _ctx.email,
          "onUpdate:inputValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
          type: "email",
          label: "Email",
          hasError: !!_ctx.emailError,
          errorMessage: _ctx.emailError
        }, null, 8, ["inputValue", "hasError", "errorMessage"])
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_MButton, {
        disabled: _ctx.$store.getters.isAddingAgent,
        class: "submit-btn",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSubmit()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Submit ")
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}