import { GetterTree } from "vuex";
import { IAuthState } from "@/ag-portal-common/interfaces/authState.interface";
import { StoreState } from "@/store/type";

export enum AUTH_GETTERS {
  /**
   * This getter returns user details.
   * @returns {User} The details of the user.
   * @example
   * // Returns User
   * this.$store.getters[AUTH_GETTERS.USER];
   */
  USER = "USER",

  /**
   * This getter returns the user's role unique ID.
   * @returns {string | null} The unique ID of the user's role, or null if no user is present.
   * @example
   * // Returns user role unique ID
   * this.$store.getters[AUTH_GETTERS.USER_ROLE];
   */
  USER_ROLE = "USER_ROLE",

  /**
   * This getter returns the access token.
   * @returns {string} The access token.
   * @example
   * // Returns access token
   * this.$store.getters[AUTH_GETTERS.ACCESS_TOKEN];
   */
  ACCESS_TOKEN = "ACCESS_TOKEN",

  /**
   * This getter returns the refresh token.
   * @returns {string} The refresh token.
   * @example
   * // Returns refresh token
   * this.$store.getters[AUTH_GETTERS.REFRESH_TOKEN];
   */
  REFRESH_TOKEN = "REFRESH_TOKEN",

  /**
   * This getter returns the user's permissions.
   * @returns {Array<string>} The permissions of the user.
   * @example
   * // Returns user permissions
   * this.$store.getters[AUTH_GETTERS.PERMISSIONS];
   */
  PERMISSIONS = "PERMISSIONS",

  /**
   * This getter returns the organization details.
   * @returns {Organization} The details of the organization.
   * @example
   * // Returns organization details
   * this.$store.getters[AUTH_GETTERS.ORGANIZATION];
   */
  ORGANIZATION = "ORGANIZATION",

  /**
   * This getter returns the financial profiles.
   * @returns {FinancialProfile[]} The default financial profiles attached to organization.
   * @example
   * // Returns financial profiles
   * this.$store.getters[AUTH_GETTERS.FINANCIAL_PROFILES];
   */
  FINANCIAL_PROFILES = "FINANCIAL_PROFILES",

  /**
   * This getter returns whether the user is signed in.
   * @returns {boolean} True if the user is signed in, false otherwise.
   * @example
   * // Returns true or false
   * this.$store.getters[AUTH_GETTERS.IS_USER_SIGNEDIN];
   */
  IS_USER_SIGNEDIN = "IS_USER_SIGNEDIN",

  /**
   * This getter returns whether authentication is in progress.
   * @returns {boolean} True if authentication is in progress, false otherwise.
   * @example
   * // Returns true or false
   * this.$store.getters[AUTH_GETTERS.IS_AUTHENTICATING];
   */
  IS_AUTHENTICATING = "IS_AUTHENTICATING",

  /**
   * This getter returns whether the password is being reset.
   * @returns {boolean} True if the password is being reset, false otherwise.
   * @example
   * // Returns true or false
   * this.$store.getters[AUTH_GETTERS.IS_RESETTING_PASSWORD];
   */
  IS_RESETTING_PASSWORD = "IS_RESETTING_PASSWORD",

  /**
   * This getter returns whether the password is being forgotten.
   * @returns {boolean} True if the password is being forgotten, false otherwise.
   * @example
   * // Returns true or false
   * this.$store.getters[AUTH_GETTERS.IS_FORGETTING_PASSWORD];
   */
  IS_FORGETTING_PASSWORD = "IS_FORGETTING_PASSWORD",

  /**
   * This getter returns default currency.
   * @returns {string | null} default currency set in the organisation of user or null.
   * @example
   * // Returns true or false
   * this.$store.getters[AUTH_GETTERS.CURRENCY];
   */
  CURRENCY = "CURRENCY",
}

const getters: GetterTree<IAuthState, StoreState> = {
  [AUTH_GETTERS.USER]: (state) => state.user,
  [AUTH_GETTERS.USER_ROLE]: (state) =>
    state.user ? state.user.role_unique_id : null,
  [AUTH_GETTERS.ACCESS_TOKEN]: (state) => state.token.access,
  [AUTH_GETTERS.REFRESH_TOKEN]: (state) => state.token.refresh,
  [AUTH_GETTERS.PERMISSIONS]: (state) => state.permissions,

  [AUTH_GETTERS.ORGANIZATION]: (state) => state.organization,
  [AUTH_GETTERS.FINANCIAL_PROFILES]: (state) => state.financialProfiles,
  [AUTH_GETTERS.CURRENCY]: (state) =>
    state.organization ? state.organization.currency : null,

  [AUTH_GETTERS.IS_USER_SIGNEDIN]: (state) => state.isUserSignedIn,
  [AUTH_GETTERS.IS_AUTHENTICATING]: (state) => state.isAuthenticating,
  [AUTH_GETTERS.IS_RESETTING_PASSWORD]: (state) => state.isResettingPassword,
  [AUTH_GETTERS.IS_FORGETTING_PASSWORD]: (state) => state.isForgettingPassword,
};

export default getters;
