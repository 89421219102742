import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { AGENT_API_PATH } from "@/modules/Agent/constants";
import { formatString } from "@/ag-portal-common/utils/helpers";
import { AgentPermissionsChangeVerificationRequestModel } from "@/modules/Agent/models/agentPermissionsChangeVerification.request";
import {
  AgentPricingResponse,
  AgentPricingUpdatePayload,
} from "@/interfaces/agentState.interface";
class AgentService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public updateAgentPermissions(
    organizationId: string,
    agentId: string,
    permissions: { [key: string]: boolean }
  ): Promise<IAGResponse> {
    return this.restClient.post(
      formatString(
        AGENT_API_PATH.UPDATE_AGENT_PERMISSIONS,
        organizationId,
        agentId
      ),
      permissions
    );
  }

  public agentPermissionsChangeVerification(
    body: AgentPermissionsChangeVerificationRequestModel
  ) {
    return this.restClient.post(
      AGENT_API_PATH.PERMISSIONS_CHANGE_VERIFICATION,
      body
    );
  }

  public deleteAgent(id: string): Promise<IAGResponse> {
    return this.restClient.delete(AGENT_API_PATH.DELETE_AGENT, id);
  }

  public getAgentPricing(
    agentId: string
  ): Promise<IAGResponse<IAGResponse<AgentPricingResponse>>> {
    return this.restClient.get(AGENT_API_PATH.GET_AGENT_PRICING(agentId));
  }

  public updateAgentPricing(
    agentId: string,
    payload: AgentPricingUpdatePayload
  ): Promise<IAGResponse<IAGResponse<AgentPricingResponse>>> {
    return this.restClient.put(
      AGENT_API_PATH.UPDATE_AGENT_PRICING(agentId),
      payload
    );
  }
}

export default AgentService;
