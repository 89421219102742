import { ActionContext, ActionTree } from "vuex";
import { IMyBookingsState } from "@/interfaces/myBookingsState.interface";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { IMyBookingsFlight } from "@/interfaces/flightBookings.interface";
import MyBookingsService from "@/modules/MyBookings/services/myBookings.service";
import { FetchBookingsParamsRequestModel } from "@/modules/MyBookings/models/fetchBookingsParams.request";
import { SendAirlineTicketBodyRequest } from "@/modules/MyBookings/models/sendAirlineTicketBody.request";
import { IMyBookingsHotel } from "@/interfaces/hotelBookings.interface";
import { IMyBookingsTour } from "@/interfaces/tourBookings.interface";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { StatusCodes } from "http-status-codes";
import { StoreState } from "@/store/type";
import { IAGErrorResponse } from "@/ag-portal-common/interfaces/agResponse.interface";

const actions: ActionTree<IMyBookingsState, StoreState> = {
  async fetchFlightBookings(
    context: ActionContext<IMyBookingsState, StoreState>,
    {
      payload,
      isFetchNewData,
      callBack,
    }: {
      payload: FetchBookingsParamsRequestModel;
      isFetchNewData: boolean;
      callBack?: () => void;
    }
  ) {
    const methodName = "actions.fetchFlightBookings";
    context.commit("enableIsFetchFlightBookings");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const myBookingsService = new MyBookingsService();

    const response: IMyBookingsFlight =
      await myBookingsService.fetchFlightBookings(payload, isFetchNewData);
    context.commit("saveFlightBookings", response);
    if (callBack) {
      callBack();
    }
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
  async downloadBrandTicket(
    context: ActionContext<IMyBookingsState, StoreState>,
    payload: { bookingId: string; pnr: string }
  ) {
    const methodName = "actions.downloadBrandTicket";
    context.commit("enableIsDownloadingBrandTicket");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const myBookingsService = new MyBookingsService();

    await myBookingsService.dowloadBrandTicket(payload.bookingId, payload.pnr);
    context.commit("disableIsDownloadingBrandTicket");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
  async downloadTourVoucher(
    context: ActionContext<IMyBookingsState, StoreState>,
    bookingId: string
  ) {
    const methodName = "actions.downloadTourVoucher";
    context.commit("enableIsDownloadingTourVoucher");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const myBookingsService = new MyBookingsService();

    await myBookingsService.downloadTourVoucher(bookingId);
    context.commit("disableIsDownloadingTourVoucher");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
  async sendAirlineTicket(
    context: ActionContext<IMyBookingsState, StoreState>,
    {
      payload,
      callback,
    }: { payload: SendAirlineTicketBodyRequest; callback: () => void }
  ) {
    const methodName = "actions.downloadAirlineTicket";
    context.commit("enableIsSendingAirlineTicket");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const myBookingsService = new MyBookingsService();
    await myBookingsService.sendAirlineTicket(payload);
    context.commit("disableIsSendingAirlineTicket");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
    callback();
  },

  async fetchHotelBookings(
    context: ActionContext<IMyBookingsState, StoreState>,
    {
      payload,
      callback,
    }: { payload: FetchBookingsParamsRequestModel; callback: () => void }
  ) {
    const methodName = "actions.fetchHotelBookings";
    context.commit("enableIsFetchHotelBookings");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const myBookingsService = new MyBookingsService();

    const response: IMyBookingsHotel =
      await myBookingsService.fetchHotelBookings(payload);
    context.commit("saveHotelBookings", response);
    if (callback) {
      callback();
    }
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
  async downloadHotelVoucher(
    context: ActionContext<IMyBookingsState, StoreState>,
    bookingId: string
  ) {
    const methodName = "actions.downloadHotelVoucher";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableIsDownloadingMBHotelVoucher", true);

      const hotelService = new MyBookingsService();
      const response = await hotelService.downloadVoucher(bookingId);

      if (
        response.success &&
        response?.status &&
        response.status >= StatusCodes.OK
      ) {
        const blob = new Blob([response.data]);
        const blobUrl = URL.createObjectURL(blob);

        context.commit("saveVoucherUrl", blobUrl);

        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          response?.data?.message || "Voucher downloaded successfully";
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      notificationService.triggerNotification();
      context.commit("disableIsDownloadingMBHotelVoucher", false);
    }
  },
  async fetchTourBookings(
    context: ActionContext<IMyBookingsState, StoreState>,
    {
      payload,
      callback,
    }: { payload: FetchBookingsParamsRequestModel; callback: () => void }
  ) {
    const methodName = "actions.fetchTourBookings";
    context.commit("enableIsFetchTourBookings");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const myBookingsService = new MyBookingsService();

    const response: IMyBookingsTour = await myBookingsService.fetchTourBookings(
      payload
    );
    context.commit("saveTourBookings", response);
    if (callback) {
      callback();
    }
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
};

export default actions;
