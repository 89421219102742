<template>
  <div class="manage-quotations">
    <QuotationPreview
      v-show="false"
      ref="quotationsPreview"
      :flights="selectedFlights"
      :pairs="selectedPairs"
      :temper-fare="temperFare"
      :agent-email="agentDetails.agentEmail.value"
      :agent-phone="agentDetails.agentPhone.value"
      :agent-organisation-name="agentDetails.organisationName.value"
      :customer-name="customerDetails.name.value"
      :customer-email="customerDetails.email.value"
    />

    <MTypography type="display"> Customise Quotation </MTypography>
    <div class="manage-quotations-section">
      <section class="mq-flight-options-section">
        <div class="mq-single-flights">
          <MCard class="mq-flight-options-section-title">
            <div>List of Options:</div>

            <div class="mq-actions">
              <template v-if="breakpoint.isTablet">
                <MFabButton
                  icon="m-delete"
                  type="outlined"
                  @click="onClearAllHandler"
                  :disabled="flights.length === 0 && pairs.length === 0"
                />
              </template>
              <template v-else>
                <MButton
                  @click="onClearAllHandler"
                  type="outlined"
                  :disabled="flights.length === 0 && pairs.length === 0"
                >
                  Clear All
                </MButton>
              </template>

              <FareCalculatorButton
                :is-fab-button="breakpoint.isTablet"
                @temper:fare="onApplyTemperFareHandler"
                @clear:fare="onClearFareHandler"
                :disabled="flights.length === 0 && pairs.length === 0"
              />

              <template v-if="breakpoint.isTablet">
                <MFabButton
                  icon="m-download"
                  type="outlined"
                  @click="onGenerateQuotationHandler"
                  :disabled="infoHasErrors() || selectedFlightsLength === 0"
                />
              </template>
              <template v-else>
                <MButton
                  @click="onGenerateQuotationHandler"
                  :disabled="infoHasErrors() || selectedFlightsLength === 0"
                >
                  <div class="mq-generate-action">
                    <MIcon name="m-download" width="18" height="18" />
                    <span>Generate Quotation</span>
                  </div>
                </MButton>
              </template>
            </div>
          </MCard>

          <template v-if="flights.length || pairs.length">
            <div :class="['list-view', { active: showList }]">
              <div
                v-for="(flight, index) in flights"
                :key="index + flight.fare_options[0].pre_booking_token"
              >
                <SingleFlightCard
                  :flight="flight"
                  :temper-fare="temperFare"
                  :is-compact-view="breakpoint.isLaptop"
                  @delete:option="onDeleteFlightOptionHandler(flight)"
                />
              </div>

              <div
                v-for="(pair, index) in pairs"
                :key="index + pair.arrival.fare_option.pre_booking_token"
              >
                <PairedFlightCard
                  :pair="pair"
                  :temper-fare="temperFare"
                  :is-compact-view="breakpoint.isLaptop"
                  @delete:option="onDeletePairOptionHandler(pair)"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="mq-empty">
              <MIcon
                class="mq-empty-icon"
                name="m-search"
                width="64"
                height="64"
              />

              <div class="mq-empty-title">No Quotations Found</div>
              <div class="mq-empty-subtitle">
                Please add some flight options to the quotations
                <RouterLink :to="PATH.FLIGHTS">here</RouterLink>.
              </div>
            </div>
          </template>
        </div>
      </section>

      <section class="mq-flight-details-section">
        <MCard
          class="mq-input-details-info-card"
          :disabled="selectedFlightsLength === 0"
        >
          <div>Selected Flights</div>
          <div
            :class="[
              'mq-input-details-badge',
              'error',
              { increment: hasIncremented, decrement: hasDecremented },
            ]"
          >
            {{ selectedFlightsLength }}
          </div>
        </MCard>

        <MCard class="mq-input-details">
          <MTextfield
            v-model:input-value.trim="agentDetails.agentEmail.value"
            label="Agent Email"
            type="email"
            :hasError="agentDetails.agentEmail.hasError"
            :errorMessage="agentDetails.agentEmail.errorMessage"
            :disabled="isAgentUser"
          />

          <MTextfield
            v-model:input-value.trim="agentDetails.organisationName.value"
            label="Organisation Name"
            :hasError="agentDetails.organisationName.hasError"
            :errorMessage="agentDetails.organisationName.errorMessage"
            :disabled="isAgentUser"
          />

          <MTextfield
            v-model:input-value.trim="agentDetails.agentPhone.value"
            label="Agent Phone Number"
            :hasError="agentDetails.agentPhone.hasError"
            :errorMessage="agentDetails.agentPhone.errorMessage"
            :disabled="isAgentUser"
          />

          <MCheckbox
            v-model="includeCustomerDetails"
            class="mq-customer-input-check"
            label="Add Customer Details"
          />

          <div v-if="includeCustomerDetails">
            <MTextfield
              v-model:input-value.trim="customerDetails.name.value"
              label="Customer Name"
              :hasError="customerDetails.name.hasError"
              :errorMessage="customerDetails.name.errorMessage"
            />

            <MTextfield
              v-model:input-value.trim="customerDetails.email.value"
              label="Customer Email"
              type="email"
              :hasError="customerDetails.email.hasError"
              :errorMessage="customerDetails.email.errorMessage"
            />
          </div>
        </MCard>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  MCard,
  MTypography,
  MCheckbox,
  MTextfield,
  MButton,
  MFabButton,
  MIcon,
} from "@aeroglobe/ag-core-ui";
import { Organization, User, USER_ROLES } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";

import SingleFlightCard from "../components/SingleFlightCard.vue";
import PairedFlightCard from "../components/PairedFlightCard.vue";
import FareCalculatorButton from "@/modules/FlightSearchResult/components/FareCalculatorButton.vue";
import QuotationPreview from "../components/preview/QuotationPreview.vue";

import { FlightOption, ReturnFlightPair } from "@/ag-flight-components/types";
import {
  AmountType,
  BreakPoint,
  FareType,
  TemperFare,
} from "@/modules/FlightSearchResult/types";
import UTILS from "@/ag-portal-common/utils";

import mainCSS from "!raw-loader!../styles/main.css?inline";
import flightRouteCss from "!raw-loader!../styles/flight-route.css?inline";
import flightSegmentsCss from "!raw-loader!../styles/flight-segments.css?inline";
import pairedFareOptionsCss from "!raw-loader!../styles/paired-fare-options.css?inline";
import pairedFlightCardCss from "!raw-loader!../styles/paired-flight-card.css?inline";
import quotationPreviewCSS from "!raw-loader!../styles/quotation-preview.css?inline";
import singleFareOptionsCSS from "!raw-loader!../styles/single-fare-options.css?inline";
import singleFlightCardCSS from "!raw-loader!../styles/single-flight-card.css?inline";
import { PATH } from "@/ag-portal-common/constants/path";
import { QuotationManager } from "../models/quotation";
import analyticServices from "@/analytic.service";
import { QUOTATIONS_ANALYTICS_EVENTS } from "../constants/anaylticsEvents";
import { IProfilePreferences } from "@/ag-portal-common/interfaces/profile.interface";

export default defineComponent({
  name: "ManageQuotations",
  components: {
    MCard,
    MTypography,
    MCheckbox,
    MTextfield,
    MButton,
    MFabButton,
    MIcon,

    SingleFlightCard,
    PairedFlightCard,
    FareCalculatorButton,
    QuotationPreview,
  },
  watch: {
    selectedFlightsLength(newVal: number, oldVal: number): void {
      if (newVal > oldVal) {
        this.hasIncremented = true;
        setTimeout(() => {
          this.hasIncremented = false;
        }, 300);
      } else {
        this.hasDecremented = true;
        setTimeout(() => {
          this.hasDecremented = false;
        }, 300);
      }
    },
  },
  computed: {
    breakpoint(): BreakPoint {
      return {
        isDesktop: this.windowWidth <= 1280,
        isLaptop: this.windowWidth <= 1100,
        isTablet: this.windowWidth <= 786,
        isMobile: this.windowWidth <= 480,
      };
    },
    user(): User | null {
      return this.$store.getters[AUTH_GETTERS.USER];
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    isAgentUser(): boolean {
      return UTILS.isUser(USER_ROLES.AGENT);
    },
    selectedFlights(): FlightOption[] {
      const selectedFlights = this.flights.filter((flight) => flight.selected);

      return selectedFlights;
    },
    selectedPairs(): ReturnFlightPair[] {
      const selectedPairs = this.pairs.filter(
        (pair) => pair.arrival.flight_option?.selected
      );

      return selectedPairs;
    },
    selectedFlightsLength(): number {
      return this.selectedFlights.length + this.selectedPairs.length;
    },
    savedPreferences(): IProfilePreferences {
      const savedPreferences = localStorage.getItem("user-preferences");

      if (savedPreferences) {
        return JSON.parse(savedPreferences);
      }

      return this.$store.state.profileModule.preferences;
    },
  },
  created(): void {
    if (this.user) {
      this.agentDetails.agentEmail.value = this.user.email;
    }

    this.agentDetails.agentPhone.value = this.savedPreferences.contact_number;

    this.agentDetails.organisationName.value =
      this.isAgentUser && this.organization
        ? this.organization.org_name_to_display
        : "Aeroglobe";

    this.flights = this.quotationManager.flights;
    this.pairs = this.quotationManager.pairs;
  },
  mounted(): void {
    window.addEventListener("resize", this.setWindowWidth);
  },
  unmounted(): void {
    this.handleDataReset();

    window.removeEventListener("resize", this.setWindowWidth);
  },
  data() {
    return {
      PATH,

      temperFare: {
        amount: 0,
        amountType: AmountType.FIXED,
        fareType: FareType.GROSS,
      } as TemperFare,

      agentDetails: {
        agentEmail: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        agentPhone: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        organisationName: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      },
      customerDetails: {
        name: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        email: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      },
      includeCustomerDetails: false,

      showList: false,

      hasIncremented: false,
      hasDecremented: false,

      validateQuotationErrors: false,

      quotationManager: new QuotationManager(),

      flights: [] as FlightOption[],
      pairs: [] as ReturnFlightPair[],

      windowWidth: window.innerWidth,
    };
  },
  methods: {
    setWindowWidth(): void {
      this.windowWidth = window.innerWidth;
    },
    handleDataReset() {
      this.customerDetails = {
        name: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        email: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      };

      this.validateQuotationErrors = false;
    },
    infoHasErrors(): boolean {
      const agentDetailsTransformed = Object.keys(this.agentDetails).map(
        (key) => (this.agentDetails as any)[key]
      );
      const customerDetailsTransformed = Object.keys(this.customerDetails).map(
        (key) => (this.customerDetails as any)[key]
      );

      this.handleQuotationErrors();

      const agentErrors = agentDetailsTransformed.some((item) => item.hasError);
      const customerErrors = customerDetailsTransformed.some(
        (item) => item.hasError
      );

      return this.includeCustomerDetails
        ? agentErrors || customerErrors
        : agentErrors;
    },

    handleQuotationErrors(): void {
      if (this.validateQuotationErrors) {
        const { agentEmail, agentPhone, organisationName } = this.agentDetails;
        const emailRegex = new RegExp(
          "[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?"
        );

        if (agentEmail.value === "") {
          agentEmail.hasError = true;
          agentEmail.errorMessage = "Email is required";
        } else if (!emailRegex.test(agentEmail.value)) {
          agentEmail.hasError = true;
          agentEmail.errorMessage = "Email is not valid";
        } else {
          agentEmail.hasError = false;
          agentEmail.errorMessage = "";
        }

        if (agentPhone.value === "") {
          agentPhone.hasError = true;
          agentPhone.errorMessage = "Phone is required";
        } else {
          agentPhone.hasError = false;
          agentPhone.errorMessage = "";
        }

        if (organisationName.value === "") {
          organisationName.hasError = true;
          organisationName.errorMessage = "Organisation Name is required";
        } else {
          organisationName.hasError = false;
          organisationName.errorMessage = "";
        }

        if (this.includeCustomerDetails) {
          const { name, email } = this.customerDetails;
          if (name.value === "") {
            name.hasError = true;
            name.errorMessage = "Name is required";
          } else {
            name.hasError = false;
            name.errorMessage = "";
          }

          if (email.value === "") {
            email.hasError = true;
            email.errorMessage = "Email is required";
          } else if (!emailRegex.test(email.value)) {
            email.hasError = true;
            email.errorMessage = "Email is not valid";
          } else {
            email.hasError = false;
            email.errorMessage = "";
          }
        }
      }
    },

    onApplyTemperFareHandler(temperFare: TemperFare): void {
      this.temperFare = temperFare;

      analyticServices.logActionEvent(
        QUOTATIONS_ANALYTICS_EVENTS.BULK_EDIT_FARE,
        { ...temperFare }
      );
    },
    onClearFareHandler(): void {
      this.temperFare.amount = 0;
    },
    onDeleteFlightOptionHandler(flight: FlightOption): void {
      this.quotationManager.deleteFlight(flight);

      this.flights = this.quotationManager.flights;

      analyticServices.logActionEvent(
        QUOTATIONS_ANALYTICS_EVENTS.DELETE_FLIGHT,
        {
          "booking-token": flight.fare_options[0].pre_booking_token,
        }
      );
    },
    onDeletePairOptionHandler(pair: ReturnFlightPair): void {
      this.quotationManager.deletePair(pair);

      this.pairs = this.quotationManager.pairs;

      analyticServices.logActionEvent(QUOTATIONS_ANALYTICS_EVENTS.DELETE_PAIR, {
        "booking-token": pair.arrival.fare_option.pre_booking_token,
      });
    },
    onClearAllHandler(): void {
      this.quotationManager.clearAll();

      this.flights = this.quotationManager.flights;
      this.pairs = this.quotationManager.pairs;
    },
    async onGenerateQuotationHandler(): Promise<void> {
      this.validateQuotationErrors = true;

      this.handleQuotationErrors();

      if (this.infoHasErrors()) {
        return;
      }

      const quotationsPreview = this.$refs.quotationsPreview as InstanceType<
        typeof QuotationPreview
      >;
      const element = quotationsPreview.$el as HTMLDivElement | null;
      const date = new Date();

      if (element) {
        let printWindow = window.open("", "", "height=595, width=842");

        if (printWindow) {
          const color = UTILS.getThemeColor(this.organization);

          printWindow.document.open();
          printWindow.document.write(element.innerHTML);

          printWindow.document.head.innerHTML = `
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link
              href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap"
              rel="stylesheet"
            />
            <style>${mainCSS}</style>
            <style>${flightRouteCss}</style>
            <style>${flightSegmentsCss}</style>
            <style>${pairedFareOptionsCss}</style>
            <style>${pairedFlightCardCss}</style>
            <style>${quotationPreviewCSS}</style>
            <style>${singleFareOptionsCSS}</style>
            <style>${singleFlightCardCSS}</style>
          `;

          printWindow.document.title = `Quotation Created by ${
            this.agentDetails.agentEmail.value
          } at ${date.toDateString()}`;

          printWindow.document.body.style.setProperty(
            "--m-primary-color",
            color
          );

          printWindow.document.close();

          printWindow.print();
        }
      }

      analyticServices.logActionEvent(QUOTATIONS_ANALYTICS_EVENTS.SAVE_QUOTE, {
        ...this.agentDetails,
        ...(this.includeCustomerDetails && this.customerDetails),
      });
    },
  },
});
</script>

<style>
.manage-quotations {
  --m-flight-card-border-color: #ececec;
}

.manage-quotations-section .mq-input-details div:last-child {
  margin-bottom: 0 !important;
}
</style>
<style scoped lang="css">
.manage-quotations-section {
  width: 100%;

  display: flex;
  gap: 20px;
  align-items: flex-start;

  color: var(--m-secondary-color);
}
.manage-quotations-section .m-card {
  padding: 16px;
}

.manage-quotations-section .mq-input-details,
.manage-quotations-section .mq-flight-options-section {
  width: 100%;

  display: flex;
  flex-direction: column;

  position: sticky;
  top: 0;
}
.manage-quotations-section .mq-input-details {
  min-width: 300px;
  max-width: 300px;
}
.manage-quotations-section .mq-input-details .mq-customer-input-check {
  margin-bottom: 20px;
}

.mq-input-details-info-card {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
}
.mq-input-details-info-card[disabled="true"] {
  opacity: 0.5 !important;
  user-select: none;
  pointer-events: none;
  cursor: not-allowed !important;
}
.mq-input-details-badge {
  font-size: 10px;
  line-height: normal;
  letter-spacing: 0.1px;
  font-weight: 500;

  padding: 4px 8px;
  border-radius: 10px;
  background-color: var(--m-error-color);

  color: white;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.mq-input-details-badge.increment {
  transform: scale(1.25);
  opacity: 1;
}
.mq-input-details-badge.decrement {
  transform: scale(0.75);
  opacity: 0.75;
}

.mq-flight-options-section-title {
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
}

.list-view {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.mq-generate-action {
  display: flex;
  align-items: center;
  gap: 8px;
}

.mq-generate-action .m-icon {
  width: 18px;
  height: 18px;
}

.mq-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.mq-empty {
  min-height: calc(100vh - (212px + 64px));
  color: var(--m-secondary-color);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
}
.mq-empty .mq-empty-icon {
  width: 64px;
  height: 64px;

  color: var(--m-primary-color);

  margin-bottom: 16px;
}
.mq-empty .mq-empty-title {
  font-size: 18px;
  font-weight: 600;

  margin-bottom: 8px;
}
.mq-empty .mq-empty-subtitle {
  font-size: 14px;
  font-weight: normal;

  color: var(--m-label-color);

  max-width: 300px;
  width: 100%;
}

@media screen and (max-width: 1100px) {
  .manage-quotations-section {
    flex-direction: column-reverse;
  }

  .manage-quotations-section .mq-flight-details-section {
    width: 100%;
  }

  .manage-quotations-section .mq-input-details {
    min-width: 0;
    max-width: none;

    width: 100%;
  }

  .manage-quotations-section .mq-input-details .m-textfield {
    max-width: 360px;
  }
}
</style>
