<template>
  <MCard class="flight-details-card">
    <ag-accordion class="flight_accordion_wrap" :panel-value="[1, 2]">
      <ag-accordion-panel>
        <template #flightInfo>
          <AgFlightDetailCard
            test-id=""
            :airline-logo="flightItem?.airline?.logo"
            :airline-code="flightItem?.flight_numbers[0]"
            :duration="legDuration(flightItem.duration)"
            :from-destination="currentJourneyLeg?.origin?.iata_code"
            :from-time="formatTime(flightItem.departure_time)"
            :to-time="formatTime(flightItem.arrival_time)"
            :to-destination="currentJourneyLeg?.destination?.iata_code"
            :airline-name="flightItem?.name"
            :fare-info="true"
          >
          </AgFlightDetailCard>
          <AgFilterDropdown
            class="flight_fare_wrap"
            test-id=""
            label=""
            icon="infoIcon"
            cart="cartIcon"
            :provider="flightItem.provider"
            v-show="showFareBreakDown"
            @add:quote="addToQuote(flightItem)"
          >
            <template #Items>
              <AgFareInfo
                test-id=""
                v-for="(fare, index) in flightItem?.fare_options"
                :key="index"
              >
                <AgHeading
                  :title="renderRBD(fare.rbd)"
                  variant="h3"
                  class="margin_bottom_10"
                ></AgHeading>
                <AgPriceSummary
                  test-id=""
                  info="Base Fare"
                  :price="
                    formatCurrency(
                      Number(fare.price.base_fare.value),
                      fare.price.base_fare.currency
                    )
                  "
                ></AgPriceSummary>
                <AgPriceSummary
                  test-id=""
                  info="Tax"
                  :price="
                    formatCurrency(
                      Number(fare.price.tax.value),
                      fare.price.tax.currency
                    )
                  "
                ></AgPriceSummary>
                <AgPriceSummary
                  test-id=""
                  info="Gross Fare"
                  :price="
                    formatCurrency(
                      Number(fare.price.gross_fare?.value),
                      fare.price.gross_fare?.currency
                    )
                  "
                ></AgPriceSummary>
                <AgPriceSummary
                  test-id=""
                  v-if="fare.price.ag_price_difference !== null"
                  :info="title(fare.price.ag_price_difference?.value ?? 0)"
                  :price="
                    formatCurrency(
                      Number(fare.price.ag_price_difference?.value),
                      fare.price.ag_price_difference?.currency ?? ''
                    )
                  "
                ></AgPriceSummary>
                <template v-if="fare.price.ag_deal_discount">
                  <AgPriceSummary
                    test-id=""
                    info="Deal Discount"
                    :price="
                      formatCurrency(
                        Number(fare.price.ag_deal_discount.value),
                        fare.price.ag_deal_discount.currency
                      )
                    "
                  ></AgPriceSummary>
                </template>
                <AgPriceSummary
                  test-id=""
                  info="Total"
                  :price="
                    formatCurrency(
                      Number(
                        fare.price.ag_gross_fare?.value
                          ? fare.price.ag_gross_fare?.value
                          : fare.price.gross_fare.value
                      ),
                      fare.price.ag_gross_fare?.currency
                        ? fare.price.ag_gross_fare?.currency
                        : fare.price.gross_fare.currency
                    )
                  "
                  total-price="true"
                ></AgPriceSummary>
              </AgFareInfo>
            </template>
          </AgFilterDropdown>
          <hr class="border-dashed" />
          <AgRow test-id="">
            <AgFlightFare
              v-for="(fare, index) in flightItem?.fare_options"
              test-id=""
              :key="index"
              :type="fare?.rbd"
              :price="
                formatCurrency(
                  calculateFlightPrice(fare?.price),
                  fare.price.gross_fare.currency
                )
              "
              :piece="renderBaggage(fare)"
              pieceIcon="bagIcon"
              :show-flag="fare.price.ag_deal_discount != null"
            >
              <template #flag>
                <DiscountFlag
                  :code="fare.discount_data.discount_code"
                  :title="parseDiscountLabel(fare.discount_data)"
                />
              </template>
              <template #button>
                <MButton @click="(e: MouseEvent) => handleBookFare(e,fare)">
                  Book Fare
                </MButton>
              </template>
            </AgFlightFare>
          </AgRow>
        </template>
        <template #tabSection>
          <hr />
          <AgTabs test-id="test">
            <template #TabHeading>
              <v-tab value="f_info_tab_1"> Flight Information</v-tab>
              <v-tab value="f_info_tab_2"> Cancellation</v-tab>
              <v-tab value="f_info_tab_3"> Date Change</v-tab>
              <v-tab value="f_info_tab_4"> Baggage</v-tab>
            </template>

            <template #TabDetail>
              <v-window-item
                value="f_info_tab_1"
                transition="fade"
                reverse-transition="fade"
              >
                <AgDiv class="complete_flight_details">
                  <AgRow test-id="">
                    <AgColumn test-id="" sm="2" md="2" lg="2">
                      <AgIconBox
                        test-id=""
                        title="Departure"
                        :sub-title="formatTime(flightItem.departure_time)"
                        icon="departureIcon"
                      ></AgIconBox>
                    </AgColumn>
                    <AgColumn test-id="" sm="10" md="10" lg="10">
                      <AgDiv class="inner_left_border">
                        <AgFlightDetailCard
                          test-id=""
                          :airline-logo="flightItem?.airline?.logo"
                          :airline-code="flightItem?.flight_numbers[0]"
                          :duration="legDuration(flightItem.duration)"
                          :from-destination="
                            currentJourneyLeg?.origin?.iata_code
                          "
                          :from-time="formatTime(flightItem.departure_time)"
                          :to-time="formatTime(flightItem.arrival_time)"
                          :to-destination="
                            currentJourneyLeg?.destination?.iata_code
                          "
                          :airline-name="flightItem?.name"
                          :fare-info="true"
                        />
                        <hr />
                        <AgHeading
                          class="flight_info_green_box"
                          :title="renderDepartureDate(flightItem!.departure_time)"
                          variant="h4"
                          test-id=""
                        />
                        <FlightRoute
                          v-for="(segment, index) in flightItem.fare_options[0]
                            .segments"
                          :next-departure-time="
                            getNextDepartureTime(
                              index,
                              flightItem.fare_options[0]
                            )
                          "
                          :show-lay-off="
                            index + 1 <
                            flightItem.fare_options[0].segments.length
                          "
                          :key="
                            segment.flight_number[0] +
                            segment.departure_datetime
                          "
                          :segment="segment"
                        />
                      </AgDiv>
                    </AgColumn>
                  </AgRow>
                </AgDiv>
              </v-window-item>
              <rules-tabs
                :origin="currentJourneyLeg?.origin?.iata_code"
                :destination="currentJourneyLeg?.destination?.iata_code"
                :pre_booking_token="
                  flightItem.fare_options[0].pre_booking_token
                "
                :isRefundable="flightItem.fare_options[0].is_refundable"
                @fetch:rules="handleTrackAction()"
              />
            </template>
          </AgTabs>
        </template>
      </ag-accordion-panel>
    </ag-accordion>
  </MCard>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";

import { DealDiscount, FareOption, FlightOption } from "../../types/Flights";
import { Price } from "../../types/BookingResponse";
import { TimeUtility } from "../../utils/TimeUtility";
import { getCurrencyFormatter, renderBaggageInfo } from "../../utils";
import { AMOUNT_TYPE, FARE_TYPE } from "../../enums/fare_calculator";
import FlightRoute from "./FlightRoute.vue";

import RulesTabs from "./RulesTabs.vue";

import analyticsService from "@/analytic.service"; // Root folder item
import { FLIGHTS_ANALYTICS_COMMON_EVENTS } from "@/constants/analyticsEvents"; // Root folder item
import { JourneyLeg } from "@/ag-flight-components/types";

import { MCard, MButton } from "@aeroglobe/ag-core-ui";
import DiscountFlag from "../DiscountFlag.vue";
import UTILS from "@/ag-portal-common/utils";

export default defineComponent({
  name: "FlightDetailCardItem",
  components: {
    RulesTabs,
    FlightRoute,
    MCard,
    MButton,
    DiscountFlag,
  },
  props: {
    flightItem: {
      type: Object as PropType<FlightOption>,
      default: () => ({}),
    },
    showFareBreakDown: {
      type: Boolean,
      default: true,
    },
  },
  data(): { isPanelExpanded: boolean } {
    return {
      isPanelExpanded: false,
    };
  },
  computed: {
    currentJourneyLeg(): JourneyLeg {
      return this.$store.getters.currentJourneyLeg;
    },
    extractToDate(): {
      departureDate: string;
      returnDate: string;
    } {
      const url = this.$route.query.trips;
      const urlToString = new String(url);
      const [, , departureDate, returnDate] = urlToString?.split(",");

      return {
        departureDate,
        returnDate: returnDate || departureDate,
      };
    },
  },
  methods: {
    parseDiscountLabel(data: DealDiscount | null): string {
      return UTILS.parseDiscountLabel(data);
    },
    addToQuote(flightItem: FlightOption): void {
      const existingQuotationsString = localStorage.getItem("quotations");
      const existingQuotations: any[] = existingQuotationsString
        ? JSON.parse(existingQuotationsString)
        : [];

      // Check if the flight is already in quotations
      const isFlightAlreadyAdded = existingQuotations.some((quotation) => {
        return (
          quotation.airline_code == flightItem.flight_numbers[0] &&
          quotation.from_time == this.formatTime(flightItem.departure_time) &&
          quotation.date.departureDate == this.extractToDate.departureDate
        );
      });

      if (!isFlightAlreadyAdded) {
        if (existingQuotations.length < 10) {
          const data = {
            airline_logo: flightItem?.airline?.logo,
            airline_code: flightItem?.flight_numbers[0],
            duration: this.legDuration(flightItem.duration),
            from_destination: this.currentJourneyLeg?.origin?.iata_code,
            from_time: this.formatTime(flightItem.departure_time),
            to_time: this.formatTime(flightItem.arrival_time),
            to_destination: this.currentJourneyLeg?.destination?.iata_code,
            airline_name: flightItem?.name,
            rdbs: flightItem?.fare_options,
            date: this.extractToDate,
          };

          existingQuotations.push(data);
          localStorage.setItem(
            "quotations",
            JSON.stringify(existingQuotations)
          );
          notificationService.type = NOTIFICATION_TYPES.SUCCESS;
          notificationService.description = "Flight Added To Quotations";
          notificationService.triggerNotification();
        } else {
          notificationService.type = NOTIFICATION_TYPES.ERROR;
          notificationService.description = "Delete few quotations to add more";
          notificationService.triggerNotification();
        }
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Flight already exists in quotations";
        notificationService.triggerNotification();
      }

      analyticsService.logActionEvent(
        FLIGHTS_ANALYTICS_COMMON_EVENTS.ADD_TO_QUOTE,
        {
          dataAdded: localStorage.getItem("quotations"),
        }
      );
    },
    title(amount: number): string {
      if (amount > 0) {
        return "Fee";
      } else {
        return "Discount";
      }
    },
    formatTime(time: Date | string): string {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    legDuration(durationInNumber: number): string {
      const duration = TimeUtility.getDurationInTextByMinutes(durationInNumber);
      return duration;
    },
    calculateFlightPrice(priceObject: Price): number {
      const fareCalculationAmount = Number(
        this.$store.state.flightModule.fareCalculatorAmount
      );
      const fareType = this.$store.state.flightModule.fareType;
      const amountType = this.$store.state.flightModule.amountType;

      if (!fareCalculationAmount) {
        return Number(
          priceObject.ag_gross_fare?.value
            ? priceObject.ag_gross_fare.value
            : priceObject.gross_fare.value
        );
      }

      let fare = Number(
        priceObject.ag_gross_fare?.value
          ? priceObject.ag_gross_fare.value
          : priceObject.gross_fare.value
      );
      let amountToAdd = fareCalculationAmount;
      let tax = 0;

      if (fareType === FARE_TYPE.BASE) {
        fare = Number(priceObject.base_fare.value);
        tax = Number(priceObject.tax.value);
      }

      if (amountType === AMOUNT_TYPE.PERCENTAGE) {
        amountToAdd = (fare / 100) * fareCalculationAmount;
      }

      return fare + amountToAdd + tax;
    },
    formatCurrency(amount: number, currency_format: string): string {
      return getCurrencyFormatter(currency_format).format(amount);
    },
    getNextDepartureTime(index: number, fareOption: FareOption): Date {
      return index + 1 < fareOption.segments.length
        ? fareOption.segments[index + 1].departure_datetime
        : new Date();
    },
    renderDepartureDate(departure_date: Date | string): string {
      return TimeUtility.parsedFlightTimeorDate(
        departure_date,
        "EEEE, MMMM dd yyyy"
      );
    },
    renderBaggage(fareOption: FareOption): string {
      const baggage_info = fareOption.baggage_info;

      return baggage_info ? renderBaggageInfo(baggage_info) : "No Baggage";
    },
    handleBookFare(e: MouseEvent, fare: FareOption): void {
      e.stopPropagation();

      const analyticsPayload = {
        from: this.currentJourneyLeg?.origin?.iata_code,
        to: this.currentJourneyLeg?.destination?.iata_code,
        "departure-time": this.formatTime(this.flightItem.departure_time),
        "return-time": this.formatTime(this.flightItem.arrival_time),
        "airline-name": this.flightItem?.name,
        "airline-code": this.flightItem?.flight_numbers[0],
        price: this.formatCurrency(
          this.calculateFlightPrice(fare?.price),
          fare.price.gross_fare.currency
        ),
        rbd: this.renderRBD(fare?.rbd),
        "baggage-info": this.renderBaggage(fare),
      };

      analyticsService.logActionEvent(
        FLIGHTS_ANALYTICS_COMMON_EVENTS.BOOK_FARE,
        analyticsPayload
      );

      this.$emit("selectedDialogItem", fare, this.flightItem);
    },
    renderRBD(rbd: string): string {
      return `RBD: ${rbd}`;
    },
    handleTrackAction(): void {
      if (!this.isPanelExpanded) {
        const analyticsPayload = {
          from: this.currentJourneyLeg?.origin?.iata_code,
          to: this.currentJourneyLeg?.destination?.iata_code,
          "departure-date": this.renderDepartureDate(
            this.flightItem.departure_time
          ),
          "departure-time": this.formatTime(this.flightItem.departure_time),
          "return-time": this.formatTime(this.flightItem.arrival_time),
          "airline-name": this.flightItem?.name,
          "airline-code": this.flightItem?.flight_numbers[0],
          "layover-time": this.legDuration(this.flightItem.duration),
        };

        analyticsService.logActionEvent(
          FLIGHTS_ANALYTICS_COMMON_EVENTS.BOOK_FARE_EXPAND,
          analyticsPayload
        );
      }

      this.isPanelExpanded = !this.isPanelExpanded;
    },
  },
});
</script>

<style lang="css">
.flight-details-card {
  margin-bottom: 20px;
}
.flight-details-card .v-expansion-panels {
  margin: 0;
}
</style>
