import { ActionContext, ActionTree } from "vuex";
import { IHotelSearch } from "../interfaces/hotels.interface";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import {
  IAGErrorResponse,
  IAGResponse,
} from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";
import {
  GetPropertiesPayload,
  GetPropertyDetailsPayload,
  HotelSearchSuggestion,
  IConfirmBooking,
  ISendOTP,
  IHotelIssue,
  InitiatePropertyBookingType,
  Property,
} from "@/ag-portal-common/types/hotel";
import HotelService from "../services/hotels.service";
import { formatImageUrl } from "@/ag-portal-common/utils/helpers";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { StoreState } from "@/store/type";

const actions: ActionTree<IHotelSearch, StoreState> = {
  // **** Fetch Locations ****
  async fetchLocations(
    context: ActionContext<IHotelSearch, StoreState>,
    payload: string
  ) {
    const methodName = "actions.fetchLocations";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("loadingLocations", true);
      const fetchLocations = new HotelService();
      const response: IAGResponse = await fetchLocations.get(payload);
      if (
        response.success &&
        response?.status &&
        response.status >= StatusCodes.OK
      ) {
        const formattedLocations = response.data?.data?.map(
          (item: HotelSearchSuggestion) => {
            return {
              ...item,
              label: `${item.display_name}, ${item.sub_display_name}`,
            };
          }
        );
        context.commit("saveLocations", formattedLocations);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (exception) {
      loggerService.logError(`${methodName}:`, exception);
    } finally {
      context.commit("loadingLocations", false);
    }
  },

  // **** Fetch Properties ****
  async searchPropertiesV2(
    context: ActionContext<IHotelSearch, StoreState>,
    payload: GetPropertiesPayload
  ) {
    const methodName = "actions.searchPropertiesV2";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const hotelService = new HotelService();
      const response: IAGResponse = await hotelService.fetchProperties(payload);
      if (response.success) {
        const propertiesData: Property[] = response.data.data.properties;
        const existingHotels: Property[] = context.getters.hotels;
        const mergedProperties: Property[] = [
          ...existingHotels,
          ...propertiesData,
        ];
        const hasPrevious: boolean = response.data.data.has_previous;
        const hasNext: boolean = response.data.data.has_next;

        context.commit("saveProperties", {
          hotels: mergedProperties,
          result_property_count: response.data.data.result_property_count,
          searched_property_not_found: propertiesData.length > 0 ? true : false,
          has_previous: hasPrevious,
          has_next: hasNext,
        });

        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("toggleIsPropertiesFetching", false);
    }
  },
  async searchProperties(
    context: ActionContext<IHotelSearch, StoreState>,
    payload: GetPropertiesPayload
  ) {
    const methodName = "actions.searchProperties";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("toggleIsPropertiesFetching", true);
      context.commit("clearProperties");
      const hotelService = new HotelService();
      const response: IAGResponse = await hotelService.fetchProperties(payload);
      if (response.success) {
        const propertiesData: Property[] = response.data.data.properties;
        const hasPrevious: boolean = response.data.data.has_previous;
        const hasNext: boolean = response.data.data.has_next;
        propertiesData.map((item) =>
          context.commit("saveSuppliers", item.supplier)
        );

        context.commit("saveProperties", {
          hotels: propertiesData,
          result_property_count: response.data.data.result_property_count,
          searched_property_not_found: propertiesData.length > 0 ? true : false,
          has_previous: hasPrevious,
          has_next: hasNext,
        });

        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("toggleIsPropertiesFetching", false);
    }
  },

  // **** Fetch Property Details ****
  async fetchPropertyDetails(
    context: ActionContext<IHotelSearch, StoreState>,
    payload: GetPropertyDetailsPayload
  ) {
    const methodName = "actions.fetchPropertyDetails";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("loadingPropertyDetail", true);
      const hotelService = new HotelService();
      const response: IAGResponse = await hotelService.fetchPropertyDetails(
        payload
      );
      if (
        response.success &&
        response?.status &&
        response.status >= StatusCodes.OK
      ) {
        const images = response.data?.data?.images as [];
        const formattedData = {
          ...response.data?.data,
          images: images.map((x) => formatImageUrl(x)),
          room_quotes: response.data?.data?.room_quotes?.map((x: any) => {
            const thumbnail_url = formatImageUrl(
              images[Math.floor(Math.random() * images.length)]
            );
            return {
              ...x,
              thumbnail_url,
            };
          }),
        };

        context.commit("savePropertyDetail", formattedData);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("loadingPropertyDetail", false);
    }
  },

  // **** Initiate Property Booking ****
  async initiatePropertyBooking(
    context: ActionContext<IHotelSearch, StoreState>,
    {
      payload,
      successCallback,
    }: {
      payload: InitiatePropertyBookingType;
      successCallback: (booking_id: string) => void;
    }
  ) {
    const methodName = "actions.initiatePropertyBooking";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("loadingInititateBooking", true);
      const hotelService = new HotelService();
      const response: IAGResponse = await hotelService.initiatePropertyBooking(
        payload
      );
      if (
        response.success &&
        response?.status &&
        response.status >= StatusCodes.OK
      ) {
        successCallback(response.data?.data?.booking_id);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("loadingInititateBooking", false);
    }
  },

  // **** Fetch Booking Details ****
  async fetchBookingDetails(
    context: ActionContext<IHotelSearch, StoreState>,
    payload: string
  ) {
    const methodName = "actions.fetchBookingDetails";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setLoading", true);
      const hotelService = new HotelService();
      const response: IAGResponse = await hotelService.fetchBookingDetails(
        payload
      );
      if (response.success && response.status === StatusCodes.OK) {
        context.commit("saveBookingDetailsResponse", response.data?.data);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("setLoading", false);
    }
  },

  // **** Confirm Hotel Booking ****
  async confirmHotelBooking(
    context: ActionContext<IHotelSearch, StoreState>,
    {
      payload,
      successCallback,
    }: {
      payload: IConfirmBooking;
      successCallback: (booking_id: string) => void;
    }
  ) {
    const methodName = "actions.confirmHotelBooking";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setConfirmBookingLoading", true);
      const hotelService = new HotelService();
      const response: IAGResponse = await hotelService.confirmBooking(payload);
      if (
        response.success &&
        response?.status &&
        response.status >= StatusCodes.OK
      ) {
        const booking_id = response?.data?.data?.booking_id;
        successCallback(booking_id);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("setConfirmBookingLoading", false);
    }
  },

  // **** Issue Hotel Booking ****
  async issueHotelBooking(
    context: ActionContext<IHotelSearch, StoreState>,
    {
      payload,
      successCallback,
    }: {
      payload: IHotelIssue;
      successCallback: () => void;
    }
  ) {
    const methodName = "actions.issueHotelBooking";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setHotelIssueLoading", true);
      const hotelService = new HotelService();
      const response: IAGResponse = await hotelService.hotelIssue(payload);
      if (
        response.success &&
        response?.status &&
        response.status >= StatusCodes.OK
      ) {
        successCallback();
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          response?.data?.message || "Hotel has been successfully issued.";
        notificationService.triggerNotification();
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("setHotelIssueLoading", false);
    }
  },

  // **** Create OTP ****
  async createOTP(
    context: ActionContext<IHotelSearch, StoreState>,
    payload: ISendOTP
  ) {
    const methodName = "actions.createOTP";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setResendOtpLoading", true);
      const hotelService = new HotelService();
      const response: IAGResponse = await hotelService.sendOtp(payload);
      if (
        response.success &&
        response?.status &&
        response.status >= StatusCodes.OK
      ) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = response?.data?.message || "OTP sent";
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || "Error Sending OTP";
      notificationService.triggerNotification();
    } finally {
      context.commit("setResendOtpLoading", false);
    }
  },

  async cancelConfirmedBooking(
    context: ActionContext<IHotelSearch, StoreState>,
    bookingId: string
  ) {
    const methodName = "actions.cancelConfirmedBooking";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableIsCancellingConfirmedBooking", true);
      const hotelService = new HotelService();
      const response: IAGResponse = await hotelService.cancelBooking(bookingId);
      if (
        response.success &&
        response?.status &&
        response.status >= StatusCodes.OK
      ) {
        context.commit("saveBookingDetailsResponse", response.data?.data);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          response?.data?.message ||
          NOTIFICATION_MESSAGES.HOTEL_BOOKING_CANCEL_SUCCESS;
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      notificationService.triggerNotification();
      context.commit("disableIsCancellingConfirmedBooking", false);
    }
  },

  async payForBooking(
    context: ActionContext<IHotelSearch, StoreState>,
    bookingId: string
  ) {
    const methodName = "actions.payForBooking";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableIsPayingForBooking", true);
      const hotelService = new HotelService();
      const response: IAGResponse = await hotelService.payForBooking(bookingId);
      if (
        response.success &&
        response?.status &&
        response.status >= StatusCodes.OK
      ) {
        context.commit("saveBookingDetailsResponse", response.data?.data);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          response?.data?.message ||
          NOTIFICATION_MESSAGES.HOTEL_BOOKING_PAYMENT_SUCCESS;
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      notificationService.triggerNotification();
      context.commit("disableIsPayingForBooking", false);
    }
  },

  async downloadVoucher(
    context: ActionContext<IHotelSearch, StoreState>,
    bookingId: string
  ) {
    const methodName = "actions.downloadVoucher";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableIsDownloadingVoucher", true);

      const hotelService = new HotelService();
      const response: IAGResponse = await hotelService.downloadVoucher(
        bookingId
      );

      if (
        response.success &&
        response?.status &&
        response.status >= StatusCodes.OK
      ) {
        const blob = new Blob([response.data]);
        const blobUrl = URL.createObjectURL(blob);

        context.commit("saveVoucherUrl", blobUrl);

        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          response?.data?.message || "Voucher downloaded successfully";
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      notificationService.triggerNotification();
      context.commit("disableIsDownloadingVoucher", false);
    }
  },
};

export default actions;
