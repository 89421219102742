<template>
  <MProgress
    class="soa-m-progess"
    indeterminate
    v-if="
      $store.getters.isFetchingSectors ||
      $store.getters.isFetchingFinancialProfiles ||
      $store.getters.isFetchingOrganization
    "
  />
  <div v-else>
    <MCard class="soa-card">
      <MTypography type="headline" class="soa-heading"
        >Select Date Range</MTypography
      >
      <div v-if="showSelectByDropdown">
        <MTypography type="body" class="soa-heading">
          Whether to show Group or Individual?
        </MTypography>
        <ag-radio
          :testId="genTestId(ELEMENT_TYPES.RADIO_BUTTON, 'soa-selectBy')"
          v-model="selectBy"
          inline
        >
          <ag-radio-item
            name="selectBy"
            :testId="
              genTestId(
                ELEMENT_TYPES.RADIO_BUTTON,
                `soa-selectBy-${selectByOpts.group.value}`
              )
            "
            :value="selectByOpts.group.value"
            :label="selectByOpts.group.label"
          ></ag-radio-item>
          <ag-radio-item
            name="selectBy"
            :testId="
              genTestId(
                ELEMENT_TYPES.RADIO_BUTTON,
                `soa-selectBy-${selectByOpts.individual.value}`
              )
            "
            :value="selectByOpts.individual.value"
            :label="selectByOpts.individual.label"
          ></ag-radio-item>
        </ag-radio>

        <div v-if="selectBy === selectByOpts.group.value">
          <ag-radio
            label="Please select the sector"
            v-model="selectedCity"
            inline
            :testId="genTestId(ELEMENT_TYPES.RADIO_BUTTON, 'soa-selectedCity')"
          >
            <ag-radio-item
              name="selectedCity"
              v-for="(city, index) in cities"
              v-bind:key="index"
              :testId="
                genTestId(
                  ELEMENT_TYPES.RADIO_BUTTON,
                  `soa-selectedCity-${city.toLowerCase()}`
                )
              "
              :value="city.toLowerCase()"
              :label="city"
            ></ag-radio-item>
          </ag-radio>
        </div>

        <div v-if="selectBy === selectByOpts.group.value && selectedCity">
          <MTextfield
            v-model:inputValue="searchProfile"
            type="text"
            label="Search Profile"
            placeholder="Search Profile"
          />
          <ag-column class="agency-scroll">
            <ag-checkbox
              :testId="
                genTestId(ELEMENT_TYPES.CHECK_BOX, `soa-profiles-selectAll`)
              "
              v-if="!searchProfile"
              @click="onSelectAll"
              label="Select all"
              v-model="checkSelectAllCheckBox"
            />
            <ag-checkbox
              v-bind:key="profile.public_id"
              :testId="
                genTestId(
                  ELEMENT_TYPES.CHECK_BOX,
                  `soa-profiles-${profile.public_id}`
                )
              "
              v-model="selectedProfiles"
              v-for="profile in filteredProfiles"
              :value="profile.public_id"
              :label="`${profile.financial_profile_name} - ${profile.platform_id}`"
            />
          </ag-column>
        </div>
      </div>
      <MFinancialProfileCombobox
        v-if="!isGroupSelected"
        class="fp-combo"
        v-model:inputValue="financialProfilePublicId"
        label="Financial Profiles"
        itemValue="value"
        itemLabel="label"
        :disabled="isFinancialProfileFetching"
        :options="financialProfileOptions"
      />
      <div class="soa-content">
        <MDatePicker
          label="Start Date"
          :startDate="minStartDate"
          :max-date="new Date()"
          :min-date="minStartDate"
          @onRangeDateChange="changeStartDate"
          placeholder="Check In Date"
          dateType="startDate"
          :multiCalendar="false"
          :hasRange="false"
          :autoPosition="false"
          position="bottom"
          class="soa-dates"
        />
        <MDatePicker
          label="End Date"
          :startDate="endDate"
          :min-date="startDate"
          :max-date="maxDate"
          @onRangeDateChange="changeEndDate"
          placeholder="End Date"
          dateType="endDate"
          :multiCalendar="false"
          :hasRange="false"
          class="soa-dates"
          :autoPosition="false"
          position="bottom"
          :error="soaDateError"
        />
        <MCombobox
          v-model:inputValue="sortBy"
          :options="items"
          itemValue="value"
          itemLabel="label"
          label="Sort By"
        />
        <div class="soa-actions">
          <MButton
            v-show="!isGroupSelected"
            @click="downloadSOA"
            :disabled="
              disableButtons ||
              $store.getters.isDownloadingSOA ||
              !hasAnySelectedField
            "
            class="soa-action-buttons"
          >
            Download PDF
          </MButton>
          <MButton
            v-show="isUserFinance || isUserAgSuper"
            @click="sendSOAEmail()"
            :disabled="
              disableButtons ||
              $store.getters.isSendingSOA ||
              !hasAnySelectedField
            "
            class="soa-action-buttons"
          >
            Send Email
          </MButton>
        </div>
      </div>
    </MCard>
    <div v-if="isUserAgent">
      <MCard>
        <MTypography type="headline">Pending Receipts and Invoices</MTypography>
        <AgNotFound
          v-if="
            pendingPostingFlightInvoices.length === 0 &&
            pendingPostingFlightReceipts.length === 0 &&
            pendingPostingHotelInvoices.length === 0 &&
            pendingPostingGroupFlightInvoices.length === 0
          "
          test-id=""
          heading="No Pending Receipts & Invoices Found"
          description=""
        />
      </MCard>
      <div class="invoices">
        <MAccordion
          :initial-open="true"
          v-if="pendingPostingFlightInvoices.length > 0"
        >
          <template v-slot:title>
            <span class="accordion-title">
              <AgIconInfoBar
                test-id=""
                mPrepandIcon="m-receipt-long"
                title="Flight Invoices"
                class="accordion-title soa-accordion"
            /></span>
          </template>
          <template #content>
            <MDataTable
              :headers="flightInvoicesHeaders"
              :data="pendingPostingFlightInvoices"
              :item-per-page="10"
              :total-item-count="pendingPostingFlightInvoices.length"
              :has-search="false"
              :is-api-paginated="false"
            >
              <template #ticket_no="{ item }">
                <MTypography class="description" type="body">
                  {{ item.ticket_no }}
                </MTypography>
              </template>
              <template #pnr="{ item }">
                <div class="mchip-alignment td-full-width">
                  <MChip
                    class="chip-width"
                    :border-less="true"
                    variant="warning"
                    >{{ item.pnr }}
                  </MChip>
                </div>
              </template>
              <template #posting_status="{ item }">
                <div class="mchip-alignment td-full-width">
                  <MChip class="chip-width" :border-less="true" variant="error"
                    >{{ item.posting_status }}
                  </MChip>
                </div>
              </template>
            </MDataTable>
          </template>
        </MAccordion>

        <MAccordion
          :initial-open="true"
          v-if="pendingPostingFlightReceipts.length > 0"
        >
          <template v-slot:title>
            <span class="accordion-title">
              <AgIconInfoBar
                test-id=""
                mPrepandIcon="m-receipt-long"
                title="Flight Receipts"
                class="accordion-title soa-accordion"
            /></span>
          </template>
          <template #content>
            <MDataTable
              :headers="flightReceiptsHeaders"
              :data="pendingPostingFlightReceipts"
              :item-per-page="10"
              :has-search="false"
              :is-api-paginated="false"
            >
              <template #payment_id="{ item }">
                <MTypography class="description" type="body">
                  {{ item.payment_id }}
                </MTypography>
              </template>
              <template #amount="{ item }">
                <div class="mchip-alignment td-full-width">
                  <MChip
                    class="chip-width"
                    :border-less="true"
                    variant="warning"
                    >{{ item.amount }}
                  </MChip>
                </div>
              </template>
              <template #posting_status="{ item }">
                <div class="mchip-alignment td-full-width">
                  <MChip class="chip-width" :border-less="true" variant="error"
                    >{{ item.posting_status }}
                  </MChip>
                </div>
              </template>
            </MDataTable>
          </template>
        </MAccordion>

        <MAccordion
          :initial-open="true"
          v-if="pendingPostingHotelInvoices.length > 0"
        >
          <template v-slot:title>
            <span class="accordion-title">
              <AgIconInfoBar
                test-id=""
                mPrepandIcon="m-receipt-long"
                title="Hotel Invoices"
                class="accordion-title soa-accordion"
            /></span>
          </template>
          <template #content>
            <MDataTable
              :headers="hotelInvoicesHeaders"
              :data="pendingPostingHotelInvoices"
              :item-per-page="10"
              :has-search="false"
              :is-api-paginated="false"
            >
              <template #booking_id="{ item }">
                <MTypography class="description" type="body">
                  {{ item.booking_id }}
                </MTypography>
              </template>
              <template #status="{ item }">
                <div class="mchip-alignment td-full-width">
                  <MChip class="chip-width" :border-less="true" variant="error"
                    >{{ item.status }}
                  </MChip>
                </div>
              </template>
            </MDataTable>
          </template>
        </MAccordion>

        <MAccordion
          :initial-open="true"
          v-if="pendingPostingGroupFlightInvoices.length > 0"
        >
          <template v-slot:title>
            <span class="accordion-title">
              <AgIconInfoBar
                test-id=""
                mPrepandIcon="m-receipt-long"
                title="Group Flight Invoices"
                class="accordion-title soa-accordion"
            /></span>
          </template>
          <template #content>
            <MDataTable
              :headers="groupFlightInvoicesHeaders"
              :data="pendingPostingGroupFlightInvoices"
              :item-per-page="10"
              :has-search="false"
              :is-api-paginated="false"
            >
              <template #booking_id="{ item }">
                <MTypography class="description" type="body">
                  {{ item.booking_id }}
                </MTypography>
              </template>
              <template #posting_status="{ item }">
                <div class="mchip-alignment td-full-width">
                  <MChip class="chip-width" :border-less="true" variant="error"
                    >{{ item.posting_status }}
                  </MChip>
                </div>
              </template>
              <template #price="{ item }">
                <div class="mchip-alignment td-full-width">
                  <MChip
                    class="chip-width"
                    :border-less="true"
                    variant="warning"
                    >{{ item.price }}
                  </MChip>
                </div>
              </template>
            </MDataTable>
          </template>
        </MAccordion>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
enum SORT_BY {
  DATE = "Date",
  AIRLINE = "Airline",
}

import { subDays } from "date-fns";
import { defineComponent } from "vue";
import { format } from "date-fns";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import {
  SOA_ANALYTICS_COMMON_EVENTS,
  SOA_DATE_FORMAT,
} from "@/modules/SOA/constants";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import {
  GroupFlightInvoices,
  MDataTableHeader,
} from "@/ag-portal-common/types";
import { ISector } from "@/ag-portal-common/interfaces/sector.interface";
import {
  MDataTable,
  MFinancialProfileCombobox,
  MTypography,
} from "@aeroglobe/ag-core-ui";
import {
  PostingInvoice,
  PostingReceipts,
  HotelInvoices,
  sendSOAType,
} from "@/ag-portal-common/types";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import UTILS from "@/ag-portal-common/utils";
import { FinancialProfile, Organization } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { pendingInvoicesAndReceipts } from "@/ag-portal-common/interfaces/organizationState.interface";
import analyticsService from "@/analytic.service";

const selectByOpts = {
  group: { label: "Group", value: "group" },
  individual: { label: "Individual", value: "individual" },
};
export default defineComponent({
  name: "SOA",
  components: { MFinancialProfileCombobox, MDataTable },
  data(): {
    ELEMENT_TYPES: typeof ELEMENT_TYPES;
    showSelectByDropdown: boolean;
    selectByOpts: {
      group: IObject;
      individual: IObject;
    };
    searchProfile: string;
    selectBy: string;
    financialProfilePublicId: string;
    selectedCity: string;
    filteredProfiles: IFinancialProfile[];
    selectedProfiles: string[];
    cities: string[];
    pendingPostingFlightReceipts: PostingReceipts[];
    pendingPostingFlightInvoices: PostingInvoice[];
    pendingPostingHotelInvoices: HotelInvoices[];
    pendingPostingGroupFlightInvoices: GroupFlightInvoices[];
    flightReceiptsHeaders: MDataTableHeader[];
    flightInvoicesHeaders: MDataTableHeader[];
    hotelInvoicesHeaders: MDataTableHeader[];
    groupFlightInvoicesHeaders: MDataTableHeader[];
    checkSelectAllCheckBox: boolean;
    items: { label: string; value: string }[];
    sortBy: string;
    startDate: Date;
    endDate: Date;
    maxDate: Date;
    minStartDate: Date;
    groupby: boolean;
    soaDateError: string;
  } {
    return {
      ELEMENT_TYPES,
      items: [
        {
          label: SORT_BY.DATE,
          value: "Date",
        },
        {
          label: SORT_BY.AIRLINE,
          value: "Airline",
        },
      ],
      sortBy: SORT_BY.DATE,
      financialProfilePublicId: "",
      startDate: new Date(),
      endDate: new Date(),
      maxDate: new Date(),
      minStartDate: new Date(),
      groupby: false,
      soaDateError: "",
      checkSelectAllCheckBox: false,
      showSelectByDropdown: false,
      selectByOpts,
      searchProfile: "",
      selectBy: "",
      selectedCity: "",
      filteredProfiles: [],
      selectedProfiles: [],
      cities: [],
      pendingPostingFlightReceipts: [],
      pendingPostingFlightInvoices: [],
      pendingPostingHotelInvoices: [],
      pendingPostingGroupFlightInvoices: [],
      hotelInvoicesHeaders: [
        {
          title: "Booking Id",
          value: "booking_id",
          key: "booking_id",
          sortable: false,
        },
        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: false,
        },
      ],
      flightReceiptsHeaders: [
        {
          title: "Payment Id",
          value: "payment_id",
          key: "payment_id",
          sortable: false,
        },
        {
          title: "Amount",
          value: "amount",
          key: "amount",
          sortable: false,
        },
        {
          title: "Posting Status",
          value: "posting_status",
          key: "posting_status",
          sortable: false,
        },
      ],
      flightInvoicesHeaders: [
        {
          title: "Ticket Number",
          value: "ticket_no",
          key: "ticket_no",
          sortable: false,
          align: "center",
        },
        {
          title: "PNR",
          value: "pnr",
          key: "pnr",
          sortable: false,
        },
        {
          title: "Posting Status",
          value: "posting_status",
          key: "posting_status",
          sortable: false,
        },
      ],
      groupFlightInvoicesHeaders: [
        {
          title: "Booking Id",
          value: "booking_id",
          key: "booking_id",
          sortable: false,
        },
        {
          title: "Posting Status",
          value: "posting_status",
          key: "posting_status",
          sortable: false,
        },
        {
          title: "Price",
          value: "price",
          key: "price",
          sortable: false,
        },
      ],
    };
  },
  async mounted() {
    this.minStartDate = subDays(new Date(), 365);
    this.startDate = this.minStartDate;
  },
  async beforeMount() {
    if (!this.isAgentUser) {
      this.$store.dispatch("fetchFinancialProfiles", {
        plan: ["PRO", "LITE", "STANDARD", "UMRAH"],
      });

      await this.$store.dispatch("fetchSectors");
      this.cities = this.$store.getters.sectors.map((sector: ISector) =>
        sector.sector_name.replace("Aeroglobe - ", "")
      );
      if (this.isUserFinance || this.isUserAgSuper) {
        this.showSelectByDropdown = true;
        this.selectBy = "individual";
      }
    }
    if (this.isAgentUser && this.organization) {
      let defaultFinancialProfile = this.organization.financial_profiles.find(
        (item) => item.is_default
      );

      if (defaultFinancialProfile) {
        this.financialProfilePublicId =
          defaultFinancialProfile?.public_id ?? "";
        await this.$store.dispatch("fetchPendingReceiptAndInvoice", {
          id: defaultFinancialProfile.public_id,
        });
        this.pendingPostingFlightInvoices = this.getPendingFlightInvoices();
        this.pendingPostingFlightReceipts = this.getPendingFlightReceipts();
        this.pendingPostingHotelInvoices = this.getPendingHotelInvoices();
        this.pendingPostingGroupFlightInvoices =
          this.getPendingGroupFlightInvoices();
      }
    }
  },
  computed: {
    hasAnySelectedField(): boolean {
      if (
        (this.selectBy === "individual" || this.selectBy === "") &&
        this.financialProfilePublicId.length > 0
      ) {
        return true;
      } else {
        return this.selectedCity.length > 0;
      }
    },
    isUserFinance(): boolean {
      return UTILS.isUser(USER_ROLES.FINANCE);
    },
    isUserAgent(): boolean {
      return UTILS.isUser(USER_ROLES.AGENT);
    },
    isUserAgSuper(): boolean {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER);
    },
    isUserSales(): boolean {
      return UTILS.isUser(USER_ROLES.SALES);
    },
    disableButtons() {
      const isAgentFinancialProfilePublicIdAvailable =
        this.isUserAgent && this.financialProfilePublicId === "";

      if (
        this.$store.getters.isSendingSOA ||
        this.$store.getters.isDownloadingSOA ||
        isAgentFinancialProfilePublicIdAvailable
      ) {
        return true;
      }
      return false;
    },
    financialProfileOptions(): FPComboboxOptions[] {
      if (this.financialProfiles) {
        const fpComboboxOptions = this.financialProfiles.map(
          (fp: FinancialProfile) => {
            const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase() as
              | "pro"
              | "elite"
              | "smart";
            const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
            const status: string = fp?.status;

            const fpComboboxOption = {
              id: fp?.platform_id,
              label: fp?.financial_profile_name,
              value: fp?.public_id,
              isActive: status === ORGANIZATION_STATUSES.ACTIVE,
              status: status,
              sector: sector ?? "",
              type: planType,
            };

            return fpComboboxOption;
          }
        );

        return fpComboboxOptions;
      } else {
        return [];
      }
    },
    isFinancialProfileFetching(): boolean {
      return this.$store.getters.isFetchingFinancialProfiles;
    },

    userRole(): USER_ROLES | null {
      return this.$store.getters[AUTH_GETTERS.USER_ROLE];
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    financialProfiles(): FinancialProfile[] | null {
      if (this.isUserAgent) {
        return this.$store.getters[AUTH_GETTERS.FINANCIAL_PROFILES];
      } else if (this.isUserSales) {
        const salesSectors = this.$store.getters.sectors.map(
          (sector: ISector) => sector.sector_name
        );
        const fp = this.$store.getters.financialProfiles;
        const filteredFp = fp.filter((profile: FinancialProfile) =>
          salesSectors.includes(profile.sector)
        );
        return filteredFp;
      } else {
        return this.$store.getters.financialProfiles;
      }
    },
    isAgentUser(): boolean {
      return UTILS.isUser(USER_ROLES.AGENT);
    },
    isProfileSelected() {
      if (!this.isAgentUser) {
        if (
          this.selectBy === selectByOpts.group.value &&
          this.selectedProfiles.length
        ) {
          return true;
        } else if (
          this.selectBy === selectByOpts.individual.value &&
          this.financialProfilePublicId
        ) {
          return true;
        }
        return false;
      }
      return true;
    },
    isGroupSelected(): boolean {
      if (this.selectBy === "group" || this.selectedProfiles.length) {
        return true;
      } else {
        return false;
      }
    },
    getPendingPostingInvoices(): pendingInvoicesAndReceipts {
      return this.$store.getters.getPendingPostingInvoices;
    },
  },
  methods: {
    changeStartDate(newDate: Date) {
      this.startDate = newDate;
    },
    async downloadSOA() {
      this.soaDateError = "";
      if (this.startDate > this.endDate) {
        this.soaDateError = "end date can't be less than start date";
      } else {
        let financialProfileIfUserIsAgent =
          this.organization?.organization_name ?? "";
        let customer = this.$store.getters.financialProfiles.find(
          (profile: IFinancialProfile) =>
            profile.public_id === this.financialProfilePublicId
        );
        let formattedStartDate = format(this.startDate, SOA_DATE_FORMAT);
        let formattedEndDate = format(this.endDate, SOA_DATE_FORMAT);
        const fileInfo = {
          customerName: this.isUserAgent
            ? financialProfileIfUserIsAgent
            : customer.financial_profile_name,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
        const params = {
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          send_email: false,
          sort_by: this.sortBy.toLowerCase(),
          financial_profile_public_id: this.financialProfilePublicId,
        };

        await this.$store.dispatch("downloadSOA", {
          params,
          fileInfo,
        });

        this.onTrackHandler(SOA_ANALYTICS_COMMON_EVENTS.SOA_DOWNLOAD);
      }
    },
    changeEndDate(newDate: Date) {
      this.endDate = newDate;
    },
    onTrackHandler(action: string) {
      let payload: any = {
        "financial-profile-id": this.financialProfilePublicId,
        "sort-by": this.sortBy,
        "start-date": this.startDate,
        "end-date": this.endDate,
      };

      analyticsService.logActionEvent(action, payload);
    },
    getPendingGroupFlightInvoices(): GroupFlightInvoices[] {
      const invoices = this.getPendingPostingInvoices;
      return invoices.groupFlightInvoices;
    },
    getPendingHotelInvoices(): HotelInvoices[] {
      const invoices = this.getPendingPostingInvoices;
      return invoices.hotelInvoices;
    },
    getPendingFlightInvoices(): PostingInvoice[] {
      const invoices = this.getPendingPostingInvoices;
      return invoices.flightInvoices || [];
    },
    getPendingFlightReceipts(): PostingReceipts[] {
      const invoices = this.getPendingPostingInvoices;
      return invoices.flightReceipts || [];
    },
    genTestId,
    onSelectAll() {
      if (this.selectedProfiles.length === this.filteredProfiles.length) {
        this.selectedProfiles = [];
      } else {
        this.selectedProfiles = this.filteredProfiles.map(
          (profile: IFinancialProfile) => profile.public_id
        );
      }
    },
    filterProfileByGroup() {
      return this.$store.getters.financialProfiles.filter(
        (profile: IFinancialProfile) =>
          this.selectedCity &&
          profile?.sector?.toLowerCase()?.includes(this.selectedCity)
      );
    },
    async sendSOAEmail() {
      let financialProfilePublicIds = [];
      let startDate = this.startDate;
      let endDate = this.endDate;
      let financialProfilePublicId = this.financialProfilePublicId;

      let customerNameIfUserIsAgent = "";
      if (this.isAgentUser) {
        let financialProfileIfUserIsAgent =
          this.$store.getters.organization?.financial_profiles.find(
            (item: IFinancialProfile) =>
              item.public_id === financialProfilePublicId
          );
        financialProfilePublicIds.push(financialProfilePublicId);
        customerNameIfUserIsAgent =
          financialProfileIfUserIsAgent.financial_profile_name;
      } else {
        if (this.selectBy === this.selectByOpts.individual.value) {
          let financialProfile = this.$store.getters.financialProfiles.find(
            (profile: IFinancialProfile) =>
              profile.public_id === this.financialProfilePublicId
          );
          if (financialProfile) {
            financialProfilePublicIds.push(financialProfile.public_id);
          }
        } else {
          financialProfilePublicIds.push(...this.selectedProfiles);
        }
      }
      let formattedStartDate = format(startDate, SOA_DATE_FORMAT);
      let formattedEndDate = format(endDate, SOA_DATE_FORMAT);
      let customer = this.$store.getters.financialProfiles.find(
        (profile: IFinancialProfile) =>
          profile.public_id === financialProfilePublicId
      );
      const fileInfo = {
        customerName:
          customer?.financial_profile_name || customerNameIfUserIsAgent,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      };
      const requestPayload = {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        send_email: true,
        sort_by: this.sortBy.toLowerCase(),
        financial_profile_public_id: financialProfilePublicIds,
      };

      await this.$store.dispatch("sendSOAEmail", {
        requestPayload,
        fileInfo,
      });
    },
  },
  watch: {
    selectedCity() {
      this.filteredProfiles = this.filterProfileByGroup();
      this.selectedProfiles = this.filteredProfiles.map(
        (profile) => profile.public_id
      );
    },
    selectBy(value) {
      if (value === selectByOpts.individual.value) {
        this.selectedCity = "";
        this.selectedProfiles = [];
        this.filteredProfiles = [];
      } else if (value === selectByOpts.group.value) {
        this.financialProfilePublicId = "";
      }
    },
    searchProfile(value) {
      if (value) {
        this.filteredProfiles = this.filterProfileByGroup().filter(
          (profile: IFinancialProfile) => {
            return (
              profile.platform_id.includes(value) ||
              profile.financial_profile_name
                .toLowerCase()
                .includes(value.toLowerCase())
            );
          }
        );
      } else {
        this.filteredProfiles = this.filterProfileByGroup();
      }
    },
    selectedProfiles(value: string[]) {
      if (value.length === this.filteredProfiles.length) {
        this.checkSelectAllCheckBox = true;
      } else {
        this.checkSelectAllCheckBox = false;
      }
    },
  },
});
</script>

<style scoped lang="css">
.soa-header {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
.chip-width {
  max-width: max-content;
}
.soa-accordion {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
.invoices {
  margin-top: 10px;
}
.soa-m-progess {
  width: 100%;
  padding: 0 4px;
  position: absolute;
  top: 30%;
  left: 50%;
}
.soa-card {
  margin: 10px 0px;
}
.soa-heading {
  margin-bottom: 10px;
}
.soa-actions {
  display: flex;
}
.soa-action-buttons {
  margin-top: 10px;
  margin-right: 5px;
}
.soa-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
@media (max-width: 900px) {
  .soa-content {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}

@media (max-width: 600px) {
  .soa-content {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
</style>
