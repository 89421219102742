import { IFlightBooking } from "@/ag-flight-components/interfaces/IFlightBooking.interface";

const flightBookingState: IFlightBooking = {
  isLoading: false,
  confirmBookingLoading: false,
  cancelBookingLoading: false,
  downloadTicketLoading: false,
  travelers: [],
  mobileNumber: null,
  flightDetails: null,
  bookingStatus: "",
  pnrExpiryDate: null,
  supplierPnr: null,
  email: null,
  voidTicketLoading: false,
  isPassengerLoading: false,
  passengers: [],
  errors: null,
  bookingActions: {
    is_ticket_issuance_enabled: false,
    is_refund_enabled: false,
    is_ticket_void_enabled: false,
    is_ticket_cancel_enabled: false,
    is_airline_ticket_enabled: false,
    is_download_ticket: false,
  },
};

export default flightBookingState;
