export enum STORAGE_KEYS {
  USER = "user",
  PERMISSIONS = "permissions",

  ACCESS_TOKEN = "access-token",
  REFRESH_TOKEN = "refresh-token",

  ORGANIZATION = "organization",
  FINANCIAL_PROFILES = "financial-profiles",
  SECTORS = "sectors",

  LAST_FETCHED_FEATURED_NEWS_TIME = "last-fetched-featured-news-time",
}
