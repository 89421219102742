<template>
  <MCard class="insights_header">
    <MTypography type="headline">New Organizations Created </MTypography>
    <div class="insights_header_action_btn">
      <MDatePicker
        label="Start Date"
        :start-date="defaultStartDate"
        :max-date="new Date()"
        @onRangeDateChange="(value) => (start_date = value)"
        placeholder="Start Date"
        dateType="startDate"
        :multiCalendar="false"
        :hasRange="false"
        :autoPosition="true"
        :error="bookingDateError"
      />
      <MDatePicker
        label="End Date"
        :start-date="new Date()"
        :max-date="new Date()"
        :min-date="startDate"
        @onRangeDateChange="(value) => (end_date = value)"
        placeholder="End Date"
        dateType="endDate"
        :multiCalendar="false"
        :hasRange="false"
        :autoPosition="true"
        :error="bookingDateError"
      />
      <MButton
        :disabled="isLoading"
        @click="fetchData"
        class="margin_bottom_20"
      >
        Fetch
      </MButton>
    </div>
  </MCard>
  <IssuanceChartShimmer v-if="isLoading" />
  <MCard class="chart_card" v-else>
    <highcharts
      class="hc"
      :options="NewOrganizationsCreatedChart"
      ref="chart"
    />
  </MCard>
</template>

<script>
import Highcharts from "highcharts";
import { sub, format } from "date-fns";
import exportingInit from "highcharts/modules/exporting";

import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { MButton } from "@aeroglobe/ag-core-ui";

import IssuanceChartShimmer from "@/modules/Dashboard/components/IssuanceChartShimmer.vue";
exportingInit(Highcharts);

export default {
  data() {
    return {
      bookingDateError: "",
      start_date: sub(new Date(), { days: 15 }),
      end_date: new Date(),
    };
  },
  created() {
    this.start_date = this.defaultStartDate;
  },
  components: {
    IssuanceChartShimmer,
  },
  computed: {
    defaultStartDate() {
      const today = new Date();
      const defaultDate = new Date(today);
      defaultDate.setDate(today.getDate() - 25);
      return defaultDate;
    },
    isLoading() {
      return this.$store.getters.isFetchingOrganizations;
    },
    organizations() {
      return this.$store.getters.organizations || [];
    },
    NewOrganizationsCreatedChart() {
      return {
        chart: {
          type: "column",
        },
        title: {
          text: "Organizations Count by Sector",
        },
        xAxis: {
          categories: ["Sector Breakdown"],
          crosshair: true,
          accessibility: {
            description: "Countries",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Number of Organizations",
          },
        },
        tooltip: {
          useHTML: true,
          formatter: function () {
            const { series, point } = this;
            return (
              `<tr><td style="color:${series.color};padding:0">${series.name}: </td>` +
              `<td style="padding:0"><b>${point.y}</b></td></tr>` +
              `</table>`
            );
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: this.generateOrganizationsSeriesData(),
      };
    },
    minDate() {
      const minDate = sub(this.date, { days: 7 });
      return minDate;
    },
  },
  methods: {
    generateOrganizationsSeriesData() {
      if (this.organizations.length === 0) {
        // If organizations are empty, create a default series with zero counts for each sector
        const defaultSeries = [
          {
            name: "Aergolobe",
            data: ["No organizations Found"],
          },
        ];
        return defaultSeries;
      }

      const sectorCounts = this.organizations.reduce((counts, org) => {
        const sector = org.sector;
        counts[sector] = (counts[sector] || 0) + 1;
        return counts;
      }, {});

      // Convert the map to Highcharts series format
      const seriesData = Object.keys(sectorCounts).map((sector) => ({
        name: sector,
        data: [sectorCounts[sector]],
      }));

      return seriesData;
    },
    fetchData() {
      if (this.start_date > this.end_date) {
        this.bookingDateError = "start date can't be after end date";
      } else {
        this.bookingDateError = "";
        const payload = {
          start_date: format(this.start_date, FORMAT_YYY_MM_DD),
          end_date: format(this.end_date, FORMAT_YYY_MM_DD),
        };
        this.$store.dispatch("fetchOrganizationsByCreationDate", payload);
      }
    },
  },
  beforeMount() {
    this.fetchData();
  },
  unmounted() {
    this.$store.commit("saveOrganization", []);
  },
};
</script>

<style lang="scss" scoped>
.insights_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
}

.insights_header_action_btn {
  display: flex;
  align-items: center;

  > :first-child {
    margin-right: 10px;
  }
  > :nth-child(2) {
    margin-right: 10px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
}
.chart_card {
  margin-top: 20px;
}
</style>
