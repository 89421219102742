import Mitt from "mitt";

export const authBus = Mitt();

export const AUTH_EVENTS = {
  LOGOUT: "LOGOUT",
  LOGIN: "LOGIN",
  UPDATE_PREFERENCES: "UPDATE_PREFERENCES",
  ROUTE_ON_ERROR_403: "ROUTE_ON_ERROR_403",
};
