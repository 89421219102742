import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_MTextfield = _resolveComponent("MTextfield")!
  const _component_MDatePicker = _resolveComponent("MDatePicker")!

  return (_openBlock(), _createElementBlock("form", {
    class: "traveler-card-form",
    onSubmit: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createVNode(_component_MCombobox, {
      inputValue: _ctx.titleComputed,
      "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.titleComputed) = $event)),
      label: "Title",
      itemValue: "value",
      itemLabel: "label",
      options: _ctx.getTitleOptions(),
      hasError: !!_ctx.getError('title'),
      errorMessage: _ctx.getError('title'),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateField('title'))),
      name: "title"
    }, null, 8, ["inputValue", "options", "hasError", "errorMessage"]),
    _createVNode(_component_MTextfield, {
      inputValue: _ctx.firstNameComputed,
      "onUpdate:inputValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.firstNameComputed) = $event)),
      type: "text",
      label: "First Name",
      placeholder: "Enter First Name",
      hasError: !!_ctx.getError('firstName'),
      errorMessage: _ctx.getError('firstName'),
      onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateField('firstName'))),
      name: "firstName"
    }, null, 8, ["inputValue", "hasError", "errorMessage"]),
    _createVNode(_component_MTextfield, {
      inputValue: _ctx.lastNameComputed,
      "onUpdate:inputValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.lastNameComputed) = $event)),
      type: "text",
      label: "Last Name",
      placeholder: "Enter Last Name",
      hasError: !!_ctx.getError('lastName'),
      errorMessage: _ctx.getError('lastName'),
      onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.validateField('lastName'))),
      name: "lastName"
    }, null, 8, ["inputValue", "hasError", "errorMessage"]),
    _createVNode(_component_MDatePicker, {
      label: "Date of Birth",
      startDate: _ctx.traveler.dateOfBirth,
      "min-date": _ctx.getMinBirthDate(_ctx.traveler.passengerType),
      "max-date": _ctx.getMaxBirthDate(_ctx.traveler.passengerType),
      onOnRangeDateChange: _ctx.changeBirthDate,
      placeholder: "Enter Date of Birth",
      dateType: "birthDate",
      multiCalendar: false,
      hasRange: false,
      error: _ctx.getError('dateOfBirth'),
      name: "dateOfBirth",
      autoPosition: false,
      position: "bottom"
    }, null, 8, ["startDate", "min-date", "max-date", "onOnRangeDateChange", "error"]),
    _createVNode(_component_MCombobox, {
      inputValue: _ctx.genderComputed,
      "onUpdate:inputValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.genderComputed) = $event)),
      label: "Gender",
      itemValue: "value",
      itemLabel: "label",
      options: _ctx.genderOptions,
      hasError: !!_ctx.getError('gender'),
      errorMessage: _ctx.getError('gender'),
      onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.validateField('gender'))),
      name: "gender"
    }, null, 8, ["inputValue", "options", "hasError", "errorMessage"]),
    _createVNode(_component_MCombobox, {
      inputValue: _ctx.nationalityComputed,
      "onUpdate:inputValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.nationalityComputed) = $event)),
      label: "Nationality",
      itemValue: "value",
      itemLabel: "label",
      options: _ctx.countries,
      hasError: !!_ctx.getError('nationality'),
      errorMessage: _ctx.getError('nationality'),
      onBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.validateField('nationality'))),
      name: "nationality"
    }, null, 8, ["inputValue", "options", "hasError", "errorMessage"]),
    (_ctx.isInternational)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_MTextfield, {
            inputValue: _ctx.passportNumberComputed,
            "onUpdate:inputValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.passportNumberComputed) = $event)),
            type: "text",
            label: "Passport Number",
            placeholder: "Enter Passport Number",
            hasError: !!_ctx.getError('passportNumber'),
            errorMessage: _ctx.getError('passportNumber'),
            onBlur: _cache[11] || (_cache[11] = ($event: any) => (_ctx.validateField('passportNumber'))),
            name: "passportNumber"
          }, null, 8, ["inputValue", "hasError", "errorMessage"]),
          _createVNode(_component_MDatePicker, {
            label: "Passport Expiry Date",
            startDate: _ctx.traveler.passportExpiry,
            "min-date": _ctx.minPassportDate,
            "max-date": _ctx.maxPassportDate,
            onOnRangeDateChange: _ctx.changePassportDate,
            placeholder: "Enter Passport Expiry Date",
            dateType: "passportExpiry",
            multiCalendar: false,
            hasRange: false,
            error: _ctx.getError('passportExpiry'),
            name: "passportExpiry"
          }, null, 8, ["startDate", "min-date", "max-date", "onOnRangeDateChange", "error"])
        ], 64))
      : (_openBlock(), _createBlock(_component_MTextfield, {
          key: 1,
          inputValue: _ctx.idNumberComputed,
          "onUpdate:inputValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.idNumberComputed) = $event)),
          type: "text",
          label: "ID Number",
          placeholder: "Enter ID Number",
          hasError: !!_ctx.getError('idNumber'),
          errorMessage: _ctx.getError('idNumber'),
          onBlur: _cache[13] || (_cache[13] = ($event: any) => (_ctx.validateField('idNumber'))),
          name: "idNumber"
        }, null, 8, ["inputValue", "hasError", "errorMessage"]))
  ], 32))
}