<template>
  <MCard
    :class="['pv-flight-card', { active: isSideSheetOpen }]"
    v-if="departureFlightOption && arrivalFlightOption"
  >
    <div class="pv-details">
      <img
        class="pv-flight-image"
        :src="departureFlightOption.airline.logo"
        alt="flight-image"
        width="24"
        height="24"
      />

      <div class="pv-column pv-header">
        <div class="pv-airline-title m-textoverflow">
          {{ departureFlightOption.airline.name }}
        </div>
        <div class="pv-airline-number">
          {{ departureFlightOption.flight_numbers[0] }}
        </div>
        <div class="pv-date">
          {{ getDepartureTime(departureFlightOption.departure_time) }}
        </div>
      </div>

      <div class="flight-details">
        <FlightRoute
          :details="getRouteDetails('departure')"
          :view-type="isCompactView ? ListViewType.SINGLE : ListViewType.PAIR"
        />
      </div>

      <MDivider
        :class="['pv-divider', { compact: isCompactView }]"
        :type="isCompactView ? 'horizontal' : 'vertical'"
      />

      <img
        class="pv-flight-image"
        :src="arrivalFlightOption.airline.logo"
        alt="flight-image"
        width="24"
        height="24"
      />

      <div class="pv-column pv-header">
        <div class="pv-airline-title m-textoverflow">
          {{ arrivalFlightOption.airline.name }}
        </div>
        <div class="pv-airline-number">
          {{ arrivalFlightOption.flight_numbers[0] }}
        </div>
        <div class="pv-date">
          {{ getDepartureTime(arrivalFlightOption.departure_time) }}
        </div>
      </div>

      <div class="flight-details fd-return">
        <FlightRoute
          :details="getRouteDetails('arrival')"
          :view-type="isCompactView ? ListViewType.SINGLE : ListViewType.PAIR"
        />
      </div>

      <div class="pv-actions">
        <div class="pv-info-action">
          <div class="pv-provider">{{ departureFlightOption.provider }}</div>

          <MDivider class="pv-mobile-action" type="vertical" />
          <MIcon
            class="pv-mobile-action pv-icon primary"
            name="m-sidebar"
            width="18"
            height="18"
            @click="onViewDetailsHandler"
          />

          <MDivider type="vertical" />
          <MIcon
            class="pv-icon pv-qoutations"
            title="Add to qoutations"
            name="m-post-add"
            @click="onAddToQuoteHandler"
          />
        </div>

        <div class="pv-details-action" @click="onViewDetailsHandler">
          <div class="sfd-title">View Details</div>
          <MIcon class="pv-icon primary" name="m-sidebar" />
        </div>
      </div>
    </div>

    <PairedFareOptions
      :departure-fare-option="departureFareOption"
      :arrival-fare-option="arrivalFareOption"
      :temper-fare="temperFare"
      :is-compact-view="isCompactView"
      @select:fare="onSelectFareHandler"
    />

    <MSideSheet v-model="isSideSheetOpen" title="Flight Details">
      <div class="pvs-flight-details">
        <div class="pvs-flight-info">
          <div class="pvs-flight-info-title">
            ({{ getRouteDetails("departure").originCode }} -
            {{ getRouteDetails("departure").destinationCode }})
            {{ getRouteDetails("departure").originTime }} -
            {{ getRouteDetails("departure").destinationTime }} ({{
              getRouteDetails("departure").duration
            }})
          </div>

          <div class="pvs-flight-info-subtitle">
            <img
              class="pvs-flight-info-row-image"
              :src="departureFlightOption.airline.logo"
              alt="flight-image"
              width="18"
              height="18"
            />
            <div>
              {{ departureFlightOption.airline.name }} •
              {{ departureFlightOption.flight_numbers.join(", ") }}
            </div>
          </div>
        </div>

        <MDivider class="pvs-divider" />

        <div class="pvs-flight-info">
          <div class="pvs-flight-info-title">
            ({{ getRouteDetails("arrival").originCode }} -
            {{ getRouteDetails("arrival").destinationCode }})
            {{ getRouteDetails("arrival").originTime }} -
            {{ getRouteDetails("arrival").destinationTime }} ({{
              getRouteDetails("arrival").duration
            }})
          </div>

          <div class="pvs-flight-info-subtitle">
            <img
              class="pvs-flight-info-row-image"
              :src="arrivalFlightOption.airline.logo"
              alt="flight-image"
              width="18"
              height="18"
            />
            <div>
              {{ arrivalFlightOption.airline.name }} •
              {{ arrivalFlightOption.flight_numbers.join(", ") }}
            </div>
          </div>
        </div>

        <MSegmentedButton
          class="pvs-segments"
          :segments="flightDetailsSegments"
        />

        <div v-show="flightDetailsSegments[0].checked">
          <div class="pvs-segment-title spacer">Departure Flight</div>

          <FlightSegments :segments="departureFareOption.segments" />

          <div class="pvs-segment-title">Return Flight</div>

          <FlightSegments :segments="arrivalFareOption.segments" />
        </div>

        <div class="pvs-cancellation" v-show="flightDetailsSegments[1].checked">
          <div class="pvs-cancellation-chips">
            <template
              v-for="(option, index) in [
                pair.departure.fare_option,
                pair.arrival.fare_option,
              ]"
              :key="index"
            >
              <MChip
                :variant="`${option.is_refundable ? 'success' : 'error'}`"
                border-less
              >
                {{ getRbdNameHandler(option.rbd) }} -
                {{ option.is_refundable ? "Refundable" : "Non-Refundable" }}
              </MChip>
            </template>
          </div>

          <template
            v-if="
              fareRules && fareRules.cancellation_policy.policy_rules.length > 0
            "
          >
            <div class="pvs-rules">
              <div
                class="pvs-rule"
                v-for="(rule, index) in fareRules.cancellation_policy
                  .policy_rules"
                :key="index"
              >
                <div class="pvs-rule-title">
                  {{ rule.rule }}
                </div>
                <div class="pvs-rule-subtitle">
                  {{ rule.value }}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="pvs-rules">
              <div class="pvs-rule">
                <div class="pvs-rule-title">Cancellation Rules</div>
                <div class="pvs-rule-subtitle">As per airline policy</div>
              </div>
            </div>
          </template>
        </div>

        <div class="pvs-modification" v-show="flightDetailsSegments[2].checked">
          <template
            v-if="
              fareRules && fareRules.modification_policy.policy_rules.length > 0
            "
          >
            <div class="pvs-rules">
              <div
                class="pvs-rule"
                v-for="(rule, index) in fareRules.modification_policy
                  .policy_rules"
                :key="index"
              >
                <div class="pvs-rule-title">
                  {{ rule.rule }}
                </div>
                <div class="pvs-rule-subtitle">
                  {{ rule.value }}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="pvs-rules">
              <div class="pvs-rule">
                <div class="pvs-rule-title">Modification Rules</div>
                <div class="pvs-rule-subtitle">As per airline policy</div>
              </div>
            </div>
          </template>
        </div>

        <div class="pvs-baggage" v-show="flightDetailsSegments[3].checked">
          <template
            v-if="fareRules && fareRules.baggage_policy.policy_rules.length > 0"
          >
            <div class="pvs-rules">
              <div
                class="pvs-rule"
                v-for="(rule, index) in fareRules.baggage_policy.policy_rules"
                :key="index"
              >
                <div class="pvs-rule-title">
                  {{ rule.rule }}
                </div>
                <div class="pvs-rule-subtitle">
                  {{ rule.value }}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="pvs-rules">
              <div class="pvs-rule">
                <div class="pvs-rule-title">Baggage Rules</div>
                <div class="pvs-rule-subtitle">As per airline policy</div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </MSideSheet>
  </MCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import {
  MCard,
  MIcon,
  MDivider,
  MSideSheet,
  MSegmentedButton,
} from "@aeroglobe/ag-core-ui";

import FlightRoute from "./FlightRoute.vue";
import PairedFareOptions from "./PairedFareOptions.vue";
import FlightSegments from "./FlightSegments.vue";

import {
  FlightRulesResponse,
  ListViewType,
  RouteDetails,
  TemperFare,
} from "../types";
import {
  FareOption,
  FlightOption,
  ReturnFlightPair,
} from "@/ag-flight-components/types";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";
import { MSegment } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import { QuotationManager } from "@/modules/ManageQuotations/models/quotation";

export default defineComponent({
  name: "PairedFlightCard",
  components: {
    MCard,
    MIcon,
    MDivider,
    MSideSheet,
    MSegmentedButton,

    FlightRoute,
    PairedFareOptions,
    FlightSegments,
  },
  props: {
    pair: {
      type: Object as PropType<ReturnFlightPair>,
      required: true,
    },
    flightsFareRules: {
      type: Object as PropType<Map<string, FlightRulesResponse>>,
    },
    temperFare: {
      type: Object as PropType<TemperFare>,
      required: true,
    },
    isCompactView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["select:fare", "fetch:rules"],
  computed: {
    departureFlightOption(): FlightOption | undefined {
      return this.pair.departure.flight_option;
    },
    departureFareOption(): FareOption {
      return this.pair.departure.fare_option;
    },
    arrivalFlightOption(): FlightOption | undefined {
      return this.pair.arrival.flight_option;
    },
    arrivalFareOption(): FareOption {
      return this.pair.arrival.fare_option;
    },
    fareRules(): FlightRulesResponse | undefined {
      const preBookingToken = this.pair.arrival.fare_option.pre_booking_token;

      return this.flightsFareRules?.get(preBookingToken);
    },
  },
  data() {
    return {
      ListViewType,
      isSideSheetOpen: false,
      flightDetailsSegments: [
        {
          label: "Flight Info",
          checked: true,
        },
        {
          label: "Cancellation",
          checked: false,
        },
        {
          label: "Date Change",
          checked: false,
        },
        {
          label: "Baggage",
          checked: false,
        },
      ] as MSegment[],
    };
  },
  methods: {
    getRouteDetails(type: keyof ReturnFlightPair): RouteDetails {
      const segments = this.pair[type].fare_option.segments;
      const duration = this.getDuration(
        this.pair[type].flight_option?.duration ?? 0
      );

      const originSegment = segments[0];
      const destinationSegment = segments[segments.length - 1];

      return {
        originCode: originSegment.origin.iata_code,
        originTime: this.getFormattedTime(
          `${originSegment.departure_datetime}`
        ),

        destinationCode: destinationSegment.destination.iata_code,
        destinationTime: this.getFormattedTime(
          `${destinationSegment.arrival_datetime}`
        ),

        duration,

        stops: segments.map((segment) => segment.destination.iata_code),
      };
    },
    getDepartureTime(departureTime: string): string {
      return TimeUtility.parsedFlightTimeorDate(
        departureTime,
        "EE, MMM dd, yyyy"
      );
    },
    getFormattedTime(time: string): string {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    getDuration(duration: number): string {
      return TimeUtility.getDurationInTextByMinutes(duration);
    },
    getRbdNameHandler(name: string): string {
      return name.length === 1 ? `RDB: ${name}` : name;
    },
    onSelectFareHandler(fareOption: FareOption): void {
      this.$emit("select:fare", fareOption);
    },
    onViewDetailsHandler(): void {
      this.isSideSheetOpen = !this.isSideSheetOpen;

      if (this.isSideSheetOpen && !this.fareRules) {
        this.onFetchFareRulesHandler();
      }
    },
    async onFetchFareRulesHandler(): Promise<void> {
      this.$emit(
        "fetch:rules",
        this.pair.arrival.fare_option.pre_booking_token
      );
    },
    onAddToQuoteHandler(): void {
      const quotationManager = new QuotationManager();

      quotationManager.addPair(this.pair);
    },
  },
});
</script>

<style lang="css">
.pv-flight-card {
  padding: 0;
}
</style>

<style scoped lang="css">
.pv-flight-card {
  color: var(--m-secondary-color);
  padding: 0 !important;
}
.pv-flight-card:hover {
  box-shadow: 0 4px 10px var(--m-divider-color);
}
.pv-flight-card.active {
  box-shadow: -10px 4px 20px var(--m-divider-color);
}

.pv-flight-card .pv-divider {
  height: 60px;
  margin-right: 10px;
}
.pv-flight-card .pv-divider.compact {
  height: 1px;
  width: 100%;
  margin: 0;
}

.pv-details {
  display: flex;
  align-items: center;
  gap: 12px;

  border-radius: 8px 8px 0 0;
  border: 1px solid var(--m-flight-card-border-color);
  border-bottom: none;

  padding: 16px;
}

.pv-icon {
  width: 18px;
  height: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.pv-icon.primary {
  color: var(--m-primary-color);
}

.pv-details .pv-header {
  width: 120px;
  max-width: 120px;
}
.pv-details .pv-flight-image {
  width: 24px;
  height: 24px;

  object-fit: cover;
}
.pv-details .pv-airline-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.pv-details .pv-airline-number,
.pv-details .pv-date {
  font-size: 12px;
  line-height: 22px;

  color: var(--m-label-color);
}

.pv-details .flight-details {
  flex: 1;
}
.pv-details .flight-details:nth-child(even) {
  padding-left: 0;
}

.pv-details .pv-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
}

.pv-details .pv-actions .pv-info-action {
  display: flex;
  align-items: center;
  gap: 8px;
}
.pv-details .pv-actions .pv-info-action .m-divider {
  height: 20px;
}
.pv-details .pv-actions .pv-info-action .pv-provider {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;
  padding: 0 12px;

  font-size: 12px;
  font-weight: 500;

  color: var(--m-primary-color);
  background-color: color-mix(
    in srgb,
    var(--m-primary-color) 10%,
    var(--m-light-color) 100%
  );
  border-radius: 4px;
}
.pv-details .pv-actions .pv-info-action .pv-qoutations {
  cursor: pointer;

  transition: color 0.2s ease-in-out;
}
.pv-details .pv-actions .pv-info-action .pv-qoutations:hover {
  color: var(--m-primary-color);
}

.pv-details .pv-actions .pv-details-action {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 12px;
  font-weight: 500;
  color: var(--m-primary-color);

  cursor: pointer;

  transition: opacity 0.2s ease-in-out;
}

.pv-details .pv-actions .pv-details-action:hover {
  opacity: 0.75;
}

.pvs-flight-details {
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: var(--m-secondary-color);

  padding: 8px;
}
.pvs-flight-details .pvs-flight-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.pvs-flight-details .pvs-flight-info .pvs-flight-info-title {
  font-size: 16px;
  font-weight: 600;
}
.pvs-flight-details .pvs-flight-info .pvs-flight-info-subtitle {
  display: flex;
  gap: 8px;
  align-items: center;
}
.pvs-flight-details .pvs-segments {
  margin: 20px auto 0;
}
.pvs-divider {
  margin: 10px 0;
}
.pvs-segment-title {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.pvs-segment-title.spacer {
  margin-top: 32px;
}

.pvs-cancellation .pvs-cancellation-chips {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  margin-top: 32px;
}

.pvs-rules {
  display: flex;
  flex-direction: column;

  gap: 4px;
  margin: 32px 0;
}

.pvs-rules .pvs-rule {
  font-size: 14px;

  border: 1px solid var(--m-divider-color);
  border-radius: 2px;
}
.pvs-rules .pvs-rule:only-child {
  border-radius: 8px;
}
.pvs-rules .pvs-rule:first-child:not(:last-child) {
  border-radius: 8px 8px 2px 2px;
}
.pvs-rules .pvs-rule:last-child:not(:first-child) {
  border-radius: 2px 2px 8px 8px;
}

.pvs-rules .pvs-rule .pvs-rule-title {
  font-weight: 500;
  border-bottom: 1px solid var(--m-divider-color);
}
.pvs-rules .pvs-rule .pvs-rule-subtitle {
  color: var(--m-label-color);
}
.pvs-rules .pvs-rule .pvs-rule-title,
.pvs-rules .pvs-rule .pvs-rule-subtitle {
  min-height: 32px;
  padding: 12px;

  display: flex;
  align-items: center;
}

.pv-details .flight-details {
  width: 100%;
}

.pv-details .pv-actions .pv-mobile-action {
  display: none;
}

@media screen and (max-width: 1100px) {
  .pv-details {
    flex-direction: column;
    align-items: flex-start;

    position: relative;
  }

  .pv-details .pv-actions {
    position: absolute;
    right: 16px;
  }

  .pv-details .flight-details {
    position: absolute;
    top: 36px;
  }

  .pv-details .flight-details.fd-return {
    position: absolute;
    top: calc(34px * 5);
  }

  .pv-details .pv-actions .pv-details-action {
    display: none;
  }

  .pv-details .pv-actions .pv-mobile-action {
    display: block;
  }
}

@media screen and (max-width: 820px) {
  .pv-details .flight-details {
    position: static;
    top: 36px;
  }

  .pv-details .flight-details.fd-return {
    position: static;
    top: calc(34px * 5);
  }
}
</style>
