<template>
  <div
    v-if="show"
    :class="['snackbar', type]"
    :style="{
      backgroundColor: snackBarDetails.backgroundColor,
      color: snackBarDetails.textColor,
    }"
  >
    <div class="snackbar-content">
      <MIcon
        :name="snackBarDetails.icon"
        width="24px"
        height="24px"
        class="m-snackbar-icon"
        :style="{ color: snackBarDetails.iconColor }"
      />
      <div class="description" :style="{ color: snackBarDetails.textColor }">
        {{ description }}
      </div>
    </div>
    <MIcon
      name="m-close"
      width="24px"
      height="24px"
      class="m-snackbar-icon close-btn"
      :style="{ color: snackBarDetails.iconColor }"
      @click="closeSnackbar"
    />
  </div>
  <div>
    <div class="notification-container">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { INotification } from "@/ag-portal-common/interfaces/notification.interface";
import {
  NOTIFICATION_EVENTS,
  notificationBus,
} from "@/ag-portal-common/eventBusses/notification";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { NOTIFICATION_STYLES } from "@/ag-portal-common/enums/NOTIFICATION_STYLES";
import { IconName } from "@aeroglobe/ag-core-ui/dist/src/components/material/icons/icons.type";

export default defineComponent({
  name: "NotificationWrapper",
  data() {
    return {
      show: false,
      description: "",
      actionButtonText: "",
      title: "",
      type: NOTIFICATION_TYPES.INFO,
    };
  },
  computed: {
    NOTIFICATION_STYLES() {
      return NOTIFICATION_STYLES;
    },
    ELEMENT_TYPES() {
      return ELEMENT_TYPES;
    },
    snackBarDetails(): {
      icon: IconName;
      backgroundColor: string;
      textColor: string;
      iconColor: string;
    } {
      switch (this.type) {
        case "warning":
          return {
            icon: "m-warning",
            backgroundColor: "#FFF4E5",
            textColor: "#EF7A19",
            iconColor: "#EF7A19",
          };
        case "error":
          return {
            icon: "m-error",
            backgroundColor: "#FDEDED",
            textColor: "#DA4C4C",
            iconColor: "#DA4C4C",
          };
        case "success":
          return {
            icon: "m-success",
            backgroundColor: "#EAF2EA",
            textColor: "#418945",
            iconColor: "#418945",
          };
        case "info":
          return {
            icon: "m-info",
            backgroundColor: "#E6F3FA",
            textColor: "#329FDB",
            iconColor: "#329FDB",
          };
        default:
          return {
            icon: "m-error",
            backgroundColor: "#FDEDED",
            textColor: "#DA4C4C",
            iconColor: "#DA4C4C",
          };
      }
    },
  },
  methods: {
    triggerNotification(data: INotification) {
      this.show = false;
      this.description = data.description;
      this.type = data.type;
      this.show = true;
      setTimeout(() => {
        this.closeSnackbar();
      }, 10000);
    },
    closeSnackbar() {
      this.show = false;
    },
  },
  mounted() {
    notificationBus.on(NOTIFICATION_EVENTS.TRIGGER, (data) => {
      this.triggerNotification(data as INotification);
    });
  },
});
</script>

<style lang="css" scoped>
.notification-container {
  right: 10px;
  top: 100px;
}
.snackbar {
  position: fixed;
  top: 70px;
  right: 20px;
  min-width: 350px;
  max-width: 600px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
  transition: opacity 0.3s ease;
  opacity: 1;
  padding: 12px;
}

.snackbar-content {
  display: flex;
  align-items: start;
}

.close-btn {
  margin-bottom: auto;
}
.description {
  margin: 0px 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
.m-snackbar-icon {
  width: 24px;
  height: 24px;
}
</style>
