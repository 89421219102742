<template>
  <div>
    <template v-if="isExpired">
      <p class="booking-expired">Expired</p>
    </template>
    <template v-else-if="isConfirmedOrInitiated">
      <div class="countdown" v-if="bookingExpiry">
        <div>
          <p class="expires-in-heading">Expires In</p>
          <ul>
            <li v-if="countdownText.days">
              <span id="days">{{ padNumber(countdownText.days) }}</span
              >days
            </li>
            <li>
              <span id="hours">{{ padNumber(countdownText.hours) }}</span
              >Hours
            </li>
            <li>
              <span id="minutes">{{ padNumber(countdownText.minutes) }}</span
              >Minutes
            </li>
            <li>
              <span id="seconds">{{ padNumber(countdownText.seconds) }}</span
              >Seconds
            </li>
          </ul>
        </div>
        <p class="booking-expiry-according-timezone">
          {{ formattedbookingExpiry() }}
        </p>
      </div>
      <p class="booking-expiry-not-available" v-else>
        Booking expiry will be available soon
      </p>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { intervalToDuration, isAfter, parseISO, Duration } from "date-fns";
import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import { BOOKING_STATUSES } from "@/ag-flight-components/enums/booking_statuses";
import _ from "lodash";

interface Sector {
  name: string;
  timeZone: string;
  countryTimeAbbreviation: string;
}

export default defineComponent({
  name: "HotelBookingExpiryTimer",
  props: {
    bookingExpiry: {
      type: Date,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      targetDate: new Date(),
      currentTime: new Date(),
      interval: null as null | number,
    };
  },
  beforeMount() {
    this.targetDate = this.targetParsedDate() as Date;
  },
  computed: {
    isExpired(): boolean {
      const status = _.capitalize(this.status);
      return (
        status === BOOKING_STATUSES.CONFIRMED &&
        isAfter(this.currentTime, this.targetDate)
      );
    },
    countdownText(): Duration {
      const countdown = intervalToDuration({
        start: this.currentTime,
        end: this.targetDate,
      });

      return countdown;
    },
    isConfirmedOrInitiated(): boolean {
      const status = _.capitalize(this.status);
      return (
        status === BOOKING_STATUSES.CONFIRMED ||
        status === BOOKING_STATUSES.INITIATED
      );
    },
  },
  methods: {
    targetParsedDate(): Date | string {
      if (this.bookingExpiry) {
        const sector: Sector = this.getSectorInfo();
        const expiryDate = this.bookingExpiry;

        const targetDateInSectorTZ = utcToZonedTime(
          expiryDate,
          sector?.timeZone ? sector?.timeZone : "Asia/Karachi"
        );
        return targetDateInSectorTZ;
      } else {
        return "";
      }
    },
    getSectorInfo(): Sector {
      const sectors: Sector[] = [
        {
          name: "Aeroglobe - Dubai",
          timeZone: "Asia/Dubai",
          countryTimeAbbreviation: "GST",
        },
        {
          name: "Aeroglobe - Riyadh",
          timeZone: "Asia/Riyadh",
          countryTimeAbbreviation: "SAST",
        },
      ];
      const currentSectorName: string = localStorage.getItem("sector") || "";
      const sector = sectors.find(
        (sector) => sector.name === currentSectorName
      );
      return sector as Sector;
    },
    updateCurrentTime(): void {
      this.currentTime = new Date();
    },
    startCountdown(): void {
      this.interval = window.setInterval(() => {
        this.updateCurrentTime();
      }, 1000);
    },
    padNumber(num: number | undefined): string {
      return num !== undefined ? (num < 10 ? `0${num}` : `${num}`) : "";
    },
    formattedbookingExpiry(): string {
      const pakistanTimeZone = "Asia/Karachi";
      const sector: Sector = this.getSectorInfo();
      const timezoneAbbreviation = sector?.countryTimeAbbreviation
        ? sector?.countryTimeAbbreviation
        : "PST";
      if (this.bookingExpiry) {
        const formattedDate = formatInTimeZone(
          this.bookingExpiry,
          sector?.timeZone ? sector?.timeZone : pakistanTimeZone,
          `dd MMM yyyy - HH:mm:ss`
        );
        return formattedDate + ` (${timezoneAbbreviation})`;
      }
      return "";
    },
  },
  mounted() {
    this.startCountdown();
  },
  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
});
</script>

<style scoped lang="scss">
.countdown {
  ul {
    text-align: center;
  }

  li {
    font-weight: 500;
    display: inline-block;
    font-size: 10px;
    list-style-type: none;
    padding: 1em;
    text-transform: uppercase;
  }

  li span {
    display: block;
    font-size: 20px;
    text-align: center;
  }
}

.booking-expiry-not-available {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0;
}

.booking-expired {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0;
  color: red;
}

.booking-heading {
  color: var(--green-color);
  font-weight: 600;
  margin-right: 10px;
  font-size: 22px;
}
.booking-text {
  font-size: 18px;
  font-weight: 500;
  font-size: 22px;
}
.booking-heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.expires-in-heading {
  font-weight: 500;
  display: inline-block;
  font-size: 10px;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}
.hr-line {
  margin: 5px 0;
}
.booking-expiry-according-timezone {
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  margin-top: 10px;
}
</style>
