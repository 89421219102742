<template>
  <div class="pv-fare-options">
    <div class="pv-fare-option">
      <template v-if="isCompactView">
        <div class="pv-fo-price">
          {{ calculateFlightPrice(trArrOption) }}
          <div
            class="pv-fo-discounted-price"
            v-if="trArrOption.price.ag_deal_discount"
          >
            {{ getFormattedCurrency(trArrOption.price.gross_fare.value) }}
          </div>
        </div>
      </template>

      <template v-if="isCompactView && trArrOption.price.ag_deal_discount">
        <MDivider type="vertical" />
        <div class="pv-fo-deal-code">
          {{ trArrOption.discount_data.discount_code }}
        </div>
      </template>

      <div class="pv-fo-title">
        <b>Departure</b>
      </div>

      <div class="pv-fo-title">{{ getRbdNameHandler(trDepOption.rbd) }}</div>
      <div class="pv-fo-info">
        <MIcon
          class="pv-icon primary"
          :name="`${trDepOption.has_meal ? 'm-meal' : 'm-no-meal'}`"
        />
        <MDivider type="vertical" />

        <MIcon
          class="pv-icon primary"
          :name="`${trDepOption.baggage_info ? 'm-baggage' : 'm-no-baggage'}`"
        />

        <div>
          {{ getBaggageInfo(trDepOption.baggage_info) }}
        </div>
      </div>
      <div class="pv-fo-actions">
        <template v-if="!isPrinting">
          <template v-if="!isCompactView && trArrOption.price.ag_deal_discount">
            <div class="pv-fo-deal-code">
              {{ trArrOption.discount_data.discount_code }}
            </div>
            <div class="pv-fo-discounted-price">
              {{ getFormattedCurrency(trArrOption.price.gross_fare.value) }}
            </div>
          </template>

          <template v-if="!isCompactView">
            <div class="pv-fo-price">
              {{ calculateFlightPrice(trArrOption) }}
            </div>
          </template>
          <MMenuWrapper @close:menu="trArrOption.showInfo = false">
            <template #target>
              <MIcon
                class="pv-icon pv-fo-details"
                name="m-info"
                @click="trArrOption.showInfo = !trArrOption.showInfo"
              />
            </template>

            <template #menu>
              <MCard
                class="pv-fo-menu m-index-level-two"
                v-show="trArrOption.showInfo"
              >
                <div class="pv-fo-title">RBD’s Pricing Details</div>
                <div class="pv-fo-code">
                  {{ getRbdNameHandler(trDepOption.rbd) }} -
                  {{ getRbdNameHandler(trArrOption.rbd) }}
                </div>

                <div class="pv-fo-summary">
                  <div class="pv-fo-row">
                    <div class="title">Base Fare</div>
                    <div class="price">
                      {{
                        getFormattedCurrency(trArrOption.price.base_fare.value)
                      }}
                    </div>
                  </div>
                  <div class="pv-fo-row">
                    <div class="title">Tax</div>
                    <div class="price">
                      {{ getFormattedCurrency(trArrOption.price.tax.value) }}
                    </div>
                  </div>
                  <div class="pv-fo-row">
                    <div class="title">Gross Fare</div>
                    <div class="price">
                      {{
                        getFormattedCurrency(trArrOption.price.gross_fare.value)
                      }}
                    </div>
                  </div>
                  <div
                    class="pv-fo-row"
                    v-if="trArrOption.price.ag_price_difference"
                  >
                    <div class="title">
                      {{
                        getPriceDifferenceLabel(
                          trArrOption.price.ag_price_difference.value
                        )
                      }}
                    </div>
                    <div class="price">
                      {{
                        getFormattedCurrency(
                          trArrOption.price.ag_price_difference.value
                        )
                      }}
                    </div>
                  </div>
                  <div
                    class="pv-fo-row"
                    v-if="trArrOption.price.ag_deal_discount"
                  >
                    <div class="title">Deal Discount</div>
                    <div class="price">
                      {{
                        getFormattedCurrency(
                          trArrOption.price.ag_deal_discount.value
                        )
                      }}
                    </div>
                  </div>
                  <div
                    class="pv-fo-row"
                    v-if="getFareCalculatorDifference(trArrOption).amount !== 0"
                  >
                    <div class="title">
                      {{ getFareCalculatorDifference(trArrOption).label }}
                    </div>
                    <div class="price">
                      {{ getFareCalculatorDifference(trArrOption).formatted }}
                    </div>
                  </div>

                  <MDivider class="pv-fo-divider" />

                  <div class="pv-fo-row total">
                    <div class="title">Total</div>
                    <div class="price">
                      {{ calculateFlightPrice(trArrOption) }}
                    </div>
                  </div>
                </div>
              </MCard>
            </template>
          </MMenuWrapper>
        </template>
      </div>
    </div>

    <div class="pv-fare-option">
      <div class="pv-fo-title">
        <b>Return</b>
      </div>
      <div class="pv-fo-title">{{ getRbdNameHandler(trArrOption.rbd) }}</div>
      <div class="pv-fo-info">
        <MIcon
          class="pv-icon primary"
          :name="`${trArrOption.has_meal ? 'm-meal' : 'm-no-meal'}`"
        />
        <MDivider type="vertical" />

        <MIcon
          class="pv-icon primary"
          :name="`${trArrOption.baggage_info ? 'm-baggage' : 'm-no-baggage'}`"
        />

        <div>
          {{ getBaggageInfo(trArrOption.baggage_info) }}
        </div>
      </div>
      <div class="pv-fo-actions">
        <div class="pv-fo-price" v-if="isPrinting">
          {{ calculateFlightPrice(trArrOption) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { MIcon, MDivider, MMenuWrapper } from "@aeroglobe/ag-core-ui";

import { BaggageInfo, FareOption, Price } from "@/ag-flight-components/types";
import UTILS from "@/ag-portal-common/utils";
import {
  TemperFare,
  TransformedFareOption,
} from "../../FlightSearchResult/types";

export default defineComponent({
  name: "SingleFareOptions",
  components: {
    MIcon,
    MDivider,
    MMenuWrapper,
  },
  props: {
    departureFareOption: {
      type: Object as PropType<FareOption>,
      required: true,
    },
    arrivalFareOption: {
      type: Object as PropType<FareOption>,
      required: true,
    },
    temperFare: {
      type: Object as PropType<TemperFare>,
      required: true,
    },
    isPrinting: {
      type: Boolean,
      default: false,
    },
    isCompactView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      trDepOption: {
        ...this.departureFareOption,
        showInfo: false,
      } as TransformedFareOption,
      trArrOption: {
        ...this.arrivalFareOption,
        showInfo: false,
      } as TransformedFareOption,
    };
  },
  emits: ["select:fare"],
  methods: {
    calculateFlightPrice(fareOption: FareOption): string {
      const calculatedPrice = UTILS.calculateFlightPrice(
        fareOption,
        this.temperFare
      );

      return this.getFormattedCurrency(calculatedPrice);
    },
    getBaggageInfo(baggageInfo?: BaggageInfo | null): string {
      return UTILS.getBaggageInfo(baggageInfo);
    },
    getFormattedCurrency(amount: number): string {
      return UTILS.getFormattedCurrency(amount);
    },
    getTotalAmount(price: Price): number {
      let amount = 0;

      if (price.ag_gross_fare && price.ag_gross_fare.value) {
        amount = price.ag_gross_fare.value;
      } else {
        amount = price.gross_fare.value;
      }

      return amount;
    },
    getFareCalculatorDifference(fareOption: FareOption): {
      label: string;
      amount: number;
      formatted: string;
    } {
      const fcAmonut = UTILS.calculateFlightPrice(fareOption, this.temperFare);
      const totalAmount = this.getTotalAmount(fareOption.price);
      const difference = fcAmonut - totalAmount;

      return {
        label: difference > 0 ? "FC Fees" : "FC Discount",
        amount: difference,
        formatted: this.getFormattedCurrency(difference),
      };
    },
    getPriceDifferenceLabel(amount?: number | null): string {
      if ((amount ?? 0) > 0) {
        return "Fee";
      } else {
        return "Discount";
      }
    },
    getRbdNameHandler(name: string): string {
      return name.length === 1 ? `RDB: ${name}` : name;
    },
    onSelectFareHandler(fareOption: FareOption): void {
      this.$emit("select:fare", fareOption);
    },
  },
});
</script>

<style scoped lang="css">
@import url("../styles/paired-fare-options.css");
</style>
