<template>
  <ag-heading class="margin_bottom_10" title="Price Summary" variant="h2" />
  <AgPriceSummary
    v-for="(item, index) in priceInfoCells"
    v-show="true"
    v-bind:key="index"
    :info="item.info"
    :price="item.amount"
    :total-price="item.isTotalPriceCell"
    test-id=""
  />
  <slot name="flightDetailsActionButtons"></slot>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IPriceSummary } from "@/modules/FDG/interface/priceSummary.interface";

export default defineComponent({
  name: "PriceSummary",
  props: {
    priceInfoCells: { type: Object as PropType<IPriceSummary[]> },
  },
});
</script>
