import { GetterTree } from "vuex";
import { StoreState } from "@/store/type";
import {
  IFlightSearch,
  IMultiCityAirports,
} from "@/ag-flight-components/interfaces/IFlightSearch.interface";

const getters: GetterTree<IFlightSearch, StoreState> = {
  isPolling: (state): boolean => state.isPolling,
  flightsResponse: (state) => state.flightsResponse,
  isFlightsLoading: (state): boolean => state.isFlightFetching,
  isAirportFetching: (state): boolean => state.isAirportFetching,
  loadingOriginAirports: (state): boolean => state.loadingOriginAirports,
  loadingDestinationAirports: (state): boolean =>
    state.loadingDestinationAirports,
  airports: (state) => state.airports,
  originAirports: (state) => state.originAirports,
  destinationAirports: (state) => state.destinationAirports,
  flights: (state) => state.flights,
  filters: (state) => state.filters,
  currentJourneyLeg: (state) =>
    state.flightsResponse.journey_legs[state.currentLegIndex],
  nextJourneyLeg: (state) => state.flightsResponse.journey_legs[1],
  allJourneyLegs: (state) => state.flightsResponse.journey_legs,
  allSelectdFlightFilters: (state) => state.selectedFlightFilters,
  selectedAmountType: (state) => state.amountType,
  allSelectedFlightLegs: (state) => state.selectedFlightLegs,
  currentLegIndex: (state) => state.currentLegIndex,
  currentRouteType: (state) => state.route_type,
  getMulticityLegs: (state) => state.multiCityLegs,

  journeyLegs: (state) => state.flightsResponse.journey_legs,

  sabreAirlines: (state): IFlightSearch["sabreAirlines"] => state.sabreAirlines,
  galileoAirline: (state): IFlightSearch["galileoAirline"] =>
    state.galileoAirline,
  multiCityAirports: (state): IMultiCityAirports[] => state.multiCityAirports,
  flightProviders: (state): IFlightSearch["flightProviders"] =>
    state.flightProviders,
  multicityInputLoadingType: (state): string | null =>
    state.currentMultiCityAirportInputTypeLoading,
  multicityInputLoadingIndex: (state): number | null =>
    state.currentMultiCityAirportInputIndexLoading,
};

export default getters;
