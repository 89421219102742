import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAgentFinances } from "@/ag-portal-common/interfaces/agentFinances.interface";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { formatString } from "@/ag-portal-common/utils/helpers";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { CACHE_NAMES } from "@/ag-portal-common/constants";
import { GetFinancesResponseModel } from "../interfaces/AgentFinance";
import {
  AGENT_FINANCES_API_PATH,
  AgentFinancesMapper,
} from "../constants/agentFinance";

class AgentFinanceService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public async fetchFinancesById(
    id: string,
    isFetchNewData: boolean
  ): Promise<IAgentFinances> {
    const methodName = "AgentFinanceService.fetchFinancesById";
    let mappedFinances: IAgentFinances = {
      availableCredit: 0,
      currentBalance: 0,
      currentLimit: 0,
      currency: "",
      permanentCreditLimit: 0,
      temporaryCreditLimit: 0,
      totalNotInvoicedTicket: 0,
      statusRemarks: "",
      accountStatus: "",
    };
    try {
      if (isFetchNewData) {
        await caches.delete(CACHE_NAMES.balance);
      }
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<GetFinancesResponseModel> =
        await this.restClient.get(
          formatString(AGENT_FINANCES_API_PATH.GET_BY_ID, id)
        );

      if (response.success && response.data) {
        const agentFinancesMapper = new AgentFinancesMapper();
        mappedFinances = agentFinancesMapper.mapAgentFinancesResponse(
          response.data
        );
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    return mappedFinances;
  }
}

export default AgentFinanceService;
