<template>
  <div
    class="summary-container"
    v-for="(leg, cardIndex) in legs"
    :key="cardIndex"
  >
    <AgTripSummary
      v-for="(segment, index) in leg.segment"
      :key="index"
      test-id=""
      :count="cardIndex + 1"
      :departure-date="renderDate(segment?.departure_datetime)"
      :arrival-date="renderDate(segment?.arrival_datetime)"
      :from-destination-code="segment?.origin?.iata_code"
      :from-destination-name="segment?.origin?.city"
      :from-destination-time="renderDate(segment?.departure_datetime, 'HH:mm')"
      :to-destination-code="segment?.destination?.iata_code"
      :to-destination-name="segment?.destination?.city"
      :to-destination-time="renderDate(segment?.arrival_datetime, 'HH:mm')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { FORMAT_DD_MMM_YYYY_WITH_DASH } from "@/ag-portal-common/constants/dateTimeFormats";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";
import { FlightLeg } from "@/ag-flight-components/types";

export default defineComponent({
  name: "TripSummary",
  props: {
    legs: {
      type: Array as () => Array<FlightLeg>,
    },
  },
  methods: {
    renderDate: (date: Date, format = FORMAT_DD_MMM_YYYY_WITH_DASH) => {
      return date ? TimeUtility.parsedFlightTimeorDate(date, format) : "";
    },
  },
});
</script>

<style lang="scss" scoped>
.summary-container {
  margin-bottom: 25px;
}
</style>
