import { PERMISSIONS } from "@/ag-portal-common/enums/PERMISSIONS";

export enum USER_ROLES {
  AGENT = 1,
  OPERATIONS = 2,
  FINANCE = 3,
  SALES = 4,
  MANAGEMENT = 5,
  SUPER_ADMIN = 6,
  AG_SUPER_USER = 7,
  SUB_AGENT = 1000001,
  EMPLOYEE = 2000001,
}

export type UserRoleSubType = keyof typeof USER_ROLES;

export interface User {
  first_name: string;
  last_name: string;
  email: string;
  role_unique_id: USER_ROLES;
  role_name: string;
  phone_number: string;
  role_sub_type: UserRoleSubType;
  agent_user_data: AgentUserData;
  sales_user_data: SalesUserData;
}

export interface AgentUserData {
  id?: string;
  organization?: Organization;
}

export interface SalesUserData {
  sectors?: Sector[];
}

export enum ORGANIZATION_STATUS {
  DOCUMENTS_PENDING = "DOCUMENTS_PENDING",
  FINANCIAL_PROFILE_PENDING = "FINANCIAL_PROFILE_PENDING",
  FINANCIAL_PROFILE_ATTACHED = "FINANCIAL_PROFILE_ATTACHED",
  VERIFIED = "VERIFIED",
}

export enum ORGANIZATION_SOURCE {
  SAAS = "SAAS",
  SALES = "SALES",
}

export interface Organization {
  organization_id: string;
  organization_name: string;
  sector_name: string;
  city: string;
  booking_template?: string;
  currency: string;
  org_display_name: string | null;
  org_default_color: string;
  org_logo: string | null;
  org_name_to_display: string;
  financial_profiles: FinancialProfile[];
  organization_status: ORGANIZATION_STATUS;
  source: ORGANIZATION_SOURCE;
}

export interface FinancialProfile {
  public_id: string;
  financial_profile_name: string;
  platform_id: string;
  is_default: boolean;
  status: string;
  plan_name: string;
  bank_details: BankDetails;
  sector?: string | null;
}

export interface BankDetails {
  bank_name: string;
  bank_title: string;
  bank_account_no: string;
  bank_iban: string;
}

export interface Sector {
  sector_name: string;
  region: string;
  city: string;
  sales_email: string[];
  tkt_issuance_emails: string[];
}

export interface AuthToken {
  access: string;
  refresh: string;
}

export interface LoginResponse {
  user: User;
  token: AuthToken;
  permissions: PERMISSIONS[];
}
