import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgHeading = _resolveComponent("AgHeading")!
  const _component_AgDiv = _resolveComponent("AgDiv")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AgHeading, {
      title: "Cancellation Policy",
      variant: "h3",
      "test-id": ""
    }),
    _withDirectives(_createVNode(_component_AgDiv, null, {
      default: _withCtx(() => [
        _createElementVNode("p", null, "Free Cancellation until " + _toDisplayString(_ctx.freeCancellationDate), 1)
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.freeCancellationDate]
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nonRefundablePolicies, (term, index) => {
      return (_openBlock(), _createBlock(_component_AgDiv, {
        style: {"display":"flex","justify-content":"space-between"},
        key: index
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, "From " + _toDisplayString(_ctx.formatDate(term.start_date)), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.getPrice(
          term.cancellation_amount.value,
          term.cancellation_amount.currency
        )), 1)
        ]),
        _: 2
      }, 1024))
    }), 128))
  ], 64))
}