import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { DEALS_API_PATH } from "@/modules/Deals/constants";
import { PROMOS_AND_DEALS } from "@/modules/Deals/constants";
class DealsService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public getOrganizationPromos(
    organizationId: string
  ): Promise<IAGResponse<IAGResponse<PROMOS_AND_DEALS>>> {
    return this.restClient.get(
      DEALS_API_PATH.GET_ORGANIZATION_DEALS_AND_PROMOS(organizationId)
    );
  }
}

export default DealsService;
