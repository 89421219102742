<template>
  <div class="error-404">
    <Error40x trailingText="4" />

    <MTypography type="display"> Page Not Found </MTypography>
    <MTypography type="body">
      <span>
        The requested URL
        <span class="current-route">
          {{ currentRoute }}
        </span>
        doesn't exists on this portal. Please navigate to
        <span>
          <router-link to="/dashboard">Dashboard</router-link>
        </span>
      </span>
    </MTypography>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MTypography } from "@aeroglobe/ag-core-ui";
import Error40x from "../../../components/Error40x.vue";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "Error404View",
  components: {
    Error40x,
    MTypography,

    RouterLink,
  },
  computed: {
    currentRoute(): string {
      return this.$route.path;
    },
  },
});
</script>

<style scoped lang="scss">
.error-404 {
  --theme-color: var(--green-color);

  max-width: 1000px;
  width: 100%;
  margin: 0 auto;

  min-height: calc(100vh - 120px);

  padding: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.error-404 .current-route {
  color: var(--theme-color);
}
</style>
