import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f382c4d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "status-container" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "price-chip-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_bookings_filters_view = _resolveComponent("my-bookings-filters-view")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_AGDropDownButtonItem = _resolveComponent("AGDropDownButtonItem")!
  const _component_AGButtonDropDown = _resolveComponent("AGButtonDropDown")!
  const _component_MFabButton = _resolveComponent("MFabButton")!
  const _component_MDataTable = _resolveComponent("MDataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_my_bookings_filters_view, { onFetchBookings: _ctx.onUpdateFilter }, null, 8, ["onFetchBookings"]), [
      [_vShow, _ctx.showFilters]
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_MDataTable, {
        "is-api-paginated": true,
        "total-item-count": _ctx.totalPageCount,
        headers: _ctx.headers,
        "item-per-page": 30,
        data: _ctx.bookings,
        "is-loading": _ctx.$store.getters.isFetchingTourBookings,
        onOnPageChange: _ctx.onPageChange
      }, {
        bookingId: _withCtx(({ item }) => [
          _createVNode(_component_router_link, {
            "data-test-id": 
            _ctx.genTestId(_ctx.ELEMENT_TYPES.LINK, `tour-booking-${item.bookingId}`)
          ,
            to: 
            _ctx.formatStringToRoutePath(_ctx.PATH.FDG_BOOKING_DETAILS, {
              id: item.bookingId,
            })
          
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MTypography, {
                class: "description td-full-width booking-id",
                type: "body"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.bookingId), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["data-test-id", "to"])
        ]),
        title: _withCtx(({ item }) => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_MTypography, {
              class: "description td-full-width",
              type: "label"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.title), 1)
              ]),
              _: 2
            }, 1024)
          ])
        ]),
        status: _withCtx(({ item }) => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_MChip, {
              variant: _ctx.renderStatusSeverity(item.status)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.renderStatus(item.status)), 1)
              ]),
              _: 2
            }, 1032, ["variant"])
          ])
        ]),
        bookingTime: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, {
            class: "description td-full-width",
            type: "body"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(item.bookingTime, _ctx.FORMAT_DD_MMM_YYYY_WITH_DASH)), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_MTypography, {
            class: "description",
            type: "label"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(item.bookingTime, _ctx.FORMAT_HH_SS_24_HOURS)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        travelers: _withCtx(({ item }) => [
          (_ctx.isInitiated(item?.status))
            ? (_openBlock(), _createBlock(_component_MTypography, {
                key: 0,
                class: "description td-full-width",
                type: "label"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" - ")
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_AGButtonDropDown, {
                  class: "table_dropdown_wrap",
                  text: "Test",
                  items: item.travelers
                }, {
                  label: _withCtx(() => [
                    _createElementVNode("i", null, [
                      _createVNode(_component_MTypography, {
                        class: "description td-full-width",
                        type: "label"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.travelers[0]), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.travelers, (dditem, index) => {
                      return (_openBlock(), _createBlock(_component_AGDropDownButtonItem, {
                        key: index,
                        name: dditem
                      }, null, 8, ["name"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["items"])
              ]))
        ]),
        startDate: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, {
            class: "description td-full-width",
            type: "body"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(item.startDate, _ctx.FORMAT_DD_MMM_YYYY_WITH_DASH)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        endDate: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, {
            class: "description td-full-width",
            type: "body"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(item.endDate, _ctx.FORMAT_DD_MMM_YYYY_WITH_DASH)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        number_of_guest: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, {
            class: "description td-full-width",
            type: "label"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.guests), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        tour_type: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, {
            class: "description td-full-width",
            type: "label"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.parse(item.tourType)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        price: _withCtx(({ item }) => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_MChip, {
              "border-less": true,
              variant: "success"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatCurrency(item.price, item.currency)), 1)
              ]),
              _: 2
            }, 1024)
          ])
        ]),
        voucher: _withCtx(({ item }) => [
          _createVNode(_component_MFabButton, {
            type: "outlined",
            icon: "m-download",
            onClick: ($event: any) => (_ctx.downloadVoucher(item.bookingId)),
            disabled: 
            _ctx.disableTourVoucher(
              item.status,
              _ctx.activeBookingIdForTourVoucher,
              item.bookingId
            )
          
          }, null, 8, ["onClick", "disabled"])
        ]),
        _: 1
      }, 8, ["total-item-count", "headers", "data", "is-loading", "onOnPageChange"])
    ])
  ], 64))
}