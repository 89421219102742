<template>
  <MCard class="banner_container" v-if="showCard">
    <div>
      <MTypography type="headline">Congratulations! 🎉</MTypography>
      <template v-if="isDocumentPendingStatus">
        <MTypography>
          You have successfully registered yourself with
          <b>Aeroglobe</b>.
        </MTypography>
        <MTypography>
          Now Let's complete your profile by uploading some required documents
        </MTypography>
        <router-link :to="PATH.ORGANIZATION_DOCUMENTS">
          <AGButton class="pulse"
            >Get Started: Upload Your Documents Now!</AGButton
          ></router-link
        >
      </template>
      <template v-if="isFinancialProfilePendingOrAttachedState">
        <MTypography>
          Your documents have been successfully uploaded.
        </MTypography>
        <MTypography>
          Our support team will review it in 1 or 2 days. Thanks
        </MTypography>
      </template>
      <template v-if="isVerifiedState">
        <MTypography>Your are now verified.</MTypography>
        <MTypography>Start your travel agency journey with us.</MTypography>
      </template>
    </div>
    <div class="progress_bar_container">
      <MProgress :progress="progress" :circular-size="64" />
      {{ progress }} % Profile Progress
    </div>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  ORGANIZATION_TYPES,
  SAAS_ELITE_ORGANIZATION_STATUS,
} from "@/ag-portal-common/enums/SAAS_ELITE_ORGANIZATION_STATUS";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";
import storageService from "@/ag-portal-common/services/storage.service";
import { Organization } from "@/modules/Auth/types";
import { MTypography } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "NewRegisteredUserBanner",
  components: {
    MTypography,
  },
  data() {
    return {
      PATH,
      progress: 0,
    };
  },

  methods: {
    getProgress(): number {
      const organization = this.parsedOrganization;
      const progresses: { [key: string]: number } = {
        [SAAS_ELITE_ORGANIZATION_STATUS.DOCUMENTS_PENDING]: 50,
        [SAAS_ELITE_ORGANIZATION_STATUS.FINANCIAL_PROFILE_PENDING]: 80,
        [SAAS_ELITE_ORGANIZATION_STATUS.FINANCIAL_PROFILE_ATTACHED]: 80,
        [SAAS_ELITE_ORGANIZATION_STATUS.VERIFIED]: 100,
      };

      if (organization && organization.organization_status in progresses) {
        return progresses[organization.organization_status];
      } else {
        return 0;
      }
    },
  },
  mounted() {
    this.progress = this.getProgress();
  },
  computed: {
    parsedOrganization(): any {
      const organizationData = storageService.getItem<Organization>(
        STORAGE_KEYS.ORGANIZATION
      );

      return organizationData;
    },
    showCard(): boolean {
      const organization = this.parsedOrganization;
      const organization_statuses = [
        SAAS_ELITE_ORGANIZATION_STATUS.DOCUMENTS_PENDING,
        SAAS_ELITE_ORGANIZATION_STATUS.FINANCIAL_PROFILE_PENDING,
        SAAS_ELITE_ORGANIZATION_STATUS.FINANCIAL_PROFILE_ATTACHED,
      ];
      const status: SAAS_ELITE_ORGANIZATION_STATUS =
        organization?.organization_status as SAAS_ELITE_ORGANIZATION_STATUS;

      return (
        organization?.source === ORGANIZATION_TYPES.SAAS &&
        organization_statuses.includes(status)
      );
    },
    isDocumentPendingStatus(): boolean {
      const organization = this.parsedOrganization;
      return (
        organization?.organization_status ===
        SAAS_ELITE_ORGANIZATION_STATUS.DOCUMENTS_PENDING
      );
    },
    isFinancialProfilePendingOrAttachedState(): boolean {
      const organization = this.parsedOrganization;
      const organization_statuses = [
        SAAS_ELITE_ORGANIZATION_STATUS.FINANCIAL_PROFILE_PENDING,
        SAAS_ELITE_ORGANIZATION_STATUS.FINANCIAL_PROFILE_ATTACHED,
      ];
      const status: SAAS_ELITE_ORGANIZATION_STATUS =
        organization?.organization_status as SAAS_ELITE_ORGANIZATION_STATUS;
      return organization_statuses.includes(status);
    },
    isVerifiedState(): boolean {
      const organization = this.parsedOrganization;
      return (
        organization?.organization_status ===
        SAAS_ELITE_ORGANIZATION_STATUS.VERIFIED
      );
    },
  },
});
</script>

<style scoped lang="css">
.banner_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.progress_bar_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.pulse {
  border-radius: 10px;
  animation: pulse 1.5s infinite;
}

.active {
  color: var(--green-color) !important;
  border-radius: 10px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.7;
    box-shadow: 0 0 0 1px var(--green-color) !important;
  }
}
</style>
