import { ActionContext, ActionTree } from "vuex";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { IFareManagementState } from "@/interfaces/fareManagementState.interface";
import FareManagementService from "@/modules/FareManagement/services/faremanagement.service";
import { StoreState } from "@/store/type";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { StatusCodes } from "http-status-codes";

const actions: ActionTree<IFareManagementState, StoreState> = {
  async fetchSabreFares(
    context: ActionContext<IFareManagementState, StoreState>,
    payload: { pnr: string; airlineCode: string }
  ) {
    const methodName = "actions.fetchSabreFares";
    context.commit("setSabreFaresLoading", true);
    context.commit("resetSabreFares", null);
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const fareManagementService = new FareManagementService();
      const response = await fareManagementService.getFares(
        payload.pnr,
        payload.airlineCode
      );
      if (
        response.success &&
        response.status === StatusCodes.OK &&
        response.data?.data
      ) {
        context.commit("saveSabreFares", response.data?.data);
      } else {
        throw response;
      }
    } catch (error: any) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, error);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = error?.message || "";
      notificationService.triggerNotification();
    } finally {
      context.commit("setSabreFaresLoading", false);
    }
  },
  async applyFares(
    context: ActionContext<IFareManagementState, StoreState>,
    reference: string
  ) {
    const methodName = "actions.applyFares";
    context.commit("setSabreFaresLoading", true);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

      const fareManagementService = new FareManagementService();
      const response = await fareManagementService.saveFares(reference);
      context.commit("setSabreFaresLoading", false);
      context.commit("resetSabreFares", null);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
      if (
        response.success &&
        response.status === StatusCodes.OK &&
        response.data?.data
      ) {
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = response.data?.message || "";
        notificationService.triggerNotification();
      } else {
        throw response;
      }
    } catch (error: any) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, error);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = error?.message || "";
      notificationService.triggerNotification();
    } finally {
      context.commit("setSabreFaresLoading", false);
    }
  },
};

export default actions;
