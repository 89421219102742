import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { CREDIT_LIMIT_MANAGEMENT_API_PATH } from "@/modules/CreditLimitManagement/constants";
import { CreditLimitRequestsResponse } from "@/modules/CreditLimitManagement/models/fetchCreditLimitRequests.response";
import { CreditLimitManagementMapper } from "@/modules/CreditLimitManagement/responseMappers/creditLimitManagement.mapper";
import { IPaginatedCreditLimitRequests } from "@/interfaces/creditLimitRequest.interface";
import { FetchCreditLimitRequestsParamsRequest } from "@/modules/CreditLimitManagement/models/fetchCreditLimitRequestsParams.request";
import { CreateCreditLimitRequestsBody } from "@/modules/CreditLimitManagement/models/createCreditLimitRequestsBody.request";
import { CreateCreditLimitRequestResponse } from "@/modules/CreditLimitManagement/models/createCreditLimitRequests.response";
import { formatString } from "@/ag-portal-common/utils/helpers";
import { CreditLimitRequestDetailResponse } from "@/modules/CreditLimitManagement/models/creditLimitRequestDetail.response";
import { ICreditLimitRequestDetail } from "@/interfaces/creditLimitRequestDetail.interface";
import { ProcessCreditLimitRequestBody } from "@/modules/CreditLimitManagement/models/processCreditLimitRequestBody.request";

class CreditLimitManagementService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public async fetchCreditLimitRequests(
    params: FetchCreditLimitRequestsParamsRequest
  ): Promise<IPaginatedCreditLimitRequests> {
    const methodName = "CreditLimitManagementService.fetchCreditLimitRequests";
    const creditLimitManagementMapper: CreditLimitManagementMapper =
      new CreditLimitManagementMapper();
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<CreditLimitRequestsResponse> =
        await this.restClient.get(CREDIT_LIMIT_MANAGEMENT_API_PATH.GET, params);

      if (response.success && response.data) {
        creditLimitManagementMapper.mapCreditLimitRequestsResponse(
          response.data
        );
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    return creditLimitManagementMapper.getCreditLimitRequests();
  }

  public async fetchCreditLimitRequestDetail(
    id: string
  ): Promise<ICreditLimitRequestDetail> {
    const methodName =
      "CreditLimitManagementService.fetchCreditLimitRequestDetail";
    const creditLimitManagementMapper: CreditLimitManagementMapper =
      new CreditLimitManagementMapper();

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<CreditLimitRequestDetailResponse> =
        await this.restClient.get(
          formatString(CREDIT_LIMIT_MANAGEMENT_API_PATH.GET_BY_ID, id)
        );

      if (response.success && response.data) {
        creditLimitManagementMapper.mapCreditLimitRequestDetailResponse(
          response.data
        );
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    return creditLimitManagementMapper.getCreditLimitRequestDetail();
  }

  public async createCreditLimitRequest(
    body: CreateCreditLimitRequestsBody,
    callback?: () => void
  ): Promise<void> {
    const methodName = "CreditLimitManagementService.createCreditLimitRequest";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<CreateCreditLimitRequestResponse> =
        await this.restClient.post(CREDIT_LIMIT_MANAGEMENT_API_PATH.POST, body);

      if (response.success && response.data) {
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          NOTIFICATION_MESSAGES.CREATE_CREDIT_LIMIT_REQUEST_SUCCESS;
        if (callback) {
          callback();
        }
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    notificationService.triggerNotification();
  }

  public async processCreditLimitRequest(
    body: ProcessCreditLimitRequestBody
  ): Promise<void> {
    const methodName = "CreditLimitManagementService.processCreditLimitRequest";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<CreateCreditLimitRequestResponse> =
        await this.restClient.post(
          CREDIT_LIMIT_MANAGEMENT_API_PATH.POST_PROCESS,
          body
        );

      if (response.success && response.data) {
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        if (body.status) {
          notificationService.description =
            NOTIFICATION_MESSAGES.APPROVE_CREDIT_LIMIT_REQUEST_SUCCESS;
        } else {
          notificationService.description =
            NOTIFICATION_MESSAGES.REJECT_CREDIT_LIMIT_REQUEST_SUCCESS;
        }
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    notificationService.triggerNotification();
  }

  public async fetchCreditLimitSlabs(): Promise<any> {
    const methodName = "CreditLimitManagementService.fetchCreditLimitSlabs";
    let response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      response = await this.restClient.get(
        CREDIT_LIMIT_MANAGEMENT_API_PATH.GET_CREDIT_LIMIT_SLABS
      );
      if (response.success && response.data) {
        return response.data?.data?.slabs || [];
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
  }
}

export default CreditLimitManagementService;
