<template>
  <MCard class="insights_header">
    <MTypography type="headline">Digital Payments</MTypography>
    <div class="insights_header_action_btn">
      <MDatePicker
        label="Start Date"
        :start-date="defaultStartDate"
        :max-date="endDate"
        @onRangeDateChange="(value) => (startDate = value)"
        placeholder="Start Date"
        dateType="startDate"
        :multiCalendar="false"
        :hasRange="false"
        :autoPosition="true"
      />
      <MDatePicker
        label="End Date"
        :start-date="new Date()"
        :max-date="new Date()"
        :min-date="startDate"
        @onRangeDateChange="(value) => (endDate = value)"
        placeholder="End Date"
        dateType="endDate"
        :multiCalendar="false"
        :hasRange="false"
        :autoPosition="true"
      />
      <MButton :disabled="isLoading" @click="fetchData" class="margin_bottom_20"
        >Fetch</MButton
      >
    </div>
  </MCard>

  <ShimmerCard v-if="isLoading" card-height="300px" />
  <MCard class="chart_card" v-else>
    <highcharts class="hc" :options="digitalPaymentsCreatedChart" ref="chart" />
  </MCard>
</template>

<script>
import Highcharts from "highcharts";

import ShimmerCard from "@/components/ShimmerCard.vue";
import { sub, format } from "date-fns";
import exportingInit from "highcharts/modules/exporting";

import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { MTypography } from "@aeroglobe/ag-core-ui";

exportingInit(Highcharts);

export default {
  data() {
    return {
      startDate: new Date(),
      endDate: new Date(),
    };
  },
  created() {
    this.startDate = this.defaultStartDate;
  },
  computed: {
    defaultStartDate() {
      const today = new Date();
      const daysSpan = new Date(today);
      daysSpan.setDate(today.getDate() - 90);
      return daysSpan;
    },
    isLoading() {
      return this.$store.getters.isFetchingPayments;
    },
    payments() {
      return this.$store.getters.paymentsBySectors || [];
    },
    digitalPaymentsCreatedChart() {
      return {
        chart: {
          type: "column",
        },
        title: {
          text: "Payments Breakdown By Sectors",
          align: "center",
        },
        xAxis: {
          categories: this.generatePaymentChartSeries().categories,
          title: {
            text: null,
          },
          gridLineWidth: 1,
          lineWidth: 0,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Payments",
            align: "high",
          },
          labels: {
            overflow: "justify",
          },
          gridLineWidth: 0,
        },
        tooltip: {
          valueSuffix: "",
        },
        plotOptions: {
          bar: {
            borderRadius: "0px 50px 50px 0px",
            dataLabels: {
              enabled: true,
            },
            groupPadding: 0.1,
            colors: ["#91e8e1", "#aaeeee", "#492970"],
          },
        },
        credits: {
          enabled: false,
        },
        series: this.generatePaymentChartSeries().series,
      };
    },
    minDate() {
      const minDate = sub(this.date, { days: 7 });
      return minDate;
    },
  },
  components: {
    ShimmerCard,
  },
  methods: {
    generatePaymentChartSeries() {
      const paymentsData = this.payments || {};
      let categories = [];
      let series = {};

      // Extract categories and initialize series
      for (let location in paymentsData) {
        categories.push(location);
        for (let transactionType in paymentsData[location]) {
          if (!series[transactionType]) {
            series[transactionType] = {
              name: transactionType,
              data: [],
            };
          }
        }
      }

      // Populate series data
      for (let i = 0; i < categories.length; i++) {
        let location = categories[i];
        for (let transactionType in series) {
          let value = paymentsData[location][transactionType] || null;
          series[transactionType].data.push(value);
        }
      }

      return {
        categories: categories,
        series: Object.values(series),
      };
    },
    generatePaymentSeriesData() {
      if (this.payments.length === 0) {
        // If payments are empty, create a default series with zero counts for each sector
        const defaultSeries = [
          {
            name: "Aergolobe",
            data: ["No payments Found"],
          },
        ];
        return defaultSeries;
      }

      const sectorCounts = this.payments.reduce((counts, org) => {
        const sector = org.sector;
        counts[sector] = (counts[sector] || 0) + 1;
        return counts;
      }, {});

      // Convert the map to Highcharts series format
      const seriesData = Object.keys(sectorCounts).map((sector) => ({
        name: sector,
        data: [sectorCounts[sector]],
      }));

      return seriesData;
    },
    fetchData() {
      const payload = {
        start_date: format(new Date(this.startDate), FORMAT_YYY_MM_DD),
        end_date: format(new Date(this.endDate), FORMAT_YYY_MM_DD),
      };
      this.$store.dispatch("fetchPaymentsBySector", payload);
    },
  },
  beforeMount() {
    this.fetchData();
  },
  unmounted() {
    this.$store.dispatch("setPaymentsByMethods", {});
  },
};
</script>

<style lang="scss" scoped>
.insights_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
}

.insights_header_action_btn {
  display: flex;
  align-items: center;

  > :first-child {
    margin-right: 10px;
  }
  > :nth-child(2) {
    margin-right: 10px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
}
.chart_card {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
