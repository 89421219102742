import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { DASHBOARD_API_PATH } from "../constants";
import { IDashboardState } from "@/ag-portal-common/interfaces/dashboard.interface";

class DashboardServices {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public getInsights(
    value: number,
    aggregated_by: string
  ): Promise<IAGResponse<IDashboardState>> {
    return this.restClient.get(
      DASHBOARD_API_PATH.GET_INSIGHTS +
        `?value=${value}&aggregated_by=${aggregated_by}`
    );
  }
  public getDailyFlightIssuance(
    date: string | null
  ): Promise<IAGResponse<IDashboardState>> {
    return this.restClient.get(DASHBOARD_API_PATH.GET_FLIGHT_ISSUANCE_DATA, {
      date,
    });
  }
  public getDailyHotelIssuance(
    date: string | null
  ): Promise<IAGResponse<IDashboardState>> {
    return this.restClient.get(DASHBOARD_API_PATH.GET_HOTEL_ISSUANCE_DATA, {
      date,
    });
  }
  public getExpirySoon(): Promise<IAGResponse> {
    return this.restClient.get(DASHBOARD_API_PATH.GET_EXPIRY);
  }
}

export default DashboardServices;
