<template>
  <MCard id="m-flight-search-card" class="m-flight-search-card">
    <ag-row>
      <ag-column>
        <FlightSearchOptions :route-type="routeType" />
      </ag-column>
    </ag-row>

    <MulticityFlightSearch
      :errors="multicityError"
      :onFlightSearch="onFlightSearch"
      @validateSearchFeilds="validateSearchFlight"
      v-if="isMulticitySearch"
    >
      <template #financial-profile-combobox>
        <MFinancialProfileCombobox
          v-model:inputValue="selectedFinancialProfile"
          label="Financial Profiles"
          placeholder="Select Financial Profile"
          itemValue="value"
          itemLabel="label"
          :hasError="!!financialProfileErrorMessage"
          :errorMessage="financialProfileErrorMessage"
          :disabled="isFinancialProfilesLoading"
          :options="financialProfiles"
          isLargerDropdown
          focusedBorderEnabled
        />
      </template>
    </MulticityFlightSearch>
    <NormalFlightSearch
      :errors="normalError"
      :onFlightSearch="onFlightSearch"
      @validateSearchFeilds="validateSearchFlight"
      v-else
    >
      <template #financial-profile-combobox>
        <MFinancialProfileCombobox
          v-model:inputValue="selectedFinancialProfile"
          label="Financial Profiles"
          placeholder="Select Financial Profile"
          itemValue="value"
          itemLabel="label"
          :hasError="!!financialProfileErrorMessage"
          :errorMessage="financialProfileErrorMessage"
          :disabled="isFinancialProfilesLoading"
          :options="financialProfiles"
          isLargerDropdown
          focusedBorderEnabled
        />
      </template>
    </NormalFlightSearch>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ValidationError } from "yup";
import { format } from "date-fns";

import { ROUTE_TYPE } from "../../enums/route_type";
import { formatMultiCityQueryParam } from "../../utils";
import {
  normalFlightSearchValidation,
  createMulticityFlightSearchValidation,
} from "../../validations/flightSearchValidation";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { formatQueryPath } from "@/ag-portal-common/utils/helpers";
import analyticsService from "@/analytic.service";
import { FLIGHTS_ANALYTICS_COMMON_EVENTS } from "@/constants/analyticsEvents";
import NormalFlightSearch from "./NormalSearch.vue";
import MulticityFlightSearch from "./MulticitySearch.vue";
import FlightSearchOptions from "./FlightSearchOptions.vue";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import UTILS from "@/ag-portal-common/utils";
import getLocalbaseInstance from "@/ag-flight-components/constants/localbase";
import { MCard } from "@aeroglobe/ag-core-ui";
import _ from "lodash";
import { FinancialProfile, Organization } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import storageService from "@/ag-portal-common/services/storage.service";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";
import { SAAS_ELITE_ORGANIZATION_STATUS } from "@/ag-portal-common/enums/SAAS_ELITE_ORGANIZATION_STATUS";

export default defineComponent({
  name: "FlightSearchComponent",

  data() {
    return {
      multicityError: {},
      normalError: {},
      selectedFinancialProfile: "",
      financialProfileErrorMessage: "",
    };
  },
  computed: {
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    isMulticitySearch(): boolean {
      return (
        this.$store.state.flightModule.route_type === ROUTE_TYPE.MULTI_CITY
      );
    },
    parsedOrganization(): any {
      const organizationData = storageService.getItem<Organization>(
        STORAGE_KEYS.ORGANIZATION
      );

      return organizationData;
    },
    localFinancialProfiles(): FinancialProfile[] | null {
      return this.$store.getters[AUTH_GETTERS.FINANCIAL_PROFILES];
    },
    financialProfiles(): FPComboboxOptions[] {
      let financialProfiles;
      if (this.isSuperUserOrOperationUser) {
        financialProfiles = this.$store.getters.issuanceFinancialProfiles;
      } else {
        if (this.localFinancialProfiles) {
          financialProfiles = this.localFinancialProfiles;
        }
      }

      if (financialProfiles) {
        return financialProfiles?.map((fp: FinancialProfile) => {
          const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase();
          const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
          return {
            id: fp?.platform_id,
            label: fp?.financial_profile_name,
            value: fp?.public_id,
            isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
            status: fp?.status,
            sector: sector,
            type: planType,
          };
        });
      } else {
        return [];
      }
    },
    isFinancialProfilesLoading(): boolean {
      return this.$store.getters.isFinancialProfilesFetching;
    },

    isSuperUserOrOperationUser(): boolean {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER, USER_ROLES.OPERATIONS);
    },
    selectedOrganizationId(): string {
      const financialProfiles = this.$store.getters.issuanceFinancialProfiles;
      const selectedFPObj = financialProfiles?.find(
        (fp: IFinancialProfile) =>
          fp?.public_id === this.selectedFinancialProfile
      ) as IFinancialProfile;
      return selectedFPObj?.organization_public_id || "";
    },
    routeType(): ROUTE_TYPE {
      return this.$store.getters.currentRouteType;
    },
  },
  components: {
    NormalFlightSearch,
    MulticityFlightSearch,
    FlightSearchOptions,
    MCard,
  },
  methods: {
    handleDateFormat(date: Date | null): any {
      return date ? format(new Date(date), FORMAT_YYY_MM_DD) : null;
    },
    formatTrips(payload: any): string {
      if (this.isMulticitySearch) {
        const multiCityLegs = this.$store.state.flightModule.multiCityLegs;
        return formatMultiCityQueryParam(multiCityLegs).replace(/,$/, "");
      } else {
        return `${payload.origin},${payload.destination},${
          payload.departure_date
        }${
          this.handleDateFormat(payload.return_date)
            ? "," + this.handleDateFormat(payload.return_date)
            : ""
        }`.trim();
      }
    },

    async validateSearchFlight() {
      const multiCityLegs = this.$store.state.flightModule.multiCityLegs;

      try {
        if (this.isMulticitySearch) {
          if (!_.isEmpty(this.multicityError)) {
            this.multicityError = {};
            await createMulticityFlightSearchValidation().validate(
              multiCityLegs,
              {
                abortEarly: false,
              }
            );
          }
        } else {
          if (!_.isEmpty(this.normalError)) {
            this.normalError = {};
            await normalFlightSearchValidation.validate(
              {
                origin: this.$store.state.flightModule?.origin?.iata_code,
                destination:
                  this.$store.state.flightModule?.destination?.iata_code,
                departure_date: this.$store.state.flightModule?.departure_date,
                return_date: this.$store.state.flightModule?.return_date,
                route_type: this.routeType,
              },
              { abortEarly: false }
            );
          }
        }
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const err = yupValidationErrorAsSchema(ex);
          if (this.isMulticitySearch) {
            this.multicityError = err;
          } else {
            this.normalError = err;
          }
        }
      }
    },

    async searchFlight(payload: any) {
      const db = getLocalbaseInstance();
      const organization = this.parsedOrganization;
      const progresses = [
        SAAS_ELITE_ORGANIZATION_STATUS.DOCUMENTS_PENDING,
        SAAS_ELITE_ORGANIZATION_STATUS.FINANCIAL_PROFILE_PENDING,
        SAAS_ELITE_ORGANIZATION_STATUS.FINANCIAL_PROFILE_ATTACHED,
      ];
      if (!this.selectedFinancialProfile) {
        if (!progresses.includes(organization?.organization_status)) {
          this.financialProfileErrorMessage =
            "Please select financial profile for flight search";
          return;
        }
      }
      try {
        const multiCityLegs = this.$store.state.flightModule.multiCityLegs;

        if (payload?.route_type === ROUTE_TYPE.MULTI_CITY) {
          await createMulticityFlightSearchValidation().validate(
            multiCityLegs,
            {
              abortEarly: false,
            }
          );
        } else {
          await normalFlightSearchValidation.validate(
            {
              origin: this.$store.state.flightModule?.origin?.iata_code,
              destination:
                this.$store.state.flightModule?.destination?.iata_code,
              departure_date: this.$store.state.flightModule?.departure_date,
              return_date: this.$store.state.flightModule?.return_date,
              route_type: this.routeType,
            },
            { abortEarly: false }
          );
        }

        const trips = this.formatTrips(payload);
        const query = {
          adult: payload.traveler_count.adult_count,
          child: payload.traveler_count.child_count,
          infant: payload.traveler_count.infant_count,
          route_type: payload.route_type,
          cabin_class: payload.cabin_class,
          trips,
          fp: this.selectedFinancialProfile,
          ...(this.isSuperUserOrOperationUser && {
            org_id: this.selectedOrganizationId,
          }),
          ...(!this.isSuperUserOrOperationUser && {
            org_id: this.organization?.organization_id,
          }),
        };
        const path = `${UTILS.flightSearchResultsPath()}${formatQueryPath(
          query
        )}`;
        this.$router.push(path);
        const queryPayload = {
          path,
          ...payload,
          ...(payload?.route_type === ROUTE_TYPE.MULTI_CITY && {
            origin: multiCityLegs[0]?.origin?.iata_code,
            departure_date: format(
              new Date(multiCityLegs[0]?.departure_date),
              FORMAT_YYY_MM_DD
            ),
            destination:
              multiCityLegs[multiCityLegs?.length - 1]?.destination?.iata_code,
          }),
        };
        db.collection("flights").add({
          ...queryPayload,
          timestamp: new Date().getTime(),
          path,
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const err = yupValidationErrorAsSchema(ex);
          if (this.isMulticitySearch) {
            this.multicityError = err;
          } else {
            this.normalError = err;
          }
        }
      }
    },
    onFlightSearch() {
      const {
        origin,
        destination,
        departure_date,
        route_type,
        adult_count,
        child_count,
        infant_count,
        cabin_class,
        return_date,
      } = this.$store.state.flightModule;

      const isReturnType = route_type === ROUTE_TYPE.RETURN;

      const payload = {
        origin: origin?.iata_code,
        destination: destination?.iata_code,
        departure_date: this.handleDateFormat(departure_date),
        return_date: isReturnType ? this.handleDateFormat(return_date) : null,
        route_type: route_type,
        traveler_count: {
          adult_count: adult_count,
          child_count: child_count,
          infant_count: infant_count,
        },
        cabin_class: cabin_class,
        non_stop_flight: false,
      };

      this.searchFlight(payload);

      const analyticsPayload = {
        from: origin?.iata_code,
        to: destination?.iata_code,
        "departure-date": this.handleDateFormat(departure_date),
        "return-date": isReturnType ? this.handleDateFormat(return_date) : null,
        "route-type": route_type,
        "adult-traveler-count": adult_count,
        "child-traveler-count": child_count,
        "infant-traveler-count": infant_count,
        "cabin-class": cabin_class,
      };

      analyticsService.logActionEvent(
        FLIGHTS_ANALYTICS_COMMON_EVENTS.BOOKING_SEARCH,
        analyticsPayload
      );

      this.$emit("close_flight_search_dialog");
    },

    handleQuickChipClick(item: any) {
      this.$router.push(`${item?.fullPath}&from_chip=true`);
    },
    handleChipRouteType(type: ROUTE_TYPE) {
      if (type === ROUTE_TYPE.ONEWAY) {
        return "oneWayFlight";
      } else {
        return "returnFlight";
      }
    },
    changeDepartureDate(newDate: Date | null) {
      this.$store.commit("saveDepartureDate", newDate);
    },
    changeReturnDate(newDate: Date | null) {
      this.$store.commit("saveReturnDate", newDate);
    },
  },
  created() {
    if (this.isSuperUserOrOperationUser) {
      this.$store.dispatch("fetchFinancialProfiles", { is_linked: true });
    }
  },
  mounted() {
    if (this.isSuperUserOrOperationUser) {
      const urlParams = new URLSearchParams(window.location.search);
      const financial_profile_id = urlParams.get("fp");
      this.selectedFinancialProfile = financial_profile_id as string;
    } else if (this.localFinancialProfiles) {
      let defaultFinancialProfile = this.localFinancialProfiles.find(
        (item) => item.is_default
      );

      if (defaultFinancialProfile) {
        this.selectedFinancialProfile = defaultFinancialProfile.public_id;
      }
    }
  },

  watch: {
    selectedFinancialProfile: {
      handler(value) {
        if (value) {
          this.financialProfileErrorMessage = "";
        }
      },
    },
  },
});
</script>

<style lang="scss">
.fp-container {
  max-width: 600px;
}

.m-flight-search-card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  margin-top: 30px;
}
</style>
