import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cefa4a06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error-403" }
const _hoisted_2 = { class: "current-route" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Error40x = _resolveComponent("Error40x")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Error40x, { trailingText: "3" }),
    _createVNode(_component_MTypography, { type: "display" }, {
      default: _withCtx(() => [
        _createTextVNode(" Forbidden ")
      ]),
      _: 1
    }),
    _createVNode(_component_MTypography, { type: "body" }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, [
          _createTextVNode(" You don't have sufficient permissions to access this URL "),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.routeId), 1),
          _createTextVNode(" . Please navigate to "),
          _createElementVNode("span", null, [
            _createVNode(_component_router_link, { to: "/dashboard" }, {
              default: _withCtx(() => [
                _createTextVNode("Dashboard")
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    })
  ]))
}