<template>
  <MCard
    class="fi-expiry-cards"
    v-if="pnrDetails.pnr_expiry || pnrDetails.raw_pnr_expiry"
  >
    <div class="fi-expiry-card" v-if="pnrDetails.pnr_expiry">
      <div class="fi-card-title">PNR Expiry:</div>
      <div class="fi-card-subtitle">
        {{ getParsedDate(pnrDetails.pnr_expiry) }}
      </div>
    </div>

    <div v-if="pnrDetails.raw_pnr_expiry" class="fi-expiry-card">
      <div class="fi-card-title">Sabre Expiry:</div>
      <div class="fi-card-subtitle">
        {{ pnrDetails.raw_pnr_expiry[0] }}
      </div>
    </div>
  </MCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { MCard } from "@aeroglobe/ag-core-ui";
import { PNRDetails, PNRDetailsV3 } from "@/ag-flight-components/types";
import { parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export default defineComponent({
  name: "IssueTicketPNRExpiry",
  components: {
    MCard,
  },
  props: {
    pnrDetails: {
      type: Object as PropType<PNRDetails | PNRDetailsV3>,
      required: true,
    },
  },
  methods: {
    getParsedDate(date: string): Date {
      const sector = this.getSectorInfo();
      const expiryDate = parseISO(date);

      const targetDateInSectorTZ = utcToZonedTime(
        expiryDate,
        sector?.timeZone ? sector?.timeZone : "Asia/Karachi"
      );
      return targetDateInSectorTZ;
    },
    getSectorInfo() {
      const sectors = [
        {
          name: "Aeroglobe - Dubai",
          timeZone: "Asia/Dubai",
          countryTimeAbbreviation: "GST",
        },
        {
          name: "Aeroglobe - Riyadh",
          timeZone: "Asia/Riyadh",
          countryTimeAbbreviation: "SAST",
        },
      ];
      const currentSectorName: string = localStorage.getItem("sector") || "";
      const sector = sectors.find(
        (sector) => sector.name === currentSectorName
      );

      return sector;
    },
  },
});
</script>

<style lang="css" scoped>
.fi-expiry-cards {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
}
.fi-expiry-cards .fi-expiry-card {
  flex: 1;
}
.fi-expiry-cards .fi-card-title {
  font-weight: 600;
  color: var(--theme-color);
}
</style>
