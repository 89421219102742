<template>
  <MCard class="revent-chart">
    <div class="insights_overall_booking_header">
      <MTypography type="headline">Over All Bookings</MTypography>
      <div class="insights_header_action_btn">
        <MTextfield
          type="number"
          v-model:inputValue="value"
          label="Last"
          class="revent-chart-inputs"
        />
        <MCombobox
          v-model:inputValue="aggregated_by"
          label="Duration"
          itemValue="value"
          itemLabel="label"
          :options="items"
          name="Duration"
          class="revent-chart-inputs"
        />
        <MButton
          :is-loading="isLoading"
          loadingText="Fetching..."
          @click="fetchData"
        >
          Fetch
        </MButton>
      </div>
    </div>
    <div class="grid-container">
      <SnapshotTile
        v-for="(item, index) in $store.getters.bookingAggregate"
        :key="index"
        :heading="renderCategory(item.name)"
        subHeadingTitle="Booking Count"
        :subHeading="item.booking_count"
        :icon="renderIcon(item.name)"
      />
      <SnapshotTile
        v-for="(item, index) in $store.getters.bookingAggregate"
        :key="index"
        :heading="renderCategory(item.name)"
        subHeadingTitle="Earning"
        :subHeading="formattedEarnings(item.total_earnings)"
        :icon="renderIcon(item.name)"
      />
    </div>

    <highcharts class="hc" :options="chartOptions" ref="chart" />
  </MCard>
</template>

<script>
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";
import { capitalize } from "lodash";
import UTILS from "@/ag-portal-common/utils";
import { MButton, MTypography } from "@aeroglobe/ag-core-ui";
import SnapshotTile from "@/components/SnapshotTile.vue";

exportingInit(Highcharts);

export default {
  components: { SnapshotTile },
  data() {
    return {
      value: 5,
      aggregated_by: "day",
      items: [
        { label: "Days", value: "day" },
        { label: "Weeks", value: "week" },
        { label: "Months", value: "month" },
      ],
    };
  },
  computed: {
    getCurrency() {
      return UTILS.getCurrency() || "PKR";
    },
    isLoading() {
      return this.$store.getters.isDashboardInsightsLoading;
    },
    chartOptions() {
      const insights = this.$store.state.dashboardModule?.insights;
      const options = {
        chart: {
          type: "column",
        },
        title: {
          text: "Revenue Overview",
        },
        xAxis: {
          categories: insights?.x_legends?.map((item) => item.name),
          crosshair: true,
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: this.generateSeries(),
      };
      return options;
    },
  },
  methods: {
    generateSeries() {
      const insights = this.$store.state.dashboardModule?.insights;
      const seriesData = insights?.y_legends?.map((item) => ({
        ...item,
        data: [],
      }));
      insights?.matrix?.forEach((dataSet) => {
        dataSet.forEach((dataPoint, i) => {
          seriesData[i].data.push(dataPoint);
        });
      });
      return seriesData;
    },
    fetchData() {
      this.$store.dispatch("getInsights", {
        value: this.value,
        aggregated_by: this.aggregated_by,
      });
    },
    formattedEarnings(earning) {
      const currency = this.getCurrency;
      const formattedEarning = getCurrencyFormatter(currency).format(earning);
      return formattedEarning;
    },
    renderCategory(category) {
      return capitalize(category);
    },
    renderIcon(name) {
      const iconMap = {
        flight: "m-flight",
        hotel: "m-hotel",
        tour: "m-group-flights",
      };

      return iconMap[name] || "m-flight";
    },
  },
  beforeMount() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.insights_overall_booking_header {
  display: flex;
  justify-content: space-between;

  button {
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    display: block;
  }

  .insights_header_action_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    * {
      margin-right: 5px;
    }

    @media (max-width: 767px) {
      justify-content: flex-start;
      margin-top: 15px;
      margin-bottom: 0px;
    }
  }
}
</style>

<style lang="css" scoped>
.revent-chart {
  margin-bottom: 16px;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
}

@media (max-width: 1263px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 963px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
.revent-chart-inputs {
  max-width: 150px;
}
</style>
