<template>
  <v-window-item
    style="width: 100%; min-height;: 110px"
    value="f_info_tab_2"
    transition="fade"
    reverse-transition="fade"
  >
    <ag-loader v-if="loading" />
    <div v-else>
      <AgTicketRefundableType
        :origin="origin"
        :destination="destination"
        test-id=""
        :is-refundable="isRefundable"
      />
      <AgStaticTable
        test-id=""
        :columns="cancellation?.headings"
        :data="cancellation?.data"
      />
    </div>
  </v-window-item>

  <v-window-item
    style="width: 100%; min-height;: 110px"
    value="f_info_tab_3"
    transition="fade"
    reverse-transition="fade"
  >
    <ag-loader v-if="loading" />
    <AgStaticTable
      v-else
      test-id=""
      :columns="dateChange?.headings"
      :data="dateChange?.data"
    />
  </v-window-item>

  <v-window-item
    style="width: 100%; min-height;: 110px"
    value="f_info_tab_4"
    transition="fade"
    reverse-transition="fade"
  >
    <ag-loader v-if="loading" />
    <div v-else>
      <AgStaticTable
        test-id=""
        :columns="baggage?.headings"
        :data="baggage?.data"
      />
      <AgDiv test-id="" class="d-flex">
        <AgAlertBox
          text="Baggage allowance policy strictly enforced."
          color="#10B981"
          type="warning"
          test-id=""
        >
        </AgAlertBox>
      </AgDiv>
    </div>
  </v-window-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { StatusCodes } from "http-status-codes";

import FlightService from "@/ag-flight-components/services/flight.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";

export default defineComponent({
  name: "RulesTabs",
  props: {
    pre_booking_token: {
      type: String,
      required: true,
    },
    origin: {
      type: String,
      required: true,
    },
    destination: {
      type: String,
      required: true,
    },
    isRefundable: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  emits: ["fetch:rules"],
  data() {
    return {
      cancellation: {} as { headings: string[]; data: any[] },
      dateChange: {} as { headings: string[]; data: any[] },
      baggage: {} as { headings: string[]; data: any[] },
      loading: false,
    };
  },
  methods: {
    async getFlightRules(pre_booking_token: string) {
      const flightService = new FlightService();
      this.loading = true;
      const response: IAGResponse = await flightService.getFlightRules({
        pre_booking_token,
      });
      if (response.success && response.status === StatusCodes.OK) {
        const data = response.data.data;

        // Cancellation Table Data
        const cancellationData = data.cancellation_policy?.policy_rules?.map(
          (x: any) => {
            return {
              TIME: x.rule,
              "CANCELLATION FEE": x.value,
            };
          }
        );

        this.cancellation = {
          headings: ["TIME", "CANCELLATION FEE"],
          data: cancellationData,
        };

        // Date Change Table Data
        const dateChangeData = data.modification_policy?.policy_rules?.map(
          (x: any) => {
            return {
              TIME: x.rule,
              "CHANGE FEE": x.value,
            };
          }
        );

        this.dateChange = {
          headings: ["TIME", "CHANGE FEE"],
          data: dateChangeData,
        };

        // Baggage Table Data
        const baggageData = data.baggage_policy?.policy_rules?.map((x: any) => {
          return {
            BAGGAGE: x.rule,
            FEE: x.value,
          };
        });

        this.baggage = {
          headings: ["BAGGAGE", "FEE"],
          data: baggageData,
        };

        this.loading = false;
      } else {
        this.loading = false;
      }

      this.$emit("fetch:rules");
    },
  },
  async mounted() {
    await this.getFlightRules(this.pre_booking_token);
  },
});
</script>
