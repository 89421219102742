<template>
  <MProgress
    indeterminate
    v-if="isDownloadVisaLoading"
    class="mprogress-alignment"
  />
  <Transition :appear="true" name="router-view-fade-transition" v-else>
    <MCard>
      <div class="ag-logo">
        <a :href="'https://aeroglobe.io'">
          <img :src="'../../assets/logo.svg'" alt="Logo" />
        </a>
      </div>

      <div class="info-card">
        <GreetingsCard :user="name" />
        <TravelPage class="travel-svg" />
      </div>
      <div class="service-container">
        <div
          v-for="(service, index) in services"
          :key="index"
          class="service-item"
        >
          <div class="services">
            <img v-if="service.img" v-bind:src="service.img" class="img" />
            <div class="content">
              <MTypography type="headline" class="service-name">
                {{ service.name }}
              </MTypography>
              <MTypography type="label" class="service-description">
                {{ service.description }}
              </MTypography>
            </div>
          </div>
        </div>
      </div>
      <div class="fab">
        <a
          :href="getWhatsAppContactLink()"
          target="_blank"
          class="whatsappIcon"
        >
          <i class="fab fa-whatsapp"></i>
        </a>
      </div>
    </MCard>
  </Transition>
</template>

<script lang="ts">
import { MTypography } from "@aeroglobe/ag-core-ui";
import { defineComponent } from "vue";
import GreetingsCard from "../components/GreetingsCard.vue";
import TravelPage from "../components/TravelPage.vue";

export default defineComponent({
  name: "downloadVisaVerificationPDF",
  components: {
    GreetingsCard,
    TravelPage,
  },
  data() {
    return {
      name: this.$route.params.name,
      id: this.$route.params.id,
      services: [
        {
          img: "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/our-service-1.png?alt=media&token=817d3b00-27ae-4bb4-ac7a-99b58e736843",
          name: "Domestic and International Flight Bookings at the best rates",
          description:
            "We offer best price on all low cost carriers, Airblue, Airsial, Pakisan International Airlines and Serene Air etc. We also offer all major international airlines through Sabre, the GDS system",
          cta: "",
        },

        {
          img: "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/our-service-2.png?alt=media&token=035ed828-d037-4297-8f35-6b8252bdd2d1",
          name: "Domestic and International Hotels available through one window",
          description:
            "We have consolidated all domestic and international hotels on our state-of-the-art management portal. You can request for hotel bookings globally, powered by multiple local and international suppliers",
          cta: "",
        },

        {
          img: "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/our-service-3.png?alt=media&token=12f7648a-a6a0-4a76-aa97-77b4595ef7ac",
          name: "Group Bookings for groups of all sizes in cheapest rates",
          description:
            "We handle groups of all sizes for you domestically and internationally.You can choose to either book just the flights with us, or we can manage your group end-to-end with flights, hotels, ground transport and activites",
          cta: "",
        },
      ],
    };
  },
  computed: {
    isDownloadVisaLoading(): boolean {
      return this.$store.getters.isDownloadVisaLoading;
    },
  },
  methods: {
    getWhatsAppContactLink(): string {
      const number = "923111145623";
      return `https://wa.me/${number}`;
    },
  },
  mounted() {
    const htmlElement = document.querySelector("html");

    if (htmlElement) {
      htmlElement.style.overflow = "auto";
    }
  },
  unmounted() {
    const htmlElement = document.querySelector("html");

    if (htmlElement) {
      htmlElement.style.overflow = "hidden";
    }
  },
  async created() {
    this.$store.dispatch("openDownloadVisaPDF", {
      id: this.id,
      name: this.name,
    });
  },
});
</script>
<style scoped lang="css">
.img {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  margin-right: 12px;
}

.services {
  color: var(--green-color);
  border: 1px solid grey;
  padding: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  transition: all 500ms ease-in-out;
  margin-right: 15px;
  min-width: 265px;
  margin-bottom: 10px;
}

.content {
  display: flex;
  flex-direction: column;
}

.service-name {
  color: var(--green-color);
  transition: all 500ms ease-in-out;
  margin-bottom: 3px;
}

.service-description {
  transition: all 500ms ease-in-out;
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 600px) {
  .services {
    min-width: 100%;
    width: 100%;
  }
}

.service-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: stretch;
}

.service-item {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>

<style lang="css" scoped>
.greetings {
  height: 100px;
  width: 900px;
  gap: 20px;
}
.info-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
}
.travel-svg {
  max-width: 500px;
  max-height: 300px;
}
.ag-logo {
  width: 200px;
}
.whatsappIcon {
  text-decoration: none;
  font-size: 50px;
  color: white;
}

.fab {
  position: fixed;
  bottom: 50px;
  right: 25px;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  outline: none;
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #10b981;
  z-index: 1;
}

.fab i {
  font-size: 36px;
  color: white;
  z-index: 2;
}

@media (max-width: 1200px) {
  .info-card {
    flex-direction: column;
    align-items: flex-start;
  }
  .ag-logo {
    width: 150px;
  }
}

@media (max-width: 992px) {
  .service-container {
    flex-direction: column;
    gap: 20px;
  }
  .service-item {
    flex: none;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .info-card {
    margin-top: 50px;
  }

  .travel-svg {
    max-width: 100%;
    max-height: 200px;
  }

  .fab {
    bottom: 30px;
    right: 20px;
    width: 50px;
    height: 50px;
  }

  .fab i {
    font-size: 30px;
  }

  .service-name,
  .service-description {
    font-size: 14px;
  }

  .img {
    width: 80px;
    height: 80px;
  }

  .services {
    min-width: 100%;
  }

  .service-item {
    flex: 1;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .img {
    width: 70px;
    height: 70px;
  }

  .service-name {
    font-size: 16px;
  }

  .service-description {
    font-size: 12px;
    line-height: 1.4;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  .service-container {
    gap: 15px;
  }

  .fab {
    width: 45px;
    height: 45px;
    bottom: 20px;
    right: 15px;
  }

  .fab i {
    font-size: 28px;
  }
}
</style>
