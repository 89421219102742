import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-053f7667"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "snackbar-content" }
const _hoisted_2 = { class: "notification-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['snackbar', _ctx.type]),
          style: _normalizeStyle({
      backgroundColor: _ctx.snackBarDetails.backgroundColor,
      color: _ctx.snackBarDetails.textColor,
    })
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_MIcon, {
              name: _ctx.snackBarDetails.icon,
              width: "24px",
              height: "24px",
              class: "m-snackbar-icon",
              style: _normalizeStyle({ color: _ctx.snackBarDetails.iconColor })
            }, null, 8, ["name", "style"]),
            _createElementVNode("div", {
              class: "description",
              style: _normalizeStyle({ color: _ctx.snackBarDetails.textColor })
            }, _toDisplayString(_ctx.description), 5)
          ]),
          _createVNode(_component_MIcon, {
            name: "m-close",
            width: "24px",
            height: "24px",
            class: "m-snackbar-icon close-btn",
            style: _normalizeStyle({ color: _ctx.snackBarDetails.iconColor }),
            onClick: _ctx.closeSnackbar
          }, null, 8, ["style", "onClick"])
        ], 6))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ], 64))
}