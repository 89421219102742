import { MutationTree } from "vuex";
import { IFlightSearch } from "@/ag-flight-components/interfaces/IFlightSearch.interface";
import {
  FlightResponse,
  MultiCityLegStateType,
  SelectedFlightLeg,
  FareOption,
  FlightOption,
  ACTIVE_PROVIDERS_AND_AIRLINES_API_RESPONSE,
  TravelerCount,
  IMultiCityAirports,
} from "@/ag-flight-components/types/Flights";
import { Place } from "@/ag-flight-components/types/Place";

import { ROUTE_TYPE } from "@/ag-flight-components/enums/route_type";
import { CABIN_CLASS } from "@/ag-flight-components/enums/cabin_class";

const mutations: MutationTree<IFlightSearch> = {
  setIsPolling(state, payload: boolean) {
    state.isPolling = payload;
  },
  setFlightsResponse(state, flights: FlightResponse) {
    state.flightsResponse = flights;
  },
  loadingFlights(state, payload: boolean) {
    state.isFlightFetching = payload;
  },
  setLoading(state, payload: boolean) {
    state.isLoading = payload;
  },
  saveFlights(state, flights: FlightResponse) {
    const filteredFlights =
      flights.journey_legs[state.currentLegIndex].flight_options;
    state.isFlightFetching = false;
    state.flightsResponse = flights;
    state.flights = filteredFlights;
    state.selectedFilteredLegsByFareIds.push(filteredFlights);
    state.lastLegIndex = flights.journey_legs.length - 1;
  },
  loadingAirports(state, payload: boolean) {
    state.isAirportFetching = payload;
  },
  saveAirports(state, airports: Array<Place>) {
    state.isAirportFetching = false;
    state.airports = airports;
  },
  saveOriginAirports(state, airports: Array<Place>) {
    state.originAirports = airports;
  },
  saveDestinationAirports(state, airports: Array<Place>) {
    state.destinationAirports = airports;
  },
  loadingOriginAirports(state: IFlightSearch, isLoading: boolean) {
    state.loadingOriginAirports = isLoading;
  },
  loadingDestinationAirports(state: IFlightSearch, isLoading: boolean) {
    state.loadingDestinationAirports = isLoading;
  },
  saveOrigin(state, payload: Place) {
    state.origin = payload;
  },
  saveDestination(state, payload: Place) {
    state.destination = payload;
  },
  saveDepartureDate(state, payload: Date) {
    state.departure_date = payload;
  },
  saveReturnDate(state, payload: Date) {
    state.return_date = payload;
  },
  saveCabinClass(state, payload: CABIN_CLASS) {
    state.cabin_class = payload;
  },
  saveRouteType(state, payload: ROUTE_TYPE) {
    state.route_type = payload;
  },
  saveAdultCount(state, payload: number) {
    state.adult_count = payload;
  },
  saveChildCount(state, payload: number) {
    state.child_count = payload;
  },
  saveInfantCount(state, payload: number) {
    state.infant_count = payload;
  },
  saveTravelersCount(state, payload: TravelerCount) {
    state.adult_count = payload.adult_count;
    state.child_count = payload.child_count;
    state.infant_count = payload.infant_count;
  },
  saveMulticityLegs(state, payload: MultiCityLegStateType[]) {
    state.multiCityLegs = payload;
  },
  addMulticityFlight(state, payload: MultiCityLegStateType) {
    state.multiCityLegs.push(payload);
  },
  deleteMulticityFlight(state, index: number) {
    const _legs = state.multiCityLegs;
    const airports = state.multiCityAirports;
    airports.splice(index, 1);
    _legs.splice(index, 1);
    state.multiCityAirports = airports;
    state.multiCityLegs = _legs;
  },
  updateMulticityDepartureDate(state, payload) {
    const _legs = state.multiCityLegs;
    _legs[payload.index].departure_date = payload.date;

    const updated_legs = _legs.map((x, index) => {
      if (index > payload.index && x.departure_date < payload.date) {
        return {
          ...x,
          departure_date: payload.date,
        };
      }

      return x;
    });

    state.multiCityLegs = updated_legs;
  },
  updateMulticityOrigin(state, payload) {
    state.multiCityLegs[payload.index].origin = payload.origin;
  },
  updateMulticityDestination(state, payload) {
    state.multiCityLegs[payload.index].destination = payload.destination;

    if (
      state.multiCityLegs[payload.index + 1] &&
      state.multiCityAirports[payload.index + 1]
    ) {
      if (
        !state.multiCityAirports[payload.index + 1]["origin"]
          .map((x) => x.iata_code)
          .includes(payload.destination.iata_code)
      ) {
        state.multiCityAirports[payload.index + 1]["origin"].push(
          payload.destination
        );
      }

      state.multiCityLegs[payload.index + 1].origin = payload.destination;
    }
  },
  saveFilters(state, payload) {
    state.filters = payload;
  },
  saveSelectedFlightFilters(state, payload) {
    state.selectedFlightFilters = payload;
  },
  saveAmountType(state, payload) {
    state.amountType = payload;
  },
  saveFareType(state, payload) {
    state.fareType = payload;
  },
  saveFareCalculatorAmount(state, payload) {
    state.fareCalculatorAmount = payload;
  },
  selectFlightLeg(state, payload: SelectedFlightLeg) {
    state.selectedFlightLegs.push(payload);
  },
  saveCurrentLegIndex(state, payload: number) {
    state.currentLegIndex = payload;
  },
  clearSelectedFlightLegs(state) {
    state.selectedFlightLegs = [];
    state.selectedFares = [];
    state.selectedFilteredLegsByFareIds = [];
    state.currentLegIndex = 0;
    state.flights = [];
  },
  removeSelectedFlightLeg(state, index: number) {
    state.currentLegIndex = index === 0 ? 0 : index - 1;
    state.selectedFlightLegs.splice(index);
    state.selectedFares.splice(index);
    if (index === 0) {
      state.selectedFilteredLegsByFareIds.splice(index + 1);
    } else {
      state.selectedFilteredLegsByFareIds.splice(index);
    }
  },
  selectFlightFare(state, payload: FareOption) {
    state.selectedFares.push(payload);
  },
  saveFilteredLegsByFareIds(state, payload: FlightOption[]) {
    state.selectedFilteredLegsByFareIds.push(payload);
  },
  addActiveAirlinesAndProviders(
    state,
    payload: ACTIVE_PROVIDERS_AND_AIRLINES_API_RESPONSE
  ) {
    state.sabreAirlines = payload.active_sabre_airlines;
    state.galileoAirline = payload.active_galileo_airlines;
    state.flightProviders = payload.active_flight_providers;
  },
  resetActiveAirlinesAndProviders(state) {
    state.sabreAirlines = [];
    state.flightProviders = [];
  },
  resetFlightSearchProperties(state) {
    state.origin = null;
    state.destination = null;
    state.departure_date = new Date();
    state.return_date = null;
    state.route_type = ROUTE_TYPE.ONEWAY;
    state.adult_count = 1;
    state.child_count = 0;
    state.infant_count = 0;
    state.cabin_class = CABIN_CLASS.ECONOMY;
  },
  setMulticityAirportInputLoading(
    state,
    payload: { type: "origin" | "destination"; index: number }
  ) {
    state.currentMultiCityAirportInputIndexLoading = payload.index;
    state.currentMultiCityAirportInputTypeLoading = payload.type;
  },
  saveBulkMulticityAirports(state, payload: IMultiCityAirports[]) {
    state.multiCityAirports = payload;
  },
  setMulticityAirports(
    state,
    payload: {
      airports: Place[];
      type: "origin" | "destination";
      index: number;
    }
  ) {
    state.multiCityAirports[payload.index][payload.type] = payload.airports;
  },
  addNewMulticityAirportsRow(state) {
    const newRow = {
      origin: state.airports,
      destination: state.airports,
    };
    state.multiCityAirports.push(newRow);
  },
};

export default mutations;
