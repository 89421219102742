<template>
  <MAccordion :initial-open="initialOpen" :external-open="isAccordionOpen">
    <template #title>
      <div
        class="accordion-title-container"
        :class="{ 'has-errors': hasErrors }"
      >
        <div class="headline-container">
          <MIcon
            class="group-flight-accordion-title-traveler-icon"
            :name="iconName"
          />
          <MTypography class="headline" type="headline"
            >{{ headline }}
            <span v-if="hasErrors" class="required-error-text">
              {{ errorText }}</span
            ></MTypography
          >
        </div>
        <hr />
        <div class="title-container">
          <MTypography class="title" type="title">{{ title }} </MTypography>
        </div>
      </div>
    </template>
    <template #content>
      <MCard>
        <slot></slot>
      </MCard>
    </template>
  </MAccordion>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { MTypography, MCard, MAccordion, MIcon } from "@aeroglobe/ag-core-ui";
import { IconName } from "@aeroglobe/ag-core-ui/dist/src/components/material/icons/icons.type";

export default defineComponent({
  name: "NewTravelerCardAccordion",
  components: {
    MTypography,
    MAccordion,
    MCard,
    MIcon,
  },
  props: {
    iconName: {
      type: String as PropType<IconName>,
      default: "m-traveler-count-icon",
    },
    headline: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    errorsLength: {
      type: Number,
      default: 0,
    },
    initialOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAccordionOpen: this.initialOpen,
    };
  },
  computed: {
    hasErrors(): boolean {
      return this.errorsLength > 0;
    },
    errorText(): string {
      const fieldText = this.errorsLength > 1 ? "Fields" : "Field";
      return `( * ${this.errorsLength} ${fieldText} Required )`;
    },
  },
  watch: {
    initialOpen: {
      handler: function (newValue: boolean) {
        this.isAccordionOpen = newValue;
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.accordion-title-container {
  padding: 15px 25px;
  width: 100%;

  &.has-errors {
    border-left: 3px solid var(--error-color);
  }

  .headline-container {
    display: flex;
    align-items: center;
    .headline {
      margin-left: 10px;
      .required-error-text {
        font-size: 16px;
        font-weight: 800;
        color: var(--m-error-color);
      }
    }
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-size: 18px;
    // text-align: left;
  }

  .error-icon {
    color: var(--error-color);
  }
}

hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid var(--neutral-300);
}
</style>

<style>
.group-flight-accordion-title-traveler-icon {
  svg {
    width: 35px;
    height: 30px;
    fill: var(--m-primary-color) !important;
  }
}
</style>
