import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e08b33c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "discount-flag-banner" }
const _hoisted_2 = { class: "discount-flag-chip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(" Deal Applied: "),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.code), 1)
  ]))
}