export const AGENT_ANALYTICS_EVENTS = {
  UPDATE_PERMISSIONS: "organization-agent-permission-update-clicked",
  OTP_VERIFICATION: "organization-agent-otp-verification",

  AGENT_ADD: "organization-agent-add-new-clicked",
  AGENT_DELETE: "organization-agent-delete-clicked",

  GET_AGENT_PRICING: "organization-get-agent-pricing",
  UPDATE_AGENT_PRICING: "organization-update-agent-pricing",
};
