import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fdc6bce4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-page-view" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MTextfield = _resolveComponent("MTextfield")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_MCard = _resolveComponent("MCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MCard, { class: "login-card" }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmitLoginFormHandler && _ctx.onSubmitLoginFormHandler(...args)), ["prevent"])),
          class: "login-form"
        }, [
          _createElementVNode("img", {
            src: _ctx.getOrgLogoHandler(),
            alt: "organisation-logo",
            class: "org-logo"
          }, null, 8, _hoisted_2),
          _createVNode(_component_MTypography, { type: "title" }, {
            default: _withCtx(() => [
              _createTextVNode(" Welcome to " + _toDisplayString(_ctx.getOrganisationNameHandler()), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_MTypography, null, {
            default: _withCtx(() => [
              _createTextVNode(" Sign in to your account to continue... ")
            ]),
            _: 1
          }),
          _createVNode(_component_MTextfield, {
            "input-value": _ctx.loginInformation.email.value,
            "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loginInformation.email.value) = $event)),
            label: "Email",
            placeholder: "Enter your email",
            autocomplete: "email",
            "has-error": _ctx.loginInformation.email.hasError,
            "error-message": _ctx.loginInformation.email.errorMessage,
            disabled: _ctx.isAuthenticating
          }, null, 8, ["input-value", "has-error", "error-message", "disabled"]),
          _createVNode(_component_MTextfield, {
            "input-value": _ctx.loginInformation.password.value,
            "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loginInformation.password.value) = $event)),
            type: "password",
            label: "Password",
            placeholder: "Enter your password",
            autocomplete: "password",
            "has-error": _ctx.loginInformation.password.hasError,
            "error-message": _ctx.loginInformation.password.errorMessage,
            disabled: _ctx.isAuthenticating
          }, null, 8, ["input-value", "has-error", "error-message", "disabled"]),
          _createVNode(_component_MButton, {
            type: "text",
            class: "btn-link",
            disabled: _ctx.isAuthenticating,
            onClick: _ctx.onNavigateToForgotPasswordViewHandler
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Forgot Password ")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createVNode(_component_MButton, {
            behaviour: "submit",
            class: "btn-submit",
            disabled: _ctx.infoHasErrors() || _ctx.isAuthenticating
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Login ")
            ]),
            _: 1
          }, 8, ["disabled"]),
          _createVNode(_component_MTypography, {
            class: "footer-caption",
            type: "label"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, [
                _createTextVNode(" Don't have an account ? "),
                _createElementVNode("span", null, [
                  _createVNode(_component_RouterLink, {
                    class: "footer-caption-link",
                    to: "/signup"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Sign Up ")
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          })
        ], 32)
      ]),
      _: 1
    })
  ]))
}