import { RouteRecordRaw } from "vue-router";
import SalesSnapshot from "@/modules/Snapshots/views/SalesSnapshot.vue";
import DailyIssuances from "@/modules/Snapshots/views/DailyIssuances.vue";
import DigitalPayments from "@/modules/Snapshots/views/DigitalPayments.vue";
import organizationSnapshot from "@/modules/Snapshots/views/OrganizationSnapshot.vue";
import SectorSnapshot from "@/modules/Snapshots/views/SectorSnapshot.vue";

import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import { PATH } from "@/ag-portal-common/constants/path";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.SALES_SNAPSHOT,
    name: "salesSnapshot",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.SALES_SNAPSHOT.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.SALES_SNAPSHOT.PERMISSIONS,
    },
    component: SalesSnapshot,
  },
  {
    path: PATH.DAILY_ISSUANCE,
    name: "dailyIssuance",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.DAILY_ISSUANCE.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.DAILY_ISSUANCE.PERMISSIONS,
    },
    component: DailyIssuances,
  },
  {
    path: PATH.DIGITAL_PAYMENTS,
    name: "digitalPayments",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.DIGITAL_PAYMENTS_BY_SECTORS.ROLES,
      permissions:
        ROLES_AND_PERMISSION_ON_ROUTES.DIGITAL_PAYMENTS_BY_SECTORS.PERMISSIONS,
    },
    component: DigitalPayments,
  },
  {
    path: PATH.ORGANIZATION_SNAPSHOT,
    name: "organizationSnapshot",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION_SNAPSHOT.ROLES,
      permissions:
        ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION_SNAPSHOT.PERMISSIONS,
    },
    component: organizationSnapshot,
  },
  {
    path: PATH.SECTOR_SNAPSHOT,
    name: "sectorSnapshot",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.SECTOR_SNAPSHOT.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.SECTOR_SNAPSHOT.PERMISSIONS,
    },
    component: SectorSnapshot,
  },
];

export default routes;
