<template>
  <MDialog
    :title="renderTitle"
    v-model="isMdialogOpen"
    @close="$emit('handleReject')"
  >
    <template #content>
      <MTypography
        >Are you sure to remove this {{ type.toLowerCase() }}?</MTypography
      >
    </template>
    <template #actions>
      <MButton type="outlined" @click="$emit('handleReject')"> No </MButton>
      <MButton @click="$emit('handleAccept')"> Yes </MButton>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { MDialog } from "@aeroglobe/ag-core-ui";
import { defineComponent } from "vue";

export default defineComponent({
  name: "RemoveItemConfirmation",
  data() {
    return {
      isMdialogOpen: this.isOpen,
    };
  },
  computed: {
    renderTitle(): string {
      return "Remove " + this.type;
    },
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    type: {
      type: String,
      default: "",
      required: true,
    },
  },
  watch: {
    isOpen(newVal) {
      this.isMdialogOpen = newVal;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("closeModal");
      }
    },
  },
});
</script>
