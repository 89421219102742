<template>
  <TourDetailShimmer v-if="isFetchingFDGDetail" />
  <div v-else class="flight-booking-details">
    <ag-row>
      <ag-column xs="12" sm="12" md="8" lg="9">
        <MCard class="flight-booking-title-card margin-bottom-20">
          <MTypography class="flight-booking-title-text" type="headline">{{
            title
          }}</MTypography>
        </MCard>

        <MCard class="margin-bottom-20">
          <MTypography type="title">Flight Details</MTypography>
          <hr />
          <FlightDetailAccordion
            v-for="(item, idx) in groupFlightLegs"
            :key="idx"
            :flight-leg="item"
            :index="idx"
          />
        </MCard>
        <MCard
          class="margin-bottom-20"
          v-for="(item, index) in additionalFlightLegDetails"
          v-bind:key="index"
        >
          <MTypography type="title"
            >Additional Services on Flight {{ index + 1 }}</MTypography
          >
          <hr />
          <AdditionalDetailsCard
            :has-meal="item.hasMeal"
            :luggage-info="item.baggageInfo"
          />
        </MCard>
      </ag-column>
      <ag-column xs="12" sm="12" md="4" lg="3">
        <MCard class="pricing-card margin-bottom-20">
          <div class="seat-fare">
            <MTypography class="fare" type="headline">{{
              formatCurrency(
                fdgDetail?.bookingOptions[0]?.price,
                fdgDetail?.bookingOptions[0]?.currency
              )
            }}</MTypography>
            <span class="per-pax-text">Per Passenger</span>
          </div>
          <div class="seat-available-label">
            {{ remainingSeats(fdgDetail?.bookingOptions[0].remainingQuantity) }}
          </div>
        </MCard>
        <MCard>
          <div class="passenger-counter-container">
            <MTypography class="counter-title" type="title"
              >Select Pax Count</MTypography
            >
            <div class="counter_wrap">
              <button
                :disabled="disableMinusPaxBtn"
                @click="handleMinusPassengerCount"
              >
                <MIcon
                  :class="{
                    'm-traveler-count-plus-btn-disabled': disableMinusPaxBtn,
                  }"
                  name="m-count-minus-icon"
                />
              </button>
              <div class="l_count">{{ passengerCount }}</div>
              <button
                :disabled="disablePlusPaxBtn"
                @click="handlePlusPassengerCount"
              >
                <MIcon
                  :class="{
                    'm-traveler-count-plus-btn-disabled': disablePlusPaxBtn,
                  }"
                  name="m-count-plus-icon"
                />
              </button>
            </div>
          </div>
          <hr />

          <MTypography type="body">
            To secure your reservation, a <b>non-refundable</b> deposit of
            <span class="token-percentage">{{ tokenPercentage }}%</span> of the
            total amount is required at the time of booking. You can pay the
            remaining balance anytime up until the <b> {{ deadlineDate }}</b>
          </MTypography>

          <div class="amount-you-pay-container">
            <MTypography class="fare" type="headline">
              <AnimatedCurrencyCounter
                :target-amount="totalAmount"
                :currency="amountCurrency"
            /></MTypography>
            <MTypography type="body">Total Amount</MTypography>
          </div>

          <hr />

          <div class="amount-you-pay-container">
            <MTypography class="fare" type="headline">
              <AnimatedCurrencyCounter
                :target-amount="amountYouPay"
                :currency="amountCurrency"
            /></MTypography>
            <MTypography class="token-amount" type="body"
              >Token amount deducted at confirmation
            </MTypography>
          </div>

          <div class="book-now-btn-container">
            <MButton :disabled="isInitiatingFDGBooking" @click="onBookNowClick"
              >Book Now</MButton
            >
          </div>
        </MCard>
      </ag-column>
    </ag-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IFDGItem } from "@/interfaces/fdg.interface";
import {
  formatStringToRoutePath,
  getDurationText,
} from "@/ag-portal-common/utils/helpers";
import AgDuration from "@/ag-portal-common/components/AgDuration.vue";
import FlightDetailAccordion from "@/ag-flight-components/components/FlightBooking/FlightDetailAccordion.vue";
import { PATH } from "@/ag-portal-common/constants/path";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";
import { IFDGDetail } from "@/interfaces/fdgDetail.interface";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import {
  AdditionalFlightDetails,
  FlightLeg,
} from "@/ag-flight-components/types";
import AdditionalDetailsCard from "@/ag-flight-components/components/FlightBooking/AdditionalDetailsCard.vue";
import { getBaggageInfo } from "@/modules/Booking/utils";
import { formatGroupFlightTitle } from "../utils";
import { MTypography, MCard, MButton, MIcon } from "@aeroglobe/ag-core-ui";
import { formatCurrency } from "@/ag-flight-components/utils";
import AnimatedCurrencyCounter from "@/components/AnimatedCurrencyCounter.vue";
import { FDGBookingDetailRequestModel } from "@/modules/FDG/models/fdgBookingDetail.request";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_STYLES } from "@/ag-portal-common/enums/NOTIFICATION_STYLES";
import TourDetailShimmer from "../components/TourDetailShimmer.vue";
import { format } from "date-fns";
import { FORMAT_DD_MM_YYYY_WITH_SPACE } from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "FlightItenaryDErew",
  components: {
    FlightDetailAccordion,
    AdditionalDetailsCard,
    MTypography,
    AnimatedCurrencyCounter,
    MCard,
    MButton,
    TourDetailShimmer,
    MIcon,
  },
  data() {
    return {
      items: [],
      title: "",
      passengerCount: 1,
      amountYouPay: 0,
      amountCurrency: "",
      totalAmount: 0,
    };
  },
  computed: {
    AgDuration() {
      return AgDuration;
    },
    BOOKING_TYPES() {
      return BOOKING_TYPES;
    },
    NOTIFICATION_MESSAGES() {
      return NOTIFICATION_MESSAGES;
    },
    groupFlightLegs(): FlightLeg[] {
      const fdgDetail = this.$store.getters.fdgDetail;
      return fdgDetail?.flightLegItems || [];
    },
    fdgDetail(): IFDGDetail {
      const details = this.$store.getters.fdgDetail;
      return details;
    },
    deadlineDate(): string {
      const deadlineDate = this.fdgDetail.deadline_date as Date;
      return format(new Date(deadlineDate), FORMAT_DD_MM_YYYY_WITH_SPACE);
    },
    tokenPercentage(): number {
      return this.fdgDetail?.token_percentage;
    },
    isFetchingFDGDetail(): boolean {
      return this.$store.getters.isFetchingFDGDetail;
    },
    isInitiatingFDGBooking(): boolean {
      return this.$store.getters.isInitiatingFDGBooking;
    },
    additionalFlightLegDetails(): AdditionalFlightDetails[] {
      const fdgDetail = this.$store.getters.fdgDetail;
      const flightLegItems = fdgDetail?.flightLegItems || [];
      const details = flightLegItems.map((leg: FlightLeg) => {
        const baggageInfo = getBaggageInfo(leg.baggage_info);
        const hasMeal: boolean = leg.has_meal;
        let details: AdditionalFlightDetails = {
          hasMeal,
          baggageInfo,
        };
        return details;
      });
      return details;
    },
    disableMinusPaxBtn(): boolean {
      return this.passengerCount === 1;
    },
    disablePlusPaxBtn(): boolean {
      const max_capacity_pax_per_booking = this.fdgDetail?.max_pax_per_booking;
      return this.passengerCount === max_capacity_pax_per_booking;
    },
    currency(): string {
      return this.fdgDetail?.bookingOptions[0]?.currency;
    },
    price(): number {
      return this.fdgDetail?.bookingOptions[0]?.price;
    },
  },
  watch: {
    fdgDetail: {
      handler(value) {
        if (value?.items?.length) {
          this.title = this.formatGroupFlightTitle(value?.items);
        }
      },
    },
    passengerCount: {
      handler(passenger_count) {
        if (this.isNumber(passenger_count)) {
          this.passengerAmountAccordingTokenPercentage(passenger_count);
        }
      },
      immediate: true,
    },
    price: {
      handler() {
        if (this.isNumber(this.passengerCount)) {
          this.passengerAmountAccordingTokenPercentage(this.passengerCount);
        }
      },
      immediate: true,
    },
    currency: {
      handler(value) {
        this.amountCurrency = value;
      },
      immediate: true,
    },
  },
  methods: {
    getDurationText,
    getBaggageInfo,
    formatGroupFlightTitle,
    isNumber(value: any): boolean {
      return typeof value === "number" ? true : false;
    },
    remainingSeats(counts: number) {
      if (counts > 0) {
        return `${counts} Seats Available`;
      }
      return "Sold out";
    },
    formatCurrency,
    handleMinusPassengerCount() {
      this.passengerCount = this.passengerCount - 1;
    },
    handlePlusPassengerCount() {
      const max_capacity_pax_per_booking = this.fdgDetail?.max_pax_per_booking;
      if (this.passengerCount < max_capacity_pax_per_booking) {
        this.passengerCount = this.passengerCount + 1;
      }
    },
    passengerAmountAccordingTokenPercentage(passenger_count: number) {
      const price = this.price;
      const value = (price * this.tokenPercentage) / 100;
      const result = passenger_count * value;
      this.amountYouPay = this.isNumber(result) ? Math.trunc(result) : 0;
      this.totalAmount = price * passenger_count;
    },
    routeToBookingConfirmation(id: string) {
      this.$router.push(
        formatStringToRoutePath(PATH.FDG_FLIGHT_BOOKING_CONFIRMATION, { id })
      );
    },
    onBookNowClick() {
      const bookingId = this.fdgDetail.bookingOptions[0].id;

      if (!bookingId) {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
        notificationService.description =
          NOTIFICATION_MESSAGES.SELECT_ANY_PACKAGE;
        notificationService.triggerNotification();
        return;
      }

      let payload: FDGBookingDetailRequestModel = {
        no_of_passengers: this.passengerCount,
        tour_option_id: bookingId || "",
      };

      this.$store.dispatch("initiateGroupFlightBooking", {
        payload,
        callback: this.routeToBookingConfirmation,
      });
    },
  },
  beforeMount() {
    this.$store.dispatch("fetchGroupFlightDetail", this.$route.params.id);
  },
});
</script>

<style lang="scss" scoped>
.margin-bottom-20 {
  margin-bottom: 20px;
}
.flight-booking-title-card {
  .flight-booking-title-text {
    font-weight: 900;
  }
}
.seat-available-label {
  background: var(--m-secondary-color);
  border-radius: 14px;
  padding: 4px 10px;
  width: max-content;
  color: var(--m-light-color);
  font-weight: bold;
  font-size: 14px;
}
.seat-fare {
  .fare {
    font-weight: 600;
    text-align: center;
  }
  .per-pax-text {
    display: block;
    font-size: 12px;
    text-align: center;
    color: var(--m-secondary-color);
    line-height: 12px;
  }
}
.pricing-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.counter_wrap {
  display: flex;
  align-items: center;
  align-items: center;
  margin-top: 10px;
  .l_count {
    min-width: 40px;
    text-align: center;
    font-weight: 800;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .counter-btn {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    min-width: 50px;
    max-height: 50px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    text-align: center;
    padding: 0;
    text-align: center;
    font-size: 16px;
    .icon_wrap {
      display: none;
    }
    span {
      padding-right: 0px !important;
    }
  }
}

.token-percentage {
  font-weight: 900;
  font-size: 18px;
}

.m-icon.m-traveler-count-plus-btn-disabled {
  cursor: not-allowed;
  stroke: var(--m-scrollbar-thumb-background-color);
}

.m-icon.m-traveler-count-plus-btn-disabled {
  svg > rect {
    stroke: var(--m-scrollbar-thumb-background-color);
  }
}

.passenger-counter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  .counter-title {
    text-align: center;
  }
}
.amount-you-pay-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  .amount {
    font-size: 18px;
    font-weight: 800;
  }
}
.book-now-btn-container {
  text-align: center;
  margin-top: 40px;
}
.token-amount {
  text-align: center;
}
</style>
