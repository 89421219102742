<template>
  <MProgress
    indeterminate
    v-if="isFinancialProfileFetching"
    class="mprogress-alignment"
  />
  <div v-else>
    <MCard class="visa-verification-input-form">
      <MTypography type="headline">Fetch PNR</MTypography>
      <MTypography type="body" class="margin-bottom-20">
        Ensure the PNR has been ticketed and includes at least one flight
        segment within Saudi Arabia.
      </MTypography>
      <div class="issuance-inputs">
        <MFinancialProfileCombobox
          v-model:inputValue="flightInformation.financialProfile.value"
          label="Financial Profiles"
          itemValue="value"
          itemLabel="label"
          :hasError="flightInformation.financialProfile.hasError"
          :errorMessage="flightInformation.financialProfile.errorMessage"
          :disabled="isPnrFetched || isFetchingPNR"
          :options="financialProfileItems"
        />

        <div class="row">
          <MCombobox
            v-model:inputValue="flightInformation.supplier.value"
            :options="suppliers"
            itemValue="value"
            itemLabel="label"
            label="Airline/Supplier"
            :hasError="flightInformation.supplier.hasError"
            :errorMessage="flightInformation.supplier.errorMessage"
            :disabled="
              isPnrFetched || isFetchingPNR || !isAirlinesAndProvidersFetched
            "
          />

          <MTextfield
            v-model:inputValue.trim="flightInformation.pnrNumber.value"
            label="PNR Number"
            :hasError="flightInformation.pnrNumber.hasError"
            :errorMessage="flightInformation.pnrNumber.errorMessage"
            :disabled="isPnrFetched || isFetchingPNR"
          />
        </div>

        <div class="row">
          <template v-if="isSabreSelected">
            <MCombobox
              v-model:inputValue="flightInformation.sabreAirline.value"
              :options="sabreAirlinesComboboxOptions"
              itemValue="value"
              itemLabel="label"
              label="Sabre Airline"
              :hasError="flightInformation.sabreAirline.hasError"
              :errorMessage="flightInformation.sabreAirline.errorMessage"
              :disabled="
                isPnrFetched || isFetchingPNR || !isAirlinesAndProvidersFetched
              "
            />
          </template>

          <template v-if="isGalileoSelected">
            <MCombobox
              v-model:inputValue="flightInformation.galileoAirline.value"
              :options="galileoAirlinesComboboxOptions"
              itemValue="value"
              itemLabel="label"
              label="Galileo Airline"
              :hasError="flightInformation.galileoAirline.hasError"
              :errorMessage="flightInformation.galileoAirline.errorMessage"
              :disabled="
                isPnrFetched || isFetchingPNR || !isAirlinesAndProvidersFetched
              "
            />
          </template>
        </div>
      </div>

      <div class="actions">
        <m-button
          class="issuance-action"
          :disabled="!canFetchPnrDetails || isPnrFetched"
          type="filled"
          @click="canFetchPnrDetails ? handleFetchPnrDetails() : ''"
        >
          {{ isFetchingPNR ? "Fetching PNR" : "Get PNR Details" }}
        </m-button>

        <m-button
          class="issuance-action"
          :disabled="infoHasErrors() || !isPnrFetched"
          type="outlined"
          @click="infoHasErrors() || !isPnrFetched ? '' : handleDataReset()"
        >
          Reset
        </m-button>
      </div>

      <MTypography type="label" v-show="showSabreAirlineInfoMessage">
        {{ sabreAirlineInfoMessage }}
      </MTypography>

      <MTypography type="label" v-show="showGalileoAirlineInfoMessage">
        {{ galileoAirlineInfoMessage }}
      </MTypography>
    </MCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  MTypography,
  MTextfield,
  MCombobox,
  MButton,
  MFinancialProfileCombobox,
} from "@aeroglobe/ag-core-ui";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { SUPPLIER_NAMES } from "@/ag-flight-components/enums/supplier_names";
import { fetchPNRValidation } from "@/ag-flight-components/validations/pnrFetchValidation";
import {
  PNRDetails,
  SABRE_AIRLINE,
  FLIGHT_PROVIDER,
  GALILEO_AIRLINE,
} from "@/ag-flight-components/types";
import { ValidationError } from "yup";
import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { FLIGHT_ISSUANCE_ANALYTICS_EVENTS } from "@/modules/FlightIssuance/constants/anaylticsEvents";
import analyticsService from "@/analytic.service";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import { ComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import UTILS from "@/ag-portal-common/utils";
import { FinancialProfile, User } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import {
  PNR_STATUSES,
  SAUDI_WHITELISTED_AIRPORTS_FOR_OK_TO_BOARD,
} from "../constants";

export default defineComponent({
  name: "FetchPNRComponent",
  components: {
    MTypography,
    MTextfield,
    MCombobox,
    MButton,
    MFinancialProfileCombobox,
  },
  emits: [
    "pnrFetched",
    "resetData",
    "pnr",
    "errorMessage",
    "visaVerificationFinancialProfileId",
  ],
  data() {
    return {
      flightInformation: {
        financialProfile: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        supplier: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        pnrNumber: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        sabreAirline: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        galileoAirline: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      },
      validateFlightInfoErrors: false,
      isAirlinesAndProvidersFetched: false,
    };
  },
  computed: {
    isFinancialProfileFetching(): boolean {
      return this.$store.getters.isFetchingFinancialProfiles;
    },
    user(): User | null {
      return this.$store.getters[AUTH_GETTERS.USER];
    },
    financialProfiles(): FinancialProfile[] | null {
      return this.$store.getters[AUTH_GETTERS.FINANCIAL_PROFILES];
    },
    sabreAirlines(): SABRE_AIRLINE[] {
      return this.$store.getters.sabreAirlines as SABRE_AIRLINE[];
    },
    galileoAirlines(): GALILEO_AIRLINE[] {
      return this.$store.getters.galileoAirline as GALILEO_AIRLINE[];
    },
    flightProviders(): FLIGHT_PROVIDER[] {
      return this.$store.getters.flightProviders as FLIGHT_PROVIDER[];
    },
    isSabreSelected(): boolean {
      return (
        this.flightInformation.supplier.value?.toLowerCase() ===
        SUPPLIER_NAMES.SABRE
      );
    },
    isGalileoSelected(): boolean {
      return (
        this.flightInformation.supplier.value?.toLowerCase() ===
        SUPPLIER_NAMES.GALILEO
      );
    },
    showSabreAirlineInfoMessage(): boolean {
      return (
        this.flightInformation.supplier.value?.toLowerCase() ===
        SUPPLIER_NAMES.SABRE
      );
    },
    showGalileoAirlineInfoMessage(): boolean {
      return (
        this.flightInformation.supplier.value?.toLowerCase() ===
        SUPPLIER_NAMES.GALILEO
      );
    },
    isFetchingPNR(): boolean {
      return this.$store.getters.isFetchingPNR;
    },
    isPnrFetched(): boolean {
      return !this.isFetchingPNR && this.$store.getters.pnrDetails !== null;
    },
    canFetchPnrDetails(): boolean {
      return !(this.infoHasErrors() || this.isFetchingPNR);
    },
    isSuperUserOrOperationUser(): boolean {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER, USER_ROLES.OPERATIONS);
    },
    financialProfileItems(): FPComboboxOptions[] {
      let financialProfiles;
      if (this.isSuperUserOrOperationUser) {
        financialProfiles = this.$store.getters.issuanceFinancialProfiles;
      } else {
        financialProfiles = this.financialProfiles;
      }

      if (financialProfiles) {
        return financialProfiles.map((fp: IFinancialProfile) => {
          const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase();
          const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
          return {
            id: fp?.platform_id,
            label: fp?.financial_profile_name,
            value: fp?.public_id,
            isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
            status: fp?.status,
            sector: sector,
            type: planType,
          };
        });
      } else {
        return [];
      }
    },
    suppliers(): ComboboxOptions[] {
      return this.flightProviders
        .filter(
          (provider: FLIGHT_PROVIDER) => provider.is_ticket_issuance_enabled
        )
        .sort((a: FLIGHT_PROVIDER, b: FLIGHT_PROVIDER) =>
          a.name.localeCompare(b.name)
        )
        .map((provider: FLIGHT_PROVIDER) => ({
          label: UTILS.toTitleCase(provider.name),
          value: provider.name,
        }));
    },
    sabreAirlinesComboboxOptions(): ComboboxOptions[] {
      return [...this.sabreAirlines]
        .sort((a: SABRE_AIRLINE, b: SABRE_AIRLINE) =>
          a.airline_code.localeCompare(b.airline_code)
        )
        .map((airline: SABRE_AIRLINE) => ({
          label: airline.airline_code,
          value: airline.airline_code,
        }));
    },
    galileoAirlinesComboboxOptions(): ComboboxOptions[] {
      return [...this.galileoAirlines]
        .sort((a: GALILEO_AIRLINE, b: GALILEO_AIRLINE) =>
          a.airline_code.localeCompare(b.airline_code)
        )
        .map((airline: GALILEO_AIRLINE) => ({
          label: airline.airline_code,
          value: airline.airline_code,
        }));
    },
    sabreAirlineInfoMessage(): string {
      if (!this.isSabreSelected) return "";
      const sabreAirline = this.sabreAirlines.find(
        (airline) =>
          airline.airline_code === this.flightInformation.sabreAirline.value
      );
      if (!sabreAirline) return "";
      if (sabreAirline.pcc) {
        return `Please queue on "${sabreAirline.pcc}" for ${
          sabreAirline.airline_code
        }. ${
          sabreAirline.airline_code === "PK"
            ? "Only PK international routes can be issued via sabre"
            : ""
        }`;
      }
      return "This airline cannot be issued through portal, please issue through whatsapp or email.";
    },
    galileoAirlineInfoMessage(): string {
      if (!this.isGalileoSelected) return "";
      const galileoAirline = this.galileoAirlines.find(
        (airline) =>
          airline.airline_code === this.flightInformation.galileoAirline.value
      );
      if (!galileoAirline) return "";
      if (galileoAirline.pcc) {
        return `Please queue on "${galileoAirline.pcc}" for ${
          galileoAirline.airline_code
        }. ${
          galileoAirline.airline_code === "PK"
            ? "Only PK international routes can be issued via galileo"
            : ""
        }`;
      }
      return "This airline cannot be issued through portal, please issue through whatsapp or email.";
    },
  },
  methods: {
    async handleFetchPnrDetails() {
      this.$emit("errorMessage", "");
      this.validateFlightInfoErrors = true;
      this.handleFlightInfoErrors();

      if (this.infoHasErrors()) {
        return;
      }

      try {
        const payload = this.transformPnrData();
        const request = await fetchPNRValidation.validate(payload, {
          abortEarly: false,
        });

        await this.$store.dispatch("fetchPNR", request);

        analyticsService.logActionEvent(
          FLIGHT_ISSUANCE_ANALYTICS_EVENTS.FETCH_PNR_DETAILS,
          payload
        );

        this.$emit("pnrFetched", this.$store.getters.pnrDetails);
        this.$emit("pnr", this.flightInformation.pnrNumber.value);
      } catch (ex) {
        if (ex instanceof ValidationError) {
          yupValidationErrorAsSchema(ex);
        }
      } finally {
        this.handleFlightInfoErrors();
      }
    },
    handleFlightInfoErrors() {
      if (this.validateFlightInfoErrors) {
        const {
          financialProfile,
          supplier,
          pnrNumber,
          sabreAirline,
          galileoAirline,
        } = this.flightInformation;

        if (financialProfile.value === "") {
          financialProfile.hasError = true;
          financialProfile.errorMessage = "Financial Profile is required";
        } else {
          financialProfile.hasError = false;
          financialProfile.errorMessage = "";
        }

        if (supplier.value === "") {
          supplier.hasError = true;
          supplier.errorMessage = "Supplier is required";
        } else {
          supplier.hasError = false;
          supplier.errorMessage = "";
        }

        if (pnrNumber.value === "") {
          pnrNumber.hasError = true;
          pnrNumber.errorMessage = "PNR is required";
        } else {
          pnrNumber.hasError = false;
          pnrNumber.errorMessage = "";
        }

        if (this.isSabreSelected) {
          if (sabreAirline.value === "") {
            sabreAirline.hasError = true;
            sabreAirline.errorMessage = "Sabre Airline is required";
          } else {
            sabreAirline.hasError = false;
            sabreAirline.errorMessage = "";
          }
        }

        if (this.isGalileoSelected) {
          if (galileoAirline.value === "") {
            galileoAirline.hasError = true;
            galileoAirline.errorMessage = "Galileo Airline is required";
          } else {
            galileoAirline.hasError = false;
            galileoAirline.errorMessage = "";
          }
        }
      }
    },
    infoHasErrors() {
      this.handleFlightInfoErrors();
      return Object.values(this.flightInformation).some(
        (item) => item.hasError
      );
    },
    transformPnrData() {
      const {
        financialProfile,
        pnrNumber,
        sabreAirline,
        galileoAirline,
        supplier,
      } = this.flightInformation;

      const flightProvider = this.flightProviders.find(
        (provider) =>
          provider.name.toLowerCase() === supplier.value.toLowerCase()
      );

      const airlineCode = this.isSabreSelected
        ? this.sabreAirlines.find(
            (airline) => airline.airline_code === sabreAirline.value
          )?.airline_code
        : this.galileoAirlines.find(
            (airline) => airline.airline_code === galileoAirline.value
          )?.airline_code;

      return {
        financial_profile_public_id: financialProfile.value,
        pnr: pnrNumber.value.trim(),
        airline: flightProvider ? flightProvider.value : null,
        ...(airlineCode && {
          airline_code: airlineCode,
        }),
      };
    },
    handleDataReset() {
      this.flightInformation = {
        financialProfile: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        supplier: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        pnrNumber: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        sabreAirline: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        galileoAirline: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      };

      this.isPnrFetched = false;
      this.validateFlightInfoErrors = false;

      if (this.isSuperUserOrOperationUser) {
        this.isAirlinesAndProvidersFetched = false;
        this.$store.commit("resetActiveAirlinesAndProviders");
      }

      this.$emit("resetData");

      this.$store.commit("resetPNRDetails");

      this.$store.commit("removeFlightPromoResponse");
    },
    async fetchAirlinesAndProviders(id: string) {
      const payload = {
        id,
        isFinancialProfile: !this.isSuperUserOrOperationUser,
      };

      await this.$store.dispatch("fetchAirlinesAndProviders", payload);
      this.isAirlinesAndProvidersFetched = true;
    },
    checkFlightArrivals(
      whiteListedAirports: string[],
      flightArrivals: string[]
    ): boolean {
      return flightArrivals.some((airport) =>
        whiteListedAirports.includes(airport)
      );
    },
  },
  watch: {
    "flightInformation.financialProfile.value": async function (value: string) {
      this.isAirlinesAndProvidersFetched = false;

      if (value.length > 0) {
        let id = value;
        if (this.isSuperUserOrOperationUser) {
          const financialProfiles = this.$store.getters
            .issuanceFinancialProfiles as IFinancialProfile[];
          const financialProfile = financialProfiles.find(
            (profile) => profile.public_id === value
          );
          if (financialProfile) {
            id = financialProfile.organization_public_id ?? "";

            this.$emit("visaVerificationFinancialProfileId", id);
          }
        }
        await this.fetchAirlinesAndProviders(id);
      }
    },
    "$store.getters.pnrDetails": {
      handler: function (pnrDetails: PNRDetails) {
        if (pnrDetails) {
          if (pnrDetails.pnr_status === PNR_STATUSES.TICKETED) {
            const flightArrivalAirports = pnrDetails?.flight_details.map(
              (item) => item.arrival_short_code
            );
            if (
              !this.checkFlightArrivals(
                SAUDI_WHITELISTED_AIRPORTS_FOR_OK_TO_BOARD,
                flightArrivalAirports
              )
            ) {
              this.$emit(
                "errorMessage",
                "This PNR has not any arrival segment of Saudi Arabia"
              );
            }
          } else {
            this.$emit(
              "errorMessage",
              "Non Issued PNR cannot be used for Visa Ok To Board Requests"
            );
            return;
          }
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    if (!this.isSuperUserOrOperationUser && this.financialProfiles) {
      const defaultFinancialProfile = this.financialProfiles.find(
        (item) => item.is_default
      );
      if (defaultFinancialProfile) {
        this.flightInformation.financialProfile.value =
          defaultFinancialProfile.public_id;
      }
    }
  },
  async created() {
    if (this.isSuperUserOrOperationUser) {
      await this.$store.dispatch("fetchFinancialProfiles", { is_linked: true });
    }
  },
  beforeMount() {
    this.handleDataReset();
  },
  unmounted() {
    this.handleDataReset();
  },
});
</script>

<style scoped>
.issuance-inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.issuance-inputs .row {
  display: flex;
  gap: 24px;
  width: 100%;
}

.actions {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

@media screen and (max-width: 786px) {
  .issuance-inputs .row {
    flex-direction: column;
  }
}
.visa-verification-input-form {
  margin-bottom: 20px;
}
</style>
