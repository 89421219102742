export const QUOTATIONS_ANALYTICS_EVENTS = {
  BULK_EDIT_FARE: "bulk-edit-fare",

  ADD_FLIGHT: "add-flight-to-quote",
  ADD_PAIR: "add-pair-to-quote",

  DELETE_FLIGHT: "delete-flight-quote",
  DELETE_PAIR: "delete-pair-quote",

  SAVE_QUOTE: "saved-editted-fare",
};
