import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aef80e08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "otp-modal" }
const _hoisted_2 = { class: "otp-modal__close_btn" }
const _hoisted_3 = { class: "otp-modal__header" }
const _hoisted_4 = { class: "otp-modal__body" }
const _hoisted_5 = { class: "otp-input" }
const _hoisted_6 = ["onUpdate:modelValue", "onInput", "onKeydown", "aria-label"]
const _hoisted_7 = { class: "otp-modal__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createBlock(_component_MDialog, {
    persistent: "",
    modelValue: _ctx.isMdialogOpen,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isMdialogOpen) = $event)),
    onClose: _ctx.closeDialog,
    "max-width": "500",
    "hide-header": "",
    "hide-footer": ""
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_MIcon, {
            onClick: _ctx.closeDialog,
            name: "m-outlined-cancel"
          }, null, 8, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_MTypography, {
            type: "headline",
            class: "otp-modal__title"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "component", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_MTypography, {
            type: "body",
            class: "otp-modal__instruction"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.instruction), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otpDigits, (digit, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("input", {
                key: index,
                type: "text",
                "onUpdate:modelValue": ($event: any) => ((_ctx.otpDigits[index]) = $event),
                onInput: ($event: any) => (_ctx.onInput(index)),
                onKeydown: ($event: any) => (_ctx.handleKeyDown(index, $event)),
                onPaste: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlePaste && _ctx.handlePaste(...args))),
                ref_for: true,
                ref: (el) => { if (el) _ctx.inputRefs[index] = el as HTMLInputElement },
                maxlength: "1",
                class: _normalizeClass(["otp-input__digit", { 'otp-input__digit--filled': digit }]),
                "aria-label": `Digit ${index + 1} of OTP`
              }, null, 42, _hoisted_6)), [
                [_vModelText, _ctx.otpDigits[index]]
              ])
            }), 128))
          ]),
          (_ctx.error)
            ? (_openBlock(), _createBlock(_component_MTypography, {
                key: 0,
                type: "body",
                class: "otp-modal__error",
                role: "alert"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.error), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_MButton, {
              onClick: _ctx.submitOTP,
              variant: "primary",
              disabled: !_ctx.isOtpComplete || _ctx.isSubmitLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Verify OTP ")
              ]),
              _: 1
            }, 8, ["onClick", "disabled"])
          ])
        ])
      ])
    ]),
    _: 3
  }, 8, ["modelValue", "onClose"]))
}