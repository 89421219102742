<template>
  <form class="passenger-visa-card" @submit.prevent>
    <MTextfield
      v-model:inputValue="firstNameComputed"
      type="text"
      label="First Name"
      placeholder="Enter First Name"
      :hasError="!!getError('firstName')"
      :errorMessage="getError('firstName')"
      @blur="validateField('firstName')"
      name="firstName"
      disabled
    />
    <MTextfield
      v-model:inputValue="lastNameComputed"
      type="text"
      label="Last Name"
      placeholder="Enter Last Name"
      :hasError="!!getError('lastName')"
      :errorMessage="getError('lastName')"
      @blur="validateField('lastName')"
      name="lastName"
      disabled
    />
    <MCombobox
      v-model:inputValue="countryComputed"
      label="Visa Country"
      itemValue="value"
      itemLabel="label"
      :options="countryOptions"
      :hasError="!!getError('country')"
      :errorMessage="getError('country')"
      @blur="validateField('country')"
      name="country"
    />
    <MTextfield
      v-model:inputValue="passportNumberComputed"
      type="text"
      label="Passport Number"
      placeholder="Enter Passport Number"
      :hasError="!!getError('passportNumber')"
      :errorMessage="getError('passportNumber')"
      @blur="validateField('passportNumber')"
      name="passportNumber"
    />
    <MTextfield
      v-model:inputValue="visaNumberComputed"
      type="text"
      label="Visa Number"
      placeholder="Enter Visa Number"
      :hasError="!!getError('visaNumber')"
      :errorMessage="getError('visaNumber')"
      @blur="validateField('visaNumber')"
      name="visaNumber"
    />
    <div class="pdf-upload">
      <MButton @click="triggerFileInput" class="upload-button">
        {{ passenger.visaDocument ? "Change Visa PDF" : "Upload Visa PDF" }}
      </MButton>
      <input
        type="file"
        ref="fileInput"
        @change="handleFileUpload"
        accept="application/pdf"
        style="display: none"
      />
      <div v-if="passenger.visaDocument" class="pdf-preview">
        <p>{{ passenger.visaDocument.name }}</p>
        <MFabButton class="delete-icon" icon="m-delete" @click="deleteFile" />
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref, watch } from "vue";
import { MTextfield, MButton, MCombobox } from "@aeroglobe/ag-core-ui";

interface PassengerVisa {
  name: string;
  firstName: string;
  lastName: string;
  passportNumber: string;
  visaNumber: string;
  visaDocument: File | null;
  isInternational: boolean;
  country: string;
}

export default defineComponent({
  name: "PassengerVisaCard",
  components: {
    MTextfield,
    MButton,
    MCombobox,
  },
  props: {
    passenger: {
      type: Object as PropType<PassengerVisa>,
      required: true,
    },
    errors: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
  },
  emits: ["update:passenger", "validate", "remove-passenger"],
  setup(props, { emit }) {
    const fileInput = ref<HTMLInputElement | null>(null);

    const countryOptions = [{ label: "Saudi Arabia", value: "SA" }];

    const createComputed = <K extends keyof PassengerVisa>(field: K) =>
      computed({
        get: () => props.passenger[field] as string,
        set: (value: string) => {
          emit("update:passenger", { ...props.passenger, [field]: value });
          emit("validate", field, !!value);
        },
      });

    const countryComputed = createComputed("country");
    const firstNameComputed = createComputed("firstName");
    const lastNameComputed = createComputed("lastName");
    const passportNumberComputed = createComputed("passportNumber");
    const visaNumberComputed = createComputed("visaNumber");

    watch(visaNumberComputed, (newValue) => {
      const valueWithoutSpaces = newValue.replace(/\s/g, "");
      if (newValue !== valueWithoutSpaces) {
        visaNumberComputed.value = valueWithoutSpaces;
      }
    });
    watch(passportNumberComputed, (newValue) => {
      const valueWithoutSpaces = newValue.replace(/\s/g, "");
      if (newValue !== valueWithoutSpaces) {
        passportNumberComputed.value = valueWithoutSpaces;
      }
    });

    const validateField = (field: keyof PassengerVisa) => {
      const isValid = !!props.passenger[field];
      emit("validate", field, isValid);
    };

    const triggerFileInput = () => {
      fileInput.value?.click();
    };

    const handleFileUpload = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const file = target.files[0];
        if (file.type === "application/pdf") {
          emit("update:passenger", { ...props.passenger, visaDocument: file });
          emit("validate", "visaDocument", true);
        } else {
          alert("Please upload a PDF file.");
        }
      }
    };

    const deleteFile = () => {
      emit("update:passenger", { ...props.passenger, visaDocument: null });
      emit("validate", "visaDocument", false);
    };

    const getError = (field: keyof PassengerVisa): string => {
      return props.errors[field] || "";
    };

    const removePassenger = () => {
      emit("remove-passenger");
    };

    const validateAllFields = () => {
      const fields: (keyof PassengerVisa)[] = [
        "firstName",
        "lastName",
        "passportNumber",
        "visaNumber",
        "visaDocument",
        "country",
      ];
      fields.forEach(validateField);
    };

    const scrollToFirstError = () => {
      const firstErrorField = Object.keys(props.errors)[0];
      if (firstErrorField) {
        const element = document.querySelector(`[name="${firstErrorField}"]`);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
          (element as HTMLElement).focus();
        }
      }
    };

    return {
      fileInput,
      countryOptions,
      countryComputed,
      firstNameComputed,
      lastNameComputed,
      passportNumberComputed,
      visaNumberComputed,
      validateField,
      triggerFileInput,
      handleFileUpload,
      deleteFile,
      getError,
      removePassenger,
      validateAllFields,
      scrollToFirstError,
    };
  },
});
</script>

<style scoped lang="scss">
.passenger-visa-card {
  width: 420px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pdf-upload {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .upload-button {
    align-self: flex-start;
  }

  .pdf-preview {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      margin: 0;
      font-weight: 500;
    }

    .delete-button {
      padding: 5px 10px;
      font-size: 0.8rem;
    }
  }
}

.remove-passenger-button {
  margin-top: 20px;
  align-self: flex-start;
}

@media (max-width: 767px) {
  .passenger-visa-card {
    width: 100%;
  }
}
</style>
