<template>
  <m-card class="m-flight-summary">
    <div class="m-price">
      <div>
        <m-typography type="headline"> {{ typeOfTrip }} </m-typography>
        <m-typography type="label">
          {{ travelersCount }} Traveler{{ travelersCount > 1 ? "s" : "" }}
        </m-typography>
      </div>
      <div>
        <BookingStatus variant="info">
          {{ pnrStatus }}
        </BookingStatus>
      </div>
    </div>
    <div class="m-flights">
      <m-typography class="m-header" type="title">
        Selected Flight{{ flightDetails.length > 1 ? "s" : "" }}
      </m-typography>

      <template v-for="(flight, index) in flightDetails" :key="index">
        <div class="m-flight">
          <div class="m-title">
            <span class="m-icon">
              <m-flight-take-off-icon />
            </span>
            <m-typography type="body" class="m-text">
              Flight {{ flightDetails.length > 1 ? index + 1 : "" }} ·
              {{ formattedDate(flight.departure_date_time) }}
            </m-typography>
          </div>

          <div class="m-body">
            <div class="m-timeline">
              <div class="date">
                <m-typography type="body">
                  {{ formattedTime(flight.departure_date_time) }}
                </m-typography>
                <m-typography type="label">
                  {{ flight.departure_short_code }}
                </m-typography>
              </div>

              <span class="m-icon">
                <m-flight-timeline-icon />
              </span>

              <div class="date">
                <m-typography type="body">
                  {{ formattedTime(flight.arrival_date_time) }}
                </m-typography>
                <m-typography type="label">
                  {{ flight.arrival_short_code }}
                </m-typography>
              </div>
            </div>

            <div class="m-baggage-info" v-if="baggageInfo(flight.baggage_info)">
              <m-typography type="label">{{
                baggageInfo(flight.baggage_info)
              }}</m-typography>
            </div>
          </div>
        </div>
      </template>
    </div>
  </m-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import {
  MTypography,
  MCard,
  MFlightTakeOffIcon,
  MFlightTimelineIcon,
} from "@aeroglobe/ag-core-ui";
import BookingStatus from "./BookingStatus.vue";

import { AGBaggageInfo } from "@/ag-flight-components/types";
import { IFlightDetails } from "@/modules/FlightIssuance/dtos/flightIssuance.dto";

import { format, parseISO } from "date-fns";

export default defineComponent({
  name: "MFlightSummary",
  components: {
    MCard,
    MTypography,
    MFlightTakeOffIcon,
    MFlightTimelineIcon,
    BookingStatus,
  },
  props: {
    flightDetails: {
      type: Array as PropType<IFlightDetails[]>,
      required: true,
    },
    travelersCount: {
      type: Number,
      required: true,
    },
    pnrStatus: {
      type: String,
      required: true,
    },
  },
  computed: {
    typeOfTrip(): string {
      const flightCount = this.flightDetails.length;

      if (flightCount === 0) {
        return "No Trip";
      }

      const firstFlight = this.flightDetails[0];
      const lastFlight = this.flightDetails[flightCount - 1];

      // Check if it's a return trip
      const isReturnTrip =
        firstFlight.departure_short_code === lastFlight.arrival_short_code;

      if (flightCount === 1) {
        return isReturnTrip ? "Return Trip" : "One Way Trip";
      }

      // Check if it's a multi-city trip
      const isMultiCityTrip = this.flightDetails
        .slice(1)
        .every(
          (flight, index) =>
            flight.departure_short_code ===
            this.flightDetails[index].arrival_short_code
        );

      return isReturnTrip
        ? "Return Trip"
        : isMultiCityTrip
        ? "Multi City Trip"
        : "One Way Trip";
    },
  },
  methods: {
    formattedTime(date: string): string {
      const arrivalTime = new Date(date);
      const parsedTime = parseISO(arrivalTime.toISOString());
      const formattedTime = format(parsedTime, "HH:mm");

      return formattedTime;
    },
    formattedDate(date: string): string {
      const arrivalDate = new Date(date);
      const parsedDate = parseISO(arrivalDate.toISOString());
      const formattedDate = format(parsedDate, "EEE, MMM dd, yyyy");

      return formattedDate;
    },

    baggageInfo(baggage_info: AGBaggageInfo | null | undefined): string | null {
      if (baggage_info) {
        const value = baggage_info.value ? Number(baggage_info.value) : 0;
        const type = baggage_info.type ? baggage_info.type : "";
        const isPlural = value > 1 ? "(s)" : "";

        switch (type.toUpperCase()) {
          case "P":
            return `${value} piece${isPlural} of baggage is allowed`;

          case "K":
            return `${value} kilogram${isPlural} of baggage is allowed`;

          case "L":
            return `${value} luggage${isPlural} is allowed`;

          case "C":
            return `${value} carry-on${isPlural} is allowed`;

          default:
            return `${value} ${type}`;
        }
      } else {
        return null;
      }
    },
  },
});
</script>

<style scoped>
.m-flight-summary {
  width: 100%;
  --s-border-color: #ececec;
}

.m-price {
  width: 100%;

  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;
}

.m-current-price {
  color: var(--theme-color);
}

.m-flight {
  border-radius: 12px;
  border: 1px solid var(--s-border-color);
  margin-bottom: 16px;
}

.m-flights .m-header {
  margin-bottom: 8px;
}

.m-flight .m-title {
  border-bottom: 1px solid var(--s-border-color);
}

.m-flight .m-title,
.m-flight .m-body {
  padding: 16px;
}

.m-flight .m-title {
  display: flex;
  gap: 24px;
}

.m-flight .m-title .m-text {
  font-size: 14px;
}

.m-flight .m-body {
  display: flex;
  flex-direction: column;
}

.m-flight .m-body .m-baggage-info {
  margin-top: 24px;
}

.m-flight .m-body .m-timeline {
  display: flex;
  gap: 8px;
}

.m-icon {
  width: 24px;
  height: 24px;
}
</style>
