import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e2bfed0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error_msg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgCard = _resolveComponent("AgCard")!
  const _component_AgFile = _resolveComponent("AgFile")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_UploadDocumentSuccessDialog = _resolveComponent("UploadDocumentSuccessDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AgCard, null, {
      default: _withCtx(() => [
        _createVNode(_component_ag_heading, {
          variant: "h2",
          title: "Upload Documents"
        })
      ]),
      _: 1
    }),
    _createVNode(_component_AgCard, { class: "form_container" }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args))),
          class: "form"
        }, [
          _createVNode(_component_AgDiv, null, {
            default: _withCtx(() => [
              _createVNode(_component_AgFile, {
                "test-id": "",
                class: "ag-file-theme",
                label: "Upload CNIC (Front, Back)",
                multiple: true,
                "onOn:change": _ctx.onFileChangeHandler
              }, null, 8, ["onOn:change"]),
              _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.fieldError), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_AGButton, {
            "test-id": "",
            type: "submit",
            class: "submit-btn",
            "is-loading": _ctx.isLoading,
            disabled: _ctx.isDisabled
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Upload ")
            ]),
            _: 1
          }, 8, ["is-loading", "disabled"])
        ], 32)
      ]),
      _: 1
    }),
    _createVNode(_component_UploadDocumentSuccessDialog, {
      "is-open": _ctx.isDialogOpen,
      onHandleClose: _ctx.handleCloseDialog
    }, null, 8, ["is-open", "onHandleClose"])
  ], 64))
}