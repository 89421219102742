import { RouteRecordRaw } from "vue-router";
import Login from "@/modules/Auth/views/Login.vue";
import Signup from "@/modules/Auth/views/Signup.vue";
import ForgotPassword from "@/modules/Auth/views/ForgotPassword.vue";
import { PATH } from "@/ag-portal-common/constants/path";
import ResetPassword from "@/modules/Auth/views/ResetPassword.vue";
import DownloadVisaVerification from "@/modules/VisaVerification/views/DownloadVisaVerification.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "login",
    component: Login,
  },
  {
    path: PATH.SIGNUP,
    name: "signup",
    component: Signup,
  },
  {
    path: PATH.FORGOT_PASSWORD,
    name: "forgotPassword",
    component: ForgotPassword,
  },
  {
    path: PATH.RESET_PASSWORD,
    name: "resetPassword",
    component: ResetPassword,
  },
  {
    path: PATH.DOWNLOAD_VISA_VERIFICATION,
    name: "downloadVisaVerificationPDF",
    component: DownloadVisaVerification,
  },
];

export default routes;
