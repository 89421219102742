class LoggerService {
  private logger: Console;

  constructor() {
    if (process.env.NODE_ENV === "production") {
      this.logger = {
        info() {
          //Empty function
        },
        error() {
          //Empty function
        },
      } as Console;
    } else {
      this.logger = console;
    }
  }

  public logInfo(msg: string, data?: any) {
    this.logger.info(msg, data);
  }

  public logError(msg: string, error: any) {
    this.logger.error(msg, error);
  }
}

export default new LoggerService();
