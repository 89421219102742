import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b120a63"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "flight-booking-form"
}
const _hoisted_2 = { class: "header-pax-info-container" }
const _hoisted_3 = { class: "booking-id-wrap" }
const _hoisted_4 = { class: "traveler-form-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TourDetailShimmer = _resolveComponent("TourDetailShimmer")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_AgPhoneField = _resolveComponent("AgPhoneField")!
  const _component_MTextfield = _resolveComponent("MTextfield")!
  const _component_ContactDetailsPreview = _resolveComponent("ContactDetailsPreview")!
  const _component_ContactDetailsAccordion = _resolveComponent("ContactDetailsAccordion")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_TravelersComponent = _resolveComponent("TravelersComponent")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_TripSummary = _resolveComponent("TripSummary")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!
  const _component_MFinancialProfileCombobox = _resolveComponent("MFinancialProfileCombobox")!
  const _component_OTPInputModal = _resolveComponent("OTPInputModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isFetchingFDGBookingDetail)
      ? (_openBlock(), _createBlock(_component_TourDetailShimmer, { key: 0 }))
      : (!_ctx.isFetchingFDGBookingDetail && _ctx.fdgBookingDetail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_MCard, { class: "margin-bottom-20" }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ag_column, {
                      xs: "12",
                      sm: "12",
                      md: "8",
                      lg: "9"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_MCard, { class: "group-flight-form-title-card" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_MTypography, {
                              type: "headline",
                              class: "group-flight-title"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.formatGroupFlightTitle(_ctx.flightItems)), 1)
                              ]),
                              _: 1
                            }),
                            _createElementVNode("div", _hoisted_2, [
                              _createVNode(_component_MIcon, { name: "m-adult-icon" }),
                              _createVNode(_component_MTypography, { type: "body" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.renderHeaderPassengerCount), 1)
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ag_column, {
                      xs: "12",
                      sm: "12",
                      md: "3"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_MTypography, {
                            class: "booking-id-title",
                            type: "title"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Booking Confirmation ")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_MTypography, { type: "headline" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.bookingId), 1)
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_AgRow, null, {
              default: _withCtx(() => [
                _createVNode(_component_AgColumn, {
                  md: "8",
                  lg: "9",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ContactDetailsAccordion, {
                      headline: "Contact Details",
                      title: "Lead Traveler",
                      initialOpen: true,
                      externalOpen: _ctx.contactAccordionOpen,
                      ref: "accordionContactInfo",
                      "icon-name": "m-contact",
                      errorsLength: _ctx.contactErrorsLength
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", null, [
                            _createVNode(_component_AgPhoneField, {
                              "test-id": "",
                              "default-country-code": "PK",
                              "info-message": "Please input the traveler's number here to receive flight updates",
                              error: _ctx.contactErrors.contact_number,
                              onUpdateValue: _ctx.onParsePhoneNumberHandler
                            }, null, 8, ["error", "onUpdateValue"]),
                            _createVNode(_component_MTextfield, {
                              inputValue: _ctx.email,
                              "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                              type: "email",
                              label: "Email",
                              placeholder: "Enter your email",
                              name: "email",
                              hasError: !!_ctx.contactErrors.email,
                              errorMessage: _ctx.contactErrors.email,
                              onBlur: _ctx.validateEmail,
                              onInput: _ctx.onEmailInput
                            }, null, 8, ["inputValue", "hasError", "errorMessage", "onBlur", "onInput"])
                          ]),
                          _createVNode(_component_ContactDetailsPreview, {
                            title: "Contact Details",
                            traveler: _ctx.contactDetails,
                            previewFields: _ctx.getPreviewFields(),
                            formatPreviewValue: _ctx.formatPreviewValue,
                            errors: _ctx.contactErrors
                          }, null, 8, ["traveler", "previewFields", "formatPreviewValue", "errors"])
                        ])
                      ]),
                      _: 1
                    }, 8, ["externalOpen", "errorsLength"]),
                    (!_ctx.showTravelersForms)
                      ? (_openBlock(), _createBlock(_component_MCard, {
                          key: 0,
                          class: "contact-details-button-container"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_MButton, {
                              disabled: _ctx.isOTPLoading,
                              onClick: _ctx.handleContinueWithoutDetails
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Continue without filling details")
                              ]),
                              _: 1
                            }, 8, ["disabled", "onClick"]),
                            _createVNode(_component_MButton, {
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showTravelersForms = true))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Fill Passenger's Details")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.showTravelersForms)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createVNode(_component_TravelersComponent, {
                            travelers: _ctx.travelers,
                            "onUpdate:travelers": _ctx.updateTravelers,
                            onSubmit: _ctx.handleTravelersSubmit,
                            ref: "travelersComponent"
                          }, null, 8, ["travelers", "onUpdate:travelers", "onSubmit"]),
                          _createVNode(_component_MCard, { class: "confirm-bottom-btn-container" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_MButton, {
                                disabled: _ctx.isOTPLoading,
                                onClick: _ctx.handleContinueWithoutDetails
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Continue without filling details")
                                ]),
                                _: 1
                              }, 8, ["disabled", "onClick"]),
                              _createVNode(_component_MButton, {
                                disabled: _ctx.isOTPLoading,
                                onClick: _ctx.handleSubmit
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Confirm Booking")
                                ]),
                                _: 1
                              }, 8, ["disabled", "onClick"])
                            ]),
                            _: 1
                          })
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_AgColumn, {
                  md: "4",
                  lg: "3",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MCard, { class: "margin-bottom-20" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_MTypography, {
                          type: "headline",
                          class: "trip-summary-title"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Trip Summary")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_TripSummary, { legs: _ctx.flightlegs }, null, 8, ["legs"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createBlock(_component_ResultNotFound, {
            key: 2,
            class: "result-not-found result-not-found-margin",
            title: _ctx.NOTIFICATION_MESSAGES.NO_RESULTS_FOUND
          }, null, 8, ["title"])),
    _createVNode(_component_OTPInputModal, {
      isOpen: _ctx.showOtpModal,
      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showOtpModal = false)),
      onSubmit: _ctx.handleSubmitVerifyOtp,
      isSubmitLoading: 
      _ctx.withoutPaxDetailsConfirmLoading || _ctx.withPaxDetailsConfirmLoading
    ,
      title: "OTP Confirmation",
      instruction: "Enter the 6-digit code sent to your email."
    }, {
      component: _withCtx(() => [
        _createVNode(_component_MFinancialProfileCombobox, {
          class: "fp-combo",
          inputValue: _ctx.financialProfileInput,
          "onUpdate:inputValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.financialProfileInput) = $event)),
          label: "Select Financial Profile",
          itemValue: "value",
          itemLabel: "label",
          disabled: _ctx.isFinancialProfileFetching,
          options: _ctx.financialProfileOptions,
          hasError: !!_ctx.fpErrorMessage,
          errorMessage: _ctx.fpErrorMessage
        }, null, 8, ["inputValue", "disabled", "options", "hasError", "errorMessage"])
      ]),
      _: 1
    }, 8, ["isOpen", "onSubmit", "isSubmitLoading"])
  ], 64))
}