import { Module } from "vuex";
import { ICreditLimitManagementState } from "@/interfaces/creditLimitManagementState.interface";
import { StoreState } from "@/store/type";

import mutations from "./mutations";
import actions from "./actions";
import state from "./state";
import getters from "./getters";

const creditLimitManagementModule: Module<
  ICreditLimitManagementState,
  StoreState
> = {
  state,
  mutations,
  actions,
  getters,
};

export default creditLimitManagementModule;
