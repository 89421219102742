import { MutationTree } from "vuex";
import {
  ActiveProvidersAndAirlinesRefundApiResponse,
  CalculateFlightRefundPNRResponse,
  FetchFlightRefundPNRResponse,
  IFlightRefundState,
} from "@/ag-flight-components/interfaces/IFlightRefunds.interface";

const mutations: MutationTree<IFlightRefundState> = {
  isPNRFetching(state, isPNRFetching: boolean) {
    state.isPNRFetching = isPNRFetching;
  },
  fetchedPNRDetail(state, payload: FetchFlightRefundPNRResponse) {
    state.isPNRFetching = false;
    state.refundPNRDetails = payload;
  },
  setPnr(state, payload: string) {
    state.refundPNR = payload;
  },
  fetchingCalculateRefundPNR(state, canCalculateRefund: boolean) {
    state.canCalculateRefund = canCalculateRefund;
  },
  calculatePNRDetail(state, payload: CalculateFlightRefundPNRResponse) {
    state.canCalculateRefund = false;
    state.calculateDetails = payload;
  },
  fetchingCommitRefundPNR(state, value: boolean) {
    state.isCommitingRefund = value;
  },
  resetRefundPNRDetails(state) {
    state.calculateDetails = null;
    state.refundPNR = null;
    state.refundPNRDetails = null;
    state.canCalculateRefund = false;
    state.isCommitingRefund = false;
    state.refundAirlinesAndProvider = null;
    state.isRefundAirlineAndProviderFetching = false;
  },
  isRefundAirlineAndProviderFetching(
    state,
    isRefundAirlineAndProviderFetching: boolean
  ) {
    state.isRefundAirlineAndProviderFetching =
      isRefundAirlineAndProviderFetching;
  },
  addRefundActiveAirlinesAndProviders(
    state,
    payload: ActiveProvidersAndAirlinesRefundApiResponse
  ) {
    state.refundAirlinesAndProvider = payload;
  },
};

export default mutations;
