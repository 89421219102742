import { GetterTree } from "vuex";

import { StoreState } from "@/store/type";
import { IDealsState } from "./state";

const getters: GetterTree<IDealsState, StoreState> = {
  isFetchingPromosAndDeals: (state) => state.isFetchingPromosAndDeals,
  promos: (state) => state.promos,
  deals: (state) => state.deals,
};
export default getters;
