<template>
  <div
    class="m-hotel-destination-combobox"
    :disabled="disabled"
    :has-error="hasError"
  >
    <label
      :for="inputId"
      class="m-label m-index-level-one m-textoverflow"
      :class="{ 'm-active': isActive }"
    >
      {{ label }}
    </label>
    <div class="m-hotel-destination-combobox-container">
      <MIcon v-if="prependIcon" class="m-prepend-icon" :name="prependIcon" />
      <input
        ref="MCombobox"
        :id="inputId"
        v-model.trim="inputModal"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="handleInput"
        :class="[
          'm-input',
          {
            hasPrependIcon: !!prependIcon,
            'm-hotel-destination-combobox-focused': isFocused,
          },
        ]"
        :placeholder="placeholder"
        type="text"
        :readonly="disabled"
      />
      <div
        class="m-icon"
        @click="onInputClickHandler"
        :class="{ 'm-active': isFocused }"
      >
        <MTransition :duration="0.15">
          <template v-if="isdestinationsLoading">
            <div class="m-combobox-icon">
              <span class="loader"></span>
            </div>
          </template>
          <template v-else>
            <template v-if="!isFocused">
              <MIcon class="m-combobox-icon" name="m-dropdown" />
            </template>
            <template v-else>
              <MIcon class="m-combobox-icon" name="m-cancel" />
            </template>
          </template>
        </MTransition>
      </div>
      <template v-if="isFocused">
        <MTransition :appear="true" :duration="0.25">
          <template v-if="filteredOptions.length">
            <div class="m-dropdown m-scrollbar m-index-level-two">
              <div
                class="m-item"
                v-for="(option, index) in filteredOptions"
                :key="index"
                @click="handleSelect(option)"
                :is-selected="inputModal.includes(option.display_name)"
              >
                <div class="details m-textoverflow destination-item-container">
                  <div class="location-icon-container">
                    <MIcon :name="prependDropdownIcon(option.type)"></MIcon>
                  </div>
                  <div class="destination-name-container">
                    <div class="city-country-label">
                      {{ option.display_name }}
                    </div>
                    <div class="destination-label">
                      {{ option.sub_display_name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <template v-if="isdestinationsLoading">
              <span></span>
            </template>
            <template v-else>
              <div class="m-dropdown m-index-level-two">
                <div class="m-item destination-not-found">
                  No destination Found
                </div>
              </div>
            </template>
          </template>
        </MTransition>
      </template>
    </div>
    <div v-show="hasError" class="m-input-error-text">{{ errorMessage }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { DestinationComboxboxOptions } from "../interfaces/hotels.interface";
import { MIcon, MTransition } from "@aeroglobe/ag-core-ui";
import { IconName } from "@aeroglobe/ag-core-ui/dist/src/components/material/icons/icons.type";

export default defineComponent({
  name: "MHotelDestinationComboBox",
  components: { MIcon, MTransition },
  data() {
    return {
      inputModal: "",
      isActive: false,
      isFocused: false,
      inputId: `m-hotel-destination-combobox-${Math.random()
        .toString(36)
        .substring(7)}`,
      canSort: false,
      isdestinationsLoading: false,
      isItemSelected: false,
    };
  },
  props: {
    inputValue: {
      type: String,
      required: true,
    },
    options: {
      type: Array as PropType<DestinationComboxboxOptions[]>,
      default: () => [],
      required: true,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
    },
    errorMessage: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String as PropType<IconName>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    focusInput: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:inputValue", "update:inputText"],
  computed: {
    filteredOptions(): DestinationComboxboxOptions[] {
      if (this.canSort) {
        return this.options
          .filter(
            (option) =>
              option.display_name
                .toLowerCase()
                .includes(this.inputModal.toLowerCase()) ||
              option.sub_display_name
                .toLowerCase()
                .includes(this.inputModal.toLowerCase())
          )
          .slice(0, 50);
      } else {
        return this.options.slice(0, 50);
      }
    },
  },
  watch: {
    inputModal(val: string): void {
      if (!this.isItemSelected) {
        this.$emit("update:inputText", val);
      }
    },
    inputValue: {
      handler(val: string) {
        if (val === "") {
          this.inputModal = "";

          if (!this.isFocused) {
            this.handleBlur();
          }
        } else {
          this.onFindSelectedOptionHandler(val);
        }
      },
      immediate: true,
    },
    isLoading(newValue) {
      this.isdestinationsLoading = newValue;
    },
    focusInput(value: string) {
      if (value) {
        this.handleFocus();
      }
    },
  },
  methods: {
    prependDropdownIcon(type: string) {
      return type === "PROPERTY" ? "m-hotel" : "m-location";
    },
    handleSelect(option: DestinationComboxboxOptions): void {
      this.inputModal = `${option.display_name} (${option.sub_display_name})`;
      this.$emit("update:inputValue", option);
      this.isItemSelected = true;
      setTimeout(() => {
        this.isItemSelected = false;
      }, 1000);
      this.handleBlur();
    },
    handleInput(): void {
      if (!(this.inputModal === "")) {
        this.canSort = true;
      }
    },
    handleFocus(): void {
      this.isActive = true;
      this.isFocused = true;

      const inputElement = this.$refs["MCombobox"] as
        | HTMLInputElement
        | undefined;

      if (inputElement) {
        inputElement.select();
      }
    },
    handleBlur(): void {
      if (this.inputModal === "") {
        this.onFindSelectedOptionHandler(this.inputValue);
      }

      setTimeout(() => {
        this.isActive = this.inputModal !== "" || this.placeholder !== "";
        this.isFocused = false;
        this.canSort = false;
      }, 300);
    },
    onInputClickHandler(): void {
      if (!this.isFocused) {
        const inputElement = this.$refs["MCombobox"] as
          | HTMLInputElement
          | undefined;

        if (inputElement) {
          inputElement.focus();
        }
      } else {
        this.inputModal = "";
        this.$emit("update:inputValue", "");
      }
    },
    onFindSelectedOptionHandler(value: string): void {
      const selectedValue =
        typeof value === "string" ? value?.toLocaleUpperCase() : value;
      const selectedOption = this.options.find(
        (option) => option?.display_name === selectedValue
      );

      if (selectedOption) {
        this.handleSelect(selectedOption);
      }
    },
  },
  mounted(): void {
    this.handleBlur();
  },
});
</script>

<style scoped lang="css">
.m-hotel-destination-combobox {
  width: 100%;
  position: relative;
  height: fit-content;
  color: var(--m-secondary-color);
}

.m-hotel-destination-combobox[disabled="true"] {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.m-label {
  position: absolute;
  top: 17px;
  left: 13px;
  padding: 0px 4px;

  pointer-events: none;
  user-select: none;
  transition: 0.2s ease-out;

  color: var(--m-label-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;

  background: var(--m-light-color);

  max-width: calc(100% - 32px);
}

.m-hotel-destination-combobox-container {
  position: relative;
}

.m-combobox-icon {
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 0;
  top: 0;

  cursor: pointer;
}

.m-prepend-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 14px;
  top: 17px;
}

.m-dropdown {
  width: 100%;
  max-height: 280px;

  overflow-y: auto;
  position: absolute;
  top: 62px;

  border-radius: 4px;

  overflow-y: auto;
  background: var(--m-light-color);
  box-shadow: var(--m-menu-shadow-color);
}

.m-dropdown .m-item {
  height: 72px;
  padding: 12px 16px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.m-dropdown .m-item[is-selected="true"] {
  background-color: var(--m-secondary-accent-color);
}

.m-dropdown .m-item:hover {
  background-color: var(--m-secondary-accent-color);
}

.m-dropdown .m-item:hover:first-child {
  border-radius: 4px 4px 0 0;
}

.m-dropdown .m-item:hover:last-child {
  border-radius: 0 0 4px 4px;
}

.m-dropdown .m-item:not(:last-child) {
  border-bottom: 1px solid var(--m-divider-color);
}

.details {
  width: 100%;
}
.m-dropdown .m-item .details .details-header {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.m-dropdown .m-item .label {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
}

.m-dropdown .m-item .details .details-statuses {
  display: grid;
  align-items: center;
  grid-template-columns: 20px 60px 15px 20px auto;
  gap: 8px;

  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
}

.m-dropdown .m-item .details .details-statuses .details-status-icon,
.m-dropdown .m-item .details .details-statuses .details-type-icon {
  height: 20px;
  width: 20px;
}
.m-dropdown .m-item .details .details-statuses .details-divider {
  height: 16px;
}

.m-dropdown
  .m-item
  .details
  .details-statuses
  .details-status-icon[status="active"] {
  color: var(--m-success-color);
}
.m-dropdown
  .m-item
  .details
  .details-statuses
  .details-status-icon[status="inactive"] {
  color: var(--m-error-color);
}

.m-dropdown .m-item .details .details-statuses .details-type-icon[type="pro"] {
  color: var(--m-info-color);
}
.m-dropdown
  .m-item
  .details
  .details-statuses
  .details-type-icon[type="elite"] {
  color: var(--m-success-color);
}
.m-dropdown .m-item .details .details-statuses .details-type-icon[type="lite"] {
  color: var(--m-success-color);
}
.m-dropdown
  .m-item
  .details
  .details-statuses
  .details-type-icon[type="smart"] {
  color: var(--m-warning-color);
}

.m-input {
  width: 100%;
  padding: 16px;
  padding-right: 60px;
  border-radius: 4px;

  color: var(--m-secondary-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;

  transition: border-color 0.2s ease-out;

  border-radius: 4px;
  border: 1px solid var(--m-border-color);

  background: var(--m-light-color);
}

.m-input:focus {
  outline: none;
  border-color: var(--m-secondary-color);
}

.m-label.m-active {
  top: -7px;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.m-hotel-destination-combobox[has-error="true"] .m-combobox-icon,
.m-hotel-destination-combobox[has-error="true"] .m-label,
.m-hotel-destination-combobox[has-error="true"] .m-input,
.m-hotel-destination-combobox[has-error="true"] .m-input:hover {
  color: var(--m-error-color);
  border-color: var(--m-error-color);
}

.m-input-error-text {
  margin-left: 16px;
  margin-top: 7px;
  position: absolute;

  color: var(--m-error-color);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.hasPrependIcon {
  padding-left: 50px;
}
.destination-item-container {
  display: flex;
  align-items: center;
  gap: 10px;
  .destination-name-container {
    line-height: 1.3;
    .city-country-label {
      font-size: 14px;
      font-weight: 600;
      .iata-code {
        color: #10b981;
        font-size: 14px;
        font-weight: 600;
        margin-left: 3px;
      }
    }
    .destination-label {
      font-size: 13px;
      font-weight: 400;
    }
  }
}

.destination-not-found {
  font-size: 14px;
  font-weight: 600;
  width: 100%;
}

.location-icon-container {
  padding-top: 5px;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid var(--m-secondary-color);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.m-hotel-destination-combobox-focused {
  border: 1.5px solid var(--m-primary-color) !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
