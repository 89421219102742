import { IFDGItem } from "@/interfaces/fdg.interface";
import { format } from "date-fns";

const formatDate = (date: Date) => {
  return format(date, "dd MMM yy").toUpperCase();
};

export const formatGroupFlightTitle = (items: IFDGItem[]) => {
  if (!Array.isArray(items) || items.length === 0) {
    return "";
  }

  const flightDetails = items
    .filter((item) => item.itemType === "FLIGHT" && item.flightItemDetails)
    .map((item) => item.flightItemDetails!);

  if (flightDetails.length === 0) {
    return "";
  }

  const routePairs = flightDetails.reduce((pairs, flight, index, array) => {
    if (index === 0) {
      pairs.push(`${flight.originIATACode} ✈ ${flight.destinationIATACode}`);
    } else {
      const prevFlight = array[index - 1];
      if (flight.originIATACode === prevFlight.destinationIATACode) {
        pairs.push(` ✈ ${flight.destinationIATACode}`);
      } else {
        pairs.push(`${flight.originIATACode} ✈ ${flight.destinationIATACode}`);
      }
    }
    return pairs;
  }, [] as string[]);

  const route = routePairs.join("");

  const startDate = format(
    new Date(flightDetails[0].departureDatetime),
    "dd MMM yy"
  ).toUpperCase();

  const endDate =
    flightDetails.length > 1
      ? formatDate(
          new Date(flightDetails[flightDetails.length - 1].departureDatetime)
        )
      : startDate;

  const dateRange =
    flightDetails.length > 1 ? `${startDate} - ${endDate}` : startDate;

  return `${route} | ${dateRange}`;
};
