import { Module } from "vuex";
import { IFlightSearch } from "@/ag-flight-components/interfaces/IFlightSearch.interface";
import { StoreState } from "@/store/type";

import mutations from "./mutations";
import actions from "./actions";
import state from "./state";
import getters from "./getters";

const flightModule: Module<IFlightSearch, StoreState> = {
  state,
  mutations,
  actions,
  getters,
};

export default flightModule;
