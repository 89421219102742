import * as yup from "yup";
import { differenceInYears } from "date-fns";
import { validateMobileNumber } from "@/ag-portal-common/utils/helpers";
import { PASSENGER_TYPE } from "@/ag-portal-common/enums/PASSENGER_TYPES";

const bookingFormValidationSchema = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .email("Please enter a valid email address")
    .required("Email is required"),
  guests: yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().nullable().required("Title is required"),
        first_name: yup
          .string()
          .nullable()
          .test(
            "alphaNumberic",
            "First Name must not contain alphanumeric characters",
            (value) => {
              return /^[^\d@]+(\s+[^\d@]+)*$/.test(value || "");
            }
          )
          .min(2, "Please enter a valid First Name")
          .required("First Name is required"),
        last_name: yup
          .string()
          .nullable()
          .test(
            "alphaNumberic",
            "Last Name must not contain alphanumeric characters",
            (value) => {
              return /^[^\d@]+(\s+[^\d@]+)*$/.test(value || "");
            }
          )
          .min(2, "Please enter a valid First Name")
          .required("Last Name is required"),
        date_of_birth: yup
          .date()
          .nullable()
          .test(
            "required",
            "Adult age must be greater than or equal to 18 years",
            (value, { parent }) => {
              if (parent.passenger_type === PASSENGER_TYPE.ADULT) {
                return differenceInYears(new Date(), value as Date) >= 18;
              }
              return true;
            }
          )
          .test(
            "required",
            "Child age must be less than or equal to 11 years",
            (value, { parent }) => {
              if (parent.passenger_type === PASSENGER_TYPE.CHILD) {
                const ageInYears = differenceInYears(new Date(), value as Date);
                return ageInYears < 18;
              }
              return true;
            }
          ),
      })
    )
    .min(1, "Minimum 1 traveler is required")
    .required("Minimum 1 traveler is required"),
});

export default bookingFormValidationSchema;
