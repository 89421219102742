<template>
  <MCard class="organization-snapshot-header">
    <ag-column sm="12" lg="6">
      <MCombobox
        v-model:inputValue="selectedOrganization"
        label="Select Organization"
        itemValue="value"
        itemLabel="name"
        :options="organizations"
        class="organization-autocomplete"
      />
    </ag-column>
    <ShimmerCard
      card-height="400px"
      v-if="$store.getters.isOrganizationStatsLoading"
    />
    <template v-else>
      <template v-if="showDetails">
        <div class="name-container">
          <img :src="organizationStats?.organization?.org_logo" />
          <div class="agency-name-container">
            <p class="agency-name">{{ renderOrgName() }}</p>
            <p>
              <a
                class="website-slug"
                :href="`https://${generateWebsiteDomain(renderWebsite())}`"
                target="_blank"
              >
                {{ generateWebsiteDomain(renderWebsite()) }}
              </a>
            </p>
          </div>
        </div>
        <hr />
        <MCard>
          <div class="grid-container">
            <SnapshotTile
              heading="Owners"
              subHeadingTitle="Count"
              :subHeading="organizationStats?.owner_count"
              icon="m-my-team"
            >
            </SnapshotTile>
            <SnapshotTile
              heading="Sub Agents"
              :subHeading="organizationStats?.sub_agent_count"
              subHeadingTitle="Count"
              icon="m-my-team"
            >
            </SnapshotTile>
            <SnapshotTile
              heading="Employees"
              :subHeading="organizationStats?.employee_count"
              subHeadingTitle="Count"
              icon="m-my-team"
            >
            </SnapshotTile>
            <SnapshotTile
              heading="Website Status"
              :subHeading="organizationStats?.organization?.website_status"
              subHeadingTitle="Status"
              icon="m-website-management"
            >
            </SnapshotTile>
            <SnapshotTile
              heading="Theme Color"
              :subHeading="renderColorCode()"
              subHeadingTitle="Color"
              icon="m-color-icon"
            >
            </SnapshotTile>
          </div>
        </MCard>

        <MAccordion :initial-open="true">
          <template v-slot:title>
            <AgIconInfoBar
              mPrepandIcon="m-agents"
              title="Organization Users"
              class="accordion-header"
            />
          </template>
          <template #content>
            <MDataTable
              :headers="headers"
              :data="users() || []"
              :item-per-page="10"
              :has-search="true"
            >
              <template #last_login="{ item }">
                <MTypography
                  type="body"
                  class="description td-full-width"
                  v-if="item.formatted_last_login_date"
                >
                  {{
                    getFormattedDateTime(
                      item.formatted_last_login_date,
                      FORMAT_DD_MMM_YYYY_WITH_DASH
                    )
                  }}
                </MTypography>
                <MTypography type="label" class="description td-full-width">
                  {{
                    item.formatted_last_login_date
                      ? getFormattedDateTime(
                          item.formatted_last_login_date,
                          FORMAT_HH_SS_24_HOURS
                        )
                      : "-"
                  }}
                </MTypography>
              </template>
            </MDataTable>
          </template>
        </MAccordion>
      </template>
      <ResultNotFound v-if="showResultNotFound" />
    </template>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formatDistance } from "date-fns";
import _ from "lodash";

import {
  IOrganizationStats,
  OrganizationUserInfo,
} from "@/ag-portal-common/interfaces/organizationStatistics.interface";
import {
  formatQueryPath,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import ShimmerCard from "@/components/ShimmerCard.vue";
import ResultNotFound from "@/modules/Organization/components/OrganizationDetails/ResultNotFound.vue";
import {
  MDataTable,
  MAccordion,
  MCard,
  MTypography,
} from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";
import SnapshotTile from "@/components/SnapshotTile.vue";
import {
  FORMAT_HH_SS_24_HOURS,
  FORMAT_DD_MMM_YYYY_WITH_DASH,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { IOrganization } from "@/ag-portal-common/interfaces/organization.interface";

export default defineComponent({
  name: "OrganizationSnapshotAnalyticsTab",
  components: {
    ShimmerCard,
    ResultNotFound,
    MDataTable,
    MAccordion,
    MCard,
    SnapshotTile,
    MTypography,
  },
  data(): {
    headers: MDataTableHeader[];
    lastOrganizationId: string;
    isInitial: boolean;
    FORMAT_DD_MMM_YYYY_WITH_DASH: string;
    FORMAT_HH_SS_24_HOURS: string;
    selectedOrganization: string;
  } {
    return {
      selectedOrganization: "",
      FORMAT_DD_MMM_YYYY_WITH_DASH,
      FORMAT_HH_SS_24_HOURS,
      headers: [
        {
          title: "Email",
          value: "email",
          key: "email",
          sortable: true,
          align: "left",
          contentAlign: "left",
        },
        {
          title: "User Type",
          value: "user_type",
          key: "user_type",
          sortable: true,
        },
        {
          title: "Last Login",
          value: "last_login",
          key: "last_login",
          sortable: true,
        },
        {
          title: "Days Ago",
          value: "days_ago",
          key: "days_ago",
          sortable: true,
        },
      ],
      lastOrganizationId: "",
      isInitial: true,
    };
  },
  methods: {
    getFormattedDateTime,
    generateWebsiteDomain(slug: string) {
      const isDev = process.env.NODE_ENV === "development";
      return `${slug}${isDev ? ".dev" : ""}.web.aeroglobe.pk`;
    },
    formatLastLogin(date: Date | null): string {
      return formatDistance(date ? date : new Date(), new Date(), {
        addSuffix: true,
      });
    },
    handleOrganizationChange() {
      const path = `${PATH.ORGANIZATION_SNAPSHOT}${formatQueryPath({
        org_id: this.selectedOrganization,
      })}`;
      this.$router.push(path);
    },
    fetchOrganization() {
      const { org_id } = this.$route.query;
      this.isInitial = false;
      if (org_id) {
        this.$store.dispatch("getOrganizationStats", org_id);
      }
    },
    renderOrgName() {
      return this.organizationStats?.organization?.org_name || "Aeroglobe";
    },
    renderWebsite() {
      return this.organizationStats?.organization?.website_slug || "Aeroglobe";
    },
    renderColorCode() {
      return this.organizationStats?.organization?.org_default_color;
    },
    users() {
      const Users = this.organizationStats?.users || [];
      const formatedUsers = Users.map((item: OrganizationUserInfo) => {
        return {
          email: item.email,
          last_login: item.last_logged_in_at_iso
            ? new Date(item.last_logged_in_at_iso)
            : "",
          formatted_last_login_date: item.last_logged_in_at_iso,
          user_type: item.user_type.replace("_", " "),
          days_ago: item.days_ago,
        };
      });
      return formatedUsers;
    },
  },
  computed: {
    organizations() {
      return this.$store.getters.organizations.map((item: IOrganization) => ({
        value: item.public_id,
        name: item.name,
      }));
    },
    websiteEnabled() {
      return "Enabled";
    },
    isStatsFetching(): boolean {
      return this.$store.getters.isOrganizationStatsLoading;
    },
    organizationStats(): IOrganizationStats {
      return this.$store.getters.organizationStats;
    },
    showDetails(): boolean {
      const isNotEmpty = !_.isEmpty(this.organizationStats);
      return isNotEmpty;
    },
    showResultNotFound(): boolean {
      return !this.isInitial && !this.isStatsFetching && !this.showDetails;
    },
  },
  watch: {
    "$route.query": {
      handler: function (value) {
        const isNotEmpty = !_.isEmpty(value);
        if (isNotEmpty) {
          if (this.lastOrganizationId !== value?.org_id) {
            this.fetchOrganization();
            this.lastOrganizationId = value?.org_id;
          }
        }
      },
      immediate: true,
    },
    selectedOrganization: {
      handler: function () {
        this.handleOrganizationChange();
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.organization-snapshot-header {
  .name-container {
    display: flex;
    align-items: center;
    margin-top: 40px;

    img {
      width: 70px;
    }

    .agency-name-container {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .agency-name {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
        color: #000;
      }

      p {
        margin-bottom: 0;
        color: #6b7280;
      }
    }
  }
}

.heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}

.color-chip {
  width: 120px;
}

.website-slug {
  font-weight: bold;
  color: #6b7280;

  &:hover {
    color: #0c47bc;
  }
}

.white-icon {
  color: #fff;
}

.accordion-header {
  border: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
</style>

<style lang="css" scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

@media (max-width: 1350px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 963px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
</style>
