<template>
  <form class="traveler-card-form" @submit.prevent>
    <MCombobox
      v-model:inputValue="titleComputed"
      label="Title"
      itemValue="value"
      itemLabel="label"
      :options="getTitleOptions()"
      :hasError="!!getError('title')"
      :errorMessage="getError('title')"
      @blur="validateField('title')"
      name="title"
    />

    <MTextfield
      v-model:inputValue="firstNameComputed"
      type="text"
      label="First Name"
      placeholder="Enter First Name"
      :hasError="!!getError('firstName')"
      :errorMessage="getError('firstName')"
      @blur="validateField('firstName')"
      name="firstName"
    />

    <MTextfield
      v-model:inputValue="lastNameComputed"
      type="text"
      label="Last Name"
      placeholder="Enter Last Name"
      :hasError="!!getError('lastName')"
      :errorMessage="getError('lastName')"
      @blur="validateField('lastName')"
      name="lastName"
    />

    <MDatePicker
      label="Date of Birth"
      :startDate="traveler.dateOfBirth"
      :min-date="getMinBirthDate(traveler.passengerType)"
      :max-date="getMaxBirthDate(traveler.passengerType)"
      @onRangeDateChange="changeBirthDate"
      placeholder="Enter Date of Birth"
      dateType="birthDate"
      :multiCalendar="false"
      :hasRange="false"
      :error="getError('dateOfBirth')"
      name="dateOfBirth"
      :autoPosition="false"
      position="bottom"
    />

    <MCombobox
      v-model:inputValue="genderComputed"
      label="Gender"
      itemValue="value"
      itemLabel="label"
      :options="genderOptions"
      :hasError="!!getError('gender')"
      :errorMessage="getError('gender')"
      @blur="validateField('gender')"
      name="gender"
    />

    <MCombobox
      v-model:inputValue="nationalityComputed"
      label="Nationality"
      itemValue="value"
      itemLabel="label"
      :options="countries"
      :hasError="!!getError('nationality')"
      :errorMessage="getError('nationality')"
      @blur="validateField('nationality')"
      name="nationality"
    />

    <template v-if="isInternational">
      <MTextfield
        v-model:inputValue="passportNumberComputed"
        type="text"
        label="Passport Number"
        placeholder="Enter Passport Number"
        :hasError="!!getError('passportNumber')"
        :errorMessage="getError('passportNumber')"
        @blur="validateField('passportNumber')"
        name="passportNumber"
      />

      <MDatePicker
        label="Passport Expiry Date"
        :startDate="traveler.passportExpiry"
        :min-date="minPassportDate"
        :max-date="maxPassportDate"
        @onRangeDateChange="changePassportDate"
        placeholder="Enter Passport Expiry Date"
        dateType="passportExpiry"
        :multiCalendar="false"
        :hasRange="false"
        :error="getError('passportExpiry')"
        name="passportExpiry"
      />
    </template>

    <template v-else>
      <MTextfield
        v-model:inputValue="idNumberComputed"
        type="text"
        label="ID Number"
        placeholder="Enter ID Number"
        :hasError="!!getError('idNumber')"
        :errorMessage="getError('idNumber')"
        @blur="validateField('idNumber')"
        name="idNumber"
      />
    </template>
  </form>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import Nationalities from "@/ag-portal-common/constants/nationalities";
import { MCombobox, MTextfield, MDatePicker } from "@aeroglobe/ag-core-ui";
import { format, subYears, addYears, addMonths } from "date-fns";
import { TravelerTypes } from "@/ag-flight-components/constants/TravelerTypes";
import { ITravelerBookingForm } from "@/ag-flight-components/interfaces/IGroupFlight.interface";
import { titles, genders } from "@/ag-flight-components/constants/bookingForm";
import { FORMAT_DD_MMM_YYYY } from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "NewTravelerCard",
  components: {
    MTextfield,
    MCombobox,
    MDatePicker,
  },
  props: {
    traveler: {
      type: Object as PropType<ITravelerBookingForm>,
      required: true,
    },
    isInternational: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
  },
  emits: ["update:traveler", "validate"],
  setup(props, { emit }) {
    const createComputed = <K extends keyof ITravelerBookingForm>(field: K) =>
      computed({
        get: () => props.traveler[field] as string,
        set: (value: string) => {
          emit("update:traveler", { ...props.traveler, [field]: value });
          emit("validate", field, !!value);
        },
      });

    return {
      titleComputed: createComputed("title"),
      firstNameComputed: createComputed("firstName"),
      lastNameComputed: createComputed("lastName"),
      genderComputed: createComputed("gender"),
      nationalityComputed: createComputed("nationality"),
      passportNumberComputed: createComputed("passportNumber"),
      idNumberComputed: createComputed("idNumber"),
    };
  },
  data() {
    return {
      titles,
      genderOptions: genders,
      countries: Nationalities,
    };
  },
  computed: {
    minPassportDate(): string {
      return format(addMonths(new Date(), 6), FORMAT_DD_MMM_YYYY);
    },
    maxPassportDate(): string {
      return format(addYears(new Date(), 50), FORMAT_DD_MMM_YYYY);
    },
  },
  methods: {
    validateAllFields() {
      const fields: (keyof ITravelerBookingForm)[] = [
        "title",
        "firstName",
        "lastName",
        "dateOfBirth",
        "gender",
        "nationality",
      ];
      if (this.isInternational) {
        fields.push("passportNumber", "passportExpiry");
      } else {
        fields.push("idNumber");
      }

      fields.forEach((field) => this.validateField(field));
    },
    changeBirthDate(date: Date) {
      this.$emit("update:traveler", { ...this.traveler, dateOfBirth: date });
      this.$nextTick(() => {
        this.validateField("dateOfBirth");
      });
    },

    changePassportDate(date: Date) {
      this.$emit("update:traveler", { ...this.traveler, passportExpiry: date });
      this.$nextTick(() => {
        this.validateField("passportExpiry");
      });
    },
    validateField(field: keyof ITravelerBookingForm) {
      let isValid = !!this.traveler[field];

      if (field === "dateOfBirth") {
        isValid = this.isValidDateOfBirth(this.traveler.dateOfBirth as any);
      } else if (field === "passportExpiry") {
        isValid = this.isValidPassportExpiry(
          this.traveler.passportExpiry as any
        );
      } else if (field === "nationality") {
        isValid =
          !!this.traveler[field] &&
          this.countries.some(
            (country) => country.value === this.traveler[field]
          );
      }

      this.$emit("validate", field, isValid);
    },

    isValidDateOfBirth(date: Date): boolean {
      if (!date) return false;
      const birthDate = new Date(date);
      const minDate = new Date(
        this.getMinBirthDate(this.traveler.passengerType)
      );
      const maxDate = new Date(
        this.getMaxBirthDate(this.traveler.passengerType)
      );
      return birthDate >= minDate && birthDate <= maxDate;
    },

    isValidPassportExpiry(date: Date): boolean {
      if (!date) return false;
      const expiryDate = new Date(date);
      const minDate = new Date(this.minPassportDate);
      const maxDate = new Date(this.maxPassportDate);
      return expiryDate >= minDate && expiryDate <= maxDate;
    },
    getError(field: keyof ITravelerBookingForm): string {
      return this.errors[field] || "";
    },
    getTitleOptions() {
      // TODO:
      // return this.titles.filter((x) => x.type === passengerType);
      return this.titles.filter((x) =>
        [TravelerTypes.ADULT, TravelerTypes.INFANT].includes(x.type)
      );
    },
    getMinBirthDate(passengerType: string) {
      const now = new Date();
      switch (passengerType) {
        case TravelerTypes.ADULT:
          return format(subYears(now, 100), FORMAT_DD_MMM_YYYY);
        case TravelerTypes.CHILD:
          return format(subYears(now, 12), FORMAT_DD_MMM_YYYY);
        case TravelerTypes.INFANT:
          return format(subYears(now, 2), FORMAT_DD_MMM_YYYY);
        default:
          return format(now, FORMAT_DD_MMM_YYYY);
      }
    },
    getMaxBirthDate(passengerType: string) {
      const now = new Date();
      switch (passengerType) {
        case TravelerTypes.ADULT:
          return new Date();
        // TODO:
        // return format(subYears(now, 12), FORMAT_DD_MMM_YYYY);
        case TravelerTypes.CHILD:
          return format(subYears(now, 2), FORMAT_DD_MMM_YYYY);
        case TravelerTypes.INFANT:
          return format(now, FORMAT_DD_MMM_YYYY);
        default:
          return format(now, FORMAT_DD_MMM_YYYY);
      }
    },
    scrollToFirstError() {
      const firstErrorField = Object.keys(this.errors)[0];
      if (firstErrorField) {
        const element = this.$el.querySelector(`[name="${firstErrorField}"]`);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
          (element as HTMLElement).focus();
        }
      }
    },
  },
});
</script>

<style scoped lang="scss">
.traveler-card-form {
  width: 420px;
}
@media (max-width: 767px) {
  .traveler-card-form {
    width: 315px;
  }
}
</style>
