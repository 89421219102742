import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a674b7d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flight_count" }
const _hoisted_2 = {
  id: "flight_search_box",
  class: "combine_flight_search combobox-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_MAirportComboBox = _resolveComponent("MAirportComboBox")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_MDatePicker = _resolveComponent("MDatePicker")!

  return (_openBlock(), _createBlock(_component_ag_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_ag_column, {
        xs: "12",
        sm: "12",
        md: "1",
        cols: "12"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, "Flight " + _toDisplayString(_ctx.index + 1), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ag_column, {
        xs: "12",
        sm: "12",
        md: "7",
        cols: "12"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ag_row, { "test-id": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_column, {
                  xs: "12",
                  sm: "12",
                  md: "6",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MAirportComboBox, {
                      inputValue: _ctx.selectedOriginIata,
                      label: "From",
                      itemValue: "value",
                      itemLabel: "label",
                      "label-icon": "m-cancel",
                      options: _ctx.renderOriginPlaces,
                      "prepend-icon": "m-flight-departure-icon",
                      placeholder: "Leave from",
                      "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSelectOrigin(_ctx.index, $event))),
                      "error-message": _ctx.originError,
                      "has-error": !!_ctx.originError,
                      "onUpdate:inputText": _ctx.handleOriginMAirportInputText,
                      "is-loading": _ctx.isOriginInputLoading
                    }, null, 8, ["inputValue", "options", "error-message", "has-error", "onUpdate:inputText", "is-loading"])
                  ]),
                  _: 1
                }),
                _createElementVNode("div", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.swapFields && _ctx.swapFields(...args))),
                  class: _normalizeClass(["m-swap-flights-icon-container m-index-level-one", { flipped: _ctx.swapIconFlipped }]),
                  variant: "link"
                }, [
                  _createVNode(_component_MIcon, { name: "m-swap-flight-icon" })
                ], 2),
                _createVNode(_component_ag_column, {
                  xs: "12",
                  sm: "12",
                  md: "6",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MAirportComboBox, {
                      inputValue: _ctx.selectedDestinationIata,
                      label: "To",
                      itemValue: "value",
                      itemLabel: "label",
                      "label-icon": "m-cancel",
                      options: _ctx.renderDestinationPlaces,
                      "prepend-icon": "m-flight-arrival-icon",
                      placeholder: "Going to",
                      "onUpdate:inputValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSelectDestination(_ctx.index, $event))),
                      "onUpdate:inputText": _ctx.handleDestinationMAirportInputText,
                      "error-message": _ctx.destinationError,
                      "has-error": !!_ctx.destinationError,
                      "is-loading": _ctx.isDestinationInputLoading
                    }, null, 8, ["inputValue", "options", "onUpdate:inputText", "error-message", "has-error", "is-loading"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ag_column, {
        xs: "12",
        sm: "12",
        md: "3",
        cols: "12"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_ag_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ag_column, {
                  xs: "12",
                  sm: "12",
                  md: "12",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MDatePicker, {
                      label: "Departure Date",
                      startDate: _ctx.multicityLeg!.departure_date,
                      "min-date": _ctx.minDate,
                      onOnRangeDateChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeDepartureDate(_ctx.index, $event))),
                      placeholder: "Departuring",
                      dateType: "startDate",
                      multiCalendar: _ctx.isMultiCalendar,
                      onHandleDatePickerFocused: _ctx.handleDepartureDatePickerFocused,
                      isFocused: _ctx.isDepartureDatePickerFocused,
                      autoPosition: false,
                      position: "right"
                    }, null, 8, ["startDate", "min-date", "multiCalendar", "onHandleDatePickerFocused", "isFocused"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ag_column, {
        xs: "12",
        sm: "12",
        md: "1",
        cols: "12",
        class: "multicity-delete-btn"
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_MIcon, {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteFlight(_ctx.index))),
            class: "multicity-delete-row-icon",
            name: "m-outlined-cancel"
          }, null, 512), [
            [_vShow, _ctx.index > 1]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}