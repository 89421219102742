import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { ActionContext, ActionTree } from "vuex";
import { IFDGState } from "@/interfaces/fdgState.interface";
import FDGService from "@/modules/FDG/services/fdg.service";
import { IFDG } from "@/interfaces/fdg.interface";
import { IFDGDetail } from "@/interfaces/fdgDetail.interface";
import { FDGBookingDetailRequestModel } from "@/modules/FDG/models/fdgBookingDetail.request";
import {
  IFDGBookingDetail,
  IFDGBookingPassenger,
} from "@/interfaces/fdgBookingDetail.interface";
import { FDGBookingConfirmRequestModel } from "@/modules/FDG/models/fdgBookingConfirm.request";
import { FDGBookingConfirmWithoutTravelerDetailsRequestModel } from "@/modules/FDG/models/fdgBookingConfirmWithoutTravelerDetails.request";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";
import { StoreState } from "@/store/type";
import { FDGUpdatePassengerRequest } from "../models/fdgUpdatePassenger.request";
import {
  FDGCancelRequest,
  FDGPayNowRequest,
} from "../models/fdgPaynow.request";

const actions: ActionTree<IFDGState, StoreState> = {
  async fetchGroupFlights(
    context: ActionContext<IFDGState, StoreState>,
    tourType: TOUR_TYPES
  ) {
    const methodName = "actions.fetchGroupFlights";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("enableIsFetchingGroupFlights", true);
    const fdgService = new FDGService();
    const response: IFDG[] = await fdgService.fetchGroupFlights(tourType);
    context.commit("saveGroupFlights", response);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
  },

  async fetchGroupFlightDetail(
    context: ActionContext<IFDGState, StoreState>,
    id: string
  ) {
    const methodName = "actions.fetchGroupFlightDetail";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("enableIsFetchingGroupFlightDetail", true);
    const fdgService = new FDGService();
    const response: IFDGDetail | null = await fdgService.fetchGroupFlightDetail(
      id
    );
    context.commit("saveGroupFlightDetail", response);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
  },

  async initiateGroupFlightBooking(
    context: ActionContext<IFDGState, StoreState>,
    {
      payload,
      callback,
    }: {
      payload: FDGBookingDetailRequestModel;
      callback: (bookingId: string) => void;
    }
  ) {
    const methodName = "actions.initiateBooking";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("enableIsInitiatingGroupFlightBooking", true);
    const fdgService = new FDGService();
    const response: IFDGBookingDetail | null = await fdgService.initiateBooking(
      payload,
      callback
    );
    context.commit("disableIsInitiatingGroupFlightBooking", false);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
  },

  async confirmGroupFlightBooking(
    context: ActionContext<IFDGState, StoreState>,
    {
      payload,
      callback,
    }: {
      payload: FDGBookingConfirmRequestModel;
      callback: () => void;
    }
  ) {
    const methodName = "actions.confirmGroupFlightBooking";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("enableIsConfirmGroupFlightBooking");
    const fdgService = new FDGService();
    const response = await fdgService.confirmBooking(payload, callback);
    context.commit("disableIsConfirmGroupFlightBooking");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
  },

  async confirmGroupFlightBookingWithoutTravelerDetails(
    context: ActionContext<IFDGState, StoreState>,
    {
      payload,
      callback,
    }: {
      payload: FDGBookingConfirmWithoutTravelerDetailsRequestModel;
      callback: () => void;
    }
  ) {
    const methodName = "actions.confirmGroupFlightBooking";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("enableIsConfirmGroupFlightBookingWithoutTravelerDetails");
    const fdgService = new FDGService();
    const response =
      await fdgService.confirmGroupFlightBookingWithoutTravelerDetails(
        payload,
        callback
      );
    context.commit("disableIsConfirmGroupFlightBookingWithoutTravelerDetails");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
  },

  async fetchGroupFlightBookingDetail(
    context: ActionContext<IFDGState, StoreState>,
    bookingId: string
  ) {
    const methodName = "actions.fetchGroupFlightBooking";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("enableIsFetchingGroupFlightBookingDetail", true);
    const fdgService = new FDGService();
    const response: IFDGBookingDetail | null =
      await fdgService.fetchGroupFlightBookingDetail(bookingId);
    context.commit("saveGroupFlightBookingDetail", response);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
  },

  async generateGroupFlightBookingOTP(
    context: ActionContext<IFDGState, StoreState>,
    {
      booking_id,
      callback,
    }: {
      booking_id: string;
      callback: () => void;
    }
  ) {
    const methodName = "actions.fetchGroupFlightBooking";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("setOTPVerificationInProgress", true);
    const fdgService = new FDGService();
    const otpReferenceId: string =
      await fdgService.generateGroupFlightBookingOTP(
        booking_id,
        callback,
        context
      );
    context.commit("saveGroupBookingFormOTPReference", otpReferenceId);
    context.commit("setOTPVerificationInProgress", false);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },

  async getGroupBookingPassengers(
    context: ActionContext<IFDGState, StoreState>,
    booking_id: string
  ) {
    const methodName = "actions.getGroupBookingPassengers";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("setGroupBookingPassengersFetching", true);
    const fdgService = new FDGService();
    const response: IFDGBookingPassenger[] =
      await fdgService.getGroupBookingPassengers(booking_id);
    context.commit("setGroupBookingPassengersFetching", false);
    context.commit("saveGroupBookingPassengers", response);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
  async generateGroupFlightCancelOTP(
    context: ActionContext<IFDGState, StoreState>,
    {
      booking_id,
      callback,
    }: {
      booking_id: string;
      callback: () => void;
    }
  ) {
    const methodName = "actions.generateGroupFlightCancelOTP";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("setOTPVerificationInProgress", true);
    const fdgService = new FDGService();
    const otpReferenceId: string =
      await fdgService.generateGroupFlightCancelOTP(
        booking_id,
        callback,
        context
      );
    context.commit("saveGroupBookingFormOTPReference", otpReferenceId);
    context.commit("setOTPVerificationInProgress", false);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
  async updateGroupBookingPassengers(
    context: ActionContext<IFDGState, StoreState>,
    {
      booking_id,
      payload,
      callback,
    }: {
      booking_id: string;
      payload: FDGUpdatePassengerRequest;
      callback: () => void;
    }
  ) {
    const methodName = "actions.updateGroupBookingPassengers";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("setGroupBookingPassengersFetching", true);
    const fdgService = new FDGService();
    await fdgService.updateGroupBookingPassengers(booking_id, payload);
    callback();
    context.commit("setGroupBookingPassengersFetching", false);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },

  async groupFlightsPaynow(
    context: ActionContext<IFDGState, StoreState>,
    {
      payload,
      callback,
    }: {
      payload: FDGPayNowRequest;
      callback: () => void;
    }
  ) {
    const methodName = "actions.groupFlightsPaynow";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("setGroupFlightsPaynowLoading", true);
    const fdgService = new FDGService();
    await fdgService.groupFlightsPayNow(payload, callback);
    context.commit("setGroupFlightsPaynowLoading", false);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
  async groupFlightsCancel(
    context: ActionContext<IFDGState, StoreState>,
    {
      payload,
      callback,
    }: {
      payload: FDGCancelRequest;
      callback: () => void;
    }
  ) {
    const methodName = "actions.groupFlightsCancel";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("setGroupFlightsCancelLoading", true);
    const fdgService = new FDGService();
    await fdgService.groupFlightsCancel(payload, callback);
    context.commit("setGroupFlightsCancelLoading", false);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
};

export default actions;
