<template>
  <MDialog
    v-model="isMdialogOpen"
    @close="closeModal"
    max-width="950"
    :title="renderTitle"
  >
    <template #content>
      <div>
        <v-switch
          test-id=""
          hide-details
          inset
          color="primary"
          v-model="active"
          :value="true"
          :label="renderPackageActiveLabel"
        />

        <AgFile
          test-id=""
          class="ag-file-theme"
          label="Package Image"
          :multiple="true"
          @on:change="onFileChangeHandler"
        />

        <m-textfield
          v-model:inputValue.trim="name"
          label="Package Name"
          :hasError="errors?.name"
          :errorMessage="errors?.name"
        />

        <m-textarea
          v-model:inputValue.trim="description"
          label="Package Description"
          :hasError="errors?.description"
          :errorMessage="errors?.description"
        />

        <div class="date-select-wrapper">
          <MDatePicker
            label="Start Date"
            :start-date="InitializeStartDate"
            :min-date="min_date"
            @onRangeDateChange="changeStartDate"
            placeholder="Start Date"
            dateType="startDate"
            :multiCalendar="false"
            :hasRange="false"
            :error="errors?.start_date"
            class="package-date"
          />
          <MDatePicker
            label="End Date"
            :start-date="InitializeEndDate"
            :min-date="end_date"
            @onRangeDateChange="changeEndDate"
            placeholder="End Date"
            dateType="endDate"
            :multiCalendar="false"
            :hasRange="false"
            :error="errors?.end_date"
            class="package-date"
          />
        </div>

        <m-textfield
          v-model:inputValue.trim="price"
          label="Package Price"
          :hasError="errors?.price"
          :errorMessage="errors?.price"
          type="number"
        />
      </div>
    </template>
    <template #actions>
      <MButton :disabled="isLoading" @click="onSubmit">
        {{ isEdit ? "Save" : "Add" }}
      </MButton>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ValidationError } from "yup";
import { addDays } from "date-fns";

import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { addPackageValidation } from "@/modules/Organization/validations/addPackage.validation";
import {
  ContentUpload,
  UPLOAD_CONTENT_CATEGORY,
  UPLOAD_CONTENT_SUB_CATEGORY,
} from "@/modules/Organization/dtos/organization.dto";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { Organization } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { MDialog } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "AddPackageModal",
  emits: ["closeModal", "onSubmit"],
  data(): {
    active: boolean;
    name: string;
    display_image_id?: string | null;
    description: string;
    start_date: Date | null;
    end_date: Date | null;
    min_date: Date;
    price: string;
    errors: any;
    ELEMENT_TYPES: typeof ELEMENT_TYPES;
    selectedFile: File | null;
    isLoading: boolean;
    isMdialogOpen: boolean;
  } {
    return {
      active: true,
      name: "",
      display_image_id: null,
      description: "",
      start_date: null,
      end_date: null,
      min_date: new Date(),
      price: "",
      errors: {},
      ELEMENT_TYPES,
      selectedFile: null,
      isLoading: false,
      isMdialogOpen: this.isOpen,
    };
  },
  created() {
    this.start_date = this.InitializeStartDate;
    this.end_date = this.InitializeEndDate;
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
  },
  methods: {
    genTestId,
    closeModal() {
      this.$emit("closeModal");
      this.clearState();
    },
    changeStartDate(newDate: Date) {
      this.start_date = newDate;
    },
    changeEndDate(newDate: Date) {
      this.end_date = newDate;
    },
    initializeState() {
      if (this.isEdit) {
        this.active = this.data?.active;
        this.name = this.data?.name;
        this.display_image_id = this.data?.display_image_id;
        this.description = this.data?.description;
        this.start_date = new Date(this.data?.start_date);
        this.end_date = new Date(this.data?.end_date);
        this.price = this.data?.price;
      }
    },
    clearState() {
      this.active = true;
      this.name = "";
      this.display_image_id = null;
      this.description = "";
      this.start_date = null;
      this.end_date = null;
      this.price = "";
      this.selectedFile = null;
    },
    async onSubmit(e: Event) {
      this.isLoading = true;
      this.errors = {};
      e.preventDefault();

      await this.onContentUploadHandler();

      try {
        const payload = await addPackageValidation.validate(
          {
            active: this.active,
            name: this.name,
            display_image_id: this.display_image_id,
            description: this.description,
            start_date: this.start_date,
            end_date: this.end_date,
            price: this.price,
          },
          { abortEarly: false }
        );
        this.$emit("onSubmit", payload);
        this.clearState();
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const _errors = yupValidationErrorAsSchema(ex);
          this.errors = _errors;
        }
      }
      this.isLoading = false;
    },
    onFileChangeHandler(files: FileList) {
      const file = files[0];
      this.selectedFile = file;
    },

    async onContentUploadHandler() {
      if (this.selectedFile && this.organization) {
        const payload: ContentUpload = {
          content: this.selectedFile,
          content_category: UPLOAD_CONTENT_CATEGORY.WEBSITE,
          content_sub_category: UPLOAD_CONTENT_SUB_CATEGORY.PACKAGE,
        };

        const organizationId = this.organization.organization_id;

        const response = await this.$store.dispatch("websiteUploadContent", {
          payload,
          organizationId,
        });

        this.display_image_id = response[0];
      }
    },
  },
  watch: {
    isOpen(val: boolean) {
      this.isMdialogOpen = val;
      if (val) {
        setTimeout(() => {
          this.initializeState();
        }, 0);
      }
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("closeModal");
      }
    },
  },
  computed: {
    InitializeStartDate(): Date {
      return this.data?.start_date
        ? new Date(this.data?.start_date)
        : new Date();
    },
    InitializeEndDate(): Date {
      return this.data?.end_date
        ? new Date(this.data?.end_date)
        : addDays(new Date(), 15);
    },
    renderTitle(): string {
      return this.isEdit ? "Edit Package" : "Add Package";
    },
    renderPackageActiveLabel(): string {
      return `Package is ${this.active ? "Active" : "Disabled"}`;
    },

    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
  },
});
</script>

<style scoped lang="scss">
.packageImageUploader {
  transition: scale 0.2s ease-in-out;

  &:active {
    transform: scale(0.98);
  }
}
.submit-btn {
  float: right;
}
</style>

<style lang="css" scoped>
.date-select-wrapper {
  gap: 0 20px;
  display: flex;
  justify-content: space-between;
}

.package-date {
  min-width: 450px;
}

@media (max-width: 767px) {
  .date-select-wrapper {
    gap: 0 20px;
    display: inline;
  }
  .package-date {
    min-width: 250px;
  }
}
</style>
