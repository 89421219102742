export const ORGANIZATION_ANALYTICS_EVENTS = {
  UPDATE_ORGANIZATION_PREFERENCE: "update-organization-preference",
  UPDATE_WEBSITE_CONFIGURATION: "update-website-configuration",
  // Whatsapp Management Events
  GET_WHATSAPP_MEMBERS: "get-whatsapp-members",
  ADD_WHATSAPP_MEMBERS: "add-whatsapp-member",
  DELETE_WHATSAPP_MEMBERS: "delete-whatsapp-member",
  GENERATE_OTP_WHATAPP_MEMBER: "generate-otp-for-whatsapp-member",
  AUTHORIZE_OTP_WHATAPP_MEMBER: "authorize-otp-for-whatsapp-member",
  // Passenger Management Events
  ADD_NEW_PASSENGER_MANAGEMENT: "add-new-passenger-management",
  UPDATE_PASSENGER_MANAGEMENT: "update-passenger-management",
  DELETE_PASSENGER_MANAGEMENT: "delete-passenger-management",
  FETCH_ORGANIZATION_SNAPSHOT: "fetch-organization-snapshot",
  FETCH_ORGANIZATION_PRODUCTIVITY: "fetch-organization-productivity",
  // Sales my Team
  SALES_MY_TEAM_FETCHED: "sales-my-team-fetched",

  PREFERENCES_UPDATE: "organization-preference-update-clicked",
  PREFERENCES_CANCEL: "organization-preference-cancel-clicked",

  WEBSITE_CONTENT_UPLOAD: "website-content-upload",
  WEBSITE_CONFIG_SAVE: "organization-website-config-save-clicked",
  WEBSITE_CONTENT_SAVE: "organization-website-content-save-clicked",

  WEBSITE_PACKAGE_ADD: "organization-website-package-add-clicked",
  WEBSITE_PACKAGE_UPDATE: "organization-website-package-update-clicked",
  WEBSITE_PACKAGE_DELETE: "organization-website-package-delete-clicked",

  WEBSITE_SERVICE_ADD: "organization-website-service-add-clicked",
  WEBSITE_SERVICE_UPDATE: "organization-website-service-update-clicked",
  WEBSITE_SERVICE_DELETE: "organization-website-service-delete-clicked",

  WEBSITE_BANNER_SAVE: "organization-website-banner-save-clicked",
  WEBSITE_ABOUT_US_SAVE: "organization-website-about-us-save-clicked",
  WEBSITE_BASIC_CONFIGURATION_SAVE:
    "organization-website-basic-configuration-save-clicked",
  WEBSITE_CONTACT_SAVE: "organization-website-contact-save-clicked",
  WEBSITE_SERVICES_SAVE: "organization-website-services-save-clicked",
  WEBSITE_PACKAGES_SAVE: "organization-website-packages-save-clicked",
  WEBSITE_SOCIALS_SAVE: "organization-website-socials-save-clicked",
};

export const CURRENCY_CONVERSION_ANALYTICS_EVENTS = {
  CURRENCY_SELECTED: "currency-selected",
  CURRENCY_FETCHED: "currency-fetched",
};
