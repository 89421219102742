<template>
  <div class="flight_route_card">
    <div class="airline_info">
      <div class="icon_airline_wrap">
        <img v-if="departureAirlineLogo" v-bind:src="departureAirlineLogo" />
      </div>
      <div v-if="departureAirlineName">
        <AgHeading
          v-if="departureAirlineName"
          :title="departureAirlineName"
          variant="h3"
        ></AgHeading>
        <div class="airline_code" v-if="departureAirlineCode">
          {{ departureAirlineCode }}
        </div>
      </div>
    </div>

    <div class="airline_destination">
      <div class="time_info left">
        <div class="time" v-if="departureFromTime">
          {{ parseTimeOrDate(departureFromTime) }}
        </div>
        <div class="destination" v-if="departureOriginSegmentIataCode">
          {{ departureOriginSegmentIataCode }}
        </div>
      </div>

      <div class="time_duration">
        <div class="time" v-if="departureDuration">
          {{ parseDuration(departureDuration) }}
        </div>
        <div class="flight_bar_wrap">
          <AgIcon name="flightBar"></AgIcon>
        </div>
      </div>

      <div class="time_info right">
        <div class="time" v-if="departureToTime">
          {{ parseTimeOrDate(departureToTime) }}
        </div>
        <div class="destination" v-if="departureDestinationSegmentIataCode">
          {{ departureDestinationSegmentIataCode }}
        </div>
      </div>
    </div>
  </div>

  <div class="flight_route_card">
    <div class="airline_info">
      <div class="icon_airline_wrap">
        <img v-if="departureAirlineLogo" v-bind:src="departureAirlineLogo" />
      </div>
      <div v-if="departureAirlineName">
        <AgHeading
          v-if="departureAirlineName"
          :title="departureAirlineName"
          variant="h3"
        ></AgHeading>
        <div class="airline_code" v-if="arrivalAirlineCode">
          {{ arrivalAirlineCode }}
        </div>
      </div>
    </div>

    <div class="airline_destination">
      <div class="time_duration">
        <div class="flight_bar_wrap">
          <AgIcon name="flightBar"></AgIcon>
        </div>
        <div class="time" v-if="arrivalDuration">
          {{ parseDuration(arrivalDuration) }}
        </div>
      </div>

      <div class="time_info left">
        <div class="destination" v-if="arrivalOriginSegmentIataCode">
          {{ arrivalOriginSegmentIataCode }}
        </div>

        <div class="time" v-if="arrivalFromTime">
          {{ parseTimeOrDate(arrivalFromTime) }}
        </div>
      </div>

      <div class="time_info right">
        <div class="destination" v-if="arrivalDestinationSegmentIataCode">
          {{ arrivalDestinationSegmentIataCode }}
        </div>
        <div class="time" v-if="arrivalToTime">
          {{ parseTimeOrDate(arrivalToTime) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";

export default {
  name: "ReturnFlightRouteCard",
  props: {
    departureAirlineName: {
      type: String,
      required: true,
    },
    departureAirlineLogo: {
      type: String,
      required: true,
    },
    departureAirlineCode: {
      type: String,
      required: true,
    },
    departureDuration: {
      type: Number,
      required: true,
    },
    departureFromTime: {
      type: String,
      required: true,
    },
    departureToTime: {
      type: String,
      required: true,
    },
    arrivalAirlineCode: {
      type: String,
      required: true,
    },
    arrivalDuration: {
      type: Number,
      required: true,
    },
    arrivalFromTime: {
      type: String,
      required: true,
    },
    arrivalToTime: {
      type: String,
      required: true,
    },

    departureOriginSegmentIataCode: {
      type: String,
      required: true,
    },
    departureDestinationSegmentIataCode: {
      type: String,
      required: true,
    },
    arrivalOriginSegmentIataCode: {
      type: String,
      required: true,
    },
    arrivalDestinationSegmentIataCode: {
      type: String,
      required: true,
    },
  },
  methods: {
    parseTimeOrDate(date: string | Date): string {
      return TimeUtility.parsedFlightTimeorDate(date);
    },
    parseDuration(duration: number): string {
      return TimeUtility.getDurationInTextByMinutes(Number(duration));
    },
  },
};
</script>

<style scoped lang="scss">
.flight_route_card {
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  align-items: center;
  .airline_info {
    display: flex;
    margin-right: 40px;
    h3 {
      font-size: 16px;
      color: #111827;
      font-weight: 600;
      margin-bottom: 0;
    }

    .icon_airline_wrap {
      margin-right: 16px;
      img {
        width: auto;
        height: 27px;
      }
    }

    .airline_code {
      color: #6b7280;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
    }
    @media (max-width: 1080px) {
      margin-right: 0px;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #dedede;
    }
  }

  .airline_destination {
    position: relative;
    position: relative;
    padding: 0px 90px;

    .time_duration {
      font-weight: 600;
      font-size: 16px;
      color: #111827;
      padding: 0px 45px;
      text-align: center;

      .flight_bar_wrap {
        width: 100%;
        margin-top: 10px;
      }
      @media (max-width: 1080px) {
        padding: 0px;
      }
    }

    .time_info {
      position: absolute;
      top: 0;
      .time {
        font-weight: 600;
        font-size: 16px;
        color: #111827;
      }

      .destination {
        color: #6b7280;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
      }

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }
    }
  }

  @media (max-width: 1080px) {
    display: block;
    padding-top: 15px;

    &:nth-child(even) {
      border-top: 1px solid #dedede;
    }
  }
}
</style>
