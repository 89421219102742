import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5083cee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_3 = { class: "dashboard" }
const _hoisted_4 = { class: "selectable-container" }
const _hoisted_5 = { class: "selectable-container" }
const _hoisted_6 = { class: "status-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MProgress = _resolveComponent("MProgress")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_DashboardCard = _resolveComponent("DashboardCard")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_ag_radio_item = _resolveComponent("ag-radio-item")!
  const _component_ag_radio = _resolveComponent("ag-radio")!
  const _component_MFinancialProfileCombobox = _resolveComponent("MFinancialProfileCombobox")!
  const _component_MTextfield = _resolveComponent("MTextfield")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_OrganizationDetailPage = _resolveComponent("OrganizationDetailPage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_MProgress, {
          key: 0,
          indeterminate: "",
          class: "mprogress-alignment"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_MCard, { class: "organizations-heading" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_AgIconInfoBar, {
                  "test-id": "",
                  title: "Organizations",
                  class: "section-title",
                  mPrepandIcon: "m-organisations"
                }),
                _createElementVNode("div", null, [
                  _withDirectives(_createVNode(_component_MButton, {
                    onClick: _ctx.onClickCreateNew,
                    type: "filled"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Create new organization ")
                    ]),
                    _: 1
                  }, 8, ["onClick"]), [
                    [_vShow, _ctx.isUserPermitted]
                  ])
                ])
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_DashboardCard, {
              heading: "Total Organizations",
              number: _ctx.getOrganizationCount,
              icon: "m-number"
            }, null, 8, ["number"]),
            _createVNode(_component_DashboardCard, {
              heading: "Active Organizations",
              number: _ctx.getActiveOrganizationCount,
              icon: "m-success"
            }, null, 8, ["number"]),
            _createVNode(_component_DashboardCard, {
              heading: "InActive Organizations",
              number: _ctx.getInActiveOrganizationCount,
              icon: "m-error"
            }, null, 8, ["number"]),
            _createVNode(_component_DashboardCard, {
              heading: "Linked Financial Profiles",
              number: _ctx.getActiveFinancialProfileOrganizationCount,
              icon: "m-profile"
            }, null, 8, ["number"]),
            _createVNode(_component_DashboardCard, {
              heading: "Unlinked Financial Profiles",
              number: _ctx.getPendingFinancialProfileOrganizationCount,
              icon: "m-pending"
            }, null, 8, ["number"])
          ]),
          _createVNode(_component_MCard, { class: "information-access-card" }, {
            default: _withCtx(() => [
              _createVNode(_component_MTypography, { type: "headline" }, {
                default: _withCtx(() => [
                  _createTextVNode("Access the information via ")
                ]),
                _: 1
              }),
              _createVNode(_component_ag_radio, {
                modelValue: _ctx.selectBy,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectBy) = $event)),
                inline: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_radio_item, {
                    name: "selectBy",
                    value: "Organization",
                    label: "Organization"
                  }),
                  _createVNode(_component_ag_radio_item, {
                    name: "selectBy",
                    value: "Financial Profile",
                    label: "Financial Profile"
                  }),
                  _createVNode(_component_ag_radio_item, {
                    name: "selectBy",
                    value: "Email",
                    label: "Email"
                  })
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _withDirectives(_createElementVNode("div", _hoisted_4, [
                _createVNode(_component_MFinancialProfileCombobox, {
                  class: "fp-combo",
                  inputValue: _ctx.financialProfileInput,
                  "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.financialProfileInput) = $event)),
                  label: "Financial Profiles",
                  itemValue: "value",
                  itemLabel: "label",
                  options: _ctx.financialProfileOptions
                }, null, 8, ["inputValue", "options"]),
                _withDirectives(_createVNode(_component_MButton, {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleGetPriceDetail())),
                  class: "details-button",
                  disabled: _ctx.getPriceBtnDisabled
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Get Details")
                  ]),
                  _: 1
                }, 8, ["disabled"]), [
                  [_vShow, _ctx.isSelectByEmail || _ctx.isSelectByFinancialProfile]
                ])
              ], 512), [
                [_vShow, _ctx.isSelectByFinancialProfile]
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_5, [
                _createVNode(_component_MTextfield, {
                  type: "email",
                  class: "fp-combo",
                  inputValue: _ctx.emailInput,
                  "onUpdate:inputValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.emailInput) = $event)),
                  label: "Search Email"
                }, null, 8, ["inputValue"]),
                _withDirectives(_createVNode(_component_MButton, {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleGetPriceDetail())),
                  class: "details-button",
                  disabled: _ctx.getPriceBtnDisabled
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Get Details")
                  ]),
                  _: 1
                }, 8, ["disabled"]), [
                  [_vShow, _ctx.isSelectByEmail || _ctx.isSelectByFinancialProfile]
                ])
              ], 512), [
                [_vShow, _ctx.isSelectByEmail]
              ])
            ]),
            _: 1
          }),
          _withDirectives(_createVNode(_component_MCard, null, {
            default: _withCtx(() => [
              _createVNode(_component_MDataTable, {
                headers: _ctx.headers,
                "is-loading": _ctx.isLoading,
                data: _ctx.transformTableData,
                "items-per-page": 30,
                "has-search": true,
                simpleDataTable: true
              }, {
                public_id: _withCtx(({ item }) => [
                  _createVNode(_component_router_link, {
                    to: `${_ctx.PATH.ORGANIZATION}/${item.id}`
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_MTypography, {
                        class: "description td-full-width organization-id",
                        type: "body"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.public_id), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                status: _withCtx(({ item }) => [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_MChip, {
                      class: "pricing-chip",
                      variant: _ctx.organizationStatusClass(item.status)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.status), 1)
                      ]),
                      _: 2
                    }, 1032, ["variant"])
                  ])
                ]),
                action: _withCtx(({ item }) => [
                  _createVNode(_component_MButton, {
                    type: "filled",
                    onClick: ($event: any) => (_ctx.onClickUpdate($event, item.id)),
                    disabled: !_ctx.isUserPermitted
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.action), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick", "disabled"])
                ]),
                _: 1
              }, 8, ["headers", "is-loading", "data"])
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.isSelectByOrganization]
          ])
        ])),
    (
      _ctx.showOrganizationDetails && (_ctx.isSelectByEmail || _ctx.isSelectByFinancialProfile)
    )
      ? (_openBlock(), _createBlock(_component_OrganizationDetailPage, {
          key: 2,
          searchedEmail: _ctx.emailInput,
          onFetchOrganizationDetails: _ctx.handleFetchOrganizationDetails,
          "is-management-page": true
        }, null, 8, ["searchedEmail", "onFetchOrganizationDetails"]))
      : _createCommentVNode("", true)
  ], 64))
}