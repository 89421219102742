<template>
  <div class="login-page-view">
    <MCard class="login-card">
      <form @submit.prevent="onSubmitLoginFormHandler" class="login-form">
        <img
          :src="getOrgLogoHandler()"
          alt="organisation-logo"
          class="org-logo"
        />

        <MTypography type="title">
          Welcome to {{ getOrganisationNameHandler() }}
        </MTypography>

        <MTypography> Sign in to your account to continue... </MTypography>

        <MTextfield
          v-model:input-value="loginInformation.email.value"
          label="Email"
          placeholder="Enter your email"
          autocomplete="email"
          :has-error="loginInformation.email.hasError"
          :error-message="loginInformation.email.errorMessage"
          :disabled="isAuthenticating"
        />

        <MTextfield
          v-model:input-value="loginInformation.password.value"
          type="password"
          label="Password"
          placeholder="Enter your password"
          autocomplete="password"
          :has-error="loginInformation.password.hasError"
          :error-message="loginInformation.password.errorMessage"
          :disabled="isAuthenticating"
        />

        <MButton
          type="text"
          class="btn-link"
          :disabled="isAuthenticating"
          @click="onNavigateToForgotPasswordViewHandler"
        >
          Forgot Password
        </MButton>

        <MButton
          behaviour="submit"
          class="btn-submit"
          :disabled="infoHasErrors() || isAuthenticating"
        >
          Login
        </MButton>

        <MTypography class="footer-caption" type="label">
          <span>
            Don't have an account ?
            <span>
              <RouterLink class="footer-caption-link" to="/signup">
                Sign Up
              </RouterLink>
            </span>
          </span>
        </MTypography>
      </form>
    </MCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MCard, MTypography, MTextfield, MButton } from "@aeroglobe/ag-core-ui";
import { AUTH_ACTIONS } from "../vuex/actions";
import { AUTH_GETTERS } from "../vuex/getters";
import { Organization, User } from "../types";
import { AUTH_ANALYTICS_EVENTS } from "../constants/analyticsEvents";
import analyticServices from "@/analytic.service";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "LoginView",
  components: { MCard, MTypography, MTextfield, MButton, RouterLink },
  created() {
    this.onPageTitleUpdateHandler();
  },
  mounted() {
    const htmlElement = document.querySelector("html");

    if (htmlElement) {
      htmlElement.style.overflow = "auto";
    }
  },
  unmounted() {
    const htmlElement = document.querySelector("html");

    if (htmlElement) {
      htmlElement.style.overflow = "hidden";
    }

    this.handleDataReset();
  },
  data() {
    return {
      loginInformation: {
        email: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        password: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      },

      validateLoginInfoErrors: false,
    };
  },
  computed: {
    user(): User | null {
      return this.$store.getters[AUTH_GETTERS.USER];
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    isAuthenticating(): boolean {
      return this.$store.getters[AUTH_GETTERS.IS_AUTHENTICATING];
    },
  },
  methods: {
    infoHasErrors() {
      const transformedArray = Object.keys(this.loginInformation).map(
        (key) => (this.loginInformation as any)[key]
      );

      this.handleLoginInfoErrors();

      return transformedArray.some((item) => item.hasError);
    },
    handleLoginInfoErrors() {
      if (this.validateLoginInfoErrors) {
        const { email, password } = this.loginInformation;
        const emailRegex = new RegExp(
          "[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?"
        );

        if (email.value === "") {
          email.hasError = true;
          email.errorMessage = "Email is required";
        } else if (!emailRegex.test(email.value)) {
          email.hasError = true;
          email.errorMessage = "Email is not valid";
        } else {
          email.hasError = false;
          email.errorMessage = "";
        }

        if (password.value === "") {
          password.hasError = true;
          password.errorMessage = "Password is required";
        } else {
          password.hasError = false;
          password.errorMessage = "";
        }
      }
    },
    handleDataReset() {
      this.loginInformation = {
        email: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        password: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      };

      this.validateLoginInfoErrors = false;
    },
    async onSubmitLoginFormHandler() {
      this.validateLoginInfoErrors = true;

      this.handleLoginInfoErrors();

      if (this.infoHasErrors()) {
        return;
      }

      const payload = {
        email: this.loginInformation.email.value,
        password: this.loginInformation.password.value,
      };

      await this.$store.dispatch(AUTH_ACTIONS.LOGIN, payload);

      analyticServices.logActionEvent(AUTH_ANALYTICS_EVENTS.LOGIN, payload);

      this.handleLoginInfoErrors();
    },

    onPageTitleUpdateHandler() {
      const organisationName = this.getOrganisationNameHandler();
      document.title = `Login - ${organisationName}`;
    },
    getOrgLogoHandler(): string {
      const DEFAULT_LOGO = "../../assets/logo.svg";
      const organization = this.organization;
      const org_logo = organization ? organization.org_logo : null;

      return org_logo ?? DEFAULT_LOGO;
    },
    getOrganisationNameHandler(): string {
      const DEFAULT_NAME = "Aeroglobe";
      const organization = this.organization;

      return organization ? organization.org_name_to_display : DEFAULT_NAME;
    },
    onNavigateToForgotPasswordViewHandler() {
      this.$router.push("/forgot-password");
    },
  },
});
</script>

<style lang="css">
.login-page-view {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #000 !important;
  }
}
</style>
<style lang="css" scoped>
.login-page-view {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  padding: 20px;

  background-color: color-mix(in srgb, var(--m-primary-color), transparent 90%);

  display: flex;
  align-items: center;
  justify-content: center;

  .login-card {
    width: 100%;
    max-width: 450px;

    padding: 30px;
    border-radius: calc(30px);

    box-shadow: 0 0 30px 0 var(--m-divider-color);
  }

  .login-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .org-logo {
      max-width: 200px;
      margin: 20px auto;
      user-select: none;
      pointer-events: none;
    }

    .m-textfield {
      margin-bottom: 0;
    }

    .btn-link {
      padding-left: 0;
      padding-right: 0;
      align-self: flex-end;
    }

    .btn-submit {
      width: 100%;
    }

    .footer-caption {
      width: 100%;
      text-align: center;
    }

    .footer-caption-link {
      color: var(--green-color);
    }
  }
}
</style>
