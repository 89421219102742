import { Module } from "vuex";
import { ISectorState } from "@/ag-portal-common/interfaces/sectorState.interface";

import mutations from "./mutations";
import actions from "./actions";
import state from "./state";
import getters from "./getters";
import { StoreState } from "@/store/type";

const sectorModule: Module<ISectorState, StoreState> = {
  state,
  mutations,
  actions,
  getters,
};

export default sectorModule;
