import { RouteRecordRaw } from "vue-router";
import Error403 from "../views/Error403.vue";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "403",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.Error403.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.Error403.PERMISSIONS,
    },
    component: Error403,
  },
];

export default routes;
