<template>
  <MProgress
    class="mprogress-alignment"
    v-if="$store.getters.isCurrenciesLoading"
    indeterminate
  />
  <template v-else>
    <MCard class="m-card-currency-conversion">
      <AgIconInfoBar
        test-id=""
        title="Currency Conversion"
        class="section-title"
        mPrepandIcon="m-currency-conversion"
      />
    </MCard>
    <MCard class="m-card-currency-conversion">
      <ag-row>
        <ag-column xs="12" sm="12" md="6" lg="5">
          <ag-row>
            <MTypography>Convert PKR amount into other currencies</MTypography>
            <ag-column xs="12" sm="12" md="6" lg="8">
              <div>
                <MTextfield
                  type="number"
                  v-model:inputValue="pkrAmount"
                  label="PKR Amount"
                />

                <p v-show="convertPkrToCurrency()" class="converted-amount">
                  {{ convertPkrToCurrency() }}
                </p>
              </div>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="4">
              <MCombobox
                v-model:inputValue="selectedCurrency1"
                label="Currency"
                itemValue="value"
                itemLabel="label"
                :options="currencyItemsPKRToCurrencies"
                errorMessage="Required"
                name="Currency"
              />
            </ag-column>
          </ag-row>
        </ag-column>
        <ag-column xs="0" sm="0" md="0" lg="2"> </ag-column>
        <ag-column xs="12" sm="12" md="6" lg="5">
          <ag-row>
            <MTypography> Convert other currencies amount into PKR</MTypography>
            <ag-column xs="12" sm="12" md="6" lg="8">
              <div>
                <MTextfield
                  type="number"
                  v-model:inputValue="currencyAmount"
                  label="Currency Amount"
                />

                <p v-show="convertCurrencyToPkr()" class="converted-amount">
                  {{ convertCurrencyToPkr() }}
                </p>
              </div>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="4">
              <MCombobox
                v-model:inputValue="selectedCurrency2"
                label="Currency"
                itemValue="value"
                itemLabel="label"
                :options="currencyItems"
                errorMessage="Required"
                name="Currency"
              />
            </ag-column>
          </ag-row>
        </ag-column>
      </ag-row>
    </MCard>

    <MCard class="m-card-currency-conversion">
      <AgIconInfoBar
        test-id=""
        title="Currency Rates"
        class="section-title"
        mPrepandIcon="m-ag-pricing"
      />
      <MDataTable
        :headers="headers"
        :data="currencies"
        :total-item-count="currencies.length"
        :has-search="false"
      >
      </MDataTable>
    </MCard>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { CurrencyConversion } from "@/ag-portal-common/types/index";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";
import analyticsService from "@/analytic.service";
import { CURRENCY_CONVERSION_ANALYTICS_EVENTS } from "@/modules/Organization/constants/analyticsEvents";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { MCard, MDataTable, MTypography } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "CurrencyConversion",
  components: { MDataTable },
  data(): {
    headers: MDataTableHeader[];
    pkrAmount: number | null;
    currencyAmount: number | null;
    convertedAmount1: number | null;
    convertedAmount2: number | null;
    selectedCurrency1: CurrencyConversion | null;
    selectedCurrency2: CurrencyConversion | null;
  } {
    return {
      headers: [
        {
          title: "Currency Name",
          value: "currency_name",
          key: "currency_name",
          sortable: true,
        },
        {
          title: "Code",
          value: "currency_code",
          key: "currency_code",
          sortable: true,
        },
        {
          title: "Rate",
          value: "conversion_rate",
          key: "conversion_rate",
          sortable: true,
        },
      ],
      pkrAmount: null,
      currencyAmount: null,
      convertedAmount1: null,
      convertedAmount2: null,
      selectedCurrency1: null,
      selectedCurrency2: null,
    };
  },
  methods: {
    formatCurrency(amount: number, currency: string) {
      const formattedEarning = getCurrencyFormatter(currency).format(amount);
      return formattedEarning;
    },
    convertPkrToCurrency() {
      const selectedCurrency = this.selectedCurrency1;
      analyticsService.logActionEvent(
        CURRENCY_CONVERSION_ANALYTICS_EVENTS.CURRENCY_SELECTED,
        {
          BASE: "PKR",
          CONVERTED_INTO: selectedCurrency?.currency_code,
        }
      );
      if (selectedCurrency) {
        const calculatedAmount =
          Number(this.pkrAmount) / Number(selectedCurrency.conversion_rate);
        const formatedAmount = this.formatCurrency(
          calculatedAmount,
          selectedCurrency.currency_code
        );
        const baseFormatedAmount = this.formatCurrency(
          Number(this.pkrAmount),
          "PKR"
        );

        return calculatedAmount
          ? `${baseFormatedAmount} = ${formatedAmount}`
          : "";
      }
    },
    convertCurrencyToPkr() {
      const selectedCurrency = this.selectedCurrency2;
      analyticsService.logActionEvent(
        CURRENCY_CONVERSION_ANALYTICS_EVENTS.CURRENCY_SELECTED,
        {
          BASE: selectedCurrency?.currency_code,
          CONVERTED_INTO: "PKR",
        }
      );
      if (selectedCurrency) {
        const calculatedAmount =
          (Number(this.currencyAmount) ?? 0) *
          Number(selectedCurrency.conversion_rate);
        const formatedAmount = this.formatCurrency(calculatedAmount, "PKR");
        const baseFormatedAmount = this.formatCurrency(
          Number(this.currencyAmount) ?? 0,
          selectedCurrency.currency_code
        );

        return calculatedAmount
          ? `${baseFormatedAmount} = ${formatedAmount}`
          : "";
      }
    },
  },
  computed: {
    currencyItems(): IObject[] {
      const localItems = this.$store.getters.currencies;
      return localItems
        .filter((a: CurrencyConversion) => a.currency_code !== "PKR")
        .map((x: CurrencyConversion) => {
          return {
            label: x.currency_code,
            value: x,
          };
        });
    },
    currencyItemsPKRToCurrencies(): IObject[] {
      const localItems = this.$store.getters.currencies;
      return localItems
        .filter((a: CurrencyConversion) => a.currency_code !== "PKR")
        .map((x: CurrencyConversion) => {
          return {
            label: x.currency_code,
            value: x,
          };
        });
    },
    currencies(): CurrencyConversion[] {
      return this.$store.getters.currencies?.map((x: CurrencyConversion) => {
        return {
          ...x,
          currency_name: x.currency_name.replace("_", " "),
        };
      });
    },
  },
  mounted() {
    this.$store.dispatch("getCurrencies");
  },
});
</script>

<style lang="scss">
.passenger-action-btn-container {
  display: flex;
  justify-content: space-between;
  width: 150px;
}

.header-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.passenger-delete-icon {
  svg {
    stroke: #d21414;
  }
}

.converted-amount {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  background: #0c4118;
  color: #fff;
  padding: 7px;
}
.converter-label {
  margin-left: 15px;
  color: #000;
  font-weight: bold;
}
</style>

<style lang="css" scoped>
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
.m-card-currency-conversion {
  margin-bottom: 10px;
}
</style>
