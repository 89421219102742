export enum BOOKING_STATUS {
  INITIATED = "Initiated",
  FAILED = "Failed",
  ISSUANCE_PROGRESS = "Issuance_progress",
  ISSUED = "Issued",
  CONFIRMED = "Confirmed",
  CANCELED = "Canceled",
  VOIDED = "Voided",
  ISSUED_BUT_NOT_PAID = "Issued_but_not_paid",
  WAITLIST = "waitlist",
}
