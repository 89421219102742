<template>
  <MCard
    :class="[
      'sf-flight-card-mq',
      { active: isSideSheetOpen, selected, printing: isPrinting },
    ]"
  >
    <div class="sf-details">
      <img
        class="sf-flight-image"
        :src="flight.airline.logo"
        alt="flight-image"
        width="24"
        height="24"
      />

      <div class="sf-column sf-header">
        <div class="sf-airline-title">
          {{ flight.airline.name }}
        </div>
        <div class="sf-airline-number">
          {{ flight.flight_numbers[0] }}
        </div>
        <div class="sf-date">
          {{ getDepartureTime(flight.departure_time) }}
        </div>
      </div>

      <div class="flight-details">
        <FlightRoute :details="routeDetails" />
      </div>

      <div class="sf-actions">
        <div class="sf-info-action">
          <template v-if="!isPrinting">
            <MIcon
              class="sf-icon sf-delete"
              title="Delete Option"
              name="m-delete"
              @click="onDeleteOptionHandler"
            />
            <MDivider type="vertical" />

            <MIcon
              class="sf-mobile-action sf-icon primary"
              name="m-sidebar"
              width="18"
              height="18"
              @click="onViewDetailsHandler"
            />

            <MDivider class="sf-mobile-action" type="vertical" />

            <MCheckbox
              v-model="selected"
              @update:model-value="onSelectedFlightHandler"
              class="sf-icon"
              title="Select Option"
              name="m-post-add"
            />
          </template>
        </div>

        <div
          class="sf-details-action"
          v-if="!isPrinting"
          @click="onViewDetailsHandler"
        >
          <div class="sfd-title">View Details</div>
          <MIcon class="sf-icon primary" name="m-sidebar" />
        </div>
      </div>
    </div>

    <SingleFareOptions
      :fare-options="flight.fare_options"
      :temper-fare="temperFare"
      :is-printing="isPrinting"
      :is-compact-view="isCompactView"
    />

    <MSideSheet v-model="isSideSheetOpen" title="Flight Details">
      <div class="sfs-flight-details">
        <div class="sfs-flight-info">
          <div class="sfs-flight-info-title">
            ({{ routeDetails.originCode }} - {{ routeDetails.destinationCode }})
            - {{ routeDetails.originTime }} -
            {{ routeDetails.destinationTime }} ({{ routeDetails.duration }})
          </div>

          <div class="sfs-flight-info-subtitle">
            <img
              class="sfs-flight-info-row-image"
              :src="flight.airline.logo"
              alt="flight-image"
              width="18"
              height="18"
            />
            <div>
              {{ flight.airline.name }} • {{ flight.flight_numbers.join(", ") }}
            </div>
          </div>
        </div>

        <FlightSegments :segments="flight.fare_options[0].segments" />
      </div>
    </MSideSheet>
  </MCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import {
  MCard,
  MIcon,
  MDivider,
  MSideSheet,
  MCheckbox,
} from "@aeroglobe/ag-core-ui";

import FlightRoute from "./FlightRoute.vue";
import SingleFareOptions from "./SingleFareOptions.vue";
import FlightSegments from "./FlightSegments.vue";

import {
  ListViewType,
  RouteDetails,
  FlightRulesResponse,
  TemperFare,
} from "../../FlightSearchResult/types";
import { FlightOption } from "@/ag-flight-components/types";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";
import {
  MDataTableHeader,
  MSegment,
} from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";

export default defineComponent({
  name: "SingleFlightCard",
  components: {
    MCard,
    MIcon,
    MDivider,
    MSideSheet,
    MCheckbox,

    FlightRoute,
    SingleFareOptions,
    FlightSegments,
  },
  props: {
    flight: {
      type: Object as PropType<FlightOption>,
      required: true,
    },
    flightsFareRules: {
      type: Object as PropType<Map<string, FlightRulesResponse>>,
    },
    temperFare: {
      type: Object as PropType<TemperFare>,
      required: true,
    },
    isPrinting: {
      type: Boolean,
      default: false,
    },
    isCompactView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["delete:option"],
  computed: {
    routeDetails(): RouteDetails {
      const segments = this.flight.fare_options[0].segments;
      const duration = this.getDuration(this.flight.duration);

      const originSegment = segments[0];
      const destinationSegment = segments[segments.length - 1];

      return {
        originCode: originSegment.origin.iata_code,
        originTime: this.getFormattedTime(
          `${originSegment.departure_datetime}`
        ),

        destinationCode: destinationSegment.destination.iata_code,
        destinationTime: this.getFormattedTime(
          `${destinationSegment.arrival_datetime}`
        ),

        duration,

        stops: segments.map((segment) => segment.destination.iata_code),
      };
    },
  },
  data() {
    return {
      ListViewType,
      isSideSheetOpen: false,
      flightDetailsSegments: [
        {
          label: "Flight Info",
          checked: true,
        },
        {
          label: "Cancellation",
          checked: false,
        },
        {
          label: "Date Change",
          checked: false,
        },
        {
          label: "Baggage",
          checked: false,
        },
      ] as MSegment[],

      tableHeaders: [
        {
          title: "Name",
          value: "rule",
          key: "rule",
          sortable: false,
          contentAlign: "left",
        },
        {
          title: "Fee",
          value: "value",
          key: "value",
          sortable: false,
          contentAlign: "left",
        },
      ] as MDataTableHeader[],

      selected: false,
    };
  },
  methods: {
    getDepartureTime(departureTime: string): string {
      return TimeUtility.parsedFlightTimeorDate(
        departureTime,
        "EE, MMM dd, yyyy"
      );
    },
    getFormattedTime(time: string): string {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    getDuration(duration: number): string {
      return TimeUtility.getDurationInTextByMinutes(duration);
    },
    getRbdNameHandler(name: string): string {
      return name.length === 1 ? `RDB: ${name}` : name;
    },
    onViewDetailsHandler(): void {
      this.isSideSheetOpen = !this.isSideSheetOpen;
    },
    onDeleteOptionHandler(): void {
      this.$emit("delete:option");
    },
    onSelectedFlightHandler(selected: boolean) {
      const flightOption = this.flight;

      if (flightOption) {
        flightOption.selected = selected;
      }
    },
  },
});
</script>

<style scoped lang="css">
@import url("../styles/single-flight-card.css");
</style>
