import { firebaseAnalytics } from "@/ag-portal-common/firebase";
import mixpanel from "mixpanel-browser";
import * as firebase from "firebase/analytics";
import { User } from "@/modules/Auth/types";

type EVENT_DATA = {
  [key: string]: string | number;
};

class AnalyticsService {
  private shouldLogEvents: boolean;
  private analyticPrefix: string;

  constructor(prefix: string) {
    const mixpanel_key = process.env.VUE_APP_MIXPANEL_KEY as undefined | string;
    const envAppEventLoggingEnabled = process.env
      .VUE_APP_EVENT_LOGGING_ENABLED as undefined | string;

    this.analyticPrefix = prefix;
    this.shouldLogEvents = envAppEventLoggingEnabled == "true";

    if (mixpanel_key) {
      mixpanel.init(mixpanel_key);
    }
  }

  setUser(user: User) {
    firebase.setUserId(firebaseAnalytics, user.email);

    mixpanel.identify(user.email);

    mixpanel.people.set({
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      fullName: `${user.first_name} ${user.last_name}`,
      phoneNumber: user.phone_number,
      roleUniqueId: user.role_unique_id,
      roleSubType: user.role_sub_type,
    });
  }

  private logEvent(eventName: string, data: EVENT_DATA = {}) {
    data = {
      ...data,
    };

    eventName = this.analyticPrefix + eventName;

    if (this.shouldLogEvents) {
      firebase.logEvent(firebaseAnalytics, eventName, data);
      mixpanel.track(eventName, data);
    }
  }

  logPageViewEvent(screen_name: string, page_name: string, data?: EVENT_DATA) {
    this.logEvent(page_name, {
      ...(data || {}),
      screen_name,
    });
  }

  logActionEvent = (event_name: string, data?: any) => {
    this.logEvent(event_name, data);
  };
}

export default AnalyticsService;
