import { ROUTE_TYPE } from "../enums/route_type";
import { CABIN_CLASS } from "../enums/cabin_class";

export const CabinClassOptions = [
  {
    label: "Economy",
    value: CABIN_CLASS.ECONOMY,
  },
  {
    label: "Business",
    value: CABIN_CLASS.BUSINESS,
  },
  {
    label: "First Class",
    value: CABIN_CLASS.FIRST_CLASS,
  },
  {
    label: "Premium Economy",
    value: CABIN_CLASS.PREMIUM_ECONOMY,
  },
];

export const RouteTypeOptions = [
  {
    label: "One-Way",
    value: ROUTE_TYPE.ONEWAY,
  },
  {
    label: "Round-Trip",
    value: ROUTE_TYPE.RETURN,
  },
  {
    label: "Multi-City",
    value: ROUTE_TYPE.MULTI_CITY,
  },
];
