import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import {
  FLIGHT_API_PATH,
  FLIGHT_RULES_PATH,
  FLIGHT_BOOKING_INITIATE_PATH,
  FLIGHT_ACTIVE_PROVIDERS_AND_AIRLINES,
} from "../constants/apiEndPoints";
import {
  FetchFlightsPayload,
  FlightRuleParams,
  FlightPreBookingTokenType,
} from "../types/Flights";

class FlightService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public post(params: FetchFlightsPayload): Promise<IAGResponse> {
    return this.restClient.post(FLIGHT_API_PATH.POST, params);
  }

  public getFlightRules(params: FlightRuleParams): Promise<IAGResponse> {
    return this.restClient.post(FLIGHT_RULES_PATH.POST, params);
  }

  public initiateBooking(
    params: FlightPreBookingTokenType
  ): Promise<IAGResponse> {
    return this.restClient.post(FLIGHT_BOOKING_INITIATE_PATH.POST, params);
  }

  public getActiveAirlinesAndAirlines(
    id: string,
    isFinancialProfile = false
  ): Promise<IAGResponse> {
    const label = isFinancialProfile ? "financial_profile" : "organization";

    return this.restClient.get(
      `${FLIGHT_ACTIVE_PROVIDERS_AND_AIRLINES.GET}?${label}=${id}`
    );
  }
}

export default FlightService;
