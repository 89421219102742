<template>
  {{ formattedAmount }}
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { getCurrencyFormatter } from "@/ag-flight-components/utils";

interface AnimatedCounterData {
  displayAmount: number;
  formatter: Intl.NumberFormat | null;
  animationId: number | null;
  isReady: boolean;
}

export default defineComponent({
  name: "AnimatedCurrencyCounter",
  props: {
    targetAmount: {
      type: Number,
      required: true,
    },
    duration: {
      type: Number,
      default: 400,
    },
    currency: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data(): AnimatedCounterData {
    return {
      displayAmount: 0,
      formatter: null,
      animationId: null,
      isReady: false,
    };
  },
  computed: {
    formattedAmount(): string {
      if (!this.formatter) return "";
      const formattedNumber = this.formatter.format(
        Math.trunc(this.displayAmount)
      );
      // Remove the currency symbol and trim any leading space
      const numberWithoutCurrency = formattedNumber
        .replace(/^[^\d]+/, "")
        .trim();
      return `${this.currency} ${numberWithoutCurrency}`;
    },
  },
  watch: {
    targetAmount: {
      handler(newValue: number): void {
        this.checkAndAnimate();
      },
      immediate: true,
    },
    currency: {
      handler(newValue: string): void {
        this.formatter = getCurrencyFormatter(newValue);
        this.checkAndAnimate();
      },
      immediate: true,
    },
  },
  methods: {
    checkAndAnimate(): void {
      if (this.targetAmount !== undefined && this.currency && this.formatter) {
        if (!this.isReady) {
          this.isReady = true;
          this.animateValue(0, Math.trunc(this.targetAmount));
        } else {
          this.cancelAnimation();
          this.animateValue(
            Math.trunc(this.displayAmount),
            Math.trunc(this.targetAmount)
          );
        }
      }
    },
    animateValue(start: number, end: number): void {
      const startTime = Date.now();
      const updateAmount = (): void => {
        const currentTime = Date.now();
        const progress = Math.min((currentTime - startTime) / this.duration, 1);
        this.displayAmount = Math.trunc(start + (end - start) * progress);
        if (progress < 1) {
          this.animationId = requestAnimationFrame(updateAmount);
        } else {
          this.displayAmount = end; // Ensure we end on the exact target amount
        }
      };
      updateAmount();
    },
    cancelAnimation(): void {
      if (this.animationId !== null) {
        cancelAnimationFrame(this.animationId);
        this.animationId = null;
      }
    },
  },
  mounted() {
    this.checkAndAnimate();
  },
  beforeUnmount() {
    this.cancelAnimation();
  },
});
</script>
