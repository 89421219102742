<template>
  <MCard>
    <div class="fare-wrapper">
      <div class="fare-details" v-if="baseFare">
        <div class="fare-label">Base Fare</div>
        <div class="fare-amount">{{ baseFare }}</div>
      </div>
      <div class="fare-details" v-if="totalTax">
        <div class="fare-label">Total Tax</div>
        <div class="fare-amount">{{ totalTax }}</div>
      </div>
      <div class="fare-details" v-if="grossFare">
        <div class="fare-label">Gross Fare</div>
        <div class="fare-amount">{{ grossFare }}</div>
      </div>
      <div v-if="hasFareDifference" class="fare-details">
        <div class="fare-label">{{ fareDifferenceTitle }}</div>
        <div class="fare-amount">{{ fareDifferenceAmount }}</div>
      </div>
      <div class="fare-details" v-if="dealDiscount">
        <div class="fare-label">Deal Discount</div>
        <div class="fare-amount">
          <MChip variant="success" border-less
            ><b>{{ dealDiscount }}</b>
          </MChip>
        </div>
      </div>
      <div class="fare-details" v-if="totalAmount">
        <div class="fare-label">Total Amount</div>
        <div class="fare-amount fare-total">{{ totalAmount }}</div>
      </div>
      <div class="fare-details" v-if="dealCode">
        <div class="fare-label fi-inline-label">
          Dealcode Applied
          <MChip variant="success" border-less>
            {{ dealCode }}
          </MChip>
        </div>
      </div>
    </div>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MCard } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "IssuanceFareSummary",
  components: {
    MCard,
  },
  props: {
    baseFare: {
      type: String,
      required: true,
    },
    grossFare: {
      type: String,
      required: true,
    },
    totalTax: {
      type: String,
      required: true,
    },
    hasFareDifference: {
      type: Boolean,
      default: false,
    },
    fareDifferenceTitle: {
      type: String,
      default: "",
    },
    fareDifferenceAmount: {
      type: String,
      default: "",
    },
    totalAmount: {
      type: String,
      required: true,
    },
    dealDiscount: {
      type: String,
      required: true,
    },
    dealCode: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="css" scoped>
.fare-wrapper .fare-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--m-secondary-color);
  margin-bottom: 16px;
}
.fare-wrapper .fare-details .fare-label {
  font-weight: 500;
}
.fare-wrapper .fare-details .fare-total {
  font-size: 20px;
  font-weight: 600;
}
.fare-wrapper .fare-details .fi-inline-label {
  min-height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.fare-wrapper .fare-details .fi-inline-label.fi-label-start {
  align-items: flex-start;
}
.fare-wrapper .fare-details .fi-inline-input {
  width: 250px;
  margin-bottom: 0;
}
</style>
