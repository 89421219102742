<template>
  <MAccordion :initial-open="true" class="m-accordion-fare-management">
    <template #title>
      <AgIconInfoBar
        mPrepandIcon="m-passenger-management"
        title="Passenger Details"
        class="maccordion-infobar"
      />
    </template>
    <template #content>
      <MDataTable
        :headers="tableHeaders"
        :data="getPassengersInfo"
        :item-per-page="30"
        :is-api-paginated="false"
        :has-search="false"
        :has-pagination="false"
      >
      </MDataTable>
    </template>
  </MAccordion>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SABRE_AIRLINE } from "@/ag-flight-components/types";
import { MCard, MProgress, MTypography } from "@aeroglobe/ag-core-ui";
import { ComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import { FinancialProfile } from "@/interfaces/agentState.interface";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import getters from "../vuex/getters";
import { MDataTableHeader } from "@/ag-portal-common/types";

export default defineComponent({
  name: "PassengerFareManagementQuoteCard",
  data() {
    return {
      tableHeaders: [
        {
          title: "Title",
          value: "title",
          key: "title",
          sortable: true,
        },
        {
          title: "First Name",
          value: "first_name",
          key: "first_name",
          sortable: true,
        },
        {
          title: "Last Name",
          value: "last_name",
          key: "last_name",
          sortable: true,
        },
        {
          title: "Passenger Type",
          value: "passenger_type",
          key: "passenger_type",
          sortable: true,
        },
      ] as MDataTableHeader[],
    };
  },
  computed: {
    getPassengersInfo() {
      return this.$store.getters.sabreFares.passengers;
    },
  },
});
</script>

<style lang="css" scoped>
.m-accordion-fare-management {
  margin-top: 10px;
}
.maccordion-infobar {
  margin: 0;
  border: none;
  padding: 0;
}
</style>
