<template>
  <MCard class="sector-snapshot-header">
    <p class="heading">Statistics {{ selectedSalesman }}</p>
    <ag-row class="mt-10">
      <ag-column xs="12" sm="12" md="12" lg="5">
        <MDatePicker
          label="Start Date"
          :start-date="defaultStartDate"
          :max-date="new Date()"
          @onRangeDateChange="(value:any) => startDate = value"
          placeholder="Start Date"
          dateType="startDate"
          :multiCalendar="false"
          :hasRange="false"
          :autoPosition="true"
          :error="bookingDateError"
        />
      </ag-column>
      <ag-column xs="12" sm="12" md="12" lg="5">
        <MDatePicker
          label="End Date"
          :start-date="new Date()"
          :max-date="new Date()"
          :min-date="startDate"
          @onRangeDateChange="(value:any) => endDate = value"
          placeholder="End Date"
          dateType="endDate"
          :multiCalendar="false"
          :hasRange="false"
          :autoPosition="true"
          :error="bookingDateError"
        />
      </ag-column>
      <ag-column
        xs="12"
        sm="12"
        md="12"
        lg="2"
        class="d-flex align-items-center"
      >
        <MButton
          @click="handleFetchData"
          :disabled="isSalesLoading"
          class="margin_bottom_20"
          >Fetch Statistics</MButton
        >
      </ag-column>
    </ag-row>
  </MCard>
  <template v-if="isSalesLoading">
    <MCard>
      <ShimmerCard card-height="200px" />
      <ShimmerCard card-height="200px" />
      <ShimmerCard card-height="150px" />
      <ShimmerCard card-height="100px" />
    </MCard>
  </template>

  <MCard v-if="showDetails">
    <FlightIssuance :handleOrganizationRowClick="handleRowClick" />
    <FlightBookings :handleOrganizationRowClick="handleRowClick" />
    <HotelBookings :handleOrganizationRowClick="handleRowClick" />
    <SabreSegments :handleOrganizationRowClick="handleRowClick" />
    <Payments :handleOrganizationRowClick="handleRowClick" />
    <CreditLimit :handleOrganizationRowClick="handleRowClick" />
  </MCard>
  <ResultNotFound v-if="showResultNotFound" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { format } from "date-fns";

import { ISectorProductivity } from "@/ag-portal-common/interfaces/sectorProductivity.interface";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import FlightIssuance from "./productivityTabComponents/flightIssuance.vue";
import FlightBookings from "./productivityTabComponents/flightBookings.vue";
import HotelBookings from "./productivityTabComponents/hotelBookings.vue";
import SabreSegments from "./productivityTabComponents/sabreSegment.vue";
import Payments from "./productivityTabComponents/paymentsData.vue";
import CreditLimit from "./productivityTabComponents/creditLimit.vue";
import { ISalesSector } from "@/ag-portal-common/interfaces/salesProductivity.interface";
import { MCard, MButton } from "@aeroglobe/ag-core-ui";
import ShimmerCard from "@/components/ShimmerCard.vue";
import ResultNotFound from "@/components/ResultNotFound.vue";
import { PATH } from "@/ag-portal-common/constants/path";

export default defineComponent({
  name: "SalesSnapshotProductionTab",
  components: {
    FlightIssuance,
    FlightBookings,
    HotelBookings,
    SabreSegments,
    Payments,
    CreditLimit,
    ShimmerCard,
    MCard,
    MButton,
    ResultNotFound,
  },
  created() {
    this.startDate = this.defaultStartDate;
  },
  data(): {
    startDate: Date;
    endDate: Date;
    selectedSalesman: string;
    isInitial: boolean;
    bookingDateError: string;
  } {
    return {
      startDate: new Date(),
      endDate: new Date(),
      selectedSalesman: "",
      isInitial: true,
      bookingDateError: "",
    };
  },
  methods: {
    handleFetchData() {
      if (this.startDate > this.endDate) {
        this.bookingDateError = "Start date Can't be less than end date";
      } else {
        this.bookingDateError = "";
        const { salesmanId } = this.$route.query;
        this.isInitial = false;
        this.$store.dispatch("getSalesProductivity", {
          salesmanId: salesmanId,
          payload: {
            start_date: format(this.startDate, FORMAT_YYY_MM_DD),
            end_date: format(this.endDate, FORMAT_YYY_MM_DD),
          },
        });
      }
    },

    renderSalemanName() {
      const { salesmanId, sectorId } = this.$route.query;
      this.startDate = new Date();
      this.endDate = new Date();
      if (salesmanId) {
        const sectors: ISalesSector[] = this.$store.getters.salesSectors;
        const selectedSector = sectors.find(
          (x: ISalesSector) => x.sector_id === Number(sectorId)
        );
        const name = selectedSector?.sales_users?.find(
          (x) => x.id === Number(salesmanId)
        )?.name;
        return ` - ${name}`;
      }
      return "";
    },
    handleRowClick(e: any, row: any) {
      const org_id = row?.public_id;
      const url = this.$router.resolve({
        path: PATH.ORGANIZATION_SNAPSHOT,
        query: { org_id: org_id },
      });

      // Open the route in a new tab
      window.open(url.href, "_blank");
    },
  },
  computed: {
    defaultStartDate(): Date {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      return yesterday;
    },
    salesProductivity(): ISectorProductivity {
      return this.$store.getters.salesProductivity;
    },
    isSalesLoading(): boolean {
      return this.$store.getters.isSalesLoading;
    },
    showDetails(): boolean {
      const isLoading = this.$store.getters.isSalesLoading;
      const salesProductivity = this.$store.getters.salesProductivity;
      return !isLoading && salesProductivity;
    },
    showResultNotFound(): boolean {
      return !this.isInitial && !this.isSalesLoading && !this.showDetails;
    },
  },
  unmounted() {
    this.$store.dispatch("setSalesProductivity", null);
  },
  watch: {
    "$route.query": {
      handler: function () {
        const name = this.renderSalemanName();
        this.selectedSalesman = name;
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.sector-snapshot-header {
  .name-container {
    display: flex;
    align-items: center;
    img {
      width: 70px;
    }
    .agency-name-container {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .agency-name {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
        color: #000;
      }
      p {
        margin-bottom: 0;
        color: #6b7280;
      }
    }
  }
}

.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-top: solid 8px var(--green-color);
}

.heading {
  font-size: 24px;
  font-weight: 600;
}

.color-chip {
  width: 120px;
}

.website-slug {
  font-weight: bold;
  color: #6b7280;
  &:hover {
    color: #0c47bc;
  }
}
.empty-items-message {
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}
</style>
