import { GetterTree } from "vuex";
import { INewsState } from "@/ag-portal-common/interfaces/news.interface";
import { StoreState } from "@/store/type";

const getters: GetterTree<INewsState, StoreState> = {
  isLoading: (state) => state.isNewsLoading,
  newsData: (state) => state.news,
};

export default getters;
