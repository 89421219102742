<template>
  <div class="quotation-preview">
    <div class="q-header">
      <div class="q-title">Flight Booking Quotation</div>

      <div class="q-subtitle">Generated at: {{ currentDate }}</div>
    </div>

    <div class="q-heading">Agency Information</div>

    <div class="q-description">
      <div>
        Agent Email: <i>{{ agentEmail }}</i>
      </div>
      <div>
        Agent Phone Number: <i>{{ agentPhone }}</i>
      </div>
      <div>
        Organisation Name: <i>{{ agentOrganisationName }}</i>
      </div>
    </div>

    <template v-if="customerName && customerEmail">
      <div class="q-heading">Customer Information</div>

      <div class="q-description">
        <div>
          Customer Name: <i>{{ customerName }}</i>
        </div>
        <div>
          Customer Email: <i>{{ customerEmail }}</i>
        </div>
      </div>
    </template>

    <div class="q-heading">Flight Options</div>

    <div class="q-options">
      <template
        v-for="(flight, index) in flights"
        :key="index + flight.fare_options[0].pre_booking_token"
      >
        <SingleFlightCard
          :flight="flight"
          :temper-fare="temperFare"
          :is-printing="true"
        />
      </template>

      <template
        v-for="(pair, index) in pairs"
        :key="index + pair.arrival.fare_option.pre_booking_token"
      >
        <PairedFlightCard
          :pair="pair"
          :temper-fare="temperFare"
          :is-printing="true"
        />
      </template>
    </div>

    <div class="q-heading">Terms & Conditions</div>

    <div class="q-description">
      <div>
        Please note that rates and seat availability are subject to change. To
        secure your reservation and avoid potential rate adjustments, we
        recommend booking your seat as early as possible.
      </div>

      <br />

      <div>
        Thank you for choosing <b>{{ agentOrganisationName }}</b
        >. If you have any questions or need assistance, please do not hesitate
        to reach out at <b>{{ agentEmail }}</b> or <b>{{ agentPhone }}</b
        >. We look forward to making your journey memorable!
      </div>

      <br />

      <div class="q-title q-text-center">Thank You</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import SingleFlightCard from "../SingleFlightCard.vue";
import PairedFlightCard from "../PairedFlightCard.vue";

import { FlightOption, ReturnFlightPair } from "@/ag-flight-components/types";
import { TemperFare } from "@/modules/FlightSearchResult/types";

export default defineComponent({
  name: "QuotationPreview",
  components: {
    SingleFlightCard,
    PairedFlightCard,
  },
  props: {
    flights: {
      type: Array as PropType<FlightOption[]>,
      required: true,
    },
    pairs: {
      type: Array as PropType<ReturnFlightPair[]>,
      required: true,
    },
    temperFare: {
      type: Object as PropType<TemperFare>,
      required: true,
    },
    agentEmail: {
      type: String,
      required: true,
    },
    agentPhone: {
      type: String,
      required: true,
    },
    agentOrganisationName: {
      type: String,
      required: true,
    },
    customerName: {
      type: String,
      required: true,
    },
    customerEmail: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentDate(): string {
      const date = new Date();

      return date.toDateString();
    },
  },
});
</script>

<style>
@import url("../../styles/quotation-preview.css");
</style>
