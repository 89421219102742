import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3044a48e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amount" }
const _hoisted_2 = { class: "amount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createBlock(_component_MDialog, {
    title: "Credit Limit Request Confirmation",
    modelValue: _ctx.isMdialogOpen,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isMdialogOpen) = $event)),
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onCloseModal()))
  }, {
    content: _withCtx(() => [
      _createElementVNode("p", null, [
        _createTextVNode(" You have requested credit "),
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.formattedAmountRequested), 1)
      ]),
      _createElementVNode("p", null, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.formattedAmountFee), 1),
        _createTextVNode(" processing fee will be applied. ")
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_MButton, {
        type: "outlined",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCloseModal()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Cancel ")
        ]),
        _: 1
      }),
      _createVNode(_component_MButton, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onConfirm())),
        disabled: _ctx.$store.getters.isCreatingCreditLimitRequest
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Confirm ")
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}