import { Promo, Deal } from "../constants";

const state: IDealsState = {
  isFetchingPromosAndDeals: false,
  promos: null,
  deals: null,
};

export interface IDealsState {
  isFetchingPromosAndDeals: boolean;
  promos: Promo[] | null;
  deals: Deal[] | null;
}

export default state;
