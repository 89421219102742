import * as yup from "yup";

export const addPackageValidation = yup.object().shape({
  active: yup.boolean().required("Required"),
  name: yup.string().required("Package name is required"),
  description: yup.string().required("Package description is required"),
  start_date: yup
    .date()
    .required("Package start date is required")
    .test(
      "startDate",
      "Start date can't be after the end date",
      function (value) {
        const { end_date } = this.parent;
        return value && end_date ? value <= end_date : true;
      }
    ),
  end_date: yup
    .date()
    .required("Package end date is required")
    .test(
      "endDate",
      "End date can't be before the start date",
      function (value) {
        const { start_date } = this.parent;
        return value && start_date ? value >= start_date : true;
      }
    ),
  price: yup.string().required("Package Price is required"),
});
