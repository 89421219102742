import { GetterTree } from "vuex";

import { StoreState } from "@/store/type";
import { IFITState } from "./state";

const getters: GetterTree<IFITState, StoreState> = {
  isFetchingFitHotels: (state) => state.isFetchingFitHotels,
  fitHotelData: (state) => state.hotelData,
  isSendingFITEmail: (state) => state.isSendingFITEmail,
  emailItems: (state) => state.emailItems,
  previewItems: (state) => state.previewItems,
};
export default getters;
