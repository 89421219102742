import { RouteRecordRaw } from "vue-router";
import AddVisaInformationForm from "@/modules/VisaVerification/views/AddInformationForm.vue";
import VisaVerificationList from "@/modules/VisaVerification/views/List.vue";

import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import { PATH } from "@/ag-portal-common/constants/path";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.ADD_VISA_VERIFICATION_REQ,
    name: "visaVerificationForm",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.VISA_VERIFICATION.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.VISA_VERIFICATION.PERMISSIONS,
    },
    component: AddVisaInformationForm,
  },
  {
    path: PATH.VISA_VERIFICATION,
    name: "visaVerificationList",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.VISA_VERIFICATION.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.VISA_VERIFICATION.PERMISSIONS,
    },
    component: VisaVerificationList,
  },
];

export default routes;
