import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d371c35"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reject-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createBlock(_component_MDialog, {
    title: "Reject Organization",
    modelValue: _ctx.isMdialogOpen,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isMdialogOpen) = $event)),
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('handleClose')))
  }, {
    content: _withCtx(() => [
      _createVNode(_component_MTypography, { type: "body" }, {
        default: _withCtx(() => [
          _createTextVNode("Are you sure to reject " + _toDisplayString(_ctx.organization_name) + " ?", 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MTypography, {
          type: "title",
          class: "note-warning"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Note: ")
          ]),
          _: 1
        }),
        _createVNode(_component_MTypography, { type: "body" }, {
          default: _withCtx(() => [
            _createTextVNode(" This action will reject the documents uploaded by the owner and grant them access to upload new documents ")
          ]),
          _: 1
        })
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_MButton, {
        disabled: _ctx.isEliteOrganizationStatusUpdating,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handleReject')))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Reject")
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}