import { IDashboardState } from "@/ag-portal-common/interfaces/dashboard.interface";

const state: IDashboardState = {
  isInsightsLoading: false,
  insights: null,
  bookingAggregate: null,
  dailyFlightChartData: null,
  dailyHotelChartData: null,
};

export default state;
