<template>
  <MAccordion :initial-open="isInitialOpen" :external-open="isOpen">
    <template #title>
      <AgIconInfoBar
        test-id=""
        :title="title"
        class="accordion-title"
        :mPrepandIcon="prepandIcon"
      />
    </template>
    <template #content>
      <div v-if="$slots.overview" class="overview-container">
        <div class="items-container">
          <slot name="overview" />
        </div>
      </div>
      <div v-else class="spacer"></div>
      <slot name="accordion-content" />
    </template>
  </MAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MAccordion } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "SnapshotDetailAccordion",
  components: { MAccordion },
  props: {
    title: {
      type: String,
      required: true,
    },
    isInitialOpen: {
      type: Boolean,
      default: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    prepandIcon: {
      type: String,
      required: true,
      default: "m-info",
    },
  },
});
</script>

<style scoped>
.accordion-title {
  font-size: 20px;
  font-weight: 600;
  text-align: start;
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
.overview-container {
  margin-top: 25px;
}
.overview-heading {
  font-size: 19px;
  margin: 10px;
  font-weight: bold;
}
.items-container {
  padding-top: 15px;
  border-top: solid 4px var(--green-color);
}
.spacer {
  margin-top: 20px;
}
</style>
