<template>
  <MDialog
    title="Update Organization Status"
    v-model="isMdialogOpen"
    @close="$emit('handleClose')"
  >
    <template #content>
      <MTypography
        >Are you sure to {{ status }} {{ organization_name }} ?</MTypography
      >
    </template>
    <template #actions>
      <MButton type="outlined" @click="$emit('handleClose')"> No </MButton>
      <MButton @click="$emit('handleAccept')" :disabled="isLoading">
        Yes
      </MButton>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UpdateOrganizationStatusDialog",
  watch: {
    isOpen(newVal) {
      this.isMdialogOpen = newVal;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("closeModal");
      }
    },
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    organization_name: {
      type: String,
      default: "",
      required: true,
    },
    status: {
      type: String,
      default: "",
      required: true,
    },
  },
  computed: {
    isLoading(): boolean {
      return this.$store.getters.isUpdatingOrganization;
    },
  },
  data() {
    return {
      isMdialogOpen: this.isOpen,
    };
  },
});
</script>

<style scoped>
.btn_container {
  display: flex;
  float: right;
}
.reject_btn {
  margin-right: 10px;
}
</style>
