<template>
  <MCard class="m-hotel-property-detail-list-item">
    <img
      :src="hotelThumbnail"
      @error="onHotelThumbnailErrorHandler"
      loading="lazy"
      class="m-hotel-property-detail-list-thumbnail"
    />
    <div class="m-hotel-property-detail-list-item-info">
      <div class="m-hotel-property-detail-list-content">
        <div>
          <div class="m-hotel-property-detail-list-content-header">
            <MTypography class="property-name" type="title">
              {{ title }}
            </MTypography>
          </div>
          <MTypography
            class="property-board-basis"
            type="body"
            v-show="mealBoardBasis"
          >
            <MIcon
              class="boardbasis-icon"
              :name="getMealBoardBasisIcon(mealBoardBasis as string)"
              width="20"
              height="20"
            />
            <MTypography type="body" class="content">
              {{ mealBoardBasis }}
            </MTypography>
          </MTypography>
        </div>

        <div class="m-hotel-property-detail-list-action-info">
          <MChip :variant="refundTypeVariant" v-show="refundType">
            {{ refundType }}
          </MChip>
          <MChip variant="success" v-show="prepaymentText">
            {{ prepaymentText }}
          </MChip>
        </div>
        <div v-show="note">
          <b>Note:</b>
          Payment will be collected on
          <span class="text-green">
            <b>
              {{ note }}
            </b>
          </span>
        </div>
      </div>
      <div class="m-hotel-property-detail-list-action">
        <div class="m-hotel-property-detail-list-action-content">
          <MTypography type="title" v-show="price">
            {{ price }}
          </MTypography>

          <MTypography type="body" v-show="daysInfo">
            {{ daysInfo }}
          </MTypography>

          <MTypography type="label" v-show="travelerInfo">
            {{ travelerInfo }}
          </MTypography>
        </div>

        <MButton
          class="m-hotel-property-detail-list-action-button"
          @click="$emit('on:click')"
        >
          Book Now
        </MButton>
      </div>
    </div>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { MCard, MTypography, MButton, MChip } from "@aeroglobe/ag-core-ui";

import { ChipVariant } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";

import DEFAULT_IMAGE from "../../../assets/images/hotel-no-photo.png";

export default defineComponent({
  name: "MHotelPropertyDetailListItem",
  components: {
    MCard,
    MTypography,
    MButton,
    MChip,
  },
  props: {
    mealBoardBasis: {
      type: String,
    },
    thumbnail: {
      type: String,
    },
    title: {
      type: String,
    },
    refundType: {
      type: String,
    },
    prepaymentText: {
      type: String,
    },
    price: {
      type: String,
    },
    daysInfo: {
      type: String,
    },
    travelerInfo: {
      type: String,
    },
    note: {
      type: String,
    },
  },
  emits: ["on:click"],
  data() {
    return {
      hotelThumbnail: this.thumbnail,
    };
  },
  computed: {
    refundTypeVariant(): ChipVariant {
      const refundType: string = (this.refundType ?? "").toLowerCase();

      switch (refundType) {
        case "non-refundable":
          return "error";

        default:
          return "info";
      }
    },
  },
  methods: {
    onHotelThumbnailErrorHandler() {
      this.hotelThumbnail = DEFAULT_IMAGE;
    },
    getMealBoardBasisIcon(boardBasis: string | null) {
      switch (boardBasis) {
        case "Breakfast":
          return "m-breakfast";

        case "Bed & Breakfast":
          return "m-restaurant";

        case "Bed And Breakfast":
          return "m-restaurant";

        case "Breakfast Buffet":
          return "m-restaurant";

        case "Room Only":
          return "m-bed";

        case "Full Board":
          return "m-fullboard";

        case "Half Board":
          return "m-halfboard";

        default:
          return "m-other-facilities";
      }
    },
  },
});
</script>

<style scoped>
.m-hotel-property-detail-list-item {
  width: 100% !important;
  height: 200px;
  padding: 0 !important;

  border-radius: 16px !important;
  border: 1px solid var(--m-divider-color) !important;

  --item-background-color-active: #10b9811a !important;
  display: flex;
  margin-bottom: 10px;
}

.m-hotel-property-detail-list-item-info {
  flex: 1;

  display: flex;
}

.m-hotel-property-detail-list-thumbnail {
  width: 198px;
  height: 198px;
  object-fit: cover;
  border-radius: 16px 0 0 16px;
}

.m-hotel-property-detail-list-content {
  flex: 1;
  height: 100%;

  padding: 12px 20px;

  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: space-between;
}

.m-hotel-property-detail-list-content-header {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.m-hotel-property-detail-list-content-header .property-name {
  font-weight: 400;
  flex: 1;
  padding-right: 8px;
  width: 0;
}
.m-hotel-property-detail-list-content-header .review-icon {
  color: gold;
}
.m-hotel-property-detail-list-content-header .property-price {
  display: flex;
  align-items: center;
  gap: 8px;
}
.m-hotel-property-detail-list-content-header .property-details {
  text-align: right;
}

.m-hotel-property-detail-list-content .property-board-basis {
  display: flex;
  gap: 6px;
  margin-top: 10px;
  max-width: fit-content;
  padding: 5px;
  background-color: var(--m-divider-color);
  border-radius: 10px;
}
.m-hotel-property-detail-list-content .property-board-basis .boardbasis-icon {
  width: 20px;
  height: 20px;

  margin-top: 4px;

  color: var(--m-primary-color);
}
.m-hotel-property-detail-list-content .property-board-basis .content {
  font-size: 14px;
  font-weight: 400;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.m-hotel-property-detail-list-action-info {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.m-hotel-property-detail-list-action {
  width: 200px;
  height: 100%;

  background-color: var(--item-background-color-active);
  border-radius: 0 16px 16px 0;

  padding: 20px;

  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: space-between;
}

.m-hotel-property-detail-list-action-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

@media screen and (max-width: 1024px) {
  .m-hotel-property-detail-list-thumbnail {
    height: auto;
  }

  .m-hotel-property-detail-list-item {
    height: auto;
  }
  .m-hotel-property-detail-list-item-info {
    flex-direction: column;
  }

  .m-hotel-property-detail-list-action {
    width: 100%;
    border-radius: 0 0 16px 0;

    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 786px) {
  .m-hotel-property-detail-list-item {
    flex-wrap: wrap;
  }

  .m-hotel-property-detail-list-thumbnail {
    width: 100%;
    height: 180px;

    border-radius: 16px 16px 0 0;
  }

  .m-hotel-property-detail-list-action {
    border-radius: 0 0 16px 16px;
  }
}
</style>
