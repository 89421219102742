import { ActionContext, ActionTree } from "vuex";
import { StatusCodes } from "http-status-codes";

import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import OrganizationPromosAndDeals from "@/modules/Deals/services/deals.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import notificationService from "@/ag-portal-common/services/notification.service";
import { IDealsState } from "@/modules/Deals/vuex/state";
import { StoreState } from "@/store/type";

const actions: ActionTree<IDealsState, StoreState> = {
  // ****** Fetch Deals And Promos ******
  async getOrganizationPromosAndDeals(
    context: ActionContext<IDealsState, StoreState>,
    params: string
  ) {
    const methodName = "actions.getOrganizationPromosAndDeals";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, params);

      context.commit("isPromoAndDealFetching", true);

      const organizationPromosAndDeals = new OrganizationPromosAndDeals();
      const response = await organizationPromosAndDeals.getOrganizationPromos(
        params
      );

      if (
        response.success &&
        response.status === StatusCodes.OK &&
        response.data?.data
      ) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);

        context.commit("fetchedOrganizationPromosAndDeals", response.data.data);
      } else {
        throw response;
      }
    } catch (error: any) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, error);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = error?.message || "";
      notificationService.triggerNotification();
    } finally {
      context.commit("isPromoAndDealFetching", false);
    }
  },
};

export default actions;
