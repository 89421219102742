import { MutationTree } from "vuex";

import { IFITState } from "./state";

import { HotelsData, ItineraryInfo } from "@/modules/FIT/constants";

const mutations: MutationTree<IFITState> = {
  isFetchingFitHotels(state, isFetching: boolean) {
    state.isFetchingFitHotels = isFetching;
  },
  fetchedHotelData(state, payload: HotelsData) {
    state.isFetchingFitHotels = false;
    state.hotelData = payload;
  },
  isSendingFITEmail(state, isSending: boolean) {
    state.isSendingFITEmail = isSending;
  },
  addFITEmailItems(state, payload: ItineraryInfo) {
    if (state.emailItems === null) {
      state.emailItems = [payload];
    } else {
      state.emailItems.push(payload);
    }
  },
  clearFITEmailItems(state) {
    state.emailItems = null;
  },

  setPreviewItems(state, payload: ItineraryInfo) {
    state.previewItems = payload;
  },
  resetFITItems(state) {
    state.previewItems = null;
    state.emailItems = null;
    state.hotelData = null;
    state.isFetchingFitHotels = false;
    state.isSendingFITEmail = false;
  },
};

export default mutations;
