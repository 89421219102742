import { ISector } from "@/ag-portal-common/interfaces/sector.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";

export interface CreateOrganizationDto {
  name: string;
  sector: Pick<ISector, "id" | "sector_name"> | null;
  pia_provider: string;
  phone_dump: string;
  email: string;
  address: string;
  contact_person: string;
  category: string;
  identity_dump: string;
  is_active: boolean;
  is_delete: boolean;
}

export interface UpdateOrganizationDto {
  id: string;
  public_id: string;
  name?: string;
  sector?: Pick<ISector, "id" | "sector_name"> | null;
  pia_provider?: string;
  phone_dump?: string;
  email?: string;
  address?: string;
  contact_person?: string;
  category?: string;
  identity_dump?: string;
  is_active?: boolean;
  is_delete?: boolean;
  status?: ORGANIZATION_STATUSES;
}

export enum UPLOAD_CONTENT_CATEGORY {
  WEBSITE = "WEBSITE",
  DOCUMENTS = "DOCUMENTS",
}

export enum UPLOAD_CONTENT_SUB_CATEGORY {
  PACKAGE = "PACKAGE",
  SERVICE = "SERVICE",
  CNIC = "CNIC",
  VISA = "VISA",
}

export enum UPLOAD_CONTENT_TYPE {
  PDF = "pdf",
  IMAGE = "image",
}

export interface ContentUpload {
  content: File;
  content_category: string;
  content_sub_category: string;
  content_type?: string;
}

export interface OrganizationContentUpload {
  content: FileList;
  content_category: string;
  content_sub_category: string;
}

export interface AddWhatsappMemberDto {
  name: string;
  contact_number: string;
}

export interface AuthorizeWhatsppMemberOTPDto {
  otp_code: string;
  is_authorized: boolean;
  authorization_id: string;
}

export interface StartDateEndDateDTO {
  start_date: Date;
  end_date: Date;
}

export type CNIC = {
  public_id: string;
  image_url: string;
};
export interface IOrganizationDocuments {
  documents: { cnic: CNIC[] };
}
