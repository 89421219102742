<template>
  <DashboardGreetingsCard user="Super User" />
  <NewOrganizationsCreatedChart />
  <SaasEliteOrganizationList :is-dashboard-view="true" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DashboardGreetingsCard from "../DashboardGreetingsCard.vue";
import NewOrganizationsCreatedChart from "@/modules/Organization/components/NewOrganizationCreatedChart.vue";
import SaasEliteOrganizationList from "@/modules/Organization/views/SaasEliteOrganizationList.vue";

export default defineComponent({
  name: "SuperUserDashboardView",
  components: {
    DashboardGreetingsCard,
    NewOrganizationsCreatedChart,
    SaasEliteOrganizationList,
  },
});
</script>
