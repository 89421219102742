import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchFareManagementCard = _resolveComponent("SearchFareManagementCard")!
  const _component_PassengerFareManagementQuoteCard = _resolveComponent("PassengerFareManagementQuoteCard")!
  const _component_OldPriceQuoteCard = _resolveComponent("OldPriceQuoteCard")!
  const _component_NewPriceQuoteCard = _resolveComponent("NewPriceQuoteCard")!
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SearchFareManagementCard),
    (_ctx.isSabreFareAvailable)
      ? (_openBlock(), _createBlock(_component_PassengerFareManagementQuoteCard, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isSabreFareAvailable)
      ? (_openBlock(), _createBlock(_component_OldPriceQuoteCard, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.isSabreFareAvailable)
      ? (_openBlock(), _createBlock(_component_NewPriceQuoteCard, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.isSabreFareAvailable)
      ? (_openBlock(), _createBlock(_component_MButton, {
          key: 3,
          class: "apply-fares",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.applyFares())),
          disabled: _ctx.isSabreFaresLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode("Apply Fares")
          ]),
          _: 1
        }, 8, ["disabled"]))
      : _createCommentVNode("", true)
  ], 64))
}