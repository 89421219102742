import { GetterTree } from "vuex";

import { IFlightBooking } from "@/ag-flight-components/interfaces/IFlightBooking.interface";
import { Traveler } from "@/ag-flight-components/types/FlightBookingForm";
import { StoreState } from "@/store/type";

const getters: GetterTree<IFlightBooking, StoreState> = {
  isFlightBookingLoading: (state): boolean => state.isLoading,
  isFlightConfirmBookingLoading: (state): boolean =>
    state.confirmBookingLoading,
  flightTravelers: (state): Array<Traveler> => state.travelers,
  flightBookingStatus: (state): string => state.bookingStatus,
  flightDetails: (state) => state.flightDetails,
  isFlightCancelBookingLoading: (state) => state.cancelBookingLoading,
  isDownloadTicketLoading: (state) => state.downloadTicketLoading,
  isVoidFlightTicketLoading: (state) => state.voidTicketLoading,
  passengers: (state) => state.passengers,
  isPassengerFetching: (state) => state.isPassengerLoading,
  errors: (state) => state.errors,
  bookingActions: (state) => state.bookingActions,
};

export default getters;
