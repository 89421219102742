<template>
  <SearchFareManagementCard />
  <PassengerFareManagementQuoteCard v-if="isSabreFareAvailable" />
  <OldPriceQuoteCard v-if="isSabreFareAvailable" />
  <NewPriceQuoteCard v-if="isSabreFareAvailable" />
  <MButton
    class="apply-fares"
    v-if="isSabreFareAvailable"
    @click="applyFares()"
    :disabled="isSabreFaresLoading"
    >Apply Fares</MButton
  >
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SearchFareManagementCard from "@/modules/FareManagement/components/SearchFareManagementCard.vue";
import PassengerFareManagementQuoteCard from "@/modules/FareManagement/components/PassengerFareManagementQuoteCard.vue";
import OldPriceQuoteCard from "@/modules/FareManagement/components/OldPriceQuoteCard.vue";
import NewPriceQuoteCard from "@/modules/FareManagement/components/NewPriceQuoteCard.vue";

import { fareManagementResponse } from "../constants";
import { MButton } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "FinancialProfileBox",
  components: {
    SearchFareManagementCard,
    PassengerFareManagementQuoteCard,
    OldPriceQuoteCard,
    NewPriceQuoteCard,
    MButton,
  },
  async created() {
    this.$store.commit("resetSabreFares");
  },
  methods: {
    async fetchAirlinesAndProviders(organizationId: string) {
      await this.$store.dispatch("fetchAirlinesAndProviders", organizationId);
    },

    async applyFares() {
      const sabreFares = this.$store.getters.sabreFares;
      const reference = sabreFares.reference;
      await this.$store.dispatch("applyFares", reference);
    },
  },
  computed: {
    isSabreFaresLoading(): boolean {
      return this.$store.getters.isSabreFaresLoading;
    },
    isSabreFareAvailable(): fareManagementResponse | null {
      return this.$store.getters.sabreFares;
    },
  },
});
</script>

<style lang="css" scoped>
.apply-fares {
  float: right;
  margin-bottom: 10px;
}
</style>
