import { GetterTree } from "vuex";
import { IDashboardState } from "@/ag-portal-common/interfaces/dashboard.interface";
import { StoreState } from "@/store/type";

const getters: GetterTree<IDashboardState, StoreState> = {
  isDashboardInsightsLoading: (state) => state.isInsightsLoading,
  bookingAggregate: (state) => state.bookingAggregate,
  dailyFlightIssuanceData: (state) => state.dailyFlightChartData,
  dailyHotelIssuanceData: (state) => state.dailyHotelChartData,
};

export default getters;
