import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { NEWS_API_PATH } from "@/modules/News/constants";

class newsService {
  private restClient: RestClientService;
  constructor() {
    this.restClient = new RestClientService();
  }
  public getAllNews(): Promise<IAGResponse> {
    return this.restClient.get(NEWS_API_PATH.GET_NEWS, "");
  }
  public getFeaturedNews(): Promise<IAGResponse> {
    return this.restClient.get(NEWS_API_PATH.GET_FEATURED_NEWS, "");
  }
}
export default newsService;
