import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a116547"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-container" }
const _hoisted_2 = { class: "form-field" }
const _hoisted_3 = { class: "form-field" }
const _hoisted_4 = { class: "form-field" }
const _hoisted_5 = { class: "form-field" }
const _hoisted_6 = { class: "form-field" }
const _hoisted_7 = { class: "form-field" }
const _hoisted_8 = { class: "form-field" }
const _hoisted_9 = { class: "form-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTextfield = _resolveComponent("MTextfield")!
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_AgPhoneField = _resolveComponent("AgPhoneField")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MCard = _resolveComponent("MCard")!

  return (_openBlock(), _createBlock(_component_MCard, { class: "details-card" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_MTextfield, {
            inputValue: _ctx.name,
            "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
            label: "Name",
            hasError: !!_ctx.errors?.name,
            errorMessage: _ctx.errors?.name
          }, null, 8, ["inputValue", "hasError", "errorMessage"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_MTextfield, {
            inputValue: _ctx.contact_person_email,
            "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contact_person_email) = $event)),
            type: "email",
            label: "Email",
            hasError: !!_ctx.errors?.contact_person_email,
            errorMessage: _ctx.errors?.contact_person_email
          }, null, 8, ["inputValue", "hasError", "errorMessage"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_MCombobox, {
            inputValue: _ctx.selectedCurrency,
            "onUpdate:inputValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedCurrency) = $event)),
            label: "Currency",
            itemValue: "value",
            itemLabel: "label",
            options: _ctx.currency,
            hasError: !!_ctx.errors?.currency,
            errorMessage: _ctx.errors?.currency,
            disabled: _ctx.isUpdateOrganization
          }, null, 8, ["inputValue", "options", "hasError", "errorMessage", "disabled"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_MCombobox, {
            inputValue: _ctx.pia_provider,
            "onUpdate:inputValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pia_provider) = $event)),
            label: "PIA Provider",
            itemValue: "value",
            itemLabel: "label",
            options: _ctx.piaProviders,
            hasError: !!_ctx.errors?.pia_provider,
            errorMessage: _ctx.errors?.pia_provider
          }, null, 8, ["inputValue", "options", "hasError", "errorMessage"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_AgPhoneField, {
            class: "up-text-field up-phone-field",
            "test-id": "",
            "default-value": _ctx.contact_person_phone,
            error: _ctx.errors?.contact_person_phone,
            onUpdateValue: _ctx.onParseWhatsAppNumberHandler
          }, null, 8, ["default-value", "error", "onUpdateValue"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_MCombobox, {
            inputValue: _ctx.selectedSector,
            "onUpdate:inputValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedSector) = $event)),
            label: "Sector Name",
            itemValue: "value",
            itemLabel: "label",
            options: _ctx.sectors,
            hasError: !!_ctx.errors?.sector,
            errorMessage: _ctx.errors?.sector,
            disabled: _ctx.isUpdateOrganization
          }, null, 8, ["inputValue", "options", "hasError", "errorMessage", "disabled"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_MCombobox, {
            disabled: !_ctx.hasAnySelectedSector,
            inputValue: _ctx.selectedRelatedPerson,
            "onUpdate:inputValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedRelatedPerson) = $event)),
            label: "Related Person",
            itemValue: "value",
            itemLabel: "label",
            options: _ctx.relatedPersons,
            hasError: !!_ctx.errors?.relatedPerson,
            errorMessage: _ctx.errors?.relatedPerson
          }, null, 8, ["disabled", "inputValue", "options", "hasError", "errorMessage"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_MButton, {
            disabled: _ctx.onLoading,
            onClick: _ctx.onSubmit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isCreateOrganization ? "Create" : "Update"), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ])
    ]),
    _: 1
  }))
}