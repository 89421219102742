<template>
  <ShimmerCard card-height="150px" />
  <ShimmerCard card-height="150px" />
  <ShimmerCard card-height="150px" />
</template>
<script>
import { defineComponent } from "vue";
import ShimmerCard from "@/components/ShimmerCard.vue";

export default defineComponent({
  name: "UpdatePassengersShimmer",
  components: { ShimmerCard },
});
</script>
