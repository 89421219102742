import { GetterTree } from "vuex";
import { IHotelSearch } from "../interfaces/hotels.interface";
import { StoreState } from "@/store/type";

const getters: GetterTree<IHotelSearch, StoreState> = {
  isPropertiesFetching: (state): boolean => state.isPropertiesFetching,
  locations: (state) => state.locations,
  isLocationsFetching: (state): boolean => state.isLocationsFetching,
  isPropertyDetailFetching: (state): boolean => state.isPropertyDetailFetching,
  isBookingInitiateLoading: (state): boolean => state.initiateBookingLoading,
  hotelChildAges: (state) => state.hotel_child_age,
  selectedFilters: (state) => state.selectedFilters,
  propertyDetail: (state) => state.propertyDetail,
  hotels: (state) => state.propertyResponse.hotels,
  hotelLocation: (state) => {
    return {
      lat: state.propertyDetail?.latitude,
      lng: state.propertyDetail?.longitude,
    };
  },
  bookingDetails: (state) => state.bookingDetailsResponse?.hotel_details,
  bookingDetailsResponse: (state) => state.bookingDetailsResponse,
  travelers: (state) => state.travelers,
  isConfirmBookindLoading: (state) => state.isConfirmBookingLoading,
  isCancellingConfirmedBooking: (state) => state.isCancellingConfirmedBooking,
  isPayingForBooking: (state) => state.isPayingForBooking,
  isDownloadingHotelVoucher: (state) => state.isDownloadingHotelVoucher,
  hotelVoucherUrl: (state) => state.hotelVoucherUrl,
  suppliers: (state) => state.hotelSuppliers,
  hotelChildCount: (state) => state.child_count,
  propertyDetails: (state) => {
    const {
      hotel_adult_count,
      hotel_child_count,
      selectedLocation,
      checkin_date,
      checkout_date,
    } = state;

    return {
      hotel_adult_count,
      hotel_child_count,
      selectedLocation,
      checkin_date,
      checkout_date,
    };
  },
  hasNext: (state) => state.propertyResponse.has_next,
};

export default getters;
