import { IPaginatedCreditLimitRequests } from "@/interfaces/creditLimitRequest.interface";
import {
  CreditLimitRequestsResponse,
  CreditLimitRequestsResultModel,
} from "@/modules/CreditLimitManagement/models/fetchCreditLimitRequests.response";
import { CREDIT_LIMIT_REQUEST_STATUSES } from "@/ag-portal-common/enums/CREDIT_LIMIT_REQUEST_STATUSES";
import { ICreditLimitRequestDetail } from "@/interfaces/creditLimitRequestDetail.interface";
import {
  CreditLimitRequestDetailResponse,
  RequestHistory,
} from "@/modules/CreditLimitManagement/models/creditLimitRequestDetail.response";

export class CreditLimitManagementMapper {
  private creditLimitRequests: IPaginatedCreditLimitRequests = {
    count: 0,
    next: "",
    previous: "",
    totalPages: 0,
    data: [],
  };

  private creditLimitRequestDetail: ICreditLimitRequestDetail = {
    active: false,
    agentEmail: "",
    agentSectorName: "",
    canApprove: false,
    id: "",
    limitExpiresAt: new Date(),
    organizationId: "",
    organizationName: "",
    priority: "",
    requestInitiatedAt: new Date(),
    requestNotes: "",
    requestedAmount: 0,
    requestedDays: 0,
    financialProfileId: "",
    financialProfileName: "",
    selfApproved: false,
    status: CREDIT_LIMIT_REQUEST_STATUSES.PENDING,
    requestHistory: [],
  };

  public getCreditLimitRequests(): IPaginatedCreditLimitRequests {
    return this.creditLimitRequests;
  }

  public getCreditLimitRequestDetail(): ICreditLimitRequestDetail {
    return this.creditLimitRequestDetail;
  }

  public mapCreditLimitRequestsResponse(
    data: CreditLimitRequestsResponse
  ): IPaginatedCreditLimitRequests {
    this.creditLimitRequests.count = data.count;
    this.creditLimitRequests.previous = data.links.previous;
    this.creditLimitRequests.next = data.links.next;
    this.creditLimitRequests.totalPages = data.total_pages;
    this.creditLimitRequests.data = data.results.map(
      (item: CreditLimitRequestsResultModel) => ({
        id: item.public_id,
        requestInitiatedAt: new Date(item.created_at),
        requestedAmount: item.total_amount,
        requestedDays: item.total_days,
        requestNotes: item.notes,
        agentEmail: item.agent_email,
        agentSectorName: item.sector,
        active: item.is_active,
        organizationId: item.organization_id,
        organizationName: item.organization_name,
        status: item.status as CREDIT_LIMIT_REQUEST_STATUSES,
        limitExpiresAt: item.expiry_date ? new Date(item.expiry_date) : null,
        financialProfileId: item.financial_profile_id,
        priority: item.priority,
        financialProfileName: item.financial_profile_name,
      })
    );

    return this.creditLimitRequests;
  }

  public mapCreditLimitRequestDetailResponse(
    data: CreditLimitRequestDetailResponse
  ): ICreditLimitRequestDetail {
    this.creditLimitRequestDetail.id = data.public_id;
    this.creditLimitRequestDetail.requestInitiatedAt = new Date(
      data.created_at
    );
    this.creditLimitRequestDetail.requestedAmount = data.total_amount;
    this.creditLimitRequestDetail.requestedDays = data.total_days;
    this.creditLimitRequestDetail.requestNotes = data.notes;
    this.creditLimitRequestDetail.agentEmail = data.agent_email;
    this.creditLimitRequestDetail.agentSectorName = data.sector;
    this.creditLimitRequestDetail.active = data.is_active;
    this.creditLimitRequestDetail.status =
      data.status as CREDIT_LIMIT_REQUEST_STATUSES;
    this.creditLimitRequestDetail.limitExpiresAt = data.expiry_date
      ? new Date(data.expiry_date)
      : null;
    this.creditLimitRequestDetail.priority = data.priority;
    this.creditLimitRequestDetail.organizationId = data.organization_id;
    this.creditLimitRequestDetail.organizationName = data.organization_name;
    this.creditLimitRequestDetail.canApprove = data.can_approve;
    this.creditLimitRequestDetail.selfApproved = data.self_approved;
    this.creditLimitRequestDetail.financialProfileId =
      data.financial_profile_id;
    this.creditLimitRequestDetail.requestHistory = data.request_history.map(
      (history: RequestHistory) => ({
        approvedBy: history.approved_by,
        actionType: history.action_type as CREDIT_LIMIT_REQUEST_STATUSES,
        requestMainId: history.request_main_id,
        comments: history.comments,
        lastUpdatedAt: new Date(history.last_updated_at),
      })
    );
    return this.creditLimitRequestDetail;
  }
}
