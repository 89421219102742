import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { ActionContext, ActionTree } from "vuex";
import { IPermissionsState } from "@/ag-portal-common/interfaces/permissionsState.interface";
import PermissionsService from "@/modules/Permissions/services/permissions.service";
import { AGENT_SUB_ROLES } from "@/ag-portal-common/enums/AGENT_SUB_ROLES";
import { StoreState } from "@/store/type";

const actions: ActionTree<IPermissionsState, StoreState> = {
  async fetchPermissions(
    context: ActionContext<IPermissionsState, StoreState>,
    agentType: AGENT_SUB_ROLES
  ) {
    const methodName = "actions.fetchPermissions";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("fetchingPermissions", true);
      const permissionsService = new PermissionsService();
      const response = await permissionsService.getAll(agentType);
      context.commit("fetchPermissionsSuccess", response.data);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
    } catch (err) {
      context.commit("fetchingPermissions", false);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
  },
};

export default actions;
