<template>
  <div class="m-hotel-shimmer-list-item"></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MHotelPropertyDetailListItem",
});
</script>

<style scoped>
.m-hotel-shimmer-card {
  background-color: none;
}
.m-hotel-shimmer-list-item {
  width: 100% !important;
  height: 200px;
  padding: 0 !important;

  border-radius: 16px !important;
  border: 1px solid var(--m-divider-color) !important;

  display: flex;
  margin-bottom: 10px;

  background: linear-gradient(
    to right,
    rgba(215, 215, 215, 0.8) 0%,
    rgba(230, 230, 230, 0.8) 20%,
    rgba(215, 215, 215, 0.8) 40%,
    rgba(215, 215, 215, 0.8) 100%
  );
  background-size: 200% 100%;
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@media screen and (max-width: 786px) {
  .m-hotel-shimmer-list-item {
    flex-wrap: wrap;
  }

  .m-hotel-shimmer-list-thumbnail {
    width: 100%;
    height: 180px;

    border-radius: 16px 16px 0 0;
  }

  .m-hotel-shimmer-list-action {
    border-radius: 0 0 16px 16px;
  }
}
</style>
