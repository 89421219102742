import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTransition = _resolveComponent("MTransition")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "targetEl",
    style: _normalizeStyle(`min-height:${_ctx.fixedMinHeight ? _ctx.fixedMinHeight : _ctx.minHeight}px`)
  }, [
    _createVNode(_component_MTransition, null, {
      default: _withCtx(() => [
        (_ctx.shouldRender)
          ? _renderSlot(_ctx.$slots, "default", { key: 0 })
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 4))
}