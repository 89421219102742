import { GetterTree } from "vuex";
import { ICreditLimitManagementState } from "@/interfaces/creditLimitManagementState.interface";
import { IPaginatedCreditLimitRequests } from "@/interfaces/creditLimitRequest.interface";
import {
  ICreditLimitRequestDetail,
  ICreditLimitSlab,
} from "@/interfaces/creditLimitRequestDetail.interface";
import { StoreState } from "@/store/type";

const getters: GetterTree<ICreditLimitManagementState, StoreState> = {
  isFetchingCreditLimitRequests: (
    state: ICreditLimitManagementState
  ): boolean => state.isFetchingCreditLimitRequests,
  isFetchingCreditLimitRequestDetail: (
    state: ICreditLimitManagementState
  ): boolean => state.isFetchingCreditLimitRequestDetail,
  isCreatingCreditLimitRequest: (state: ICreditLimitManagementState): boolean =>
    state.isCreatingCreditLimitRequest,
  isProcessingCreditLimitRequest: (
    state: ICreditLimitManagementState
  ): boolean => state.isProcessingCreditLimitRequest,
  creditLimitRequests: (
    state: ICreditLimitManagementState
  ): IPaginatedCreditLimitRequests => state.creditLimitRequests,
  creditLimitRequestDetail: (
    state: ICreditLimitManagementState
  ): ICreditLimitRequestDetail | null => state.creditLimitRequestDetail,
  creditLimitSlabs: (state: ICreditLimitManagementState): ICreditLimitSlab[] =>
    state.creditLimitSlabs,
  creditLimitSlabsLoading: (state: ICreditLimitManagementState): boolean =>
    state.isCreditLimitSlabLoading,
};

export default getters;
