<template>
  <MCard class="snapshot-card">
    <div class="icon-container">
      <MIcon class="icon" :name="icon" />
    </div>
    <div class="content">
      <MTypography type="body">{{ heading }}</MTypography>
      <MTypography type="label">
        {{ subHeadingTitle }}: {{ subHeading }}
      </MTypography>
      <MTypography type="label" v-if="descriptionTitle">
        {{ descriptionTitle }}: {{ description }}
      </MTypography>
    </div>
    <div class="button-container">
      <slot name="button"></slot>
    </div>
  </MCard>
</template>

<script>
import { MCard, MIcon, MTypography } from "@aeroglobe/ag-core-ui";
import { defineComponent } from "vue";
export default defineComponent({
  name: "SnapshotTile",
  props: {
    heading: {
      type: String,
      default: "",
    },
    subHeading: {
      type: [Number, String],
      default: "0",
    },
    subHeadingTitle: {
      type: String,
      default: "0",
    },
    description: {
      type: [Number, String],
      default: "0",
    },
    descriptionTitle: {
      type: String,
    },
    icon: {
      type: String,
      default: "m-flight",
    },
  },
});
</script>

<style scoped lang="css">
.snapshot-card {
  display: flex;
  align-items: center;
  border: solid 1px var(--green-color);
  padding: 16px;
}

.icon-container {
  margin-right: 16px;
}

.button-container {
  margin-left: 8px;
}

.content {
  display: flex;
  flex-direction: column;
}
</style>
