import { HotelLocationType, HotelSearchSuggestion } from "../types/hotel/index";

export const getPreHotelsSuggestions = (): HotelSearchSuggestion[] => {
  // ****************************************************************
  // ******* Prod Locations *******
  if (process.env.NODE_ENV === "production") {
    return [
      {
        search_id: "VW5V35ON2LS",
        display_name: "Karachi",
        sub_display_name: "Pakistan",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fkarachi.jpeg?alt=media&token=9c0c7b12-572b-4b4e-8296-774aa33d82aa",
        description:
          "A vibrant metropolis with coastal beauty & historical landmarks.",
      },
      {
        search_id: "VW5V35IOQL0",
        display_name: "Lahore",
        sub_display_name: "Pakistan",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2FLahore.jpeg?alt=media&token=9c66c3db-2a99-4a3b-8f0f-e07f3f5e902d",
        description:
          "The cultural heart of Pakistan with rich history & Mughal architecture.",
      },
      {
        search_id: "VW5V35ULGSU",
        display_name: "Islamabad",
        sub_display_name: "Pakistan",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2FIslamabad.jpeg?alt=media&token=eb706c32-415c-47cb-bcd7-dee38d125e36",
        description:
          "The serene capital city known for its greenery & the iconic Faisal Mosque.",
      },
      {
        search_id: "VW5V35IORWZ",
        display_name: "Mecca",
        sub_display_name: "Saudi Arabia",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fmakkah.jpeg?alt=media&token=1ee8bafa-7960-4f1f-a128-5a2382fd7f8d",
        description:
          "The holiest city in Islam, attracting millions for the Hajj pilgrimage.",
      },
      {
        search_id: "VW5V366I4KF",
        display_name: "Medina",
        sub_display_name: "Saudi Arabia",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fmadina.jpeg?alt=media&token=a1fc4356-9803-4972-b198-207e85d7694f",
        description:
          "The second holiest city in Islam, home to the Prophet's Mosque.",
      },
      {
        search_id: "VW5V35IORH8",
        display_name: "Dubai",
        sub_display_name: "United Arab Emirates",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2FDubai.png?alt=media&token=432a74ba-68a2-4991-af56-dfd3f5009baf",
        description: "A modern city famous for skyscrapers & luxury shopping.",
      },
      {
        search_id: "VW5V350TQEL",
        display_name: "Istanbul",
        sub_display_name: "Turkey",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fistanbul.jpeg?alt=media&token=ce9b381c-fbc8-4379-b01b-42f775e93b79",
        description:
          "A city where East meets West, with stunning mosques & rich culture.",
      },
      {
        search_id: "VW5V35IORM8",
        display_name: "Baku",
        sub_display_name: "Azerbaijan",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fbaku.jpeg?alt=media&token=7230b9b1-9018-41a8-b2d9-9a1b3add0d9a",
        description:
          "An ancient city with modern attractions like the Flame Towers.",
      },
      {
        search_id: "VW5V34UVEH0",
        display_name: "Kuala Lumpur",
        sub_display_name: "Malaysia",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2FKuala-Lumpur.jpeg?alt=media&token=a3647d8e-14b2-42c2-88c1-8790a8907fcb",
        description:
          "A bustling city known for the Petronas Towers & diverse culture.",
      },
      {
        search_id: "VW5V360H9SX",
        display_name: "Phuket",
        sub_display_name: "Thailand",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fphuket.jpeg?alt=media&token=2e85e288-6fc7-40d8-b4c0-37b07580f1fe",
        description:
          "A tropical paradise with beautiful beaches & vibrant nightlife.",
      },
      {
        search_id: "VW5V35CQEJE",
        display_name: "London",
        sub_display_name: "United Kingdom",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Flondon.jpeg?alt=media&token=c1bede76-76d1-466f-95c2-1418f087d2ff",
        description: "A global hub of history, culture, & iconic landmarks.",
      },
      {
        search_id: "VW5V35ON5MT",
        display_name: "Denpasar",
        sub_display_name: "Indonesia",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fdenpasar.jpeg?alt=media&token=86eeb811-739a-4cf5-b935-b550027a037a",
        description: "A gateway to Bali, offering vibrant markets & temples.",
      },
      {
        search_id: "VW5V35ULEKL",
        display_name: "Tokyo",
        sub_display_name: "Japan",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Ftokyo.jpeg?alt=media&token=bfc4f2fb-c72a-42be-a572-96b2fcae908c",
        description:
          "A bustling metropolis that blends tradition with futuristic innovations.",
      },
      {
        search_id: "VW5V35IOQJ8",
        display_name: "Bangkok",
        sub_display_name: "Thailand",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fbangkok.jpeg?alt=media&token=cd4834af-5841-47c2-b187-200ebf1b7b3c",
        description:
          "A dynamic city known for its ornate temples & bustling markets.",
      },
      {
        search_id: "VW5V34UVEXI",
        display_name: "Berlin",
        sub_display_name: "Germany",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fberlin.jpeg?alt=media&token=92d8c233-1031-4937-af44-0e2de292466e",
        description:
          "A city rich in history, with a blend of cultural heritage & modern art.",
      },
      {
        search_id: "VW5V34UVE7S",
        display_name: "Maldives",
        sub_display_name: "Maldives",
        type: HotelLocationType.CITY,
        image:
          "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fmaldives.jpeg?alt=media&token=e19ee61b-5b63-4366-9573-77025026110f",
        description:
          "A tropical paradise known for its stunning white sand beaches.",
      },
    ];
  }
  // ****************************************************************
  // ******* Dev Locations *******
  return [
    {
      search_id: "W13HCO428FD",
      display_name: "Karachi",
      sub_display_name: "Pakistan",
      description:
        "A vibrant metropolis with coastal beauty & historical landmarks.",
      type: HotelLocationType.CITY,
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fkarachi.jpeg?alt=media&token=9c0c7b12-572b-4b4e-8296-774aa33d82aa",
    },
    {
      search_id: "W13HCOXTWOW",
      display_name: "Lahore",
      sub_display_name: "Pakistan",
      description:
        "The cultural heart of Pakistan with rich history & Mughal architecture.",
      type: HotelLocationType.CITY,
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2FLahore.jpeg?alt=media&token=9c66c3db-2a99-4a3b-8f0f-e07f3f5e902d",
    },
    {
      search_id: "W13HCOXR4KW",
      display_name: "Islamabad",
      sub_display_name: "Pakistan",
      description:
        "The serene capital city known for its greenery & the iconic Faisal Mosque.",
      type: HotelLocationType.CITY,
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2FIslamabad.jpeg?alt=media&token=eb706c32-415c-47cb-bcd7-dee38d125e36",
    },
    {
      search_id: "W13HCNS5KZ1",
      display_name: "Mecca",
      sub_display_name: "Saudi Arabia",
      description:
        "The holiest city in Islam, attracting millions for the Hajj pilgrimage.",
      type: HotelLocationType.CITY,
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fmakkah.jpeg?alt=media&token=1ee8bafa-7960-4f1f-a128-5a2382fd7f8d",
    },
    {
      search_id: "W13HCNM794Z",
      display_name: "Medina",
      sub_display_name: "Saudi Arabia",
      type: HotelLocationType.CITY,
      description:
        "The second holiest city in Islam, home to the Prophet's Mosque.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fmadina.jpeg?alt=media&token=a1fc4356-9803-4972-b198-207e85d7694f",
    },
    {
      search_id: "W13HCO3ZFQV",
      display_name: "Dubai",
      sub_display_name: "United Arab Emirates",
      type: HotelLocationType.CITY,
      description: "A modern city famous for skyscrapers & luxury shopping.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2FDubai.png?alt=media&token=432a74ba-68a2-4991-af56-dfd3f5009baf",
    },
    {
      search_id: "W13HCP3S8PZ",
      display_name: "Istanbul",
      sub_display_name: "Turkey",
      description:
        "A city where East meets West, with stunning mosques & rich culture.",
      type: HotelLocationType.CITY,
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fistanbul.jpeg?alt=media&token=ce9b381c-fbc8-4379-b01b-42f775e93b79",
    },
    {
      search_id: "W13HCO428Y5",
      display_name: "Baku",
      sub_display_name: "Azerbaijan",
      type: HotelLocationType.CITY,
      description:
        "An ancient city with modern attractions like the Flame Towers.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fbaku.jpeg?alt=media&token=7230b9b1-9018-41a8-b2d9-9a1b3add0d9a",
    },
    {
      search_id: "W13HCOFYWKI",
      display_name: "Kuala Lumpur",
      sub_display_name: "Malaysia",
      type: HotelLocationType.CITY,
      description:
        "A bustling city known for the Petronas Towers & diverse culture.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2FKuala-Lumpur.jpeg?alt=media&token=a3647d8e-14b2-42c2-88c1-8790a8907fcb",
    },
    {
      search_id: "W13HCNY1O32",
      display_name: "Phuket",
      sub_display_name: "Thailand",
      description:
        "A tropical paradise with beautiful beaches & vibrant nightlife.",
      type: HotelLocationType.CITY,
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fphuket.jpeg?alt=media&token=2e85e288-6fc7-40d8-b4c0-37b07580f1fe",
    },
    {
      search_id: "W13HCNM78AQ",
      display_name: "London",
      sub_display_name: "United Kingdom",
      description: "A global hub of history, culture, & iconic landmarks.",
      type: HotelLocationType.CITY,
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Flondon.jpeg?alt=media&token=c1bede76-76d1-466f-95c2-1418f087d2ff",
    },
    {
      search_id: "W13HCNS5LRS",
      display_name: "Denpasar",
      sub_display_name: "Indonesia",
      description: "A gateway to Bali, offering vibrant markets & temples.",

      type: HotelLocationType.CITY,
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fdenpasar.jpeg?alt=media&token=86eeb811-739a-4cf5-b935-b550027a037a",
    },
    {
      search_id: "W13HCOXTWN5",
      display_name: "Tokyo",
      sub_display_name: "Japan",
      description:
        "A bustling metropolis that blends tradition with futuristic innovations.",
      type: HotelLocationType.CITY,
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Ftokyo.jpeg?alt=media&token=bfc4f2fb-c72a-42be-a572-96b2fcae908c",
    },
    {
      search_id: "W13HCOXTWNX",
      display_name: "Bangkok",
      sub_display_name: "Thailand",
      type: HotelLocationType.CITY,
      description:
        "A dynamic city known for its ornate temples & bustling markets.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fbangkok.jpeg?alt=media&token=cd4834af-5841-47c2-b187-200ebf1b7b3c",
    },
    {
      search_id: "W13HCO9XRNO",
      display_name: "Berlin",
      sub_display_name: "Germany",
      description:
        "A city rich in history, with a blend of cultural heritage & modern art.",
      type: HotelLocationType.CITY,
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fberlin.jpeg?alt=media&token=92d8c233-1031-4937-af44-0e2de292466e",
    },
    {
      search_id: "W13HCORSRFL",
      display_name: "Maldives",
      sub_display_name: "Maldives",
      description:
        "A tropical paradise known for its stunning whitesand beaches.",
      type: HotelLocationType.CITY,
      image:
        "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fmaldives.jpeg?alt=media&token=e19ee61b-5b63-4366-9573-77025026110f",
    },
  ];
};
