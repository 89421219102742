<template>
  <a
    class="floating-widget-button"
    :href="link"
    target="_blank"
    @click="onClickWidgetTrack('whatsapp')"
  >
    <MIcon name="m-send-message" width="14" height="14" />

    <div>WhatsApp</div>
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { MIcon } from "@aeroglobe/ag-core-ui";

import analyticsService from "../services/local_analytics.service";
import { ANALYTICS_COMMON_EVENTS } from "../constants/analytics";

export default defineComponent({
  name: "WhatsAppWidget",
  props: {
    link: {
      type: String,
      default: "",
    },
  },
  components: {
    MIcon,
  },
  methods: {
    onClickWidgetTrack(name: string): void {
      const payload = {
        "item-name": name,
      };

      analyticsService.logActionEvent(
        ANALYTICS_COMMON_EVENTS.WIDGET_CLICKED,
        payload
      );
    },
  },
});
</script>

<style lang="css" scoped>
.floating-widget-button {
  background-color: #25d366;
}
</style>
