<template>
  <HotelSearchBar />
  <ShimmerCard v-if="isPageLoaderLoading" />
  <ShimmerCard v-if="isPageLoaderLoading" />
  <ShimmerCard v-if="isPageLoaderLoading" />
  <template v-else>
    <ag-heading variant="h2" :title="getName" />
    <ag-row v-if="hasPropertyDetails">
      <ag-column cols="12" sm="12" md="12" lg="9">
        <!-- Fancy Box -->
        <AgFancyBox>
          <AgDiv
            class="hotel_gallery_wrap"
            :class="renderFancyBoxParentClass()"
          >
            <AgDiv
              v-for="(item, index) in propertyImages"
              :key="index"
              :class="renderFancyBoxItemClass(index)"
            >
              <a data-fancybox="gallery" :href="item">
                <AgImage
                  class="radius-8"
                  test-id=""
                  :src="item"
                  height="100%"
                  :data-more-count="renderMoreImagesBackdrop(index)"
                />
              </a>
            </AgDiv>
          </AgDiv>
        </AgFancyBox>
        <ag-card>
          <AgTabs class="no_tab_icon" test-id="test" :panel-value="[1]">
            <template #TabHeading>
              <v-tab value="rooms"><span>Rooms</span></v-tab>
              <v-tab value="about"><span>About</span></v-tab>
            </template>
            <template #TabDetail>
              <v-window-item
                value="rooms"
                transition="fade"
                reverse-transition="fade"
              >
                <template
                  v-for="(item, index) in propertyDetail?.room_quotes"
                  :key="index"
                >
                  <MHotelPropertyDetailListItem
                    :id="`${item.stay_quote_id}-${index}`"
                    class="hotel-search-list-item"
                    :thumbnail="item.thumbnail_url"
                    :title="item.room_type"
                    :refund-type="formatRefundable(item.non_refundable)"
                    :price="
                      formatPrice(
                        item.gross_price.value,
                        item.gross_price.currency
                      )
                    "
                    :days-info="getNights"
                    :traveler-info="getTravelersCount"
                    :meal-board-basis="item.meal_board_basis"
                    :prepayment-text="
                      item?.issue_now_pay_later ? 'No prepayment needed' : ''
                    "
                    :note="
                      item?.ag_payment_date
                        ? formattedDate(item.ag_payment_date)
                        : ''
                    "
                    @on:click="handleBooknow(item)"
                  ></MHotelPropertyDetailListItem>
                </template>
              </v-window-item>
              <v-window-item
                value="about"
                transition="fade"
                reverse-transition="fade"
              >
                <div
                  v-html="renderMarkdown(propertyDetail?.description as string)"
                ></div>
              </v-window-item>
            </template>
          </AgTabs>
        </ag-card>
      </ag-column>
      <ag-column cols="12" sm="12" md="12" lg="3">
        <m-card test-id="">
          <ag-heading variant="h3" title="Location" class="margin_bottom_10" />
          <a
            :href="googleMapLocation(propertyDetail?.address_line_1)"
            target="_blank"
          >
            <AgIconBox
              test-id=""
              icon="mapLocation"
              :title="propertyDetail?.address_line_1"
              class="contact_info"
            />
          </a>
          <a
            :href="phoneUrl(propertyDetail?.telephone)"
            v-show="propertyDetail?.telephone"
          >
            <AgIconBox
              test-id=""
              icon="phone"
              :title="propertyDetail?.telephone"
              class="contact_info"
            />
          </a>
        </m-card>

        <GMapMap
          :center="getPropertyLocation"
          :zoom="16"
          map-type-id="terrain"
          style="width: 100%; height: 20rem"
        >
          <GMapMarker
            :position="getPropertyLocation"
            :clickable="true"
            :icon="require('@/assets/marker.png').default"
          />
        </GMapMap>
      </ag-column>
    </ag-row>
    <AgNotFound
      v-else
      test-id=""
      heading="No Results Found"
      description="Please Try Modify Your Filters OR Try Again"
    />
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { differenceInDays } from "date-fns";
import _ from "lodash";
import ShimmerCard from "../components/HotelShimmerCard.vue";

import {
  formatNumber,
  formatStringToRoutePath,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { PropertyDetail, RoomQuote } from "@/ag-portal-common/types/hotel";
import HotelSearchBar from "../components/HotelSearchBar.vue";
import { PATH } from "@/ag-portal-common/constants/path";
import { FORMAT_DD_MM_YYYY_WITH_SPACE } from "@/ag-portal-common/constants/dateTimeFormats";
import analyticsService from "@/analytic.service";
import { HOTEL_ANALYTICS_EVENTS } from "../constants/analyticsEvents";

import MHotelPropertyDetailListItem from "../components/MHotelPropertyDetailListItem.vue";

export default defineComponent({
  name: "HotelPropertyView",
  components: {
    HotelSearchBar,
    MHotelPropertyDetailListItem,
    ShimmerCard,
  },
  data() {
    return {
      allowRouterQueryRequest: true,
    };
  },
  computed: {
    getName() {
      let propertyName = "";
      const propertyDetail = this.$store.getters
        .propertyDetail as PropertyDetail;

      if (propertyDetail && propertyDetail.property_name) {
        propertyName = propertyDetail.property_name;
      }

      return "Property: " + propertyName;
    },
    isPageLoaderLoading() {
      const isPropertiesLoading =
        this.$store.state.hotelModule.isPropertyDetailFetching;
      const isInitiateBookingLoading =
        this.$store.getters.isBookingInitiateLoading;
      return isPropertiesLoading || isInitiateBookingLoading;
    },
    hasPropertyDetails() {
      const isLoading = this.$store.state.hotelModule.isPropertyDetailFetching;
      const propertyDetails = this.$store.getters
        .propertyDetail as PropertyDetail;
      return !isLoading && propertyDetails?.room_quotes?.length;
    },
    getPropertyLocation() {
      return this.$store.getters.hotelLocation;
    },
    propertyDetail(): PropertyDetail {
      return this.$store.getters.propertyDetail;
    },
    getNights() {
      const { checkin, checkout } = this.$route.query;
      const nights = differenceInDays(
        new Date(checkout as string),
        new Date(checkin as string)
      );
      return `${nights} Night${nights > 1 ? "s" : ""}`;
    },
    getTravelersCount(): string {
      const { adult, child } = this.$route.query;

      const child_count = child ? (child as string).split(",").length : 0;
      const totalCount = Number(adult) + child_count;

      return `Traveler${totalCount > 1 ? "s" : ""} ${totalCount}, Room 1`;
    },
    isBookingInitiateLoading(): boolean {
      return this.$store.getters.isBookingInitiateLoading;
    },
    propertyImages() {
      const propertyDetails = this.$store.getters
        .propertyDetail as PropertyDetail;
      const images = _.cloneDeep(propertyDetails?.images) || [];
      return images?.splice(0, 5);
    },
  },
  methods: {
    phoneUrl(phoneNumber: string) {
      return "tel:" + phoneNumber;
    },
    googleMapLocation(location: string) {
      return "https://www.google.com/maps/search/" + location;
    },
    formatPrice(grossPrice: number, default_currency_code: string): string {
      return `${default_currency_code} ${formatNumber(grossPrice)}`;
    },
    formattedDate(date: string) {
      return getFormattedDateTime(new Date(date), FORMAT_DD_MM_YYYY_WITH_SPACE);
    },
    renderMarkdown(description: string | null) {
      return description ?? "";
    },
    renderMealBoard(
      key: "Breakfast" | "Room Only" | "Half Board" | "BED AND BREAKFAST"
    ): string {
      const icons = {
        Breakfast: "cupIcon",
        "Room Only": "bedIcon",
        "Half Board": "halfboardIcon",
        "BED AND BREAKFAST": "cupIcon",
      };
      return icons[key];
    },
    fetchPropertyDetails() {
      const {
        name,
        sub_name,
        destination,
        type,
        checkin,
        checkout,
        adult,
        child,
      } = this.$route.query;

      const childData = child
        ? (child as string).split(",").map((x) => {
            return {
              age: Number(x),
            };
          })
        : null;

      this.$store.commit("saveCheckInDate", new Date(checkin as string));
      this.$store.commit("saveCheckOutDate", new Date(checkout as string));
      this.$store.commit(
        "updateChildrenAges",
        childData ? childData.map((x) => x.age) : []
      );
      this.$store.commit("saveHotelChildCount", childData?.length || 0);
      this.$store.commit("saveHotelAdultCount", Number(adult as string));
      this.$store.commit("saveSelectedLocation", {
        display_name: name,
        sub_display_name: sub_name,
        label: `${name}, ${sub_name}`,
        search_id: destination,
        type,
      });

      const payload = {
        checkin,
        checkout,
        id: this.$route.query.property,
        rooms_occupancy: [
          {
            adults: Number(adult as string),
            ...(childData && { children: childData }),
          },
        ],
      };

      this.$store.dispatch("fetchPropertyDetails", payload);
    },
    formatRefundable(non_refundable: boolean | undefined) {
      return non_refundable ? "Non-Refundable" : "Refundable";
    },
    formatRoomType(roomType: string): string {
      return _.capitalize(roomType);
    },
    renderPrice(grossPrice: number, currencyCode: string) {
      return `${currencyCode} ${formatNumber(grossPrice)}`;
    },
    onSuccessHandler(booking_id: string) {
      this.$router.push({
        path: formatStringToRoutePath(PATH.HOTEL_PROPERTY_BOOKING_FORM, {
          id: booking_id,
        }),
        query: this.$route.query,
      });
    },
    handleBooknow(quote: RoomQuote) {
      const payload = {
        "check-in": quote.check_in,
        "check-out": quote.check_out,
        "price-currency": quote.gross_price.currency,
        "price-value": quote.gross_price.value,
        "meal-board": quote.meal_board_basis,
        type: quote.room_type,
        "quote-id": quote.stay_quote_id,
        "is-refundable": quote.non_refundable,
      };

      analyticsService.logActionEvent(
        HOTEL_ANALYTICS_EVENTS.HOTEL_BOOK_NOW,
        payload
      );

      this.$store.dispatch("initiatePropertyBooking", {
        payload: { quote_id: quote.stay_quote_id },
        successCallback: this.onSuccessHandler,
      });
    },
    renderFancyBoxParentClass() {
      const propertyDetails = this.$store.getters
        .propertyDetail as PropertyDetail;
      const imagesLength = propertyDetails?.images?.length || 0;
      return `hotel_gallery_wrap_${imagesLength >= 5 ? 5 : imagesLength}`;
    },
    renderFancyBoxItemClass(index: number) {
      return `gallery_thumb_${index + 1}`;
    },
    renderMoreImagesBackdrop(index: number) {
      const propertyDetails = this.$store.getters
        .propertyDetail as PropertyDetail;
      const imagesLength = propertyDetails?.images?.length || 0;
      return index === 4 ? (imagesLength > 5 ? imagesLength - 5 : "") : "";
    },
  },
  watch: {
    "$route.query": {
      handler: function () {
        if (this.allowRouterQueryRequest) {
          this.fetchPropertyDetails();
        }
      },
      immediate: true,
    },
  },
  beforeRouteLeave() {
    this.allowRouterQueryRequest = false;
  },
});
</script>
