import { IAuthState } from "@/ag-portal-common/interfaces/authState.interface";

const authState: IAuthState = {
  user: null,
  token: {
    access: "",
    refresh: "",
  },
  permissions: [],

  organization: undefined,
  financialProfiles: undefined,
  sectors: undefined,

  isUserSignedIn: false,
  isAuthenticating: false,
  isForgettingPassword: false,
  isResettingPassword: false,
};

export default authState;
