import { createApp } from "vue";
import AgCoreUI from "@aeroglobe/ag-core-ui";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import HighchartsVue from "highcharts-vue";
import GoogleMaps from "@fawmi/vue-google-maps";
import "./assets/main.css";
const app = createApp(App);

app.use(router);
app.use(store);
app.use(AgCoreUI);
app.use(HighchartsVue as any);
app.use(GoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
  },
});

app.mount("#app");
