import { MutationTree } from "vuex";
import { ISectorState } from "@/ag-portal-common/interfaces/sectorState.interface";
import { ISector } from "@/ag-portal-common/interfaces/sector.interface";
import {
  ISectorProductivity,
  ISectorStatistics,
} from "@/ag-portal-common/interfaces/sectorProductivity.interface";

const mutations: MutationTree<ISectorState> = {
  saveSectors(state, sectors: Array<ISector>) {
    state.sectors = sectors;
  },
  setSectorStatsLoading(state, payload: boolean) {
    state.isSectorStatsLoading = payload;
  },
  setSectorProductivityLoading(state, payload: boolean) {
    state.isSectorProductivityLoading = payload;
  },
  setSectorStats(state, payload: ISectorStatistics) {
    state.sectorStats = payload;
  },
  setSectorProductivity(state, payload: ISectorProductivity) {
    state.sectorProductivity = payload;
  },
  setSectorLoading(state, payload: boolean) {
    state.isFetchingSectors = payload;
  },
};

export default mutations;
