export const FIT_API_PATH = {
  GET_HOTELS: "/v1/tours/fit/search?",
  SEND_EMAIL: "/v1/tours/fit/initiate",
};

export interface HotelMeta {
  distance: string;
}

export interface RoomRate {
  per_night_per_bed: string;
  per_night_per_room: string;
  room_count: number;
  final_price: string;
}

export interface RoomDetail {
  room_id: string;
  room_name: string;
  max_occupancy: number;
  total_price: string;
  total_nights: number;
  room_rate: RoomRate;
}

export interface HotelDetail {
  hotel_name: string;
  hotel_id: string;
  city_id: string;
  address: string;
  hotel_currency: string;
  hotel_meta: HotelMeta;
  rooms_detail: RoomDetail[];
}

export interface HotelsData {
  hotels_detail: HotelDetail[];
}

export interface ItineraryInfo {
  hotel_id: string;
  room_id: string;
  total_passengers: number;
  check_in: string;
  check_out: string;
  total_selling_price: string;
  number_of_rooms: number;
  hotel_name: string;
  room_name: string;
  nights: number;
}

export interface FITBookingPayload {
  itinerary_info: ItineraryInfo[];
  request_message: string;
}

export interface FITSearchPayload {
  city: number;
  checkin: string;
  checkout: string;
  travelers: number;
}
export interface Destination {
  display_name: string;
  sub_display_name: string;
  city_id: number;
  description: string;
  image: string;
}
