import { Module } from "vuex";
import { IOrganizationState } from "@/ag-portal-common/interfaces/organizationState.interface";
import { StoreState } from "@/store/type";

import mutations from "./mutations";
import actions from "./actions";
import state from "./state";
import getters from "./getters";

const authModule: Module<IOrganizationState, StoreState> = {
  state,
  mutations,
  actions,
  getters,
};

export default authModule;
