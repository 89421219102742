import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeLayout from "@/ag-portal-common/Layout/HomeLayout.vue";

import AuthRoutes from "@/modules/Auth/route";
import FlightRoutes from "./flightRoutes";
import HotelRoutes from "./hotelRoutes";
import TourRoutes from "./tourRoutes";

import HomeRoutes from "./homeRoutes";

import { PATH } from "@/ag-portal-common/constants/path";
import {
  AuthMiddleware,
  CheckPermissionsMiddleware,
  CheckRoleMiddleware,
  ScrollToTopMiddleware,
} from "./middleware";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.ROOT,
    name: "auth",
    meta: {
      isProtected: false,
    },
    children: AuthRoutes,
  },
  {
    path: PATH.ROOT,
    name: "homeRoutes",
    component: HomeLayout,
    meta: {
      isProtected: true,
    },
    props: {
      title: "",
    },
    children: HomeRoutes,
  },
  {
    path: PATH.HOTELS,
    name: "hotelRoutes",
    component: HomeLayout,
    meta: {
      isProtected: true,
    },
    props: {
      title: "",
    },
    children: HotelRoutes,
  },
  {
    path: PATH.FLIGHTS,
    name: "flightRoutes",
    component: HomeLayout,
    meta: {
      isProtected: true,
    },
    props: {
      title: "",
    },
    children: FlightRoutes,
  },
  {
    path: PATH.FIX_DEPARTURE_GROUP,
    name: "tourRoutes",
    component: HomeLayout,
    meta: {
      isProtected: true,
    },
    props: {
      title: "",
    },
    children: TourRoutes,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(AuthMiddleware);
router.beforeEach(CheckRoleMiddleware);
router.beforeEach(CheckPermissionsMiddleware);
router.beforeEach(ScrollToTopMiddleware);

export default router;
