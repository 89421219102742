<template>
  <AgTabs test-id="test">
    <template #TabHeading>
      <v-tab value="one">
        <MIcon name="m-details" />
        <span>Details</span>
      </v-tab>
      <v-tab value="two">
        <MIcon name="m-issue-ticket" />
        <span>Booking Template</span></v-tab
      >
    </template>
    <template #TabDetail>
      <v-window-item
        value="one"
        transition="fade"
        reverse-transition="fade"
        :disabled="true"
      >
        <form @submit="onSubmit" class="organization-preference">
          <MCard>
            <ag-heading variant="h3" title="Update Preferences"></ag-heading>
            <ag-heading
              variant="p"
              title="Update your preference here"
            ></ag-heading>
            <hr />
            <ag-row>
              <ag-column xs="12" md="6" cols="12">
                <ag-column class="preferences_right_border">
                  <ag-upload
                    :testId="
                      genTestId(
                        ELEMENT_TYPES.FILE_UPLOAD_FIELD,
                        'organization-preference-logo'
                      )
                    "
                    accept=".jpg, .jpeg, .png"
                    @onFileChange="onLogoChange"
                    @onFileRemove="onLogoRemove"
                  />
                </ag-column>
              </ag-column>
              <ag-column>
                <MTypography type="body" class="margin_bottom_10"
                  >Travel Agency Name</MTypography
                >
                <MTextfield
                  v-model:inputValue="displayName"
                  :value="displayName"
                  type="text"
                  label="Display Name"
                  :onkeyup="onDisplayNameChange"
                />

                <MTypography type="body">Choose Theme</MTypography>
                <ag-card class="chip_wrapper theme-chip">
                  <ag-color-chip
                    v-for="(color, index) in colorList"
                    v-bind:key="index"
                    :color-name="color.name"
                    :testId="
                      genTestId(
                        ELEMENT_TYPES.BUTTON,
                        `organization-preference-color-${color.code}`
                      )
                    "
                    :color-code="color.code"
                    :is-active="color.code === primaryColor.code"
                    @onClick="onColorChange(color)"
                  />
                </ag-card>
              </ag-column>
            </ag-row>
            <ag-column class="bottom_inline_btn">
              <MButton
                type="text"
                :testId="
                  genTestId(
                    ELEMENT_TYPES.BUTTON,
                    'organization-preference-cancel'
                  )
                "
                @click="onCancelClick"
                :disabled="isUpdateButtonDisabled"
                >Cancel
              </MButton>
              <MButton
                type="filled"
                behaviour="submit"
                :testId="
                  genTestId(
                    ELEMENT_TYPES.TEXT_FIELD,
                    'organization-preference-submit'
                  )
                "
                :disabled="isUpdateButtonDisabled"
                :is-loading="$store.getters.isUpdatingOrganization"
                >Update
              </MButton>
            </ag-column>
          </MCard>
        </form>
      </v-window-item>
      <v-window-item
        value="two"
        transition="fade"
        reverse-transition="fade"
        :disabled="true"
      >
        <div class="template-container">
          <MTypography class="description" type="body">
            Please select the template you prefer for the ticket ?
          </MTypography>
          <MSegmentedButton :segments="templates" />
        </div>
        <MCard class="thumbnail-container">
          <booking-template
            :logo="logo.url"
            :organization-name="orgName"
            :default-color="primaryColor.code"
            v-show="!isClassicTemplateSelected()"
          />
          <booking-template-two
            :logo="logo.url"
            :organization-name="orgName"
            :default-color="primaryColor.code"
            class="thumbnail"
            v-show="isClassicTemplateSelected()"
          />
        </MCard>
        <MButton
          class="template-button"
          @click="handleSaveEmailTemplate"
          :disabled="isSaveTemplateButtonDisabled"
          >Save Template
        </MButton>
      </v-window-item>
    </template>
  </AgTabs>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ORGANIZATION_COLORS } from "@/modules/Organization/constants";
import BookingTemplate from "@/modules/Organization/components/BookingTemplate.vue";
import BookingTemplateTwo from "@/modules/Organization/components/BookingTemplateTwo.vue";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import analyticsService from "@/analytic.service";
import { ORGANIZATION_ANALYTICS_EVENTS } from "@/modules/Organization/constants/analyticsEvents";
import { Organization } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";

import { setThemeColor } from "@/ag-portal-common/utils/colortheme";
import { MButton, MCard, MTextfield, MTypography } from "@aeroglobe/ag-core-ui";
import UTILS from "@/ag-portal-common/utils";
import { MSegment } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";

export default defineComponent({
  name: "OrganizationPreference",
  components: {
    BookingTemplate,
    BookingTemplateTwo,
    MButton,
    MTextfield,
    MTypography,
  },
  methods: {
    genTestId,
    isClassicTemplateSelected(): boolean {
      const isClassicSelected = this.templates[1].checked ? true : false;
      if (isClassicSelected) {
        this.selectedTemplate = "TEMPLATE_ONE";
      } else {
        this.selectedTemplate = "DEFAULT";
      }
      return isClassicSelected;
    },
    handleSaveEmailTemplate() {
      if (!this.organization) {
        return;
      }

      const request = {
        template: this.selectedTemplate,
      };

      analyticsService.logActionEvent(
        ORGANIZATION_ANALYTICS_EVENTS.PREFERENCES_UPDATE,
        request
      );

      this.$store.dispatch("updateOrganizationPreference", {
        payload: request,
        organizationId: this.organization.organization_id,
      });
      let organizationDetails = this.organization;
      organizationDetails = {
        ...organizationDetails,
        booking_template: this.selectedTemplate,
      };
      UTILS.updateOrganization(organizationDetails);
    },
    updateDataAsStateData() {
      this.logo = {
        url: this.orgLogo,
        file: null,
      };
      this.displayName = this.orgName;
      this.primaryColor = this.orgPrimaryColor;
      this.selectedTemplate = this.orgTemplate;
      if (this.selectedTemplate === "DEFAULT") {
        this.templates[0].checked = true;
      } else {
        this.templates[1].checked = true;
      }
    },
    onLogoChange(files: File[]) {
      const image = new Image();
      image.src = URL.createObjectURL(files[0]);
      image.onload = () => {
        if (!(image.width === 600 && image.height === 300)) {
          this.onLogoRemove();
          notificationService.type = NOTIFICATION_TYPES.ERROR;
          notificationService.description =
            "Image Dimensions must be 600 x 300, Please resize your image size accordingly.";
          notificationService.triggerNotification();
          return;
        }
        this.logo = {
          url: URL.createObjectURL(files[0]),
          file: files[0],
        };
      };
    },
    onLogoRemove() {
      this.logo = {
        url: this.orgLogo,
        file: null,
      };
    },
    onDisplayNameChange(event: any) {
      this.displayName = event.target.value;
    },
    onColorChange(selectedColor: { code: string; name: string }) {
      this.primaryColor = selectedColor;
    },
    onCancelClick() {
      this.updateDataAsStateData();

      const analyticsPayload = {
        "cancel-update": true,
      };

      analyticsService.logActionEvent(
        ORGANIZATION_ANALYTICS_EVENTS.PREFERENCES_CANCEL,
        analyticsPayload
      );
    },
    async onSubmit(event: Event) {
      event.preventDefault();

      if (!this.organization) {
        return;
      }

      const { organization } = this;
      const { displayName, primaryColor, logo } = this;

      const request: any = {};

      const updatedOrganizationDetails = {
        ...organization,
        org_name_to_display: (
          displayName ?? organization.org_name_to_display
        ).trim(),
        org_default_color: primaryColor.code ?? organization.org_default_color,
      };

      request.displayName = updatedOrganizationDetails.org_name_to_display;
      request.defaultColor = updatedOrganizationDetails.org_default_color;
      if (logo.file) {
        request.logoFile = logo.file;
      }
      if (Object.keys(request).length === 0) return;

      // Analytics payload
      const analyticsPayload = {
        "organisation-name": request.displayName,
        "organisation-color": request.defaultColor,
        "organisation-filename": logo.file ? "new logo added" : "no logo added",
      };
      analyticsService.logActionEvent(
        ORGANIZATION_ANALYTICS_EVENTS.PREFERENCES_UPDATE,
        analyticsPayload
      );

      await this.$store.dispatch("updateOrganizationPreference", {
        payload: request,
        organizationId: organization.organization_id,
      });

      UTILS.updateOrganization(updatedOrganizationDetails);
      setThemeColor(updatedOrganizationDetails);
    },
  },
  computed: {
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    orgTemplate(): string {
      const organization = this.organization;

      return organization ? organization.booking_template ?? "DEFAULT" : "";
    },
    orgName(): string {
      const DEFAULT_NAME = "Aeroglobe";
      const organization = this.organization;

      return organization ? organization.org_name_to_display : DEFAULT_NAME;
    },
    orgLogo(): string {
      const DEFAULT_LOGO = "../../assets/logo.svg";
      const organization = this.organization;
      const orgLogo = organization ? organization.org_logo : null;

      return orgLogo ?? DEFAULT_LOGO;
    },
    orgPrimaryColor(): { code: string; name: string } {
      const organization = this.organization;
      const orgColor = organization ? organization.org_default_color : null;

      if (orgColor) {
        const foundColor = ORGANIZATION_COLORS.find(
          ({ code }) => code === orgColor
        );
        return foundColor || ORGANIZATION_COLORS[0];
      }
      return ORGANIZATION_COLORS[0];
    },

    colorList() {
      return ORGANIZATION_COLORS;
    },
    isDisplayNameChanged(): boolean {
      const newDisplayName = (
        this.displayName ?? this.organization?.org_name_to_display
      ).trim();
      if (newDisplayName === "") return false;
      return newDisplayName !== this.orgName;
    },
    isPrimaryColorChanged(): boolean {
      return this.primaryColor.code !== this.orgPrimaryColor.code;
    },
    isUpdateButtonDisabled(): boolean {
      const logoChanged = this.logo.file !== null;
      const preferencesChanged = !(
        logoChanged ||
        this.isDisplayNameChanged ||
        this.isPrimaryColorChanged
      );
      return preferencesChanged || this.$store.getters.isUpdatingOrganization;
    },
    isSaveTemplateButtonDisabled(): boolean {
      const preferencesChanged =
        this.selectedTemplate !== this.organization?.booking_template;
      return !preferencesChanged || this.$store.getters.isUpdatingOrganization;
    },
  },
  data() {
    return {
      logo: {
        url: null as null | string,
        file: null as null | File,
      },
      ELEMENT_TYPES,
      displayName: "",
      primaryColor: { code: "", name: "" },
      selectedTemplate: "",
      templates: [
        {
          label: "Standard",
          checked: false,
          icon: "m-issue-ticket",
        },
        {
          label: "Classic",
          checked: false,
          icon: "m-star",
        },
      ] as MSegment[],
    };
  },
  created() {
    this.updateDataAsStateData();
  },
  watch: {
    "$store.state.authModule.organization_preference": function () {
      this.logo = {
        url: this.orgLogo,
        file: null,
      };
    },
  },
});
</script>

<style lang="css" scoped>
.template-m-accordions {
  margin: 16px;
}
.template-button {
  float: right;
  margin: 10px;
}
.section-title {
  margin: 0;
  border: none;
  padding: 2px;
}
.template-container {
  padding: 16px;
}
.template-radio-button-container {
  display: flex;
  gap: 20px;
  align-items: center;
}

@media (max-width: 767px) {
  .template-radio-button-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
}
</style>

<style lang="css">
.organization-preference .default_white_widget.chip_wrapper {
  padding: 0px;
}
.thumbnail-container {
  margin: 10px;
}
</style>
