<template>
  <MCard class="details-card">
    <div class="form-container">
      <div class="form-field">
        <MTextfield
          v-model:inputValue="name"
          label="Name"
          :hasError="!!errors?.name"
          :errorMessage="errors?.name"
        />
      </div>
      <div class="form-field">
        <MTextfield
          v-model:inputValue="contact_person_email"
          type="email"
          label="Email"
          :hasError="!!errors?.contact_person_email"
          :errorMessage="errors?.contact_person_email"
        />
      </div>
      <div class="form-field">
        <MCombobox
          v-model:inputValue="selectedCurrency"
          label="Currency"
          itemValue="value"
          itemLabel="label"
          :options="currency"
          :hasError="!!errors?.currency"
          :errorMessage="errors?.currency"
          :disabled="isUpdateOrganization"
        />
      </div>
      <div class="form-field">
        <MCombobox
          v-model:inputValue="pia_provider"
          label="PIA Provider"
          itemValue="value"
          itemLabel="label"
          :options="piaProviders"
          :hasError="!!errors?.pia_provider"
          :errorMessage="errors?.pia_provider"
        />
      </div>
      <div class="form-field">
        <AgPhoneField
          class="up-text-field up-phone-field"
          test-id=""
          :default-value="contact_person_phone"
          :error="errors?.contact_person_phone"
          @update-value="onParseWhatsAppNumberHandler"
        />
      </div>
      <div class="form-field">
        <MCombobox
          v-model:inputValue="selectedSector"
          label="Sector Name"
          itemValue="value"
          itemLabel="label"
          :options="sectors"
          :hasError="!!errors?.sector"
          :errorMessage="errors?.sector"
          :disabled="isUpdateOrganization"
        />
      </div>
      <div class="form-field">
        <MCombobox
          :disabled="!hasAnySelectedSector"
          v-model:inputValue="selectedRelatedPerson"
          label="Related Person"
          itemValue="value"
          itemLabel="label"
          :options="relatedPersons"
          :hasError="!!errors?.relatedPerson"
          :errorMessage="errors?.relatedPerson"
        />
      </div>
      <div class="form-field">
        <MButton :disabled="onLoading" @click="onSubmit">
          {{ isCreateOrganization ? "Create" : "Update" }}
        </MButton>
      </div>
    </div>
  </MCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IOrganizationDetail } from "@/ag-portal-common/interfaces/organizationDetail.interface";
import { organizationCreateUpdateValidation } from "@/modules/Organization/validations/organizationCreateUpdateForm.validation";
import { ValidationError } from "yup";
import {
  genTestId,
  yupValidationErrorAsSchema,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { ISalesSector } from "@/ag-portal-common/interfaces/salesProductivity.interface";
import { MButton, MTextfield, MCombobox } from "@aeroglobe/ag-core-ui";
import { PhoneFieldValue } from "@/ag-portal-common/interfaces/profile.interface";

// Define the type for sales user objects
interface SalesUser {
  id: number;
  name: string;
  email: string;
}

export default defineComponent({
  name: "OrganizationForm",
  components: {
    MButton,
    MTextfield,
    MCombobox,
  },
  props: {
    onLoading: {
      type: Boolean,
    },
    organizationData: {
      type: Object as PropType<IOrganizationDetail>,
      required: false,
    },
    isCreateOrganization: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    this.relatedPersons = [];
  },
  data(): {
    name: string;
    sector: string;
    pia_provider: string;
    contact_person_phone: string;
    contact_person_email: string;
    is_active: boolean;
    is_delete: boolean;
    errors: any;
    ELEMENT_TYPES: IObject;
    selectedCurrency: string;
    relatedPersons: any[];
    selectedRelatedPerson: string;
    selectedSector: string;
    isPhoneNumberValid: boolean;
  } {
    return this.isCreateOrganization
      ? {
          ELEMENT_TYPES,
          name: "",
          sector: "",
          pia_provider: "",
          contact_person_phone: "",
          contact_person_email: "",
          is_active: true,
          is_delete: false,
          errors: {},
          selectedCurrency: "",
          relatedPersons: [],
          selectedSector: "",
          selectedRelatedPerson: "",
          isPhoneNumberValid: false,
        }
      : {
          ELEMENT_TYPES,
          name: this.organizationData?.name || "",
          sector: "",
          pia_provider: this.organizationData?.pia_provider || "",
          contact_person_phone:
            this.renderContactPersonNumber(
              this.organizationData?.contact_person_phone
            ) || "",
          contact_person_email:
            this.organizationData?.contact_person_email || "",
          is_active: this.organizationData?.is_active || true,
          is_delete: this.organizationData?.is_delete || false,
          errors: {},
          selectedCurrency: this.organizationData?.currency || "",
          relatedPersons: [],
          selectedSector: "",
          selectedRelatedPerson: "",
          isPhoneNumberValid: false,
        };
  },
  methods: {
    genTestId,
    onSubmit: async function (event: any) {
      event.preventDefault();
      this.errors = {};
      try {
        const {
          name,
          pia_provider,
          contact_person_phone,
          contact_person_email,
          is_delete,
          is_active,
        } = this;
        const payload = await organizationCreateUpdateValidation.validate(
          {
            name,
            sector: this.selectedSector ? Number(this.selectedSector) : -1,
            pia_provider,
            contact_person_phone,
            contact_person_email,
            is_delete,
            is_active,
            currency: this.selectedCurrency,
            relatedPerson: this.selectedRelatedPerson
              ? Number(this.selectedRelatedPerson)
              : -1,
            sales_poc: this.selectedRelatedPerson,
          },
          { abortEarly: false }
        );

        if (this.isPhoneNumberValid) {
          this.$emit("onSubmitOrganizationForm", {
            ...payload,
            public_id: this.organizationData?.public_id,
          });
        } else {
          this.errors["contact_person_phone"] =
            "Please enter a valid phone number";
        }
      } catch (exception) {
        if (exception instanceof ValidationError) {
          this.errors = yupValidationErrorAsSchema(exception);
        }
      }
    },
    updateSalesUsers(sectorId: string) {
      const salesSectors: ISalesSector[] =
        this.$store.getters.organizationSectors || [];
      const selectedObj = salesSectors?.find(
        (item) => item?.sector_id === Number(sectorId)
      );
      if (selectedObj) {
        const sales_users = selectedObj.sales_users.map((user: SalesUser) => {
          return {
            label: user.name + " - (" + user.email + ")",
            value: Number(user.id),
          };
        });
        this.relatedPersons = sales_users;
      } else {
        this.relatedPersons = [];
      }
    },
    selectedSectorFromResponse(sectors: ISalesSector[] = []): string {
      const organization_sector_name = this.organizationData?.sector || "";
      const data = sectors?.find(
        (x: ISalesSector) => x?.sector_name === organization_sector_name
      );
      return data?.sector_id.toString() || "";
    },
    onParseWhatsAppNumberHandler(value: PhoneFieldValue) {
      if (value.e164) {
        this.contact_person_phone = value.e164;
        this.isPhoneNumberValid = value.isValid;
      }
    },
    renderContactPersonNumber(phoneNumber: string | null) {
      const modifiedNumber = phoneNumber
        ? phoneNumber.replace(/^03/, "+923")
        : "";
      return modifiedNumber;
    },
  },
  computed: {
    hasAnySelectedSector(): boolean {
      return this.selectedSector ? true : false;
    },
    currency(): IObject[] {
      const currencies = this.$store.getters.curreny || [];
      return currencies.map((currency: string) => ({
        label: currency,
        value: currency,
      }));
    },
    sectors(): IObject[] {
      const sectors = this.$store.getters.organizationSectors;
      return (
        sectors?.map((item: ISalesSector) => {
          return {
            label: item.sector_name,
            value: item.sector_id + "",
          };
        }) || []
      );
    },
    piaProviders(): IObject[] {
      const providers = this.$store.getters.piaProviders || [];
      return providers.map((provider: string) => ({
        label: provider,
        value: provider,
      }));
    },
    isUpdateOrganization(): boolean {
      return !this.isCreateOrganization;
    },
  },
  watch: {
    selectedSector: {
      handler: function (value: string) {
        if (value) {
          this.updateSalesUsers(value);
        }
      },
      immediate: true,
    },

    "$store.getters.organizationSectors": {
      handler: function (sectors: ISalesSector[]) {
        const sector_id = this.selectedSectorFromResponse(sectors || []);
        this.selectedSector = sector_id;
      },
      immediate: true,
    },
    relatedPersons: {
      handler: function (persons: IObject[]) {
        if (persons.length > 0) {
          const salesPersonEmail = this.organizationData?.sales_poc;
          const relatedPerson = persons.find((x) =>
            x.label.includes(salesPersonEmail as string)
          );
          this.selectedRelatedPerson = relatedPerson?.value
            ? relatedPerson?.value
            : "";
        }
      },
      immediate: true,
    },
  },
});
</script>

<style lang="css" scoped>
.details-card {
  margin-top: 10px;
}
.form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.form-field {
  flex: 1 1 calc(50% - 10px);
  min-width: 300px;
}

@media (max-width: 768px) {
  .form-field {
    flex: 1 1 100%;
  }
}
</style>
