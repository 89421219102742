<template>
  <div class="dashboard">
    <div class="emoji">🌟</div>
    <MTypography type="headline"> Greetings, Esteemed {{ user }}! </MTypography>
    <MTypography>
      Step into the Aeroglobe Portal Dashboard—a realm tailored just for you! 🚀
    </MTypography>
    <MTypography>Welcome aboard! 👋</MTypography>
  </div>
</template>

<script lang="ts">
import { MTypography } from "@aeroglobe/ag-core-ui";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DashboardGreetingsCard",
  props: {
    user: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.dashboard {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 10px;
}

.emoji {
  font-size: 36px;
  margin-bottom: 10px;
}

.welcome-aboard {
  font-size: 20px;
  font-weight: bold;
}
</style>
