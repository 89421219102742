<template>
  <div v-if="isLoading">
    <ShimmerCard />
    <ShimmerCard />
  </div>

  <template v-else>
    <MCard class="m-card">
      <MTypography class="description" type="title">
        Account Details
      </MTypography>
      <hr />
      <div class="info-grid">
        <MTypography class="description" type="title"> Name: </MTypography>
        <MTypography class="description" type="body">
          {{ agent?.full_name || "-" }}
        </MTypography>
      </div>
      <div class="info-grid">
        <MTypography class="description" type="title"> Email: </MTypography>
        <MTypography class="description" type="body">
          {{ agent?.email || "-" }}
        </MTypography>
      </div>
    </MCard>

    <MCard class="m-card">
      <MTypography class="description" type="title"> Permissions </MTypography>
      <MTypography class="description" type="body">
        Update your Permissions here
      </MTypography>
      <hr />
    </MCard>

    <MAccordion
      :initial-open="false"
      v-for="(permissionType, index) in permissionsTypes"
      :key="index"
    >
      <template #title>
        <AgIconInfoBar
          class="section-title"
          test-id=""
          :mPrepandIcon="accordionIcon(permissionType)"
          :title="capitalizeFirstLetter(permissionType)"
        />
      </template>
      <template #content>
        <div class="permissions-container">
          <div
            v-for="(permission, index) in systemPermissions[permissionType]"
            :key="index"
          >
            <permission-item
              :value="permission.permission_code"
              v-model="selectedPermissions"
              :label="permission.permission_name"
            />
          </div>
        </div>
      </template>
    </MAccordion>

    <div class="bottom_inline_btn">
      <MButton
        :disabled="$store.getters.updatingAgentPermissions"
        @click="onCancelPermissions"
        >Cancel
      </MButton>
      <MButton
        class="m-buttons"
        :disabled="$store.getters.updatingAgentPermissions"
        @click="updatePermissions"
        >Update
      </MButton>
    </div>
    <OTPInputModal
      :isOpen="otpDialog"
      @close="otpDialog = false"
      @submit="onPermissionsUpdateOTPVerification"
      :isSubmitLoading="isLoadingOTPVerificationSubmit"
      title="OTP Confirmation"
      instruction="Enter the 6-digit code sent to your email."
    />
  </template>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import PermissionItem from "@/modules/Agent/components/PermissionItem.vue";
import { PERMISSIONS_TYPE } from "@/modules/Permissions/utils/enums/PERMISSIONS_TYPE";
import { systemPermissionsByPermissionTypes } from "@/modules/Permissions/utils/helpers";
import OTPInputModal from "@/ag-flight-components/components/OTPModal.vue";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { Organization } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { IPermission } from "@/ag-portal-common/interfaces/permission.interface";
import { IAgentDetails } from "@/ag-portal-common/interfaces/agent.interface";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { MAccordion, MButton, MCard, MTypography } from "@aeroglobe/ag-core-ui";
import ShimmerCard from "@/modules/Hotel/components/HotelShimmerCard.vue";

export default defineComponent({
  name: "AgentView",
  components: {
    OTPInputModal,
    PermissionItem,
    ShimmerCard,
    MAccordion,
    MCard,
    MButton,
    MTypography,
  },
  computed: {
    systemPermissions(): {
      [key: string]: IPermission[];
    } {
      return systemPermissionsByPermissionTypes(
        this.$store.getters.systemPermissions
      );
    },
    isLoading(): boolean {
      return (
        this.$store.getters.isFetchingPermissions ||
        this.$store.getters.isFetchingAgent
      );
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
  },
  data() {
    return {
      ELEMENT_TYPES,
      agent: null as IUserV2 | null,
      otpDialog: false,
      otp: "",
      referenceId: "",
      permissionsTypes: [
        PERMISSIONS_TYPE.FLIGHTS,
        PERMISSIONS_TYPE.HOTELS,
        PERMISSIONS_TYPE.PAYMENTS,
        PERMISSIONS_TYPE.SOA,
        PERMISSIONS_TYPE.OTHERS,
      ],
      isLoadingOTPVerificationSubmit: false,
      selectedPermissions: [] as string[],
    };
  },
  async beforeMount() {
    if (this.organization) {
      const id = this.organization.organization_id;
      await this.$store.dispatch("fetchAgentById", {
        agentId: this.$route.params.id,
        organizationId: id,
      });

      this.setAgent();
    }

    if (this.agent) {
      await this.$store.dispatch("fetchPermissions", this.agent.role_sub_type);
    }
  },
  methods: {
    genTestId,
    capitalizeFirstLetter(permission: string): string {
      if (permission === "soa") {
        return "SOA";
      }
      return permission.slice(0, 1).toUpperCase() + permission.slice(1);
    },
    accordionIcon(permission: string): string {
      switch (permission) {
        case "payment":
          return "m-payment-management";

        case "soa":
          return "m-soa";

        case "hotel":
          return "m-hotel";

        case "flight":
          return "m-flight";

        case "others":
          return "m-details";

        default:
          return "";
      }
    },
    setAgent() {
      const agent = this.$store.getters.userAgent;
      if (agent) {
        this.agent = agent.agent.user as IUserV2;
        this.selectedPermissions = agent.permissions;
      }
    },
    onCancelPermissions() {
      this.selectedPermissions = this.$store.getters.userAgent.permissions;
      this.$router.go(-1);
    },
    onSuccessUpdatePermissions(refrenceId: string) {
      this.referenceId = refrenceId;
      this.otpDialog = true;
    },
    updatePermissions(): void {
      const userAgent = this.$store.getters.userAgent as IAgentDetails | null;
      const selectedPermissions = this.selectedPermissions;
      const selected: { [index: string]: boolean } = {};
      const unSelected: { [index: string]: boolean } = {};

      if (!userAgent) {
        return;
      }

      userAgent.permissions.forEach((permission) => {
        if (!selectedPermissions.includes(permission)) {
          unSelected[permission] = false;
        }
      });
      selectedPermissions.forEach((permission) => {
        if (!userAgent.permissions.includes(permission)) {
          selected[permission] = true;
        }
      });

      //   Check if 'can_view_all_payments' is selected
      const isCanViewAllPaymentsSelected = selectedPermissions.includes(
        "can_view_all_payments"
      );

      if (isCanViewAllPaymentsSelected) {
        // If 'can_view_all_payments' is selected, auto-select 'can_view_self_made_payment'
        if (!selectedPermissions.includes("can_view_self_made_payments")) {
          selected["can_view_self_made_payments"] = true;
          selectedPermissions.push("can_view_self_made_payments");
        }
      }
      //   Check if 'can_view_all_flight_bookings' is selected
      const isCanViewAllBookingsSelected = selectedPermissions.includes(
        "can_view_all_flight_bookings"
      );

      if (isCanViewAllBookingsSelected) {
        // If '' is selected, auto-select 'can_view_self_made_payment'
        if (
          !selectedPermissions.includes("can_view_self_made_flight_bookings")
        ) {
          selected["can_view_self_made_flight_bookings"] = true;
          selectedPermissions.push("can_view_self_made_flight_bookings");
        }
      }

      const isCanCreatePaymentSelected =
        selectedPermissions.includes("can_create_payment");
      if (isCanCreatePaymentSelected) {
        // If '' is selected, auto-select 'can_view_self_made_payment'
        if (!selectedPermissions.includes("can_view_self_made_payments")) {
          selected["can_view_self_made_payments"] = true;
          selectedPermissions.push("can_view_self_made_payments");
        }
      }

      const isCanviewHotelsSelected = selectedPermissions.includes(
        "can_view_all_bookings_of_hotel"
      );
      if (isCanviewHotelsSelected) {
        // If '' is selected, auto-select 'can_view_self_made_payment'
        if (
          !selectedPermissions.includes(
            "can_view_only_self_made_bookings_of_hotel"
          )
        ) {
          selected["can_view_only_self_made_bookings_of_hotel"] = true;
          selectedPermissions.push("can_view_only_self_made_bookings_of_hotel");
        }
      }
      const finalSelectedPermissions = { ...selected, ...unSelected };

      if (Object.keys(finalSelectedPermissions).length === 0) {
        return;
      }

      this.$store.dispatch("updateAgentPermissions", {
        agentId: userAgent.agent.agent_id,
        permissions: finalSelectedPermissions,
        callback: this.onSuccessUpdatePermissions,
      });
    },
    disableOTPDialog() {
      this.otpDialog = false;
    },
    onPermissionsUpdateOTPVerification(otp: string) {
      const body = {
        reference: this.referenceId,
        otp,
      };
      this.isLoadingOTPVerificationSubmit = true;
      this.$store.dispatch("verificationOnPermissionsChange", {
        body,
        callback: () => (this.isLoadingOTPVerificationSubmit = false),
        onSuccessCallback: this.disableOTPDialog,
      });
    },
  },
});
</script>

<style scoped lang="css">
.permissions-heading {
  margin-bottom: 10px;
}

.section-title {
  margin: 0;
  border: none;
  padding: 0;
}
.m-card {
  margin-bottom: 15px;
}
.m-buttons {
  margin-left: 10px !important;
}
.permissions-container {
  display: grid;
  margin-top: 10px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

@media (max-width: 768px) {
  .permissions-container {
    grid-template-columns: 1fr;
  }
}
.info-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2px 16px;
  align-items: center;
}
</style>
