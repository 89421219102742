import { RouteRecordRaw } from "vue-router";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import PaymentList from "@/modules/Payments/views/PaymentList.vue";
import { PATH } from "@/ag-portal-common/constants/path";
import CreatePayment from "@/modules/Payments/views/CreatePayment.vue";
import DigitalPaymentsChart from "@/modules/Payments/views/DigitalPaymentsBySectors.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "payments",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.PAYMENTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.PAYMENTS.PERMISSIONS,
    },
    component: PaymentList,
  },
  {
    path: PATH.CREATE_PAYMENT,
    name: "createPayment",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.CREATE_PAYMENT.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.CREATE_PAYMENT.PERMISSIONS,
    },
    component: CreatePayment,
  },
  {
    path: PATH.PAYMENT_DETAIL,
    name: "paymentDetail",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.PAYMENTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.PAYMENTS.PERMISSIONS,
    },
    component: PaymentList,
  },
];

export default routes;
