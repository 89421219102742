<template>
  <TourDetailShimmer v-if="isFetchingFDGBookingDetail" />
  <div v-else class="flight-booking-details">
    <ag-row>
      <ag-column xs="12" sm="12" md="8" lg="9">
        <MCard class="flight-booking-title-card margin-bottom-20">
          <MTypography class="flight-booking-title-text" type="headline">{{
            title
          }}</MTypography>
          <MChip :variant="renderStatusSeverity">
            {{ renderStatus(bookingStatus) }}
          </MChip>
        </MCard>

        <MCard class="margin-bottom-20">
          <MTypography type="title">Flight Details</MTypography>
          <hr />
          <FlightDetailAccordion
            v-for="(item, idx) in groupFlightLegs"
            :key="idx"
            :flight-leg="item"
            :index="idx"
          />
        </MCard>
        <MCard
          class="margin-bottom-20"
          v-for="(item, index) in additionalFlightLegDetails"
          v-bind:key="index"
        >
          <MTypography type="title"
            >Additional Services on Flight {{ index + 1 }}</MTypography
          >
          <hr />
          <AdditionalDetailsCard
            :has-meal="item.hasMeal"
            :luggage-info="item.baggageInfo"
          />
        </MCard>

        <MCard class="margin-bottom-20">
          <div class="passengers-card-title">
            <MTypography type="title">Passengers</MTypography>
            <MButton
              v-if="showUpdatePassengerBtn"
              @click="showUpdatePassengers = true"
              >Update Passengers</MButton
            >
          </div>
          <hr />
          <UpdateGroupFlightPassengers
            v-if="showUpdatePassengers"
            @close="onCloseUpdatePassengerDialog"
            title="Update Passengers"
          />

          <PassengerItem
            v-else
            v-for="(item, idx) in passengers"
            :passenger="item"
            v-bind:key="idx"
          />
        </MCard>
      </ag-column>
      <ag-column xs="12" sm="12" md="4" lg="3">
        <MCard class="margin-bottom-20">
          <PriceSummary :priceInfoCells="getPriceInfo" />
          <div class="paynow-btn-container" v-if="showPaynowBtn">
            <MButton
              :disabled="isOTPLoading"
              @click="generateOTPForGroupFlightBooking"
              >Pay Remaining Amount</MButton
            >
          </div>
        </MCard>
        <MCard class="margin-bottom-20">
          <MTypography type="title"> Booking Voucher </MTypography>
          <div class="paynow-btn-container">
            <MButton
              :disabled="$store.getters.isDownloadingTourVoucher"
              @click="onDownloadVoucherHandler()"
            >
              Download Voucher
            </MButton>
          </div>
        </MCard>
        <MCard v-if="canCancel(bookingStatus)">
          <MTypography type="title"> Cancel Booking </MTypography>
          <div class="paynow-btn-container">
            <MButton
              :disabled="$store.getters.isOTPVerificationInProgress"
              @click="onCancelBookingHandler()"
            >
              Cancel
            </MButton>
          </div>
        </MCard>
      </ag-column>
    </ag-row>
  </div>
  <OTPInputModal
    :isOpen="showOtpModal"
    @close="showOtpModal = false"
    @submit="handleSubmitVerifyOtp"
    :isSubmitLoading="isGroupFlightsPaynowLoading"
    title="OTP Confirmation"
    instruction="Enter the 6-digit code sent to your email."
  >
    <template #component>
      <MFinancialProfileCombobox
        class="fp-combo"
        v-model:inputValue="financialProfileInput"
        label="Select Financial Profile"
        itemValue="value"
        itemLabel="label"
        :disabled="isFinancialProfileFetching"
        :options="financialProfileOptions"
        :hasError="!!fpErrorMessage"
        :errorMessage="fpErrorMessage"
      />
    </template>
  </OTPInputModal>
  <OTPInputModal
    :isOpen="showCancelOtpModal"
    @close="showCancelOtpModal = false"
    @submit="handleCancel"
    :isSubmitLoading="isGroupFlightsCancelLoading"
    title="OTP Confirmation"
    instruction="Enter the 6-digit code sent to your email."
  >
    <template #component>
      <div class="otp-component">
        <MTypography type="body" class="otp-warning"> Note: </MTypography>
        <MTypography type="body">
          Kindly note that the token amount is non-refundable.
        </MTypography>
      </div>
    </template>
  </OTPInputModal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IFDGItem } from "@/interfaces/fdg.interface";
import AgDuration from "@/ag-portal-common/components/AgDuration.vue";
import FlightDetailAccordion from "@/ag-flight-components/components/FlightBooking/FlightDetailAccordion.vue";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import {
  AdditionalFlightDetails,
  FlightLeg,
} from "@/ag-flight-components/types";

import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";
import AdditionalDetailsCard from "@/ag-flight-components/components/FlightBooking/AdditionalDetailsCard.vue";
import { getBaggageInfo } from "@/modules/Booking/utils";
import { formatGroupFlightTitle } from "../utils";
import {
  MTypography,
  MCard,
  MChip,
  MButton,
  MFinancialProfileCombobox,
} from "@aeroglobe/ag-core-ui";
import TourDetailShimmer from "../components/TourDetailShimmer.vue";
import PriceSummary from "@/modules/FDG/components/PriceSummary.vue";
import { PAX_TYPES } from "@/ag-portal-common/constants/paxTypes";
import {
  formatNumber,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import NATIONALITIES from "@/ag-portal-common/constants/nationalities";
import { PAX_RELATIONS } from "@/ag-portal-common/constants/paxRelations";
import { FORMAT_DD_MMM } from "@/ag-portal-common/constants/dateTimeFormats";
import {
  IFDGBookingDetail,
  IFDGBookingPassenger,
} from "@/interfaces/fdgBookingDetail.interface";
import { IPriceSummary } from "@/modules/FDG/interface/priceSummary.interface";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";
import _ from "lodash";
import UpdateGroupFlightPassengers from "@/modules/FDG/components/UpdateGroupFlightPassengers.vue";
import PassengerItem from "@/modules/FDG/components/PassengerItem.vue";
import { getCurrencyFormatter } from "@/ag-flight-components/utils";
import OTPInputModal from "@/ag-flight-components/components/OTPModal.vue";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { FinancialProfile } from "@/interfaces/agentState.interface";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import {
  FDG_BOOKING_STATUSES,
  FDG_BOOKING_STATUSES_MAPPING,
} from "../constants";

export default defineComponent({
  name: "FlightItenaryDErew",
  components: {
    FlightDetailAccordion,
    AdditionalDetailsCard,
    MTypography,
    MCard,
    PriceSummary,
    TourDetailShimmer,
    UpdateGroupFlightPassengers,
    MButton,
    PassengerItem,
    OTPInputModal,
    MFinancialProfileCombobox,
  },
  data() {
    return {
      items: [],
      title: "",
      passengerCount: 1,
      amountYouPay: 0,
      amountCurrency: "",
      showUpdatePassengers: false,
      showOtpModal: false,
      financialProfileInput: "",
      fpErrorMessage: "",
      showCancelOtpModal: false,
    };
  },
  methods: {
    formatGroupFlightTitle,
    getCurrencyFormatter,
    async onCancelBookingHandler() {
      const booking_id = this.bookingId;
      await this.$store.dispatch("generateGroupFlightCancelOTP", {
        booking_id,
        callback: () => (this.showCancelOtpModal = true),
      });
    },
    onDownloadVoucherHandler() {
      return this.$store.dispatch("downloadTourVoucher", this.bookingId);
    },
    canCancel(status: FDG_BOOKING_STATUSES) {
      return (
        status === FDG_BOOKING_STATUSES.PROCESSING.toUpperCase() ||
        status === FDG_BOOKING_STATUSES.INITIATED.toUpperCase() ||
        status === FDG_BOOKING_STATUSES.CONFIRMED.toUpperCase()
      );
    },
    onCloseUpdatePassengerDialog() {
      this.showUpdatePassengers = false;
    },
    handlePaynow(otp: string) {
      const payload = {
        booking_id: this.bookingId,
        financial_profile_public_id: this.financialProfileInput,
        otp: otp,
        otp_reference_id: this.otpReferenceId,
      };
      this.$store.dispatch("groupFlightsPaynow", {
        payload,
        callback: () => {
          this.showOtpModal = false;
          this.showUpdatePassengers = false;
          this.$store.dispatch("fetchGroupFlightBookingDetail", this.bookingId);
        },
      });
    },
    handleCancel(otp: string) {
      const payload = {
        booking_id: this.bookingId,
        otp: otp,
        otp_reference_id: this.otpReferenceId,
      };
      this.$store.dispatch("groupFlightsCancel", {
        payload,
        callback: () => {
          this.showCancelOtpModal = false;
          this.$store.dispatch("fetchGroupFlightBookingDetail", this.bookingId);
        },
      });
    },
    handleSubmitVerifyOtp(otp: string) {
      this.fpErrorMessage = "";
      if (this.financialProfileInput) {
        this.handlePaynow(otp);
      } else {
        this.fpErrorMessage = "Please select financial profile";
      }
    },
    async generateOTPForGroupFlightBooking() {
      const booking_id = this.bookingId;
      await this.$store.dispatch("generateGroupFlightBookingOTP", {
        booking_id,
        callback: () => (this.showOtpModal = true),
      });
    },
    renderStatus(bookingStatus: FDG_BOOKING_STATUSES): string {
      return bookingStatus.replaceAll("_", " ");
    },
  },
  computed: {
    AgDuration() {
      return AgDuration;
    },
    BOOKING_TYPES() {
      return BOOKING_TYPES;
    },
    NOTIFICATION_MESSAGES() {
      return NOTIFICATION_MESSAGES;
    },
    groupFlightLegs(): FlightLeg[] {
      const isFetchingFDGBookingDetail = this.$store.getters.fdgBookingDetail;
      return isFetchingFDGBookingDetail?.flightLegItems || [];
    },
    renderStatusSeverity() {
      switch (this.bookingStatus) {
        case FDG_BOOKING_STATUSES.CANCELED:
          return "error";
        case FDG_BOOKING_STATUSES.CONFIRMED:
          return "info";
        case FDG_BOOKING_STATUSES.ISSUANCE_IN_PROGRESS:
          return "success";
        case FDG_BOOKING_STATUSES.ISSUED:
          return "success";
        default:
          return "info";
      }
    },
    isFetchingFDGBookingDetail(): boolean {
      return this.$store.getters.isFetchingFDGBookingDetail;
    },
    renderBookingId(): string {
      return this.$route.params.id as string;
    },
    TOUR_TYPES() {
      return TOUR_TYPES;
    },
    PAX_RELATIONS() {
      return PAX_RELATIONS;
    },
    passengers(): IFDGBookingPassenger[] {
      const fdgBookingDetails: IFDGBookingDetail =
        this.$store.getters.fdgBookingDetail;
      return fdgBookingDetails?.passengers || [];
    },

    fdgBookingDetail(): IFDGBookingDetail {
      return this.$store.getters.fdgBookingDetail;
    },
    isConfirmFDGBooking(): boolean {
      return this.$store.getters.isConfirmFDGBooking;
    },
    isConfirmFDGBookingWithoutTravelerDetails(): boolean {
      return this.$store.getters.isConfirmFDGBookingWithoutTravelerDetails;
    },
    PAX_TYPES() {
      return PAX_TYPES;
    },
    NATIONALITIES() {
      return NATIONALITIES;
    },
    bookingId(): string {
      return this.$route.params.id as string;
    },
    getLegs(): IFDGItem[] {
      let fdgBookingDetail: IFDGBookingDetail =
        this.$store.getters.fdgBookingDetail;
      return fdgBookingDetail.items || [];
    },
    getPriceInfo(): IPriceSummary[] {
      let fdgBookingDetail: IFDGBookingDetail | null =
        this.$store.getters.fdgBookingDetail;
      let startDate = fdgBookingDetail?.startDate || new Date();

      const totalPriceTitle = this.isIssued
        ? "TOTAL YOU HAVE PAID"
        : "REMAINING AMOUNT";
      let priceYouPayNowValue =
        (fdgBookingDetail?.totalFare?.value || 0) -
        (fdgBookingDetail?.tokenFare?.value || 0);
      if (this.isIssued) {
        priceYouPayNowValue = fdgBookingDetail?.totalFare?.value || 0;
      }

      return [
        {
          info: `${getFormattedDateTime(startDate, FORMAT_DD_MMM)} | ${
            fdgBookingDetail?.items[0].itemName
          }`,
          amount: `${this.getCurrencyFormatter(
            fdgBookingDetail?.currency
          ).format(fdgBookingDetail?.bookingPricePerPerson || 0)}`,
          isTotalPriceCell: false,
        },
        {
          info: `Passenger(s) Count x ${fdgBookingDetail?.numberOfGuests}`,
          amount: `${this.getCurrencyFormatter(
            fdgBookingDetail?.totalFare?.currency
          ).format(fdgBookingDetail?.totalFare?.value || 0)}`,
          isTotalPriceCell: false,
        },
        {
          info: "Paid Token Amount",
          amount: `${this.getCurrencyFormatter(
            fdgBookingDetail?.tokenFare?.currency
          ).format(fdgBookingDetail?.tokenFare?.value || 0)}`,
          isTotalPriceCell: false,
        },
        {
          info: totalPriceTitle,
          amount: `${this.getCurrencyFormatter(
            fdgBookingDetail?.totalFare?.currency
          ).format(priceYouPayNowValue || 0)}`,
          isTotalPriceCell: true,
        },
      ];
    },
    additionalFlightLegDetails(): AdditionalFlightDetails[] {
      const fdgDetail = this.$store.getters.fdgBookingDetail;
      const flightLegItems = fdgDetail?.flightLegItems || [];
      const details = flightLegItems.map((leg: FlightLeg) => {
        const baggageInfo = getBaggageInfo(leg.baggage_info);
        const hasMeal: boolean = leg.has_meal;
        const details: AdditionalFlightDetails = {
          hasMeal,
          baggageInfo,
        };
        return details;
      });
      return details;
    },
    isOTPLoading(): boolean {
      return this.$store.getters.isOTPLoading;
    },
    otpReferenceId(): string {
      return this.$store.getters.groupFlightsOTPReference;
    },
    isGroupFlightsPaynowLoading(): boolean {
      return this.$store.getters.isGroupFlightsPaynowLoading;
    },
    isGroupFlightsCancelLoading(): boolean {
      return this.$store.getters.isGroupFlightsCancelLoading;
    },
    financialProfiles(): FinancialProfile[] | null {
      return this.$store.getters[AUTH_GETTERS.FINANCIAL_PROFILES];
    },
    financialProfileOptions(): FPComboboxOptions[] {
      const financialProfiles =
        this.$store.getters[AUTH_GETTERS.FINANCIAL_PROFILES];
      return financialProfiles?.map((fp: IFinancialProfile) => {
        const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase();
        const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
        return {
          id: fp?.platform_id,
          label: fp?.financial_profile_name,
          value: fp?.public_id,
          isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
          status: fp?.status,
          sector: sector,
          type: planType,
        };
      });
    },
    bookingStatus(): FDG_BOOKING_STATUSES {
      return this.fdgBookingDetail.status;
    },
    isFinancialProfileFetching(): boolean {
      return this.$store.getters.isFetchingFinancialProfiles;
    },
    showPaynowBtn(): boolean {
      const status = this.bookingStatus;
      return [FDG_BOOKING_STATUSES.CONFIRMED].includes(status);
    },
    showUpdatePassengerBtn(): boolean {
      const status = this.bookingStatus;
      return [
        FDG_BOOKING_STATUSES.PROCESSING,
        FDG_BOOKING_STATUSES.CONFIRMED,
      ].includes(status);
    },
    isIssued(): boolean {
      const status = this.bookingStatus;
      return [
        FDG_BOOKING_STATUSES.ISSUANCE_IN_PROGRESS,
        FDG_BOOKING_STATUSES.ISSUED,
      ].includes(status);
    },
  },
  props: {
    ietnaryDetail: {
      type: Object as PropType<IFDGItem>,
    },
  },
  watch: {
    fdgBookingDetail: {
      handler(value) {
        if (value?.items?.length) {
          this.title = this.formatGroupFlightTitle(value?.items);
        }
      },
    },
  },
  beforeMount() {
    this.$store.dispatch("fetchGroupFlightBookingDetail", this.bookingId);
  },
  async mounted() {
    let defaultFinancialProfile = this.financialProfiles?.find(
      (item) => item.is_default
    );

    if (defaultFinancialProfile) {
      this.financialProfileInput = defaultFinancialProfile.public_id;
    }
  },
});
</script>

<style lang="css" scoped>
.margin-bottom-20 {
  margin-bottom: 20px;
}
.flight-booking-title-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .flight-booking-title-text {
    font-weight: 900;
  }
}
.seat-available-label {
  background: var(--m-secondary-color);
  border-radius: 14px;
  padding: 4px 10px;
  width: max-content;
  color: var(--m-light-color);
  font-weight: bold;
  font-size: 14px;
}
.seat-fare {
  .fare {
    font-weight: 600;
    text-align: center;
  }
  .per-pax-text {
    display: block;
    font-size: 12px;
    text-align: center;
    color: var(--m-secondary-color);
    line-height: 12px;
  }
}

.counter_wrap {
  display: flex;
  align-items: center;
  align-items: center;
  margin-top: 10px;
  .l_count {
    min-width: 40px;
    text-align: center;
    font-weight: 800;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .counter-btn {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    min-width: 50px;
    max-height: 50px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    text-align: center;
    padding: 0;
    text-align: center;
    font-size: 16px;
    .icon_wrap {
      display: none;
    }
    span {
      padding-right: 0px !important;
    }
  }
}

.token-percentage {
  font-weight: 900;
  font-size: 18px;
}

.m-icon.m-traveler-count-plus-btn-disabled {
  cursor: not-allowed;
  stroke: var(--m-scrollbar-thumb-background-color);
}

.m-icon.m-traveler-count-plus-btn-disabled {
  svg > rect {
    stroke: var(--m-scrollbar-thumb-background-color);
  }
}

.passenger-counter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  .counter-title {
    text-align: center;
  }
}
.amount-you-pay-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
  .amount {
    font-size: 18px;
    font-weight: 800;
  }
}
.book-now-btn-container {
  text-align: center;
  margin-top: 40px;
}
.update-passenger-btn-card {
  display: flex;
  justify-content: center;
}

.passengers-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.paynow-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1cap;
}
.otp-warning {
  color: red;
}
.otp-component {
  display: flex;
}
</style>
