<template>
  <MDialog v-model="isMdialogOpen" hide-header hide-footer>
    <template #content>
      <div class="dialog-box">
        <h2>Great! 🚀</h2>
        <p>Your documents have been successfully uploaded.</p>
        <p>
          Kindly take a moment to relax while one of our support representatives
          verifies your documents.
        </p>
      </div>
      <br />
      <div class="btn_container">
        <router-link to="/">
          <AGButton>Let's Explore Aeroglobe Portal</AGButton>
        </router-link>
      </div>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UploadDocumentSuccessDialog",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    type: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      isMdialogOpen: this.isOpen,
    };
  },
  watch: {
    isOpen(newVal) {
      this.isMdialogOpen = newVal;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("closeModal");
      }
    },
  },
});
</script>
<style scoped>
.dialog-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}
.btn_container {
  display: flex;
  justify-content: center;
}
</style>
