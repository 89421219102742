import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_json_pretty = _resolveComponent("vue-json-pretty")!
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createBlock(_component_MDialog, {
    title: "Logs",
    modelValue: _ctx.isMdialogOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isMdialogOpen) = $event)),
    onClose: _ctx.handleClose,
    "max-width": "1000"
  }, {
    content: _withCtx(() => [
      _createVNode(_component_vue_json_pretty, {
        data: _ctx.jsonData,
        "show-icon": true,
        "show-line-number": true
      }, null, 8, ["data"])
    ]),
    _: 1
  }, 8, ["modelValue", "onClose"]))
}