import {
  IAGErrorResponse,
  IAGResponse,
} from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { ActionContext, ActionTree } from "vuex";
import { ISectorState } from "@/ag-portal-common/interfaces/sectorState.interface";
import SectorService from "@/modules/Sector/services/sector.service";
import { SECTOR_ANALYTICS_EVENTS } from "../constants/analyticsEvents";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import analyticServices from "@/analytic.service";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { SectorProductivityParamsRequestModel } from "../models/FetchSectorProductivityParams.request";
import { StoreState } from "@/store/type";

const actions: ActionTree<ISectorState, StoreState> = {
  async fetchSectors(context: ActionContext<ISectorState, StoreState>) {
    const methodName = "actions.fetchSectors";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const sectorService = new SectorService();
      const response: IAGResponse = await sectorService.getAll();
      context.commit("setSectorLoading", true);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("saveSectors", response.data);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
    } finally {
      context.commit("setSectorLoading", false);
    }
  },

  // *********** Get Sector Stats ***********
  async getSectorStats(
    context: ActionContext<ISectorState, StoreState>,
    sectorId
  ) {
    const methodName = "actions.getOrganizationStats";
    let response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setSectorStatsLoading", true);
      context.commit("setSectorStats", null);
      context.commit("setSectorProductivity", null);
      const sectorService = new SectorService();
      response = await sectorService.getSectorStats(sectorId);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setSectorStats", response.data?.data);
        analyticServices.logActionEvent(
          SECTOR_ANALYTICS_EVENTS.SECTOR_STATS_FETCHED,
          {
            sectorId,
          }
        );
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          "Sector statistics fetched successfully";
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setSectorStatsLoading", false);
    }
    notificationService.triggerNotification();
  },

  // *********** Get Sector Productivity ***********
  async getSectorProductivity(
    context: ActionContext<ISectorState, StoreState>,
    {
      sectorId,
      payload,
    }: { payload: SectorProductivityParamsRequestModel; sectorId: string }
  ) {
    const methodName = "actions.getOrganizationStats";
    let response: IAGResponse = {
      success: false,
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setSectorProductivityLoading", true);
      context.commit("setSectorProductivity", null);
      const sectorService = new SectorService();
      response = await sectorService.getSectorProductivity(sectorId, payload);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setSectorProductivity", response.data?.data);
        analyticServices.logActionEvent(
          SECTOR_ANALYTICS_EVENTS.SECTOR_PRODUCTIVIY_FETCHED,
          {
            sectorId,
            ...payload,
          }
        );
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          "Sector productivity fetched successfully";
      } else {
        loggerService.logInfo(
          `${methodName}: ${LOG_LABELS.ERROR}`,
          response.error
        );
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    } finally {
      context.commit("setSectorProductivityLoading", false);
    }
    notificationService.triggerNotification();
  },
};

export default actions;
