<template>
  <ag-row>
    <!-- Origin and Destination columns -->
    <ag-column xs="12" sm="12" md="7">
      <div
        id="flight_search_box"
        class="combine_flight_search combobox-container"
      >
        <ag-row test-id="">
          <ag-column xs="12" sm="12" md="6" cols="12">
            <MAirportComboBox
              :inputValue="selectedOriginIata"
              label="From"
              :options="renderOriginPlaces()"
              prepend-icon="m-flight-departure-icon"
              placeholder="Leave from"
              :is-loading="loadingOriginAirports"
              @update:inputValue="handleSelectOriginItem"
              @update:inputText="handleOriginMAirportInputText"
              :has-error="!!errors?.origin"
              :error-message="errors?.origin"
            />
          </ag-column>
          <div
            class="m-swap-flights-icon-container m-index-level-one"
            @click="swapFields"
            :class="{ flipped: swapIconFlipped }"
            variant="link"
          >
            <MIcon name="m-swap-flight-icon" />
          </div>
          <ag-column xs="12" sm="12" md="6" cols="12">
            <MAirportComboBox
              :inputValue="selectedDestinationIata"
              label="To"
              :options="renderDestinationPlaces()"
              prepend-icon="m-flight-arrival-icon"
              placeholder="Going to"
              :is-loading="loadingDestinationAirports"
              @update:inputValue="handleSelectDestinationItem"
              @update:input-text="handleDestinationMAirportInputText"
              :has-error="!!errors?.destination"
              :error-message="errors?.destination"
              :foucsInput="nextFocusInputDest"
            />
          </ag-column>
        </ag-row>
      </div>
    </ag-column>

    <ag-column xs="12" sm="12" md="5" cols="12">
      <div>
        <ag-row>
          <ag-column xs="12" sm="12" md="6" cols="12">
            <MDatePicker
              label="Departure Date"
              :startDate="startDate"
              :endDate="endDate"
              :min-date="minimumDate"
              @onRangeDateChange="onRangeDateChange"
              @rangeStartDate="onRangeStartDepartureDate"
              placeholder="Departing"
              dateType="startDate"
              :hasRange="isReturnFlight"
              :multiCalendar="isMultiCalendar"
              :foucsInput="nextFocusDatePicker"
              position="center"
              :auto-position="false"
              @handleDatePickerFocused="handleDepartureDatePickerFocused"
              :isFocused="isDepartureDatePickerFocused"
              :error="errors?.departure_date"
            />
          </ag-column>
          <ag-column xs="12" sm="12" md="6" cols="12">
            <MDatePicker
              label="Return Date"
              :startDate="startDate"
              :endDate="endDate"
              :min-date="minimumDate"
              @onRangeDateChange="onRangeDateChange"
              @rangeStartDate="onRangeStartArrivalDate"
              placeholder="Returning"
              dateType="endDate"
              :disabled="disableReturnDatePicker"
              :multiCalendar="isMultiCalendar"
              :hasRange="isReturnFlight"
              position="right"
              :auto-position="false"
              @handleDatePickerFocused="handleReturnDatePickerFocused"
              :isFocused="isReturnDatePickerFocused"
              :error="errors?.return_date"
              ref="MReturnDatePickerRef"
            />
          </ag-column>
        </ag-row>
      </div>
    </ag-column>

    <ag-column xs="12" sm="12" md="7" cols="12">
      <AgRow>
        <ag-column xs="12" sm="12" md="6" cols="12">
          <MTravelerCountBox
            label="Travelers"
            prepend-icon="m-traveler-count-icon"
            :adult-count="adultCount"
            :child-count="childCount"
            :infant-count="infantCount"
          >
            <template #items>
              <MTravelerCountBoxItem
                name="adultCount"
                label="Adult"
                icon="m-adult-traveler-icon"
                hint="(12 years and above)"
                :count="adultCount"
                @update:adultCount="updateAdultCount"
              />
              <MTravelerCountBoxItem
                name="childCount"
                label="Children"
                icon="m-child-traveler-icon"
                hint="(2 to 11 years)"
                :count="childCount"
                @update:childCount="updateChildCount"
              />
              <MTravelerCountBoxItem
                name="infantCount"
                label="Infants"
                icon="m-infant-traveler-icon"
                hint="(0 to less than 2)"
                :count="infantCount"
                @update:infantCount="updateInfantCount"
              />
            </template>
          </MTravelerCountBox>
        </ag-column>
        <ag-column xs="12" sm="12" md="6" cols="12">
          <slot name="financial-profile-combobox" />
        </ag-column>
      </AgRow>
    </ag-column>
    <ag-column xs="12" sm="12" md="5" cols="12">
      <ag-row>
        <ag-column xs="12" sm="12" md="6" cols="12">
          <MCombobox
            v-model:inputValue="selectedCabinClass"
            label="Cabin Class"
            itemValue="value"
            itemLabel="label"
            :options="CabinClassOptions"
            class="no-margin"
            focusedBorderEnabled
          />
        </ag-column>
        <ag-column
          xs="12"
          sm="12"
          md="6"
          cols="12"
          class="search-btn-container"
        >
          <MButton type="square" @click="onFlightSearch()">
            Search Flights
          </MButton>
        </ag-column>
      </ag-row>
    </ag-column>
  </ag-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { debounce } from "lodash";

import {
  MAirportComboBox,
  MDatePicker,
  MIcon,
  MCombobox,
  MButton,
  MTravelerCountBox,
  MTravelerCountBoxItem,
} from "@aeroglobe/ag-core-ui";
import { ROUTE_TYPE } from "../../enums/route_type";
import { Place } from "../../types/Place";
import { CabinClassOptions } from "@/ag-flight-components/constants";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { CABIN_CLASS } from "@/ag-flight-components/enums/cabin_class";
import _ from "lodash";
import { addDays, isBefore } from "date-fns";
import { useIsMobile } from "@aeroglobe/ag-core-ui";
import type { DatePickerInstance } from "@vuepic/vue-datepicker";

export default defineComponent({
  name: "NormalFlightSearch",
  events: ["validateSearchFeilds"],
  components: {
    MTravelerCountBox,
    MTravelerCountBoxItem,
    MAirportComboBox,
    MDatePicker,
    MCombobox,
    MIcon,
    MButton,
  },
  props: {
    onFlightSearch: {
      type: Function,
      required: true,
    },
    errors: {
      type: Object,
    },
  },
  data(): {
    originAirportQuery: string;
    destinationAirportQuery: string;
    selectedOriginIata: string;
    selectedDestinationIata: string;
    startDate: Date | null | string;
    endDate: Date | null;
    minimumDate: Date;
    CabinClassOptions: IObject[];
    selectedCabinClass: string;
    nextFocusInputDest: string;
    nextFocusDatePicker: string;
    isSwapBtnActive: boolean;
    swapIconFlipped: boolean;
    isInitialRender: boolean;
    isMobile: boolean;
    isDepartureDatePickerFocused: boolean;
    isReturnDatePickerFocused: boolean;
  } {
    return {
      originAirportQuery: "",
      destinationAirportQuery: "",
      selectedOriginIata: "",
      selectedDestinationIata: "",
      startDate: null,
      endDate: null,
      minimumDate: new Date(),
      CabinClassOptions,
      selectedCabinClass: "",
      nextFocusInputDest: "",
      nextFocusDatePicker: "",
      isSwapBtnActive: false,
      swapIconFlipped: false,
      isInitialRender: true,
      isMobile: false,
      isDepartureDatePickerFocused: false,
      isReturnDatePickerFocused: false,
    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const tripsParam = urlParams.get("trips");

    if (
      this.$store.state.flightModule?.destination &&
      this.$store.state.flightModule?.origin
    ) {
      this.$store.state.flightModule?.destination;
      this.$store.state.flightModule?.origin;
    } else if (tripsParam) {
      const tripsArray = tripsParam.split(",");
      const origin = tripsArray[0];
      const airports = this.$store.getters.airports as Array<Place>;
      let originState = airports.filter((x) => x.iata_code === origin);
      this.$store.commit("saveOrigin", originState[0]);

      const destination = tripsArray[1];
      let destinationState = airports.filter(
        (x) => x.iata_code === destination
      );
      this.$store.commit("saveDestination", destinationState[0]);
    }

    const { isMobile } = useIsMobile();
    this.isMobile = isMobile.value;
  },
  computed: {
    loadingOriginAirports(): boolean {
      return this.$store.getters.loadingOriginAirports;
    },
    loadingDestinationAirports(): boolean {
      return this.$store.getters.loadingDestinationAirports;
    },
    disableReturnDatePicker(): boolean {
      return this.$store.state.flightModule.route_type === ROUTE_TYPE.ONEWAY;
    },
    cabinClass(): CABIN_CLASS {
      return this.$store.state.flightModule.cabin_class;
    },
    isReturnFlight(): boolean {
      return this.$store.state.flightModule.route_type === ROUTE_TYPE.RETURN;
    },
    isAirportFetching(): boolean {
      return this.$store.getters.isAirportFetching;
    },
    adultCount(): number {
      return Number(this.$store.state.flightModule.adult_count);
    },
    childCount(): number {
      return Number(this.$store.state.flightModule.child_count);
    },
    infantCount(): number {
      return Number(this.$store.state.flightModule.infant_count);
    },
    startDateError(): string {
      return !this.startDate ? "Please select departure date" : "";
    },
    endDateError(): string {
      if (this.isReturnFlight) {
        return !this.endDate ? "Please select return date" : "";
      }
      return "";
    },
    selectedDepatureDate(): Date | null {
      return this.$store.state.flightModule?.departure_date;
    },
    selectedReturnDate(): Date | null {
      return this.$store.state.flightModule?.return_date;
    },
    selectedOrigin(): Place | null {
      return this.$store.state.flightModule?.origin;
    },
    selectedDeparture(): Place | null {
      return this.$store.state.flightModule?.destination;
    },
    isMultiCalendar(): boolean {
      return this.isMobile ? false : true;
    },
    ...mapState(["flightModule"]),
  },
  watch: {
    originAirportQuery: {
      handler: debounce(function (value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.searchOriginAirports(value);
      }, 300),
      immediate: true,
    },
    destinationAirportQuery: {
      handler: debounce(function (value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.searchDestinationAirports(value);
      }, 300),
      immediate: true,
    },
    selectedOriginIata: {
      handler: function (value) {
        if (!this.isInitialRender) {
          if (!this.isSwapBtnActive) {
            this.nextFocusInputDest = value;
          }
        }
      },
      immediate: false,
    },
    selectedDestinationIata: {
      handler: function (value) {
        if (!this.isSwapBtnActive) {
          this.nextFocusDatePicker = value;
        }
      },
      immediate: false,
    },
    selectedOrigin: {
      handler: function (value: Place) {
        this.selectedOriginIata = value?.iata_code || "";
      },
      immediate: true,
    },
    selectedDeparture: {
      handler: function (value: Place) {
        this.selectedDestinationIata = value?.iata_code || "";
      },
      immediate: true,
    },
    selectedDepatureDate: {
      handler: function (value: Date) {
        this.startDate = value;
      },
      immediate: true,
    },
    selectedReturnDate: {
      handler: function (value: Date) {
        this.endDate = value;
      },
      immediate: true,
    },
    selectedCabinClass: {
      handler: function (cabinClass: CABIN_CLASS) {
        this.changeCabinClass(cabinClass);
      },
    },
    cabinClass: {
      handler: function (cabinClass: CABIN_CLASS) {
        this.selectedCabinClass = cabinClass;
      },
      immediate: true,
    },
    startDate() {
      this.$emit("validateSearchFeilds");
    },
    endDate() {
      this.$emit("validateSearchFeilds");
    },
  },
  methods: {
    renderOriginPlaces() {
      const mergedAirports = [
        ...this.$store.getters.originAirports,
        ...this.$store.getters.airports,
      ] as Array<Place>;
      const airports = _.uniqBy(mergedAirports, "iata_code");

      const selectedDestination = this.$store.state.flightModule
        .destination as Place;
      if (selectedDestination) {
        return airports.filter(
          (x) => x.iata_code !== selectedDestination.iata_code
        );
      }
      return airports;
    },
    renderDestinationPlaces() {
      const mergedAirports = [
        ...this.$store.getters.destinationAirports,
        ...this.$store.getters.airports,
      ] as Array<Place>;
      const airports = _.uniqBy(mergedAirports, "iata_code");

      const selectedOrigin = this.$store.state.flightModule.origin as Place;
      if (selectedOrigin) {
        return airports.filter((x) => x.iata_code !== selectedOrigin.iata_code);
      }
      return airports;
    },
    getOrigin() {
      // const urlParams = new URLSearchParams(window.location.search);
      // const tripsParam = urlParams.get("trips");
      if (this.$store.state.flightModule.origin) {
        return this.$store.state.flightModule.origin;
      }
    },
    getDestination() {
      // const urlParams = new URLSearchParams(window.location.search);
      // const tripsParam = urlParams.get("trips");
      if (this.$store.state.flightModule.destination) {
        return this.$store.state.flightModule.destination;
      }
    },
    changeDepartureDate(newDate: Date) {
      this.$store.commit("saveDepartureDate", newDate);
    },
    changeReturnDate(newDate: Date | null) {
      this.$store.commit("saveReturnDate", newDate);
    },
    changeOrigin(origin: Place | null) {
      this.$store.commit("saveOrigin", origin);
    },
    changeDestination(destination: Place | null) {
      this.$store.commit("saveDestination", destination);
    },
    changeCabinClass(cabinClass: CABIN_CLASS) {
      this.$store.commit("saveCabinClass", cabinClass);
    },
    searchOriginAirportQuery(value: string) {
      this.originAirportQuery = value;
    },
    searchDestinationAirportQuery(value: string) {
      this.destinationAirportQuery = value;
    },
    searchOriginAirports(value: string) {
      if (value && value.length > 2) {
        this.$store.dispatch("fetchAirports", {
          location: value,
          type: "origin",
        });
      }
    },
    searchDestinationAirports(value: string) {
      if (value && value.length > 2) {
        this.$store.dispatch("fetchAirports", {
          location: value,
          type: "destination",
        });
      }
    },
    swapFields() {
      this.swapIconFlipped = !this.swapIconFlipped;
      this.isSwapBtnActive = true;
      const tempOrigin = this.$store.state.flightModule?.origin;
      const tempDestination = this.$store.state.flightModule?.destination;

      if (!tempOrigin || !tempDestination) {
        return;
      }

      const originAirports = this.$store.getters.originAirports;
      const destinationAirports = this.$store.getters.destinationAirports;

      this.$store.commit("saveOriginAirports", destinationAirports);
      this.$store.commit("saveDestinationAirports", originAirports);

      const tempOriginIata = this.selectedOriginIata;
      const tempDestinationIata = this.selectedDestinationIata;
      this.selectedOriginIata = tempDestinationIata;
      this.selectedDestinationIata = tempOriginIata;

      this.changeOrigin(tempDestination);
      this.changeDestination(tempOrigin);
    },
    handleOriginMAirportInputText(value: string) {
      if (value) {
        this.originAirportQuery = value;
      }
    },
    handleDestinationMAirportInputText(value: string) {
      if (value) {
        this.destinationAirportQuery = value;
      }
    },
    onRangeDateChange(dates: any) {
      if (this.isReturnFlight) {
        if (dates?.length > 1) {
          this.startDate = new Date(dates[0]);
          this.changeDepartureDate(dates[0]);
          this.endDate = new Date(dates[1]);
          this.changeReturnDate(dates[1]);
          this.minimumDate = new Date();
        }
      } else {
        this.changeDepartureDate(dates);
        this.startDate = new Date(dates);
        this.minimumDate = new Date();
      }

      this.isDepartureDatePickerFocused = false;
      this.isReturnDatePickerFocused = false;
    },
    onRangeStartDepartureDate(date: Date) {
      if (this.isReturnFlight) {
        this.startDate = date;
        this.endDate = null;
        this.isDepartureDatePickerFocused = false;
        this.isReturnDatePickerFocused = true;
      }
    },
    onRangeStartArrivalDate(date: Date) {
      if (this.isReturnFlight) {
        if (this.startDate) {
          if (isBefore(date, this.startDate as Date)) {
            this.changeDepartureDate(date);
            this.changeReturnDate(null);
            this.startDate = date;
            this.endDate = null;
          } else {
            this.changeReturnDate(date);
            this.endDate = date;
            this.closeReturnDatePicker();
          }
        } else {
          this.changeDepartureDate(date);
          this.changeReturnDate(null);
          this.startDate = date;
          this.endDate = null;
        }
      }
    },
    handleSelectOriginItem(origin: Place) {
      this.isSwapBtnActive = false;
      const iata_code = origin?.iata_code;
      this.selectedOriginIata = iata_code;
      this.changeOrigin(origin);
      this.$emit("validateSearchFeilds", iata_code);
    },
    handleSelectDestinationItem(destination: Place) {
      this.isSwapBtnActive = false;
      const iata_code = destination?.iata_code;
      this.selectedDestinationIata = iata_code;
      this.changeDestination(destination);
      this.$emit("validateSearchFeilds", iata_code);
    },

    updateAdultCount(value: number): void {
      this.$store.commit("saveAdultCount", value);
    },
    updateChildCount(value: number): void {
      this.$store.commit("saveChildCount", value);
    },
    updateInfantCount(value: number): void {
      this.$store.commit("saveInfantCount", value);
    },
    handleDepartureDatePickerFocused(value: boolean) {
      this.isDepartureDatePickerFocused = value;
    },
    handleReturnDatePickerFocused(value: boolean) {
      this.isReturnDatePickerFocused = value;
    },
    closeReturnDatePicker() {
      const mDatePicker = this.$refs.MReturnDatePickerRef as InstanceType<
        typeof MDatePicker
      > & {
        closeMenu: () => void;
      };

      if (mDatePicker) {
        mDatePicker.closeMenu();
      }
    },
  },
  mounted() {
    this.isInitialRender = true;
    this.nextFocusDatePicker = "";
    this.nextFocusInputDest = "";

    setTimeout(() => {
      this.isInitialRender = false;
    }, 1000);
  },
});
</script>

<style scoped lang="scss">
.combine_flight_search {
  position: relative;
  .flipped {
    transform: rotate(180deg);
  }
  .m-swap-flights-icon-container {
    background-color: #fff !important;
    border: 0.75px solid #ababab;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    max-height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border-radius: 50%;
    padding-top: 9px;
    padding-left: 9px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: rgb(228, 228, 228);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    svg > path {
      fill: #ababab;
    }

    .btn-loading {
      svg path {
        fill: #ababab !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .search-btn-container {
    display: flex;
    justify-content: center;
  }
}
</style>
