<template>
  <ag-row>
    <ag-column xs="12" sm="12" md="8" lg="9">
      <ShimmerCard card-height="350px" />
      <ShimmerCard card-height="244px" />
    </ag-column>
    <ag-column xs="12" sm="12" md="4" lg="3">
      <ShimmerCard card-height="244px" />
      <ShimmerCard card-height="500px" />
    </ag-column>
  </ag-row>
</template>

<script>
import { defineComponent } from "vue";
import ShimmerCard from "@/components/ShimmerCard.vue";

export default defineComponent({
  name: "TourDetailShimmer",
  components: { ShimmerCard },
});
</script>
