<template>
  <MProgress
    class="mprogress-alignment"
    v-if="isPaymentMethodLoaded"
    indeterminate
  />
  <MCard v-else>
    <AgNotFound
      v-if="!isAnyPaymentMethod"
      test-id=""
      heading="No Payment Methods Found"
      description=""
    />
    <Agdiv v-else>
      <MTypography type="title">Create New Payment</MTypography>
      <ag-row>
        <ag-column xs="12" sm="12" md="12">
          <MTypography type="label">Select Payment Method</MTypography>
          <ag-radio
            v-for="type in PaymentMethods"
            :key="type.payment_type"
            v-model="method"
            :testId="
              genTestId(ELEMENT_TYPES.RADIO_BUTTON, 'create-payment-method')
            "
          >
            <MCard
              class="options"
              v-if="type.payment_type === 'IBFT' && type.is_active === true"
            >
              <ag-radio-item
                :testId="
                  genTestId(ELEMENT_TYPES.RADIO_BUTTON, 'create-payment-IBFT')
                "
                :value="PAYMENT_METHODS_TYPES.IBFT"
                name="method"
                label="IBFT - Bank Transfer"
              ></ag-radio-item>
              <div v-if="bankDetails && isBank && bankDetails.bank_account_no">
                <MDataTable
                  :headers="bankTable"
                  :data="[bankDetails]"
                  :has-pagination="false"
                  :has-search="false"
                >
                </MDataTable>
              </div>

              <MTypography
                v-if="method === PAYMENT_METHODS_TYPES.IBFT"
                type="body"
                class="description"
              >
                {{
                  noteForPayment(
                    type.payment_fee_value || 0,
                    type.payment_type,
                    type.payment_fee_type,
                    type.currency
                  )
                }}
              </MTypography>
              <AgNotFound
                v-if="bankDetails?.bank_account_no === null && isBank"
                test-id=""
                heading="Please contact billing team to have your IBFT details updated"
                description=""
              />
            </MCard>

            <MCard
              v-if="type.payment_type === 'ONE_BILL' && type.is_active === true"
              class="options"
            >
              <ag-radio-item
                :testId="
                  genTestId(
                    ELEMENT_TYPES.RADIO_BUTTON,
                    'create-payment-ONE_BILL'
                  )
                "
                :value="PAYMENT_METHODS_TYPES.ONE_BILL"
                name="method"
                label="One Bill"
              ></ag-radio-item>
              <MTypography
                v-if="method === PAYMENT_METHODS_TYPES.ONE_BILL"
                type="body"
                class="description"
              >
                {{
                  noteForPayment(
                    type.payment_fee_value || 0,
                    type.payment_type,
                    type.payment_fee_type,
                    type.currency
                  )
                }}
              </MTypography>
            </MCard>
            <MCard
              v-if="
                type.payment_type === 'CREDIT_CARD' && type.is_active === true
              "
              class="options"
            >
              <ag-radio-item
                :testId="
                  genTestId(
                    ELEMENT_TYPES.RADIO_BUTTON,
                    'create-payment-CREDIT_CARD'
                  )
                "
                :value="PAYMENT_METHODS_TYPES.CREDIT_CARD"
                name="method"
                label="Debit/Credit Card"
              ></ag-radio-item>
              <MTypography
                v-if="method === PAYMENT_METHODS_TYPES.CREDIT_CARD"
                type="body"
                class="description"
              >
                {{
                  noteForPayment(
                    type.payment_fee_value || 0,
                    type.payment_type,
                    type.payment_fee_type,
                    type.currency
                  )
                }}
              </MTypography>
            </MCard>
          </ag-radio>
          <ag-heading
            class="color-red"
            v-if="errors?.method"
            :title="errors?.method"
            variant="p"
          />

          <form @submit.prevent="onSubmit" v-if="!isBank">
            <MTextfield
              v-model:inputValue.trim="amount"
              label="Amount"
              :hasError="errors?.amount"
              :errorMessage="errors?.amount"
              type="Number"
            />
            <MTypography
              v-if="amount && !isBank"
              type="body"
              class="amount-description"
            >
              Note: {{ paymentAmount() }}
            </MTypography>
            <MFinancialProfileCombobox
              v-model:inputValue="financialProfilePublicId"
              label="Financial Profiles"
              placeholder="Select Financial Profile"
              itemValue="value"
              itemLabel="label"
              :options="financialProfiles"
              isLargerDropdown
              focusedBorderEnabled
            />
            <m-textarea
              v-model:inputValue.trim="description"
              label="Description"
            />
            <ag-div class="d-flex justify-content-end">
              <MButton
                variant="filled"
                class="margin_right_20"
                @click="routeToPaymentList"
                :disabled="$store.getters.isCreatingPayment"
                >Cancel
              </MButton>
              <MButton
                type="filled"
                :disabled="$store.getters.isCreatingPayment"
                :is-loading="$store.getters.isCreatingPayment"
                behaviour="submit"
                >Create Payment
              </MButton>
            </ag-div>
          </form>
        </ag-column>
      </ag-row>
    </Agdiv>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PaymentsService from "@/modules/Payments/services/payments.service";
import {
  IAGErrorResponse,
  IAGResponse,
} from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { PAYMENT_METHODS_TYPES } from "@/ag-portal-common/enums/PAYMENT_METHODS_TYPES";
import {
  formatStringToRoutePath,
  genTestId,
  yupValidationErrorAsSchema,
} from "@/ag-portal-common/utils/helpers";
import { ValidationError } from "yup";
import { createPaymentValidationschema } from "@/modules/Payments/validations/createPaymentValidationSchema";
import { PATH } from "@/ag-portal-common/constants/path";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import FinancialProfileDropdown from "@/components/FinancialProfileDropdown.vue";
import analyticsService from "@/analytic.service";
import { PAYMENTS_ANALYTICS_EVENTS } from "@/modules/Payments/constants/anaylticsEvents";
import { BankDetails, Organization } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { MDataTable, MProgress, MTypography } from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";

interface IState {
  method: PAYMENT_METHODS_TYPES;
  financialProfilePublicId: string;
  bankDetails: BankDetails | null;
  description: string;
  amount: number;
  errors: IObject;
  isBank: boolean;
  bankTable: MDataTableHeader[];
  isPaymentMethodLoaded: boolean;
  isAnyPaymentMethod: boolean;
  PaymentMethods:
    | {
        payment_type: string;
        is_active: boolean;
        payment_fee_type: "PERCENTAGE" | "FIXED_FEE" | "FREE";
        payment_fee_value: number | null;
        minimum_payment: number | null;
        currency: string;
      }[]
    | null;
}

export default defineComponent({
  name: "CreatePayment",
  components: { MDataTable },
  computed: {
    isSectorDubai() {
      return localStorage.getItem("sector") === "Aeroglobe - Dubai";
    },
    ELEMENT_TYPES() {
      return ELEMENT_TYPES;
    },
    PAYMENT_METHODS_TYPES() {
      return PAYMENT_METHODS_TYPES;
    },

    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    financialProfiles(): FPComboboxOptions[] {
      let financialProfiles =
        this.$store.getters[AUTH_GETTERS.FINANCIAL_PROFILES];

      if (financialProfiles) {
        return financialProfiles?.map((fp: IFinancialProfile) => {
          const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase();
          const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
          return {
            id: fp?.platform_id,
            label: fp?.financial_profile_name,
            value: fp?.public_id,
            isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
            status: fp?.status,
            sector: sector,
            type: planType,
          };
        });
      } else {
        return [];
      }
    },
  },
  data(): IState {
    return {
      method: PAYMENT_METHODS_TYPES.IBFT,
      bankDetails: null,
      isAnyPaymentMethod: false,
      description: "",
      financialProfilePublicId: "",
      amount: Number(0),
      errors: {},
      isBank: true,
      isPaymentMethodLoaded: true,
      bankTable: [
        {
          title: "Bank Name",
          value: "bank_name",
          key: "bank_name",
          sortable: false,
        },
        {
          title: "Bank IBAN",
          value: "bank_iban",
          key: "bank_iban",
          sortable: false,
        },
        {
          title: "Bank Account No",
          value: "bank_account_no",
          key: "bank_account_no",
          sortable: false,
        },
        {
          title: "Bank Title",
          value: "bank_title",
          key: "bank_title",
          sortable: false,
        },
      ] as MDataTableHeader[],
      PaymentMethods: [],
    };
  },
  methods: {
    genTestId,
    async onSubmit() {
      this.errors = {};
      const payload = {
        amount: Number(this.amount),
        method: this.method,
        description: this.description,
        financial_profile_public_id: this.financialProfilePublicId,
      };
      let minValue = 0;
      for (const paymentMethod of this.PaymentMethods || []) {
        if (
          this.method === paymentMethod.payment_type &&
          paymentMethod.payment_fee_type === "FIXED_FEE"
        ) {
          minValue = paymentMethod.minimum_payment || 0;
        } else if (
          this.method === paymentMethod.payment_type &&
          paymentMethod.payment_fee_type === "PERCENTAGE"
        ) {
          minValue = paymentMethod.minimum_payment || 0;
        }
      }
      try {
        await createPaymentValidationschema(minValue).validate(payload, {
          abortEarly: false,
        });
        this.$store.dispatch("createPayment", {
          payload,
          callback: this.routeToPaymentDetail,
        });

        analyticsService.logActionEvent(
          PAYMENTS_ANALYTICS_EVENTS.PAYMENT_NEW,
          payload
        );
      } catch (ex) {
        if (ex instanceof ValidationError) {
          this.errors = yupValidationErrorAsSchema(ex);
        }
      }
    },
    paymentAmount() {
      let note = "";
      for (const paymentMethod of this.PaymentMethods || []) {
        if (
          this.method === paymentMethod.payment_type &&
          paymentMethod.payment_fee_type === "FIXED_FEE"
        ) {
          const amount = this.amount - (paymentMethod.payment_fee_value || 0);
          note += " " + amount.toFixed(2) + " " + paymentMethod.currency;
        } else if (
          // eslint-disable-next-line no-dupe-else-if
          this.method === paymentMethod.payment_type &&
          paymentMethod.payment_fee_type === "PERCENTAGE"
        ) {
          const percentageAmount =
            ((paymentMethod.payment_fee_value || 0) / 100) * this.amount;
          const amount = this.amount - percentageAmount;
          note += " " + amount.toFixed(2) + " " + paymentMethod.currency;
        }
      }
      note += " will be deposited in your account";
      return note;
    },
    noteForPayment(
      payment_fee_value: number,
      payment_type: string,
      charges: string,
      currency: string
    ) {
      let note = "Note: " + payment_fee_value;
      if (charges === "PERCENTAGE") {
        note += "%";
      } else if (charges === "FIXED_FEE") {
        note += " " + currency;
      } else {
        note += " " + currency;
      }

      note += " will be deducted in case of ";
      if (payment_type === "CREDIT_CARD") {
        note += "debit/credit card";
      } else if (payment_type === "ONE_BILL") {
        note += "one bill";
      } else {
        note += "IBFT";
      }
      return note;
    },

    updateFinancialProfilePublicId(value: string) {
      this.financialProfilePublicId = value;
    },
    routeToPaymentDetail(id: string) {
      this.$router.push(formatStringToRoutePath(PATH.PAYMENT_DETAIL, { id }));
    },
    routeToPaymentList() {
      this.$router.push(PATH.PAYMENTS);
    },
  },
  created() {
    if (this.financialProfiles) {
      let financialProfiles =
        this.$store.getters[AUTH_GETTERS.FINANCIAL_PROFILES];
      let defaultFinancialProfile = financialProfiles.find(
        (item: { is_default: boolean }) => item.is_default
      );
      this.financialProfilePublicId = defaultFinancialProfile.public_id;
      if (defaultFinancialProfile) {
        this.bankDetails = defaultFinancialProfile.bank_details;
      }
    }
  },
  async beforeMount() {
    const paymentsService = new PaymentsService();
    const organizationId = this.organization
      ? this.organization.organization_id
      : "";
    const response: IAGResponse = await paymentsService.getAllPaymentMethods(
      organizationId
    );

    try {
      if (response.success && response.status === StatusCodes.OK) {
        this.PaymentMethods = response.data.data.payment_methods;
        if (this.PaymentMethods?.length) {
          this.isAnyPaymentMethod = true;
        }
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          response.message ||
          response.error ||
          "fetched payment methods successfully";
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message ||
        exception.error ||
        "error while fetching payment methods";
    } finally {
      this.isPaymentMethodLoaded = false;
    }
    notificationService.triggerNotification();
  },
  watch: {
    method(newMethod: string) {
      this.isBank = newMethod === PAYMENT_METHODS_TYPES.IBFT;
    },
  },
});
</script>

<style lang="css" scoped>
.options {
  padding: 0px;
}
.amount-description {
  margin-bottom: 10px;
}
</style>
