<template>
  <SnapshotDetailAccordion
    title="Flight Issuance"
    prepand-icon="m-issue-ticket"
  >
    <template #overview>
      <div class="grid-container">
        <div v-for="(item, index) in flightIssuanceAggregates" :key="index">
          <SnapshotTile
            :heading="getTitle(item)"
            :subHeading="getCount(item)"
            subHeadingTitle="Issuance Count"
            :description="getAmount(item)"
            descriptionTitle="Amount"
          >
          </SnapshotTile>
        </div>
      </div>
    </template>
    <template #accordion-content>
      <MDataTable
        v-if="showDetails"
        :headers="issuanceHeader"
        :data="flightIssuanceOrganizations"
        :items-per-page="10"
        :hasSearch="true"
      >
        <template #organization_name="{ item }">
          <div @click="handleOrganizationRowClick($event, item)">
            <MTypography class="description organization-link" type="label">
              {{ item.organization_name }}
            </MTypography>
          </div>
        </template>
      </MDataTable>
      <ResultNotFound v-else title="No Organization Found" description="" />
    </template>
  </SnapshotDetailAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";

import ResultNotFound from "@/components/ResultNotFound.vue";
import {
  ISectorProductivity,
  FlightIssuanceAggregates,
} from "@/ag-portal-common/interfaces/sectorProductivity.interface";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { MDataTable } from "@aeroglobe/ag-core-ui";
import SnapshotDetailAccordion from "@/components/SnapshotDetailAccordion.vue";
import MSnapshotItemCountTile from "../../MSnapshotItemCountTile.vue";
import { getCurrencyFormatter } from "@/ag-flight-components/utils";

import SnapshotTile from "@/components/SnapshotTile.vue";
export default defineComponent({
  name: "FlightIssuanceTable",
  components: {
    MDataTable,
    SnapshotDetailAccordion,
    SnapshotTile,
    ResultNotFound,
  },
  props: {
    handleOrganizationRowClick: {
      type: Function,
      default: () => ({}),
    },
  },
  data(): {
    startDate: Date;
    endDate: Date;
    selectedSector: string;
    issuanceHeader: MDataTableHeader[];
  } {
    return {
      startDate: new Date(),
      endDate: new Date(),
      selectedSector: "",
      issuanceHeader: [
        {
          title: "Organization Name",
          value: "organization_name",
          key: "organization_name",
          sortable: true,
          align: "left",
          contentAlign: "left",
        },
        {
          title: "Total PNR Issued",
          value: "total_pnr_issued",
          key: "total_pnr_issued",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    getTitle(value: string): string {
      return _.capitalize(value?.replaceAll("_", " "));
    },
    getCount(value: keyof FlightIssuanceAggregates): number {
      const count =
        this.sectorProductivity.flight_issuance_data?.aggregates[value]
          .count_pnrs;

      return count;
    },
    formattedCurrency(currency: string, amount: number): string {
      return getCurrencyFormatter(currency).format(amount);
    },
    getAmount(value: keyof FlightIssuanceAggregates): string {
      const fare =
        this.sectorProductivity.flight_issuance_data?.aggregates[value]
          .total_amount;
      const formattedCurrency = this.formattedCurrency(
        fare.currency,
        fare.value
      );

      return formattedCurrency;
    },
    updateIssuanceHeader() {
      const sectorProductivity = this.$store.getters
        .sectorProductivity as ISectorProductivity;
      const organizations =
        sectorProductivity?.flight_issuance_data?.organizations ?? [];

      const providersName = organizations[0].pnrs_issued_by_provider.map(
        ({ provider_name }) => provider_name
      );

      const updatedTableHeaders = providersName.map((item) => {
        return {
          title: item.replaceAll("_", " "),
          value: item,
          key: item,
          sortable: true,
        };
      });

      this.issuanceHeader = [
        ...this.issuanceHeader,
        ...updatedTableHeaders,
      ] as MDataTableHeader[];
    },
  },
  computed: {
    sectorProductivity(): ISectorProductivity {
      return this.$store.getters.sectorProductivity;
    },
    showDetails(): boolean {
      const isLoading = this.$store.getters.isSectorProductivityLoading;
      const organizations =
        this.$store.getters.sectorProductivity?.flight_issuance_data
          ?.organizations || [];
      return !isLoading && organizations.length > 0;
    },
    flightIssuanceOrganizations() {
      const sectorProductivity = this.$store.getters
        .sectorProductivity as ISectorProductivity;
      const organizations =
        sectorProductivity?.flight_issuance_data?.organizations ?? [];

      const formattedOrganizations = organizations.map((item) => {
        const flights = Object.fromEntries(
          item.pnrs_issued_by_provider.map(
            ({ provider_name, issuance_count }) => [
              provider_name,
              issuance_count,
            ]
          )
        );

        return {
          public_id: item.public_id,
          organization_name: item.organization_name,
          total_pnr_issued: item.total_pnr_issued,
          ...flights,
        };
      });

      return formattedOrganizations;
    },
    flightIssuanceAggregates(): Array<keyof FlightIssuanceAggregates> {
      const sectorProductivity = this.$store.getters.sectorProductivity as
        | ISectorProductivity
        | undefined;
      const aggregates = sectorProductivity?.flight_issuance_data?.aggregates;

      if (aggregates) {
        return Object.keys(aggregates) as Array<keyof FlightIssuanceAggregates>;
      }

      return [];
    },
  },
  mounted() {
    this.updateIssuanceHeader();
  },
});
</script>

<style lang="scss" scoped>
.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-top: solid 8px var(--green-color);
}

.heading {
  font-size: 30px;
  font-weight: bold;
}

.overview-heading {
  font-size: 19px;
  margin: 10px;
  font-weight: bold;
}

.items-container {
  border-left: solid 4px var(--green-color);
}

.empty-items-message {
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}
.organization-link {
  color: var(--green-color);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.white-icon {
  color: #fff;
}
</style>

<style lang="css" scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

@media (max-width: 1350px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 963px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
</style>
