<template>
  <MCard class="applied-filters-card">
    <div
      class="af-header header-row"
      @click="showAppliedFilters = !showAppliedFilters"
      :disabled="filtersLength === 0"
    >
      <div class="af-title">
        <div>Applied Filters</div>
        <div
          :class="[
            'm-badge',
            'error',
            { increment: hasIncremented, decrement: hasDecremented },
          ]"
          :show="filtersLength > 0"
        >
          {{ filtersLength }}
        </div>
      </div>
      <MIcon
        :class="[
          'af-icon',
          { rotate: showAppliedFilters && filtersLength > 0 },
        ]"
        name="m-dropdown"
        width="20"
        height="20"
      />
    </div>

    <div
      :class="[
        'af-wrapper',
        { active: showAppliedFilters && filtersLength > 0 },
      ]"
    >
      <div class="af-chips">
        <template
          v-for="(filter, index) in modelValue.activeFilters"
          :key="index"
        >
          <MChip
            variant="primary"
            class="af-chip"
            border-less
            append-icon="m-cross"
            @click:append="modelValue.removeFilterByIndex(index)"
          >
            {{ onRenderFilterLabelHandler(filter) }}
          </MChip>
        </template>
      </div>
    </div>
  </MCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { MCard, MChip, MIcon } from "@aeroglobe/ag-core-ui";

import { FlightFilters } from "../../models/filters";
import { Filter, FilterType } from "../../types";
import UTILS from "@/ag-portal-common/utils";

export default defineComponent({
  name: "AppliedFiltersCard",
  components: { MCard, MChip, MIcon },
  props: {
    modelValue: {
      type: Object as PropType<FlightFilters>,
      required: true,
    },
  },
  watch: {
    filtersLength(newVal: number, oldVal: number) {
      if (newVal > oldVal) {
        this.hasIncremented = true;

        setTimeout(() => {
          this.hasIncremented = false;
        }, 300);
      } else {
        this.hasDecremented = true;

        setTimeout(() => {
          this.hasDecremented = false;
        }, 300);
      }
    },
  },
  computed: {
    filtersLengthLabel(): string {
      const length = this.modelValue.activeFilters.length;

      return `${length} Filter${length > 1 ? "s" : ""} Applied`;
    },
    filtersLength(): number {
      return this.modelValue.activeFilters.length;
    },
  },
  data() {
    return {
      showAppliedFilters: false,
      hasIncremented: false,
      hasDecremented: false,
    };
  },
  methods: {
    onRenderFilterLabelHandler(filter: Filter): string {
      switch (filter.type) {
        case FilterType.PRICE:
          return `Upto ${this.getFormattedCurrency(filter.count ?? 0)}`;
        case FilterType.STOPS:
          return filter.label;
        case FilterType.TIMES:
          return filter.label;
        case FilterType.AIRLINES:
          return `${filter.label} (${filter.count})`;
      }
    },
    getFormattedCurrency(amount: number): string {
      return UTILS.getFormattedCurrency(amount);
    },
  },
});
</script>

<style scoped lang="css">
.applied-filters-card {
  color: var(--m-secondary-color);
}

.applied-filters-card .header-row {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}

.applied-filters-card .af-header {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;

  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}
.applied-filters-card .af-header .af-title {
  display: flex;
  align-items: center;
  gap: 4px;
}
.applied-filters-card .af-header .af-title .m-badge {
  font-size: 10px;
  line-height: normal;
  letter-spacing: 0.1px;
  font-weight: 500;

  padding: 4px 8px;
  border-radius: 10px;

  background-color: var(--m-error-color);
  color: white;

  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.applied-filters-card .af-header .af-title .m-badge.increment {
  transform: scale(1.25);
  opacity: 1;
}
.applied-filters-card .af-header .af-title .m-badge.decrement {
  transform: scale(0.75);
  opacity: 0.75;
}

.applied-filters-card .af-header .af-subtitle {
  font-weight: normal;
}

.applied-filters-card .af-header .af-action {
  color: var(--m-primary-color);
  text-decoration: underline;
  cursor: pointer;

  user-select: none;
}
.applied-filters-card .af-header[disabled="true"] {
  opacity: 0.5 !important;
  user-select: none;
  pointer-events: none;
  cursor: not-allowed !important;
}

.af-chips {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
}

.af-wrapper {
  max-height: 0;
  overflow: hidden;
  padding: 0;

  animation: collapse 0.2s ease-in-out forwards;
}

.af-wrapper.active {
  animation: expand 0.2s ease-in-out forwards;
}

.af-chips .af-chip {
  width: max-content;
  font-weight: 500;
}

.af-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
}
.af-icon:hover {
  background-color: var(--m-secondary-accent-color);
}
.af-icon.rotate {
  transform: rotate(180deg);
}

@keyframes expand {
  0% {
    max-height: 0;
    padding: 0 6px 0;
  }
  50% {
    max-height: 50px;
    padding: 4px 6px 0;
  }
  100% {
    max-height: 1000px;
    padding: 16px 6px 0;
  }
}
@keyframes collapse {
  0% {
    max-height: 1000px;
    padding: 16px 6px 0;
  }
  50% {
    max-height: 50px;
    padding: 8px 6px 0;
  }
  100% {
    max-height: 0;
    padding: 0 6px 0;
  }
}
</style>
