<template>
  <MDialog
    title="Add New Agent"
    v-model="isMdialogOpen"
    @close="$emit('handleClose')"
  >
    <template #content>
      <div class="add-agent-container">
        <MCombobox
          v-model:inputValue="role"
          label="Roles"
          itemValue="value"
          itemLabel="label"
          :options="roles"
        />
        <MTextfield
          v-model:inputValue="fullName"
          label="Name"
          :hasError="!!fullNameError"
          :errorMessage="fullNameError"
        />
        <MTextfield
          v-model:inputValue="email"
          type="email"
          label="Email"
          :hasError="!!emailError"
          :errorMessage="emailError"
        />
      </div>
    </template>

    <template #actions>
      <MButton
        :disabled="$store.getters.isAddingAgent"
        class="submit-btn"
        @click="onSubmit()"
        >Submit
      </MButton>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { AGENT_SUB_ROLES } from "@/ag-portal-common/enums/AGENT_SUB_ROLES";
import {
  genTestId,
  validateEmail,
  validateName,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import UTILS from "@/ag-portal-common/utils";
import { MDialog } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "AddAgentModal",
  components: {
    MDialog,
  },
  events: ["handleClose"],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  watch: {
    isOpen(updatedValue) {
      this.isMdialogOpen = updatedValue;
    },
    isMdialogOpen(updatedValue) {
      if (!updatedValue) {
        this.$emit("handleClose");
      }
    },
  },
  computed: {
    isUserAgent() {
      return UTILS.isUser(USER_ROLES.AGENT);
    },
    isSuperUser() {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER);
    },
  },
  created() {
    if (this.isUserAgent) {
      this.roles = [AGENT_SUB_ROLES.SUB_AGENT, AGENT_SUB_ROLES.EMPLOYEE].map(
        (role) => ({
          label: role,
          value: role,
        })
      );
    } else if (this.isSuperUser) {
      this.roles = [AGENT_SUB_ROLES.OWNER].map((role) => ({
        label: role,
        value: role,
      }));
    }
  },
  methods: {
    validateEmail,
    validateName,
    genTestId,
    closeModal() {
      this.$emit("handleClose");
    },
    clearErrors() {
      this.emailError = "";
      this.fullNameError = "";
    },
    onSubmit() {
      const isPayloadValidated = this.validate();
      if (isPayloadValidated === "") {
        const payload = {
          email: this.email,
          full_name: this.fullName,
          role: this.role,
        };
        this.$emit("onSubmit", payload);
      }
    },
    validate() {
      this.clearErrors();
      const isEmailValid = this.validateEmail(this.email);
      const isNameValid = this.validateName(this.fullName);
      if (!isEmailValid) {
        this.emailError = "Email is not valid";
      }
      this.fullNameError = isNameValid;
      return isEmailValid && isNameValid;
    },
  },
  data(): {
    roles: Array<{ label: string; value: AGENT_SUB_ROLES }>;
    fullName: string;
    fullNameError: string;
    email: string;
    emailError: string;
    role: AGENT_SUB_ROLES;
    ELEMENT_TYPES: typeof ELEMENT_TYPES;
    isMdialogOpen: boolean;
  } {
    return {
      ELEMENT_TYPES,
      isMdialogOpen: this.isOpen,
      roles: [],
      fullName: "",
      fullNameError: "",
      email: "",
      emailError: "",
      role: UTILS.isUser(USER_ROLES.AGENT)
        ? AGENT_SUB_ROLES.SUB_AGENT
        : AGENT_SUB_ROLES.OWNER,
    };
  },
});
</script>

<style lang="css" scoped>
.submit-btn {
  float: right;
}
.add-agent-container {
  margin-top: 10px;
}
</style>
