import { MutationTree } from "vuex";
import {
  ISabreSegmentState,
  SegmentList,
  SegmentStats,
} from "@/ag-portal-common/interfaces/sabreSegmentState.interface";

const mutations: MutationTree<ISabreSegmentState> = {
  setDefaultSegmentList: (state, data: SegmentList[]) => {
    state.defaultSegmentList = data;
  },
  setDefaultSegmentStats: (state, data: SegmentStats) => {
    state.defaultSegmentStats = data;
  },
};

export default mutations;
