import { IFlightRefundState } from "@/ag-flight-components/interfaces/IFlightRefunds.interface";

const state: IFlightRefundState = {
  isPNRFetching: false,
  refundPNRDetails: null,
  refundPNR: null,
  canCalculateRefund: false,
  calculateDetails: null,
  isCommitingRefund: false,
  isRefundAirlineAndProviderFetching: false,
  refundAirlinesAndProvider: null,
};

export default state;
