<template>
  <MProgress
    class="mprogress-alignment"
    v-if="isFetchingCreditLimitRequestDetail"
    indeterminate
  />

  <div
    v-else-if="!isFetchingCreditLimitRequestDetail && creditLimitRequestDetail"
  >
    <MCard class="m-card">
      <MTypography type="headline">Agent Information </MTypography>
      <div class="responsive-grid">
        <div class="grid-item">
          <MTypography type="body" class="heading_label">Email</MTypography>
          <MTypography type="label" class="heading_label">
            {{ creditLimitRequestDetail?.agentEmail || "" }}
          </MTypography>
        </div>

        <div class="grid-item">
          <MTypography type="body" class="heading_label">Sector</MTypography>
          <MTypography type="label" class="heading_label">
            {{ creditLimitRequestDetail?.agentSectorName || "" }}
          </MTypography>
        </div>

        <div class="grid-item">
          <MTypography type="body" class="heading_label"
            >Request Priority</MTypography
          >
          <MTypography type="label" class="heading_label">
            {{
              formatWordIntoCapitalize(creditLimitRequestDetail?.priority || "")
            }}
          </MTypography>
        </div>

        <div class="grid-item">
          <MTypography type="body" class="heading_label"
            >Requested Amount</MTypography
          >
          <MTypography type="label" class="heading_label">
            {{ requestedAmount }}
          </MTypography>
        </div>

        <div class="grid-item">
          <MTypography type="body" class="heading_label"
            >Credit Days</MTypography
          >
          <MTypography type="label" class="heading_label">
            {{ requestedDays }}
          </MTypography>
        </div>

        <div class="grid-item">
          <MTypography type="body" class="heading_label"
            >Request Status</MTypography
          >
          <MTypography type="label" class="heading_label">
            {{ requestStatus(creditLimitRequestDetail?.status) }}
          </MTypography>
        </div>

        <div class="grid-item">
          <MTypography type="body" class="heading_label"
            >Agent Comments</MTypography
          >
          <MTypography type="label" class="heading_label">
            {{ creditLimitRequestDetail?.requestNotes || "N/A" }}
          </MTypography>
        </div>
      </div>
    </MCard>

    <div>
      <MProgress
        class="mprogress-alignment"
        v-if="isFetchingFinancialProfile"
        indeterminate
      />

      <financial-profile-box
        v-else-if="!isFetchingFinancialProfile && financialProfile"
        :financial-profile="financialProfile"
      />
    </div>

    <MCard v-if="creditLimitRequestDetail?.requestHistory?.length">
      <MTypography class="description" type="headline"
        >Request History
      </MTypography>
      <MDataTable
        :headers="headers"
        :data="creditLimitRequestDetail?.requestHistory || []"
        :items-per-page="creditLimitRequestDetail?.requestHistory?.length || 0"
        :has-pagination="false"
        :has-search="false"
      >
        <template #lastUpdatedAt="{ item }">
          <MTypography
            class="description"
            type="body"
            v-if="item.lastUpdatedAt"
          >
            {{ lastUpdatedDate(item.lastUpdatedAt) }}
          </MTypography>
          <MTypography
            class="description"
            type="label"
            v-if="item.lastUpdatedAt"
          >
            {{ lastUpdatedTime(item.lastUpdatedAt) }}
          </MTypography>
        </template>
        <template #comments="{ item }">
          <MTypography class="description" type="label" v-if="item.comments">
            {{ item.comments }}
          </MTypography>
          <MTypography class="description" type="label" v-else> - </MTypography>
        </template>
        <template #actionType="{ item }">
          <MTypography class="description" type="label">
            {{ item.actionType.replace(/_/g, " ") }}
          </MTypography>
        </template>
      </MDataTable>
    </MCard>

    <ag-card v-if="creditLimitRequestDetail?.canApprove">
      <ag-heading
        variant="h3"
        title="Sales Person Review"
        class="margin_bottom_30"
      ></ag-heading>
      <AgTextarea
        rows=""
        v-model="salesPersonComments"
        :value="salesPersonComments"
        label="Comments"
        error=""
        class="margin_bottom_20"
      ></AgTextarea>
      <AGButton
        test-id=""
        :is-loading="isProcessingCreditLimitRequest && approvedStatus"
        class="margin_right_10"
        @click="onProcessCreditLimitRequest(true)"
        >Approve
      </AGButton>
      <AGButton
        test-id=""
        :is-loading="isProcessingCreditLimitRequest && !approvedStatus"
        variant="danger"
        @click="onProcessCreditLimitRequest(false)"
        >Reject
      </AGButton>
    </ag-card>
  </div>

  <ag-not-found
    v-else
    :title="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND"
    :description="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND_DESCRIPTION"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ICreditLimitRequestDetail } from "@/interfaces/creditLimitRequestDetail.interface";
import {
  formatNumber,
  formatWordIntoCapitalize,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_HH_SS_24_HOURS,
} from "../../../ag-portal-common/constants/dateTimeFormats";
import { ProcessCreditLimitRequestBody } from "@/modules/CreditLimitManagement/models/processCreditLimitRequestBody.request";
import { PATH } from "@/ag-portal-common/constants/path";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import FinancialProfileBox from "@/modules/Agent/components/FinancialProfileBox.vue";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { GetFinancialProfileDto } from "@/modules/FinancialProfile/dtos/financialProfile.dto";
import { MCard, MDataTable } from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";

export default defineComponent({
  name: "CreditLimitRequestDetail",
  components: { FinancialProfileBox, MCard, MDataTable },
  methods: {
    getFormattedDateTime,
    formatWordIntoCapitalize,
    requestStatus(status: string) {
      return formatWordIntoCapitalize(status.replace(/_/g, " ") || "");
    },
    lastUpdatedDate(lastUpdatedAt: Date) {
      return getFormattedDateTime(lastUpdatedAt, FORMAT_DD_MMM_YYYY_WITH_DASH);
    },
    lastUpdatedTime(lastUpdatedAt: Date) {
      return getFormattedDateTime(lastUpdatedAt, FORMAT_HH_SS_24_HOURS);
    },
    onProcessCreditLimitRequest(status: boolean) {
      this.approvedStatus = status;
      let body: ProcessCreditLimitRequestBody = {
        comments: this.salesPersonComments,
        limit_request_id: this.creditLimitRequestDetail?.id || "",
        status,
      };

      this.$store.dispatch("processCreditLimitRequest", {
        body,
        callback: this.routeToCreditLimitManagement,
      });
    },
    routeToCreditLimitManagement() {
      this.$router.push(PATH.CREDIT_LIMIT_MANAGEMENT);
    },
  },
  data() {
    return {
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
      FORMAT_HH_SS_24_HOURS: FORMAT_HH_SS_24_HOURS,
      salesPersonComments: "",
      approvedStatus: false,
      headers: [
        {
          key: "approvedBy",
          title: "Approved By",
          value: "approvedBy",
        },
        {
          key: "actionType",
          title: "Status",
          value: "actionType",
        },
        {
          key: "lastUpdatedAt",
          title: "Last Updated At",
          value: "lastUpdatedAt",
        },

        {
          key: "comments",
          title: "Comments",
          value: "comments",
        },
      ] as MDataTableHeader[],
    };
  },
  computed: {
    NOTIFICATION_MESSAGES() {
      return NOTIFICATION_MESSAGES;
    },
    isFetchingFinancialProfile(): boolean {
      return this.$store.getters.isFetchingFinancialProfile;
    },
    financialProfile(): IFinancialProfile | null {
      return this.$store.getters.financialProfile;
    },
    creditLimitRequestDetail(): ICreditLimitRequestDetail | null {
      return this.$store.getters.creditLimitRequestDetail;
    },
    isFetchingCreditLimitRequestDetail(): boolean {
      return this.$store.getters.isFetchingCreditLimitRequestDetail;
    },
    isProcessingCreditLimitRequest(): boolean {
      return this.$store.getters.isProcessingCreditLimitRequest;
    },
    requestedAmount(): string {
      return `PKR ${formatNumber(
        this.creditLimitRequestDetail?.requestedAmount || 0
      )}`;
    },
    requestedDays(): string {
      return `${this.creditLimitRequestDetail?.requestedDays} Days`;
    },
  },
  beforeMount() {
    this.$store.dispatch(
      "fetchCreditLimitRequestDetail",
      this.$route.params.id
    );
  },
  watch: {
    creditLimitRequestDetail() {
      if (this.creditLimitRequestDetail?.canApprove) {
        let params: GetFinancialProfileDto = {
          financial_data: "show",
        };
        this.$store.dispatch("fetchFinancialProfile", {
          id: this.creditLimitRequestDetail.financialProfileId,
          params,
        });
      }
    },
  },
});
</script>

<style lang="css" scoped>
.m-card {
  margin: 16px;
}
.responsive-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.grid-item {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .responsive-grid {
    grid-template-columns: 1fr;
  }
}
</style>
