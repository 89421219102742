<template>
  <ListViewForSalesUser v-if="showListForSalesUser" />
  <ListViewForAgentsUser v-if="isAgentUser" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ListViewForSalesUser from "@/modules/CreditLimitManagement/components/ListViewForSalesUser.vue";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import ListViewForAgentsUser from "@/modules/CreditLimitManagement/components/ListViewForAgentsUser.vue";
import UTILS from "@/ag-portal-common/utils";

export default defineComponent({
  name: "CreditLimitList",
  components: { ListViewForAgentsUser, ListViewForSalesUser },
  data() {
    return {
      USER_ROLES,
    };
  },
  computed: {
    showListForSalesUser(): boolean {
      return UTILS.isUser(
        USER_ROLES.SUPER_ADMIN,
        USER_ROLES.AG_SUPER_USER,
        USER_ROLES.SALES
      );
    },
    isAgentUser(): boolean {
      return UTILS.isUser(USER_ROLES.AGENT);
    },
  },
});
</script>
