<template>
  <MFinancialProfileCombobox
    v-model:inputValue="searchValue"
    label="Select Organization"
    itemValue="value"
    itemLabel="label"
    :options="filteredOrgs"
    @update:inputValue="onUpdateModelValue"
    :disabled="isFetchingOrganizations"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IOrganization } from "@/ag-portal-common/interfaces/organization.interface";
import { MFinancialProfileCombobox } from "@aeroglobe/ag-core-ui";
import { ComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";

export default defineComponent({
  name: "OrganizationAutoComplete",
  components: {
    MFinancialProfileCombobox,
  },
  props: {
    defaultValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchValue: "",
      ELEMENT_TYPES,
    };
  },
  methods: {
    genTestId,
    onUpdateModelValue(value: string) {
      this.$emit("onChange", value);
    },
  },
  computed: {
    filteredOrgs(): FPComboboxOptions[] {
      let filterOrganizations = this.$store.getters.organizations || [];

      if (filterOrganizations) {
        return filterOrganizations?.map(
          (fp: {
            default_financial_profile?: {
              plan_name: string;
              platform_id: string;
            };
            sector: string;
            name: string;
            public_id: string;
            status: string;
          }) => {
            const planType =
              (fp?.default_financial_profile?.plan_name
                ?.split(" ")[1]
                ?.toLowerCase() as "pro" | "elite" | "smart") ?? "";
            const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
            return {
              id: fp?.default_financial_profile?.platform_id ?? "",
              label: fp?.name,
              value: fp?.public_id,
              isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
              status: fp?.status,
              sector: sector,
              type: planType,
            };
          }
        );
      } else {
        return [];
      }
    },
    isFetchingOrganizations(): boolean {
      return this.$store.getters.isFetchingOrganizations;
    },
  },
  watch: {
    defaultValue: {
      handler: function (value) {
        this.searchValue = value;
      },
      immediate: true,
    },
  },
});
</script>
