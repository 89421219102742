import { load } from "recaptcha-v3";

class RecaptchaService {
  private siteKey = "";

  constructor() {
    this.siteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY ?? "";
  }

  async generateToken(action: string): Promise<string> {
    const recaptcha = await load(this.siteKey);
    const token = await recaptcha.execute(action);

    return token;
  }
}

const recaptchaService = new RecaptchaService();

export default recaptchaService;
