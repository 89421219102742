export const FDG_FLIGHTS_API_PATH = {
  GET: "/v1/tours",
  GET_BY_ID: "/v1/tours/{0}/",
  GET_BOOKING_BY_ID: "/v2/bookings/{0}/",
  POST_INITIATE_BOOKING: "/v1/tours/bookings/initiate/",
  POST_CONFIRM_BOOKING: "/v1/tours/bookings/confirm/",
  POST_CONFIRM_BOOKING_WITHOUT_TRAVELER_DETAILS:
    "/v1/tours/bookings/democonfirm/",
  GENERATE_OTP: "/v1/tours/bookings/generate_otp",
  GROUP_FLIGHTS_BOOKING_PASSENGERS: "v1/tours/bookings/passengers/{0}",
  GROUP_FLIGHTS_BOOKING_PAY_NOW: "v1/tours/bookings/paynow/",
  DOWNLOAD_BRAND_TICKET: "v1/tours/bookings/bookings/get-voucher/{0}",
  CANCEL_OTP: "v1/tours/bookings/generate_otp",
  CANCEL_TOUR: "v1/tours/bookings/cancel-booking/",
};

export const FDG_DETAIL_VIEW_TEXTS = {
  GROUP_FLIGHT_PAX_SELECTION_HEADING: "Passenger(s) Count",
  GROUP_FLIGHT_PAX_SELECTION_DESCRIPTION:
    "Please select number of Passenger(s) for the chosen package",
  UMRAH_PAX_SELECTION_HEADING: "Pilgrim(s) Count",
  UMRAH_PAX_SELECTION_DESCRIPTION:
    "Please select number of Pilgrim(s) for the chosen package",
};

export enum FDG_BOOKING_STATUSES {
  INITIATED = "INITIATED",
  CONFIRMED = "CONFIRMED",
  ISSUANCE_IN_PROGRESS = "ISSUANCE_IN_PROGRESS",
  ISSUED = "ISSUED",
  CANCELED = "CANCELED",
  PROCESSING = "PROCESSING",
}

export const FDG_BOOKING_STATUSES_SEVERITY_MAPPING = {
  [FDG_BOOKING_STATUSES.INITIATED]: "info",
  [FDG_BOOKING_STATUSES.CONFIRMED]: "info",
  [FDG_BOOKING_STATUSES.ISSUANCE_IN_PROGRESS]: "success",
  [FDG_BOOKING_STATUSES.ISSUED]: "success",
  [FDG_BOOKING_STATUSES.PROCESSING]: "info",
};

export const FDG_BOOKING_STATUSES_MAPPING = {
  [FDG_BOOKING_STATUSES.INITIATED]: "INITIATED",
  [FDG_BOOKING_STATUSES.CONFIRMED]: "CONFIRMED",
  [FDG_BOOKING_STATUSES.ISSUANCE_IN_PROGRESS]: "ISSUANCE IN PROGRESS",
  [FDG_BOOKING_STATUSES.ISSUED]: "ISSUED",
  [FDG_BOOKING_STATUSES.PROCESSING]: "PROCESSING",
};
