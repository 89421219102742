import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6090932f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flight_route_card" }
const _hoisted_2 = { class: "airline_info" }
const _hoisted_3 = { class: "icon_airline_wrap" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "airline_code"
}
const _hoisted_7 = { class: "airline_destination" }
const _hoisted_8 = { class: "time_info left" }
const _hoisted_9 = {
  key: 0,
  class: "time"
}
const _hoisted_10 = {
  key: 1,
  class: "destination"
}
const _hoisted_11 = { class: "time_duration" }
const _hoisted_12 = {
  key: 0,
  class: "time"
}
const _hoisted_13 = { class: "flight_bar_wrap" }
const _hoisted_14 = { class: "time_info right" }
const _hoisted_15 = {
  key: 0,
  class: "time"
}
const _hoisted_16 = {
  key: 1,
  class: "destination"
}
const _hoisted_17 = { class: "flight_route_card" }
const _hoisted_18 = { class: "airline_info" }
const _hoisted_19 = { class: "icon_airline_wrap" }
const _hoisted_20 = ["src"]
const _hoisted_21 = { key: 0 }
const _hoisted_22 = {
  key: 1,
  class: "airline_code"
}
const _hoisted_23 = { class: "airline_destination" }
const _hoisted_24 = { class: "time_duration" }
const _hoisted_25 = { class: "flight_bar_wrap" }
const _hoisted_26 = {
  key: 0,
  class: "time"
}
const _hoisted_27 = { class: "time_info left" }
const _hoisted_28 = {
  key: 0,
  class: "destination"
}
const _hoisted_29 = {
  key: 1,
  class: "time"
}
const _hoisted_30 = { class: "time_info right" }
const _hoisted_31 = {
  key: 0,
  class: "destination"
}
const _hoisted_32 = {
  key: 1,
  class: "time"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgHeading = _resolveComponent("AgHeading")!
  const _component_AgIcon = _resolveComponent("AgIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          ($props.departureAirlineLogo)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: $props.departureAirlineLogo
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]),
        ($props.departureAirlineName)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              ($props.departureAirlineName)
                ? (_openBlock(), _createBlock(_component_AgHeading, {
                    key: 0,
                    title: $props.departureAirlineName,
                    variant: "h3"
                  }, null, 8, ["title"]))
                : _createCommentVNode("", true),
              ($props.departureAirlineCode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($props.departureAirlineCode), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          ($props.departureFromTime)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString($options.parseTimeOrDate($props.departureFromTime)), 1))
            : _createCommentVNode("", true),
          ($props.departureOriginSegmentIataCode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString($props.departureOriginSegmentIataCode), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
          ($props.departureDuration)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString($options.parseDuration($props.departureDuration)), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_AgIcon, { name: "flightBar" })
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          ($props.departureToTime)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString($options.parseTimeOrDate($props.departureToTime)), 1))
            : _createCommentVNode("", true),
          ($props.departureDestinationSegmentIataCode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString($props.departureDestinationSegmentIataCode), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          ($props.departureAirlineLogo)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: $props.departureAirlineLogo
              }, null, 8, _hoisted_20))
            : _createCommentVNode("", true)
        ]),
        ($props.departureAirlineName)
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
              ($props.departureAirlineName)
                ? (_openBlock(), _createBlock(_component_AgHeading, {
                    key: 0,
                    title: $props.departureAirlineName,
                    variant: "h3"
                  }, null, 8, ["title"]))
                : _createCommentVNode("", true),
              ($props.arrivalAirlineCode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString($props.arrivalAirlineCode), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _createVNode(_component_AgIcon, { name: "flightBar" })
          ]),
          ($props.arrivalDuration)
            ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString($options.parseDuration($props.arrivalDuration)), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_27, [
          ($props.arrivalOriginSegmentIataCode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString($props.arrivalOriginSegmentIataCode), 1))
            : _createCommentVNode("", true),
          ($props.arrivalFromTime)
            ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString($options.parseTimeOrDate($props.arrivalFromTime)), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_30, [
          ($props.arrivalDestinationSegmentIataCode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_31, _toDisplayString($props.arrivalDestinationSegmentIataCode), 1))
            : _createCommentVNode("", true),
          ($props.arrivalToTime)
            ? (_openBlock(), _createElementBlock("div", _hoisted_32, _toDisplayString($options.parseTimeOrDate($props.arrivalToTime)), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}