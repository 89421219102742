<template>
  <MCard>
    <MTypography type="headline">Request Limit</MTypography>
    <MTypography type="body">Update your Request Limit here</MTypography>
    <hr />
    <form @submit.prevent="onSubmit">
      <div class="credit-limit-row">
        <MFinancialProfileCombobox
          v-model:inputValue="formData.financialProfilePublicId"
          label="Financial Profiles"
          placeholder="Select Financial Profile"
          itemValue="value"
          itemLabel="label"
          :hasError="errors['financialProfilePublicId']"
          :errorMessage="errors['financialProfilePublicId']"
          :options="financialProfileOptions"
        />
        <MTextfield
          class="fields-column"
          v-model:inputValue="formData.totalAmount"
          label="Total Amount"
          placeholder="Total Amount"
          type="number"
          :has-error="errors['totalAmount']"
          :error-message="errors['totalAmount']"
        />
        <MTextfield
          class="fields-column"
          v-model:inputValue="formData.totalDays"
          label="Total Days"
          placeholder="Total Days"
          type="number"
          :has-error="errors['totalDays']"
          :error-message="errors['totalDays']"
        />
        <MCombobox
          class="fields-column"
          v-model:inputValue="formData.priority"
          label="Priority"
          itemValue="value"
          itemLabel="label"
          :options="CREDIT_LIMIT_REQUEST_PRIORITIES"
        />
      </div>
      <div class="credit-limit-row">
        <m-textarea
          v-model:inputValue.trim="formData.comment"
          label="Comment"
          :hasError="errors['comment']"
          :errorMessage="errors['comment']"
        />
      </div>
      <div>
        <MButton
          type="filled"
          behaviour="submit"
          test-id=""
          :disabled="
            disableCreditLimitRequestBtn || isCreatingCreditLimitRequest
          "
          >Create Limit Request
        </MButton>
      </div>
      <div v-if="showFeeNote" class="third-row">
        <MTypography class="description" type="headline">Note: </MTypography>
        <MTypography class="description" type="body">
          {{ formattedAmount(Number(processingFee())) }} processing fee will be
          applied on your requested credit limit.
        </MTypography>
      </div>
    </form>
  </MCard>
  <CreditLimitConfirmationModal
    :is-open="isDialogOpen"
    :amount-fee="Number(processingFee())"
    :amount-requested="formData.totalAmount"
    @onConfirm="onConfirmation"
    @onCloseModal="isDialogOpen = false"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CREDIT_LIMIT_REQUEST_PRIORITIES } from "@/modules/CreditLimitManagement/constants";
import { createCreditLimitRequestValidationSchema } from "@/modules/CreditLimitManagement/validations/createCreditLimitRequestValidationSchema";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { ValidationError } from "yup";
import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { CreateCreditLimitRequestsBody } from "@/modules/CreditLimitManagement/models/createCreditLimitRequestsBody.request";
import { PATH } from "@/ag-portal-common/constants/path";
import analyticsService from "@/analytic.service";
import { CREDIT_LIMIT_ANALYTICS_EVENTS } from "@/modules/CreditLimitManagement/constants/anaylticsEvents";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";
import CreditLimitConfirmationModal from "../components/CreditLimitConfirmationModal.vue";
import { MTypography } from "@aeroglobe/ag-core-ui";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";

interface ICreateCreditLimitRequestState {
  errors: IObject;
  formData: {
    totalAmount: number;
    totalDays: number;
    financialProfilePublicId: string;
    priority: string;
    comment: string;
  };
  isDialogOpen: boolean;
}

export default defineComponent({
  name: "CreateCreditLimitRequest",
  components: {
    CreditLimitConfirmationModal,
    MTypography,
  },

  mounted() {
    this.$store.dispatch("fetchCreditLimitSlabs");
  },
  data(): ICreateCreditLimitRequestState {
    return {
      errors: {},
      formData: {
        totalAmount: 0,
        financialProfilePublicId: "",
        totalDays: 0,
        priority: CREDIT_LIMIT_REQUEST_PRIORITIES[0].value,
        comment: "",
      },
      isDialogOpen: false,
    };
  },

  methods: {
    routeToCreditLimitRequests() {
      this.$router.push(PATH.CREDIT_LIMIT_MANAGEMENT);
    },
    updateFinancialProfilePublicId(value: string) {
      this.formData.financialProfilePublicId = value;
    },
    async onSubmit() {
      try {
        await createCreditLimitRequestValidationSchema.validate(this.formData, {
          abortEarly: false,
        });
        if (this.showFeeNote) {
          this.isDialogOpen = true;
        } else {
          this.onConfirmation();
        }
      } catch (ex) {
        if (ex instanceof ValidationError) {
          this.errors = yupValidationErrorAsSchema(ex);
        }
      }
    },
    amountIsLessThanOne() {
      return this.formData.totalAmount < 1;
    },
    onConfirmation() {
      let body: CreateCreditLimitRequestsBody = {
        financial_profile_id: this.formData.financialProfilePublicId,
        total_amount: this.formData.totalAmount,
        total_days: this.formData.totalDays,
        priority: this.formData.priority,
        notes: this.formData.comment,
      };
      this.$store.dispatch("createCreditLimitRequest", {
        body,
        callback: this.routeToCreditLimitRequests,
      });

      analyticsService.logActionEvent(
        CREDIT_LIMIT_ANALYTICS_EVENTS.REQUEST_LIMIT,
        body
      );
    },
    formattedAmount(amount: number) {
      if (amount) {
        const currency = "PKR";
        const formattedAmount = getCurrencyFormatter(currency).format(amount);
        return formattedAmount;
      }
    },
    processingFee(): number {
      const slabs = this.$store.getters.creditLimitSlabs || [];
      if (!this.formData.totalAmount) return 0;

      let fee = null;
      for (let i = 0; i < slabs.length; i++) {
        const slab = slabs[i];
        if (
          this.formData.totalAmount >= Number(slab.min_slab) &&
          this.formData.totalAmount <= Number(slab.max_slab)
        ) {
          fee = slab.fee;
          break;
        }
        // If the entered amount exceeds the last slab, set fee to the last slab fee
        if (
          this.formData.totalAmount > Number(slabs[slabs.length - 1].max_slab)
        ) {
          fee = Number(slabs[slabs.length - 1].fee);
          break;
        }
      }
      return fee;
    },
  },
  computed: {
    CREDIT_LIMIT_REQUEST_PRIORITIES() {
      return CREDIT_LIMIT_REQUEST_PRIORITIES;
    },
    isCreatingCreditLimitRequest(): boolean {
      return (
        this.$store.getters.isCreatingCreditLimitRequest ||
        this.$store.getters.creditLimitSlabsLoading
      );
    },
    showFeeNote(): boolean {
      return this.processingFee() > 1;
    },
    disableCreditLimitRequestBtn(): boolean {
      return this.formData.totalAmount < 1;
    },
    financialProfileOptions(): {
      id: string;
      label: string;
      value: string;
      isActive: boolean;
      status: string;
      sector: string;
      type: string;
    }[] {
      const profiles = this.$store.getters[
        AUTH_GETTERS.FINANCIAL_PROFILES
      ] as IFinancialProfile[];
      return profiles?.map((fp: IFinancialProfile) => {
        const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase();
        const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "") || "";
        return {
          id: fp?.platform_id,
          label: fp?.financial_profile_name,
          value: fp?.public_id,
          isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
          status: fp?.status,
          sector: sector,
          type: planType,
        };
      });
    },
  },
});
</script>

<style lang="css" scoped>
.credit-limit-row {
  display: flex;
  flex-direction: row;
}
.fields-column {
  width: 250px;
  margin-left: 10px;
}
@media screen and (max-width: 786px) {
  .credit-limit-row {
    flex-direction: column;
  }

  .fields-column {
    width: 100%;
    margin-left: 0px;
  }
}
</style>
