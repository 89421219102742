import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgFile = _resolveComponent("AgFile")!
  const _component_m_textfield = _resolveComponent("m-textfield")!
  const _component_m_textarea = _resolveComponent("m-textarea")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createBlock(_component_MDialog, {
    modelValue: _ctx.isMdialogOpen,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isMdialogOpen) = $event)),
    onClose: _ctx.closeModal,
    "max-width": "1000",
    title: _ctx.renderTitle
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_AgFile, {
          "test-id": "",
          class: "ag-file-theme",
          label: "Service Image",
          multiple: false,
          "onOn:change": _ctx.onFileChangeHandler
        }, null, 8, ["onOn:change"]),
        _createVNode(_component_m_textfield, {
          inputValue: _ctx.name,
          "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
          inputValueModifiers: { trim: true },
          label: "Service Name",
          hasError: _ctx.errors?.name,
          errorMessage: _ctx.errors?.name
        }, null, 8, ["inputValue", "hasError", "errorMessage"]),
        _createVNode(_component_m_textarea, {
          inputValue: _ctx.description,
          "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.description) = $event)),
          inputValueModifiers: { trim: true },
          label: "Service Description",
          hasError: _ctx.errors?.description,
          errorMessage: _ctx.errors?.description
        }, null, 8, ["inputValue", "hasError", "errorMessage"])
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_MButton, {
        disabled: _ctx.isLoading,
        onClick: _ctx.onSubmit
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.isEdit ? "Save" : "Add"), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ]),
    _: 1
  }, 8, ["modelValue", "onClose", "title"]))
}