<template>
  <ag-card>
    <ag-heading
      variant="h2"
      title="Cancellation Policy"
      class="margin_bottom_10"
    />

    <AgPriceSummary
      v-show="freeCancellationDate"
      test-id=""
      info="Free Cancellation until"
      :price="freeCancellationDate"
      class="margin_bottom_10"
    />

    <AgDiv v-for="(term, index) in nonRefundablePolicies" :key="index">
      <AgPriceSummary
        test-id=""
        :info="renderCancellationDate(term.start_date)"
        :price="
          getPrice(
            term.cancellation_amount.value,
            term.cancellation_amount.currency
          )
        "
      />
    </AgDiv>
  </ag-card>
</template>

<script lang="ts">
import {
  RoomCancellationTerm,
  HotelDetails,
} from "@/ag-portal-common/types/hotel";
import { formatNumber } from "@/ag-portal-common/utils/helpers";
import { format } from "date-fns";
import { defineComponent } from "vue";

export default defineComponent({
  name: "RoomCancellationTerm",
  props: {
    cancellationTerms: {
      type: Array as () => Array<RoomCancellationTerm>,
    },
  },
  methods: {
    formatDate(date: string) {
      return date ? format(new Date(date), "MMM dd yyyy") : "";
    },
    getPrice(amount: number | string, currency: string) {
      return currency + " " + formatNumber(amount);
    },
    renderCancellationDate(date: string) {
      return `Cancellation after ${this.formatDate(date)}`;
    },
  },
  computed: {
    bookingDetails(): HotelDetails {
      return this.$store.getters.bookingDetails;
    },
    freeCancellationDate(): string {
      const date = this.cancellationTerms?.find(
        (x) => x.cancellation_amount.value <= 0
      )?.end_date;
      return date ? format(new Date(date), "MMM dd yyyy") : "";
    },
    nonRefundablePolicies(): RoomCancellationTerm[] | undefined {
      const items = this.cancellationTerms?.filter(
        (x) => x.cancellation_amount.value > 0
      );
      return items;
    },
  },
});
</script>
