import { stringDecrypt, stringEncrypt } from "../utils/helpers";

class StorageService {
  private storage: Storage;
  private prefix = "v1";

  constructor() {
    this.storage = window.localStorage;
  }

  public getKey(key: string): string {
    return `${key}/${this.prefix}`;
  }

  public getItem<T>(key: string, encrypt = true): T | null {
    const data = this.storage.getItem(this.getKey(key)) as T | null;

    if (encrypt) {
      const decryptedData = data && stringDecrypt(data);
      return decryptedData && JSON.parse(decryptedData);
    } else {
      return data;
    }
  }

  public setItem(key: string, value: string, encrypt = true) {
    const item = encrypt ? stringEncrypt(value) : value;

    this.storage.setItem(this.getKey(key), item);
  }

  public removeItem(key: string) {
    this.storage.removeItem(this.getKey(key));
  }

  public clear() {
    this.storage.clear();
  }
}

const storageService = new StorageService();

export default storageService;
