import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpdatePassengerShimmer = _resolveComponent("UpdatePassengerShimmer")!
  const _component_TravelersComponent = _resolveComponent("TravelersComponent")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MCard = _resolveComponent("MCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_UpdatePassengerShimmer, { key: 0 }))
      : (_openBlock(), _createBlock(_component_TravelersComponent, {
          key: 1,
          travelers: _ctx.travelers,
          ref: "travelersComponent",
          "onUpdate:travelers": _ctx.updateTravelers,
          onSubmit: _ctx.handleTravelersSubmit
        }, null, 8, ["travelers", "onUpdate:travelers", "onSubmit"])),
    _createVNode(_component_MCard, { class: "confirm-bottom-btn-container" }, {
      default: _withCtx(() => [
        _createVNode(_component_MButton, {
          disabled: _ctx.isLoading,
          onClick: _ctx.closeUpdatePassengers
        }, {
          default: _withCtx(() => [
            _createTextVNode("Close")
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]),
        _createVNode(_component_MButton, {
          disabled: _ctx.isLoading,
          onClick: _ctx.handleSubmit
        }, {
          default: _withCtx(() => [
            _createTextVNode("Update Passengers")
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      _: 1
    })
  ], 64))
}