<template>
  <MAccordion :initial-open="true">
    <template #title>
      <AgIconInfoBar
        test-id=""
        mPrepandIcon="m-penalty"
        title="Penalties"
        class="section-title"
      />
    </template>
    <template #content>
      <div class="penalty-details-content">
        <div v-for="(penalty, index) in penalties" :key="`penalty-${index}`">
          <MChip variant="success" class="refund-penalties">
            {{ penalty.departure_city_code }} -
            {{ penalty.arrival_city_code }}</MChip
          >
          <MDataTable
            :headers="penaltyTableHeaders"
            :is-loading="false"
            :data="penalty.penalty_details"
            :items-per-page="5"
            :has-search="false"
            :simpleDataTable="true"
            :has-pagination="false"
          >
            <template #amount="{ item }">
              <MTypography class="description" type="label">
                {{ formattedCurrency(item.currency, item.amount) }}
              </MTypography>
            </template>
          </MDataTable>
        </div>
      </div>
    </template>
  </MAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getCurrencyFormatter } from "../../../ag-flight-components/utils";
import { MDataTableHeader } from "../../../ag-portal-common/types";
import { Penalty } from "../constants";
import { MTypography } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "PenaltyDetails",
  components: {
    MTypography,
  },
  computed: {
    penalties(): Penalty[] {
      const fetchRefundDetails = this.$store.getters.refundPNRDetails;
      return fetchRefundDetails.penalties;
    },
  },
  data() {
    return {
      penaltyTableHeaders: [
        {
          key: "penalty_period",
          title: "Penalty Period",
          value: "penalty_period",
          align: "left",
          contentAlign: "left",
        },
        {
          key: "amount",
          title: "Amount",
          value: "amount",
          align: "left",
          contentAlign: "left",
        },
      ] as MDataTableHeader[],
    };
  },
  methods: {
    formattedCurrency(currency: string, amount: number): string {
      return getCurrencyFormatter(currency).format(amount);
    },
  },
});
</script>

<style scoped lang="css">
.section-title {
  margin: 0;
  border: none;
  padding: 0;
}
.penalty-details-content {
  padding-top: 24px;
}
.refund-penalties {
  max-width: max-content;
  min-height: 50px;
  margin-top: 10px;
  font-weight: bolder;
  margin-left: 10px;
}
</style>
