<template>
  <MDialog title="Delete Whatsapp Member" v-model="isMdialogOpen" hide-header>
    <template #content>
      <div class="delete-whatsapp-member">
        <MIcon
          class="delete-whatsapp-member-icon"
          name="m-info"
          width="48"
          height="48"
        />
        <div class="delete-whatsapp-member-title">Delete Member</div>
        <div class="delete-whatsapp-member-subtitle">
          Are you sure to delete whatsapp member?
        </div>
      </div>
    </template>
    <template #actions>
      <MButton @click="$emit('handleReject')" type="outlined"> Cancel </MButton>
      <MButton
        @click="$emit('handleAccept')"
        :disabled="$store.getters.whatsappActionLoading"
      >
        Delete
      </MButton>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DeleteWhatsappMemberConfirmationModel",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data(): {
    isMdialogOpen: boolean;
  } {
    return {
      isMdialogOpen: this.isOpen,
    };
  },
  watch: {
    isOpen(newVal) {
      this.isMdialogOpen = newVal;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("handleReject");
      }
    },
  },
});
</script>

<style lang="css" scoped>
.delete-whatsapp-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 16px;

  padding: 16px;
}

.delete-whatsapp-member .delete-whatsapp-member-icon {
  width: 48px;
  height: 48px;

  color: var(--m-error-color);
}
.delete-whatsapp-member .delete-whatsapp-member-title {
  font-size: 24px;
  font-weight: 500;
}
.delete-whatsapp-member .delete-whatsapp-member-subtitle {
  color: var(--m-label-color);
}
</style>
