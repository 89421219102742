<template>
  <ShimmerCard card-height="244px" />
  <ShimmerCard card-height="244px" />
  <ShimmerCard card-height="244px" />
</template>
<script>
import { defineComponent } from "vue";
import ShimmerCard from "@/components/ShimmerCard.vue";

export default defineComponent({
  name: "TourListShimmer",
  components: { ShimmerCard },
});
</script>
