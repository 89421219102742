import { RouteRecordRaw } from "vue-router";
import SalesMyTeam from "@/modules/Sales/views/SalesMyTeamPage.vue";

import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import { PATH } from "@/ag-portal-common/constants/path";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.MY_TEAM,
    name: "salesMyTeam",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.SALES_MY_TEAM.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.SALES_MY_TEAM.PERMISSIONS,
    },
    component: SalesMyTeam,
  },
];

export default routes;
