import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f92ac808"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "d-flex justify-content-between align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MProgress = _resolveComponent("MProgress")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MFabButton = _resolveComponent("MFabButton")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_DeletePassengerConfirmationModal = _resolveComponent("DeletePassengerConfirmationModal")!
  const _component_AddPassengerModal = _resolveComponent("AddPassengerModal")!
  const _component_UpdatePassengerModal = _resolveComponent("UpdatePassengerModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isFetchingOrganizationPassengers)
      ? (_openBlock(), _createBlock(_component_MProgress, {
          key: 0,
          class: "mprogress-alignment",
          indeterminate: ""
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_MCard, { class: "passenger-management-heading" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_AgIconInfoBar, {
                  "test-id": "",
                  title: "Passenger Management",
                  class: "section-title",
                  mPrepandIcon: "m-passenger-management"
                }),
                _createElementVNode("div", null, [
                  _createVNode(_component_MButton, { onClick: _ctx.handleNewPassenger }, {
                    default: _withCtx(() => [
                      _createTextVNode("Add New Passenger")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_MCard, null, {
            default: _withCtx(() => [
              (_ctx.showNoResult())
                ? (_openBlock(), _createBlock(_component_AgNotFound, {
                    key: 0,
                    "test-id": "",
                    heading: "No Passenger Found"
                  }))
                : (_openBlock(), _createBlock(_component_MDataTable, {
                    key: 1,
                    headers: _ctx.headers,
                    data: _ctx.passengersList,
                    "item-per-page": 10,
                    "has-search": true
                  }, {
                    passport_expiry: _withCtx(({ item }) => [
                      _createVNode(_component_MTypography, {
                        class: "description td-full-width",
                        type: "label"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.formattedDate(item.passport_expiry)), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    action: _withCtx(({ item }) => [
                      _createVNode(_component_MButton, {
                        disabled: item.agent_type === _ctx.AGENT_SUB_ROLES.OWNER,
                        onClick: ($event: any) => (_ctx.handleUpdate($event, item))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.action_update), 1)
                        ]),
                        _: 2
                      }, 1032, ["disabled", "onClick"])
                    ]),
                    remove: _withCtx(({ item }) => [
                      _createVNode(_component_MFabButton, {
                        class: "delete-icon",
                        icon: "m-delete",
                        onClick: ($event: any) => (_ctx.handleDelete($event, item))
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  }, 8, ["headers", "data"]))
            ]),
            _: 1
          })
        ])),
    _createVNode(_component_DeletePassengerConfirmationModal, {
      "is-loading": _ctx.isConfirmationDialogLoading,
      "is-open": _ctx.confirmationDialogOpen,
      onHandleAccept: _ctx.handleConfirmDeletePassenger,
      onHandleReject: _ctx.handleDeletePassengerDialogClose
    }, null, 8, ["is-loading", "is-open", "onHandleAccept", "onHandleReject"]),
    _createVNode(_component_AddPassengerModal, {
      "is-open": _ctx.updatePassengerDialogOpen,
      "is-edit": _ctx.isPassengerEdit,
      onCloseModal: _ctx.handleClosePassengerModal,
      onOnPassengerSubmit: _ctx.handlePassengerSubmit
    }, null, 8, ["is-open", "is-edit", "onCloseModal", "onOnPassengerSubmit"]),
    _createVNode(_component_UpdatePassengerModal, {
      passenger: _ctx.selectedPassenger ?? null,
      "is-open": _ctx.updatePassengerDialogOpen,
      "is-edit": _ctx.isPassengerEdit,
      onCloseModal: _ctx.handleClosePassengerModal,
      onOnPassengerSubmit: _ctx.handlePassengerSubmit
    }, null, 8, ["passenger", "is-open", "is-edit", "onCloseModal", "onOnPassengerSubmit"])
  ], 64))
}