import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { FARE_MANAGEMENT_API_PATH } from "../constants";

class FareManagemenentService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public getFares(pnr: string, airline_code: string): Promise<IAGResponse> {
    return this.restClient.get(
      FARE_MANAGEMENT_API_PATH.GET_FARE +
        `?pnr=${pnr}&airline_code=${airline_code}`
    );
  }
  public saveFares(reference: string): Promise<IAGResponse> {
    return this.restClient.post(FARE_MANAGEMENT_API_PATH.GET_FARE, {
      reference: reference,
    });
  }
}

export default FareManagemenentService;
