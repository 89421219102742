import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a5ef8c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-row" }
const _hoisted_2 = ["src", "onError"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createBlock(_component_MDialog, {
    title: _ctx.renderDialogHeading(),
    modelValue: _ctx.isMdialogOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isMdialogOpen) = $event)),
    "max-width": "1000",
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeModal'))),
    "hide-footer": ""
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderCNIC(), (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "image-container"
          }, [
            _createElementVNode("img", {
              class: "cnic-image",
              src: item?.content_url,
              onError: ($event: any) => (item.content_url = require('@/assets/news-icon.png')),
              alt: "Cnic"
            }, null, 40, _hoisted_2)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title", "modelValue"]))
}