<template>
  <MDialog
    title="Reject Organization"
    v-model="isMdialogOpen"
    @close="$emit('handleClose')"
  >
    <template #content>
      <MTypography type="body"
        >Are you sure to reject {{ organization_name }} ?</MTypography
      >
      <div class="reject-content">
        <MTypography type="title" class="note-warning"> Note: </MTypography>
        <MTypography type="body">
          This action will reject the documents uploaded by the owner and grant
          them access to upload new documents
        </MTypography>
      </div>
    </template>
    <template #actions>
      <MButton
        :disabled="isEliteOrganizationStatusUpdating"
        @click="$emit('handleReject')"
        >Reject</MButton
      >
    </template>
  </MDialog>
</template>

<script lang="ts">
import { MTypography } from "@aeroglobe/ag-core-ui";
import { defineComponent } from "vue";

export default defineComponent({
  name: "RejectOrganizationDialog",
  components: {
    MTypography,
  },
  data() {
    return {
      isMdialogOpen: this.isOpen,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    organization_name: {
      type: String,
      default: "",
      required: true,
    },
  },
  computed: {
    isEliteOrganizationStatusUpdating(): boolean {
      return this.$store.getters.isEliteOrganizationStatusUpdating;
    },
  },
  watch: {
    isOpen(updatedValue) {
      this.isMdialogOpen = updatedValue;
    },
    isMdialogOpen(updatedValue) {
      if (!updatedValue) {
        this.$emit("closeModal");
      }
    },
  },
});
</script>

<style scoped lang="css">
.reject-content {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.note-warning {
  color: var(--m-error-color);
}
.reject-button {
  background-color: var(--m-error-color);
}
</style>
