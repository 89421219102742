import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7aeba5a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SnapshotTile = _resolveComponent("SnapshotTile")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!
  const _component_SnapshotDetailAccordion = _resolveComponent("SnapshotDetailAccordion")!

  return (_openBlock(), _createBlock(_component_SnapshotDetailAccordion, {
    title: "Credit Limit Requests",
    "prepand-icon": "m-credit-limit-management"
  }, {
    overview: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.creditLimitAggregates, (item, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createVNode(_component_SnapshotTile, {
              heading: _ctx.renderItemTitle(item),
              subHeading: _ctx.renderItemValue(item),
              subHeadingTitle: "Count",
              icon: "m-credit-limit-management"
            }, null, 8, ["heading", "subHeading"])
          ]))
        }), 128))
      ])
    ]),
    "accordion-content": _withCtx(() => [
      (_ctx.showDetails)
        ? (_openBlock(), _createBlock(_component_MDataTable, {
            key: 0,
            headers: _ctx.creditLimitSegmentHeader,
            data: _ctx.creditLimitOrganizations,
            "item-per-page": 10,
            simpleDataTable: true,
            hasSearch: true
          }, {
            organization_name: _withCtx(({ item }) => [
              _createElementVNode("div", {
                onClick: ($event: any) => (_ctx.handleOrganizationRowClick($event, item))
              }, [
                _createVNode(_component_MTypography, {
                  class: "description organization-link",
                  type: "label"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.organization_name), 1)
                  ]),
                  _: 2
                }, 1024)
              ], 8, _hoisted_2)
            ]),
            _: 1
          }, 8, ["headers", "data"]))
        : (_openBlock(), _createBlock(_component_ResultNotFound, {
            key: 1,
            title: "No Organization Found",
            description: ""
          }))
    ]),
    _: 1
  }))
}