import { IRestClient } from "@/ag-portal-common/interfaces/restClient.interface";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";
import storageService from "@/ag-portal-common/services/storage.service";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";
import { API_ENDPOINTS } from "@/ag-portal-common/configs/apiEndpoints";
import loggerService from "@/ag-portal-common/services/logger.service";
import { AUTH_EVENTS, authBus } from "@/ag-portal-common/eventBusses/auth";
import UTILS from "../utils";

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

class RestClientService implements IRestClient {
  private readonly axios: AxiosInstance;
  private storageService = storageService;
  private agResponse: IAGResponse<AxiosResponse> = {
    success: false,
    error: null,
    errors: null,
  };
  private whiteListRoutes = [
    API_ENDPOINTS.LOGIN_ENDPOINT,
    API_ENDPOINTS.FORGOT_PASSWORD_ENDPOINT,
    API_ENDPOINTS.REFRESH_TOKEN_ENDPOINT,
  ];
  private DEFAULT_ERROR_MESSAGE = "An error occurred";

  constructor() {
    this.axios = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 180000,
    });
    this.axios.interceptors.request.use((req: InternalAxiosRequestConfig) => {
      const token = this.storageService.getItem<string>(
        STORAGE_KEYS.ACCESS_TOKEN,
        false
      );

      if (token && req.headers) {
        req.headers["Authorization"] = `Bearer ${token}`;
      }
      return req;
    });
    this.axios.interceptors.response.use(
      (response: AxiosResponse): any => {
        return this.handleResponse(response);
      },
      (err: AxiosError) => {
        return this.handleError(err);
      }
    );
  }

  public async get(
    endpoint: string,
    params?: any,
    additionalConfig?: any
  ): Promise<IAGResponse> {
    loggerService.logInfo("RestClientService.get: INITIATED", {
      endpoint,
      params,
      additionalConfig,
    });
    additionalConfig ||= {};
    return this.axios.get(endpoint, { params, ...additionalConfig });
  }

  public async post(
    endpoint: string,
    body: any,
    additionalConfig?: any
  ): Promise<IAGResponse> {
    loggerService.logInfo("RestClientService.post: INITIATED", {
      endpoint,
      body,
      additionalConfig,
    });
    return this.axios.post(endpoint, body, additionalConfig);
  }

  public async put(endpoint: string, body?: any): Promise<IAGResponse> {
    loggerService.logInfo("RestClientService.put: INITIATED", {
      endpoint,
      body,
    });
    return this.axios.put(endpoint, body);
  }

  public async patch(
    endpoint: string,
    body: any,
    params?: any
  ): Promise<IAGResponse> {
    loggerService.logInfo("RestClientService.patch: INITIATED", {
      endpoint,
      body,
    });
    return this.axios.patch(endpoint, body, { params: params || {} });
  }

  public async getById(endpoint: string, id: string): Promise<IAGResponse> {
    loggerService.logInfo("RestClientService.getById: INITIATED", {
      endpoint,
      id,
    });
    endpoint = `${endpoint}/${id}`;
    return this.axios.get(endpoint);
  }

  public async delete(endpoint: string, id: string): Promise<IAGResponse> {
    loggerService.logInfo("RestClientService.delete: INITIATED", {
      endpoint,
      id,
    });
    endpoint = `${endpoint}/${id}`;
    return await this.axios.delete(endpoint);
  }

  private handleResponse(response: AxiosResponse) {
    loggerService.logInfo(
      `RestClientService.handleResponse: INITIATED ${JSON.stringify(response)}`
    );
    this.agResponse = {
      status: response.status,
      data: response?.data?.response || response?.data,
      message: response?.data?.message || "",
      success:
        typeof response?.data?.success === "boolean"
          ? response?.data?.success
          : true,
      error: null,
    };
    loggerService.logInfo(
      `RestClientService.handleResponse: ENDED ${JSON.stringify(
        this.agResponse
      )}`
    );
    return this.agResponse;
  }

  private isEmptyObject(obj: any): boolean {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  private isObject(data: object): boolean {
    return data && data.constructor === Object;
  }

  private isArray(data: string[]): boolean {
    return Array.isArray(data);
  }

  private parseObjectToString(data: object): string | null {
    return !this.isEmptyObject(data) ? JSON.stringify(data) : null;
  }

  private parseArrayToString(data: string[]): string | null {
    return data.length > 0 ? data.join(", ") : null;
  }

  private parseErrorToString(data: any | null): string | null {
    if (this.isObject(data)) {
      return this.parseObjectToString(data);
    } else if (this.isArray(data)) {
      return this.parseArrayToString(data);
    } else {
      return data;
    }
  }

  private async handleError(error: AxiosError) {
    loggerService.logInfo(
      `RestClientService.handleError: INITIATED ${JSON.stringify(error)}`
    );

    const errorConfig = error.config as CustomAxiosRequestConfig;

    let message = "";
    let agResponseMessage = "";
    let errorMessage: string | null = null;
    let errorsMessage: string | null = null;
    let status = StatusCodes.INTERNAL_SERVER_ERROR;
    let bookingErrors = null;

    if (error.response) {
      const errorData = error.response.data as IAGResponse<AxiosResponse>;

      message = errorData.message ?? this.DEFAULT_ERROR_MESSAGE;
      status = errorData.status ?? StatusCodes.INTERNAL_SERVER_ERROR;

      if (
        errorConfig &&
        !this.whiteListRoutes.includes(errorConfig.url ?? "")
      ) {
        if (
          error.response.status === StatusCodes.UNAUTHORIZED &&
          !errorConfig._retry
        ) {
          errorConfig._retry = true;

          return this.refreshTokenCall(errorConfig);
        } else if (error.response.status === StatusCodes.FORBIDDEN) {
          authBus.emit(AUTH_EVENTS.ROUTE_ON_ERROR_403, location.pathname);
        }
      }

      bookingErrors = errorData.errors;
      errorMessage = this.parseErrorToString(errorData.error);
      errorsMessage = this.parseErrorToString(errorData.errors);
    }

    if (message.trim().length >= 0) {
      agResponseMessage = message;
    } else {
      agResponseMessage =
        errorMessage || errorsMessage || this.DEFAULT_ERROR_MESSAGE;
    }

    this.agResponse = {
      error: errorMessage,
      errors: bookingErrors,
      status,
      success: false,
      message: agResponseMessage,
    };

    loggerService.logInfo(
      `RestClientService.handleError: ENDED ${JSON.stringify(this.agResponse)}`
    );

    return Promise.resolve(this.agResponse);
  }

  private async refreshTokenCall(
    originalRequestConfig: CustomAxiosRequestConfig
  ) {
    try {
      loggerService.logInfo(
        "RestClientService.refreshTokenCall: INITIATED",
        originalRequestConfig
      );
      const refreshTokenApiResponse: IAGResponse = await this.post(
        API_ENDPOINTS.REFRESH_TOKEN_ENDPOINT,
        {
          refresh: this.storageService.getItem(
            STORAGE_KEYS.REFRESH_TOKEN,
            false
          ),
        }
      );
      const { access, refresh } = refreshTokenApiResponse.data;

      UTILS.updateAuthTokens(access, refresh);

      return this.axios(originalRequestConfig);
    } catch (_error) {
      loggerService.logError(
        "RestClientService.refreshTokenCall: ERROR",
        _error
      );

      authBus.emit(AUTH_EVENTS.LOGOUT);
    }
  }
}

export default RestClientService;
