import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37e5c2d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "trip-type-radio-btn-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MRadioButton = _resolveComponent("MRadioButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MRadioButton, {
      modelValue: _ctx.selectedRouteType,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRouteType) = $event)),
      name: "trip-type",
      value: _ctx.ROUTE_TYPE.ONEWAY
    }, {
      default: _withCtx(() => [
        _createTextVNode(" One-Way ")
      ]),
      _: 1
    }, 8, ["modelValue", "value"]),
    _createVNode(_component_MRadioButton, {
      modelValue: _ctx.selectedRouteType,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedRouteType) = $event)),
      name: "trip-type",
      value: _ctx.ROUTE_TYPE.RETURN
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Round-Trip ")
      ]),
      _: 1
    }, 8, ["modelValue", "value"]),
    _createVNode(_component_MRadioButton, {
      modelValue: _ctx.selectedRouteType,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedRouteType) = $event)),
      name: "trip-type",
      value: _ctx.ROUTE_TYPE.MULTI_CITY
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Multi-City ")
      ]),
      _: 1
    }, 8, ["modelValue", "value"])
  ]))
}