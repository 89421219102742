<template>
  <div class="passenger-list-item">
    <AgContactInfo
      class="flex-child"
      icon-name="pakFlag"
      :title="renderFullName(passenger)"
    />
    <AgContactInfo
      class="flex-child"
      icon-name="nicIcon"
      :title="renderPassportNumberandExpiry(passenger).number"
      :sub-title="renderPassportNumberandExpiry(passenger).expiry"
    />
    <AgContactInfo
      class="flex-child"
      :icon-name="renderPassengerType(passenger).icon"
      :title="renderPassengerType(passenger).type"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  getTravelerTypeName,
  TravelerTypes,
} from "@/ag-flight-components/constants/TravelerTypes";
import { IFDGBookingPassenger } from "@/interfaces/fdgBookingDetail.interface";

export default defineComponent({
  name: "FDGPassengerItem",
  props: {
    passenger: {
      type: Object as PropType<IFDGBookingPassenger>,
      default: () => ({}),
    },
  },
  methods: {
    renderFullName(passenger: IFDGBookingPassenger): string {
      return `${passenger?.salutation} ${passenger?.first_name} ${passenger?.last_name}`;
    },
    renderPassportNumberandExpiry(passenger: IFDGBookingPassenger): {
      number: string | null | undefined;
      expiry: string;
    } {
      if (passenger.passport_no) {
        const expiryDate = passenger?.passport_expiry || new Date();
        return {
          number: passenger?.passport_no,
          expiry: `Expiry: ${new Date(expiryDate).toLocaleDateString()}`,
        };
      }
      return {
        number: "-",
        expiry: "",
      };
    },
    renderPassengerType(passenger: IFDGBookingPassenger) {
      const type = getTravelerTypeName(
        passenger?.passenger_type as TravelerTypes
      );
      return {
        type,
        icon: `${type?.toLowerCase()}Icon`,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.passenger-list-item {
  border: 1px solid #e6e8f0;
  padding: 25px;
  border-radius: 8px;
  background-color: var(--white-color);
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  max-width: 1200px;
  padding-right: 40px;
  padding-left: 40px;
  margin-bottom: 10px;
}
.flex-child {
  flex: 1 1 calc(33.333% - 10px);
  min-width: 200px;
  box-sizing: border-box;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
