import { Module } from "vuex";
import { IAuthState } from "@/ag-portal-common/interfaces/authState.interface";
import { StoreState } from "@/store/type";

import mutations from "./mutations";
import actions from "./actions";
import state from "./state";
import getters from "./getters";

const authModule: Module<IAuthState, StoreState> = {
  state,
  mutations,
  actions,
  getters,
};

export default authModule;
