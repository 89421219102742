<template>
  <div class="deal-card">
    <div class="deal-header">
      <MTypography type="body" class="text-white deal-name">
        {{ renderDetailsToUpperCase(deal_name) }}
      </MTypography>
    </div>
    <div class="deal-body">
      <div class="item">
        <MIcon name="m-deals" class="deals-icon"></MIcon>
        <MTypography type="label" class="item-details"
          >Discount Code:</MTypography
        >
        <MTypography type="body">{{ discount_code }}</MTypography>
      </div>
      <div class="item">
        <MIcon name="m-deals-amount" class="deals-icon"></MIcon>
        <MTypography type="label" class="item-details">Discount:</MTypography>
        <MTypography type="body">{{ amount }}</MTypography>
      </div>
      <div class="item">
        <MIcon name="m-deals-active" class="deals-icon"></MIcon>
        <MTypography type="label" class="item-details">Active:</MTypography>
        <MTypography type="body">{{ renderBoolean(isActive) }}</MTypography>
      </div>

      <div class="item">
        <MIcon name="m-deals-portal-only" class="deals-icon"></MIcon>
        <MTypography type="label" class="item-details"
          >Booking Via:</MTypography
        >
        <MTypography type="body">{{
          renderBookingType(portalOnly)
        }}</MTypography>
      </div>
      <div class="item">
        <MIcon name="m-deals-validity" class="deals-icon"></MIcon>
        <MTypography type="label" class="item-details">Validity:</MTypography>
        <MTypography type="body">
          {{ getFormattedDateTime(start, FORMAT_DD_MMM_YYYY_WITH_DASH) }}
        </MTypography>

        <m-flight-timeline-icon />
        <MTypography type="body">
          {{ getFormattedDateTime(end, FORMAT_DD_MMM_YYYY_WITH_DASH) }}
        </MTypography>
      </div>
      <div class="item">
        <MIcon name="m-deals-confirmation" class="deals-icon"></MIcon>
        <MTypography type="label" class="item-details"
          >Discount Mode:</MTypography
        >
        <MTypography type="body">{{
          renderDetailsToLowerCase(discount_mode)
        }}</MTypography>
      </div>
      <div class="item">
        <MIcon name="m-deals-flight" class="deals-icon"></MIcon>
        <MTypography type="label" class="item-details"
          >Applicable On:</MTypography
        >
        <MTypography type="body">{{ applicable_on }}</MTypography>
      </div>
      <div class="deal-tags">
        <div class="deal-tag">
          <MChip
            variant="warning"
            :prependIcon="amountType"
            :border-less="true"
            v-if="grossApply"
          >
            Gross Fare
          </MChip>
          <MChip
            variant="error"
            :prependIcon="amountType"
            :border-less="true"
            v-else
          >
            Base Fare
          </MChip>
        </div>
        <div class="deal-tag">
          <MChip
            variant="info"
            prependIcon="m-flight-takeoff"
            appendIcon="m-deals-info"
            :border-less="true"
            class="tooltip-container"
          >
            {{ renderRoutes }}
            <span class="tooltip-text">{{ renderToolTip }}</span>
          </MChip>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { MChip, MIcon, MTypography } from "@aeroglobe/ag-core-ui";
import { defineComponent } from "vue";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { FORMAT_DD_MMM_YYYY_WITH_DASH } from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "DealsComponent",
  components: {
    MChip,
    MIcon,
    MTypography,
  },
  data() {
    return {
      animationState: false,
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
    };
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    start: {
      type: Date,
      required: true,
    },
    end: {
      type: Date,
      required: true,
    },
    deal_name: {
      type: String,
      required: true,
    },
    discount_code: {
      type: String,
      required: true,
    },
    amount: {
      type: String,
      required: true,
    },
    discount_mode: {
      type: String,
      required: true,
    },
    portalOnly: {
      type: Boolean,
      required: true,
    },
    applicable_on: {
      type: String,
      required: true,
    },
    isFeatured: {
      type: Boolean,
      required: true,
    },
    grossApply: {
      type: Boolean,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
    flightSector: {
      type: String,
    },
    originAirports: {
      type: String,
    },
    destinationAirports: {
      type: String,
    },
  },
  computed: {
    themeColor() {
      const root = document.documentElement;
      const mainColor = getComputedStyle(root)
        .getPropertyValue("--green-color")
        .trim();
      return mainColor;
    },
    renderRoutes(): string {
      switch (this.route) {
        case "ALL":
          return "All Routes";
        case "SECTOR":
          return "Selected Routes";
        case "ORIGIN":
          return "Selected Origin Airports";
        case "DESTINATION":
          return "Selected Destination Airports";
        default:
          return "";
      }
    },
    renderToolTip(): string {
      switch (this.route) {
        case "ALL":
          return "All Routes";
        case "SECTOR":
          return this.flightSector ?? "";
        case "ORIGIN":
          return this.originAirports ?? "";
        case "DESTINATION":
          return this.destinationAirports ?? "";
        default:
          return "";
      }
    },
    amountType(): string {
      return this.type === "FIXED" ? "m-payment-management" : "m-deals-percent";
    },
  },
  methods: {
    getFormattedDateTime,
    renderBoolean(value: boolean) {
      return value === true ? "Yes" : "No";
    },
    renderBookingType(value: boolean) {
      return value === true ? "Portal Bookings Only" : "All Bookings";
    },
    renderDetailsToUpperCase(value: string) {
      return value.replace(/[_-]/g, " ").toUpperCase();
    },
    renderDetailsToLowerCase(value: string) {
      return value
        .toLowerCase()
        .replace(/[_-]/g, " ")
        .replace(/(^\w|\s\w)/g, (match: string) => match.toUpperCase());
    },
  },
});
</script>

<style lang="css" scoped>
.item-details {
  margin-right: 5px;
}
.deal-card {
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  box-shadow: 0px 6px 8px var(--m-divider-color);
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.4s ease;
  transform: scale(1);
}

.deal-header {
  background: var(--green-color);
  color: #fff;
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.deal-body {
  padding: 10px;
  color: #333;
}

.deal-tags {
  margin-top: 4px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.deal-tag {
  border-radius: 20px;
  margin: 2px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.deals-icon {
  margin-right: 10px;
}
.deal-name {
  text-transform: capitalize;
}
.tooltip-container {
  position: relative;
  cursor: pointer;
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  margin-left: -80px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
</style>
