import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createBlock(_component_MDialog, {
    title: "Delete Passenger",
    modelValue: _ctx.isMdialogOpen,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isMdialogOpen) = $event)),
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('handleReject')))
  }, {
    content: _withCtx(() => [
      _createVNode(_component_MTypography, null, {
        default: _withCtx(() => [
          _createTextVNode("Are you sure to delete passenger?")
        ]),
        _: 1
      })
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_MButton, {
        type: "outlined",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handleReject')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" No ")
        ]),
        _: 1
      }),
      _createVNode(_component_MButton, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('handleAccept')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Yes ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}