<template>
  <notification-wrapper>
    <authentication-wrapper>
      <router-view />
    </authentication-wrapper>
  </notification-wrapper>
</template>

<script lang="ts">
import Mitt from "mitt";
import { defineComponent } from "vue";

import NotificationWrapper from "@/ag-portal-common/components/NotificationWrapper.vue";
import AuthenticationWrapper from "./modules/Auth/components/AuthenticationWrapper.vue";

import { AUTH_EVENTS } from "@/ag-portal-common/eventBusses/auth";
import { NOTIFICATION_EVENTS } from "@/ag-portal-common/eventBusses/notification";
import { setThemeColor } from "@/ag-portal-common/utils/colortheme";
import { AGENT_FINANCES_EVENTS } from "@/ag-portal-common/eventBusses/agentFinances";
import storageService from "./ag-portal-common/services/storage.service";
import { Organization } from "./modules/Auth/types";
import { STORAGE_KEYS } from "./ag-portal-common/constants/storageKeys";

export default defineComponent({
  name: "AppView",
  components: {
    NotificationWrapper,
    AuthenticationWrapper,
  },
  beforeCreate() {
    const organization = storageService.getItem<Organization>(
      STORAGE_KEYS.ORGANIZATION
    );

    if (organization) {
      setThemeColor(organization);
    }
  },
  beforeUnmount() {
    const mitt = Mitt();

    mitt.off(NOTIFICATION_EVENTS.TRIGGER);
    mitt.off(AUTH_EVENTS.LOGIN);
    mitt.off(AUTH_EVENTS.LOGOUT);
    mitt.off(AGENT_FINANCES_EVENTS.REFRESH);
  },
});
</script>

<style lang="scss"></style>
