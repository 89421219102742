import { MutationTree } from "vuex";
import { IAuthState } from "@/ag-portal-common/interfaces/authState.interface";
import { PERMISSIONS } from "@/ag-portal-common/enums/PERMISSIONS";
import {
  AuthToken,
  FinancialProfile,
  LoginResponse,
  Organization,
  Sector,
  User,
} from "../types";
import UTILS from "@/ag-portal-common/utils";

export enum AUTH_MUTATIONS {
  /**
   * This mutation saves the login response.
   * @param state - The state of the store.
   * @param {LoginResponse} payload - The payload of the login response.
   * @example
   * // Mutates the state
   * this.$store.commit(AUTH_MUTATIONS.SAVE_LOGIN_RESPONSE, response);
   */
  SAVE_LOGIN_RESPONSE = "SAVE_LOGIN_RESPONSE",

  /**
   * This mutation resets the login response.
   * @param state - The state of the store.
   * @example
   * // Mutates the state
   * this.$store.commit(AUTH_MUTATIONS.RESET_LOGIN_RESPONSE);
   */
  RESET_LOGIN_RESPONSE = "RESET_LOGIN_RESPONSE",

  /**
   * This mutation toggles the authentication status.
   * @param state - The state of the store.
   * @param {boolean} payload - The new authentication status.
   * @example
   * // Mutates the state
   * this.$store.commit(AUTH_MUTATIONS.TOGGLE_IS_AUTHENTICATING, true);
   */
  TOGGLE_IS_AUTHENTICATING = "TOGGLE_IS_AUTHENTICATING",

  /**
   * This mutation toggles the forgetting password status.
   * @param state - The state of the store.
   * @param {boolean} payload - The new forgetting password status.
   * @example
   * // Mutates the state
   * this.$store.commit(AUTH_MUTATIONS.TOGGLE_IS_FORGETTING_PASSWORD, true);
   */
  TOGGLE_IS_FORGETTING_PASSWORD = "TOGGLE_IS_FORGETTING_PASSWORD",

  /**
   * This mutation toggles the resetting password status.
   * @param state - The state of the store.
   * @param {boolean} payload - The new resetting password status.
   * @example
   * // Mutates the state
   * this.$store.commit(AUTH_MUTATIONS.TOGGLE_IS_RESETTING_PASSWORD, true);
   */
  TOGGLE_IS_RESETTING_PASSWORD = "TOGGLE_IS_RESETTING_PASSWORD",

  /**
   * This mutation updates the user information.
   * @param state - The state of the store.
   * @param {User} payload - The new user information.
   * @example
   * // Mutates the state
   * this.$store.commit(AUTH_MUTATIONS.UPDATE_USER, user);
   */
  UPDATE_USER = "UPDATE_USER",

  /**
   * This mutation updates the organization information.
   * @param state - The state of the store.
   * @param {Organization} payload - The new organization information.
   * @example
   * // Mutates the state
   * this.$store.commit(AUTH_MUTATIONS.UPDATE_ORGANIZATION, organization);
   */
  UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION",

  /**
   * This mutation updates the sectors information.
   * @param state - The state of the store.
   * @param {Sector[]} payload - The new sectors information.
   * @example
   * // Mutates the state
   * this.$store.commit(AUTH_MUTATIONS.UPDATE_SECTORS, sectors);
   */
  UPDATE_SECTORS = "UPDATE_SECTORS",

  /**
   * This mutation updates the financial profiles information.
   * @param state - The state of the store.
   * @param {FinancialProfile[]} payload - The new financial profiles information.
   * @example
   * // Mutates the state
   * this.$store.commit(AUTH_MUTATIONS.UPDATE_FINANCIAL_PROFILES, financialProfiles);
   */
  UPDATE_FINANCIAL_PROFILES = "UPDATE_FINANCIAL_PROFILES",

  /**
   * This mutation updates the authentication tokens.
   * @param state - The state of the store.
   * @param {AuthToken} payload - The new authentication tokens.
   * @example
   * // Mutates the state
   * this.$store.commit(AUTH_MUTATIONS.UPDATE_AUTH_TOKENS, tokens);
   */
  UPDATE_AUTH_TOKENS = "UPDATE_AUTH_TOKENS",

  /**
   * This mutation updates the permissions.
   * @param state - The state of the store.
   * @param {PERMISSIONS[]} payload - The new permissions.
   * @example
   * // Mutates the state
   * this.$store.commit(AUTH_MUTATIONS.PERMISSIONS, permissions);
   */
  PERMISSIONS = "PERMISSIONS",
}

const mutations: MutationTree<IAuthState> = {
  [AUTH_MUTATIONS.SAVE_LOGIN_RESPONSE](state, payload: LoginResponse) {
    state.user = payload.user;
    state.token = payload.token;
    state.permissions = payload.permissions;

    const organization = payload.user.agent_user_data.organization;
    state.organization = organization;

    if (organization) {
      state.financialProfiles = UTILS.mapSectorInFinancialProfiles(
        organization.sector_name,
        organization.financial_profiles
      );
    }

    const sectors = payload.user.sales_user_data.sectors;
    state.sectors = sectors;

    state.isUserSignedIn = true;
  },
  [AUTH_MUTATIONS.RESET_LOGIN_RESPONSE](state) {
    state.user = null;
    state.token = {
      access: "",
      refresh: "",
    };
    state.permissions = [];

    state.financialProfiles = undefined;
    state.sectors = undefined;

    state.isUserSignedIn = false;
  },

  [AUTH_MUTATIONS.TOGGLE_IS_AUTHENTICATING](state, payload: boolean) {
    state.isAuthenticating = payload;
  },
  [AUTH_MUTATIONS.TOGGLE_IS_FORGETTING_PASSWORD](state, payload: boolean) {
    state.isForgettingPassword = payload;
  },
  [AUTH_MUTATIONS.TOGGLE_IS_RESETTING_PASSWORD](state, payload: boolean) {
    state.isResettingPassword = payload;
  },

  [AUTH_MUTATIONS.UPDATE_USER](state, payload: User) {
    state.user = payload;

    const organization = payload.agent_user_data.organization;
    state.organization = organization;

    if (organization) {
      state.financialProfiles = UTILS.mapSectorInFinancialProfiles(
        organization.sector_name,
        organization.financial_profiles
      );
    }

    const sectors = payload.sales_user_data.sectors;
    state.sectors = sectors;
  },
  [AUTH_MUTATIONS.UPDATE_ORGANIZATION](
    state,
    payload: Organization | undefined
  ) {
    state.organization = payload;
    state.financialProfiles = payload
      ? UTILS.mapSectorInFinancialProfiles(
          payload.sector_name,
          payload.financial_profiles
        )
      : undefined;
  },
  [AUTH_MUTATIONS.UPDATE_FINANCIAL_PROFILES](
    state,
    payload: FinancialProfile[]
  ) {
    state.financialProfiles = payload;
  },
  [AUTH_MUTATIONS.UPDATE_SECTORS](state, payload: Sector[]) {
    state.sectors = payload;
  },
  [AUTH_MUTATIONS.UPDATE_AUTH_TOKENS](state, payload: AuthToken) {
    state.token = payload;
  },
  [AUTH_MUTATIONS.PERMISSIONS](state, payload: PERMISSIONS[]) {
    state.permissions = payload;
  },
};

export default mutations;
