import { ActionContext, ActionTree } from "vuex";
import download from "downloadjs";
import { StatusCodes } from "http-status-codes";

import {
  IAGErrorResponse,
  IAGResponse,
} from "@/ag-portal-common/interfaces/agResponse.interface";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";

import { IFlightBooking } from "@/ag-flight-components/interfaces/IFlightBooking.interface";

import FlightBookingService from "@/ag-flight-components/services/booking.service";
import {
  GetBookingPayloadType,
  GetDownloadBookingPayloadType,
  ICancelBookingPayloadType,
  IConfirmBooking,
  IVoidBookingPayloadType,
  Traveler,
} from "@/ag-flight-components/types/FlightBookingForm";
import { BookingFormTravelerData } from "@/ag-flight-components/constants/bookingForm";
import { TravelerTypes } from "@/ag-flight-components/constants/TravelerTypes";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { CACHE_NAMES } from "@/ag-portal-common/constants";
import { IPassenger } from "@/ag-portal-common/interfaces/passenger.interface";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { StoreState } from "@/store/type";
import { BookingErrorMessage } from "../constants";

export interface SendAirlineTicketBodyRequest {
  pnr: string;
  airline_provider: string;
  emails: string[];
}

const actions: ActionTree<IFlightBooking, StoreState> = {
  async sendAirlineTicket(
    context: ActionContext<IFlightBooking, StoreState>,
    {
      payload,
      callback,
    }: { payload: SendAirlineTicketBodyRequest; callback: () => void }
  ) {
    const methodName = "actions.downloadAirlineTicket";

    context.commit("enableIsSendingAirlineTicket");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

    const myBookingsService = new FlightBookingService();

    await myBookingsService.sendAirlineTicket(payload);

    context.commit("disableIsSendingAirlineTicket");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);

    callback();
  },

  // **** Get Flight Booking ****
  async getFlightBooking(
    context: ActionContext<IFlightBooking, StoreState>,
    payload: GetBookingPayloadType
  ) {
    const methodName = "actions.getFlightBooking";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setLoading", true);

      const flightBooking = new FlightBookingService();
      const response: IAGResponse = await flightBooking.getBooking(payload);

      if (response.success && response.status === StatusCodes.OK) {
        const flightDetailResponse = response.data?.data || {};
        const flightDetails = flightDetailResponse.flight_details;

        context.commit("saveBookingStatus", flightDetailResponse?.status);
        context.commit(
          "saveBookingActions",
          flightDetailResponse.booking_actions
        );
        context.commit(
          "savePnrExpiry",
          flightDetailResponse?.confirmed_booking_expires_at
        );
        context.commit("saveSupplierPnr", flightDetails?.supplier_pnr);
        context.commit("saveFlightDetails", flightDetails);

        const travelerFormItems = [
          ...Array.from(Array(flightDetails.adult_count).keys()).map(() => ({
            ...BookingFormTravelerData,
            is_international: flightDetails?.is_international,
            passenger_type: TravelerTypes.ADULT,
            nationality: "PK",
          })),
          ...Array.from(Array(flightDetails.child_count).keys()).map(() => ({
            ...BookingFormTravelerData,
            is_international: flightDetails?.is_international,
            passenger_type: TravelerTypes.CHILD,
            nationality: "PK",
          })),
          ...Array.from(Array(flightDetails.infant_count).keys()).map(() => ({
            ...BookingFormTravelerData,
            is_international: flightDetails?.is_international,
            passenger_type: TravelerTypes.INFANT,
            nationality: "PK",
          })),
        ];

        context.commit("saveTravelers", travelerFormItems);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("setLoading", false);
    }
  },

  // **** Confirm Flight Booking ****
  async confirmFlightBooking(
    context: ActionContext<IFlightBooking, StoreState>,
    {
      payload,
      successCallback,
      errorCallback,
    }: {
      payload: IConfirmBooking;
      successCallback: (booking_id: string) => void;
      errorCallback: () => void;
    }
  ) {
    const methodName = "actions.confirmFlightBooking";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setConfirmBookingLoading", true);

      context.commit("setBookingDetailError", null);
      const flightBooking = new FlightBookingService();
      const response = await flightBooking.confirmBooking(payload);

      if (response.success && response.status === StatusCodes.OK) {
        const booking_id = response?.data?.data?.booking_id;

        await caches.delete(CACHE_NAMES.flightsBookings);

        successCallback(booking_id);

        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
      context.commit("setBookingDetailError", exception?.errors);

      if (exception.message && exception.message === BookingErrorMessage) {
        errorCallback();
      }
    } finally {
      context.commit("setConfirmBookingLoading", false);
    }
  },

  // **** Cancel Flight Booking ****
  async cancelFlightBooking(
    context: ActionContext<IFlightBooking, StoreState>,
    {
      payload,
      successCallback,
    }: {
      payload: ICancelBookingPayloadType;
      successCallback: () => void;
    }
  ) {
    const methodName = "actions.cancelFlightBooking";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setCancelBookingLoading", true);

      const flightBooking = new FlightBookingService();
      const response: IAGResponse = await flightBooking.cancelBooking(payload);

      if (response.success && response.status === StatusCodes.OK) {
        await caches.delete(CACHE_NAMES.flightsBookings);

        successCallback();

        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("setCancelBookingLoading", false);
    }
  },

  // **** Cancel Flight Booking ****
  async downloadFlightBooking(
    context: ActionContext<IFlightBooking, StoreState>,
    payload: GetDownloadBookingPayloadType
  ) {
    const methodName = "actions.downloadFlightBooking";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setDownloadTicketLoading", true);

      const flightBooking = new FlightBookingService();
      const response: IAGResponse = await flightBooking.downloadFlightTicket(
        payload
      );

      if (response.success && response.status === StatusCodes.OK) {
        download(response.data, payload.pnr + "-E-ticket.pdf");

        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("setDownloadTicketLoading", false);
    }
  },

  // **** Void Flight Booking ****
  async voidPIAFlightBooking(
    context: ActionContext<IFlightBooking, StoreState>,
    {
      payload,
      successCallback,
    }: {
      payload: IVoidBookingPayloadType;
      successCallback: () => void;
    }
  ) {
    const methodName = "actions.voidPIAFlightBooking";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setVoidTicketLoading", true);

      const flightBooking = new FlightBookingService();
      const response: IAGResponse = await flightBooking.voidPIABooking(payload);

      if (response.success && response.status === StatusCodes.OK) {
        await caches.delete(CACHE_NAMES.flightsBookings);

        successCallback();

        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = response.message?.toString() || "";
        notificationService.triggerNotification();
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
      loggerService.logError(`${methodName}:`, exception);
    } finally {
      context.commit("setVoidTicketLoading", false);
    }
  },

  // **** Get Organization Passengers ****
  async getOrganizationPassengers(
    context: ActionContext<IFlightBooking, StoreState>,
    organizationId: string
  ) {
    const methodName = "actions.getOrganizationPassengers";
    try {
      context.commit("setPassengerLoading", true);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

      const flightBooking = new FlightBookingService();
      const response: IAGResponse =
        await flightBooking.getOrganizationPassengers(organizationId);

      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setPassengerLoading", false);

        const passengers: IPassenger[] = response.data;
        const updatedPassengers = passengers.map((x) => {
          return {
            ...x,
            fullName: x?.first_name + " " + x?.last_name,
          };
        });

        context.commit("setPassengers", updatedPassengers);
      } else {
        context.commit("setPassengerLoading", false);
        throw response;
      }
    } catch (exception) {
      context.commit("setPassengerLoading", false);
      loggerService.logError(`${methodName}:`, exception);
    }
  },

  async createPassengers(
    context: ActionContext<IFlightBooking, StoreState>,
    { body, organizationId }: { body: Traveler[]; organizationId: string }
  ) {
    const methodName = "actions.createPassengers";
    try {
      context.commit("setPassengerActionLoading", true);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

      const flightBooking = new FlightBookingService();
      const response: IAGResponse = await flightBooking.createPassengers(
        body,
        organizationId
      );

      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setPassengerActionLoading", false);
      } else {
        context.commit("setPassengerActionLoading", false);
        throw response;
      }
    } catch (exception) {
      context.commit("setPassengerActionLoading", false);
      loggerService.logError(`${methodName}:`, exception);
    }
  },
};

export default actions;
