import { Module } from "vuex";
import { IVisaVerificationState } from "@/ag-portal-common/interfaces/visaVerificationState.interface";
import { StoreState } from "@/store/type";

import mutations from "./mutations";
import actions from "./actions";
import state from "./state";
import getters from "./getters";

const visaVerificationModule: Module<IVisaVerificationState, StoreState> = {
  state,
  mutations,
  actions,
  getters,
};

export default visaVerificationModule;
