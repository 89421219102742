import { RouteRecordRaw } from "vue-router";
import FlightSearchResult from "@/modules/FlightSearchResult/views/FlightSearchResult.vue";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import { PATH } from "@/ag-portal-common/constants/path";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.FLIGHTS_SEARCH_RESULT_V1,
    name: "FlightSearchResultV1",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.PERMISSIONS,
    },
    component: FlightSearchResult,
  },
];

export default routes;
