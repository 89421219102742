import * as yup from "yup";
import { Place } from "../types";
import { ROUTE_TYPE } from "../enums/route_type";

export const normalFlightSearchValidation = yup.object().shape({
  origin: yup.string().nullable().required("Please select origin"),
  destination: yup.string().nullable().required("Please select destination"),
  departure_date: yup
    .string()
    .nullable()
    .when("route_type", {
      is: ROUTE_TYPE.RETURN,
      then: yup.string().nullable().required("Please select departure date"),
      otherwise: yup
        .string()
        .nullable()
        .required("Please select departure date"),
    }),
  return_date: yup
    .string()
    .nullable()
    .when("route_type", {
      is: ROUTE_TYPE.RETURN,
      then: yup.string().nullable().required("Please select return date"),
      otherwise: yup.string().nullable(),
    }),
  route_type: yup.string().required("Please select route type"),
});

export const createMulticityFlightSearchValidation = () => {
  return yup.array().of(
    yup.object().shape({
      origin: yup.object().nullable().required("Please select origin"),
      destination: yup
        .object()
        .nullable()
        .required("Please select destination"),
      departure_date: yup.date().required("Please select departure date"),
    })
  );
};
