import { Module } from "vuex";
import { IFlightIssuanceState } from "@/ag-flight-components/interfaces/IFlightIssuance.interface";
import { StoreState } from "@/store/type";

import mutations from "./mutations";
import actions from "./actions";
import state from "./state";
import getters from "./getters";

const flightIssuanceModule: Module<IFlightIssuanceState, StoreState> = {
  state,
  mutations,
  actions,
  getters,
};

export default flightIssuanceModule;
