import { FlightOption, ReturnFlightPair } from "@/ag-flight-components/types";
import storageService from "@/ag-portal-common/services/storage.service";
import { QuotationType } from "../types";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import analyticServices from "@/analytic.service";
import { QUOTATIONS_ANALYTICS_EVENTS } from "../constants/anaylticsEvents";
import store from "@/store";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { User } from "@/modules/Auth/types";

export class QuotationManager {
  private prefix = "";

  constructor() {
    const user: User = store.getters[AUTH_GETTERS.USER];

    this.prefix = user.email;
  }

  get flights(): FlightOption[] {
    const flights = storageService.getItem<FlightOption[]>(
      this.storageKey(QuotationType.FLIGHT)
    );

    return flights ?? [];
  }

  get pairs(): ReturnFlightPair[] {
    const pairs = storageService.getItem<ReturnFlightPair[]>(
      this.storageKey(QuotationType.PAIR)
    );

    return pairs ?? [];
  }

  storageKey(type: QuotationType): string {
    return `${type}-${this.prefix}`;
  }

  addFlight(flightQuote: FlightOption): void {
    const isFlightAdded = this.flights.some(
      (flight) =>
        flight.fare_options[0].pre_booking_token ==
        flightQuote.fare_options[0].pre_booking_token
    );

    if (!isFlightAdded) {
      const updatedFlights = [flightQuote, ...this.flights];

      storageService.setItem(
        this.storageKey(QuotationType.FLIGHT),
        JSON.stringify(updatedFlights)
      );
      notificationService.type = NOTIFICATION_TYPES.SUCCESS;
      notificationService.description = "Flight Added To Quotations";
      notificationService.triggerNotification();
    } else {
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = "Flight already exists in Quotations";
      notificationService.triggerNotification();
    }

    analyticServices.logActionEvent(QUOTATIONS_ANALYTICS_EVENTS.ADD_FLIGHT, {
      "booking-token": flightQuote.fare_options[0].pre_booking_token,
    });
  }

  addPair(pairQuote: ReturnFlightPair): void {
    const isPairAdded = this.pairs.some(
      (pair) =>
        pair.arrival.fare_option.pre_booking_token ==
        pairQuote.arrival.fare_option.pre_booking_token
    );

    if (!isPairAdded) {
      const updatedPairs = [pairQuote, ...this.pairs];

      storageService.setItem(
        this.storageKey(QuotationType.PAIR),
        JSON.stringify(updatedPairs)
      );
      notificationService.type = NOTIFICATION_TYPES.SUCCESS;
      notificationService.description = "Pair Added To Quotations";
      notificationService.triggerNotification();
    } else {
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = "Pair already exists in Quotations";
      notificationService.triggerNotification();
    }

    analyticServices.logActionEvent(QUOTATIONS_ANALYTICS_EVENTS.ADD_PAIR, {
      "booking-token": pairQuote.arrival.fare_option.pre_booking_token,
    });
  }

  deleteFlight(flightQuote: FlightOption): void {
    const updatedFlights = this.flights.filter(
      (flight) =>
        flight.fare_options[0].pre_booking_token !=
        flightQuote.fare_options[0].pre_booking_token
    );

    storageService.setItem(
      this.storageKey(QuotationType.FLIGHT),
      JSON.stringify(updatedFlights)
    );

    notificationService.type = NOTIFICATION_TYPES.INFO;
    notificationService.description = "Flight deleted from Quotations";
    notificationService.triggerNotification();
  }

  deletePair(pairQuote: ReturnFlightPair): void {
    const updatedPairs = this.pairs.filter(
      (pair) =>
        pair.arrival.fare_option.pre_booking_token !=
        pairQuote.arrival.fare_option.pre_booking_token
    );

    storageService.setItem(
      this.storageKey(QuotationType.PAIR),
      JSON.stringify(updatedPairs)
    );

    notificationService.type = NOTIFICATION_TYPES.INFO;
    notificationService.description = "Pair deleted from Quotations";
    notificationService.triggerNotification();
  }

  clearAll(): void {
    storageService.removeItem(this.storageKey(QuotationType.FLIGHT));
    storageService.removeItem(this.storageKey(QuotationType.PAIR));
  }
}
