import { GetterTree } from "vuex";

import {
  CalculatedFareV3,
  PassengersList,
  PNRDetails,
  PNRDetailsV3,
} from "@/ag-flight-components/types";
import { IFlightIssuanceState } from "@/ag-flight-components/interfaces/IFlightIssuance.interface";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { SalesUser } from "@/ag-flight-components/types";
import { IApplyFlightPromoCodeResponse } from "@/ag-portal-common/interfaces/flightIssuance.interface";
import { StoreState } from "@/store/type";

const getters: GetterTree<IFlightIssuanceState, StoreState> = {
  isFetchingPNR: (state): boolean => state.fetchingPNR,
  isCalculatingFare: (state): boolean => state.calculatingFareV3,
  calculatedFare: (state): CalculatedFareV3 | null => state.calculatedFareV3,
  isIssueTicketLoading: (state): boolean => state.issueTicketLoading,
  pnrDetails: (state): PNRDetails | null => state.pnrDetail,
  pnrDetailsV3: (state): PNRDetailsV3 | null => state.pnrDetailV3,
  pnr: (state): string | null => state.pnr,
  financialProfilePublicId: (state): string | null =>
    state.financialProfilePublicId,
  airTravelers: (state): PassengersList[] => state.pnrDetail?.passengers || [],
  isTicketIssued: (state): boolean => state.isTicketIssued,
  issuanceFinancialProfiles: (state): IFinancialProfile[] =>
    state.issuanceFinancialProfiles,
  isFinancialProfilesFetching: (state): boolean =>
    state.isFinancialProfilesFetching,
  salesUsersList: (state): SalesUser[] => state.salesUsersList,
  isApplyFlightPromoLoading: (state): boolean => state.isApplyPromoCodeLoading,
  promoCodeResponse: (state): IApplyFlightPromoCodeResponse | null =>
    state.promoCodeResponse,
};

export default getters;
