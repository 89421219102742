<template>
  <MCard
    :class="[
      'pv-flight-card-mq',
      { active: isSideSheetOpen, selected, printing: isPrinting },
    ]"
    v-if="departureFlightOption && arrivalFlightOption"
  >
    <div class="pv-details">
      <img
        class="pv-flight-image"
        :src="departureFlightOption.airline.logo"
        alt="flight-image"
        width="24"
        height="24"
      />

      <div class="pv-column pv-header">
        <div class="pv-airline-title m-textoverflow">
          {{ departureFlightOption.airline.name }}
        </div>
        <div class="pv-airline-number">
          {{ departureFlightOption.flight_numbers[0] }}
        </div>
        <div class="pv-date">
          {{ getDepartureTime(departureFlightOption.departure_time) }}
        </div>
      </div>

      <div class="flight-details">
        <FlightRoute
          :details="getRouteDetails('departure')"
          :view-type="isCompactView ? ListViewType.SINGLE : ListViewType.PAIR"
        />
      </div>

      <MDivider
        :class="['pv-divider', { compact: isCompactView }]"
        :type="isCompactView ? 'horizontal' : 'vertical'"
      />

      <img
        class="pv-flight-image"
        :src="arrivalFlightOption.airline.logo"
        alt="flight-image"
        width="24"
        height="24"
      />

      <div class="pv-column pv-header">
        <div class="pv-airline-title m-textoverflow">
          {{ arrivalFlightOption.airline.name }}
        </div>
        <div class="pv-airline-number">
          {{ arrivalFlightOption.flight_numbers[0] }}
        </div>
        <div class="pv-date">
          {{ getDepartureTime(arrivalFlightOption.departure_time) }}
        </div>
      </div>

      <div class="flight-details fd-return">
        <FlightRoute
          :details="getRouteDetails('arrival')"
          :view-type="isCompactView ? ListViewType.SINGLE : ListViewType.PAIR"
        />
      </div>

      <div class="pv-actions">
        <div class="pv-info-action">
          <template v-if="!isPrinting">
            <MIcon
              class="pv-icon pv-delete"
              title="Delete Option"
              name="m-delete"
              @click="onDeleteOptionHandler"
            />
            <MDivider type="vertical" />

            <MIcon
              class="pv-mobile-action pv-icon primary"
              name="m-sidebar"
              width="18"
              height="18"
              @click="onViewDetailsHandler"
            />
            <MDivider class="pv-mobile-action" type="vertical" />

            <MCheckbox
              v-model="selected"
              class="pv-icon"
              title="Select Option"
              name="m-post-add"
              @update:model-value="onSelectedPairHandler"
            />
          </template>
        </div>

        <div
          v-if="!isPrinting"
          class="pv-details-action"
          @click="onViewDetailsHandler"
        >
          <div class="sfd-title">View Details</div>
          <MIcon class="pv-icon primary" name="m-sidebar" />
        </div>
      </div>
    </div>

    <PairedFareOptions
      :departure-fare-option="departureFareOption"
      :arrival-fare-option="arrivalFareOption"
      :temper-fare="temperFare"
      :is-printing="isPrinting"
      :is-compact-view="isCompactView"
    />

    <MSideSheet v-model="isSideSheetOpen" title="Flight Details">
      <div class="pvs-flight-details">
        <div class="pvs-flight-info">
          <div class="pvs-flight-info-title">
            ({{ getRouteDetails("departure").originCode }} -
            {{ getRouteDetails("departure").destinationCode }})
            {{ getRouteDetails("departure").originTime }} -
            {{ getRouteDetails("departure").destinationTime }} ({{
              getRouteDetails("departure").duration
            }})
          </div>

          <div class="pvs-flight-info-subtitle">
            <img
              class="pvs-flight-info-row-image"
              :src="departureFlightOption.airline.logo"
              alt="flight-image"
              width="18"
              height="18"
            />
            <div>
              {{ departureFlightOption.airline.name }} •
              {{ departureFlightOption.flight_numbers.join(", ") }}
            </div>
          </div>
        </div>

        <MDivider class="pvs-divider" />

        <div class="pvs-flight-info">
          <div class="pvs-flight-info-title">
            ({{ getRouteDetails("arrival").originCode }} -
            {{ getRouteDetails("arrival").destinationCode }})
            {{ getRouteDetails("arrival").originTime }} -
            {{ getRouteDetails("arrival").destinationTime }} ({{
              getRouteDetails("arrival").duration
            }})
          </div>

          <div class="pvs-flight-info-subtitle">
            <img
              class="pvs-flight-info-row-image"
              :src="arrivalFlightOption.airline.logo"
              alt="flight-image"
              width="18"
              height="18"
            />
            <div>
              {{ arrivalFlightOption.airline.name }} •
              {{ arrivalFlightOption.flight_numbers.join(", ") }}
            </div>
          </div>
        </div>

        <div v-show="flightDetailsSegments[0].checked">
          <div class="pvs-segment-title spacer">Departure Flight</div>

          <FlightSegments :segments="departureFareOption.segments" />

          <div class="pvs-segment-title">Return Flight</div>

          <FlightSegments :segments="arrivalFareOption.segments" />
        </div>
      </div>
    </MSideSheet>
  </MCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { MCard, MIcon, MDivider, MSideSheet } from "@aeroglobe/ag-core-ui";

import FlightRoute from "./FlightRoute.vue";
import PairedFareOptions from "./PairedFareOptions.vue";
import FlightSegments from "./FlightSegments.vue";

import {
  FlightRulesResponse,
  ListViewType,
  RouteDetails,
  TemperFare,
} from "../../FlightSearchResult/types";
import {
  FareOption,
  FlightOption,
  ReturnFlightPair,
} from "@/ag-flight-components/types";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";
import { MSegment } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";

export default defineComponent({
  name: "SingleFlightCard",
  components: {
    MCard,
    MIcon,
    MDivider,
    MSideSheet,

    FlightRoute,
    PairedFareOptions,
    FlightSegments,
  },
  props: {
    pair: {
      type: Object as PropType<ReturnFlightPair>,
      required: true,
    },
    flightsFareRules: {
      type: Object as PropType<Map<string, FlightRulesResponse>>,
    },
    temperFare: {
      type: Object as PropType<TemperFare>,
      required: true,
    },
    isPrinting: {
      type: Boolean,
      default: false,
    },
    isCompactView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["delete:option"],
  computed: {
    departureFlightOption(): FlightOption | undefined {
      return this.pair.departure.flight_option;
    },
    departureFareOption(): FareOption {
      return this.pair.departure.fare_option;
    },
    arrivalFlightOption(): FlightOption | undefined {
      return this.pair.arrival.flight_option;
    },
    arrivalFareOption(): FareOption {
      return this.pair.arrival.fare_option;
    },
  },
  data() {
    return {
      ListViewType,
      isSideSheetOpen: false,
      flightDetailsSegments: [
        {
          label: "Flight Info",
          checked: true,
        },
        {
          label: "Cancellation",
          checked: false,
        },
        {
          label: "Date Change",
          checked: false,
        },
        {
          label: "Baggage",
          checked: false,
        },
      ] as MSegment[],

      selected: false,
    };
  },
  methods: {
    getRouteDetails(type: keyof ReturnFlightPair): RouteDetails {
      const segments = this.pair[type].fare_option.segments;
      const duration = this.getDuration(
        this.pair[type].flight_option?.duration ?? 0
      );

      const originSegment = segments[0];
      const destinationSegment = segments[segments.length - 1];

      return {
        originCode: originSegment.origin.iata_code,
        originTime: this.getFormattedTime(
          `${originSegment.departure_datetime}`
        ),

        destinationCode: destinationSegment.destination.iata_code,
        destinationTime: this.getFormattedTime(
          `${destinationSegment.arrival_datetime}`
        ),

        duration,

        stops: segments.map((segment) => segment.destination.iata_code),
      };
    },
    getDepartureTime(departureTime: string): string {
      return TimeUtility.parsedFlightTimeorDate(
        departureTime,
        "EE, MMM dd, yyyy"
      );
    },
    getFormattedTime(time: string): string {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    getDuration(duration: number): string {
      return TimeUtility.getDurationInTextByMinutes(duration);
    },
    getRbdNameHandler(name: string): string {
      return name.length === 1 ? `RDB: ${name}` : name;
    },
    onViewDetailsHandler(): void {
      this.isSideSheetOpen = !this.isSideSheetOpen;
    },
    onDeleteOptionHandler(): void {
      this.$emit("delete:option");
    },
    onSelectedPairHandler(selected: boolean) {
      const flightOption = this.pair.arrival.flight_option;

      if (flightOption) {
        flightOption.selected = selected;
      }
    },
  },
});
</script>

<style scoped lang="css">
@import url("../styles/paired-flight-card.css");
</style>
