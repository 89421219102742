<template>
  <AgDiv>
    <span class="additional-details">
      <img
        class="image"
        :has-meal="hasMeal"
        src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-network.appspot.com/o/breakfast.png?alt=media&amp;token=f8de8953-6d82-421f-8b73-af071d91e822"
      />
      <span class="title">{{ mealInfo }}</span>
    </span>
    <br />
    <span class="additional-details">
      <img
        class="image"
        src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-network.appspot.com/o/travel-luggage.png?alt=media&amp;token=5bd10394-1735-4d38-91b6-a1c0e1d62064"
      />
      <span class="title"> Luggage included: {{ luggageInfo }} </span>
    </span>
  </AgDiv>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AdditionalDetailsCard",
  props: {
    hasMeal: {
      type: Boolean,
    },
    luggageInfo: {
      type: String,
    },
  },
  computed: {
    mealInfo(): string {
      return this.hasMeal ? "Meal Included" : "No Meal Included";
    },
  },
});
</script>

<style scoped lang="scss">
.additional-details {
  display: flex;
  align-items: center;
}

.additional-details .title {
  font-weight: bold;
}

.additional-details .image {
  width: 35px;
  margin-right: 10px;
}

.additional-details .image[has-meal="false"] {
  filter: saturate(0);
}
</style>
