import { RouteRecordRaw } from "vue-router";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import DealsAndPromos from "@/modules/Deals/views/DealsAndPromos.vue";
import { PATH } from "@/ag-portal-common/constants/path";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.DEALS_AND_PROMOS,
    name: "DealsAndPromos",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.DEALS_AND_PROMOS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.DEALS_AND_PROMOS.PERMISSIONS,
    },
    component: DealsAndPromos,
  },
];

export default routes;
