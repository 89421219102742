<template>
  <MDataTable
    :headers="tableHeaders"
    :data="localTravelers"
    :item-per-page="30"
    :is-api-paginated="false"
    :has-search="false"
    :has-pagination="false"
  >
  </MDataTable>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { MDataTable } from "@aeroglobe/ag-core-ui";

import { PassengerTraveler } from "@/modules/FlightIssuance/dtos/flightIssuance.dto";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { FORMAT_DD_MMM_YYYY_WITH_DASH } from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "MFlightTravelers",
  components: {
    MDataTable,
  },
  props: {
    travelers: {
      type: Array as () => Array<PassengerTraveler>,
      default: () => [],
    },
  },
  data() {
    return {
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
      tableHeaders: [
        {
          title: "Passenger Name",
          value: "name",
          key: "name",
          sortable: true,
        },
        {
          title: "Surname",
          value: "surname",
          key: "surname",
          sortable: true,
        },
        {
          title: "Passenger Type",
          value: "type",
          key: "type",
          sortable: true,
        },
        {
          title: "Birth Date",
          value: "birthdate",
          key: "birthdate",
          sortable: true,
        },
        {
          title: "Base Fare",
          value: "base_fare",
          key: "base_fare",
          sortable: true,
        },
        {
          title: "Fees",
          value: "fees",
          key: "fees",
          sortable: true,
        },
        {
          title: "Tax",
          value: "taxes",
          key: "taxes",
          sortable: true,
        },
        {
          title: "Surcharges",
          value: "surcharges",
          key: "surcharges",
          sortable: true,
        },
        {
          title: "Total Fare",
          value: "total_fare",
          key: "total_fare",
          sortable: true,
        },
      ] as MDataTableHeader[],
      rowsPerPage: 10,
      currentPage: 1,
      localTravelers: [] as PassengerTraveler[],
    };
  },
  watch: {
    rowsPerPage() {
      this.currentPage = 1;
    },
    travelers: {
      handler: function (travelers: PassengerTraveler[]) {
        this.localTravelers = travelers;
      },
      immediate: true,
    },
  },
  methods: {
    getFormattedDateTime,
    formatString<T extends number | string>(value: T | undefined): string {
      return value ? String(value) : typeof value === "number" ? "0" : "";
    },
  },
});
</script>
