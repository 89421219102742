import { IOrganizationState } from "@/ag-portal-common/interfaces/organizationState.interface";

const state: IOrganizationState = {
  organizations: [],
  isFetchingOrganizations: false,
  organization: null,
  isAddingAgent: false,
  isDeletingAgentFromOrganization: false,
  isFetchingOrganization: false,
  isCreatingOrganization: false,
  isUpdatingOrganization: false,
  isUpdateWebsiteConfigurationLoading: false,
  isUpdateWebsiteContentLoading: false,
  isWebsiteConfigLoading: false,
  websiteConfig: null,
  websiteContent: null,
  passengerActionLoading: false,
  passengers: [],
  isFetchingPassengers: false,
  isWhatsappMembersLoading: false,
  whatsappActionLoading: false,
  whatsappMembers: [],
  isGenerateOTPLoading: false,
  isOrganizationStatsLoading: false,
  organizationStats: null,
  organizationProductivity: null,
  isCurrenciesLoading: false,
  currencies: [],
  isOrganizationProductivityLoading: false,
  sectors: null,
  pia_provider: null,
  curreny: null,
  isDocumentUploadLoading: false,
  saasEliteOrganization: [],
  isEliteOrganizationStatusUpdating: false,
  errorOrganizationNotFound: false,
  internalProvidersLoading: false,
  pia_providers: null,
  airblue_providers: null,
  isUpdateFinancialProfileProvidersLoading: false,
};

export default state;
