<template>
  <MProgress indeterminate v-if="isLoading" class="mprogress-alignment" />
  <div v-else>
    <MCard class="organizations-heading">
      <div class="d-flex justify-content-between align-items-center">
        <AgIconInfoBar
          test-id=""
          title="Organizations"
          class="section-title"
          mPrepandIcon="m-organisations"
        />
        <div>
          <MButton
            @click="onClickCreateNew"
            type="filled"
            v-show="isUserPermitted"
          >
            Create new organization
          </MButton>
        </div>
      </div>
    </MCard>
    <div class="dashboard">
      <DashboardCard
        heading="Total Organizations"
        :number="getOrganizationCount"
        icon="m-number"
      />
      <DashboardCard
        heading="Active Organizations"
        :number="getActiveOrganizationCount"
        icon="m-success"
      />
      <DashboardCard
        heading="InActive Organizations"
        :number="getInActiveOrganizationCount"
        icon="m-error"
      />
      <DashboardCard
        heading="Linked Financial Profiles"
        :number="getActiveFinancialProfileOrganizationCount"
        icon="m-profile"
      />
      <DashboardCard
        heading="Unlinked Financial Profiles"
        :number="getPendingFinancialProfileOrganizationCount"
        icon="m-pending"
      />
    </div>

    <MCard class="information-access-card">
      <MTypography type="headline">Access the information via </MTypography>
      <ag-radio v-model="selectBy" inline>
        <ag-radio-item
          name="selectBy"
          value="Organization"
          label="Organization"
        ></ag-radio-item>

        <ag-radio-item
          name="selectBy"
          value="Financial Profile"
          label="Financial Profile"
        ></ag-radio-item>

        <ag-radio-item
          name="selectBy"
          value="Email"
          label="Email"
        ></ag-radio-item>
      </ag-radio>
      <div v-show="isSelectByFinancialProfile" class="selectable-container">
        <MFinancialProfileCombobox
          class="fp-combo"
          v-model:inputValue="financialProfileInput"
          label="Financial Profiles"
          itemValue="value"
          itemLabel="label"
          :options="financialProfileOptions"
        />
        <MButton
          v-show="isSelectByEmail || isSelectByFinancialProfile"
          @click="handleGetPriceDetail()"
          class="details-button"
          :disabled="getPriceBtnDisabled"
          >Get Details</MButton
        >
      </div>

      <div v-show="isSelectByEmail" class="selectable-container">
        <MTextfield
          type="email"
          class="fp-combo"
          v-model:inputValue="emailInput"
          label="Search Email"
        />
        <MButton
          v-show="isSelectByEmail || isSelectByFinancialProfile"
          @click="handleGetPriceDetail()"
          class="details-button"
          :disabled="getPriceBtnDisabled"
          >Get Details</MButton
        >
      </div>
    </MCard>
    <MCard v-show="isSelectByOrganization">
      <MDataTable
        :headers="headers"
        :is-loading="isLoading"
        :data="transformTableData"
        :items-per-page="30"
        :has-search="true"
        :simpleDataTable="true"
      >
        <template #public_id="{ item }">
          <router-link :to="`${PATH.ORGANIZATION}/${item.id}`">
            <MTypography
              class="description td-full-width organization-id"
              type="body"
              >{{ item.public_id }}</MTypography
            >
          </router-link>
        </template>
        <template #status="{ item }">
          <div class="status-container">
            <MChip
              class="pricing-chip"
              :variant="organizationStatusClass(item.status)"
              >{{ item.status }}</MChip
            >
          </div>
        </template>

        <template #action="{ item }">
          <MButton
            type="filled"
            @click="onClickUpdate($event, item.id)"
            :disabled="!isUserPermitted"
            >{{ item.action }}
          </MButton>
        </template>
      </MDataTable>
    </MCard>
  </div>
  <OrganizationDetailPage
    v-if="
      showOrganizationDetails && (isSelectByEmail || isSelectByFinancialProfile)
    "
    :searchedEmail="emailInput"
    @fetchOrganizationDetails="handleFetchOrganizationDetails"
    :is-management-page="true"
  />
</template>

<script lang="ts">
import DashboardCard from "@/components/OverviewCard.vue";
import { PATH } from "@/ag-portal-common/constants/path";
import { defineComponent } from "vue";
import { IOrganization } from "@/ag-portal-common/interfaces/organization.interface";
import {
  formatStringToRoutePath,
  genTestId,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { ORGANIZATION_STATUS_MAPPING, ORGANIZATION_STATUS } from "../constants";
import { MButton, MDataTable, MChip, MTypography } from "@aeroglobe/ag-core-ui";
import { ChipVariant } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import OrganizationDetailPage from "@/modules/Organization/components/OrganizationDetails/OrganizationDetailPage.vue";
import UTILS from "@/ag-portal-common/utils";
import { AGENT_ANALYTICS_EVENTS } from "@/modules/Agent/constants/analyticsEvents";
import { USER_ROLES } from "@/modules/Auth/types";

export default defineComponent({
  name: "OrganizationListView",
  components: {
    MDataTable,
    MButton,
    DashboardCard,
    MChip,
    OrganizationDetailPage,
  },
  data() {
    return {
      emailInput: "",
      showOrganizationDetails: false,
      financialProfileInput: "",
      isMobile: false,
      selectBy: "Organization",
      ELEMENT_TYPES,
      ORGANIZATION_STATUS_MAPPING,
      ORGANIZATION_STATUS,
      headers: [
        {
          key: "public_id",
          title: "Public Id",
          value: "public_id",
        },
        {
          key: "name",
          title: "Name",
          value: "name",
        },
        {
          title: "Sector Name",
          value: "sector_name",
          key: "sector_name",
          sortable: true,
        },
        {
          title: "PIA Provider",
          value: "pia_provider",
          key: "pia_provider",
          sortable: true,
        },
        {
          title: "Phone",
          value: "phone",
          key: "phone",
          sortable: true,
        },
        {
          title: "Email",
          value: "email",
          key: "email",
          sortable: true,
        },
        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: true,
        },
        {
          title: "Action",
          value: "action",
          key: "action",
          sortable: false,
        },
      ] as MDataTableHeader[],
    };
  },
  beforeMount() {
    this.showOrganizationDetails = false;
    this.fetchOrganizations();
    this.fetchFinancialProfiles();
  },
  methods: {
    handleGetPriceDetail() {
      this.showOrganizationDetails = true;
      if (this.isSelectByEmail) {
        this.$store.dispatch("fetchOrganization", this.emailInput);
      } else {
        if (this.financialProfileInput) {
          this.$store.dispatch("fetchOrganization", this.financialProfileInput);
        }
      }
    },
    handleFetchOrganizationDetails() {
      this.$store.dispatch("fetchOrganization", this.financialProfileInput);
    },
    genTestId,
    fetchOrganizations() {
      this.$store.dispatch("fetchOrganizations");
    },
    fetchFinancialProfiles() {
      this.$store.dispatch("fetchFinancialProfiles", {
        plan: ["PRO", "LITE", "STANDARD", "UMRAH"],
      });
    },
    onClickCreateNew() {
      this.$router.push(PATH.CREATE_ORGANIZATION);
    },
    onClickUpdate(e: any, id: string) {
      e.stopImmediatePropagation();
      this.$router.push(
        formatStringToRoutePath(PATH.UPDATE_ORGANIZATION, { id })
      );
    },
    organizationStatusClass(status: ORGANIZATION_STATUS): ChipVariant {
      return status === ORGANIZATION_STATUS.ACTIVE ? "success" : "error";
    },
  },
  computed: {
    PATH() {
      return PATH;
    },
    isLoading(): boolean {
      return (
        this.$store.getters.isFetchingOrganizations ||
        this.$store.getters.isFetchingFinancialProfiles
      );
    },
    isUserPermitted(): boolean {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER);
    },
    getPriceBtnDisabled(): boolean {
      return (
        (!this.emailInput && !this.financialProfileInput) ||
        this.$store.getters.isFetchingOrganization
      );
    },
    financialProfileOptions() {
      const profiles = this.$store.getters
        .financialProfiles as IFinancialProfile[];
      return profiles?.map((fp: IFinancialProfile) => {
        const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase();
        const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
        return {
          id: fp?.platform_id,
          label: fp?.financial_profile_name,
          value: fp?.organization_public_id,
          isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
          status: fp?.status,
          sector: sector,
          type: planType,
        };
      });
    },
    transformTableData() {
      return this.$store.getters.organizations.map((item: IOrganization) => ({
        id: item.public_id,
        public_id: item.default_financial_profile?.platform_id
          ? `${item.public_id} (${item.default_financial_profile?.platform_id})`
          : `${item.public_id} (-)`,
        name: item.name,
        sector_name: item.sector,
        pia_provider: item.pia_provider,
        phone: item.contact_person_phone ? item.contact_person_phone : "-",
        email: item.contact_person_email ? item.contact_person_email : "-",
        status: item.status,
        action: "Update",
      }));
    },
    getOrganizationCount(): number {
      return this.transformTableData.length;
    },

    getActiveOrganizationCount(): number {
      return this.transformTableData.filter(
        (item: { status: string }) => item.status === "ACTIVE"
      ).length;
    },

    getInActiveOrganizationCount(): number {
      return this.transformTableData.filter(
        (item: { status: string }) => item.status === "INACTIVE"
      ).length;
    },

    getPendingFinancialProfileOrganizationCount(): number {
      return this.transformTableData.filter((item: { public_id: string }) =>
        item.public_id.includes("(-)")
      ).length;
    },
    getActiveFinancialProfileOrganizationCount(): number {
      return this.transformTableData.filter(
        (item: { public_id: string }) => !item.public_id.includes("(-)")
      ).length;
    },
    isSelectByOrganization(): boolean {
      return this.selectBy === "Organization";
    },
    isSelectByFinancialProfile(): boolean {
      return this.selectBy === "Financial Profile";
    },
    isSelectByEmail(): boolean {
      return this.selectBy === "Email";
    },
  },
});
</script>

<style scoped>
.organization-id {
  color: var(--green-color);
}
.min-width-200 {
  min-width: 200px;
}
.status-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.text-align-center {
  text-align: center;
}
.create-org-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.organizations-heading {
  margin-bottom: 10px;
}
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
.dashboard {
  display: grid;
  gap: 16px;
  margin-bottom: 10px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

@media (max-width: 768px) {
  .dashboard {
    grid-template-columns: 1fr;
  }
}
.information-access-card {
  margin-bottom: 10px;
}

.selectable-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.fp-combo {
  flex: 1;
  max-width: 600px;
}

.m-button {
  flex: 0;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .selectable-container {
    flex-wrap: nowrap;
  }
  .fp-combo,
  .m-button {
    width: auto;
  }
}
.details-button {
  margin-bottom: 16px;
}
</style>
