import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewRegisteredUserBanner = _resolveComponent("NewRegisteredUserBanner")!
  const _component_NewsSlider = _resolveComponent("NewsSlider")!
  const _component_BookingExpiry = _resolveComponent("BookingExpiry")!
  const _component_RevenueChart = _resolveComponent("RevenueChart")!
  const _component_MyBookingsTable = _resolveComponent("MyBookingsTable")!
  const _component_MCard = _resolveComponent("MCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NewRegisteredUserBanner),
    _createVNode(_component_NewsSlider),
    _createElementVNode("div", null, [
      _createVNode(_component_BookingExpiry),
      _createVNode(_component_RevenueChart),
      _createVNode(_component_MCard, null, {
        default: _withCtx(() => [
          _createVNode(_component_MyBookingsTable, { isDashboardView: true })
        ]),
        _: 1
      })
    ])
  ], 64))
}