<template>
  <MProgress
    v-if="isFetchingCreditLimitRequests"
    indeterminate
    class="mprogress-alignment"
  />
  <div v-else>
    <MCard class="credit-limit-heading">
      <div class="d-flex justify-content-between align-items-center">
        <AgIconInfoBar
          test-id=""
          title="Credit Limit Management"
          class="section-title"
          mPrepandIcon="m-credit-limit-management"
        />
        <div class="credit_limit_btn_space">
          <MButton
            :test-id="genTestId(ELEMENT_TYPES.BUTTON, 'create-credit-limit')"
            @click="routeToCreateCreditLimitRequest"
            >Request Limit
          </MButton>
        </div>
      </div>
    </MCard>
    <MCard>
      <MDataTable
        :isLoading="isFetchingCreditLimitRequests"
        :headers="creditLimitHeaders"
        :data="creditLimitRequests.data"
      >
        <template #requestInitiatedAt="{ item }">
          <MTypography
            class="description"
            type="body"
            v-if="item.requestInitiatedAt"
          >
            {{
              getFormattedDateTime(
                item.requestInitiatedAt,
                FORMAT_DD_MMM_YYYY_WITH_DASH
              )
            }}
          </MTypography>
          <MTypography
            class="description"
            type="label"
            v-if="item.requestInitiatedAt"
          >
            {{
              getFormattedDateTime(
                item.requestInitiatedAt,
                FORMAT_HH_SS_24_HOURS
              )
            }}
          </MTypography>
          <p class="margin_bottom_0" v-if="!item.requestInitiatedAt">N/A</p>
        </template>
        <template #requestedAmount="{ item }">
          <MTypography class="description" type="label">
            {{ formatNumber(item.requestedAmount) }}
          </MTypography>
        </template>
        <template #limitExpiresAt="{ item }">
          <MTypography
            class="description"
            type="body"
            v-if="item.limitExpiresAt"
          >
            {{
              getFormattedDateTime(
                item.limitExpiresAt,
                FORMAT_DD_MMM_YYYY_WITH_DASH
              )
            }}
          </MTypography>
          <MTypography
            class="description"
            type="label"
            v-if="item.limitExpiresAt"
          >
            {{
              getFormattedDateTime(item.limitExpiresAt, FORMAT_HH_SS_24_HOURS)
            }}
          </MTypography>
          <MTypography class="description" type="label" v-else> - </MTypography>
        </template>
        <template #status="{ item }">
          <div class="mchip-alignment">
            <MChip
              :variant="creditLimitStatusClass(item.status)"
              class="m-chip-credit-limit"
            >
              {{ item.status }}
            </MChip>
          </div>
        </template>
        <template #priority="{ item }">
          <div class="mchip-alignment">
            <MChip
              :variant="creditLimitPriorityclass(item.priority)"
              class="m-chip-credit-limit"
            >
              {{ item.priority }}
            </MChip>
          </div>
        </template>
        <template #financialProfileName="{ item }">
          <MTypography class="description financial_profile_name" type="body">
            {{ item.financialProfileName }}
          </MTypography>
        </template>

        <template #requestNotes="{ item }">
          <p v-if="!item.requestNotes">-</p>
          <MTypography v-else class="description" type="label">
            {{ item.requestNotes }}
          </MTypography>
        </template>
      </MDataTable>
    </MCard>
  </div>
</template>

<script lang="ts">
import { CREDIT_LIMIT_REQUEST_STATUSES } from "@/ag-portal-common/enums/CREDIT_LIMIT_REQUEST_STATUSES";
import {
  formatNumber,
  genTestId,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { IPaginatedCreditLimitRequests } from "@/interfaces/creditLimitRequest.interface";
import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_HH_SS_24_HOURS,
  FORMAT_YYY_MM_DD,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { defineComponent } from "vue";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { PATH } from "@/ag-portal-common/constants/path";
import { CREDIT_PRIORITIES, CREDIT_STATUSES } from "../constants";
import { MCard, MChip, MDataTable } from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";

export default defineComponent({
  name: "ListViewForAgentsUser",
  data() {
    return {
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
      FORMAT_HH_SS_24_HOURS: FORMAT_HH_SS_24_HOURS,
      itemsPerPage: 0,
      page: 1,
      activeTab: CREDIT_LIMIT_REQUEST_STATUSES.PENDING,
      creditLimitHeaders: [
        {
          key: "requestInitiatedAt",
          title: "Request Initiated At",
          value: "requestInitiatedAt",
        },
        {
          key: "priority",
          title: "Priority",
          value: "priority",
        },
        {
          key: "status",
          title: "Status",
          value: "status",
        },
        {
          key: "limitExpiresAt",
          title: "Credit Limit Expires At",
          value: "limitExpiresAt",
        },
        {
          key: "financialProfileName",
          title: "Financial Profile Name",
          value: "financialProfileName",
        },
        {
          key: "requestedAmount",
          title: "Requested Amount",
          value: "requestedAmount",
        },
        {
          key: "requestedDays",
          title: "Requested Days",
          value: "requestedDays",
        },
        {
          key: "requestNotes",
          title: "Request Notes",
          value: "requestNotes",
        },
      ] as MDataTableHeader[],
    };
  },
  methods: {
    genTestId,
    formatNumber,
    getFormattedDateTime,
    routeToCreateCreditLimitRequest() {
      this.$router.push(PATH.CREATE_CREDIT_LIMIT_REQUEST);
    },
    setItemsPerPage() {
      let requestsLength = this.$store.getters.creditLimitRequests.data.length;
      this.itemsPerPage = requestsLength;
    },
    fetchCreditLimitRequests(callback?: () => void) {
      let params = {
        status: this.activeTab,
        page: this.page,
      };
      let payload = {
        params,
        callback,
      };

      this.$store.dispatch("fetchCreditLimitRequests", payload);
    },
    onPageChange(num: number) {
      let nextPage = this.$store.getters.creditLimitRequests.next;
      let prevPage = this.$store.getters.creditLimitRequests.previous;
      let currentPage: number = this.page || 1;
      if ((num > currentPage && nextPage) || (currentPage > num && prevPage)) {
        this.page = num;
        this.fetchCreditLimitRequests();
      }
    },
    onClickTab(e: PointerEvent, tab: CREDIT_LIMIT_REQUEST_STATUSES) {
      this.activeTab = tab;
    },
    creditLimitStatusClass(status: CREDIT_STATUSES) {
      switch (status) {
        case "CANCELLED":
          return "error";
        case "NEW":
          return "info";
        case "APPROVED":
          return "success";
        case "IN_PROGRESS":
          return "warning";
        case "PARTIALLY_APPROVED":
          return "warning";
        default:
          return "success";
      }
    },
    creditLimitPriorityclass(status: CREDIT_PRIORITIES) {
      switch (status) {
        case "LOW":
          return "warning";
        case "NORMAL":
          return "info";
        case "URGENT":
          return "error";
        default:
          return "success";
      }
    },
  },
  components: {
    MDataTable,
    MChip,
    MCard,
  },
  computed: {
    ELEMENT_TYPES(): typeof ELEMENT_TYPES {
      return ELEMENT_TYPES;
    },
    NOTIFICATION_MESSAGES(): typeof NOTIFICATION_MESSAGES {
      return NOTIFICATION_MESSAGES;
    },
    CREDIT_LIMIT_REQUEST_STATUSES() {
      return CREDIT_LIMIT_REQUEST_STATUSES;
    },
    creditLimitRequests(): IPaginatedCreditLimitRequests {
      return this.$store.getters.creditLimitRequests;
    },
    isFetchingCreditLimitRequests(): boolean {
      return this.$store.getters.isFetchingCreditLimitRequests;
    },
    FORMAT_YYY_MM_DD(): string {
      return FORMAT_YYY_MM_DD;
    },
  },
  watch: {
    activeTab() {
      this.page = 1;
      this.fetchCreditLimitRequests(this.setItemsPerPage);
    },
  },
  beforeMount() {
    this.fetchCreditLimitRequests(this.setItemsPerPage);
  },
});
</script>

<style lang="css" scoped>
.m-chip-credit-limit {
  align-items: center;
  align-content: center;
  max-width: max-content;
}
.credit-limit-heading {
  margin-bottom: 10px;
}
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
</style>
