<template>
  <template v-if="flightDetails">
    <ag-heading variant="h2" title="Price Summary" class="margin_bottom_10" />

    <template v-for="(type, index) in paxTypes" :key="`fare-info-${index}`">
      <AgPriceSummary
        test-id=""
        v-show="canShowBreakDown(type)"
        :info="getPriceSummaryTitleHandler(type).label"
        :price="getPriceSummaryTitleHandler(type).price"
      />
    </template>

    <AgPriceSummary
      test-id=""
      info="Price You Pay"
      :price="renderTotalGrossPrice()"
      total-price="true"
    />

    <template v-if="canDisplayAgentPricing && isUserPermitted">
      <AgPriceSummary
        test-id=""
        info="Your Price"
        :price="renderTotalAgentPrice()"
        total-price="true"
      />

      <AgPriceSummary
        test-id=""
        info="Difference"
        :price="agentPricingDifference"
        total-price="true"
      />
    </template>

    <ag-accordion
      class="flight_accordion_wrap"
      :panel-value="[1, 2]"
      v-show="canShowAllBreakdowns"
    >
      <ag-accordion-panel>
        <template #flightInfo>
          <ag-heading
            variant="h3"
            title="Fare Break Down"
            class="margin_bottom_10"
            style="margin-top: 10px"
          />
        </template>
        <template #tabSection>
          <AgFareInfo test-id="" style="max-width: inherit">
            <template
              v-for="(type, index) in paxTypes"
              :key="`fare-info-${index}`"
            >
              <AgDiv v-show="canShowBreakDown(type)">
                <ag-heading
                  variant="h3"
                  :title="getHeadingHandler(type)"
                  class="margin_bottom_10"
                  style="margin-top: 10px"
                />
                <AgPriceSummary
                  test-id=""
                  info="Base Fare"
                  :price="getFareHandler('base_fare')[type]"
                ></AgPriceSummary>
                <AgPriceSummary
                  test-id=""
                  info="Tax"
                  :price="getFareHandler('tax')[type]"
                ></AgPriceSummary>
                <AgPriceSummary
                  test-id=""
                  info="Gross Fare"
                  :price="getFareHandler('gross_fare')[type]"
                ></AgPriceSummary>
                <AgPriceSummary
                  test-id=""
                  v-if="getPriceDifferenceHandler(type) !== 0"
                  :info="
                    getSummaryTitleHandler(getPriceDifferenceHandler(type) || 0)
                  "
                  :price="getFormattedPriceDifferenceHandler(type)"
                ></AgPriceSummary>
                <template v-if="getDealDiscount()[type]">
                  <AgPriceSummary
                    test-id=""
                    info="Deal Discount"
                    :price="getDealDiscount()[type]"
                  ></AgPriceSummary>
                </template>
                <AgPriceSummary
                  test-id=""
                  info="Total"
                  :price="getFareHandler('ag_total_amount')[type]"
                  total-price="true"
                ></AgPriceSummary>
              </AgDiv>
            </template>
          </AgFareInfo>
        </template>
      </ag-accordion-panel>
    </ag-accordion>
    <slot name="flightDetailsActionButtons"> </slot>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";
import { FlightDetailsType, FlightLeg } from "@/ag-flight-components/types";

import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import UTILS from "@/ag-portal-common/utils";

export default defineComponent({
  name: "PriceSummary",
  data() {
    return {
      paxTypes: ["adult", "child", "infant"] as (
        | "adult"
        | "child"
        | "infant"
      )[],
    };
  },
  computed: {
    canShowAllBreakdowns(): boolean {
      return (
        this.canShowBreakDown("adult") ||
        this.canShowBreakDown("child") ||
        this.canShowBreakDown("infant")
      );
    },
    flightDetails(): FlightDetailsType | null {
      return this.$store.getters.flightDetails ?? null;
    },

    isUserPermitted(): boolean {
      return UTILS.isUser(
        USER_ROLES.AGENT,
        USER_ROLES.OPERATIONS,
        USER_ROLES.AG_SUPER_USER
      );
    },
    canDisplayAgentPricing(): boolean {
      return this.flightDetails
        ? this.flightDetails.display_agent_pricing ?? false
        : false;
    },
    agentPricingDifference(): string {
      let difference = 0;
      let currency = "PKR";

      if (this.flightDetails) {
        const agTotalAmount = this.flightDetails.agent_total_amount;
        const agentTotalAmount = this.flightDetails.ag_total_amount;

        if (agentTotalAmount && agTotalAmount) {
          difference =
            Number(agentTotalAmount.value) - Number(agTotalAmount.value);
          currency = agTotalAmount.currency;
        }
      }

      return this.formatCurrency(Number(difference), currency);
    },
  },
  methods: {
    getHeadingHandler(title: string): string {
      const formattedTitle = title.charAt(0).toUpperCase() + title.slice(1);

      return `${formattedTitle} Break Down`;
    },
    formatCurrency(amount: number, currency_format: string): string {
      return getCurrencyFormatter(currency_format).format(amount);
    },
    getSummaryTitleHandler(amount: number): string {
      if (amount > 0) {
        return "Fee";
      } else {
        return "Discount";
      }
    },
    getPriceSummaryTitleHandler(paxType: "adult" | "child" | "infant"): {
      label: string;
      price: string;
      amount: number;
    } {
      if (!this.flightDetails) {
        return {
          label: "",
          price: "",
          amount: 0,
        };
      }

      const journeyLegs: FlightLeg[] = this.flightDetails.flight_legs || [];
      const airline = journeyLegs[0].segment[0].marketing_airline.name;

      const paxTypeFareBreakdown =
        this.flightDetails.price.pax_type_price_breakdown;
      // const count = this.flightDetails[`${paxType}_count`] ?? 0;
      const fare = paxTypeFareBreakdown[paxType];

      const agTotalAmount = fare.ag_total_amount
        ? fare.ag_total_amount.value
        : 0;
      const agTotalCurrency = fare.ag_total_amount
        ? fare.ag_total_amount.currency
        : "PKR";

      const price = this.formatCurrency(Number(agTotalAmount), agTotalCurrency);

      const amount = agTotalAmount;

      return {
        label: `${airline} (${paxType}) x ${
          this.flightDetails[`${paxType}_count`]
        }`,
        price,
        amount,
      };
    },
    getPriceDifferenceHandler(paxType: "adult" | "child" | "infant"): number {
      if (!this.flightDetails) {
        return 0;
      }

      const paxTypeFareBreakdown =
        this.flightDetails.price.pax_type_price_breakdown;
      const fare = paxTypeFareBreakdown[paxType].ag_price_difference;

      return fare ? fare.value : 0;
    },
    getCurrencyHandler(paxType: "adult" | "child" | "infant"): string {
      if (!this.flightDetails) {
        return "";
      }

      const paxTypeFareBreakdown =
        this.flightDetails.price.pax_type_price_breakdown;
      const currency = paxTypeFareBreakdown[paxType].gross_fare.currency;

      return currency ?? "";
    },
    getFareHandler(
      fareType:
        | "base_fare"
        | "gross_fare"
        | "ag_total_amount"
        | "tax"
        | "ag_deal_discount"
        | "ag_price_difference"
    ): {
      adult: string;
      child: string;
      infant: string;
    } {
      if (!this.flightDetails) {
        return {
          adult: "",
          child: "",
          infant: "",
        };
      }

      const paxTypeFareBreakdown =
        this.flightDetails.price.pax_type_price_breakdown;

      const getFare = (paxType: "adult" | "child" | "infant"): string => {
        const fare = paxTypeFareBreakdown[paxType][fareType];

        return fare
          ? this.formatCurrency(Number(fare.value), fare.currency ?? "")
          : "";
      };

      return {
        adult: getFare("adult"),
        child: getFare("child"),
        infant: getFare("infant"),
      };
    },
    getDealDiscount(): {
      adult: string | null;
      child: string | null;
      infant: string | null;
    } {
      const flightDetails = this.flightDetails;

      if (!flightDetails) {
        return {
          adult: null,
          child: null,
          infant: null,
        };
      }

      const paxTypeFareBreakdown = flightDetails.price.pax_type_price_breakdown;

      const getFare = (
        paxType: "adult" | "child" | "infant"
      ): string | null => {
        const fare = paxTypeFareBreakdown[paxType].ag_deal_discount;

        return fare && flightDetails.ag_deal_discount
          ? this.formatCurrency(-Number(fare.value), fare.currency ?? "")
          : null;
      };

      return {
        adult: getFare("adult"),
        child: getFare("child"),
        infant: getFare("infant"),
      };
    },

    renderTotalGrossPrice(): string {
      if (!this.flightDetails) {
        return "";
      }

      return this.formatCurrency(
        Number(this.flightDetails.ag_total_amount.value),
        this.flightDetails.ag_total_amount.currency
      );
    },
    renderTotalAgentPrice(): string {
      if (!this.flightDetails) {
        return "";
      }

      const amount = this.flightDetails.agent_total_amount;

      const fare = amount ?? null;

      if (!fare) {
        return "";
      }

      return this.formatCurrency(Number(fare.value), fare.currency);
    },

    getFormattedPriceDifferenceHandler(
      paxType: "adult" | "child" | "infant"
    ): string {
      const priceDifference = this.getPriceDifferenceHandler(paxType);
      const currency = this.getCurrencyHandler(paxType);
      const formattedDifference = this.formatCurrency(
        priceDifference,
        currency
      );

      return formattedDifference;
    },

    canShowBreakDown(paxType: "adult" | "child" | "infant"): boolean {
      if (!this.flightDetails) {
        return false;
      }

      return Number(this.flightDetails[`${paxType}_count`]) > 0;
    },
  },
});
</script>
