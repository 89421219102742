import { PNR_STATUSES } from "@/ag-flight-components/enums/flight_issuance";

export enum PNR_dSTATUSES {
  ONHOLD = "ONHOLD",
  TICKETED = "TICKETED",
  WAITLIST = "WAITLIST",
  CANCELLED = "CANCELLED",
  REFUNDED = "REFUNDED",
  EXPIRED = "EXPIRED",
  VOIDED = "VOIDED",
}

export const PNR_STATUSES_COLOR_MAPPER = {
  [PNR_STATUSES.TICKETED]: "success",
  [PNR_STATUSES.WAITLIST]: "default",
  [PNR_STATUSES.ONHOLD]: "default",
  [PNR_STATUSES.REFUNDED]: "success",
  [PNR_STATUSES.EXPIRED]: "error",
  [PNR_STATUSES.CANCELLED]: "error",
  [PNR_STATUSES.VOIDED]: "warning",
};
