<template>
  <MDialog
    title="Delete Passenger"
    v-model="isMdialogOpen"
    @click="$emit('handleReject')"
  >
    <template #content>
      <MTypography>Are you sure to delete passenger?</MTypography>
    </template>
    <template #actions>
      <MButton type="outlined" @click="$emit('handleReject')"> No </MButton>
      <MButton @click="$emit('handleAccept')"> Yes </MButton>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { MButton, MDialog, MTypography } from "@aeroglobe/ag-core-ui";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DeletePassengerConfirmationModal",
  data() {
    return {
      isMdialogOpen: this.isOpen,
    };
  },
  components: {
    MDialog,
    MTypography,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  watch: {
    isOpen(newVal) {
      this.isMdialogOpen = newVal;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("closeModal");
      }
    },
  },
});
</script>
