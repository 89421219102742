import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4289f33b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "waitlist-alert" }
const _hoisted_2 = { class: "waitlist-alert" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MDialog, {
      modelValue: _ctx.isMdialogOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isMdialogOpen) = $event)),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isMdialogOpen = !_ctx.isMdialogOpen)),
      "hide-footer": "",
      "hide-header": ""
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_MIcon, {
            class: "waitlist-alert-icon",
            name: "m-info",
            width: "48",
            height: "48"
          }),
          _createVNode(_component_MTypography, { type: "title" }, {
            default: _withCtx(() => [
              _createTextVNode("Your booking is on WAITLIST")
            ]),
            _: 1
          }),
          _createVNode(_component_MTypography, null, {
            default: _withCtx(() => [
              _createTextVNode(" Please check with airline before issuance ")
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("template", null, [
      _createVNode(_component_MDialog, {
        modelValue: _ctx.isMdialogOpen,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isMdialogOpen) = $event)),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isMdialogOpen = !_ctx.isMdialogOpen)),
        "hide-footer": "",
        "hide-header": ""
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_MIcon, {
              class: "waitlist-alert-icon",
              name: "m-info",
              width: "48",
              height: "48"
            }),
            _createVNode(_component_MTypography, { type: "title" }, {
              default: _withCtx(() => [
                _createTextVNode("Your booking is on WAITLIST")
              ]),
              _: 1
            }),
            _createVNode(_component_MTypography, null, {
              default: _withCtx(() => [
                _createTextVNode(" Please check with airline before issuance ")
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}