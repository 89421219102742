export interface Promo {
  name: string;
  discount_code: string;
  promo_deal_type: string;
  promo_apply_on: string | null;
  discount_type: string;
  start_date: Date;
  end_date: Date;
  organization_created_in_days: number | null;
  last_issuance_before_days: number | null;
  discount_amount: number;
  currency: string;
  discount_amount_type: string;
  is_featured: boolean;
  is_active: boolean;
  apply_on_gross: boolean;
  for_portal_only: boolean;
  route_map: string;
  flight_numbers: string[] | null;
  origin_airports: string[] | null;
  destination_airports: string[] | null;
  flight_sector: string[] | null;
  discount_mode: string;
  max_usage: number | null;
  apply_on_airline: string;
  airline_data: AirlineData[] | null;
}

export interface Deal {
  name: string;
  discount_code: string;
  discount_type: string;
  start_date: Date;
  end_date: Date;
  discount_amount: number;
  currency: string;
  discount_amount_type: string;
  is_featured: boolean;
  is_active: boolean;
  apply_on_gross: boolean;
  for_portal_only: boolean;
  route_map: string;
  flight_numbers: string[] | null;
  origin_airports: string[] | null;
  destination_airports: string[] | null;
  flight_sector: string[] | null;
  discount_mode: string;
  apply_on_airline: string;
  airline_data: AirlineData[] | null;
}

export interface AirlineData {
  airline_code: string;
  airline_name: string;
}

export interface PROMOS_AND_DEALS {
  applicable_promos: Promo[] | null;
  applicable_deals: Deal[] | null;
}

export const DEALS_API_PATH = {
  GET_ORGANIZATION_DEALS_AND_PROMOS: (organizationId: string): string =>
    `promo/organizations/${organizationId}/all-promos-deals/`,
};
