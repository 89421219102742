<template>
  <MProgress class="mprogress-alignment" v-if="!newsLoaded" indeterminate />
  <ag-div
    class="slider-container"
    v-else-if="newsLoaded && filteredNews.length > 0"
  >
    <div class="news">
      <div
        class="slide"
        v-for="(news, index) in filteredNews"
        :key="index"
        @click="onClickNewsHandler(index)"
      >
        <img
          class="news-image"
          :src="news?.featured_image"
          @error="
            news.featured_image = require('../../../assets/news-icon.png')
          "
          alt="news image"
          @click.stop="onClickImageHandler(news.featured_image)"
        />

        <div class="content">
          <div class="featured" v-if="news?.show_on_banner === true">
            Featured
          </div>

          <div class="news-wrapper">
            <MTypography class="description title" type="body">
              {{ news?.news_title }}
            </MTypography>
            <div class="subtitle">
              <MTypography class="description" type="label">
                {{ news?.news_description }}
              </MTypography>
            </div>
          </div>
          <AGButton class="action" @click="onNewsActionClickHandler(news)">
            {{ news?.call_to_action_label }}
          </AGButton>
        </div>
      </div>
    </div>
  </ag-div>

  <!-- Slider Modal -->
  <MDialog
    class="news-modal"
    v-model="isNewModalOpened"
    @close="isNewModalOpened = false"
    max-width="900"
    :persistent="imageModal"
    hide-header
  >
    <template #content>
      <div class="modal-body">
        <div class="close-action" @click="isNewModalOpened = false"></div>
        <img
          class="news-image"
          :src="filteredNews[selectedNewsIndex]?.featured_image"
          @error="
            filteredNews[
              selectedNewsIndex
            ].featured_image = require('../../../assets/news-icon.png')
          "
          alt="news image"
          @click.stop="
            onClickImageHandler(filteredNews[selectedNewsIndex].featured_image)
          "
        />

        <div class="content">
          <div
            class="featured"
            v-if="filteredNews[selectedNewsIndex]?.show_on_banner === true"
          >
            Featured
          </div>

          <div class="news-wrapper">
            <div class="title">
              {{ filteredNews[selectedNewsIndex]?.news_title }}
            </div>

            <div class="subtitle">
              {{ filteredNews[selectedNewsIndex]?.news_description }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <MButton
        @click="onNewsActionClickHandler(filteredNews[selectedNewsIndex])"
      >
        {{ filteredNews[selectedNewsIndex]?.call_to_action_label }}
      </MButton>
    </template>
  </MDialog>

  <ImageLightBox v-model:state="imageModal" :image-src="selectedImageSrc" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ImageLightBox from "@/modules/Dashboard/components/ImageLightBox.vue";
import { INews } from "@/ag-portal-common/interfaces/news.interface";
import { MButton } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "NewsSlider",
  components: {
    ImageLightBox,
  },
  data() {
    return {
      newsLoaded: false,
      selectedNewsIndex: 0,
      isNewModalOpened: false,
      imageModal: false,
      selectedImageSrc: "",
    };
  },
  async created() {
    await this.$store.dispatch("getAllNews");
    this.newsLoaded = true;
  },
  computed: {
    filteredNews(): INews[] {
      const newsData = this.$store.getters.newsData;
      return newsData;
    },
  },
  methods: {
    onNewsActionClickHandler(news: INews) {
      const url = news.action_url ?? "";
      const isExternalUrl =
        url?.includes("https://") || url?.includes("http://");
      if (isExternalUrl) {
        window.open(url);
      } else {
        this.$router.push(url);
      }
    },
    onClickNewsHandler(index: number) {
      this.selectedNewsIndex = index;
      this.isNewModalOpened = true;
    },
    onClickImageHandler(src: string) {
      this.selectedImageSrc = src;
      this.imageModal = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.news {
  width: 100%;
  margin-bottom: 24px;
  --base-size: 200px;
  position: relative;

  &::before {
    content: " ";
    position: absolute;
    height: 100%;
    background-image: linear-gradient(
      to left,
      transparent,
      var(--m-container-color)
    );
    z-index: 2;
  }

  &::after {
    content: " ";
    position: absolute;
    height: 100%;
    background-image: linear-gradient(
      to right,
      transparent,
      var(--m-container-color)
    );
    z-index: 2;
    top: 0;
    right: 0;
  }

  .slide {
    width: 100%;
    max-width: 100%;
    padding: 0;
    position: relative;
    background-color: #ffffff;
    margin-bottom: 10px;

    border-radius: 8px;
    background: #fff;

    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    padding: 16px;

    cursor: pointer;

    position: relative;

    .news-image {
      height: var(--base-size);
      width: var(--base-size);

      object-fit: cover;

      border-radius: 12px;
      background: lightgray 50% / cover no-repeat;
      box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.18);
    }

    .content {
      flex: 1;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .featured {
        height: 32px;
        width: fit-content;

        padding: 2px 16px;
        margin-bottom: 26px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 8px;
        background: #e7f8f2;

        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.24px;
      }

      .message {
        color: #10b981;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0.72px;

        margin-bottom: 8px;
      }

      .title {
        color: #49454f;
        font-size: 32px;
        font-weight: 600;
        letter-spacing: 0.64px;

        margin-bottom: 16px;
      }

      .subtitle {
        color: #49454f;
        font-size: 16px;
        font-weight: 400;

        margin-bottom: 24px;
      }

      .title,
      .subtitle {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .action {
        border-radius: 50px;
        width: fit-content;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .news {
    .slide {
      flex-direction: column;
      width: var(--base-size);
      min-height: var(--base-size);
      padding: 0;

      .news-image {
        box-shadow: none;
        border-radius: 8px;
      }

      .content {
        width: 100%;
        height: var(--base-size);
        position: absolute;
        bottom: 0;
        padding: 16px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 8px;

        .title {
          font-size: 18px;
          color: white;
          margin-bottom: 0;
        }

        .subtitle,
        .action {
          display: none;
        }
      }
    }
  }
}

.news-modal {
  .modal-body {
    position: relative;
    margin-top: 10px;

    .close-action {
      &::after {
        content: "\00d7";
        position: absolute;
        top: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        font-size: 24px;

        background-color: white;
        border: 1px solid #dadada;
        border-radius: 4px;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
      }
    }
    .news-image {
      width: 100%;
      height: 280px;

      object-fit: cover;
      object-position: 0 0;

      border-radius: 0 0 12px 12px;
      background: lightgray 50% / cover no-repeat;
      box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.18);

      cursor: pointer;
    }

    .content {
      flex: 1;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      padding: 16px;

      .featured {
        height: 32px;
        width: fit-content;

        padding: 2px 16px;
        margin-bottom: 26px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 8px;
        background: #e7f8f2;

        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.24px;
      }

      .message {
        color: #10b981;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0.72px;

        margin-bottom: 8px;
      }

      .title {
        color: #49454f;
        font-size: 32px;
        font-weight: 600;
        letter-spacing: 0.64px;

        margin-bottom: 16px;
      }

      .subtitle {
        color: #49454f;
        font-size: 16px;
        font-weight: 400;

        margin-bottom: 24px;
      }

      .action {
        border-radius: 50px;
        width: fit-content;
      }
    }
  }
}
</style>

<style lang="scss">
.news-modal .v-card-text {
  padding: 0 !important;
}
</style>
