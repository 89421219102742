import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c57c892"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTypography = _resolveComponent("MTypography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MTypography, { class: "greetings-heading" }, {
      default: _withCtx(() => [
        _createTextVNode(" Greetings! 🌟 ")
      ]),
      _: 1
    }),
    _createVNode(_component_MTypography, { class: "greetings-sub-heading" }, {
      default: _withCtx(() => [
        _createTextVNode(" Thank you for downloading visa for " + _toDisplayString(_ctx.user.toLowerCase()) + " 🚀 ", 1)
      ]),
      _: 1
    })
  ]))
}