<template>
  <div class="fit_quick_search_wrap">
    <img v-if="countryThumb" v-bind:src="countryThumb" class="img" />
    <div class="content">
      <MTypography type="body" class="country">{{ countryName }}</MTypography>
      <MTypography type="label" class="description">{{
        description
      }}</MTypography>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "FitQuickSearch",
  props: {
    countryThumb: {
      type: String,
    },
    countryName: {
      type: String,
    },
    description: {
      type: String,
    },
  },
});
</script>

<style scoped lang="css">
.img {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  margin-right: 12px;
}
.fit_quick_search_wrap {
  color: var(--green-color);
  border: 1px solid grey;
  padding: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 500ms ease-in-out;
  margin-right: 15px;
  min-width: 265px;
  margin-bottom: 10px;
  .content {
    display: flex;
    flex-direction: column;
  }

  .country {
    color: var(--green-color);
    transition: all 500ms ease-in-out;
    margin-bottom: 3px;
  }

  .description {
    transition: all 500ms ease-in-out;
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: var(--login-screen-color) !important;
    border-color: var(--green-color) !important;

    .country {
      color: var(--green-color) !important;
    }
  }

  @media (max-width: 600px) {
    min-width: 100%;
    width: 100%;
  }
}
</style>
