import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import storageService from "@/ag-portal-common/services/storage.service";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";
import { PATH } from "@/ag-portal-common/constants/path";
import { PERMISSIONS } from "@/ag-portal-common/enums/PERMISSIONS";
import {
  isRoleAllowed,
  isUserPermitted,
} from "@/ag-portal-common/utils/helpers";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { User } from "@/modules/Auth/types";
import { AUTH_EVENTS, authBus } from "@/ag-portal-common/eventBusses/auth";

export function AuthMiddleware(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const accessToken = storageService.getItem<string>(
    STORAGE_KEYS.ACCESS_TOKEN,
    false
  );
  const refreshToken = storageService.getItem<string>(
    STORAGE_KEYS.REFRESH_TOKEN,
    false
  );

  if (to.meta.isProtected && (!accessToken || !refreshToken)) {
    next(PATH.ROOT);
  } else if (to.meta.isProtected === false && accessToken && refreshToken) {
    next(PATH.DASHBOARD);
  } else {
    next();
  }
}

export function CheckRoleMiddleware(
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const user = storageService.getItem<User>(STORAGE_KEYS.USER);
  const allowedRoles = (to.meta?.roles as Array<USER_ROLES>) || [];
  const userRole = user?.role_unique_id as USER_ROLES;

  if (isRoleAllowed(userRole, allowedRoles)) {
    next();
  } else {
    authBus.emit(AUTH_EVENTS.ROUTE_ON_ERROR_403, to.path);
  }
}

export function CheckPermissionsMiddleware(
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const user = storageService.getItem<User>(STORAGE_KEYS.USER);
  const userPermissions = storageService.getItem<PERMISSIONS[]>(
    STORAGE_KEYS.PERMISSIONS
  );

  const allowedPermissions = (to.meta?.permissions as Array<PERMISSIONS>) || [];

  const isAgentUser = user?.role_unique_id === USER_ROLES.AGENT;
  const canAllowPermissions =
    isAgentUser && userPermissions
      ? isUserPermitted(allowedPermissions, userPermissions)
      : true;

  if (canAllowPermissions) {
    next();
  } else {
    authBus.emit(AUTH_EVENTS.ROUTE_ON_ERROR_403, to.path);
  }
}

export function ScrollToTopMiddleware() {
  const appBody = document.querySelector(".m-body");

  if (appBody) {
    appBody.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }
}
