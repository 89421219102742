export enum BOOKING_STATUSES {
  INITIATED = "Initiated",
  FAILED = "Failed",
  ISSUANCE_PROGRESS = "Issuance_progress",
  ISSUED = "Issued",
  CONFIRMED = "Confirmed",
  CANCELED = "Canceled",
  VOIDED = "Voided",
  WAITLIST = "Waitlist",
}
