<template>
  <MCard class="selected-flights-card">
    <div class="sf-header header-row">
      <div class="sf-title">Selected Flights</div>
      <div class="sf-action" @click="onResetAllFiltersHandler">Clear All</div>
    </div>

    <div class="sf-flights">
      <template v-for="(flight, index) in selectedFlights" :key="index">
        <div class="sf-flight">
          <img
            :src="flight.logo"
            class="sf-flight-icon"
            alt="select-flight-logo"
            width="18"
            height="18"
          />
          <MDivider type="vertical" class="sf-flight-divider" />
          <div class="sf-flight-date">
            {{ getDepartureTime(flight.departureTime) }}
          </div>
          <MDivider type="vertical" class="sf-flight-divider" />
          <div class="sf-flight-time">
            {{ getFormattedTime(flight.departureTime) }} -
            {{ getFormattedTime(flight.arrivalTime) }}
          </div>
          <MIcon
            class="sf-flight-icon sf-flight-edit"
            name="m-edit"
            width="18"
            height="18"
            title="Edit Flight"
            @click="onResetAllFiltersHandler"
          />
        </div>
      </template>
    </div>
  </MCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { MCard, MIcon, MDivider } from "@aeroglobe/ag-core-ui";

import { SelectedFlight } from "../../types";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";

export default defineComponent({
  name: "SelectedFlightsCard",
  components: { MCard, MIcon, MDivider },
  props: {
    selectedFlights: {
      type: Array as PropType<SelectedFlight[]>,
      required: true,
    },
  },
  emits: ["clear:flights", "edit:flight"],
  methods: {
    getDepartureTime(departureTime: string): string {
      return TimeUtility.parsedFlightTimeorDate(
        departureTime,
        "EE, MMM dd, yy"
      );
    },
    getFormattedTime(time: string): string {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    onResetAllFiltersHandler(): void {
      this.$emit("clear:flights");
    },
  },
});
</script>

<style scoped lang="css">
.selected-flights-card {
  color: var(--m-secondary-color);
}

.selected-flights-card .header-row {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}

.selected-flights-card .sf-header {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;

  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  margin-bottom: 22px;
}
.selected-flights-card .sf-header .sf-action {
  color: var(--m-primary-color);
  text-decoration: underline;
  cursor: pointer;

  user-select: none;
}

.sf-flights {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.sf-flights .sf-flight {
  height: 32px;
  width: 100%;

  border: 1px solid var(--m-divider-color);
  border-radius: 4px;

  padding: 0 8px;

  display: flex;
  align-items: center;

  font-size: 12px;
  font-weight: 500;

  box-shadow: 0 1px 2px var(--m-divider-color);
}
.sf-flights .sf-flight .sf-flight-icon {
  width: 18px;
  height: 18px;
}
.sf-flights .sf-flight .sf-flight-edit {
  margin-left: auto;
  border-radius: 50%;
  cursor: pointer;

  transition: background 0.2s ease-in-out;
}
.sf-flights .sf-flight .sf-flight-edit:hover {
  opacity: 0.5;
}
.sf-flights .sf-flight .sf-flight-divider {
  margin: 0 8px;
  height: 12px;
}
.sf-flights .sf-flight .sf-flight-date {
  min-width: 90px;
}
</style>
