<template>
  <div class="flight-route" :view-type="viewType">
    <div class="fr-column">
      <div class="fr-time">{{ details.originCode }}</div>
      <div class="fr-code">{{ details.originTime }}</div>
    </div>

    <div class="fr-column fr-route-details">
      <div class="fr-duration">{{ details.duration }}</div>
      <div class="fr-graphics">
        <MDivider class="fr-border" />
        <MIcon class="fr-icon" name="m-flight" />
        <MDivider class="fr-border" />
      </div>
      <div class="fr-code">
        <span>
          <b>{{ stopsLabel }}</b>
        </span>
        {{ " " }}
        <span v-if="stopsWithLayoffs.length > 0">
          ({{ stopsWithLayoffs.join(", ") }})
        </span>
      </div>
    </div>

    <div class="fr-column">
      <div class="fr-time">{{ details.destinationCode }}</div>
      <div class="fr-code">{{ details.destinationTime }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { MIcon, MDivider } from "@aeroglobe/ag-core-ui";

import { ListViewType, RouteDetails } from "../../FlightSearchResult/types";

export default defineComponent({
  name: "FlightRoute",
  props: {
    viewType: {
      type: String as PropType<ListViewType>,
      default: ListViewType.SINGLE,
    },
    details: {
      type: Object as PropType<RouteDetails>,
      required: true,
    },
  },
  components: {
    MIcon,
    MDivider,
  },
  computed: {
    stopsWithLayoffs(): string[] {
      return this.details.stops.filter((_, index) => this.haveLayOff(index));
    },
    stopsLabel(): string {
      const stopsLength = this.stopsWithLayoffs.length;

      if (stopsLength > 0) {
        return `${stopsLength} Stop${stopsLength > 1 ? "s" : ""}`;
      } else {
        return "Non Stop";
      }
    },
  },
  methods: {
    haveLayOff(index: number): boolean {
      return index + 1 < this.details.stops.length;
    },
  },
});
</script>

<style scoped lang="css">
@import url("../styles/flight-route.css");
</style>
