<template>
  <div class="forgot-pass-page-view">
    <MCard class="forgot-pass-card">
      <form
        @submit.prevent="onSubmitForgotPasswordFormHandler"
        class="forgot-pass-form"
      >
        <img
          :src="getOrgLogoHandler()"
          alt="organisation-logo"
          class="org-logo"
        />

        <MTypography type="title"> Forgot Your Password? </MTypography>

        <MTypography>
          Enter your registered email address below and we will send you a link
          to reset your password.
        </MTypography>

        <MTextfield
          v-model:input-value="forgotPassInformation.email.value"
          label="Email"
          placeholder="Enter your email"
          autocomplete="email"
          :has-error="forgotPassInformation.email.hasError"
          :error-message="forgotPassInformation.email.errorMessage"
          :disabled="isForgettingPassword"
        />

        <MButton
          type="text"
          class="btn-link"
          :disabled="isForgettingPassword"
          @click="onNavigateToLoginViewHandler"
        >
          Back To Login
        </MButton>

        <MButton
          behaviour="submit"
          class="btn-submit"
          :disabled="infoHasErrors() || isForgettingPassword"
        >
          Submit
        </MButton>
      </form>
    </MCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MCard, MTypography, MTextfield, MButton } from "@aeroglobe/ag-core-ui";
import { AUTH_ACTIONS } from "../vuex/actions";
import { AUTH_GETTERS } from "../vuex/getters";
import { Organization, User } from "../types";
import { AUTH_ANALYTICS_EVENTS } from "../constants/analyticsEvents";
import analyticServices from "@/analytic.service";

export default defineComponent({
  name: "ForgotPasswordView",
  components: { MCard, MTypography, MTextfield, MButton },
  created() {
    this.onPageTitleUpdateHandler();
  },
  mounted() {
    const htmlElement = document.querySelector("html");

    if (htmlElement) {
      htmlElement.style.overflow = "auto";
    }
  },
  unmounted() {
    const htmlElement = document.querySelector("html");

    if (htmlElement) {
      htmlElement.style.overflow = "hidden";
    }

    this.handleDataReset();
  },
  data() {
    return {
      forgotPassInformation: {
        email: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      },

      validateForgotPassInfoErrors: false,
    };
  },
  computed: {
    user(): User | null {
      return this.$store.getters[AUTH_GETTERS.USER];
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    isForgettingPassword(): boolean {
      return this.$store.getters[AUTH_GETTERS.IS_FORGETTING_PASSWORD];
    },
  },
  methods: {
    infoHasErrors() {
      const transformedArray = Object.keys(this.forgotPassInformation).map(
        (key) => (this.forgotPassInformation as any)[key]
      );

      this.handleForgotPassInfoErrors();

      return transformedArray.some((item) => item.hasError);
    },
    handleForgotPassInfoErrors() {
      if (this.validateForgotPassInfoErrors) {
        const { email } = this.forgotPassInformation;
        const emailRegex = new RegExp(
          "[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?"
        );

        if (email.value === "") {
          email.hasError = true;
          email.errorMessage = "Email is required";
        } else if (!emailRegex.test(email.value)) {
          email.hasError = true;
          email.errorMessage = "Email is not valid";
        } else {
          email.hasError = false;
          email.errorMessage = "";
        }
      }
    },
    handleDataReset() {
      this.forgotPassInformation = {
        email: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      };

      this.validateForgotPassInfoErrors = false;
    },
    async onSubmitForgotPasswordFormHandler() {
      this.validateForgotPassInfoErrors = true;

      this.handleForgotPassInfoErrors();

      if (this.infoHasErrors()) {
        return;
      }

      const payload = {
        email: this.forgotPassInformation.email.value,
      };

      await this.$store.dispatch(AUTH_ACTIONS.FORGOT_PASSWORD, payload);

      analyticServices.logActionEvent(
        AUTH_ANALYTICS_EVENTS.FORGOT_PASSWORD,
        payload
      );

      this.handleForgotPassInfoErrors();
    },

    onPageTitleUpdateHandler() {
      const organisationName = this.getOrganisationNameHandler();
      document.title = `Forgot Password - ${organisationName}`;
    },
    getOrgLogoHandler(): string {
      const DEFAULT_LOGO = "../../assets/logo.svg";
      const organization = this.organization;
      const org_logo = organization ? organization.org_logo : null;

      return org_logo ?? DEFAULT_LOGO;
    },
    getOrganisationNameHandler(): string {
      const DEFAULT_NAME = "Aeroglobe";
      const organization = this.organization;

      return organization ? organization.org_name_to_display : DEFAULT_NAME;
    },
    onNavigateToLoginViewHandler() {
      this.$router.push("/");
    },
  },
});
</script>

<style lang="css">
.forgot-pass-page-view {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #000 !important;
  }
}
</style>
<style lang="css" scoped>
.forgot-pass-page-view {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  padding: 20px;

  background-color: color-mix(in srgb, var(--m-primary-color), transparent 90%);

  display: flex;
  align-items: center;
  justify-content: center;

  .forgot-pass-card {
    width: 100%;
    max-width: 400px;

    padding: 30px;
    border-radius: calc(30px);

    box-shadow: 0 0 30px 0 var(--m-divider-color);
  }

  .forgot-pass-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .org-logo {
      max-width: 200px;
      margin: 20px auto;
      user-select: none;
      pointer-events: none;
    }

    .m-textfield {
      margin-bottom: 0;
    }

    .btn-link {
      padding-left: 0;
      padding-right: 0;
      align-self: flex-end;
    }

    .btn-submit {
      width: 100%;
    }
  }
}
</style>
