<template>
  <MDialog
    v-model="isMdialogOpen"
    @close="closeModal"
    max-width="1000"
    :title="renderTitle"
  >
    <template #content>
      <div>
        <AgFile
          test-id=""
          class="ag-file-theme"
          label="Service Image"
          :multiple="false"
          @on:change="onFileChangeHandler"
        />
        <m-textfield
          v-model:inputValue.trim="name"
          label="Service Name"
          :hasError="errors?.name"
          :errorMessage="errors?.name"
        />
        <m-textarea
          v-model:inputValue.trim="description"
          label="Service Description"
          :hasError="errors?.description"
          :errorMessage="errors?.description"
        />
      </div>
    </template>
    <template #actions>
      <MButton :disabled="isLoading" @click="onSubmit">
        {{ isEdit ? "Save" : "Add" }}
      </MButton>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ValidationError } from "yup";

import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import {
  ContentUpload,
  UPLOAD_CONTENT_CATEGORY,
  UPLOAD_CONTENT_SUB_CATEGORY,
} from "@/modules/Organization/dtos/organization.dto";
import { addServiceValidation } from "@/modules/Organization/validations/addService.validation";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { Organization } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";

export default defineComponent({
  name: "AddPackageModal",
  emits: ["closeModal", "onSubmit"],
  data(): {
    name: string;
    display_image_id?: string | null;
    description: string;
    errors: any;
    ELEMENT_TYPES: typeof ELEMENT_TYPES;
    selectedFile: File | null;
    isLoading: boolean;
    isMdialogOpen: boolean;
  } {
    return {
      name: "",
      display_image_id: null,
      description: "",
      errors: {},
      ELEMENT_TYPES,
      selectedFile: null,
      isLoading: false,
      isMdialogOpen: this.isOpen,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    renderTitle(): string {
      return this.isEdit ? "Edit Service" : "Add Service";
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
  },
  methods: {
    genTestId,
    closeModal() {
      this.$emit("closeModal");
      this.clearState();
    },
    initializeState() {
      if (this.isEdit) {
        this.name = this.data?.name;
        this.description = this.data?.description;
        this.display_image_id = this.data?.display_image_id;
      }
    },
    clearState() {
      this.name = "";
      this.description = "";
      this.display_image_id = null;
      this.selectedFile = null;
    },
    async onSubmit(e: any) {
      this.isLoading = true;
      this.errors = {};
      e.preventDefault();

      await this.onContentUploadHandler();

      try {
        const payload = await addServiceValidation.validate(
          {
            name: this.name,
            display_image_id: this.display_image_id,
            description: this.description,
          },
          { abortEarly: false }
        );
        this.$emit("onSubmit", payload);
        this.clearState();
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const _errors = yupValidationErrorAsSchema(ex);
          this.errors = _errors;
        }
      }
      this.isLoading = false;
    },
    onFileChangeHandler(files: FileList) {
      const file = files[0];
      this.selectedFile = file;
    },
    async onContentUploadHandler() {
      if (this.selectedFile && this.organization) {
        const payload: ContentUpload = {
          content: this.selectedFile,
          content_category: UPLOAD_CONTENT_CATEGORY.WEBSITE,
          content_sub_category: UPLOAD_CONTENT_SUB_CATEGORY.PACKAGE,
        };

        const organizationId = this.organization.organization_id;

        const response = await this.$store.dispatch("websiteUploadContent", {
          payload,
          organizationId,
        });

        this.display_image_id = response[0];
      }
    },
  },
  watch: {
    isOpen(val: boolean) {
      if (val) {
        setTimeout(() => {
          this.initializeState();
        }, 0);
      }
      this.isMdialogOpen = val;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("closeModal");
      }
    },
  },
});
</script>

<style scoped lang="scss">
#packageImageUploader {
  transition: scale 0.2s ease-in-out;

  &:active {
    transform: scale(0.98);
  }
}

.submit-btn {
  float: right;
}
</style>
