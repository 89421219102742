import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import NewsServices from "@/modules/News/services/news.service";
import { ActionTree, Commit } from "vuex";
import { INewsState } from "@/ag-portal-common/interfaces/news.interface";
import { StoreState } from "@/store/type";

const actions: ActionTree<INewsState, StoreState> = {
  async getAllNews({ commit }: { commit: Commit }) {
    let response: IAGResponse = {
      success: false,
    };
    const methodName = "actions.getAllNews";
    commit("setLoadingState", true); // Set loading state to true
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const newsServices = new NewsServices();
      response = await newsServices.getAllNews();
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        commit("setNews", response.data); // Commit news data to the state
        return response.data;
        //
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    } finally {
      commit("setLoadingState", false); // Set loading state to false
    }
  },
};

export default actions;
