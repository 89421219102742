<template>
  <NewTravelerCardAccordion
    v-for="(traveler, index) in localTravelers"
    :key="index"
    :headline="renderAccordionHeadline(traveler.isInternational)"
    :title="renderAccordionTitle(index)"
    :errorsLength="errorsLength(index)"
    :initialOpen="index === 0"
    :ref="`accordion-${index}`"
  >
    <div class="traveler-form-container">
      <NewTravelerCard
        :traveler="traveler"
        :isInternational="traveler.isInternational"
        :errors="localErrors[index]"
        @update:traveler="updateTraveler(index, $event)"
        @validate="(field, isValid) => validateTraveler(index, field, isValid)"
        :ref="`travelerCard-${index}`"
      />
      <NewTravelerCardPreview
        title="Traveler Details"
        :traveler="traveler"
        :previewFields="getPreviewFields(traveler.isInternational)"
        :formatPreviewValue="formatPreviewValue"
        :errors="localErrors[index]"
      />
    </div>
  </NewTravelerCardAccordion>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  ITraveler,
  ITravelerPreviewField,
} from "@/ag-flight-components/interfaces/IGroupFlight.interface";
import { format } from "date-fns";
import { titles, genders } from "@/ag-flight-components/constants/bookingForm";
import NewTravelerCard from "./NewTravelerCard.vue";
import NewTravelerCardAccordion from "./NewTravelerAccordion.vue";
import NewTravelerCardPreview from "./NewTravelerPreview.vue";
import { FORMAT_DD_MMM_YYYY } from "@/ag-portal-common/constants/dateTimeFormats";
import NATIONALITIES from "@/ag-portal-common/constants/nationalities";
import { splitCamelCase } from "@/ag-flight-components/utils";

export default defineComponent({
  name: "TravelersComponent",
  components: {
    NewTravelerCard,
    NewTravelerCardAccordion,
    NewTravelerCardPreview,
  },
  props: {
    travelers: {
      type: Array as PropType<ITraveler[]>,
      required: true,
    },
  },
  emits: ["update:travelers", "submit"],
  data() {
    return {
      localTravelers: [] as ITraveler[],
      localErrors: [] as Record<string, string>[],
      currentErrorIndex: -1,
      commonPreviewFields: [
        { key: "title", label: "Title" },
        { key: "firstName", label: "First Name" },
        { key: "lastName", label: "Last Name" },
        { key: "dateOfBirth", label: "Date of Birth" },
        { key: "gender", label: "Gender" },
        { key: "nationality", label: "Nationality" },
      ] as ITravelerPreviewField[],
      internationalPreviewFields: [
        { key: "passportNumber", label: "Passport Number" },
        { key: "passportExpiry", label: "Passport Expiry Date" },
      ] as ITravelerPreviewField[],
      domesticPreviewFields: [
        { key: "idNumber", label: "CNIC" },
      ] as ITravelerPreviewField[],
    };
  },
  computed: {
    isFormValid(): boolean {
      return this.localErrors.every((error) => Object.keys(error).length === 0);
    },
  },
  watch: {
    travelers: {
      handler(newTravelers) {
        this.localTravelers = JSON.parse(JSON.stringify(newTravelers));
        if (this.localErrors.length === 0) {
          this.initializeErrors(newTravelers.length);
        }
      },
      immediate: true,
    },
  },
  methods: {
    // Update the initializeErrors method
    initializeErrors(travelersCount: number) {
      this.localErrors = Array(travelersCount)
        .fill(null)
        .map(() => ({}));
    },
    handleSubmit() {
      this.validateAllTravelers();
      if (this.isFormValid) {
        this.$emit("submit", [...this.localTravelers]);
      } else {
        this.navigateToFirstError();
      }
    },
    validateAllTravelers() {
      this.localTravelers.forEach((_, index) => {
        const cardRef = this.$refs[`travelerCard-${index}`] as any;
        if (
          cardRef &&
          cardRef[0] &&
          typeof cardRef[0].validateAllFields === "function"
        ) {
          cardRef[0].validateAllFields();
        }
      });
      // Close all accordions except those with errors
      this.localTravelers.forEach((_, index) => {
        if (this.errorsLength(index) === 0) {
          this.closeAccordion(index);
        } else {
          this.openAccordion(index);
        }
      });
    },
    validateTraveler(index: number, field: string, isValid: boolean) {
      // Create a new copy of the errors for the specific traveler
      const travelerErrors = { ...this.localErrors[index] };

      if (isValid) {
        // If the field is valid, remove the error
        delete travelerErrors[field];
      } else {
        // If the field is invalid, set the error message
        const error = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } Is Required`;
        travelerErrors[field] = splitCamelCase(error);
      }

      // Update the localErrors array with the new travelerErrors object
      this.localErrors = [
        ...this.localErrors.slice(0, index),
        travelerErrors,
        ...this.localErrors.slice(index + 1),
      ];

      // If there are no more errors for the current traveler
      if (
        Object.keys(travelerErrors).length === 0 &&
        index === this.currentErrorIndex
      ) {
        this.closeAccordion(index);
        this.navigateToNextError();
      }
    },
    errorsLength(index: number): number {
      return this.localErrors[index]
        ? Object.keys(this.localErrors[index]).length
        : 0;
    },
    renderAccordionTitle(index: number): string {
      return `Traveler ${index + 1}`;
    },
    renderAccordionHeadline(isInternational: boolean): string {
      const travelType = isInternational ? "International" : "Domestic";
      return `Traveler Details - ${travelType}`;
    },
    getPreviewFields(isInternational: boolean): ITravelerPreviewField[] {
      if (isInternational) {
        return [
          ...this.commonPreviewFields,
          ...this.internationalPreviewFields,
        ];
      } else {
        return [...this.commonPreviewFields, ...this.domesticPreviewFields];
      }
    },
    formatPreviewValue(key: string, value: any): string {
      if (!value) return "";

      switch (key) {
        case "dateOfBirth":
        case "passportExpiry":
          try {
            return format(new Date(value), FORMAT_DD_MMM_YYYY);
          } catch (error) {
            console.error("Error parsing date:", error);
            return value;
          }
        case "nationality":
          return this.getNationalityLabel(value);
        case "title":
          return this.getTitleLabel(value);
        case "gender":
          return this.getGenderLabel(value);
        default:
          return value;
      }
    },
    getNationalityLabel(value: string): string {
      const nationality = NATIONALITIES.find((n) => n.value === value);
      return nationality ? nationality.label : value;
    },
    getTitleLabel(value: string): string {
      const title = titles.find((t) => t.value === value);
      return title ? title.label : value;
    },
    getGenderLabel(value: string): string {
      const gender = genders.find((g) => g.value === value);
      return gender ? gender.label : value;
    },
    navigateToFirstError() {
      const firstErrorIndex = this.localErrors.findIndex(
        (error) => Object.keys(error).length > 0
      );
      if (firstErrorIndex !== -1) {
        this.currentErrorIndex = firstErrorIndex;
        this.scrollToError(firstErrorIndex);
      }
    },
    navigateToNextError() {
      const nextErrorIndex = this.localErrors.findIndex(
        (error, index) =>
          index > this.currentErrorIndex && Object.keys(error).length > 0
      );

      if (nextErrorIndex !== -1) {
        this.currentErrorIndex = nextErrorIndex;
        this.scrollToError(nextErrorIndex);
      } else {
        this.currentErrorIndex = -1;
      }
    },
    scrollToError(travelerIndex: number) {
      this.openAccordion(travelerIndex);
      this.$nextTick(() => {
        const travelerCard = this.$refs[`travelerCard-${travelerIndex}`] as any;
        if (
          travelerCard &&
          travelerCard[0] &&
          typeof travelerCard[0].scrollToFirstError === "function"
        ) {
          travelerCard[0].scrollToFirstError();
        }
      });
    },
    openAccordion(index: number) {
      const accordion = this.$refs[`accordion-${index}`] as any;
      if (
        accordion &&
        accordion[0] &&
        typeof accordion[0].open === "function"
      ) {
        accordion[0].open();
      }
    },
    closeAccordion(index: number) {
      const accordion = this.$refs[`accordion-${index}`] as any;
      if (
        accordion &&
        accordion[0] &&
        typeof accordion[0].close === "function"
      ) {
        accordion[0].close();
      }
    },
    updateTraveler(index: number, updatedTraveler: ITraveler) {
      this.localTravelers[index] = updatedTraveler;
      this.$emit("update:travelers", [...this.localTravelers]);
    },
  },
});
</script>

<style lang="scss" scoped>
.travelers-component {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.traveler-form-container {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .traveler-form-container {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
}
</style>
