<template>
  <MAccordion :initial-open="true" id="calculateRefundSummary">
    <template #title>
      <AgIconInfoBar
        test-id=""
        mPrepandIcon="m-passenger-management"
        title="Calculated Refund Summary"
        class="section-title"
      />
    </template>
    <template #content>
      <MCard class="no-padding-card">
        <RefundStatusFlag
          title="Total Amount"
          :code="getCalculatedSummary().total_amount"
          class="refund-summary-flag"
        />

        <RefundStatusFlag
          title="Total Refund Charges"
          :code="getCalculatedSummary().total_refund_charges"
          class="refund-summary-flag"
        />

        <RefundStatusFlag
          title="Total Refundable Amount"
          :code="getCalculatedSummary().refundable_amount"
          class="refund-summary-flag"
        />
      </MCard>
      <div class="flight-refunds-content">
        <div>
          <div v-for="(passenger, index) in getPassengers" :key="index">
            <MAccordion class="passenger-accordion-header" :initial-open="true">
              <template #title>
                <div class="refund-passenger">
                  <div class="widget_border refunds-checkbox">
                    <AgContactInfo
                      icon-name="pakFlag"
                      :title="
                        renderPassengerTitle(
                          passenger.first_name,
                          passenger.last_name,
                          passenger.title
                        )
                      "
                      class="contact-info"
                    />

                    <AgContactInfo
                      :mPrepandIcon="
                        renderPassengerType(passenger.passenger_type).icon
                      "
                      :title="
                        renderPassengerType(passenger.passenger_type).type
                      "
                      class="contact-info"
                    />

                    <AgContactInfo
                      icon-name="notInvoice"
                      :title="passenger.ticket_number"
                      class="contact-info"
                    />
                  </div>
                </div>
              </template>
              <template #content>
                <div>
                  <MCard
                    v-for="(legs, legIndex) in passenger.leg_details"
                    :key="legIndex"
                    class="no-padding-card"
                  >
                    <div class="m-flight">
                      <div class="m-title">
                        <span class="m-icon">
                          <m-flight-take-off-icon />
                        </span>
                        <m-typography type="body" class="m-text">
                          {{ renderSegmentNames(legs) }}
                        </m-typography>
                      </div>

                      <div class="m-body">
                        <div class="m-timeline">
                          <div class="date">
                            <m-typography type="body">
                              {{ renderSegmentDate(legs) }}
                            </m-typography>
                            <m-typography type="label">
                              {{ renderSegmentTime(legs) }}
                            </m-typography>
                          </div>

                          <span class="m-icon">
                            <m-flight-timeline-icon />
                          </span>

                          <div class="date">
                            <m-typography type="body">
                              Base Refund Charges
                            </m-typography>
                            <m-typography type="label">
                              {{ getLegsInfo(legs).base_charges }}
                            </m-typography>
                          </div>

                          <span class="m-icon">
                            <m-flight-timeline-icon />
                          </span>

                          <div class="date">
                            <m-typography type="body">
                              Other Refund Charges
                            </m-typography>
                            <m-typography type="label">
                              {{ getLegsInfo(legs).other_charges }}
                            </m-typography>
                          </div>

                          <span class="m-icon">
                            <m-flight-timeline-icon />
                          </span>

                          <div class="date">
                            <m-typography type="body"> Base Fare </m-typography>
                            <m-typography type="label">
                              {{ getLegsInfo(legs).base_fare }}
                            </m-typography>
                          </div>
                          <span class="m-icon">
                            <m-flight-timeline-icon />
                          </span>

                          <div class="date">
                            <m-typography type="body"> Fees </m-typography>
                            <m-typography type="label">
                              {{ getLegsInfo(legs).fees }}
                            </m-typography>
                          </div>

                          <span class="m-icon">
                            <m-flight-timeline-icon />
                          </span>

                          <div class="date">
                            <m-typography type="body"> Taxes </m-typography>
                            <m-typography type="label">
                              {{ getLegsInfo(legs).taxes }}
                            </m-typography>
                          </div>

                          <span class="m-icon">
                            <m-flight-timeline-icon />
                          </span>

                          <div class="date">
                            <m-typography type="body"> Others </m-typography>
                            <m-typography type="label">
                              {{ getLegsInfo(legs).others }}
                            </m-typography>
                          </div>
                          <span class="m-icon">
                            <m-flight-timeline-icon />
                          </span>

                          <div class="date">
                            <m-typography type="body">
                              Total Fare
                            </m-typography>
                            <m-typography type="label">
                              {{ getLegsInfo(legs).total_fare }}
                            </m-typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </MCard>
                </div>
              </template>
            </MAccordion>
          </div>
        </div>
      </div>
    </template>
  </MAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { format, parseISO } from "date-fns";
import { MCard, MTypography } from "@aeroglobe/ag-core-ui";
import RefundStatusFlag from "../components/RefundedFlag.vue";

import {
  TravelerTypes,
  getMaterialTravelerTypeIcon,
  getMaterialTravelerTypeName,
} from "../../../ag-flight-components/constants/TravelerTypes";
import { getCurrencyFormatter } from "../../../ag-flight-components/utils";
import {
  LegDetail,
  PassengerDetail,
  Penalties,
  Penalty,
} from "@/modules/FlightRefunds/constants/index";
import {
  CalculateFlightRefundPNRResponse,
  PenaltyDetail,
} from "@/ag-flight-components/interfaces/IFlightRefunds.interface";

export default defineComponent({
  name: "CalculateSummary",
  components: {
    MTypography,
    MCard,
    RefundStatusFlag,
  },
  mounted() {
    const calculateRefundSummary = document.getElementById(
      "calculateRefundSummary"
    );

    if (calculateRefundSummary) {
      calculateRefundSummary.scrollIntoView({ behavior: "smooth" });
    }
  },
  computed: {
    hasPenalties(): boolean {
      const fetchRefundDetails = this.$store.getters.refundPNRDetails;
      return Object.keys(fetchRefundDetails.penalties).length > 0
        ? true
        : false;
    },
    getPenalties(): Penalties {
      const fetchRefundDetails = this.$store.getters.refundPNRDetails;
      return fetchRefundDetails.penalties;
    },
    hasPassengers(): boolean {
      const fetchRefundDetails = this.$store.getters.refundPNRDetails;
      return Object.keys(fetchRefundDetails.penalties).length > 0
        ? true
        : false;
    },
    getPassengers(): PassengerDetail[] {
      const fetchRefundDetails: CalculateFlightRefundPNRResponse =
        this.$store.getters.calculateRefundDetails;
      return fetchRefundDetails.passenger_details;
    },
    isFetchingPNR(): boolean {
      return this.$store.getters.isPNRFetching;
    },
    isIssueTicketLoading(): boolean {
      return this.$store.getters.isIssueTicketLoading;
    },
  },
  data() {
    return {
      selectedLegs: [],
      haveLegs: false,
      legsCount: 0,
      OTP: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      validateFlightInfoErrors: false,
      validateFlightrefundsErrors: false,
    };
  },
  methods: {
    getCalculatedSummary(): {
      total_refund_charges: string;
      total_amount: string;
      refundable_amount: string;
    } {
      const refundDetails = this.$store.getters.calculateRefundDetails;
      const totalRefundCharges = this.formattedCurrency(
        refundDetails.total_refund_charges.currency,
        refundDetails.total_refund_charges.value
      );
      const totalAmount = this.formattedCurrency(
        refundDetails.total_amount.currency,
        refundDetails.total_amount.value
      );
      const refundableAmount = this.formattedCurrency(
        refundDetails.refundable_amount.currency,
        refundDetails.refundable_amount.value
      );
      const calculatedRefundSummary = {
        total_refund_charges: totalRefundCharges,
        total_amount: totalAmount,
        refundable_amount: refundableAmount,
      };
      return calculatedRefundSummary;
    },
    handlePenalties(penalties: Penalty): PenaltyDetail[] {
      return penalties.penalty_details;
    },
    formattedTime(date: string): string {
      const arrivalTime = new Date(date);
      const parsedTime = parseISO(arrivalTime.toISOString());
      const formattedTime = format(parsedTime, "HH:mm");
      return formattedTime;
    },
    formattedDate(date: string): string {
      const arrivalDate = new Date(date);
      const parsedDate = parseISO(arrivalDate.toISOString());
      const formattedDate = format(parsedDate, "EEE, MMM dd, yyyy");
      return formattedDate;
    },
    renderSegmentNames(legs: LegDetail): string {
      const segmentDetails = legs.segment_details;
      let legInformation = "";

      for (const segment of segmentDetails) {
        legInformation +=
          segment.departure_city_code + " - " + segment.arrival_city_code + " ";
      }

      return legInformation.trim();
    },
    renderSegmentDate(legs: LegDetail): string {
      const segmentDetails = legs.segment_details;

      return this.formattedDate(segmentDetails[0].departure_date_time);
    },
    renderSegmentTime(legs: LegDetail): string {
      const segmentDetails = legs.segment_details;

      return (
        this.formattedTime(segmentDetails[0].departure_date_time) +
        " - " +
        this.formattedTime(segmentDetails[0].arrival_date_time)
      );
    },
    haveLegDetails(legs: LegDetail[]): boolean {
      this.legsCount = legs.length;
      this.haveLegs = legs ? true : false;

      return this.haveLegs;
    },
    getLegsInfo(legs: LegDetail): {
      fees: string;
      base_fare: string;
      others: string;
      taxes: string;
      total_fare: string;
      base_charges: string;
      other_charges: string;
    } {
      const fareInfo = legs.fare_detail.fare_info;
      const refundInfo = legs.fare_detail.refund_charges;
      const currency = legs.fare_detail.currency_code;
      const fees = this.formattedCurrency(currency, fareInfo.fees.value);
      const baseFare = this.formattedCurrency(
        currency,
        fareInfo.base_fare.value
      );
      const others = this.formattedCurrency(currency, fareInfo.others.value);
      const taxes = this.formattedCurrency(currency, fareInfo.taxes.value);
      const totalFare = this.formattedCurrency(
        currency,
        fareInfo.total_fare.value
      );
      const baseCharges = this.formattedCurrency(
        currency,
        refundInfo.base_refund.value
      );
      const otherCharges = this.formattedCurrency(
        currency,
        refundInfo.other_charges.value
      );
      const legsData = {
        fees: fees,
        base_fare: baseFare,
        others: others,
        taxes: taxes,
        total_fare: totalFare,
        base_charges: baseCharges,
        other_charges: otherCharges,
      };
      return legsData;
    },
    renderPassengerTitle(firstName: string, lastName: string, title?: string) {
      return `${title ?? ""} ${firstName} ${lastName}`;
    },
    renderPassengerType(passengerType: string): {
      type: string;
      icon: string;
    } {
      const type = getMaterialTravelerTypeName(passengerType as TravelerTypes);
      const icon = getMaterialTravelerTypeIcon(type as TravelerTypes);

      return {
        type,
        icon,
      };
    },
    formattedCurrency(currency: string, amount: number): string {
      return getCurrencyFormatter(currency).format(amount);
    },
    async handleCalculateRefundDetails(): Promise<void> {
      const payload = {
        fare_refund_ids: this.selectedLegs || [],
      };

      await this.$store.dispatch("calculateRefundPNR", payload);
    },
  },
});
</script>

<style lang="css" scoped>
.no-padding-card {
  padding: 0px;
  margin-bottom: 5px;
}
.refund-passenger {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.chip-content {
  max-width: max-content;
  align-items: center;
}
.contact-info {
  min-width: inherit;
  margin-left: 10px;
}
.calculate-btn {
  margin-bottom: 10px;
  margin-top: 0;
}
</style>

<style scoped>
.flight-refunds-content {
  padding-top: 24px;
}
.accordion-header {
  border: none;
  padding-bottom: 0;
}
.section-title {
  margin: 0;
  border: none;
  padding: 0;
}
.passenger-accordion-header {
  padding: 5px 20px;
  border: 1px solid var(--m-divider-color);
}
.refunds-checkbox {
  min-width: max-content;
  min-height: 50px;
  /* margin-top: 10px; */
  font-weight: bolder;
  max-width: 100%;
  flex: 1;

  width: 100%;
  display: flex;
  align-items: center;
}
.mchip-checkbox {
  min-width: max-content;
  min-height: 50px;
  margin-top: 10px;
  font-weight: bolder;
  margin-left: 10px;
}
.refunds {
  --text-color: #49454f;
  --label-color: #636363;
  --border-color: #ababab;
  --error-color: #b3261e;
  --theme-color: var(--green-color);
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
.refunds-inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.refunds-inputs,
.refunds-action {
  margin-bottom: 16px;
}
.refunds-inputs .row {
  display: flex;
  gap: 24px;
  width: 100%;
}
.refunds .actions {
  display: flex;
  gap: 16px;
}
.m-otp {
  max-width: 50%;
}
.m-success-message {
  color: #22bb33;
}
.m-multiline-chip-wrapper {
  text-align: left;
  max-lines: 2;
  align-items: start;
}

@media screen and (max-width: 600px) {
  .refunds-inputs .row {
    display: flex;
    flex-direction: column;
  }
  .m-refunds .m-otp {
    max-width: none;
  }
}

@media screen and (max-width: 400px) {
  .refunds .actions {
    flex-direction: column;
  }
  .refunds .actions button,
  .m-refunds button {
    width: 100%;
  }
}
.refund-charges-typographies {
  margin-right: 25px;
}
.refund-summary-flag {
  margin-top: 10px;
}
</style>

<style scoped>
.m-flight-summary {
  width: 100%;
  --s-border-color: #ececec;
}
.m-flight {
  border-radius: 12px;
  border: 1px solid var(--s-border-color);
  margin-bottom: 16px;
}

.m-header {
  margin-bottom: 8px;
}

.m-title {
  border-bottom: 1px solid var(--s-border-color);
}

.m-title,
.m-body {
  padding: 8px;
}

.m-title {
  display: flex;
  gap: 24px;
}

.m-title .m-text {
  font-size: 14px;
}

.m-body {
  display: flex;
  flex-direction: column;
}

.m-body .m-timeline {
  display: flex;
  gap: 8px;
}

.m-icon {
  width: 24px;
  height: 24px;
}
</style>

<style lang="css">
.m-accordion-container .open {
  max-height: max-content;
}
</style>
