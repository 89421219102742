import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { ActionTree } from "vuex";
import SABREService from "@/modules/SabreSegment/services/sabreSegment.service";
import { ISabreSegmentState } from "@/ag-portal-common/interfaces/sabreSegmentState.interface";
import { StoreState } from "@/store/type";

const actions: ActionTree<ISabreSegmentState, StoreState> = {
  async defaultSabreSegmentList() {
    let response: IAGResponse = {
      success: false,
    };
    const methodName = "actions.defaultSabreSegmentList";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const sabreSegmentService = new SABREService();
      response = await sabreSegmentService.getByDefaultSegmentList();
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        return response.data?.data.segment_list;
        //
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    } finally {
      //
    }
  },
  async defaultSabreSegmentStats() {
    let response: IAGResponse = {
      success: false,
    };
    const methodName = "actions.defaultSabreSegmentStats";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const sabreSegmentService = new SABREService();
      response = await sabreSegmentService.getByDefaultSegmentStats();
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        return response.data?.data;
        //
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    } finally {
      //
    }
  },
};

export default actions;
