import { GetterTree } from "vuex";
import { IFDGState } from "@/interfaces/fdgState.interface";
import { IFDG } from "@/interfaces/fdg.interface";
import { IFDGDetail } from "@/interfaces/fdgDetail.interface";
import {
  IFDGBookingDetail,
  IFDGBookingPassenger,
} from "@/interfaces/fdgBookingDetail.interface";
import { StoreState } from "@/store/type";
import { FlightLeg } from "@/ag-flight-components/types";

const getters: GetterTree<IFDGState, StoreState> = {
  isFetchingFDGList: (state): boolean => state.isFetchingFDGList,
  fdgList: (state): Array<IFDG> => state.fdgList,
  fdgDetail: (state): IFDGDetail | null => state.fdgDetail,
  isFetchingFDGDetail: (state): boolean => state.isFetchingFDGDetail,
  fdgBookingDetail: (state): IFDGBookingDetail | null => state.fdgBookingDetail,
  isFetchingFDGBookingDetail: (state): boolean =>
    state.isFetchingFDGBookingDetail,
  isInitiatingFDGBooking: (state): boolean => state.isInitiatingFDGBooking,
  isConfirmFDGBooking: (state): boolean => state.isConfirmFDGBooking,
  isConfirmFDGBookingWithoutTravelerDetails: (state): boolean =>
    state.isConfirmFDGBookingWithoutTravelerDetails,
  totalNumberofPassengers: (state): number | undefined =>
    state.fdgBookingDetail?.numberOfGuests,
  flightLegItems: (state): FlightLeg[] | undefined =>
    state.fdgBookingDetail?.flightLegItems,
  isOTPLoading: (state): boolean => state.isOTPVerificationInProgress,
  isGroupBookingPassengersFetching: (state): boolean =>
    state.isGroupBookingPassengersFetching,
  groupBookingPassengers: (state): IFDGBookingPassenger[] => state.passengers,
  groupFlightsOTPReference: (state): string => state.bookingFormOTPReference,
  isGroupFlightsPaynowLoading: (state): boolean =>
    state.isGroupFlightsPaynowLoading,
};

export default getters;
