import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7eaff6a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "floating-widgets" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_MLayout = _resolveComponent("MLayout")!
  const _component_AgPopupAlert = _resolveComponent("AgPopupAlert")!
  const _component_WhatsAppWidget = _resolveComponent("WhatsAppWidget")!
  const _component_FreshDeskWidget = _resolveComponent("FreshDeskWidget")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MLayout, {
      "is-agent-user": _ctx.isAgentUser,
      "fp-amount-details": _ctx.fpAmountDetails,
      "organisation-title": _ctx.getOrganisationNameHandler(),
      "organisation-logo": _ctx.getOrgLogoHandler(),
      "page-title": _ctx.pageTitle,
      "is-loading-financial-profile": _ctx.isLoadingFinances,
      "financial-profile-status": _ctx.fpAccountStatus,
      "financial-profile-status-label": _ctx.fpAccountStatusLabel,
      "header-menu-list": _ctx.headerMenuList,
      "sidebar-items": _ctx.sidebarItems,
      "is-forced-closed": _ctx.isForcedClosed,
      "onClick:financialProfile": _ctx.onClickFinancialBreakdownTrack,
      "onReload:financialProfile": _ctx.onRefreshBalanceHandler
    }, {
      default: _withCtx(() => [
        _createVNode(_component_RouterView, null, {
          default: _withCtx(({ Component, route }) => [
            _createVNode(_Transition, {
              appear: true,
              name: "router-view-fade-transition"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: route.path
                }))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-agent-user", "fp-amount-details", "organisation-title", "organisation-logo", "page-title", "is-loading-financial-profile", "financial-profile-status", "financial-profile-status-label", "header-menu-list", "sidebar-items", "is-forced-closed", "onClick:financialProfile", "onReload:financialProfile"]),
    _createVNode(_component_AgPopupAlert),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_WhatsAppWidget, {
        link: _ctx.getWhatsAppContactLink()
      }, null, 8, ["link"]),
      (_ctx.isAgentOrSubAgentUser)
        ? (_openBlock(), _createBlock(_component_FreshDeskWidget, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}