<template>
  <MDialog
    :title="renderDialogHeading()"
    v-model="isMdialogOpen"
    max-width="1000"
    @close="$emit('closeModal')"
    hide-footer
  >
    <template #content>
      <div class="image-row">
        <div
          v-for="(item, index) in renderCNIC()"
          :key="index"
          class="image-container"
        >
          <img
            class="cnic-image"
            :src="item?.content_url"
            @error="item.content_url = require('@/assets/news-icon.png')"
            alt="Cnic"
          />
        </div>
      </div>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DocumentsModal",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    selectedDocuments: {
      type: Object,
      default: () => ({}),
    },
    organizationName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      documents: {},
      isMdialogOpen: this.isOpen,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    renderCNIC() {
      return this.selectedDocuments?.cnic || [];
    },
    renderDialogHeading() {
      return (this.organizationName + " documents").toLocaleUpperCase();
    },
  },
  watch: {
    selectedDocuments(data: object) {
      this.documents = data;
    },
    isOpen(updatedValue) {
      this.isMdialogOpen = updatedValue;
    },
    isMdialogOpen(updatedValue) {
      if (!updatedValue) {
        this.$emit("closeModal");
      }
    },
  },
});
</script>

<style lang="css" scoped>
.image-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 50px;
  justify-content: center;
  margin-bottom: 20px;
}

.image-container {
  flex: 0 0 auto;
}

.cnic-image {
  width: 350px;
  height: 350px;
  object-fit: contain;
  border-radius: 12px;
}
</style>
