import { Module } from "vuex";
import { IPermissionsState } from "@/ag-portal-common/interfaces/permissionsState.interface";
import { StoreState } from "@/store/type";

import mutations from "./mutations";
import actions from "./actions";
import state from "./state";
import getters from "./getters";

const permissionsModule: Module<IPermissionsState, StoreState> = {
  state,
  mutations,
  actions,
  getters,
};

export default permissionsModule;
