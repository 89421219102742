import { GetterTree } from "vuex";
import { ISabreSegmentState } from "@/ag-portal-common/interfaces/sabreSegmentState.interface";
import { StoreState } from "@/store/type";

const getters: GetterTree<ISabreSegmentState, StoreState> = {
  defaultSegmentList: (state) => state.defaultSegmentList,
  defaultSegmentStats: (state) => state.defaultSegmentStats,
};

export default getters;
