<template>
  <MProgress
    class="mprogress-alignment"
    indeterminate
    v-if="$store.getters.isFetchingPayments"
  />
  <div
    v-else-if="
      !$store.getters.isFetchingPayments && $store.getters.payments.payments
    "
  >
    <MCard class="whatsapp-management-heading">
      <div class="d-flex justify-content-between align-items-center">
        <AgIconInfoBar
          test-id=""
          title="Payment Management"
          class="section-title"
          mPrepandIcon="m-payment-management"
        />
        <div>
          <MButton type="filled" @click="routeToCreatePayment"
            >Create New Payment
          </MButton>
        </div>
      </div>
    </MCard>
    <MCard>
      <MDataTable
        :is-api-paginated="true"
        :has-search="true"
        :total-item-count="$store.getters.payments.totalRecords"
        :headers="headers"
        :item-per-page="$store.getters.payments.pageSize"
        :data="$store.getters.payments.payments"
        @onPageChange="onPageChange"
      >
        <template #transactionId="{ item }">
          <MTypography type="body" class="description td-full-width">
            {{ item.transactionId }}
          </MTypography>
        </template>
        <template #transactionType="{ item }">
          <MTypography type="label" class="description td-full-width">
            {{ TRANSACTION_TYPES[item.transactionType] }}
          </MTypography>
        </template>
        <template #paymentStatus="{ item }">
          <div class="mchip-alignment td-full-width">
            <MChip
              :border-less="true"
              class="pricing-chip"
              :variant="paymentStatusClass(item.paymentStatus)"
            >
              {{ PAYMENT_STATUSES[item.paymentStatus] }}</MChip
            >
          </div>
        </template>
        <template #receiptStatus="{ item }">
          <div class="mchip-alignment td-full-width">
            <MChip
              :border-less="true"
              class="pricing-chip"
              :variant="receiptStatusClass(item.receiptStatus)"
            >
              {{ RECEIPT_STATUSES[item.receiptStatus] }}
            </MChip>
          </div>
        </template>
        <template #dateTime="{ item }">
          <MTypography type="body" class="description td-full-width">
            {{
              getFormattedDateTime(item.dateTime, FORMAT_DD_MMM_YYYY_WITH_DASH)
            }}
          </MTypography>

          <MTypography type="label" class="description td-full-width">
            {{ getFormattedDateTime(item.dateTime, FORMAT_HH_SS_24_HOURS) }}
          </MTypography>
        </template>
        <template #amount="{ item }">
          <MTypography type="label" class="description td-full-width">
            {{ formattedCurrency(item.amount, item.currency) }}
          </MTypography>
        </template>
        <template #action="{ item }">
          <MButton @Click="showPaymentDetails(item.id)">
            {{
              item.transactionType === PAYMENT_METHODS_TYPES.ONE_BILL
                ? "Update Status"
                : "View Details"
            }}
          </MButton>
        </template>
      </MDataTable>
    </MCard>
  </div>

  <PaymentDetailModal
    :show-modal="showDetailModal"
    v-if="showDetailModal"
    @onCloseModal="onCloseModal"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  formatNumber,
  formatStringToRoutePath,
  genTestId,
  getFormattedDateTime,
  getCurrencyFormatter,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { FetchPaymentsParamsRequestModel } from "@/modules/Payments/models/fetchPaymentsParams.request";
import { TRANSACTION_TYPES } from "@/ag-portal-common/constants/transactionTypes";
import { PAYMENT_STATUSES } from "@/ag-portal-common/constants/paymentStatuses";
import { RECEIPT_STATUSES } from "../../../ag-portal-common/constants/receiptStatuses";
import { PAYMENT_METHODS_TYPES } from "../../../ag-portal-common/enums/PAYMENT_METHODS_TYPES";
import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_HH_SS_24_HOURS,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { RECEIPT_STATUS } from "@/ag-portal-common/enums/RECEIPT_STATUS";
import { PAYMENT_STATUS } from "@/ag-portal-common/enums/PAYMENT_STATUS";
import { PATH } from "@/ag-portal-common/constants/path";
import PaymentDetailModal from "@/modules/Payments/components/PaymentDetailModal.vue";
import {
  PAYMENT_STATUS_MAPPING,
  RECEIPT_STATUS_MAPPING,
} from "@/modules/Payments/constants";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { MButton, MDataTable, MTypography } from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";

export default defineComponent({
  name: "PaymentList",
  components: { PaymentDetailModal, MButton, MDataTable, MTypography },
  computed: {
    PATH(): typeof PATH {
      return PATH;
    },
  },
  data(): {
    ELEMENT_TYPES: IObject;
    PAYMENT_METHODS_TYPES: IObject;
    NOTIFICATION_MESSAGES: IObject;
    TRANSACTION_TYPES: IObject;
    PAYMENT_STATUSES: IObject;
    RECEIPT_STATUSES: IObject;
    headers: MDataTableHeader[];
    fetchPaymentsParams: FetchPaymentsParamsRequestModel;
    showDetailModal: boolean;
    FORMAT_DD_MMM_YYYY_WITH_DASH: string;
    FORMAT_HH_SS_24_HOURS: string;
  } {
    return {
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
      FORMAT_HH_SS_24_HOURS: FORMAT_HH_SS_24_HOURS,
      fetchPaymentsParams: {
        page_no: 1,
      },
      ELEMENT_TYPES,
      PAYMENT_METHODS_TYPES,
      NOTIFICATION_MESSAGES,
      TRANSACTION_TYPES,
      PAYMENT_STATUSES,
      RECEIPT_STATUSES,
      showDetailModal: false,
      headers: [
        {
          title: "Transaction ID",
          value: "transactionId",
          key: "transactionId",
          sortable: false,
        },
        {
          title: "Transaction Type",
          value: "transactionType",
          key: "transactionType",
          sortable: false,
        },
        {
          title: "Amount",
          value: "amount",
          key: "amount",
          sortable: false,
        },
        {
          title: "Financial Profile",
          value: "financialProfile",
          key: "financialProfile",
          sortable: false,
        },
        {
          title: "Date & Time",
          value: "dateTime",
          key: "dateTime",
          sortable: false,
        },
        {
          title: "Payment Status",
          value: "paymentStatus",
          key: "paymentStatus",
          sortable: false,
        },
        {
          title: "Receipt Status",
          value: "receiptStatus",
          key: "receiptStatus",
          sortable: false,
        },
        {
          title: "Agent Email",
          value: "agentEmail",
          key: "agentEmail",
          sortable: false,
        },
        {
          title: "Action",
          value: "action",
          key: "action",
          sortable: false,
        },
      ],
    };
  },
  beforeMount() {
    this.fetchPayments();
  },
  methods: {
    formatStringToRoutePath,
    getFormattedDateTime,
    showPaymentDetails(paymentId: string) {
      this.$router.push(
        formatStringToRoutePath(PATH.PAYMENT_DETAIL, {
          id: paymentId,
        })
      );
    },
    formattedCurrency(amount: number, currency: string) {
      const formattedAmount = getCurrencyFormatter(currency).format(amount);
      return formattedAmount;
    },
    formatNumber,
    genTestId,
    onPageChange() {
      this.fetchPaymentsParams.page_no =
        this.$store.getters.payments.pageNumber + 1;
      this.fetchPayments();
    },
    receiptStatusClass(status: RECEIPT_STATUS) {
      return `${RECEIPT_STATUS_MAPPING[status]}`;
    },
    paymentStatusClass(status: PAYMENT_STATUS) {
      return `${PAYMENT_STATUS_MAPPING[status]}`;
    },
    routeToCreatePayment() {
      this.$router.push(PATH.CREATE_PAYMENT);
    },
    fetchPayments() {
      this.$store.dispatch("fetchPayments", this.fetchPaymentsParams);
    },
    openDetailModal(value: string) {
      if (value) {
        this.showDetailModal = true;
      }
    },
    onCloseModal() {
      this.$router.replace(PATH.PAYMENTS);
      this.showDetailModal = false;
    },
  },

  watch: {
    "$route.params.id": {
      handler(value) {
        this.openDetailModal(value);
      },
      immediate: true,
    },
  },
});
</script>

<style lang="css" scoped>
.whatsapp-management-heading {
  margin-bottom: 10px;
}
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
</style>
