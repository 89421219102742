<template>
  <div class="dashboard">
    <MTypography class="greetings-heading"> Greetings! 🌟 </MTypography>
    <MTypography class="greetings-sub-heading">
      Thank you for downloading visa for {{ user.toLowerCase() }} 🚀
    </MTypography>
  </div>
</template>

<script lang="ts">
import { MTypography } from "@aeroglobe/ag-core-ui";
import { defineComponent } from "vue";

export default defineComponent({
  name: "GreetingsCard",
  props: {
    user: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="css" scoped>
.dashboard {
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.greetings-heading {
  font-size: 4.5em;
  margin-bottom: 10px;
  line-height: 0.875;
}
.greetings-sub-heading {
  font-size: 2.5em;
  margin-bottom: 10px;
  line-height: 1.875;
}
.dashboard {
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.greetings-heading {
  font-size: 4.5em;
  margin-bottom: 10px;
  line-height: 0.875;
}

.greetings-sub-heading {
  font-size: 2.5em;
  margin-bottom: 10px;
  line-height: 1.875;
}

@media (max-width: 1024px) {
  .greetings-heading {
    font-size: 3.5em;
    margin-bottom: 8px;
  }

  .greetings-sub-heading {
    font-size: 2em;
  }
}

@media (max-width: 768px) {
  .greetings-heading {
    font-size: 2.5em;
    margin-bottom: 8px;
  }

  .greetings-sub-heading {
    font-size: 1.75em;
    margin-bottom: 6px;
  }

  .dashboard {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .greetings-heading {
    font-size: 2em;
    margin-bottom: 6px;
  }

  .greetings-sub-heading {
    font-size: 1.5em;
    margin-bottom: 5px;
  }

  .dashboard {
    padding: 10px;
  }
}
</style>
