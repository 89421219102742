import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cb527ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fare-management-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MProgress = _resolveComponent("MProgress")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MTextfield = _resolveComponent("MTextfield")!
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MCard = _resolveComponent("MCard")!

  return (_ctx.isAirlineAndProvidersFetching)
    ? (_openBlock(), _createBlock(_component_MProgress, {
        key: 0,
        indeterminate: "",
        class: "mprogress-alignment"
      }))
    : (_openBlock(), _createBlock(_component_MCard, { key: 1 }, {
        default: _withCtx(() => [
          _createVNode(_component_MTypography, { type: "headline" }, {
            default: _withCtx(() => [
              _createTextVNode("Search for Sabre Details")
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_MTextfield, {
              inputValue: _ctx.pnrNumber,
              "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pnrNumber) = $event)),
              inputValueModifiers: { trim: true },
              label: "PNR Number",
              hasError: _ctx.hasPnrError,
              errorMessage: _ctx.pnrNumberError,
              disabled: false
            }, null, 8, ["inputValue", "hasError", "errorMessage"]),
            _createVNode(_component_MCombobox, {
              inputValue: _ctx.airlineCode,
              "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.airlineCode) = $event)),
              options: _ctx.sabreAirlinesComboboxOptions,
              itemValue: "value",
              itemLabel: "label",
              label: "Airline/Supplier",
              hasError: _ctx.hasAirlineCodeError,
              errorMessage: _ctx.airlineCodeError,
              disabled: _ctx.isAirlineAndProvidersFetching
            }, null, 8, ["inputValue", "options", "hasError", "errorMessage", "disabled"]),
            _createVNode(_component_MButton, {
              class: "get-fare-action-button",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getSabreFares())),
              disabled: _ctx.isSabreFaresLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode("Get Fare")
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          (_ctx.airlineCode)
            ? (_openBlock(), _createBlock(_component_MTypography, {
                key: 0,
                type: "label"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.sabreAirlineInfoMessage), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
}