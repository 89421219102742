import { GetterTree } from "vuex";
import { ISOAState } from "@/ag-portal-common/interfaces/soaState.interface";
import { StoreState } from "@/store/type";

const getters: GetterTree<ISOAState, StoreState> = {
  isDownloadingSOA: (state): boolean => state.isDownloadingSOA,
  isSendingSOA: (state): boolean => state.isSendingSOA,
  getPendingPostingInvoices: (state) => state.pendingInvoicesAndReceipts,
};

export default getters;
