<template>
  <div class="sf-fare-options">
    <div
      class="sf-fare-option"
      v-for="(option, index) in transformedFareOptions"
      :key="index"
    >
      <template v-if="isCompactView">
        <div class="sf-fo-price">
          {{ calculateFlightPrice(option) }}
          <div
            class="sf-fo-discounted-price"
            v-if="option.price.ag_deal_discount"
          >
            {{ getFormattedCurrency(option.price.gross_fare.value) }}
          </div>
        </div>
      </template>

      <div class="sf-fo-title">{{ getRbdNameHandler(option.rbd) }}</div>

      <div class="sf-fo-info">
        <MIcon
          class="sf-icon primary"
          :name="`${option.has_meal ? 'm-meal' : 'm-no-meal'}`"
        />
        <MDivider type="vertical" />

        <MIcon class="sf-icon primary" :name="`${renderBaggageIcon(option)}`" />

        <div>{{ getBaggageInfo(option.baggage_info) }}</div>

        <template v-if="isCompactView && option.price.ag_deal_discount">
          <MDivider type="vertical" />
          <div class="sf-fo-deal-code">
            {{ option.discount_data.discount_code }}
          </div>
        </template>
      </div>
      <div class="sf-fo-actions">
        <template v-if="!isCompactView && option.price.ag_deal_discount">
          <div class="sf-fo-deal-code">
            {{ option.discount_data.discount_code }}
          </div>
          <div class="sf-fo-discounted-price">
            {{ getFormattedCurrency(option.price.gross_fare.value) }}
          </div>
        </template>

        <template v-if="!isCompactView">
          <div class="sf-fo-price">
            {{ calculateFlightPrice(option) }}
          </div>
        </template>

        <template v-if="!isPrinting">
          <MMenuWrapper @close:menu="option.showInfo = false">
            <template #target>
              <MIcon
                class="sf-icon sf-fo-details"
                name="m-info"
                @click="option.showInfo = !option.showInfo"
              />
            </template>

            <template #menu>
              <MCard
                class="sf-fo-menu m-index-level-two"
                v-show="option.showInfo"
              >
                <div class="sf-fo-title">Pricing Details</div>
                <div class="sf-fo-code">
                  {{ getRbdNameHandler(option.rbd) }}
                </div>

                <div class="sf-fo-summary">
                  <div class="sf-fo-row">
                    <div class="title">Base Fare</div>
                    <div class="price">
                      {{ getFormattedCurrency(option.price.base_fare.value) }}
                    </div>
                  </div>
                  <div class="sf-fo-row">
                    <div class="title">Tax</div>
                    <div class="price">
                      {{ getFormattedCurrency(option.price.tax.value) }}
                    </div>
                  </div>
                  <div class="sf-fo-row">
                    <div class="title">Gross Fare</div>
                    <div class="price">
                      {{ getFormattedCurrency(option.price.gross_fare.value) }}
                    </div>
                  </div>
                  <div
                    class="sf-fo-row"
                    v-if="option.price.ag_price_difference"
                  >
                    <div class="title">
                      {{
                        getPriceDifferenceLabel(
                          option.price.ag_price_difference.value
                        )
                      }}
                    </div>
                    <div class="price">
                      {{
                        getFormattedCurrency(
                          option.price.ag_price_difference.value
                        )
                      }}
                    </div>
                  </div>
                  <div class="sf-fo-row" v-if="option.price.ag_deal_discount">
                    <div class="title">Deal Discount</div>
                    <div class="price">
                      {{
                        getFormattedCurrency(
                          option.price.ag_deal_discount.value
                        )
                      }}
                    </div>
                  </div>
                  <div
                    class="sf-fo-row"
                    v-if="getFareCalculatorDifference(option).amount !== 0"
                  >
                    <div class="title">
                      {{ getFareCalculatorDifference(option).label }}
                    </div>
                    <div class="price">
                      {{ getFareCalculatorDifference(option).formatted }}
                    </div>
                  </div>

                  <MDivider class="sf-fo-divider" />

                  <div class="sf-fo-row total">
                    <div class="title">Total</div>
                    <div class="price">{{ calculateFlightPrice(option) }}</div>
                  </div>
                </div>
              </MCard>
            </template>
          </MMenuWrapper>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { MIcon, MDivider, MMenuWrapper } from "@aeroglobe/ag-core-ui";

import { BaggageInfo, FareOption, Price } from "@/ag-flight-components/types";
import UTILS from "@/ag-portal-common/utils";
import {
  TemperFare,
  TransformedFareOption,
} from "../../FlightSearchResult/types";

export default defineComponent({
  name: "SingleFareOptions",
  components: {
    MIcon,
    MDivider,
    MMenuWrapper,
  },
  props: {
    fareOptions: {
      type: Array as PropType<FareOption[]>,
      required: true,
    },
    temperFare: {
      type: Object as PropType<TemperFare>,
      required: true,
    },
    isPrinting: {
      type: Boolean,
      default: false,
    },
    isCompactView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      transformedFareOptions: this.fareOptions.map((option) => ({
        ...option,
        showInfo: false,
      })) as TransformedFareOption[],
    };
  },
  emits: ["select:fare"],
  methods: {
    calculateFlightPrice(fareOption: FareOption): string {
      const calculatedPrice = UTILS.calculateFlightPrice(
        fareOption,
        this.temperFare
      );

      return this.getFormattedCurrency(calculatedPrice);
    },
    getBaggageInfo(baggageInfo?: BaggageInfo | null): string {
      return UTILS.getBaggageInfo(baggageInfo);
    },
    getFormattedCurrency(amount: number): string {
      return UTILS.getFormattedCurrency(amount);
    },
    getTotalAmount(price: Price): number {
      let amount = 0;

      if (price.ag_gross_fare && price.ag_gross_fare.value) {
        amount = price.ag_gross_fare.value;
      } else {
        amount = price.gross_fare.value;
      }

      return amount;
    },
    getFareCalculatorDifference(fareOption: FareOption): {
      label: string;
      amount: number;
      formatted: string;
    } {
      const fcAmonut = UTILS.calculateFlightPrice(fareOption, this.temperFare);
      const totalAmount = this.getTotalAmount(fareOption.price);
      const difference = fcAmonut - totalAmount;

      return {
        label: difference > 0 ? "FC Fees" : "FC Discount",
        amount: difference,
        formatted: this.getFormattedCurrency(difference),
      };
    },
    getPriceDifferenceLabel(amount?: number | null): string {
      if ((amount ?? 0) > 0) {
        return "Fee";
      } else {
        return "Discount";
      }
    },
    getRbdNameHandler(name: string): string {
      return name.length === 1 ? `RDB: ${name}` : name;
    },
    onSelectFareHandler(fareOption: FareOption): void {
      this.$emit("select:fare", fareOption);
    },
    renderBaggageIcon(option: TransformedFareOption) {
      if (option.baggage_info) {
        if (
          option.baggage_info?.pieces > 0 &&
          option.baggage_info?.weight > 0
        ) {
          return "m-baggage";
        }
      }

      return "m-no-baggage";
    },
  },
});
</script>

<style scoped lang="css">
@import url("../styles/single-fare-options.css");
</style>
