<template>
  <MDialog
    v-model="isMdialogOpen"
    @click="isMdialogOpen = !isMdialogOpen"
    hide-footer
    hide-header
  >
    <template #content>
      <div class="waitlist-alert">
        <MIcon
          class="waitlist-alert-icon"
          name="m-info"
          width="48"
          height="48"
        />
        <MTypography type="title">Your booking is on WAITLIST</MTypography>
        <MTypography> Please check with airline before issuance </MTypography>
      </div>
    </template>
  </MDialog>
  <template>
    <MDialog
      v-model="isMdialogOpen"
      @click="isMdialogOpen = !isMdialogOpen"
      hide-footer
      hide-header
    >
      <template #content>
        <div class="waitlist-alert">
          <MIcon
            class="waitlist-alert-icon"
            name="m-info"
            width="48"
            height="48"
          />
          <MTypography type="title">Your booking is on WAITLIST</MTypography>
          <MTypography> Please check with airline before issuance </MTypography>
        </div>
      </template>
    </MDialog>
  </template>
</template>

<script lang="ts">
import { MTypography } from "@aeroglobe/ag-core-ui";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WaitlistDialog",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  watch: {
    isOpen(newVal) {
      this.isMdialogOpen = newVal;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("closeModal");
      }
    },
  },
  data() {
    return {
      isMdialogOpen: this.isOpen,
    };
  },
});
</script>

<style lang="css" scoped>
.waitlist-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 16px;
}

.waitlist-alert .waitlist-alert-icon {
  width: 48px;
  height: 48px;

  color: var(--m-error-color);
}
</style>
