<template>
  <MAccordion :initial-open="true" class="m-accordion-fare-management">
    <template #title>
      <AgIconInfoBar
        mPrepandIcon="m-fare-management"
        :title="renderNewPriceQuoteTitle()"
        class="maccordion-infobar"
      />
    </template>
    <template #content>
      <MAccordion
        v-for="(passenger, index) in getNewPriceQuoteInfo"
        :key="index"
        :initial-open="false"
        class="m-accordion-fare-management"
      >
        <template #title>
          <AgIconInfoBar
            :mPrepandIcon="renderPassengerType(passenger.passenger_type).icon"
            :title="renderPassengerType(passenger.passenger_type).type"
            class="maccordion-infobar"
          />
        </template>
        <template #content>
          <!-- Passenger Type Details -->
          <div class="new-price-quote-details">
            <div class="price-quote-detail-typographies">
              <MTypography>Passenger Type</MTypography>
              <MTypography type="label">{{
                passenger.passenger_type
              }}</MTypography>
            </div>

            <div class="price-quote-detail-typographies">
              <MTypography>Base Fare</MTypography>
              <MTypography type="label">{{
                getPassengerTypeCharges(passenger.price).base_fare
              }}</MTypography>
            </div>

            <div class="price-quote-detail-typographies">
              <MTypography>Fees</MTypography>
              <MTypography type="label">{{
                getPassengerTypeCharges(passenger.price).fees
              }}</MTypography>
            </div>

            <div class="price-quote-detail-typographies">
              <MTypography>Total Taxes</MTypography>
              <MTypography type="label">{{
                getPassengerTypeCharges(passenger.price).total_tax
              }}</MTypography>
            </div>

            <div class="price-quote-detail-typographies">
              <MTypography>Surcharges</MTypography>
              <MTypography type="label">{{
                getPassengerTypeCharges(passenger.price).surcharges
              }}</MTypography>
            </div>

            <div class="price-quote-detail-typographies">
              <MTypography>Gross Fare</MTypography>
              <MTypography type="label">{{
                getPassengerTypeCharges(passenger.price).gross_fare
              }}</MTypography>
            </div>
          </div>
          <!-- Passenger Type Taxes -->
          <div>
            <MDataTable
              :headers="taxTableHeaders"
              :data="passenger.price.tax_list"
              :is-api-paginated="false"
              :has-search="false"
              :has-pagination="false"
            >
              <template #amount="{ item }">
                <div>
                  <MTypography type="label">
                    {{ formattedCurrency(item.currency, item.amount) }}
                  </MTypography>
                </div>
              </template>
              <template #tax_name="{ item }">
                <div>
                  <MTypography type="label">
                    {{ item.tax_name ?? "-" }}
                  </MTypography>
                </div>
              </template>
              <template #ticketing_tax_code="{ item }">
                <div>
                  <MTypography type="label">
                    {{ item.ticketing_tax_code ?? "-" }}
                  </MTypography>
                </div>
              </template>
            </MDataTable>
          </div>
          <!-- Per Passenger Type Details -->
          <MTypography type="headline"
            >Price Per Passenger (Passenger Count:
            {{ passenger.passenger_count }})</MTypography
          >
          <div class="new-price-quote-details">
            <div class="price-quote-detail-typographies">
              <MTypography>Passenger Type</MTypography>
              <MTypography type="label">{{
                passenger.passenger_type
              }}</MTypography>
            </div>

            <div class="price-quote-detail-typographies">
              <MTypography>Base Fare</MTypography>
              <MTypography type="label">{{
                getPassengerTypeCharges(passenger.price_per_passenger).base_fare
              }}</MTypography>
            </div>

            <div class="price-quote-detail-typographies">
              <MTypography>Fees</MTypography>
              <MTypography type="label">{{
                getPassengerTypeCharges(passenger.price_per_passenger).fees
              }}</MTypography>
            </div>

            <div class="price-quote-detail-typographies">
              <MTypography>Total Taxes</MTypography>
              <MTypography type="label">{{
                getPassengerTypeCharges(passenger.price_per_passenger).total_tax
              }}</MTypography>
            </div>

            <div class="price-quote-detail-typographies">
              <MTypography>Surcharges</MTypography>
              <MTypography type="label">{{
                getPassengerTypeCharges(passenger.price_per_passenger)
                  .surcharges
              }}</MTypography>
            </div>

            <div class="price-quote-detail-typographies">
              <MTypography>Gross Fare</MTypography>
              <MTypography type="label">{{
                getPassengerTypeCharges(passenger.price_per_passenger)
                  .gross_fare
              }}</MTypography>
            </div>
          </div>

          <!-- Per Passenger Type Tax Details -->
          <div>
            <MDataTable
              :headers="taxTableHeaders"
              :data="passenger.price_per_passenger.tax_list"
              :is-api-paginated="false"
              :has-search="false"
              :has-pagination="false"
            >
              <template #amount="{ item }">
                <div>
                  <MTypography type="label">
                    {{ formattedCurrency(item.currency, item.amount) }}
                  </MTypography>
                </div>
              </template>
              <template #tax_name="{ item }">
                <div>
                  <MTypography type="label">
                    {{ item.tax_name ?? "-" }}
                  </MTypography>
                </div>
              </template>
              <template #ticketing_tax_code="{ item }">
                <div>
                  <MTypography type="label">
                    {{ item.ticketing_tax_code ?? "-" }}
                  </MTypography>
                </div>
              </template>
            </MDataTable>
          </div>
          <!-- Segment Details -->

          <div v-if="passenger.segments.length > 0">
            <MTypography type="headline">Segment Details</MTypography>
            <div>
              <MDataTable
                :headers="segmnetTableHeaders"
                :data="passenger.segments"
                :is-api-paginated="false"
                :has-search="false"
                :has-pagination="false"
              >
                <template #baggage="{ item }">
                  <div>
                    <MTypography type="label">
                      {{ renderSegmentBaggageInfo(item.baggage) }}
                    </MTypography>
                  </div>
                </template>
                <template #sector="{ item }">
                  <div>
                    <MTypography type="label">
                      {{
                        renderSegmentSector(
                          item.departure_city,
                          item.arrival_city
                        )
                      }}
                    </MTypography>
                  </div>
                </template>
                <template #departure_date_time="{ item }">
                  <MTypography class="description td-full-width" type="body">
                    {{
                      getFormattedDateTime(
                        item.departure_date_time,
                        FORMAT_DD_MMM_YYYY_WITH_DASH
                      )
                    }}
                  </MTypography>
                  <MTypography class="description" type="label">
                    {{
                      getFormattedDateTime(
                        item.departure_date_time,
                        FORMAT_HH_SS_24_HOURS
                      )
                    }}
                  </MTypography>
                </template>

                <template #arrival_date_time="{ item }">
                  <MTypography class="description td-full-width" type="body">
                    {{
                      getFormattedDateTime(
                        item.arrival_date_time,
                        FORMAT_DD_MMM_YYYY_WITH_DASH
                      )
                    }}
                  </MTypography>
                  <MTypography class="description" type="label">
                    {{
                      getFormattedDateTime(
                        item.arrival_date_time,
                        FORMAT_HH_SS_24_HOURS
                      )
                    }}
                  </MTypography>
                </template>
              </MDataTable>
            </div>
          </div>

          <!-- Baggage Info-->
          <div v-if="passenger.baggage_info.length > 0">
            <MTypography type="headline">Baggage Details</MTypography>
            <div>
              <MTypography type="label">
                {{
                  passenger.baggage_info
                    .map(
                      (info: string) =>
                        info.charAt(0).toUpperCase() +
                        info.slice(1).toLowerCase()
                    )
                    .join(", ")
                }}
              </MTypography>
            </div>
          </div>

          <!-- Price Quote Policies Details -->
          <div v-if="passenger.price_quote_policies.length > 0">
            <MTypography type="headline">Price Quote Policies</MTypography>
            <div>
              <MDataTable
                :headers="policiesTableHeaders"
                :data="passenger.price_quote_policies"
                :is-api-paginated="false"
                :has-search="false"
                :has-pagination="false"
              >
                <template #amount="{ item }">
                  <div>
                    <MTypography type="label">
                      {{ formattedCurrency(item.currency, item.amount) }}
                    </MTypography>
                  </div>
                </template>
                <template #cat_16="{ item }">
                  <RoundCheckCrossIcon :is-check="item.cat_16" />
                </template>
                <template #not_applicable="{ item }">
                  <RoundCheckCrossIcon :is-check="item.not_applicable" />
                </template>
              </MDataTable>
            </div>
          </div>
        </template>
      </MAccordion>
    </template>
  </MAccordion>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import RoundCheckCrossIcon from "@/modules/Organization/components/OrganizationDetails/CheckCrossIconComponent.vue";
import { MCard, MDataTable, MTypography } from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";
import {
  getCurrencyFormatter,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { NewPriceQuote, Price, Baggage } from "../constants";
import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_HH_SS_24_HOURS,
} from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "NewPriceQuoteCard",
  components: {
    RoundCheckCrossIcon,
  },
  data() {
    return {
      FORMAT_DD_MMM_YYYY_WITH_DASH,
      FORMAT_HH_SS_24_HOURS,
      taxTableHeaders: [
        {
          title: "Code",
          value: "code",
          key: "code",
          sortable: true,
        },
        {
          title: "Amount",
          value: "amount",
          key: "amount",
          sortable: true,
        },
        {
          title: "Tax Name",
          value: "tax_name",
          key: "tax_name",
          sortable: true,
        },
        {
          title: "Ticketing Tax Code",
          value: "ticketing_tax_code",
          key: "ticketing_tax_code",
          sortable: true,
        },
      ] as MDataTableHeader[],

      segmnetTableHeaders: [
        {
          title: "Sector",
          value: "sector",
          key: "sector",
          sortable: true,
        },
        {
          title: "Departure Date",
          value: "departure_date_time",
          key: "departure_date_time",
          sortable: true,
        },
        {
          title: "Arrival Date",
          value: "arrival_date_time",
          key: "arrival_date_time",
          sortable: true,
        },
        {
          title: "Baggage",
          value: "baggage",
          key: "baggage",
          sortable: true,
        },
        {
          title: "Flight Number",
          value: "flight_number",
          key: "flight_number",
          sortable: true,
        },
        {
          title: "RBD",
          value: "rbd",
          key: "rbd",
          sortable: true,
        },
        {
          title: "Segment Status",
          value: "segment_status",
          key: "segment_status",
          sortable: true,
        },
      ] as MDataTableHeader[],
      policiesTableHeaders: [
        {
          title: "Type",
          value: "type",
          key: "type",
          sortable: true,
        },
        {
          title: "Amount",
          value: "amount",
          key: "amount",
          sortable: true,
        },
        {
          title: "Cat 16",
          value: "cat_16",
          key: "cat_16",
          sortable: true,
        },
        {
          title: "Not Applicable",
          value: "not_applicable",
          key: "not_applicable",
          sortable: true,
        },
      ] as MDataTableHeader[],
    };
  },
  computed: {
    getNewPriceQuoteInfo(): NewPriceQuote[] {
      return this.$store.getters.sabreFares.new_price_quotes;
    },
    totalNewFare(): string {
      const priceDetail = this.getNewPriceQuoteInfo;
      const price = this.$store.getters.sabreFares.total_new_fare;
      const currency = priceDetail[0].price.currency_code;
      return this.formattedCurrency(currency, price);
    },
  },
  methods: {
    getFormattedDateTime,
    renderNewPriceQuoteTitle() {
      return "New Price Quote (" + this.totalNewFare + ")";
    },
    renderPassengerType(passengerType: string) {
      switch (passengerType) {
        case "ADT":
          return {
            type: "Adult Price Quote",
            icon: "m-adult-icon",
          };
        case "CNN":
          return {
            type: "Child Price Quote",
            icon: "m-child-icon",
          };
        case "INF":
          return {
            type: "Infant Price Quote",
            icon: "m-infant-icon",
          };
        default:
          return {
            type: "Adult Price Quote",
            icon: "m-infant-icon",
          };
      }
    },
    renderSegmentBaggageInfo(baggageInfo: Baggage | null) {
      return baggageInfo ? baggageInfo.allowance + baggageInfo.type : "-";
    },
    renderSegmentSector(departureCity: string, arrivalCity: string) {
      return departureCity + " - " + arrivalCity;
    },
    formattedCurrency(currency: string, amount: number): string {
      return getCurrencyFormatter(currency).format(amount);
    },
    getPassengerTypeCharges(priceDetails: Price) {
      const currency = priceDetails.currency_code;
      const fees = this.formattedCurrency(currency, priceDetails.fees);
      const baseFare = this.formattedCurrency(currency, priceDetails.base_fare);
      const totalTax = this.formattedCurrency(currency, priceDetails.total_tax);
      const grossFare = this.formattedCurrency(
        currency,
        priceDetails.gross_fare
      );
      const surcharges = this.formattedCurrency(
        currency,
        priceDetails.surcharges
      );
      const passengerData = {
        fees: fees,
        base_fare: baseFare,
        surcharges: surcharges,
        total_tax: totalTax,
        gross_fare: grossFare,
      };
      return passengerData;
    },
  },
});
</script>

<style lang="css" scoped>
.m-accordion-fare-management {
  margin-top: 10px;
}
.maccordion-infobar {
  margin: 0;
  border: none;
  padding: 0;
}

.new-price-quote-details {
  display: flex;
  flex-wrap: wrap;
}

.price-quote-detail-typographies {
  margin-right: 25px;
}
</style>
