import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { SOA_API_PATH } from "@/modules/SOA/constants";
import { GetSOADto, SendSOAEmailDto } from "@/modules/SOA/dto/getSOA.dto";
import { formatString } from "@/ag-portal-common/utils/helpers";
class SOAService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public get(params: GetSOADto): Promise<IAGResponse> {
    const additionalConfig = !params.send_email
      ? {
          responseType: "blob",
        }
      : {};
    const financialProfileId = params.financial_profile_public_id;

    return this.restClient.get(
      SOA_API_PATH.GET + financialProfileId + "/soa",
      params,
      additionalConfig
    );
  }
  public getEmail(params: GetSOADto): Promise<IAGResponse> {
    const additionalConfig = !params.send_email
      ? {
          responseType: "blob",
        }
      : {};
    return this.restClient.get(
      SOA_API_PATH.GET_EMAIL,
      params,
      additionalConfig
    );
  }
  public sendSOAEmail(payload: SendSOAEmailDto): Promise<IAGResponse<any>> {
    return this.restClient.post(SOA_API_PATH.SEND_SOA_EMAIL, payload);
  }
  public getPendingInvoice(id: string): Promise<IAGResponse<any>> {
    return this.restClient.get(formatString(SOA_API_PATH.INVOICE, id));
  }
}

export default SOAService;
