import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79a403d1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_3 = { class: "credit_limit_btn_space" }
const _hoisted_4 = {
  key: 2,
  class: "margin_bottom_0"
}
const _hoisted_5 = { class: "mchip-alignment" }
const _hoisted_6 = { class: "mchip-alignment" }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MProgress = _resolveComponent("MProgress")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MDataTable = _resolveComponent("MDataTable")!

  return (_ctx.isFetchingCreditLimitRequests)
    ? (_openBlock(), _createBlock(_component_MProgress, {
        key: 0,
        indeterminate: "",
        class: "mprogress-alignment"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MCard, { class: "credit-limit-heading" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_AgIconInfoBar, {
                "test-id": "",
                title: "Credit Limit Management",
                class: "section-title",
                mPrepandIcon: "m-credit-limit-management"
              }),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_MButton, {
                  "test-id": _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'create-credit-limit'),
                  onClick: _ctx.routeToCreateCreditLimitRequest
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Request Limit ")
                  ]),
                  _: 1
                }, 8, ["test-id", "onClick"])
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_MCard, null, {
          default: _withCtx(() => [
            _createVNode(_component_MDataTable, {
              isLoading: _ctx.isFetchingCreditLimitRequests,
              headers: _ctx.creditLimitHeaders,
              data: _ctx.creditLimitRequests.data
            }, {
              requestInitiatedAt: _withCtx(({ item }) => [
                (item.requestInitiatedAt)
                  ? (_openBlock(), _createBlock(_component_MTypography, {
                      key: 0,
                      class: "description",
                      type: "body"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(
                item.requestInitiatedAt,
                _ctx.FORMAT_DD_MMM_YYYY_WITH_DASH
              )), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (item.requestInitiatedAt)
                  ? (_openBlock(), _createBlock(_component_MTypography, {
                      key: 1,
                      class: "description",
                      type: "label"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(
                item.requestInitiatedAt,
                _ctx.FORMAT_HH_SS_24_HOURS
              )), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (!item.requestInitiatedAt)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, "N/A"))
                  : _createCommentVNode("", true)
              ]),
              requestedAmount: _withCtx(({ item }) => [
                _createVNode(_component_MTypography, {
                  class: "description",
                  type: "label"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formatNumber(item.requestedAmount)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              limitExpiresAt: _withCtx(({ item }) => [
                (item.limitExpiresAt)
                  ? (_openBlock(), _createBlock(_component_MTypography, {
                      key: 0,
                      class: "description",
                      type: "body"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(
                item.limitExpiresAt,
                _ctx.FORMAT_DD_MMM_YYYY_WITH_DASH
              )), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (item.limitExpiresAt)
                  ? (_openBlock(), _createBlock(_component_MTypography, {
                      key: 1,
                      class: "description",
                      type: "label"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(item.limitExpiresAt, _ctx.FORMAT_HH_SS_24_HOURS)), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : (_openBlock(), _createBlock(_component_MTypography, {
                      key: 2,
                      class: "description",
                      type: "label"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" - ")
                      ]),
                      _: 1
                    }))
              ]),
              status: _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_MChip, {
                    variant: _ctx.creditLimitStatusClass(item.status),
                    class: "m-chip-credit-limit"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.status), 1)
                    ]),
                    _: 2
                  }, 1032, ["variant"])
                ])
              ]),
              priority: _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_MChip, {
                    variant: _ctx.creditLimitPriorityclass(item.priority),
                    class: "m-chip-credit-limit"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.priority), 1)
                    ]),
                    _: 2
                  }, 1032, ["variant"])
                ])
              ]),
              financialProfileName: _withCtx(({ item }) => [
                _createVNode(_component_MTypography, {
                  class: "description financial_profile_name",
                  type: "body"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.financialProfileName), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              requestNotes: _withCtx(({ item }) => [
                (!item.requestNotes)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, "-"))
                  : (_openBlock(), _createBlock(_component_MTypography, {
                      key: 1,
                      class: "description",
                      type: "label"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.requestNotes), 1)
                      ]),
                      _: 2
                    }, 1024))
              ]),
              _: 1
            }, 8, ["isLoading", "headers", "data"])
          ]),
          _: 1
        })
      ]))
}