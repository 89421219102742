<template>
  <SnapshotDetailAccordion title="Hotel Bookings" prepand-icon="m-hotel">
    <template #overview>
      <div class="grid-container">
        <div v-for="(item, index) in hotelBookingsAggregates" :key="index">
          <SnapshotTile
            :heading="renderItemTitle(item)"
            :subHeading="renderItemValue(item)"
            subHeadingTitle="Booking Count"
            icon="m-hotel"
          >
          </SnapshotTile>
        </div>
      </div>
    </template>
    <template #accordion-content>
      <MDataTable
        v-if="showDetails"
        :headers="bookingHeader"
        :data="hotelBookingsOrganizations"
        :item-per-page="10"
        :hasSearch="true"
        :simpleDataTable="true"
      >
        <template #organization_name="{ item }">
          <div
            @click="handleOrganizationRowClick($event, item)"
            class="name-column organization-link"
          >
            <MTypography class="description organization-link" type="label">
              {{ item.organization_name }}
            </MTypography>
          </div>
        </template>
      </MDataTable>
      <ResultNotFound title="No Organization Found" description="" v-else />
    </template>
  </SnapshotDetailAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";

import SnapshotTile from "@/components/SnapshotTile.vue";

import {
  ISectorProductivity,
  HotelBookingsAggregates,
} from "@/ag-portal-common/interfaces/sectorProductivity.interface";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { MDataTable } from "@aeroglobe/ag-core-ui";
import SnapshotDetailAccordion from "@/components/SnapshotDetailAccordion.vue";
import ResultNotFound from "@/components/ResultNotFound.vue";

export default defineComponent({
  name: "HotelBookingsTable",
  components: {
    MDataTable,
    SnapshotDetailAccordion,
    SnapshotTile,
    ResultNotFound,
  },
  props: {
    handleOrganizationRowClick: {
      type: Function,
      default: () => ({}),
    },
  },
  data(): {
    startDate: Date;
    endDate: Date;
    selectedSector: string;
    bookingHeader: MDataTableHeader[];
  } {
    return {
      startDate: new Date(),
      endDate: new Date(),
      selectedSector: "",
      bookingHeader: [
        {
          title: "Organization Name",
          value: "organization_name",
          key: "organization_name",
          sortable: true,
          align: "left",
          contentAlign: "left",
        },
        {
          title: "Hotel Bookings",
          value: "hotel_bookings",
          key: "hotel_bookings",
          sortable: true,
        },
        {
          title: "Hotel Issued",
          value: "hotel_issued",
          key: "hotel_issued",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    renderItemTitle(value: string) {
      return _.capitalize(value?.replaceAll("_", " "));
    },
    renderItemValue(value: keyof HotelBookingsAggregates) {
      return this.sectorProductivity.hotel_bookings_data?.aggregates[value];
    },
  },
  computed: {
    sectorProductivity(): ISectorProductivity {
      return this.$store.getters.sectorProductivity;
    },
    showDetails(): boolean {
      const isLoading = this.$store.getters.isSectorProductivityLoading;
      const organizations =
        this.$store.getters.sectorProductivity?.hotel_bookings_data
          ?.organizations || [];
      return !isLoading && organizations.length > 0;
    },
    hotelBookingsOrganizations() {
      const sectorProductivity = this.$store.getters
        .sectorProductivity as ISectorProductivity;
      const organizations =
        sectorProductivity?.hotel_bookings_data?.organizations ?? [];

      return organizations;
    },
    hotelBookingsAggregates(): Array<keyof HotelBookingsAggregates> {
      const sectorProductivity = this.$store.getters.sectorProductivity as
        | ISectorProductivity
        | undefined;
      const aggregates = sectorProductivity?.hotel_bookings_data?.aggregates;

      if (aggregates) {
        return Object.keys(aggregates) as Array<keyof HotelBookingsAggregates>;
      }

      return [];
    },
  },
});
</script>

<style lang="scss" scoped>
.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-top: solid 8px var(--green-color);
}

.heading {
  font-size: 30px;
  font-weight: bold;
}

.overview-heading {
  font-size: 19px;
  margin: 10px;
  font-weight: bold;
}

.items-container {
  border-left: solid 4px var(--green-color);
}

.empty-items-message {
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}

.organization-link {
  color: var(--green-color);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.white-icon {
  color: #fff;
}
</style>

<style lang="css" scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

@media (max-width: 1350px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 963px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
</style>
